import { Helmet } from 'react-helmet'

type HelmetProps = {
    title: string | undefined
    options: HelmetOptions
}

type HelmetOptions = {
    [key: string]: string | {}
}

export const HelmetComponent: React.FC<HelmetProps> = ({ title = '', options: {} }) => {

    const appTitle = 'HatChuPay!'

    return (
        <Helmet>
            {title ? <title>{title} - {appTitle}</title> : <title>{appTitle}</title>}
        </Helmet>
    )
}