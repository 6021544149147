import {Fragment, useEffect, useLayoutEffect, useState, useRef} from 'react'
import {KTCard, KTSVG} from '@/_metronic/helpers'
import {CustomFieldActionsCell} from './tabel/endPointActionsCell'
import {UsersListLoading} from './tabel/UsersListLoading'
import {useNavigate} from 'react-router-dom'
import {showSuccessMessage, showErrorMessage, showWarningMessage} from '@/app/components/sweetAlert'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {addDays, startOfMonth, endOfMonth} from 'date-fns'
import {ja} from 'date-fns/locale'
import {
  orderDataFilter,
  orderDataUpdate,
  orderCsvTemplateListAllGet,
  orderDataDownload,
  parameterStoreGet,
  getCommonDetails,
} from 'src/app/modules/api'
import {Pagination} from 'src/app/components/pagination'
import {Spin, Space, Tag} from 'antd'
import {useLayout, useAlert} from 'src/_metronic/layout/core'
import moment from 'moment'
import {useIsWidthSize, formatNumber, getParameterStore} from 'src/app/modules/utils'
import {Modal} from 'react-bootstrap'
import {Direction} from '@aws-amplify/ui-react'
import {useFormik} from 'formik'
import {OrderStatus} from '@/app/modules/pages/settingsRegister/order-list/table/columns/Cell'

let arrys: any = []
let target: any = []
let list1: any = ''
let list2: any = ''
let titles: String = ''

export default function Order() {
  const navigate = useNavigate()
  const [keyWords, setKeyWords] = useState<string>('') // 检索
  const [parameters, setParameters] = useState<any>({})
  const {isContentLoading, setContentLoading} = useLayout()

  const initParameters = async () => {
    let res: any = await getParameterStore({
      names: ['/Define/Order/Status', '/Define/Payment/Status', '/Define/Shippingdetails/Status'],
    })

    setParameters(res)
  }

  const [queryParams, setQueryParams] = useState<any>({
    pageNum: 1,
    pageSize: 50,
  }) // 分页参数
  const [data, setData] = useState<any>([]) // 数据列表
  const [showdata, setshowData] = useState<any>([]) // 展示数据列表
  let [selectType, setSelectType] = useState<string>('') // 左上角选择框
  const [nowPage, setNowPage] = useState<any>(1) // 当前页
  const [pageSize, setPageSize] = useState<any>(10) // 每页多少条
  let [page, setPage] = useState<any>([]) // 页码列表
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [selectedList, setSelectList] = useState<any>([])
  const [activeStatus, setActiveStatus] = useState<{[key: string]: [value: string]} | {}>({})
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([])
  const [showFilter, setShowFilter] = useState<boolean>(true)
  const [showDownload, setShowDownload] = useState<boolean>(false)
  const [initialzedFlag, setInitializedFlag] = useState<boolean>(false)
  const parentRef = useRef<HTMLDivElement>(null)
  const [total, setTotal] = useState<any>(0)
  const [filterData, setFilterData] = useState<any>({})

  const formik = useFormik<{
    orderStatus: string[]
    paymentStatus: string[]
    shipmentStatus: string[]
    startDate: Date | null
    endDate: Date | null
  }>({
    initialValues: {
      orderStatus: [],
      paymentStatus: [],
      shipmentStatus: [],
      startDate: null,
      endDate: null,
    },
    onSubmit: (values: any) => {
      setQueryParams({...queryParams, ...values})
    },
  })

  const scrollableStyle = {
    overflow: 'auto',
    scrollbarWidth: 'none', // Firefox対応
    msOverflowStyle: 'none', // IEとEdge対応
  }

  const webkitScrollbarStyle = {
    display: 'none', // Chrome, Safari対応
  }

  // 適用
  const apply = () => {
    queryParams.stateVal = selectType
    getAllList()
  }
  const currentPage = (pageNum: any, pageSize: any) => {
    queryParams.pageNum = pageNum
    queryParams.pageSize = pageSize
    getAllList()
  }
  //处理页码
  const changePage = (sumPage: any, size?: number | string) => {
    page = []
    let num = Math.ceil(sumPage / Number(size ? size : pageSize))
    for (let i = 1; i < num + 1; i++) {
      page.push(i)
    }
    let newPage = JSON.parse(JSON.stringify([...page]))

    setPage(newPage)
  }
  // 改变当前显示数量
  // const changePageSize = (size: string | number) => {
  //   queryParams.pageSize = size
  //   queryParams.pageNum = 1
  //   setPageSize(size)
  //   setNowPage(1)
  //   changePage(data.length, size)
  //   let newArr = data.slice((1 - 1) * Number(size), 1 * Number(size))
  //   // setshowData(newArr)
  //   setData(newArr)
  //   getAllList()
  // }
  const changeSelectList = (obj: any) => {
    console.log('obj', obj)
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = []
        let arrs: any = []
        data.forEach((item: any) => {
          arr.push(item.id)
        })
        setSelectList(arr)
      } else {
        selectedList.push(obj.id)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([])
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id
        })
        selectedList.splice(idx, 1)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    }
  }
  // 获取列表数据
  const getAllList = async () => {
    try {
      setContentLoading(true)

      let res: any = await orderDataFilter(queryParams)

      if (res.success === true) {
        setFilterData(res.data.statusGroupData)
        let arr = res.data.pageData.rows.map((item: any) => {
          item.children = []
          item.id = item.pk

          if (parameters) {
            parameters['/Define/Order/Status']?.map((v: any) => {
              if (item.status == v.value) {
                item.status_name = v.label
                item.status_color = v.color
              }
            })
            parameters['/Define/Payment/Status']?.map((v: any) => {
              if (item.payment_status == v.value) {
                item.payment_status_name = v.label
                item.payment_status_color = v.color
              }
            })
            parameters['/Define/Shippingdetails/Status']?.map((v: any) => {
              if (item.shipment_status == v.value) {
                item.shipment_status_name = v.label
                item.shipment_status_color = v.color
              }
            })
          }
          return item
        })

        changePage(res.data.pageData.total, queryParams.pageSize)
        setTotal(res.data.pageData.total)
        // setQueryParams((prev: any) => {
        //   return {...prev, total: res.data.pageData.total}
        // })
        setData(arr)
      } else {
        // changePage(res.data.pageData.total, queryParams.pageSize)
        setTotal(0)
        setshowData([])
        setContentLoading(false)
      }
    } catch (error) {
      console.error(error)
      setTotal(0)
      setshowData([])
      setContentLoading(false)
    }
  }

  const bindClickStatus = async (val: string) => {
    if (val === '') {
      setQueryParams({...queryParams, orderStatus: []})
    } else {
      setQueryParams({...queryParams, orderStatus: [val]})
    }
  }

  useEffect(() => {
    setContentLoading(true)
    initParameters()
  }, [])

  useEffect(() => {
    // 必要なパラメーターが全て揃ったらデータ取得
    // if (Object.keys(parameters).length > 0) {
    if (
      parameters['/Define/Order/Status'] &&
      parameters['/Define/Payment/Status'] &&
      parameters['/Define/Shippingdetails/Status']
    ) {
      setInitializedFlag(true)
      getAllList()
    }
  }, [parameters])

  useEffect(() => {
    // 2回目以降のデータ取得
    if (initialzedFlag) {
      getAllList()
    }
  }, [queryParams])

  useEffect(() => {
    // console.log('data', data)
    if (data.length > 0) {
      setshowData(data)
    }
    setContentLoading(false)
  }, [data])

  return (
    <>
      {useIsWidthSize('lg') ? (
        <div style={{position: 'relative'}}>
          <KTCard resetSidePaddings={true}>
            <div className='table-responsive '>
              <ScrollMenu
                parameters={parameters}
                parentRef={parentRef}
                queryParams={queryParams}
                bindClickStatus={bindClickStatus}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                showDownload={showDownload}
                setShowDownload={setShowDownload}
                totalCounts={filterData}
              />

              <FilterMenu formik={formik} parameters={parameters} isShow={showFilter} />
              <table
                className='table align-middle table-row-bordered fs-6 gy-1 gx-6 gy-0 dataTable no-footer'
                style={{minWidth: 1000}}
              >
                <thead className='table-light'>
                  <tr className='text-start text-uppercase gs-0'>
                    <th className='w-10px ps-6 pe-4'>
                      <div className='form-check form-check-sm form-check-custom'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          data-kt-check-target='#kt_table_users .form-check-input'
                          checked={selectedList.length === data.length && selectedList.length !== 0}
                          onChange={(e) => {
                            let obj = {
                              id: 'all',
                              value: e.target.checked,
                            }
                            changeSelectList(obj)
                          }}
                        />
                      </div>
                    </th>
                    <th id='id'>注文番号</th>
                    <th id='orderData'>注文日時</th>
                    <th id='name'>取引先名</th>
                    <th id='total_amount'>総合計</th>
                    <th id='status'>注文</th>
                    <th id='payment_status'>決済</th>
                    <th id='shipping_status'>出荷</th>
                    <th id='bill'>帳票</th>

                    {/* <th id='reason'>対応状況</th> */}
                  </tr>
                </thead>

                <tbody className=''>
                  {showdata.length > 0 ? (
                    showdata.map((item: any, index: number) => {
                      return (
                        <Fragment key={item.id}>
                          <tr>
                            <td className='ps-6 pe-4'>
                              <div className='form-check form-check-sm form-check-custom'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  data-kt-check-target='#kt_table_users .form-check-input'
                                  checked={selectedList.includes(item.id)}
                                  onChange={(e) => {
                                    let obj = {
                                      id: item.id,
                                      paymentId: item.payment_id,
                                      value: e.target.checked,
                                    }
                                    changeSelectList(obj)
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <a
                                onClick={() => {
                                  navigate(`/order/${item.id.split('#').pop()}`)
                                }}
                                style={{
                                  textDecorationLine: 'underline',
                                  color: '#009ef7',
                                  cursor: 'pointer',
                                }}
                              >
                                {item.read_flag === 'false' && (
                                  <span
                                    className='bullet bullet-dot bg-danger me-2'
                                    style={{width: '.5rem', height: '.5rem'}}
                                  ></span>
                                )}
                                {item.display_id}
                              </a>
                            </td>
                            <td>{moment(item.order_date).format('YYYY/MM/DD HH:mm:ss')}</td>
                            <td>{item.buyer_name}</td>
                            <td className=''>
                              <span className='fw-bold' style={{color: 'red'}}>
                                {formatNumber(item.total_amount)}円
                              </span>
                            </td>
                            <td>
                              <span className={`badge py-3 px-4 badge-${item.status_color}`}>
                                {item.status_name}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`badge py-3 px-4 badge-${item.payment_status_color}`}
                              >
                                {item.payment_status_name}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`badge py-3 px-4 badge-${item.shipment_status_color}`}
                              >
                                {item.shipment_status_name}
                              </span>
                            </td>
                            <td>
                              <div className='d-inline-flex flex-row gap-2'>
                                <a
                                  href='#'
                                  className={`btn btn-sm btn-white btn-outline w-100 py-1 disabled`}
                                >
                                  領収書
                                </a>
                                <a
                                  href='#'
                                  className={`btn btn-sm btn-white btn-outline w-100 py-1 disabled`}
                                >
                                  納品書
                                </a>
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={17}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {isContentLoading ? 'データの取得中' : '注文データはありません'}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>

                {/* <tfoot>
                  <tr>
                    <td colSpan={17}>
                      <select
                        className='form-select '
                        data-control='select2'
                        style={{width: 80}}
                        value={pageSize}
                        onChange={(e) => {
                          changePageSize(e.target.value)
                        }}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </td>
                  </tr>
                </tfoot> */}
              </table>
            </div>
          </KTCard>

          <div className='row mt-6'>
            <div className='col-12 d-flex justify-content-center'>
              <Pagination
                currentPage={currentPage}
                page={queryParams.pageNum}
                pageSize={queryParams.pageSize}
                total={total}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className='mb-4'>
            <ScrollMenu
              parameters={parameters}
              parentRef={parentRef}
              queryParams={queryParams}
              bindClickStatus={bindClickStatus}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              showDownload={showDownload}
              setShowDownload={setShowDownload}
              totalCounts={filterData}
            />
            <FilterMenu formik={formik} parameters={parameters} isShow={showFilter} />
          </div>
          <OrderListSP data={showdata} />
          <div className='row'>
            <div className='col-12 d-flex justify-content-center'>
              <Pagination
                currentPage={currentPage}
                page={queryParams.pageNum}
                pageSize={queryParams.pageSize}
                total={total}
              />
            </div>
          </div>
        </>
      )}
      <DownloadModal
        parameters={parameters}
        isShow={showDownload}
        handleClose={() => setShowDownload(false)}
      />
    </>
  )
}

const OrderListSP: React.FC<any> = ({data}) => {
  const navigate = useNavigate()

  return (
    <>
      {data.length > 0 ? (
        data.map((item: any, index: number) => {
          return (
            <div className='row mb-6'>
              <div className='col-12 fs-6 mb-2'>
                注文日 {moment(item.order_date).format('YYYY/MM/DD HH:mm:ss')}
              </div>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row mb-4'>
                      <div className='col-4 fs-2x d-flex align-items-center px-1'>
                        <span
                          className={`badge badge-${item.status_color} w-100 justify-content-center py-3`}
                        >
                          {item.status_name}
                        </span>
                      </div>
                      <div className='col-4 fs-2x d-flex align-items-center px-1'>
                        <span
                          className={`badge badge-${item.payment_status_color} w-100 justify-content-center py-3`}
                        >
                          {item.payment_status_name}
                        </span>
                      </div>
                      <div className='col-4 fs-2x d-flex align-items-center px-1'>
                        <span
                          className={`badge badge-${item.shipment_status_color} w-100 justify-content-center py-3`}
                        >
                          {item.shipment_status_name}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-6 fs-7 text-center border-end'>
                        注文店舗 <br />
                        {item.buyer_name}
                      </div>
                      <div className='col-6 fs-7 text-center'>
                        注文番号
                        <br />
                        {item.display_id}
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-12 fs-7 text-center'>
                        総合計
                        <br />
                        <span className='fs-5 fw-bold' style={{color: 'red'}}>
                          {formatNumber(item.total_amount)}円
                        </span>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12 d-flex justify-content-center'>
                        <a
                          href='#'
                          className='btn btn-secondary btn-color-primary w-75'
                          onClick={(e) => {
                            navigate(`/order/${item.id.split('#').pop()}`, {
                              state: {
                                pkVal: item.pk,
                                gamenFlg: '2',
                                id: item.id,
                                orderManagementId: item.order_management_id,
                                buyer_id: item.buyer_id,
                              },
                            })
                            e.preventDefault()
                          }}
                        >
                          注文明細
                        </a>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-6 d-flex justify-content-center'>
                        <a href='#' className='btn btn-white btn-outline fs-6 w-100 disabled'>
                          領収書
                        </a>
                      </div>
                      <div className='col-6 d-flex justify-content-center'>
                        <a href='#' className='btn btn-white btn-outline fs-6 w-100 disabled'>
                          納品書
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <div className='row'>
          <div className='col-12 d-flex text-center w-100 align-content-center justify-content-center'>
            注文データはありません
          </div>
        </div>
      )}
    </>
  )
}

const DownloadModal: React.FC<any> = ({
  parameters,
  isShow,
  handleClose,
}: {
  parameters: any
  isShow: boolean
  handleClose: () => void
}) => {
  const [templateOptions, setTemplateOptions] = useState<any[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<string>('')

  useEffect(() => {
    // 初回レンダリング時にAPIをコールしてセレクトの内容を取得
    const fetchTemplates = async () => {
      // API呼び出し例
      const response: any = await orderCsvTemplateListAllGet()
      setTemplateOptions(response)
    }

    fetchTemplates()
  }, []) // 空の依存配列で初回マウント時にのみ実行

  const handleTemplateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTemplate(event.target.value)
  }

  const handleSubmit = () => {
    // ダウンロード処理をここで実行
    console.log('Downloading template:', selectedTemplate)
    handleClose() // モーダルを閉じる
  }

  return (
    <>
      <Modal show={isShow} onHide={handleClose} dialogClassName='modal-dialog-centered'>
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>注文CSVダウンロード</div>
        </div>
        <div className='separator border-gray-200'></div>
        <div className='px-7 py-5'>
          <div className='row'>
            <div className='col-12 mb-4'>現在表示中の条件でCSVをダウンロードします。</div>
          </div>
          <div className='row'>
            <div className='col-12 mb-4'>
              <label className='form-label fs-6 fw-bold'>ダウンロード テンプレート</label>
            </div>
            <div className='col-12 mb-4'>
              <select
                className='form-select form-select-solid'
                style={{width: '100%'}}
                value={selectedTemplate}
                onChange={handleTemplateChange}
              >
                <option value=''>選択してください</option>
                {templateOptions.map((item: any) => (
                  <option key={item.pk} value={item.pk}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              onClick={() => {
                handleClose()
              }}
            >
              キャンセル
            </button>

            <button
              type='button'
              className='btn btn-primary fw-bold px-6'
              onClick={() => {
                // setdistinction(vipList)
                // updateOrder()
              }}
            >
              ダウンロード
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const ScrollMenu: React.FC<any> = ({
  parameters,
  parentRef,
  queryParams,
  bindClickStatus,
  showFilter,
  setShowFilter,
  showDownload,
  setShowDownload,
  totalCounts,
}: {
  parameters: any
  parentRef: any
  queryParams: any
  bindClickStatus: (param: any) => void
  showFilter: boolean
  setShowFilter: (param: boolean) => void
  showDownload: boolean
  setShowDownload: (param: boolean) => void
  totalCounts: any
}) => {
  const [isScrollable, setIsScrollable] = useState(false)

  const handleScroll = (direction: 'left' | 'right') => {
    const scrollAmount = direction === 'left' ? -25 : 25
    const duration = 300 // スクロールの合計時間
    const startTime = performance.now()

    const scroll = (currentTime: number) => {
      const elapsed = currentTime - startTime
      const progress = Math.min(elapsed / duration, 1) // 0から1の範囲で進捗を計算

      if (parentRef.current) {
        // 新しいスクロール位置を計算して適用
        parentRef.current.scrollLeft += scrollAmount * progress

        // スクロール位置のログを出力
        console.log('parentRef.current.scrollLeft', parentRef.current.scrollLeft)
      }

      // 進捗が1未満なら次のフレームで続行
      if (progress < 1) {
        requestAnimationFrame(scroll)
      }
    }

    requestAnimationFrame(scroll)
  }

  useEffect(() => {
    const parent: any = parentRef.current

    if (!parent) return

    const checkScrollability = () => {
      if (parent.scrollWidth > parent.clientWidth) {
        setIsScrollable(true)
      } else {
        setIsScrollable(false)
      }
    }

    // 子要素のサイズ変更を監視
    const resizeObserver = new ResizeObserver(() => {
      checkScrollability()
    })

    Array.from(parent.children).forEach((child: any) => resizeObserver.observe(child))

    // ウィンドウのリサイズイベントを監視
    window.addEventListener('resize', checkScrollability)

    // 初回レンダリング後にスクロール可能かどうかをチェック
    checkScrollability()

    // コンポーネントがアンマウントされたときにリスナーをクリーンアップ
    return () => {
      resizeObserver.disconnect()
      window.removeEventListener('resize', checkScrollability)
    }
  }, [parameters])

  return (
    <>
      <div className='d-flex bg-white'>
        <div
          className={`flex-glow-1 d-flex text-nowrap overflow-auto w-100 `}
          ref={parentRef}
          style={{
            scrollbarWidth: 'none',
          }}
        >
          <div className='fw-normal p-2'>
            <a
              href='#'
              className={
                `btn btn-sm btn-active-light-primary me-2 fs-6 fw-bold` +
                (!queryParams.orderStatus || queryParams.orderStatus.includes('') ? ' active' : '')
              }
              onClick={(e) => {
                e.preventDefault()
                bindClickStatus('')
              }}
            >
              すべて {totalCounts['totolStatusVal'] ? `(${totalCounts['totolStatusVal']})` : ''}
            </a>
            {parameters['/Define/Order/Status']?.map((item: any) => (
              <a
                href='#'
                className={
                  `btn btn-sm btn-active-light-primary me-2 fs-6 fw-bold` +
                  (queryParams.orderStatus && queryParams.orderStatus.includes(item.value)
                    ? ' active'
                    : '')
                }
                onClick={(e) => {
                  e.preventDefault()
                  bindClickStatus(item.value)
                }}
              >
                {item.label} {totalCounts[item.value] ? `(${totalCounts[item.value]})` : ''}
              </a>
            ))}
          </div>
        </div>
        <div className='d-flex align-items-center px-2'>
          {isScrollable && (
            <>
              <button
                type='button'
                className='btn btn-sm btn-white btn-outline btn-icon me-2'
                onClick={() => handleScroll('left')}
              >
                <i className='bi bi-arrow-left fs-2'></i>
              </button>

              <button
                type='button'
                className='btn btn-sm btn-white btn-outline btn-icon me-2'
                onClick={() => handleScroll('right')}
              >
                <i className='bi bi-arrow-right fs-2'></i>
              </button>
            </>
          )}
          <button
            type='button'
            className={`btn btn-sm btn-white btn-outline btn-icon me-2 ${
              showFilter ? 'active' : ''
            }`}
            onClick={() => {
              setShowFilter(!showFilter)
            }}
          >
            <i className='bi bi-filter fs-2'></i>
          </button>
          <button
            type='button'
            className={`btn btn-sm btn-white btn-outline btn-icon me-2 ${
              showDownload ? 'active' : ''
            }`}
            onClick={() => {
              setShowDownload(!showDownload)
            }}
          >
            <i className='bi bi-download fs-4'></i>
          </button>
        </div>
      </div>
    </>
  )
}

const FilterMenu: React.FC<any> = ({formik, parameters, isShow}) => {
  const isWidthLg = useIsWidthSize('lg')
  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD')
      formik.setFieldValue('startDate', formattedDate)
      if (formik.values.endDate && moment(date).isAfter(moment(formik.values.endDate))) {
        formik.setFieldValue('endDate', '')
      }
    } else {
      formik.setFieldValue('startDate', '')
    }
  }

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DD')
      formik.setFieldValue('endDate', formattedDate)
      if (formik.values.startDate && moment(date).isBefore(moment(formik.values.startDate))) {
        formik.setFieldValue('startDate', '')
      }
    } else {
      formik.setFieldValue('endDate', '')
    }
  }

  const setToday = () => {
    const today = moment().format('YYYY-MM-DD')
    formik.setFieldValue('startDate', today)
    formik.setFieldValue('endDate', today)
  }

  const setLast7Days = () => {
    const today = moment()
    formik.setFieldValue('startDate', today.clone().subtract(7, 'days').format('YYYY-MM-DD'))
    formik.setFieldValue('endDate', today.format('YYYY-MM-DD'))
  }

  const setCurrentMonth = () => {
    const today = moment()
    formik.setFieldValue('startDate', today.clone().startOf('month').format('YYYY-MM-DD'))
    formik.setFieldValue('endDate', today.clone().endOf('month').format('YYYY-MM-DD'))
  }

  const handleCheckboxChange = (
    key: 'orderStatus' | 'paymentStatus' | 'shipmentStatus',
    value: string
  ) => {
    const selected = formik.values[key]
    if (selected.includes(value)) {
      // 既に選択されている場合は削除
      formik.setFieldValue(
        key,
        selected.filter((item: any) => item !== value)
      )
    } else {
      // 選択されていない場合は追加
      formik.setFieldValue(key, [...selected, value])
    }
  }

  return (
    <>
      {isShow && (
        <div className='bg-light my-0 p-2'>
          <div className='d-flex flex-column gap-4 bg-white p-2'>
            {isWidthLg ? (
              <div className='d-flex align-items-center gap-6'>
                <div className='flex-shrink-0'>注文日時</div>
                <div>
                  <DatePicker
                    selected={
                      formik.values.startDate
                        ? moment(formik.values.startDate, 'YYYY/MM/DD').toDate()
                        : null
                    }
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={
                      formik.values.startDate
                        ? moment(formik.values.startDate, 'YYYY/MM/DD').toDate()
                        : null
                    }
                    endDate={
                      formik.values.endDate
                        ? moment(formik.values.endDate, 'YYYY/MM/DD').toDate()
                        : null
                    }
                    showIcon
                    className='custom-datepicker form-control form-control-sm ps-10 w-auto'
                    icon='bi bi-calendar3 position-absolute top-50 translate-middle ms-6'
                    dateFormat='yyyy/MM/dd'
                    locale={ja}
                    placeholderText=''
                  />
                </div>
                <div className='flex-shrink-0'>から</div>
                <div>
                  <DatePicker
                    selected={
                      formik.values.endDate
                        ? moment(formik.values.endDate, 'YYYY/MM/DD').toDate()
                        : null
                    }
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={
                      formik.values.startDate
                        ? moment(formik.values.startDate, 'YYYY/MM/DD').toDate()
                        : null
                    }
                    endDate={
                      formik.values.endDate
                        ? moment(formik.values.endDate, 'YYYY/MM/DD').toDate()
                        : null
                    }
                    minDate={
                      formik.values.startDate
                        ? moment(formik.values.startDate, 'YYYY/MM/DD').toDate()
                        : null
                    } // 開始日以降のみ選択可能
                    showIcon
                    className='custom-datepicker form-control form-control-sm ps-10'
                    icon='bi bi-calendar3 position-absolute top-50 translate-middle ms-6'
                    dateFormat='yyyy/MM/dd'
                    locale={ja}
                    placeholderText=''
                  />
                </div>
                <div className='flex-shrink-0'>まで</div>
                <div className='d-flex gap-2'>
                  <button className='btn btn-sm btn-white btn-outline' onClick={setToday}>
                    今日
                  </button>
                  <button className='btn btn-sm btn-white btn-outline' onClick={setLast7Days}>
                    7日間
                  </button>
                  <button className='btn btn-sm btn-white btn-outline' onClick={setCurrentMonth}>
                    今月
                  </button>
                </div>
              </div>
            ) : (
              <div className='row'>
                <div className='col-12 mb-3'>注文日時</div>
                <div className='col-12'>
                  <div className='d-flex align-items-center mb-2'>
                    <div>
                      <DatePicker
                        selected={
                          formik.values.startDate
                            ? moment(formik.values.startDate, 'YYYY/MM/DD').toDate()
                            : null
                        }
                        onChange={handleStartDateChange}
                        selectsStart
                        startDate={
                          formik.values.startDate
                            ? moment(formik.values.startDate, 'YYYY/MM/DD').toDate()
                            : null
                        }
                        endDate={
                          formik.values.endDate
                            ? moment(formik.values.endDate, 'YYYY/MM/DD').toDate()
                            : null
                        }
                        showIcon
                        className='custom-datepicker form-control form-control-sm ps-10'
                        icon='bi bi-calendar3 position-absolute top-50 translate-middle ms-6'
                        dateFormat='yyyy/MM/dd'
                        locale={ja}
                        placeholderText=''
                      />
                    </div>
                    <div className='flex-shrink-0 px-2'>から</div>
                    <div>
                      <DatePicker
                        selected={
                          formik.values.endDate
                            ? moment(formik.values.endDate, 'YYYY/MM/DD').toDate()
                            : null
                        }
                        onChange={handleEndDateChange}
                        selectsEnd
                        startDate={
                          formik.values.startDate
                            ? moment(formik.values.startDate, 'YYYY/MM/DD').toDate()
                            : null
                        }
                        endDate={
                          formik.values.endDate
                            ? moment(formik.values.endDate, 'YYYY/MM/DD').toDate()
                            : null
                        }
                        minDate={
                          formik.values.startDate
                            ? moment(formik.values.startDate, 'YYYY/MM/DD').toDate()
                            : null
                        } // 開始日以降のみ選択可能
                        showIcon
                        className='custom-datepicker form-control form-control-sm ps-10'
                        icon='bi bi-calendar3 position-absolute top-50 translate-middle ms-6'
                        dateFormat='yyyy/MM/dd'
                        locale={ja}
                        placeholderText=''
                      />
                    </div>
                    <div className='flex-shrink-0 ps-2'>まで</div>
                  </div>
                  <div className='col-12'>
                    <div className='d-flex gap-2'>
                      <button
                        className='btn btn-sm btn-white btn-outline flex-grow-1'
                        onClick={setToday}
                      >
                        今日
                      </button>
                      <button
                        className='btn btn-sm btn-white btn-outline flex-grow-1'
                        onClick={setLast7Days}
                      >
                        7日間
                      </button>
                      <button
                        className='btn btn-sm btn-white btn-outline flex-grow-1'
                        onClick={setCurrentMonth}
                      >
                        今月
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isWidthLg ? (
              <div className='d-flex align-items-center gap-6'>
                <div className='flex-shrink-0'>注文ステータス</div>
                <div className='flex-glow-1 d-flex gap-4 flex-wrap'>
                  {parameters['/Define/Order/Status']?.map((item: any) => (
                    <div key={item.value} className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id={`status-${item.value}`}
                        value={item.value}
                        checked={formik.values.orderStatus.includes(item.value)}
                        onChange={() => handleCheckboxChange('orderStatus', item.value)}
                      />
                      <label className='form-check-label' htmlFor={`status-${item.value}`}>
                        {item.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className='row'>
                <div className='col-12 mb-3'>注文日時</div>
                <div className='col-12'>
                  <div className='d-flex flex-wrap gap-3'>
                    {parameters['/Define/Order/Status']?.map((item: any) => (
                      <div key={item.value} className='form-check flex-shrink-0'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={`status-${item.value}`}
                          value={item.value}
                          checked={formik.values.orderStatus.includes(item.value)}
                          onChange={() => handleCheckboxChange('orderStatus', item.value)}
                        />
                        <label className='form-check-label' htmlFor={`status-${item.value}`}>
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {isWidthLg ? (
              <div className='d-flex align-items-center gap-6'>
                <div className='flex-shrink-0'>決済ステータス</div>
                <div className='flex-glow-1 d-flex gap-4 flex-wrap'>
                  {parameters['/Define/Payment/Status']?.map((item: any) => (
                    <div key={item.value} className='form-check'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id={`payment-${item.value}`}
                        value={item.value}
                        checked={formik.values.paymentStatus.includes(item.value)}
                        onChange={() => handleCheckboxChange('paymentStatus', item.value)}
                      />
                      <label className='form-check-label' htmlFor={`payment-${item.value}`}>
                        {item.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className='row'>
                <div className='col-12 mb-3'>決済ステータス</div>
                <div className='col-12'>
                  <div className='d-flex flex-wrap gap-3'>
                    {parameters['/Define/Payment/Status']?.map((item: any) => (
                      <div key={item.value} className='form-check flex-shrink-0'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={`payment-${item.value}`}
                          value={item.value}
                          checked={formik.values.paymentStatus.includes(item.value)}
                          onChange={() => handleCheckboxChange('paymentStatus', item.value)}
                        />
                        <label className='form-check-label' htmlFor={`payment-${item.value}`}>
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {isWidthLg ? (
              <>
                <div className='d-flex align-items-center gap-6'>
                  <div className='flex-shrink-0'>出荷ステータス</div>
                  <div className='flex-glow-1 d-flex gap-4 flex-wrap'>
                    {parameters['/Define/Shippingdetails/Status']?.map((item: any) => (
                      <div key={item.value} className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={`shipping-${item.value}`}
                          value={item.value}
                          checked={formik.values.shipmentStatus.includes(item.value)}
                          onChange={() => handleCheckboxChange('shipmentStatus', item.value)}
                        />
                        <label className='form-check-label' htmlFor={`shipping-${item.value}`}>
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              <div className='row'>
                <div className='col-12 mb-3'>出荷ステータス</div>
                <div className='col-12'>
                  <div className='d-flex flex-wrap gap-3'>
                    {parameters['/Define/Shippingdetails/Status']?.map((item: any) => (
                      <div key={item.value} className='form-check flex-shrink-0'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={`shipping-${item.value}`}
                          value={item.value}
                          checked={formik.values.shipmentStatus.includes(item.value)}
                          onChange={() => handleCheckboxChange('shipmentStatus', item.value)}
                        />
                        <label className='form-check-label' htmlFor={`shipping-${item.value}`}>
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className='d-flex align-items-center gap-6'>
              <div>
                <button
                  type='button'
                  className='btn btn-sm btn-primary'
                  onClick={formik.submitForm}
                >
                  指定した条件で検索
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
