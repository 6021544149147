import { getServer, postServer, deleteServer } from 'src/service/http'

// 商品情報取得
export function productListGet(param: object, headers: object = {}) {
  return getServer(`/product-list-get`, { ...param }, { ...headers })
}

export function productInfoGet(param: object, headers: object = {}) {
    return getServer(`/product-info-get`, { ...param }, { ...headers })
}

// 商品情報登録
export function productInfoAdd(param: object, headers: object = {}) {
    return postServer(`/product-info-add`, { ...param }, { ...headers })
}

// 商品情報ダウンロード
export function productInfoDownload(param: object) {
    return getServer(`/product-info-download`, { ...param })
}

// 商品情報複製
export function productInfoCopy(param: object, headers: object = {}) {
    return postServer(`/product-info-copy`, { ...param }, { ...headers })
}

// 商品情報更新
export function productInfoUpdate(param: object) {
    return postServer(`/product-info-update`, { ...param })
}

// 商品一括更新
export function productStatusBulkUpdate(param: object) {
    return postServer(`/product-status-bulk-update`, { ...param })
}

// 商品情報削除
export function productInfoDelete(param: object) {
    return deleteServer(`/product-info-delete`, { ...param })
}

// 商品一括削除
export function productBulkDelete(param: object) {
    return deleteServer(`/product-bulk-delete`, { ...param })
}

  // 商品情報取得
  export function productVariationInfoGet(param: object) {
    return getServer(`/product-variation-info-get`, { ...param })
  }
  
  // 商品情報複製
  export function productVariationInfoCopy(param: object,headers: object={}) {
    return postServer(`/product-variation-info-copy`, { ...param }, {...headers})
  }
  
  // 商品情報更新
  export function productVariationInfoUpdate(param: object,headers: object={}) {
    return postServer(`/product-variation-info-update`, { ...param }, { ...headers})
  }
  
  // 商品情報削除
  export function productVariationInfoDelete(param: object) {
    return deleteServer(`/product-variation-info-delete`, { ...param })
  }