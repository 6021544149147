import React, { useEffect, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '@/_metronic/helpers';
import { UsersListLoading } from '../cards/UsersListLoading';
import { billingInfoListGet, userContractListGet, parameterStoreGet } from 'src/app/modules/api';
// import { Pagination } from 'antd';
import { Pagination } from 'src/app/components/pagination';
import './InfoList.scss';
import { useAuth } from "src/app/modules/auth"
import moment from 'moment'

const class1 = 'page-item previous';
const class2 = 'page-item previous active';
const class3 = 'page-item previous disabled';

export function InfoList() {
  const {auth} = useAuth();
    let headers = {
      "Content-Type":"application/json",
      Authorization:`Bearer ${auth.token}`
    }
  const [nowPage, setNowPage] = useState<any>(1); // 当前页
  const [isLoading, setIsLoading] = useState<boolean>(false); // 加载状态
  let [page, setPage] = useState<any>([]); // 页码列表
  const [pageSize, setPageSize] = useState<any>(10); // 每页多少条
  const [selectedList, setSelectList] = useState<any>([]); // 选中的项
  const [arr, setArr] = useState<any>([
    {
      id: '1313460',
      issueDate: '2023/11/1',
      content: 'HatChuPay基本料金1',
      requestAmount: '3,300円',
      requestLetter: 'PDF',
      status: '決済前',
    },
    {
      id: '1313459',
      issueDate: '2023/11/2',
      content: 'HatChuPay基本料金2',
      requestAmount: '6,300円',
      requestLetter: 'CSV',
      status: '決済済み',
    },
  ]); // 总数据
  const [data, setData] = useState<any>([]); // 数据列表
  const [searchParams, setSearchParams] = useState<any>({
    pageNum: 1,
    pageSize: 10,
  });
  const [tableTotal, setTableTotal] = useState<number>(0);
  // 处理页码
  const changePage = (sumPage: any, size?: number | string) => {
    page = [];
    let num = Math.ceil(sumPage / Number(size ? size : pageSize));
    for (let i = 1; i < num + 1; i++) {
      page.push(i);
    }
    let newPage = JSON.parse(JSON.stringify([...page]));
    setPage(newPage);
  };
  // 获取当前页码的列表
  const getPageList = async (page: any) => {
    setIsLoading(true);
    let newArr = arr.slice((page - 1) * pageSize, page * pageSize);
    setData(newArr);
    setNowPage(page);
    setIsLoading(false);
  };
  // 改变当前显示数量
  const changePageSize = (size: string | number) => {
    setPageSize(size);
    changePage(arr.length, size);
    let newArr = arr.slice((nowPage - 1) * Number(size), nowPage * Number(size));
    setData(newArr);
  };
  // 改变选中列表
  const changeSelectList = (obj: any) => {
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = [];
        data.forEach((item: any) => {
          arr.push(item.id);
        });
        setSelectList(arr);
      } else {
        selectedList.push(obj.id);
        let newSelectedList = JSON.parse(JSON.stringify(selectedList));
        setSelectList(newSelectedList);
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([]);
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id;
        });
        selectedList.splice(idx, 1);
        let newSelectedList = JSON.parse(JSON.stringify(selectedList));
        setSelectList(newSelectedList);
      }
    }
  };
  // 获取列表数据
  const getAllList = () => {
    changePage(arr.length);
    let newArr = arr.slice((nowPage - 1) * pageSize, nowPage * pageSize);
    userContractListGet({
      ...searchParams,
    }, headers).then((res: any) => {
      setData(res.rows || []);
      setTableTotal(res.total || 0);
    });
  };
  const getData = (pageNum: any, pageSize: any) => {
    userContractListGet({
      // ...searchParams,
      pageNum,
      pageSize,
    },headers).then((res: any) => {
      console.log(res);
      res.rows = res.rows.map((item: any) => {
        // item.create_at = item.create_at.split('T')[0];
        // item.statusName = item.status === 'actived' ? 'アクティブ' : item.status === 'pause' ? '一時停止' : '終了';
        if (item.plan_info) item.plan_info = JSON.parse(item.plan_info)
        return item;
      });
      console.log(res.rows);
      setData(res.rows || []);
      setTableTotal(res.total || 0);
    });
  };
  
  const [parameters, setParameters] = useState<any>([])
  const getParameter = async () => {
    const res: any = await parameterStoreGet({ names: ['/Define/SubscriptionsPayment/Status'] }, {})
    for (const key in res) {
      // JSON.parseしてエラーだったらそのまま
      try {
        res[key] = JSON.parse(res[key])
      } catch (error) {
        res[key] = res[key]
      }
    }
    console.log('getParameter', res)
    setParameters(res)
  }
  useEffect(() => {
    getParameter()
    getData(1, 10);
  }, [searchParams]);


  const strLength = (str: string, length = 20) => {
    if (!str) return '';

    if (str.length > length) {
      return str.substring(0, length) + '...';
    }

    return str;

  };

  return (
    <div className="card mb-6 shadow-sm">
      <div className="d-flex align-items-center card-header">
        <span className="card-title fw-bold m-0">請求情報</span>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="table-responsive pb-10">
            <table className="table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer">
              <thead>
                <tr className="text-start fs-6 text-uppercase gs-0">
                  {/* <th style={{ minWidth: '60px' }}>ID</th> */}
                  <th style={{ minWidth: '70px' }}>発行日</th>
                  <th style={{ minWidth: '70px' }}>内容</th>
                  <th style={{ minWidth: '80px' }}>請求金額</th>
                  {/* <th style={{minWidth: '70px'}}>請求書</th> */}
                  <th style={{ minWidth: '100px' }}>ステータス</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        {/* <td>{strLength(item.plan_id)}</td> */}
                        {/* <td>{item.create_at}</td> */}
                        {/* <td>{item.create_at.slice(0, 10)+'  '+item.create_at.slice(11, 19)}</td> */}
                        <td>{moment(item.create_at).format('YYYY-MM-DD')}</td>
                        <td>{item.plan_info.title}</td>
                        <td>{item.amount || 0}円</td>
                        {/* <td>{item.requestLetter}</td> */}
                        <td>{parameters['/Define/SubscriptionsPayment/Status']?.find((k:any)=> k.value == item.status)?.label}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>
                      <div className="d-flex text-center w-100 align-content-center justify-content-center">
                        {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="pagination-box d-flex">
              {/* <select */}
              {/*  className="form-select " */}
              {/*  data-control="select2" */}
              {/*  style={{ width: 80, left: 20, bottom: 10 }} */}
              {/*  value={searchParams.pageSize} */}
              {/*  onChange={(e) => { */}
              {/*    setSearchParams({ */}
              {/*      pageNum: e.target.value === searchParams.pageSize ? searchParams.pageNum : 1, */}
              {/*      pageSize: e.target.value, */}
              {/*    }); */}
              {/*  }} */}
              {/* > */}
              {/*  <option value={5}>5</option> */}
              {/*  <option value={10}>10</option> */}
              {/*  <option value={20}>20</option> */}
              {/*  <option value={50}>50</option> */}
              {/*  <option value={100}>100</option> */}
              {/* </select> */}
              {/* page */}
              <div className="pagination d-flex">
                {/* <Pagination */}
                {/*  current={searchParams.pageNum} */}
                {/*  pageSize={searchParams.pageSize} */}
                {/*  total={tableTotal} */}
                {/*  onChange={(page, pageSize) => { */}
                {/*    setSearchParams({ */}
                {/*      pageNum: pageSize === searchParams.pageSize ? page : 1, */}
                {/*      pageSize: pageSize, */}
                {/*    }); */}
                {/*  }} */}
                {/* /> */}
                <div style={{ marginTop: '20px' }}>
                  <Pagination
                    currentPage={getData}
                    page={searchParams.pageNum}
                    pageSize={searchParams.pageSize}
                    total={tableTotal}
                  />
                </div>
              </div>
            </div>
            {isLoading && <UsersListLoading></UsersListLoading>}
          </div>
        </div>
      </div>
    </div>
  );
}
