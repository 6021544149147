import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '@/_metronic/helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAlert} from 'src/_metronic/layout/core'
import {getAddress, registerAddress, updateAddress} from 'src/app/modules/api'
import {parameterStoreGet} from 'src/app/modules/api'

interface IProps {
  showModal: any
  handleClose: Function
  apply: Function
  pageData: any
}

const ShippingAddressModal: React.FC<IProps> = ({
  showModal,
  handleClose: close,
  apply,
  pageData,
}) => {
  const {setMessage} = useAlert()
  const [loading, setLoading] = useState<boolean>(false)

  const [parameters, setParameters] = useState<any>([])

  const getParameter = async () => {
    const res: any = await parameterStoreGet({names: ['/Define/General/Prefecture']}, {})
    const parameters = JSON.parse(res['/Define/General/Prefecture'])
    console.log('parameters', res, parameters)
    setParameters(parameters)
  }

  useEffect(() => {
    console.log(initialValues1, '====', pageData, '===')
    getParameter()

    if (pageData?.id) {
      formikAddress.resetForm({
        values: {
          lastname: pageData.lastname,
          firstname: pageData.firstname,
          lastname_kana: pageData.lastname_kana,
          firstname_kana: pageData.firstname_kana,
          address_postal_code: pageData.address_postal_code,
          address_prefecture: pageData.address_prefecture,
          address_city: pageData.address_city,
          address_street: pageData.address_street,
          address_building_name: pageData.address_building_name,
          company_name: pageData.company_name,
          company_name_kana: pageData.company_name_kana,
          tel: pageData.tel,
          fax: pageData.fax,
          defaultStatu: pageData.default == 'true' ? true : false,
        },
      })
    } else {
    }
  }, [showModal])

  const validationSchema1 = Yup.object({
    firstname: Yup.string()
      .required('名は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(255, '255文字以下で入力してください。'),
    lastname: Yup.string()
      .required('姓は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(255, '255文字以下で入力してください。'),
    lastname_kana: Yup.string()
      .required('姓（フリガナ）は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(255, '255文字以下で入力してください。'),
    firstname_kana: Yup.string()
      .required('名（フリガナ）は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(255, '255文字以下で入力してください。'),
    address_postal_code: Yup.string()
      .required('郵便番号は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(7, '最大7文字'),
    address_prefecture: Yup.string().required('都道府県は必須です。'),
    address_city: Yup.string()
      .required('市区町村は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(255, '255文字以下で入力してください。'),
    address_street: Yup.string()
      .required('丁目番地は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(255, '255文字以下で入力してください。'),
    // address_building_name: Yup.string()
    //   .required('丁目番地は必須です。')
    //   .min(1, '1文字以上で入力してください。')
    //   .max(255, '255文字以下で入力してください。'),
    company_name: Yup.string()
      .required('会社名・店名は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(255, '255文字以下で入力してください。'),
    company_name_kana: Yup.string()
      .required('会社名・店名（カタカナ）は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(255, '255文字以下で入力してください。'),
    tel: Yup.string()
      .required('電話は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(13, '13文字以下で入力してください。'),
    fax: Yup.string()
      // .required('ファックスは必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(13, '13文字以下で入力してください。'),
  })
  const [initialValues1, setInitialValues1] = useState<any>({
    // create_at
    // update_at
    // update_user
    // buyer_id
    // id
    lastname: '',
    firstname: '',
    lastname_kana: '',
    firstname_kana: '',
    address_postal_code: '',
    address_prefecture: '',
    address_city: '',
    address_street: '',
    address_building_name: '',
    company_name: '',
    company_name_kana: '',
    tel: '',
    fax: '',
    defaultStatu: false,
    // user_id
  }) // 地址
  // 添加地址表单
  const formikAddress: any = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,
    onSubmit: (values: any, {setSubmitting}: any) => {
      setSubmitting(true)
      setLoading(true)
      values.defaultStatu = values.defaultStatu ? 'true' : 'false'
      if (pageData?.id) {
        updateAddress({...values, id: pageData.id, pkVal: pageData.pk})
          .then((val: any) => {
            setLoading(false)
            setMessage({type: 'success', message: '更新しました'})
            handleClose()
            apply()
          })
          .catch((err) => {
            setLoading(false)
            setMessage({type: 'error', message: '更新に失敗しました'})
          })
      } else {
        registerAddress({...values}, {})
          .then((val: any) => {
            setLoading(false)
            setMessage({type: 'success', message: '更新しました'})
            handleClose()
            apply()
          })
          .catch((err) => {
            setLoading(false)
            setMessage({type: 'error', message: '更新に失敗しました'})
          })
      }
    },
  })

  const handleClose = () => {
    formikAddress.resetForm({
      values: {
        lastname: '',
        firstname: '',
        lastname_kana: '',
        firstname_kana: '',
        address_postal_code: '',
        address_prefecture: '',
        address_city: '',
        address_street: '',
        address_building_name: '',
        company_name: '',
        company_name_kana: '',
        tel: '',
        fax: '',
        defaultStatu: false,
      },
    })
    close()
  }

  return (
    <Modal
      show={showModal}
      onHide={() => handleClose}
      dialogClassName='modal-dialog-centered mw-650px'
    >
      <div className='modal-header'>
        <h5 className='modal-title'>
          {/* {pageData && pageData.id ? 'Edit Address' : 'Add New Address'} */}
          {pageData && pageData.id ? '配送先を編集' : '新しい配送先を追加'}
        </h5>
        <div
          className='btn btn-icon btn-sm btn-active-light-primary ms-2'
          onClick={() => {
            handleClose()
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
        </div>
      </div>
      <div className='modal-body scroll-y p-2'>
        <form
          className='form fv-plugins-bootstrap5 fv-plugins-framework'
          onSubmit={formikAddress.handleSubmit}
          noValidate
        >
          <div className='modal-body'>
            <div
              className='scroll-y me-n7 pe-7'
              id='kt_modal_new_address_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_new_address_header'
              data-kt-scroll-wrappers='#kt_modal_new_address_scroll'
              data-kt-scroll-offset='300px'
              style={{maxHeight: '439px'}}
              data-select2-id='select2-data-kt_modal_new_address_scroll'
            >
              <div className='row mb-5'>
                <div className='col-md-6 fv-row fv-plugins-icon-container'>
                  <label className='required fs-5 fw-semibold mb-2'>姓</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    name='last-name'
                    {...formikAddress.getFieldProps('lastname')}
                    value={formikAddress.values.lastname}
                  />
                  {formikAddress.touched.lastname && formikAddress.errors.lastname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikAddress.errors.lastname}</div>
                    </div>
                  )}

                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </div>

                <div className='col-md-6 fv-row fv-plugins-icon-container'>
                  <label className='required fs-5 fw-semibold mb-2'>名</label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    name='last-name'
                    {...formikAddress.getFieldProps('firstname')}
                    value={formikAddress.values.firstname}
                  />
                  {formikAddress.touched.firstname && formikAddress.errors.firstname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikAddress.errors.firstname}</div>
                    </div>
                  )}
                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </div>
              </div>

              <div className='row mb-5'>
                <div className='col-md-6 fv-row fv-plugins-icon-container'>
                  <label className='required fs-5 fw-semibold mb-2'>姓（フリガナ）</label>

                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    name='first-name'
                    {...formikAddress.getFieldProps('lastname_kana')}
                    value={formikAddress.values.lastname_kana}
                  />
                  {formikAddress.touched.lastname_kana && formikAddress.errors.lastname_kana && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikAddress.errors.lastname_kana}</div>
                    </div>
                  )}
                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </div>

                <div className='col-md-6 fv-row fv-plugins-icon-container'>
                  <label className='required fs-5 fw-semibold mb-2'>名（フリガナ）</label>

                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    name='first-name'
                    {...formikAddress.getFieldProps('firstname_kana')}
                    value={formikAddress.values.firstname_kana}
                  />
                  {formikAddress.touched.firstname_kana && formikAddress.errors.firstname_kana && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikAddress.errors.firstname_kana}</div>
                    </div>
                  )}
                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </div>
              </div>

              <div className='col-md-12 fv-row fv-plugins-icon-container'>
                <label className='required fs-5 fw-semibold mb-2'>郵便番号</label>

                <input
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='first-name'
                  {...formikAddress.getFieldProps('address_postal_code')}
                  value={formikAddress.values.address_postal_code}
                />
                {formikAddress.touched.address_postal_code &&
                  formikAddress.errors.address_postal_code && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        {formikAddress.errors.address_postal_code}
                      </div>
                    </div>
                  )}
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
              </div>

              <div
                className='d-flex flex-column mb-5 fv-row fv-plugins-icon-container'
                data-select2-id='select2-data-360-alyz'
              >
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>都道府県</span>
                </label>

                <select
                  name='country'
                  data-control='select2'
                  data-placeholder='Select a Country...'
                  className='form-select select2-hidden-accessible'
                  {...formikAddress.getFieldProps('address_prefecture')}
                >
                  <option value={''}></option>
                  {parameters?.map((item: any, idx: number) => {
                    return (
                      <option key={idx} value={item.value} data-select2-id='select2-data-358-cnul'>
                        {item.label}
                      </option>
                    )
                  })}
                </select>
                {formikAddress.touched.address_prefecture &&
                  formikAddress.errors.address_prefecture && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikAddress.errors.address_prefecture}</div>
                    </div>
                  )}

                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
              </div>

              <div className='col-md-12 fv-row fv-plugins-icon-container'>
                <label className='required fs-5 fw-semibold mb-2'>市区町村</label>

                <input
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='first-name'
                  {...formikAddress.getFieldProps('address_city')}
                  value={formikAddress.values.address_city}
                />
                {formikAddress.touched.address_city && formikAddress.errors.address_city && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formikAddress.errors.address_city}</div>
                  </div>
                )}
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
              </div>

              <div className='col-md-12 fv-row fv-plugins-icon-container'>
                <label className='required fs-5 fw-semibold mb-2'>丁目番地</label>

                <input
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='first-name'
                  {...formikAddress.getFieldProps('address_street')}
                  value={formikAddress.values.address_street}
                />
                {formikAddress.touched.address_street && formikAddress.errors.address_street && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formikAddress.errors.address_street}</div>
                  </div>
                )}
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
              </div>

              <div className='col-md-12 fv-row fv-plugins-icon-container'>
                <label className='fs-5 fw-semibold mb-2'>建物名</label>

                <input
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='first-name'
                  {...formikAddress.getFieldProps('address_building_name')}
                  value={formikAddress.values.address_building_name}
                />

                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
              </div>

              <div className='col-md-12 fv-row fv-plugins-icon-container'>
                <label className='required fs-5 fw-semibold mb-2'>会社名・店名</label>

                <input
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='first-name'
                  {...formikAddress.getFieldProps('company_name')}
                  value={formikAddress.values.company_name}
                />
                {formikAddress.touched.company_name && formikAddress.errors.company_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formikAddress.errors.company_name}</div>
                  </div>
                )}
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
              </div>

              <div className='col-md-12 fv-row fv-plugins-icon-container'>
                <label className='required fs-5 fw-semibold mb-2'>会社名・店名（カタカナ）</label>

                <input
                  type='text'
                  className='form-control'
                  placeholder=''
                  name='first-name'
                  {...formikAddress.getFieldProps('company_name_kana')}
                  value={formikAddress.values.company_name_kana}
                />
                {formikAddress.touched.company_name_kana && formikAddress.errors.company_name_kana && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formikAddress.errors.company_name_kana}</div>
                  </div>
                )}
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
              </div>

              <div className='row mb-5'>
                <div className='col-md-6 fv-row fv-plugins-icon-container'>
                  <label className='required fs-5 fw-semibold mb-2'>電話番号</label>

                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    name='first-name'
                    {...formikAddress.getFieldProps('tel')}
                    value={formikAddress.values.tel}
                  />
                  {formikAddress.touched.tel && formikAddress.errors.tel && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikAddress.errors.tel}</div>
                    </div>
                  )}
                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </div>

                <div className='col-md-6 fv-row fv-plugins-icon-container'>
                  <label className='fs-5 fw-semibold mb-2'>FAX</label>

                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    name='first-name'
                    {...formikAddress.getFieldProps('fax')}
                    value={formikAddress.values.fax}
                  />
                  {formikAddress.touched.fax && formikAddress.errors.fax && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formikAddress.errors.fax}</div>
                    </div>
                  )}
                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </div>
              </div>
              <div className='row mb-5'>
                <label>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    name='default'
                    value={formikAddress.values.defaultStatu}
                    {...formikAddress.getFieldProps('defaultStatu')}
                    checked={formikAddress.values.defaultStatu == true}
                  />
                  <label>この住所をデフォルトにする</label>
                </label>
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button className='btn btn-lightbtn-active-light-primary me-2' onClick={handleClose}>
              キャンセル
            </button>
            <button
              type='submit'
              disabled={loading}
              className='btn btn-primary'
              data-kt-indicator={loading ? 'on' : 'off'}
            >
              {pageData?.id && <span className='indicator-label'>更新</span>}
              {!pageData?.id && <span className='indicator-label'>登録</span>}
              <span className='indicator-progress'>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default ShippingAddressModal
