import { getServer, postServer, deleteServer } from 'src/service/http'

// CSV 登録する
export function dataImport(param: object) {
    return postServer(`/data-import`, { ...param })
}

// インポート履歴
export function dataImportHistoryGet(param: object, headers: object = {}) {
    return getServer(`/data-import-history-get`, { ...param },{...headers})
}