import {useState, ChangeEvent, SetStateAction, useEffect} from 'react'
import {useParams, useNavigate, useLocation} from 'react-router-dom'
import {
  useFormik,
  FormikProvider,
  Form,
  useFormikContext,
  useField,
  FieldArray,
  FieldHookConfig,
} from 'formik'
import * as Yup from 'yup'
import {showWarningMessage} from '@/app/components/sweetAlert'
import {useAlert} from 'src/_metronic/layout/core'
import {
  productVariationInfoGet,
  productVariationInfoCopy,
  productVariationInfoUpdate,
  productVariationInfoDelete,
  productGroupGet,
  customerRankListGet,
  productCustomItemListGet,
} from 'src/app/modules/api'
import {getCommonDetails, parameterStoreGet, taxSettingGet} from 'src/app/modules/api'
import {useLayout} from 'src/_metronic/layout/core'
import {useAuth} from 'src/app/modules/auth'
import PreviewModal from './PreviewModal'

const validationSchema = Yup.object().shape({
  variation_product_name: Yup.string().required('バリエーション商品名は必須です。'),
  sku: Yup.string().required('バリエーション商品名は必須です。'),
})

const ztstatusOptions: ztstatusOptions[] = [
  {id: 'publish', label: '表示'},
  {id: 'hidden', label: '非表示'},
]
const compileTypeOptions: unitOptions[] = [
  {id: '1', label: 'コピーを作成する'},
  {id: '2', label: '編集'},
  {id: '3', label: '削除'},
]
const itemsInTheAncientPeriodOptions: unitOptions[] = [
  {id: '1', label: '非表示'},
  {id: '2', label: '公開済'},
  {id: '3', label: 'オープン'},
  {id: '4', label: '参考上代'},
  {id: '5', label: 'メーカー希望小売価格'},
  {id: '6', label: 'カタログ価格'},
  {id: '7', label: 'カスタム'},
  {id: '8', label: '単価表示'},
]
const representMethodsInLibrariesOptions: unitOptions[] = [
  {id: 'publish', label: '数字'},
  {id: 'hidden', label: '△☓方式'},
]
const shippingAndShippingRegulationsOptions: unitOptions[] = [
  {id: '1', label: '荷扱い'},
  {id: '2', label: '精密機器'},
  {id: '3', label: 'ワレ注意'},
  {id: '4', label: '下積現金'},
  {id: '5', label: '天地無用'},
  {id: '6', label: 'ナマモノ'},
  {id: '7', label: '水濡厳禁'},
]
const paymentRestrictionOptions: unitOptions[] = [
  {id: 'credit_card', label: 'クレジットカード'},
  {id: 'bank_transfer', label: '銀行振込'},
  {id: 'bill_payment', label: '請求書払い'},
]

export function ProductVariation() {
  const {auth} = useAuth()
  const {setContentLoading} = useLayout()
  const location = useLocation()
  const navigate = useNavigate()
  const {setMessage} = useAlert()
  const {id} = useParams()
  const [modalShow, setModalShow] = useState(false)
  const [compileType, setCompileType] = useState<any>(0) // 编辑、新增状态
  const [productVariationList, setProductVariationList] = useState<any>([])
  const [queryParams, setQueryParams] = useState<any>({
    product_id: id,
    page: 1,
    pageSize: 10,
    total: 0,
  })

  const [parameters, setParameters] = useState<any>([])
  const [deliveryGroupOptions, setDeliveryGroupOptions] = useState<any>([])
  const [productCustomFieldOptions, setproductCustomFieldOptions] = useState<any>([])
  const [taxDifferentiationOptions, setTaxDifferentiationOptions] = useState<any>([])
  const [customerRankList, setcustomerRankList] = useState<any>([])

  const [selectedAll, setSelectedAll] = useState(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([]) // 保存选中项的状态数组

  const formData = {
    product_id: id,
    variation_id: '',
    variation_product_name: '',
    sku: '',
    jan_code: '',
    location: '',
    product_custom_field: [{id: '', value: ''}],
    label: [''],
    suggested_retail_price_item_name: '',
    suggested_retail_price: '',
    minimum_order_quantity: '',
    maximum_order_quantity: '',
    stock_quantity: '',
    unlimited_stock_quantity: false,
    number_per_case: '',
    unit: '',
    weight: '',
    weight_unit: 'kg',
    stock_display: 'publish',
    stock_display_method: 'publish',
    low_stock_threshold: '0',
    shipping_group: '',
    shipping_fee_policy: '1',
    shipping_fee_regulations: '',
    payment_restriction: ['credit_card'],
    tax_category: '',
    display_method: false,
    price_range: 'unit_price',
    price_range_min: '',
    price_range_max: '',
    unit_price: '',
    quantity_discount: [{unit: '', price: ''}],
    customer_rank_specific_pricing: [],
    special_price: [{id: '', price: ''}],
    status: 'publish',
    product_hidden_customer_rank: [],
    exception_display_member_id: '',
    display_order: '',
    variation_main_image: '',
    variation_sub_images: '',
    pk: '',
  }

  const getParameterStore = async () => {
    let res: any = await parameterStoreGet({names: ['/Define/Product/Units']})
    // console.log("parameters", JSON.parse(res['/Define/Product/Units']))
    setParameters(res)
  }

  const initData = async () => {
    setContentLoading(true)
    getProductVariationInfo(queryParams.page, queryParams.pageSize)

    const productCustomItemList: any = await productCustomItemListGet({
      type: 'variation',
      pageNum: 1,
      pageSize: 100,
    })
    setproductCustomFieldOptions(productCustomItemList.rows)

    const productGroup = await productGroupGet({})
    setDeliveryGroupOptions(productGroup)

    const customerRankList: any = await customerRankListGet({})

    customerRankList.rows.forEach((item: any) => {
      item.individualPricing = ''
      item.individualPricingMin = ''
      item.individualPricingMax = ''
      item.price = ''
      item.price_range_min = ''
      item.price_range_max = ''
      item.quantity_discount = []
    })

    setcustomerRankList(customerRankList.rows)
    // console.log('customerRankList', customerRankList)

    const taxSetting: any = await taxSettingGet({pk: auth.group_id})
    // console.log('taxSetting', taxSetting)
    if (taxSetting && taxSetting.length > 0) {
      setTaxDifferentiationOptions(taxSetting[0].taxSettingDetailList)
    }
  }

  // 商品バリエーション情報取得
  const getProductVariationInfo = async (pageNum: any, pageSize: any) => {
    // console.log('pageNum', pageNum, pageSize, id)
    // const {id} = useParams()
    // console.log(id)
    setContentLoading(true)
    try {
      const res: any = await productVariationInfoGet({
        pageNum,
        pageSize,
        product_id: id,
      })
      // res.rows.unlimited_stock_quantity = res.rows.unlimited_stock_quantity == 'yes' ? true : false
      // console.log('res==', res)
      setQueryParams({...queryParams, total: res.total})
      setProductVariationList(res.rows)
      setContentLoading(false)
    } catch (error) {
      setContentLoading(false)
      console.error('error', error)
    }
  }

  // 商品バリエーションを削除する
  const deleteProductVariation = (item: any) => {
    // console.log('deleteProductVariation', item)
    showWarningMessage(
      '元に戻すことはできません。よろしいでしょうか？',
      () => {
        productVariationInfoDelete({pkVal: item.pk}).then((res: any) => {
          // console.log('res', res)
          // console.log('compileType', compileType)
          initData()
          setMessage({type: 'success', message: '削除しました'})
        })
      },
      '削除',
      'キャンセル'
    )
  }

  useEffect(() => {
    initData()
    getParameterStore()
  }, [])

  useEffect(() => {
    console.log('productVariationList', productVariationList)
  }, [productVariationList])

  // 顧客ランク別特別価格
  const handleInputChange = (itemValue: any) => {
    setcustomerRankList((prevTable: any[]) => {
      const updatedTable = prevTable.map((item: any) => {
        if (item.group_id === itemValue.group_id) {
          return {
            ...item,
            individualPricing: itemValue.price,
            individualPricingMin: itemValue.price_range_min,
            individualPricingMax: itemValue.price_range_max,
          }
        }
        return item
      })
      formik.values.customer_rank_specific_pricing = updatedTable.map((e: any) => ({
        id: e.group_id,
        price: e.individualPricing || '',
        price_range_min: e.individualPricingMin || '',
        price_range_max: e.individualPricingMax || '',
        quantity_discount: e.quantity_discount,
      }))
      updateFormData(formik.values)
      // console.log('updatedTable', updatedTable, formik.values.customer_rank_specific_pricing)
      return updatedTable
    })
  }

  const handleSelectAll = (e: any) => {
    // console.log('e.target.checked', e.target.checked)
    let arr = []

    if (e.target.checked) {
      // 如果之前没有全选，将所有项添加到选中项数组中
      arr = customerRankList.map((item: any) => item.group_id)
      setSelectedItems(arr)
    } else {
      // 如果之前已经全选，清空选中项数组
      setSelectedItems([])
    }

    setSelectedAll(e.target.checked)

    formik.values.product_hidden_customer_rank = arr
    updateFormData(formik.values)
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, itemId: string) => {
    const checked = e.target.checked
    let newSelectedItems: string[] = []

    if (checked) {
      newSelectedItems = [...selectedItems, itemId]
    } else {
      newSelectedItems = selectedItems.filter((id) => id !== itemId)
    }

    // 检查是否选中了所有项
    const allItemsSelected = newSelectedItems.length === customerRankList.length
    setSelectedItems(JSON.parse(JSON.stringify(newSelectedItems)))
    setSelectedAll(allItemsSelected)

    formik.values.product_hidden_customer_rank = newSelectedItems
    updateFormData(formik.values)
  }

  const updateFormData = (fieldsToUpdate: Partial<any>) => {
    // console.log('fieldsToUpdate', fieldsToUpdate)
    // label
    if (fieldsToUpdate.label && !Array.isArray(fieldsToUpdate.label))
      fieldsToUpdate.label.split(',')
    // product_hidden_customer_rank
    if (
      fieldsToUpdate.product_hidden_customer_rank &&
      !Array.isArray(fieldsToUpdate.product_hidden_customer_rank)
    )
      fieldsToUpdate.product_hidden_customer_rank =
        fieldsToUpdate.product_hidden_customer_rank.split(',')
    // payment_restriction
    if (fieldsToUpdate.payment_restriction && !Array.isArray(fieldsToUpdate.payment_restriction))
      fieldsToUpdate.payment_restriction = fieldsToUpdate.payment_restriction.split(',')
    // product_custom_field
    if (
      fieldsToUpdate.product_custom_field &&
      typeof fieldsToUpdate.product_custom_field !== 'object'
    )
      fieldsToUpdate.product_custom_field = JSON.parse(fieldsToUpdate.product_custom_field) || []
    // quantity_discount
    if (fieldsToUpdate.quantity_discount && typeof fieldsToUpdate.quantity_discount !== 'object')
      fieldsToUpdate.quantity_discount = JSON.parse(fieldsToUpdate.quantity_discount) || []
    // customer_rank_specific_pricing
    if (
      fieldsToUpdate.customer_rank_specific_pricing &&
      typeof fieldsToUpdate.customer_rank_specific_pricing !== 'object'
    )
      fieldsToUpdate.customer_rank_specific_pricing =
        JSON.parse(fieldsToUpdate.customer_rank_specific_pricing) || []
    // special_price
    if (fieldsToUpdate.special_price && typeof fieldsToUpdate.special_price !== 'object')
      fieldsToUpdate.special_price = JSON.parse(fieldsToUpdate.special_price) || []

    // customerRankList に値を設定
    setcustomerRankList(
      customerRankList.map((item: any) => {
        fieldsToUpdate.customer_rank_specific_pricing.forEach((e: any) => {
          if (item.group_id === e.id) {
            item.individualPricing = e.price
            item.individualPricingMin = e.price_range_min
            item.individualPricingMax = e.price_range_max
            item.price = e.price
            item.price_range_min = e.price_range_min
            item.price_range_max = e.price_range_max
            item.quantity_discount = e.quantity_discount
          }
        })
        return item
      })
    )

    // selectedItems に値を設定
    setSelectedItems([...selectedItems, ...fieldsToUpdate.product_hidden_customer_rank])

    const updatedData = {...formData, ...fieldsToUpdate}
    formik.setValues(updatedData)
  }

  const formik: any = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      // console.log('values======', values)
      let obj = {
        ...values,
        pkVal: values.pk,
        label: Array.isArray(values.label) ? values.label.join(',') : values.label,
        product_hidden_customer_rank: Array.isArray(values.product_hidden_customer_rank)
          ? values.product_hidden_customer_rank.join(',')
          : values.product_hidden_customer_rank,
        payment_restriction: Array.isArray(values.payment_restriction)
          ? values.payment_restriction.join(',')
          : values.payment_restriction,
        product_custom_field:
          typeof values.product_custom_field === 'object'
            ? JSON.stringify(values.product_custom_field)
            : values.product_custom_field,
        quantity_discount:
          typeof values.quantity_discount === 'object'
            ? JSON.stringify(values.quantity_discount)
            : values.quantity_discount,
        customer_rank_specific_pricing:
          typeof values.customer_rank_specific_pricing === 'object'
            ? JSON.stringify(values.customer_rank_specific_pricing)
            : values.customer_rank_specific_pricing,
        special_price:
          typeof values.special_price === 'object'
            ? JSON.stringify(values.special_price)
            : values.special_price,
        // unlimited_stock_quantity: values.unlimited_stock_quantity.toString(),
        // display_method: values.display_method.toString(),
        // price_range: values.price_range.toString(),
        product_id: id,
      }

      // console.log('obj', obj)
      setSubmitting(true)

      switch (compileType) {
        // 1: コピーを作成する
        case '1':
          productVariationInfoCopy(obj)
            .then((res: any) => {
              setSubmitting(false)
              setMessage({type: 'success', message: res.message})
              initData()
            })
            .catch((err: any) => {
              setContentLoading(false)
            })
          break
        case '2':
          // 2: 編集
          productVariationInfoUpdate(obj)
            .then((res: any) => {
              setSubmitting(false)
              setMessage({type: 'success', message: res.message})
            })
            .catch((err: any) => {
              setContentLoading(false)
            })
          break
        default:
          productVariationInfoCopy(obj)
            .then((res: any) => {
              if (res.message === 'SKUが重複しています') {
                setMessage({type: 'error', message: res.message})
              } else {
                setMessage({type: 'success', message: res.message})
                initData()
              }
            })
            .catch((err: any) => {
              setContentLoading(false)
            })
      }
    },
  })

  return (
    <div className='container-fluid p-0'>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1 className='fw-bolder fs-6 '>バリエーション商品一覧</h1>
      </div> */}

      {/* <form className='my-3' onSubmit={formik.handleSubmit} noValidate> */}
      <FormikProvider value={formik}>
        <Form>
          {/* マッピング設定 */}
          <div className='card mb-5'>
            <div className='card-body'>
              {/* 商品セット非表示顧客ランク */}
              <div className='row'>
                <div className='col align-self-center w-50'>
                  <div className='table-responsive'>
                    <table
                      className='table table-bordered'
                      style={{borderCollapse: 'collapse', border: '1px solid #c7c7c7'}}
                    >
                      <thead className='text-center' style={{backgroundColor: '#7f7f7f'}}>
                        <tr>
                          <th scope='col' className='text-white' style={{minWidth: '50px'}}>
                            ID
                          </th>
                          <th scope='col' className='text-white' style={{minWidth: '200px'}}>
                            バリエーション商品名
                          </th>
                          <th scope='col' className='text-white' style={{minWidth: '100px'}}>
                            SKU
                          </th>
                          <th scope='col' className='text-white' style={{minWidth: '100px'}}>
                            単価
                          </th>
                          <th scope='col' className='text-white' style={{minWidth: '60px'}}>
                            在庫
                          </th>
                          <th scope='col' className='text-white' style={{minWidth: '60px'}}>
                            状態
                          </th>
                          <th scope='col' className='text-white' style={{minWidth: '100px'}}>
                            操作
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {productVariationList?.map((item: any, index: number) => (
                          <tr key={index} className='text-center align-middle'>
                            <td
                              style={{
                                border: '1px solid #c7c7c7',
                                borderColor: '#c7c7c7 !important',
                              }}
                            >
                              {Number(index) + 1}
                            </td>
                            <td
                              style={{
                                border: '1px solid #c7c7c7',
                                borderColor: '#c7c7c7 !important',
                              }}
                            >
                              {item.variation_product_name}
                            </td>
                            <td
                              style={{
                                border: '1px solid #c7c7c7',
                                borderColor: '#c7c7c7 !important',
                              }}
                            >
                              {item.sku}
                            </td>
                            <td
                              style={{
                                border: '1px solid #c7c7c7',
                                borderColor: '#c7c7c7 !important',
                              }}
                            >
                              {item.price_range == 'unit_price'
                                ? item.unit_price
                                : item.price_range_min && item.price_range_max
                                ? item.price_range_min + ' ~ ' + item.price_range_max
                                : '0'}
                            </td>
                            <td
                              style={{
                                border: '1px solid #c7c7c7',
                                borderColor: '#c7c7c7 !important',
                              }}
                            >
                              {item.stock_quantity}
                            </td>
                            <td
                              style={{
                                border: '1px solid #c7c7c7',
                                borderColor: '#c7c7c7 !important',
                              }}
                            >
                              {ztstatusOptions.find((option) => option.id === item.status)?.label}
                            </td>
                            <td
                              style={{
                                border: '1px solid #c7c7c7',
                                borderColor: '#c7c7c7 !important',
                              }}
                            >
                              <select
                                onChange={(e) => {
                                  setCompileType(e.target.value)
                                  // 他の行のsetCompileTypeを解除
                                  productVariationList.forEach((i: any) => {
                                    if (i.pk != item.pk) {
                                      i.compile = ''
                                    } else {
                                      i.compile = e.target.value
                                    }
                                  })

                                  if (e.target.value == '1' || e.target.value == '2') {
                                    // console.log('item', item)
                                    updateFormData(item)
                                  } else if (e.target.value == '3') {
                                    deleteProductVariation(item)
                                  } else {
                                    initData()
                                  }
                                }}
                                value={item.compile}
                                className='form-select'
                              >
                                <option value={''}></option>
                                {compileTypeOptions.map((option: any, index: number) => (
                                  <option key={index} value={option.id}>
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title'>バリエーション商品情報登録</div>
            </div>
            <div className='card-body'>
              {/* 商品名 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className='required'>バリエーション商品名</span>
                </label>

                <div className='col-lg-9 align-self-center'>
                  <input
                    className='form-control'
                    type='text'
                    {...formik.getFieldProps('variation_product_name')}
                  />
                  {formik.touched.variation_product_name && formik.errors.variation_product_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.variation_product_name}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* 品番 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className='required'>SKU</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <input
                    className='form-control'
                    type='text'
                    disabled={Boolean(compileType == '2')}
                    {...formik.getFieldProps('sku')}
                    value={formik.values.sku}
                  />
                </div>
              </div>

              {/* JANコード */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span>JANコード</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <input
                    className='form-control'
                    type='text'
                    {...formik.getFieldProps('jan_code')}
                  />
                </div>
              </div>

              {/* 重量 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span>重量</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <div className='d-flex flex-wrap'>
                    <div className='col-lg-6'>
                      <input
                        className='form-control'
                        type='text'
                        {...formik.getFieldProps('weight')}
                      />
                    </div>
                    <div className='col-lg-6'>
                      <div className='flex-root ms-5 d-flex' style={{alignItems: 'center'}}>
                        <label className='col-form-label ' style={{minWidth: '65px'}}>
                          単位
                        </label>
                        <div className='align-self-center'>
                          <div style={{display: 'flex', alignItems: 'center', minWidth: '60px'}}>
                            <select
                              className='form-select'
                              data-control='select2'
                              placeholder='選択してください'
                              {...formik.getFieldProps('weight_unit')}
                              onChange={(e) => {
                                // console.log(e.target.value)
                                formik.values.weight_unit = e.target.value
                                updateFormData(formik.values)
                              }}
                            >
                              <option value=''>選択してください</option>
                              {parameters['/Define/Product/Units'] &&
                                JSON.parse(parameters['/Define/Product/Units'])?.map(
                                  (item: any, index: number) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.label}
                                      </option>
                                    )
                                  }
                                )}
                            </select>
                            {formik.errors.shipping_group && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.shipping_group}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* 商品カスタム項目 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fs-6'>
                  <span>商品カスタム項目</span>
                </label>

                <div className='col-lg-9 align-self-center'>
                  {formik.values?.product_custom_field.map((item: any, index: number) => {
                    return (
                      <div className='row gx-0 pb-3' key={index}>
                        <div
                          className='col-lg-3'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#dfe2e9',
                            borderTopLeftRadius: '0.475rem',
                            borderBottomLeftRadius: '0.475rem',
                            borderRight: 'none',
                            padding: '5px 0',
                          }}
                        >
                          項目名を選択
                        </div>
                        <div className='col-lg-7'>
                          <div style={{display: 'flex'}}>
                            <select
                              className='form-select'
                              data-control='select2'
                              placeholder='選択してください'
                              value={item.id}
                              onChange={(e) => {
                                // console.log(e.target.value)
                                formik.values.product_custom_field[index].id = e.target.value
                                updateFormData(formik.values)
                              }}
                              style={{
                                borderTopRightRadius: '0.475rem',
                                borderBottomRightRadius: '0.475rem',
                                borderTopLeftRadius: '0',
                                borderBottomLeftRadius: '0',
                              }}
                            >
                              <option value=''></option>
                              {productCustomFieldOptions?.map((item: any, index: number) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                )
                              })}
                            </select>
                            <input
                              type='text'
                              className='form-control'
                              value={formik.values.product_custom_field[index].value}
                              onChange={(e) => {
                                formik.values.product_custom_field[index].value = e.target.value
                                updateFormData(formik.values)
                              }}
                              style={{
                                borderTopLeftRadius: '0.475rem',
                                borderBottomLeftRadius: '0.475rem',
                                marginLeft: '5px',
                              }}
                            />
                          </div>
                        </div>
                        <div className='col-lg-2 d-flex justify-content-end'>
                          <div
                            className='btn btn-danger'
                            onClick={(index) => {
                              if (formik.values.product_custom_field.length > 1) {
                                formik.values.product_custom_field.splice(index, 1)
                              }
                              updateFormData(formik.values)
                            }}
                          >
                            削除
                          </div>
                        </div>
                        {productCustomFieldOptions?.find((k: any) => k.id == item.id)?.status ==
                          'disabled' && (
                          <div style={{color: 'red'}}>この項目は設定で無効化されています</div>
                        )}
                      </div>
                    )
                  })}
                </div>
                {
                  <div style={{textAlign: 'right'}}>
                    <div
                      className='btn btn-primary w-100px'
                      onClick={() => {
                        formik.values.product_custom_field.push({id: '', value: ''})
                        updateFormData(formik.values)
                      }} // 点击按键，切换显示和隐藏
                    >
                      ＋追加
                    </div>
                  </div>
                }
              </div>

              {/* 上代項目名 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className=''>上代項目名</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <select
                    className='form-select'
                    data-control='select2'
                    placeholder='選択してください'
                    {...formik.getFieldProps('suggested_retail_price_item_name')}
                  >
                    <option value=''></option>
                    {itemsInTheAncientPeriodOptions?.map((item: any, index: number) => {
                      return (
                        <option key={index} value={item.label}>
                          {item.label}
                        </option>
                      )
                    })}
                  </select>
                  {formik.errors.suggested_retail_price_item_name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        {formik.errors.suggested_retail_price_item_name}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* 上代 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className=''>上代</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      className='form-control'
                      type='text'
                      {...formik.getFieldProps('suggested_retail_price')}
                    />
                    <span style={{marginLeft: '5px'}}>円</span>
                  </div>
                  {formik.errors.suggested_retail_price && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.suggested_retail_price}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* 最小注文受付数 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className=''>最小注文受付数</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <input
                    className='form-control'
                    type='text'
                    {...formik.getFieldProps('minimum_order_quantity')}
                  />
                  {formik.errors.minimum_order_quantity && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.minimum_order_quantity}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* 最大注文受付数 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className=''>最大注文受付数</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <input
                    className='form-control'
                    type='text'
                    {...formik.getFieldProps('maximum_order_quantity')}
                  />
                  {formik.errors.maximum_order_quantity && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.maximum_order_quantity}</div>
                    </div>
                  )}
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>

          {/* 在庫設定 */}
          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title'>在庫設定</div>
            </div>
            <div className='card-body'>
              {/* <form> */}

              {/* 在庫数 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className=''>在庫数</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      className='form-control'
                      type='text'
                      {...formik.getFieldProps('stock_quantity')}
                    />
                    <label
                      style={{
                        width: '152px',
                        marginLeft: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                      }}
                    >
                      <input
                        className='form-check-input'
                        type='checkbox'
                        name='default'
                        value={formik.values.unlimited_stock_quantity}
                        checked={formik.values.unlimited_stock_quantity === 'yes'}
                        onChange={(e) => {
                          if (e.target.checked) {
                            formik.values.unlimited_stock_quantity = 'yes'
                          } else {
                            formik.values.unlimited_stock_quantity = 'no'
                          }
                          updateFormData(formik.values)
                        }}
                      />
                      在庫無制限
                    </label>
                  </div>
                  {formik.errors.stock_quantity && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.stock_quantity}</div>
                    </div>
                  )}
                  {formik.errors.unlimited_stock_quantity && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.unlimited_stock_quantity}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* 入数 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className=''>入数</span>
                </label>

                <div className='col-lg-9 align-self-center '>
                  <div className='d-flex flex-wrap' style={{alignItems: 'center'}}>
                    <input
                      className='form-control flex-root'
                      style={{minWidth: '170px'}}
                      type='text'
                      {...formik.getFieldProps('number_per_case')}
                    />
                    <div className='flex-root ms-5 d-flex' style={{alignItems: 'center'}}>
                      <label className='col-form-label ' style={{minWidth: '65px'}}>
                        単位
                      </label>
                      <div className='align-self-center'>
                        <div style={{display: 'flex', alignItems: 'center', minWidth: '60px'}}>
                          <select
                            className='form-select'
                            data-control='select2'
                            placeholder='選択してください'
                            {...formik.getFieldProps('unit')}
                          >
                            <option value=''>選択してください</option>
                            {parameters['/Define/Product/Units'] &&
                              JSON.parse(parameters['/Define/Product/Units'])?.map(
                                (item: any, index: number) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.label}
                                    </option>
                                  )
                                }
                              )}
                          </select>
                          {formik.errors.unit && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.unit}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {formik.touched.number_per_case && formik.errors.number_per_case && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.number_per_case}</div>
                    </div>
                  )}
                  {formik.errors.unit && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.unit}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* 在庫表示 */}
              <div className='row mb-2'>
                <div className='col-xxl-3 align-items-center align-self-center'>
                  <label className='col-form-label '>在庫表示</label>
                </div>
                <div className='col-lg-9 align-self-center'>
                  {ztstatusOptions.map((item, index) => {
                    return (
                      <div className='form-check form-check-inline pe-3' key={index}>
                        <input
                          className='form-check-input'
                          type='radio'
                          value={item.id}
                          checked={formik.values.stock_display === item.id}
                          onChange={() => {
                            formik.values.stock_display = item.id
                            updateFormData(formik.values)
                          }}
                        />
                        <label className='form-check-label'>{item.label}</label>
                      </div>
                    )
                  })}
                </div>
              </div>

              {/* 在庫表示方法 */}
              <div className='row mb-2'>
                <div className='col-xxl-3 align-items-center align-self-center'>
                  <label className='col-form-label '>在庫表示方法</label>
                </div>
                <div className='col-lg-9 align-self-center'>
                  {representMethodsInLibrariesOptions.map((item, index) => {
                    return (
                      <div className='form-check form-check-inline pe-3' key={index}>
                        <input
                          className='form-check-input'
                          type='radio'
                          value={item.id}
                          checked={formik.values.stock_display_method === item.id}
                          onChange={() => {
                            formik.values.stock_display_method = item.id
                            updateFormData(formik.values)
                          }}
                        />
                        <label className='form-check-label'>{item.label}</label>
                      </div>
                    )
                  })}
                </div>
              </div>

              {/* 在庫わずかになる数量 */}
              <div className='row mb-6'>
                <div className='col-xxl-3'>
                  <label className='col-form-label '>在庫わずかになる数量</label>
                </div>
                <div className='col-lg-9 align-self-center'>
                  <div className='d-flex' style={{alignItems: 'center'}}>
                    <input
                      className='form-control flex-root'
                      type='text'
                      {...formik.getFieldProps('low_stock_threshold')}
                    />
                    <div className='flex-root ms-5'>在庫数以下</div>
                  </div>
                  {formik.errors.low_stock_threshold && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.low_stock_threshold}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* 配送設定 */}
          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title'>配送設定</div>
            </div>
            <div className='card-body'>
              {/* 配送グループ */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className=''>配送グループ</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <select
                    className='form-select'
                    data-control='select2'
                    placeholder='選択してください'
                    {...formik.getFieldProps('shipping_group')}
                  >
                    <option value=''></option>
                    {deliveryGroupOptions?.map((item: any, index: number) => {
                      return (
                        <option key={index} value={item.delivery_master_id}>
                          {item.delivery_master_name}
                        </option>
                      )
                    })}
                  </select>
                  {formik.errors.shipping_group && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.shipping_group}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* 配送・送料規定 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className=''>配送・送料規定</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <select
                    className='form-select'
                    data-control='select2'
                    placeholder='選択してください'
                    {...formik.getFieldProps('shipping_fee_policy')}
                  >
                    <option value=''></option>
                    {shippingAndShippingRegulationsOptions?.map((item: any, index: number) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.label}
                        </option>
                      )
                    })}
                  </select>
                  {formik.errors.shipping_fee_policy && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.shipping_fee_policy}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* 決済設定 */}
          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title'>決済設定</div>
            </div>
            <div className='card-body'>
              {/* 決済制限 */}
              <div className='row mb-2'>
                <div className='col-xxl-3 align-items-center align-self-center'>
                  <label className='col-form-label '>決済制限</label>
                </div>
                <div className='col align-self-center'>
                  {paymentRestrictionOptions.map((item, index) => {
                    return (
                      <div className='form-check form-check-inline pe-3' key={index}>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={formik.values.payment_restriction.includes(item.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              formik.values.payment_restriction.push(item.id)
                            } else {
                              const idx = formik.values.payment_restriction.findIndex(
                                (k: any) => k == item.id
                              )
                              formik.values.payment_restriction.splice(idx, 1)
                            }
                            updateFormData(formik.values)
                          }}
                        />
                        <label className='form-check-label'>{item.label}</label>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* 価格設定 */}
          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title'>価格設定</div>
            </div>
            <div className='card-body'>
              {/* 税区分 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className=''>税区分</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <select
                    className='form-select'
                    data-control='select2'
                    placeholder='選択してください'
                    {...formik.getFieldProps('tax_category')}
                  >
                    <option value=''></option>
                    {taxDifferentiationOptions?.map((item: any, index: number) => {
                      return (
                        <option key={index} value={item.pk}>
                          {item.name}（{item.taxrate}%）
                        </option>
                      )
                    })}
                  </select>{' '}
                  {formik.touched.tax_category && formik.errors.tax_category && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.tax_category}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* 表示方法 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fs-6'>
                  <span>価格設定方法</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <div className='d-flex flex-wrap align-items-center'>
                    <label className='flex-shrink-0' style={{minWidth: '120px'}}>
                      <input
                        className='form-check-input me-3'
                        type='radio'
                        name='price_range'
                        value='unit_price' // ラジオボタンのvalueを設定
                        checked={formik.values.price_range === 'unit_price'} // Formikのvaluesをチェックして、条件に合致する場合にchecked属性を設定
                        onChange={() => formik.setFieldValue('price_range', 'unit_price')} // ラジオボタンを選択したときに、Formikのvaluesを更新
                      />
                      単価
                    </label>

                    <label className='flex-shrink-0' style={{minWidth: '120px'}}>
                      <input
                        className='form-check-input me-3'
                        type='radio'
                        name='price_range'
                        value='price_range' // ラジオボタンのvalueを設定
                        checked={formik.values.price_range === 'price_range'} // Formikのvaluesをチェックして、条件に合致する場合にchecked属性を設定
                        onChange={() => formik.setFieldValue('price_range', 'price_range')} // ラジオボタンを選択したときに、Formikのvaluesを更新
                      />
                      価格レンジ
                    </label>
                  </div>
                  {(formik.errors.price_range ||
                    formik.errors.price_range_min ||
                    formik.errors.price_range_max) && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.price_range}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* 単価 */}
              {formik.values.price_range === 'unit_price' && (
                <div className='row mb-6'>
                  {/*  style={{textAlign: 'right'}} */}
                  <div className='col-3'>
                    <label className='col-form-label '>単価</label>
                  </div>
                  <div className='col align-self-center'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <div className='flex-root' style={{display: 'flex', alignItems: 'center'}}>
                        <input
                          className='form-control form-control-sm'
                          type='text'
                          {...formik.getFieldProps('unit_price')}
                          onChange={(e) => {
                            // console.log('onchange', e)
                            let value = e.target.value
                            console.log(value)
                            formik.values.unit_price = value
                            updateFormData(formik.values)

                            if (value) {
                              let num = Number(value)
                              // console.log('customerRankList', customerRankList)
                              let dataList = customerRankList.map((item: any) => {
                                item.ratePricing = num * (item.rate / 100)
                                return item
                              })
                              // console.log('dataList', dataList)
                              setcustomerRankList(dataList)
                            }
                          }}
                        />
                        <div style={{marginLeft: '10px', width: '155px'}}>円　(通常会員価格)</div>
                      </div>
                    </div>
                    {formik.errors.unit_price && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.unit_price}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* 価格レンジ */}
              {formik.values.price_range === 'price_range' && (
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label  fs-6'>
                    <span className=''>価格レンジ</span>
                  </label>
                  <div className='col-lg-9 align-self-center'>
                    <div className='d-flex flex-wrap align-items-center'>
                      <div className='flex-root' style={{display: 'flex', alignItems: 'center'}}>
                        <input
                          className='form-control  min-w-125px'
                          type='text'
                          {...formik.getFieldProps('price_range_min')}
                          onChange={(e) => {
                            // console.log('onchange', e)
                            let value = e.target.value
                            // console.log(value)
                            formik.values.price_range_min = value
                            updateFormData(formik.values)

                            if (value) {
                              let num = Number(value)
                              // console.log('customerRankList', customerRankList)
                              let dataList = customerRankList.map((item: any) => {
                                item.ratePricingMin = num * (item.rate / 100)
                                return item
                              })
                              // console.log('dataList', dataList)
                              setcustomerRankList(dataList)
                            }
                          }}
                        />
                        <div style={{marginLeft: '10px'}}>円</div>
                      </div>
                      <div style={{width: '45px', textAlign: 'center'}}>～</div>
                      <div className='flex-root' style={{display: 'flex'}}>
                        <input
                          className='form-control min-w-125px'
                          type='text'
                          {...formik.getFieldProps('price_range_max')}
                          onChange={(e) => {
                            // console.log('onchange', e)
                            let value = e.target.value
                            // console.log(value)
                            formik.values.price_range_max = value
                            updateFormData(formik.values)

                            if (value) {
                              let num = Number(value)
                              // console.log('customerRankList', customerRankList)
                              let dataList = customerRankList.map((item: any) => {
                                item.ratePricingMax = num * (item.rate / 100)
                                return item
                              })
                              // console.log('dataList', dataList)
                              setcustomerRankList(dataList)
                            }
                          }}
                        />
                      </div>
                      <div style={{width: '125px', marginLeft: '10px'}}>円　(通常会員価格)</div>
                    </div>
                    {(formik.errors.price_range ||
                      formik.errors.price_range_min ||
                      formik.errors.price_range_max) && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.price_range}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* 表示方法 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fs-6'>
                  <span>価格表示設定</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <div className='d-flex flex-wrap align-items-center'>
                    <label className='flex-shrink-0 me-4'>
                      <input
                        className='form-check-input me-3'
                        type='radio'
                        name='visible_price'
                        value='true' // ラジオボタンのvalueを設定
                        checked={formik.values.visible_price === 'true'} // Formikのvaluesをチェックして、条件に合致する場合にchecked属性を設定
                        onChange={() => formik.setFieldValue('visible_price', 'true')} // ラジオボタンを選択したときに、Formikのvaluesを更新
                      />
                      公開
                    </label>

                    <label className='flex-shrink-0'>
                      <input
                        className='form-check-input me-3'
                        type='radio'
                        name='visible_price'
                        value='false' // ラジオボタンのvalueを設定
                        checked={formik.values.visible_price === 'false'} // Formikのvaluesをチェックして、条件に合致する場合にchecked属性を設定
                        onChange={() => formik.setFieldValue('visible_price', 'false')} // ラジオボタンを選択したときに、Formikのvaluesを更新
                      />
                      非公開
                    </label>
                  </div>
                  {(formik.errors.price_range ||
                    formik.errors.price_range_min ||
                    formik.errors.price_range_max) && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.price_range}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* 数量割引 */}
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fs-6'>
                  <span className=''>数量割引</span>
                </label>
                <div className='col-lg-9 align-self-center'>
                  <div className='row mb-2'>
                    <div className='col align-self-center'>
                      <div className='table-responsive'>
                        <table className='table'>
                          <thead style={{display: 'none'}}>
                            <tr style={{background: 'var(--bs-dark-text)'}}>
                              <th
                                className='align-middle text-center'
                                style={{color: 'var(--bs-gray-80)'}}
                              ></th>
                              <th
                                className='align-middle text-center'
                                style={{color: 'var(--bs-gray-80)'}}
                              ></th>
                              <th
                                className='align-middle text-center'
                                style={{color: 'var(--bs-gray-80)'}}
                              ></th>
                              <th
                                className='align-middle text-center'
                                style={{color: 'var(--bs-gray-80)'}}
                              ></th>
                              <th
                                className='align-middle text-center'
                                style={{color: 'var(--bs-gray-80)'}}
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {formik.values.quantity_discount?.map((item: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className='align-middle text-center'
                                    style={{minWidth: '120px'}}
                                  >
                                    <input
                                      className='form-control form-control-sm'
                                      type='text'
                                      value={item.unit}
                                      onChange={(e) => {
                                        item.unit = e.target.value
                                        updateFormData(formik.values)
                                      }}
                                    />
                                  </td>
                                  <td
                                    className='align-middle text-center'
                                    style={{minWidth: '54px'}}
                                  >
                                    セット
                                  </td>
                                  <td
                                    className='align-middle text-center'
                                    style={{minWidth: '42px'}}
                                  >
                                    単価
                                  </td>
                                  <td
                                    className='align-middle text-center'
                                    style={{minWidth: '120px'}}
                                  >
                                    {formik.values.price_range === 'unit_price' && (
                                      <>
                                        <input
                                          className='form-control form-control-sm'
                                          type='text'
                                          value={item.price}
                                          onChange={(e) => {
                                            item.price = e.target.value
                                            updateFormData(formik.values)
                                          }}
                                        />
                                      </>
                                    )}

                                    {formik.values.price_range === 'price_range' && (
                                      <>
                                        <input
                                          className='form-control form-control-sm'
                                          type='text'
                                          value={item.price_range_min}
                                          onChange={(e) => {
                                            item.price_range_min = e.target.value
                                            updateFormData(formik.values)
                                          }}
                                        />
                                        ～
                                        <input
                                          className='form-control form-control-sm'
                                          type='text'
                                          value={item.price_range_max}
                                          onChange={(e) => {
                                            item.price_range_max = e.target.value
                                            updateFormData(formik.values)
                                          }}
                                        />
                                      </>
                                    )}
                                  </td>
                                  <td
                                    className='align-middle text-center'
                                    style={{minWidth: '5px'}}
                                  >
                                    円
                                  </td>
                                  <td
                                    className='align-middle text-center'
                                    style={{minWidth: '90px'}}
                                  >
                                    <button
                                      className='btn btn-danger btn-sm'
                                      type='button'
                                      style={{borderStyle: 'none', color: 'var(--bs-gray-100)'}}
                                      // disabled={formik.values.quantity_discount.length <= 1}
                                      onClick={() => {
                                        formik.values.quantity_discount.splice(index, 1)
                                        updateFormData(formik.values)
                                      }}
                                    >
                                      行を削除
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='row mb-2'>
                    <div className='col align-self-center'>
                      <button
                        className='btn btn-success btn-sm'
                        type='button'
                        style={{borderStyle: 'none', color: 'var(--bs-gray-100)'}}
                        onClick={() => {
                          let obj: any = {unit: '', price: ''}
                          formik.values.quantity_discount.push(obj)
                          updateFormData(formik.values)
                        }}
                      >
                        行を追加
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* 顧客ランク別価格設定 */}
              <div className='row mb-2'>
                <div className='col-xxl-3 align-items-center align-self-center'>
                  <label className='col-form-label '>顧客ランク別価格設定</label>
                </div>
                <div className='table-responsive col align-self-center w-50'>
                  <table
                    style={{borderCollapse: 'collapse', border: '1px solid #c7c7c7'}}
                    className='table table-bordered'
                  >
                    <thead className=' text-center' style={{backgroundColor: '#7f7f7f'}}>
                      <tr>
                        <th scope='col' className='text-white' style={{minWidth: '40px'}}>
                          ID
                        </th>
                        <th scope='col' className='text-white' style={{minWidth: '120px'}}>
                          顧客ランク名
                        </th>
                        <th scope='col' className='text-white' style={{minWidth: '80px'}}>
                          掛け率
                        </th>
                        <th scope='col' className='text-white' style={{minWidth: '120px'}}>
                          掛け率適用価格
                        </th>
                        <th scope='col' className='text-white' style={{minWidth: '200px'}}>
                          個別用価格設定
                        </th>
                        <th scope='col' className='text-white' style={{minWidth: '200px'}}>
                          数量割引
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerRankList?.map((item: any, index: number) => (
                        <tr key={index} className='text-center align-middle'>
                          <td
                            style={{border: '1px solid #c7c7c7', borderColor: '#c7c7c7 !important'}}
                          >
                            {item.group_id}
                          </td>
                          <td
                            style={{border: '1px solid #c7c7c7', borderColor: '#c7c7c7 !important'}}
                          >
                            {item.group_name}
                          </td>
                          <td
                            style={{border: '1px solid #c7c7c7', borderColor: '#c7c7c7 !important'}}
                          >
                            {item.rate + '%'}
                          </td>
                          <td
                            style={
                              !item.individualPricing ||
                              isNaN(Number(item.individualPricing) * Number(item.rate) * 0.01) ||
                              Number(item.individualPricing) * Number(item.rate) * 0.01 === 0
                                ? {border: '1px solid #c7c7c7', borderColor: '#c7c7c7 !important'}
                                : {
                                    border: '1px solid #c7c7c7',
                                    borderColor: '#c7c7c7 !important',
                                    backgroundColor: '#b4c7e7',
                                  }
                            }
                          >
                            {formik.values.price_range === 'unit_price' && (
                              <>
                                {!item.individualPricing
                                  ? item.ratePricing?.toFixed(2) ||
                                    (
                                      Number(formik?.values.unit_price) *
                                      Number(item.rate) *
                                      0.01
                                    ).toFixed(2) ||
                                    0
                                  : isNaN(Number(item.individualPricing) * Number(item.rate) * 0.01)
                                  ? 0
                                  : (
                                      Number(item.individualPricing) *
                                      Number(item.rate) *
                                      0.01
                                    ).toFixed(2)}
                              </>
                            )}
                            {formik.values.price_range === 'price_range' && (
                              <>
                                {!item.individualPricingMin
                                  ? item.ratePricingMin?.toFixed(2) || 0
                                  : isNaN(
                                      Number(item.individualPricingMin) * Number(item.rate) * 0.01
                                    )
                                  ? 0
                                  : (
                                      Number(item.individualPricingMin) *
                                      Number(item.rate) *
                                      0.01
                                    ).toFixed(2)}
                                ～
                                {!item.individualPricingMax
                                  ? item.ratePricingMax?.toFixed(2) || 0
                                  : isNaN(
                                      Number(item.individualPricingMax) * Number(item.rate) * 0.01
                                    )
                                  ? 0
                                  : (
                                      Number(item.individualPricingMax) *
                                      Number(item.rate) *
                                      0.01
                                    ).toFixed(2)}
                              </>
                            )}
                            円
                          </td>
                          <td
                            style={
                              item.individualPricing == '' || item.individualPricing == '0'
                                ? {border: '1px solid #c7c7c7', borderColor: '#c7c7c7 !important'}
                                : {
                                    border: '1px solid #c7c7c7',
                                    borderColor: '#c7c7c7 !important',
                                    backgroundColor: '#b4c7e7',
                                  }
                            }
                          >
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              {formik.values.price_range === 'unit_price' && (
                                <>
                                  <input
                                    className='form-control '
                                    type='text'
                                    value={item.price}
                                    onChange={(e) => {
                                      item.price = e.target.value
                                      handleInputChange(item)
                                    }}
                                  />
                                  <span className='mx-5'>円</span>
                                </>
                              )}

                              {formik.values.price_range === 'price_range' && (
                                <>
                                  <input
                                    className='form-control '
                                    type='text'
                                    value={item.price_range_min}
                                    onChange={(e) => {
                                      item.price_range_min = e.target.value
                                      handleInputChange(item)
                                    }}
                                  />
                                  ～
                                  <input
                                    className='form-control '
                                    type='text'
                                    value={item.price_range_max}
                                    onChange={(e) => {
                                      item.price_range_max = e.target.value
                                      handleInputChange(item)
                                    }}
                                  />
                                  <span className='mx-5'>円</span>
                                </>
                              )}
                            </div>
                          </td>
                          <td>
                            <div style={{display: 'flex', alignItems: 'center', marginRight: 10}}>
                              {formik.values.price_range === 'unit_price' && (
                                <input
                                  className='form-control '
                                  type='text'
                                  value={item?.quantity_discount?.price}
                                  onChange={(e) => {
                                    item.quantity_discount = {
                                      price: '',
                                      price_range: '',
                                    }
                                    item.quantity_discount.price = e.target.value
                                    handleInputChange(item)
                                  }}
                                />
                              )}
                              {formik.values.price_range === 'price_range' && (
                                <input
                                  className='form-control '
                                  type='text'
                                  value={item?.quantity_discount?.price_range}
                                  onChange={(e) => {
                                    item.quantity_discount = {
                                      price: '',
                                      price_range: '',
                                    }
                                    item.quantity_discount.price_range = e.target.value
                                    handleInputChange(item)
                                  }}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* 特別価格 */}
              <div className='row mb-2'>
                <div className='col-xxl-3 align-items-center align-self-center'>
                  <label className='col-form-label '>特別価格 </label>
                </div>
                <div className='col align-self-center w-50 table-responsive'>
                  <table
                    style={{borderCollapse: 'collapse', border: '1px solid #c7c7c7'}}
                    className='table table-bordered'
                  >
                    <thead className='text-center' style={{backgroundColor: '#7f7f7f'}}>
                      <tr>
                        <th scope='col' className='text-white' style={{minWidth: '230px'}}>
                          顧客ID（カンマ区切りで複数入力可）
                        </th>
                        <th scope='col' className='text-white' style={{minWidth: '100px'}}>
                          価格
                        </th>
                        <th scope='col' className='text-white' style={{minWidth: '100px'}}></th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        borderCollapse: 'separate',
                        borderSpacing: '1px',
                        border: '1px solid #b8b8b8',
                      }}
                    >
                      {formik.values.special_price.map((item: any, index: number) => (
                        <tr key={index}>
                          <td style={{textAlign: 'center'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <input
                                className='form-control mx-3'
                                type='text'
                                value={item.id}
                                onChange={(e) => {
                                  item.id = e.target.value
                                  updateFormData(formik.values)
                                }}
                              />
                            </div>
                          </td>
                          <td style={{textAlign: 'center'}}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <input
                                className='form-control '
                                type='text'
                                value={item.price}
                                onChange={(e) => {
                                  item.price = e.target.value
                                  updateFormData(formik.values)
                                }}
                              />
                              <span className='mx-5'>円</span>
                            </div>
                          </td>
                          <td style={{textAlign: 'center'}}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                className='btn btn-danger'
                                onClick={(index) => {
                                  if (formik.values.special_price.length > 1) {
                                    formik.values.special_price.splice(index, 1)
                                  }
                                  updateFormData(formik.values)
                                }}
                              >
                                削除
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='row mb-2 d-flex justify-content-end'>
                    <div
                      className='btn btn-primary w-100px'
                      onClick={() => {
                        formik.values.special_price.push({id: '', price: ''})
                        updateFormData(formik.values)
                      }}
                    >
                      追加
                    </div>
                  </div>
                  <label className='col-form-label'>※価格は特別価格が優先されます </label>
                </div>
              </div>
              {/* </form> */}
            </div>
          </div>

          {/* 表示設定 */}
          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title'>公開設定</div>
            </div>
            <div className='card-body'>
              {/* <form> */}

              {/* 状態 */}
              <div className='row mb-2'>
                <div className='col-xxl-3 align-items-center align-self-center'>
                  <label className='col-form-label '>状態</label>
                </div>
                <div className='col align-self-center'>
                  {ztstatusOptions.map((item, index) => {
                    return (
                      <div className='form-check form-check-inline pe-3' key={index}>
                        <input
                          className='form-check-input'
                          type='radio'
                          value={item.id}
                          checked={formik.values.status === item.id}
                          onChange={() => {
                            formik.values.status = item.id
                            updateFormData(formik.values)
                          }}
                        />
                        <label className='form-check-label'>{item.label}</label>
                      </div>
                    )
                  })}
                </div>
              </div>

              {/* 商品セット非表示顧客ランク */}
              <div className='row mb-2'>
                <div className='col-xxl-3 align-items-center align-self-center'>
                  <label className='col-form-label '>商品セット非表示顧客ランク</label>
                </div>
                <div className='col align-self-center w-50'>
                  <div>下記で選択された「表示グループ」の会員様には、この商品は表示されません</div>
                  <table
                    style={{borderCollapse: 'collapse', border: '1px solid #c7c7c7'}}
                    className='table table-bordered'
                  >
                    <thead style={{backgroundColor: '#7f7f7f'}}>
                      <tr>
                        <th scope='col' style={{textAlign: 'center'}}>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            checked={selectedAll}
                            onChange={(e) => handleSelectAll(e)}
                          />
                        </th>
                        <th scope='col' style={{color: '#fff'}}>
                          ID
                        </th>
                        <th scope='col' style={{color: '#fff'}}>
                          顧客ランク名
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        borderCollapse: 'separate',
                        borderSpacing: '1px',
                        border: '1px solid #c7c7c7',
                      }}
                    >
                      {customerRankList.map((item: any, index: number) => (
                        <tr key={index}>
                          <td style={{textAlign: 'center'}}>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={selectedItems.includes(item.group_id)}
                              onChange={(e) => handleCheckboxChange(e, item.group_id)}
                            />
                          </td>
                          <td>{item.group_id}</td>
                          <td>{item.group_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div>例外的に表示させる顧客ID：</div>
                  <input
                    className='form-control'
                    type='text'
                    {...formik.getFieldProps('exception_display_member_id')}
                  />
                  <div>カンマ区切りで複数入力可</div>
                  {formik.touched.exception_display_member_id &&
                    formik.errors.exception_display_member_id && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {formik.errors.exception_display_member_id}
                        </div>
                      </div>
                    )}
                </div>
              </div>

              {/* 表示順 */}
              <div className='row mb-6'>
                <div className='col-xxl-3 align-items-center align-self-center'>
                  <label className='col-form-label '>表示順</label>
                </div>
                <div className='col align-self-center w-50'>
                  <input
                    className='form-control'
                    type='text'
                    {...formik.getFieldProps('display_order')}
                    value={formik.values.display_order}
                  />
                  {formik.touched.display_order && formik.errors.display_order && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.display_order}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* 按键组 */}
          <div className='container'>
            <div className='row mb-6 justify-content-center'>
              <div className='col-12 col-md-auto'>
                <div className='btn-group d-flex flex-wrap' role='group' aria-label='Button group'>
                  <button
                    type='button'
                    className='btn btn-primary rounded mx-2 my-2'
                    onClick={() => navigate(-1)}
                  >
                    戻る
                  </button>
                  <button
                    type='button'
                    className='btn btn-secondary rounded mx-2 my-2'
                    onClick={() => {
                      updateFormData({
                        ...formData,
                      })
                    }}
                  >
                    キャンセル
                  </button>
                  {compileType != '2' && (
                    <button
                      className='btn btn btn-primary rounded mx-2 my-2'
                      type='submit'
                      data-kt-users-modal-action='submit'
                    >
                      <span className='indicator-label'>登録</span>
                    </button>
                  )}
                  {compileType == '2' && (
                    <button
                      className='btn btn btn-primary rounded mx-2 my-2'
                      type='submit'
                      data-kt-users-modal-action='submit'
                    >
                      <span className='indicator-label'>更新</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <ControllArea handleModalShow={setModalShow} />
          {modalShow && (
            <PreviewModal
              show={modalShow}
              handleModalShow={() => setModalShow(false)}
              variation={productVariationList}
            />
          )}
        </Form>
      </FormikProvider>
    </div>
  )
}

const ControllArea: React.FC<any> = ({handleModalShow}) => {
  const {id} = useParams()
  const navigate = useNavigate()
  const {handleSubmit, isSubmitting, isValid} = useFormikContext<any>()

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleSubmit()
  }

  const handleButtonModalClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    handleModalShow(true)
  }

  return (
    <div className='app-wrapper fixed-bottom'>
      <div className='col-12 col-md-auto w-100'>
        <div className='btn-group d-flex flex-wrap' role='group' aria-label='Button group'>
          <button
            type='button'
            className='btn btn-primary rounded mx-2 my-2'
            onClick={() => navigate(`/product`)}
          >
            一覧へ戻る
          </button>

          <button
            type='button'
            className='btn btn-success rounded mx-2 my-2'
            onClick={() => {
              navigate(`/product/${id}`)
            }}
          >
            基本情報登録
          </button>

          <button
            type='button'
            className='btn btn-info rounded mx-2 my-2'
            onClick={(event) => {
              handleButtonModalClick(event)
            }}
          >
            プレビューを表示
          </button>

          <button
            className='btn btn-primary rounded mx-2 my-2'
            type='submit'
            onClick={handleButtonClick}
            // disabled={isSubmitting || !isValid}
          >
            更新
          </button>
        </div>
      </div>
    </div>
  )
}
