import React from 'react';
import { useField, useFormikContext } from 'formik';

interface RadioGroupProps {
    label: string;
    name: string;
    options: { id: string; label: string }[];
  }
  
  const CustomRadioGroup: React.FC<RadioGroupProps> = ({ label, name, options }) => {
    const [field, meta] = useField(name);
    const { setFieldValue } = useFormikContext();
  
    return (
      <div className='row'>
        <div className='col-12 align-self-center'>
          {options.map((option) => (
            <div className='form-check form-check-inline pe-3' key={option.id}>
              <input
                className='form-check-input'
                type='radio'
                {...field}
                value={option.id}
                checked={field.value === option.id}
                onChange={() => setFieldValue(name, option.id)}
              />
              <label className='form-check-label'>{option.label}</label>
            </div>
          ))}
          {meta.touched && meta.error ? (
            <div className='col-12 fv-plugins-message-container'>
              <div className='fv-help-block'>{meta.error}</div>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  
  
  
  
  
  
  
  export default CustomRadioGroup;