import {getServer, postServer, deleteServer} from 'src/service/http'

// アカウント情報取得
export function accountInfoGet(param: any, headers: object = {}) {
  return getServer('/account-info-get', {param}, {...headers})
}

// アカウント情報設定
export function accountInfoUpdate(param: object) {
  return postServer('/account-info-update', {...param})
}

// 用户列表
export function accountEdit(obj: any) {
  return postServer('/account-edit', obj)
}

export function getAction(param: any, headers: object = {}) {
  return getServer('/account-info-get', {param}, {...headers})
}

export function getAddressList() {
  return getServer('/get-master-by-id', {
    data_key: 'todouhuken',
  })
}

// アカウント情報取得
export function accountUserInfoGet() {
  return getServer('/account-user-info-get', {})
}

// アカウント情報取得
export function accountUserInfoUpdate(param: any) {
  return postServer('/account-user-info-update', param)
}

// アカウント招待
export function accountUserAdd(param: any) {
  return postServer('/account-user-add', param)
}

// アカウント招待
export function accountUserDelete(param: any) {
  return postServer('/account-user-delete', param)
}

// アカウント情報取得
export function accountUsersListGet(param: any) {
  return postServer('/account-users-list', param)
}
