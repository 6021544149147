import {message} from 'antd'
import {ChangeEvent, useCallback, useEffect, useState} from 'react'
import {TypeIcon} from './TypeIcon'
import {replaceSlashInFolderName} from '@/utils'

type CreateFolderProps = {
  onCreateFolder: (folderName: string) => Promise<void>
  onHide?: () => void
  defaultValue?: string
  isEdit?: boolean
  isFolder?: boolean | number
}

export default function CreateFolder(props: CreateFolderProps) {
  const {onCreateFolder, onHide, defaultValue, isEdit, isFolder} = props
  const names = defaultValue?.split('.')
  const extensionFile = isFolder ? '' : names?.pop()
  const [{name, extension}, setNewFolderName] = useState<{name: string; extension?: string}>({
    name: names?.join() || '',
    extension: extensionFile,
  })
  const [error, setError] = useState<string>()

  const handleSubmit = useCallback(() => {
    if (!name.trim()) return setError('フォルダ/ファイル名が必要です')
    if (name.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~_]+$/) === null) return setError('フォルダ/ファイル名は半角英数字記号で入力してください')
    setError('')
    onCreateFolder(`${name}${extension ? `.${extension}` : ''}`)
      .then(onHide)
      .catch((e: any) => {
        if (e.message) {
          setError(
            e.message !== 'File already exists'
              ? 'フォルダ名はすでに存在しています。'
              : 'ファイル名はすでに存在しています。'
          )
        } else setError('System Error')
      })
  }, [extension, name, onCreateFolder, onHide])

  useEffect(() => {
    if (error) {
      message.error(error)
    }
  }, [error])

  useEffect(() => {
    setError('')
  }, [name])

  const onChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const name = replaceSlashInFolderName(e.target.value.substring(0, 50).trim())
    setNewFolderName((pre) => ({...pre, name}))
  }, [])

  const intputFolder = (colSpan?: number) => {
    return (
      <>
        <td />
        <td colSpan={colSpan}>
          <div className='d-flex align-items-center'>
            <TypeIcon isFolder={colSpan || isFolder} />
            <div style={{marginRight: 8}}>
              <input
                maxLength={50}
                autoFocus
                type='text'
                style={{borderColor: error ? '#ff0000' : undefined}}
                placeholder='フォルダー/ファイル名を入力する'
                className='form-control mw-250px me-3'
                value={name}
                onChange={onChangeName}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSubmit()
                }}
              />
            </div>
            <button className='btn btn-icon btn-light-primary me-3' onClick={handleSubmit}>
              <span className='indicator-label'>
                <i className='bi bi-check-lg'></i>
              </span>
            </button>

            <button className='btn btn-icon btn-light-danger' onClick={onHide}>
              <span className='indicator-label'>
                <i className='bi bi-x-lg'></i>
              </span>
            </button>
          </div>
        </td>
      </>
    )
  }

  if (isEdit) return intputFolder()

  return (
    <tr id='kt_file_manager_new_folder_row' data-kt-filemanager-template='upload'>
      {intputFolder(4)}
    </tr>
  )
}
