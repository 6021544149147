/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAlert} from 'src/_metronic/layout/core'
import {useAuth} from 'src/app/modules/auth'
import {specialCommerceLawInfoGet, specialCommerceLawInfoUpdate} from 'src/app/modules/api'
import {Spin} from 'antd'

const validationSchema = Yup.object().shape({
  store_status: Yup.string().required('お取引に関して必須です。'),
  registration_date: Yup.string().required('ご注文方法必須です。'),
  affiliate_id: Yup.string().required('商品代金以外の料金必須です。'),
  company_name: Yup.string().required('申込有効期限必須です。'),
  corporation_or_individual: Yup.string().required('不良品について必須です。'),
  shop_name: Yup.string().required('販売数量必須です。'),
  shop_name_kana: Yup.string().required('引き渡し時期必須です。'),
  representative_name: Yup.string().required('お支払方法必須です。'),
  establishment_date: Yup.string().required('返品期限必須です。'),
  fiscal_year_end: Yup.string().required('返品送料必須です。'),
})

export function BusinessLawSet({pk}: any) {
  const {auth} = useAuth()
  const {setMessage} = useAlert()
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }
  const navigate = useNavigate()
  const [userdata, setUserdata] = useState<any>({
    store_status: '',
    registration_date: '',
    affiliate_id: '',
    referrer: '',
    company_name: '',
    corporation_or_individual: '',
    shop_name: '',
    shop_name_kana: '',
    representative_name: '',
    establishment_date: '',
    fiscal_year_end: '',
  }) // 表单数据

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    initData()
  }, [])

  // 获取列表数据
  const initData = () => {
    setLoading(true)
    specialCommerceLawInfoGet({supplier_id: pk}, headers)
      .then((res: any) => {
        console.log('res', res)
        if (res && res.rows.length > 0) {
          let obj = res.rows[0]
          let data = {
            pk: obj.pk,
            supplier_id: obj.supplier_id,
            store_status: obj.transaction_details,
            registration_date: obj.order_method,
            affiliate_id: obj.additional_charges,
            referrer: obj.display_shipping_settings,
            company_name: obj.application_deadline,
            corporation_or_individual: obj.defective_products,
            shop_name: obj.sales_quantity,
            shop_name_kana: obj.delivery_time,
            representative_name: obj.payment_method,
            establishment_date: obj.return_deadline,
            fiscal_year_end: obj.return_shipping_fee,
          }
          console.log(data)
          setUserdata(data)
          formik.setValues(data)
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch((err: any) => {
        setLoading(false)
      })
  }

  const formik: any = useFormik<any>({
    initialValues: userdata as any,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('values', values)
      setSubmitting(true)
      setLoading(true)
      let obj = values
      let data = {
        pkVal: obj.pk,
        transaction_details: obj.store_status,
        order_method: obj.registration_date,
        additional_charges: obj.affiliate_id,
        display_shipping_settings: obj.referrer,
        application_deadline: obj.company_name,
        defective_products: obj.corporation_or_individual,
        sales_quantity: obj.shop_name,
        delivery_time: obj.shop_name_kana,
        payment_method: obj.representative_name,
        return_deadline: obj.establishment_date,
        return_shipping_fee: obj.fiscal_year_end,
      }
      console.log(data)
      specialCommerceLawInfoUpdate(data, {headers})
        .then((res: any) => {
          setLoading(false)
          setMessage({type: 'info', message: '更新しました'})
        })
        .catch((err: any) => {
          setLoading(false)
          setMessage({type: 'error', message: '更新に失敗しました'})
        })
    },
  })

  console.log('formik', formik)

  return (
    <Spin spinning={loading} tip='Processing' size='large'>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>特定商取引法に基づく記載設定</h3>
          </div>
        </div>

        <div className='collapse show'>
          <form
            id='kt_modal_add_user_form'
            className='container-fluid'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>お取引に関して</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg'
                    rows={2}
                    data-kt-element='input'
                    placeholder='お取引に関して'
                    //value={userdata.store_status}
                    {...formik.getFieldProps('store_status')}
                  ></textarea>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>ご注文方法</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg'
                    rows={2}
                    data-kt-element='input'
                    placeholder='ご注文方法'
                    {...formik.getFieldProps('registration_date')}
                  ></textarea>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>商品代金以外の料金</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg'
                    rows={2}
                    data-kt-element='input'
                    placeholder='商品代金以外の料金'
                    {...formik.getFieldProps('affiliate_id')}
                  ></textarea>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  設定した送料設定を表示させる
                </label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg'
                    rows={2}
                    data-kt-element='input'
                    placeholder='設定した送料設定を表示させる'
                    {...formik.getFieldProps('referrer')}
                  ></textarea>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>申込有効期限</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg'
                    rows={2}
                    data-kt-element='input'
                    placeholder='申込有効期限'
                    {...formik.getFieldProps('company_name')}
                  ></textarea>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>不良品について</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg'
                    rows={2}
                    data-kt-element='input'
                    placeholder='不良品について'
                    {...formik.getFieldProps('corporation_or_individual')}
                  ></textarea>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>販売数量</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg'
                    rows={2}
                    data-kt-element='input'
                    placeholder='販売数量'
                    {...formik.getFieldProps('shop_name')}
                  ></textarea>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>引き渡し時期</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg'
                    rows={2}
                    data-kt-element='input'
                    placeholder='引き渡し時期'
                    {...formik.getFieldProps('shop_name_kana')}
                  ></textarea>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>お支払方法</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg'
                    rows={2}
                    data-kt-element='input'
                    placeholder='お支払方法'
                    {...formik.getFieldProps('representative_name')}
                  ></textarea>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>返品期限</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg'
                    rows={2}
                    data-kt-element='input'
                    placeholder='返品期限'
                    {...formik.getFieldProps('establishment_date')}
                  ></textarea>
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>返品送料</label>
                <div className='col-lg-8 fv-row'>
                  <textarea
                    className='form-control form-control-lg'
                    rows={2}
                    data-kt-element='input'
                    placeholder='返品送料'
                    {...formik.getFieldProps('fiscal_year_end')}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button
                type='submit'
                data-kt-users-modal-action='submit'
                className='btn btn-danger me-4'
                onClick={() => navigate('/account/law')}
              >
                戻る
              </button>

              <button type='submit' data-kt-users-modal-action='submit' className='btn btn-primary'>
                {!loading && '保存'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    お待ちください...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit'
            >
              {
                !loading && <span className='indicator-label'>保存</span>
              }
              {
                loading && <span>
                お待ちください...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
              }
            </button> */}
          </form>
        </div>
      </div>
    </Spin>
  )
}
