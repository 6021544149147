import { getServer, postServer, deleteServer } from 'src/service/http'

// 請求書
export function invoiceInit(param: object, headers:object = {}) {
    return getServer(`/invoice-setting-get`, { ...param }, { ...headers })
}
export function invoice(param: object, headers:object = {}) {
    return postServer(`/invoice-data-add`, { ...param }, { ...headers })
}
// 納品書
export function slipInit(param: object,headers:object={}) {
    return getServer(`/delivery-note-setting-get`, { ...param  },{...headers})
}
export function slip(param: object,headers:object={}) {
    return postServer(`/delivery-note-data-add`, { ...param },{...headers})
}
// 領収書
export function receiptInit(param: object,headers:object={}) {
    return getServer(`/receipt-setting-get`, { ...param },{...headers})
}
export function receipt(param: object,headers:object={}) {
    return postServer(`/receipt-data-add`, { ...param },{...headers})
}
export function ledgerDataGet(param: object) {
    return getServer(`/ledger-data-get`, { ...param })
}

//下载
export function orderDownA(param: object) {
    return getServer(`/invoice-download`, { ...param })
}
export function orderDownB(param: object) {
    return getServer(`/delivery-note-download`, { ...param })
}
export function ledgerDownload(param: object) {
    return postServer(`/ledger-download`, { ...param })
}
