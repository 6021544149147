import React, {useEffect, useState, useRef} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {Steps} from './components/Steps'
import './style.css'
import {formatNumber} from 'src/app/modules/utils'
import {useLayout} from 'src/_metronic/layout/core'
import {orderDataAdd, paymentTransaction, orderStatusUpdate} from 'src/app/modules/api'
import ProcessButton from 'src/app/components/part/ProcessButton'
import {SupplierCheckoutModal} from './components/CheckoutModal'
import {useAlert} from 'src/_metronic/layout/core'
import {Spin} from 'antd'
import {object} from 'yup'

interface OrderDetailProps {
  isShowAddButton?: boolean
  isShowDeleteButton?: boolean
  isShowEditButton?: boolean
}

export default function CartCheckoutConfirm(props: OrderDetailProps) {
  const {setContentLoading} = useLayout()
  const navigate = useNavigate()
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [orderData, setOrderData] = useState<any>([])
  const [ordering, setOrdering] = useState<boolean>(false)
  const proceccedList = useRef<any>([])
  const [errorList, setErrorList] = useState<any>([])
  const [completeList, setCompleteList] = useState<any>([])
  // const [newTab, setNewTab] = useState<any>(null)
  const newTab = useRef<any>(null)
  const [modalData, setModalData] = useState<any>({})
  const {setMessage} = useAlert()
  const returnState = useRef<any>({})
  const completeListRef = useRef(completeList)
  const errorListRef = useRef(errorList)

  let location = useLocation()
  const qramesObj: any = location.state

  useEffect(() => {
    setOrderData([])
    returnState.current = {...qramesObj}
  }, [])

  // useEffect(() => {
  //   console.log('modalData', modalData)
  // }, [modalData])

  useEffect(() => {
    completeListRef.current = completeList

    // 完了したものはreturnStateから削除する
    if (completeListRef.current.length > 0) {
      returnState.current.supplier_items = qramesObj.supplier_items.filter(
        (k: any) => !completeListRef.current.includes(k.id)
      )
    }
  }, [completeList])

  useEffect(() => {
    errorListRef.current = errorList
  }, [errorList])

  useEffect(() => {
    // console.log('qramesObj', qramesObj)
    if (orderData.length === 0 && qramesObj && qramesObj.supplier_items.length > 0) {
      // 注文登録
      qramesObj.supplier_items.forEach((supplier_data: any, index: number) => {
        console.log('supplier_data', supplier_data)

        let orderDataList: any = []
        let orderDataListItems: any = {}
        let orderDataListBilling: any = {}
        let orderDataListShipping: any = []
        let orderDataListOrderItems: any = []
        let orderDataListPayment: any = {}

        let tmpData = supplier_data

        // 請求先
        const address = qramesObj._addressList.find((k: any) => k.pk === tmpData.address)
        orderDataListBilling = {
          billing_store_name: '',
          billing_name: '',
          billing_email_address: '',
          billing_street_address: address.address_street,
          billing_zip_code: address.address_postal_code,
          billing_building_name: address.address_building_name,
          billing_city: address.address_city,
          billing_prefecture: address.address_prefecture,
          billing_phone_number: address.tel,
          internal_memo: '',
          member_specific_setting: '',
          customer_inquiry: '',
          product_option: '',
          order_management_id: '',
          payment_id: '',
          supplier_order_id: '',
          supplier_id: tmpData.id,
          status: 'in_progress',
        }

        // 配送先
        orderDataListShipping = supplier_data.shipping.map((k: any) => {
          return {
            id: k.id,
            fee: k.shippingPrice,
            name: k.name,
            tax: k.shippingTaxPrice,
            desired_delivery_date: k.desired_delivery_date,
            desired_delivery_time: k.desired_delivery_time,
          }
        })

        // 支払い情報
        orderDataListPayment = {
          payment_amount: supplier_data.supplierPrice.toString(),
          payment_method: supplier_data.payment_method,
          payment_status:
            supplier_data.payment_method === 'later_payment' ? 'later_payment' : 'in_progress',
        }

        // 商品情報

        orderDataListOrderItems = supplier_data.list.map((item1: any) => {
          if (!item1.tax_rate) {
            let reg = /(\d+)%/g
            let tax_rate = reg.exec(item1.tax_category)
            if (tax_rate) {
              item1.tax_rate = tax_rate[1]
            } else {
              item1.tax_rate = 0
            }
          }
          item1.shipping_group = item1.shipping_group
          item1.total_amount = item1.calcPrice
          item1.product_variation_id = item1.variation_id
          item1.product_variation_name = item1.variation_name
          item1.shippingPrice = item1.shippingPrice || 0
          item1.shippingTaxPrice = item1.shippingTaxPrice || 0
          return item1
        })

        setOrderData((prevOrderData: any) => [
          ...prevOrderData,
          {
            ...orderDataListBilling,
            orderItems: orderDataListOrderItems,
            shipping: orderDataListShipping,
            paymentItem: {...orderDataListPayment},
            notes: supplier_data.note,
            buyerShippingPk: supplier_data.address,
          },
        ])
      })
    }
  }, [orderData, qramesObj])

  useEffect(() => {
    if (isComplete) {
      navigate('/buy/checkout/complete', {replace: true})
      setCompleteList([])
    }
  }, [isComplete])
  useEffect(() => {
    // completeListとerrorListの合計がsupplier_itemsの長さと一致したら、全ての注文が完了
    if (completeList.length + errorList.length === qramesObj.supplier_items.length) {
      console.log('終了処理')

      if (completeList.length === 0 && errorList.length > 0) {
        setMessage({
          type: 'error',
          message: '注文に失敗しました。注文内容またはクレジットカード情報をご確認ください。',
        })
        navigate('/buy/checkout', {state: {...returnState.current}, replace: true})
      } else if (errorList.length > 0 && completeList.length > 0) {
        setMessage({
          type: 'warning',
          message: '一部の注文が完了できませんでした。完了できなかった注文はカートに残っています。',
        })
        setIsComplete(true)
      } else if (completeList.length === qramesObj.supplier_items.length) {
        // console.log('全ての注文が完了しました', completeList, qramesObj.supplier_items)
        setMessage({
          type: 'success',
          message: 'すべての注文が完了しました。',
        })
        setIsComplete(true)
      }
    }
  }, [completeList, errorList])

  const execOrder = async () => {
    // console.log('execOrder', orderData)
    setOrdering(true)

    for (let order of orderData) {
      if (!errorList.find((k: any) => k[order.supplier_id])) {
        try {
          proceccedList.current = [...proceccedList.current, order.supplier_id]

          setModalData({
            show: true,
            company_name: qramesObj.supplier_items.find((k: any) => k.id === order.supplier_id)
              .name,
            amount: order.paymentItem.payment_amount,
            redirectUrl: '',
            order_id: '',
            loading: false,
            supplier_id: order.supplier_id,
            payment_method: order.paymentItem.payment_method,
            status: 'ordering',
            index: proceccedList.current.length,
            max: Object.keys(qramesObj.supplier_items).length,
            handleShow: () => setModalData((prev: any) => ({...prev, show: true})),
            handleHide: () => {
              setModalData((prev: any) => ({...prev, show: false}))
              setErrorList((prev: string[]) => [...prev, order.supplier_id])
            },
            handleSubmit: async () => secureTraceWindow('', '', ''),
          })

          let res: any = await orderDataAdd([order])

          if (res.data) {
            let order_id = res.data[0]
            try {
              let cardSeq = qramesObj.supplier_items.find(
                (k: any) => k.id === order.supplier_id
              ).cardSeq
              let res: any = await paymentTransaction({
                order_id: [order_id],
                supplier: order.supplier_id,
                card_seq: cardSeq,
                amount: order.paymentItem.payment_amount,
                provider: 'smbc',
                payment_method: order.paymentItem.payment_method,
              })

              if (res.data?.redirectUrl) {
                // リダイレクトURLがあるときはリダイレクト
                setModalData((prev: any) => ({
                  ...prev,
                  order_id: order_id,
                  redirectUrl: res.data.redirectUrl,
                  status: 'pending',
                  handleSubmit: async () =>
                    secureTraceWindow(res.data.redirectUrl, order_id, order.supplier_id),
                }))

                let count = 0
                try {
                  await new Promise<void>((resolve, reject) => {
                    const intervalId = setInterval(() => {
                      const nextFlag =
                        completeListRef.current.includes(order.supplier_id) ||
                        errorListRef.current.includes(order.supplier_id)

                      console.log(
                        'nextFlag',
                        nextFlag,
                        completeListRef.current,
                        order.supplier_id,
                        newTab
                      )

                      if (!nextFlag && newTab.current && newTab.current.closed) {
                        clearInterval(intervalId)
                        setModalData((prev: any) => ({...prev, status: 'error'}))
                        setMessage({
                          type: 'info',
                          message: '決済がキャンセルされました。支払い画面に戻ります。',
                        })
                        navigate('/buy/checkout', {state: {...returnState.current}, replace: true})
                        reject(new Error('決済がキャンセルされました'))
                      }

                      if (count >= 180) {
                        clearInterval(intervalId)
                        setModalData((prev: any) => ({...prev, status: 'error'}))
                        setMessage({
                          type: 'info',
                          message: '決済がタイムアウトしました。支払い画面に戻ります。',
                        })
                        navigate('/buy/checkout', {state: {...returnState.current}, replace: true})
                        reject(new Error('決済がタイムアウトしました'))
                      }

                      if (nextFlag) {
                        clearInterval(intervalId)

                        // completeListにあるときは成功、ないときはエラー
                        if (completeListRef.current.includes(order.supplier_id)) {
                          setModalData((prev: any) => ({...prev, status: 'payment_success'}))
                        } else {
                          setModalData((prev: any) => ({...prev, status: 'error'}))
                        }
                        newTab.current = null
                        resolve()
                      }
                      count++
                    }, 1000)
                  })

                  // 5秒待ってから次の処理に進む
                  await new Promise((resolve) => setTimeout(resolve, 5000))
                } catch (e) {
                  // 5秒待ってから次の処理に進む
                  await new Promise((resolve) => setTimeout(resolve, 5000))
                  // setModalData((prev: any) => ({...prev, show: false}))
                  return false
                }
              } else {
                setModalData((prev: any) => ({...prev, status: 'in_progress'}))

                if (res.message === 'ok') {
                  updateOrderStatus(order_id)
                  setModalData((prev: any) => ({...prev, status: 'order_success'}))
                  await new Promise((resolve) => setTimeout(resolve, 5000))
                  setCompleteList((prev: any) => [...prev, order.supplier_id])
                } else {
                  setModalData((prev: any) => ({...prev, status: 'error'}))
                  await new Promise((resolve) => setTimeout(resolve, 5000))
                  throw new Error('決済に失敗しました')
                }
              }
            } catch (err) {
              console.log('err', err)
              setErrorList((prev: any) => [...prev, order.supplier_id])
              break
            }
          }
        } catch (err) {
          setErrorList((prev: any) => [...prev, order.supplier_id])
        }
      }
    }

    setOrdering(false)
  }

  const secureTraceWindow = async (redirectUrl: string, order_id: string, supplier_id: string) => {
    // console.log('modalData', redirectUrl, order_id, supplier_id)
    // let newTab: any = null
    try {
      newTab.current = window.open(redirectUrl, '_blank')
      setModalData((prev: any) => ({...prev, status: 'in_progress'}))
      // setModalData((prev: any) => ({...prev, loading: true}))
    } catch (e) {
      // alert(
      //   'ポップアップがブロックされたため、決済を中断します。ブラウザの設定を変更して、再度お試しください。'
      // )
      setMessage({
        type: 'info',
        message:
          'ポップアップがブロックされたため、決済を中断します。ブラウザの設定を変更して、再度お試しください。',
      })
      setErrorList((prev: any) => [...prev, supplier_id])
      return false
    }

    const handleMessage = (event: MessageEvent) => {
      // console.log('event', event)

      if (event.origin === process.env.REACT_APP_API_ORIGIN) {
        if (event.data.checkout === 'ok') {
          updateOrderStatus(order_id)
          setCompleteList((prev: string[]) => [...prev, supplier_id])
          window.removeEventListener('message', handleMessage)
        }
        if (event.data.auth === 'ng') {
          window.removeEventListener('message', handleMessage)
          setErrorList((prev: string[]) => [...prev, supplier_id])
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return
  }

  const updateOrderStatus = async (order_id: string) => {
    try {
      const responseOrderStatusUpdate: any = await orderStatusUpdate({
        id: order_id,
        status: 'in_progress',
        page: 'checkout',
      })

      if (responseOrderStatusUpdate && responseOrderStatusUpdate.message === 'success') {
        console.log('orderStatusUpdate', responseOrderStatusUpdate)
      }
    } catch (err) {
      console.log('Error updating order status:', err)
    }
  }

  return (
    <>
      <Steps StepNo={3} />
      {/* {console.log('data', qramesObj)} */}
      {qramesObj.supplier_items &&
        qramesObj.supplier_items.length > 0 &&
        qramesObj.supplier_items.map((supplier_data: any, index: number) => (
          <>
            {!completeList.includes(supplier_data.id) && !errorList.includes(supplier_data.id) && (
              <Spin spinning={ordering} key={index}>
                <SectionSuppliers
                  key={index}
                  supplier_data={qramesObj}
                  index={index}
                  completeList={completeList}
                  errorList={errorList}
                />
              </Spin>
            )}
          </>
        ))}

      <div className='row'>
        <div className='mt-4 d-flex w-100 justify-content-between'>
          <button
            type='button'
            className='btn btn-white'
            style={{border: '1px solid'}}
            onClick={() => {
              navigate('/buy/checkout', {state: {...returnState.current}, replace: true})
            }}
          >
            情報を修正する
          </button>

          <Spin spinning={ordering}>
            <ProcessButton
              className='btn btn-dark d-flex align-items-center justify-content-center'
              processingText='決済を実行中'
              onClick={() => {
                execOrder()
              }}
            >
              決済を確定する
            </ProcessButton>
          </Spin>
        </div>
      </div>

      {modalData && modalData.show && (
        // <Spin spinning={modalData}>
        <SupplierCheckoutModal {...modalData} />
        // </Spin>
      )}
    </>
  )
}

// 加盟店ごとの商品・決済方法選択・配送方法選択を表示するコンポーネント
interface SectionSuppliersProps {
  supplier_data: any
  index: number
  completeList: any
  errorList: any
}

const SectionSuppliers: React.FC<SectionSuppliersProps> = ({
  supplier_data,
  index,
  completeList = [],
  errorList = [],
}) => {
  useEffect(() => {
    // console.log('SectionSuppliers', supplier_data)
  }, [])

  return (
    <>
      <div className='card border mb-6'>
        <div className='card-header bg-light'>
          <div className='card-title'>
            <h3 className='fs-6'>
              {/* {console.log('supplier_data', supplier_data, index)} */}
              {supplier_data.supplier_items[index].name}
            </h3>
          </div>
        </div>
        <div className='card-body'>
          <div className='row mb-4'>
            <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
              <span className='fw-bold'>配送先</span>
            </div>
            <div className='col-12 col-lg-8 d-flex align-items-center'>
              {(() => {
                const address = supplier_data._addressList.find(
                  (k: any) => k.pk === supplier_data.supplier_items[index].address
                )

                const address_prefecture = supplier_data._parameters[
                  '/Define/General/Prefecture'
                ]?.find((k: any) => {
                  return k.value == address?.address_prefecture
                })?.label

                // console.log('address_prefecture', address_prefecture)

                return address ? (
                  <>
                    <div>
                      {address.company_name}
                      <br />
                      {address.lastname} {address.firstname}
                      <br />
                      {address.address_postal_code}
                      <br />
                      {address_prefecture}
                      {address.address_city}
                      {address.address_street}
                      {address.address_building_name && address.address_building_name}
                    </div>
                  </>
                ) : (
                  'No matching address found'
                )
              })()}
            </div>
          </div>
          {supplier_data.supplier_items[index]['shipping'] &&
            // Object.keys(supplier_data.supplier_items[index]['shipping']).map(
            supplier_data.supplier_items[index]['shipping'].map(
              (shipping_data: any, listIdx: number) => (
                <div className='row mb-2'>
                  <div
                    className='col-12 bg-light px-4 mb-4'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseExample'
                    aria-expanded='false'
                    aria-controls='collapseExample'
                  >
                    <span className='fw-bold'>
                      商品内訳（配送方法：
                      {shipping_data.name}）
                    </span>
                  </div>
                  <div className='col-12 px-4'>
                    {shipping_data.list.map((listItem: any, listIdx: number) => (
                      <div key={listIdx} className='row mb-2 border-bottom border-bottom-lg-0'>
                        <div className='col-12 col-lg-5 border-bottom-lg'>
                          {listItem.product_name} - {listItem.variation_name}
                        </div>
                        <div className='col-12 col-lg-5 border-bottom-lg'>
                          単位：
                          {
                            supplier_data._parameters['/Define/Product/Units']?.find(
                              (k: any) => k.id == listItem.unit
                            )?.label
                          }{' '}
                          x {listItem.quantity}
                        </div>
                        <div className='col-12 col-lg-2 border-bottom-lg text-end'>
                          ￥{formatNumber(listItem.calcPrice)}
                        </div>
                      </div>
                    ))}
                    <div className='row mb-2'>
                      <div className='col-12 col-lg-5 border-bottom-lg'>送料</div>
                      <div className='col-12 col-lg-5 border-bottom-lg'></div>
                      <div className='col-12 col-lg-2 border-bottom-lg text-end'>
                        ￥ {formatNumber(shipping_data.shippingPrice)}
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
                        <span className='fw-bold'>お届け希望日</span>
                      </div>
                      <div className='col-12 col-lg-8 d-flex align-items-center'>
                        {shipping_data.desired_delivery_date}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
                        <span className='fw-bold'>お届け希望時間</span>
                      </div>
                      <div className='col-12 col-lg-8 d-flex align-items-center'>
                        {shipping_data.desired_delivery_time}
                      </div>
                    </div>
                  </div>
                </div>
                // ))}
              )
            )}

          <div className='row mb-2 border-bottom border-bottom-lg-0 mt-4'>
            <div className='col-12 col-lg-5 border-bottom-lg'>商品計</div>
            <div className='col-12 col-lg-5 border-bottom-lg'></div>
            <div className='col-12 col-lg-2 border-bottom-lg text-end fs-6 fw-bold'>
              ￥{formatNumber(supplier_data.supplier_items[index].supplierPrice)}
            </div>
          </div>

          <div className='row mb-2 border-bottom border-bottom-lg-0 mt-4'>
            <div className='col-12 col-lg-5 border-bottom-lg'>送料合計</div>
            <div className='col-12 col-lg-5 border-bottom-lg'></div>
            <div className='col-12 col-lg-2 border-bottom-lg text-end fs-6 fw-bold'>
              ￥{formatNumber(supplier_data.supplier_items[index].shippingPrice)}
            </div>
          </div>

          <div className='row mb-2 border-bottom border-bottom-lg-0 mt-4'>
            <div className='col-12 col-lg-5 border-bottom-lg'>総合計</div>
            <div className='col-12 col-lg-5 border-bottom-lg'></div>
            <div className='col-12 col-lg-2 border-bottom-lg text-end fs-6 fw-bold'>
              ￥
              {formatNumber(
                supplier_data.supplier_items[index].supplierPrice +
                  supplier_data.supplier_items[index].shippingPrice
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
              <span className='fw-bold'>支払方法</span>
            </div>
            <div className='col-12 col-lg-8 d-flex align-items-center'>
              {supplier_data.supplier_items[index].payment_method === 'credit_card'
                ? 'クレジットカード'
                : ''}
              {supplier_data.supplier_items[index].payment_method === 'later_payment'
                ? '後Pay'
                : ''}
            </div>
          </div>
          {supplier_data.supplier_items[index].payment_method &&
            supplier_data.supplier_items[index].payment_method === 'credit_card' && (
              <div className='row'>
                <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
                  <span className='fw-bold'>クレジットカード</span>
                </div>
                <div className='col-12 col-lg-8 d-flex align-items-center'>
                  {supplier_data._cardList[supplier_data.supplier_items[index].id].find(
                    (k: any) => {
                      // console.log(
                      //   'k',
                      //   k,
                      //   supplier_data.supplier_items[0].id,
                      //   k.cardSeq,
                      //   supplier_data.supplier_items[index].cardSeq,
                      //   k.cardSeq === supplier_data.supplier_items[index].cardSeq
                      // )
                      return k.cardSeq === supplier_data.supplier_items[index].cardSeq
                    }
                  )?.cardNo || 'カード情報がありません'}
                </div>
              </div>
            )}
          <div className='row'>
            <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
              <span className='fw-bold'>配送に関する備考</span>
            </div>
            <div className='col align-self-center'>{supplier_data.supplier_items[index].note}</div>
          </div>
        </div>
      </div>
    </>
  )
}
