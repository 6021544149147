import React from 'react'
import { toAbsoluteUrl } from '@/_metronic/helpers'
import { useNavigate } from 'react-router-dom'

export default function PlanComplete() {

    const navigate = useNavigate()

    return (
        <div className='card p-10'>
            <div className='mb-13 text-center'>
                <h1 className='fs-2hx fw-bold mb-5'>お申込みありがとうございました。</h1>
                <div className='fw-semibold fs-5'>
                    お申込み内容の確認の為、ご登録のアドレスに
                </div>
                <div className='fw-semibold fs-5'>
                    詳細をお送りしております。
                </div>
            </div>
            <div className="my-5">
                <div className='d-flex' style={{ justifyContent: 'center' }}>
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/logo.png')}
                        className='app-sidebar-logo-default theme-light-show'
                    />
                </div>
            </div>
            <div className="my-5 text-center">
                <h1 className='fs-4x'>GO!GO! CashLess</h1>
            </div>
            <div className="text-center">
                <button
                    className='btn btn-dark'
                    onClick={() => {
                        navigate('/plan')
                    }}
                >
                    トップページへ戻る
                </button>
            </div>
        </div>
    )
}
