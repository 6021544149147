/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
// import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import { useLayout } from 'src/_metronic/layout/core'
import {Spin, ConfigProvider} from 'antd'
import {FooterWrapper} from 'src/_metronic/layout/components/footer'

const AuthLayout = () => {

  const {isLoading} = useLayout()

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
      <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
        <div className='d-flex flex-column flex-row-fluid w-lg-50 p-3 p-mb-10 order-2 order-lg-2'>
          <div className='d-flex flex-center flex-column flex-row-fluid'>         
            <div className="row mt-6 mb-8">
              <div className="col-10 offset-1">
                <div className='d-flex justify-content-center'>
                  <img src={toAbsoluteUrl('/media/logos/top-logo.png')} className="img-fluid w-75" alt='' />
                </div>
              </div>
            </div>
            <div className='p-10 bg-white' style={{width: "100%", maxWidth: "500px"}}>
              <Spin tip="Loading" spinning={isLoading} size='large'>
                <Outlet />
              </Spin>
            </div>
          </div>
          <FooterWrapper />
        </div>
      </div>
  )
}

export {AuthLayout}
