import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Modal } from 'react-bootstrap';
import {showSuccessMessage} from 'src/app/components/sweetAlert'
import {buyerSettingUpdatePost,buyerSettingGet} from 'src/app/modules/api'
import { useAuth } from 'src/app/modules/auth'
import { useLayout } from 'src/_metronic/layout/core'
const options1:any = [
  { id: 'disabled', label: "自動承認しない" },
  { id: 'enabled', label: "自動承認する" },
]


const PaymentLoginSet = ({ resData }: any) => {
  
  const {setContentLoading} = useLayout()
  const { auth } = useAuth()
  const [userdata, setUserdata] = useState<any>({
    // user_supplier_approval_setting: 0,
    price_change_approval_request: 0,
    product_weight_setting_use: 0,
    out_of_stock_display_setting: 0,
  }); // 表单数据
  useEffect(() => {
    //setUserdata({...resData})
    init()
  }, [])
  const [loading, setLoading] = useState(false)
  //const [data, setData] = useState(resData)
  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...formik.values, ...fieldsToUpdate}
    console.log(updatedData,'updatedData')
    setUserdata(updatedData)
    formik.setValues(updatedData)
  }
  const init = async ()=>{
    setContentLoading(true)

    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token}`
    }

    try {
    const response: any = await buyerSettingGet("auth.username",headers)
    console.log('ppuserdata1----',response);
    let newData:any={}
    newData.price_change_approval_request= response.price_change_approval_request
    newData.product_weight_setting_use= response.product_weight_setting_use
    newData.out_of_stock_display_setting= response.out_of_stock_display_setting
    console.log('ppuserdata----',userdata);

    // let newData = JSON.parse(JSON.stringify(userdata))
    console.log('pp----',newData);
    
    setUserdata(newData)
    setContentLoading(false)
    } catch(err) {
      setContentLoading(false)
    }
  }
  const formik: any = useFormik<any>({
    initialValues: userdata as any,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      setLoading(true)
      console.log()
      let obj = values
      let data = {
        pk: resData.pk,
        update_user: resData.user_id,
        price_change_approval_request: userdata.price_change_approval_request,
        // product_weight_setting_use: obj.product_weight_setting_use,
        // out_of_stock_display_setting: obj.out_of_stock_display_setting,
      }
      let headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      }
      buyerSettingUpdatePost(data, {headers})
        .then((res: any) => {
          setLoading(false)
          showSuccessMessage('更新しました')
        })
        .catch((err: any) => {
          setLoading(false) 
        })
    },
  })


  return (
    <div className="container-fluid">
      <div className="card mb-6 shadow-sm">
        <div className="d-flex align-items-center card-header">
          <span className="card-title fw-bold m-0">共通設定</span>
        </div>
        <div className="collapse show">
          <form
            id='kt_modal_add_user_form'
            className='container-fluid'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='card-body p-9'>
            
              <div className='mb-5'>
                <div className='form-label fs-5 fw-bold mb-4'>価格変更の承認依頼について</div>
                <div>
                  {options1.map((item:any) => {
                    return (
                      <div
                        className='form-check form-check-inline pe-3'
                        key={item.id}
                      >
                        <input
                          className='form-check-input'
                          type='radio'
                          value={item.id}
                          checked={userdata.price_change_approval_request === item.id}
                          onChange={() => {
                            formik.values.price_change_approval_request = item.id
                            updateData(formik.values)
                          }}
                        />
                        <label className='form-check-label'>{item.label}</label>
                      </div>
                    )
                  })}
                </div>
              </div>
           
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' data-kt-users-modal-action='submit' className='btn btn-primary'>
                {!loading && '更新'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    更新...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            </div>
          </form>
        </div>
      </div>
     
    </div>
  )
}
export { PaymentLoginSet }