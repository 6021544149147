import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

export default function TransactionAuth() {
  const [status, setStatus] = useState<string>('')
  const navigate = useNavigate()

  useEffect(() => {
    // 親タブに情報を送信
    if (window.opener && !window.opener.closed) {
      // window.opener.postMessage({auth: 'ok'})
      setStatus('ok')
    } else {
      // navigate('/error/404')
    }
  }, [])

  useEffect(() => {
    if (status === 'ok') {
      window.close()
    }
  }, [status])

  return <div>認証する画面だよ</div>
}
