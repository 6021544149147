import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import {useFormik} from 'formik'
import {useAuth} from '../../auth'
import * as Yup from 'yup'
import clsx from 'clsx'
import ProcessButton from 'src/app/components/part/ProcessButton'

interface FormValues {
  code: string
}

interface VerificationModalProps {
  // username: string;
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const VerificationSchema = Yup.object().shape({
  code: Yup.string().required('認証コードは必須です。'),
})

const initialValues = {
  code: '',
}

function VerificationModal({showModal, setShowModal}: VerificationModalProps) {
  const [resentMessageSuccess, setResentMessageSuccess] = useState<string>('')
  const [resentMessageFalse, setResentMessageFalse] = useState<string>('')
  const {auth} = useAuth()
  // console.log("un:", auth.username);
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: VerificationSchema,
    onSubmit: async (
      values: {
        code: string
      },
      {setStatus, setSubmitting}
    ) => {
      console.log('values', values)
      try {
        const result = await auth.confirmSignUp(values.code)
        if (result.success) {
          navigate({pathname: '/dashboard'})
          // setShowModal(true);
        } else {
          throw new Error(result.message)
        }
      } catch (error) {
        const typedError = error as Error
        console.error(typedError)
        setStatus(typedError.message)
      }
    },
  })

  const resendCode = async () => {
    try {
      const result = await auth.resendSignUp(auth.username)
      if (result.success) {
        setResentMessageSuccess(result.message)
        // navigate({ pathname: '/dashboard' });
        // setShowModal(true);
      } else {
        throw new Error(result.message)
      }
    } catch (error) {
      const typedError = error as Error
      console.error(typedError)
      setResentMessageFalse(typedError.message)
    }
  }

  return (
    <>
      {/* <Button onClick={() => setShowModal(true)}>Open Verification Modal</Button> */}
      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop='static' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>まもなく登録完了です。</Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <p>入力したメールアドレスに送信されたコードを入力して、登録を完了してください。</p>

            {formik.status && (
              <div className='mb-lg-5 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            {resentMessageSuccess && (
              <div className='mb-lg-5 alert alert-success'>
                <div className='alert-text font-weight-bold'>{resentMessageSuccess}</div>
              </div>
            )}

            {resentMessageFalse && (
              <div className='mb-lg-5 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{resentMessageFalse}</div>
              </div>
            )}

            <div>
              <input
                placeholder='検証コード'
                {...formik.getFieldProps('code')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.code && formik.errors.code},
                  {
                    'is-valid': formik.touched.code && !formik.errors.code,
                  }
                )}
                type='text'
                name='code'
                autoComplete='off'
                // onChange={formik.handleChange}
                // value={formik.values.email}
              />
              {formik.touched.code && formik.errors.code && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.code}</span>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={() => setShowModal(false)}>
              
            </Button> */}

            <Button variant='info' onClick={() => resendCode()}>
              検証コードを再送信
            </Button>

            <Button variant='primary' type='submit'>
              コードを検証
            </Button>
            {/* <ProcessButton
              type='submit'
              className='btn-primary'
              onClick={async () => {
                await formik.handleSubmit
              }}
              processingText='コードを検証'
            >
              コードを検証
            </ProcessButton> */}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default VerificationModal
