/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {slip, slipInit} from 'src/app/modules/api';
import * as Yup from "yup";
import {useFormik} from "formik";
import { showSuccessMessage} from '@/app/components/sweetAlert';
import {message} from "antd";
import { useAuth } from 'src/app/modules/auth'

const Slip: FC = () => {
  useEffect(() => {
    apply()
  },[])
  const [loading, setLoading] = useState<boolean>(false)
  const [pkVal,setPkVal] = useState<string>('')
  const {auth} = useAuth();
  let headers = {
    "Content-Type":"application/json",
    Authorization:`Bearer ${auth.token}`
  }
  const apply = () => {
    slipInit({},headers).then((res: any) => {
      if(res?.rows.length){
        setPkVal(res.rows[0].pk)
        let che: any  = document.getElementById('checkbox')
        che.checked = res.rows[0].display_price_flag == 'yes'? true : false
        formik.resetForm({
          values:{
            attension:res.rows[0].attension,
            display_price_flag:res.rows[0].display_price_flag == 'yes'? true : false,
            header_message:res.rows[0].header_message,
            title:res.rows[0].title,
            notes:res.rows[0].notes,
          }
        })
      }
    }).catch((err: any) => {
      message.error("初始化失敗")
    })

  }
  const validationSchema = Yup.object().shape({
    header_message: Yup.string()
        .required('ヘッダーの挨拶文は必須です。')
        .min(1, '1文字以上で入力してください。')
        .max(255, '255文字以下で入力してください。'),
    notes: Yup.string()
        .required('備考は必須です。')
        .min(1, '1文字以上で入力してください。')
        .max(255, '255文字以下で入力してください。'),
    title: Yup.string()
        .required('書類タイトルは必須です。')
        .min(1, '1文字以上で入力してください。')
        .max(255, '255文字以下で入力してください。'),
  })
  const formik:any = useFormik<any>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      /**
       * 納品書 宛先
       * 1        会社名（御中）    （默认）
       * 2        会社名＋部署名（御中）
       * 3        会社名＋部署名＋担当者名（様）
       */
      attension: '1',
      /**
       * 納品書 納品書での金額を表示する
       * yes        する
       * no        しない
       */
      display_price_flag:false,
      /**
       * 納品書 ヘッダーの挨拶文
       */
      header_message: '',
      /**
       * 納品書 備考
       */
      notes:'',
      /**
       * 納品書 書類タイトル
       */
      title: '',
    },
    validationSchema: validationSchema,
    onSubmit:async (values) => {
      let query:any = {...values}
      query.display_price_flag =  query.display_price_flag? "yes" : 'no'
      slip({...query,pkVal}, {headers}).then((res: any) => {
        setLoading(false)
        apply()
        showSuccessMessage(res.message)
      }) .catch((err: any) => {
        setLoading(false)
      })
    },
  });

  const  clearForm = ()=> {
   let che: any  = document.getElementById('checkbox')
    che.checked = false
    formik.resetForm({
      values:{
        attension: '1',
        header_message: '',
        display_price_flag:false,
        notes:'',
        title: '',
      }
    })
  }

  return (
    <div id='slip' className='card tab-pane'>
      <form onSubmit={formik.handleSubmit}>
        <div className='card-body p-9'>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3  required'>書類タイトル</div>
            </div>
            <div className='col-lg-8'>
              <input
                type='text'
                className='form-control form-control-sm'
                name='Search Team'
                placeholder='納品書'
                {...formik.getFieldProps('title')}
              />
              {formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>宛先</div>
            </div>
            <div className='col-lg-8'>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                {...formik.getFieldProps('attension')}
              >
                <option value='1'>会社名(御中)</option>
                <option value='2'>会社名＋部署名(御中)</option>
                <option value='3'>会社名＋部署名＋担当者名(様)</option>
              </select>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3  required'>ヘッダーの挨拶文</div>
            </div>
            <div className='col-lg-8'>
              <input
                placeholder='ヘッダーの挨拶文'
                type='text'
                className='form-control form-control-sm'
                {...formik.getFieldProps('header_message')}
              />
              {formik.errors.header_message && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.header_message}</div>
                  </div>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3  required'>備考</div>
            </div>
            <div className='col-lg-8'>
              <textarea
                placeholder='備考'
                className='form-control h-100px'
                {...formik.getFieldProps('notes')}
              ></textarea>
              {formik.errors.notes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.notes}</div>
                  </div>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>その他帳票設定</div>
            </div>
            <div className='col-lg-8'>
              <label className='form-check form-switch form-check-custom'>
                <input
                  id={'checkbox'}
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  {...formik.getFieldProps('display_price_flag')}
                />
                <span className='form-check-label text-dark fs-6'>
                  納品書での金額（単価・販売価格・送料・総額）を表示するデフォルト設定
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button className='btn btn-lightbtn-active-light-primary me-2' onClick={clearForm}>キャンセル</button>
          <button type='submit' disabled={loading}  className='btn btn-primary' data-kt-indicator={loading?'on':'off'}>
               <span className="indicator-label">
                 保存
              </span>
            <span className="indicator-progress"><span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
          </button>
        </div>
      </form>
    </div>
  )
}
export {Slip}
