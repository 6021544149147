import { Fragment, useEffect, useState } from 'react'
import { KTCard, KTSVG } from '@/_metronic/helpers'
import { CustomFieldActionsCell } from './tabel/endPointActionsCell'
import { TableLoading } from './tabel/tableLoading'
// import { useNavigate } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { useLayout } from 'src/_metronic/layout/core'
import { useAuth } from "src/app/modules/auth"
import { tempLateList, deleteTemplate } from 'src/app/modules/api'
import { useLocation, useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { Pagination } from 'src/app/components/pagination'
const class1 = 'page-item previous'
const class2 = 'page-item previous active'
const class3 = 'page-item previous disabled'

const options = [
  { id: '0', name: '編集する' },
  { id: '1', name: '削除' },
]

export default function CsvTemplate() {
  const navigate = useNavigate()
  const [arr, setArr] = useState<any>([
    {
      id: 'xxxxx-xxxx-xxxx-xxxx',
      displayId: '3',
      title: '全項目受注CSV',
      createAt: '2023/10/10 11:10:15',
      updateAt: '2023/10/10 11:10:15'
    },
    {
      id: 'xxxxx-xxxx-xxxx-xxxx',
      displayId: '2',
      title: 'B2クラウド形式',
      createAt: '2023/10/10 11:10:15',
      updateAt: '2023/10/10 11:10:15'
    },
    {
      id: 'xxxxx-xxxx-xxxx-xxxx',
      displayId: '1',
      title: '●●形式CSVテンプレート',
      createAt: '2023/10/10 11:10:15',
      updateAt: '2023/10/10 11:10:15'
    }
  ]) //总数据
  const [queryParams, setQueryParams] = useState<any>({
    pageNum: 1,
    pageSize: 10,
    total: 0,
    name: "",
    status: ""
  }) // 分页参数
  const [data, setData] = useState<any>([]) // 数据列表
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
  const [selectedList, setSelectList] = useState<any>([]) // 选中的项
  const [nowPage, setNowPage] = useState<any>(1) // 当前页
  const [pageSize, setPageSize] = useState<any>(10) // 每页多少条
  let [page, setPage] = useState<any>([]) // 页码列表
  const { setContentLoading } = useLayout()
  const { auth } = useAuth();
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${auth.token}`
  }
  // 单行 选择操作
  const operationModification = (i: string | number, val: any) => {
    let newdata = JSON.parse(JSON.stringify(data))
    newdata[i].controls = val
    setData(newdata)
  }
  const deleteList = async () => {
    console.log("点击删除");

  }
  // 適用
  const apply = () => { }
  //处理页码
  const changePage = (sumPage: any, size?: number | string) => {
    page = []
    let num = Math.ceil(sumPage / Number(size ? size : pageSize))
    for (let i = 1; i < num + 1; i++) {
      page.push(i)
    }
    let newPage = JSON.parse(JSON.stringify([...page]))
    setPage(newPage)
  }
  //获取当前页码的列表
  const getPageList = async (page: any) => {
    setIsLoading(true)
    let newArr = arr.slice((page - 1) * pageSize, page * pageSize)
    setData(newArr)
    setNowPage(page)
    setIsLoading(false)
  }
  // 改变当前显示数量
  const changePageSize = (size: string | number) => {
    setPageSize(size)
    changePage(arr.length, size)
    let newArr = arr.slice((nowPage - 1) * Number(size), nowPage * Number(size))
    setData(newArr)
  }
  // 改变选中列表
  const changeSelectList = (obj: any) => {
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = []
        data.forEach((item: any) => {
          arr.push(item.id)
        })
        setSelectList(arr)
      } else {
        selectedList.push(obj.id)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([])
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id
        })
        selectedList.splice(idx, 1)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    }
  }

  // 获取列表数据
  const initData = () => {
    getAllList(queryParams.pageNum, queryParams.pageSize)
  }

  const getAllList = (page: any, pageSize: any, value?: any) => {
    queryParams.pageSize=pageSize

    console.log(value)
    let params = {
      pageNum: page,
      pageSize: pageSize,
      name: queryParams.name,
      status: value ? value : ''
    }

    setContentLoading(true)
    tempLateList(params, headers).then((res: any) => {
      console.log(res, "接口返回的数据")
      setQueryParams({ ...queryParams, total: res.total })
      // let arr = res.rows.map((item: any) => {
      //     item.children = []
      //     item.id = item.pk
      //     options.map((v) => {
      //         if (item.status == v.id) {
      //             item.statusName = v.name
      //         }
      //     })

      //     return item
      // })
      setData(res.rows)
      // console.log(arr, "获取列表数据1");
      console.log(res.calcNum, "获取条数总览");


      setContentLoading(false)
      // setData(arr)
    })
  }

  useEffect(() => {
    getAllList(queryParams.pageNum, queryParams.pageSize)
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      <div
        className='mb-6'
        style={{ display: 'flex', justifyContent: 'end' }}
      >
        {/* <div className='card-title fs-3 fw-bold'>受注CSVテンプレート一覧</div> */}
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', height: 43 }}>
          <div style={{ height: 43 }}>
            <button
              type='button'
              onClick={async () => {
                navigate('/order/csv/id')
              }}
              className='btn btn-primary'
              data-kt-docs-datatable-subtable='expand_row'
            >
              CSVテンプレートの追加
            </button>
          </div>
        </div>
      </div>
      <KTCard resetSidePaddings={true}>
        <div className='table-responsive'>
          <table
            className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer'
          >
            <thead className='table-light'>
              <tr className='text-start fs-6 text-uppercase gs-0'>
                <th>
                  ID
                </th>
                <th id='title'>CSVテンプレート名</th>
                <th id='createAt'>作成日時</th>
                <th id='updateAt'>更新日時</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody className=''>
              {data.length > 0 ? (
                data.map((item: any, index: number) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.management_id}</td>
                      <td>
                        <a href='#' className='' onClick={e => {
                          e.preventDefault();
                          navigate(`/order/csv/${item.id}`, {
                            state: item,
                        })
                        }}>{item.name}
                        </a>
                      </td>
                      <td>  {item.create_at.slice(0, 10)} <br /> {item.create_at.slice(11, 19)}</td>
                      <td>  {item.update_at.slice(0, 10)} <br /> {item.update_at.slice(11, 19)}</td>
                      <td style={{ minWidth: 90 }}>
                        <CustomFieldActionsCell
                          id={item.id}
                          item={item}
                          nowPage={nowPage}
                          index={index}
                          deleteList={initData}
                          controls={item.controls}
                          operationModification={operationModification}
                        ></CustomFieldActionsCell>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={17}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* 分页 */}
          {queryParams.total != 0 && (
            <Pagination
              currentPage={getAllList}
              page={queryParams.pageNum}
              pageSize={queryParams.pageSize}
              total={queryParams.total}
            />
          )}
          {isLoading && <TableLoading></TableLoading>}
        </div>
      </KTCard>
    </div>
  )
}
