import {SidebarMenuMain} from './SidebarMenuMain'
import {KTSVG} from '../../../../helpers'
import {useAccount} from 'src/app/modules/accounts/AccountProvider'

const SidebarMenu = () => {
  const {account} = useAccount()

  return (
    <div className='app-sidebar-menu hover-scroll-overlay-y flex-column-fluid flex-shrink-1'>
      <div
        id='kt_app_sidebar_menu_wrapper'
        className='app-sidebar-wrapper my-5 d-flex flex-column justify-content-between'
        // data-kt-scroll='true'
        // data-kt-scroll-activate='true'
        // data-kt-scroll-height='auto'
        // data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        // data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        // data-kt-scroll-offset='5px'
        // data-kt-scroll-save-state='true'
      >
        <div
          id='kt_app_sidebar_menu_scroll'
          className='menu menu-column menu-rounded menu-sub-indention px-3'
          // style={{height: 'calc(100vh - 7.5rem)'}}
          // data-kt-menu='true'
          // data-kt-menu-expand='false'
        >
          <div className='d-flex border-bottom border-bottom-dotted pb-6 mb-4'>
            <div className='symbol symbol-50px'>
              <img src={account.companyImage} alt=''></img>
            </div>
            <div className='flex-row-fluid flex-wrap ms-5'>
              <div className='d-flex'>
                <div className='flex-grow-1'>
                  <span className='d-block fw-semibold text-white'>{account.companyName}</span>

                  <span className='fs-6 fw-bold text-white'>{account.userName}</span>
                </div>
              </div>
            </div>
          </div>

          <SidebarMenuMain />
        </div>
      </div>
    </div>
  )
}

export {SidebarMenu}
