import { getServer, postServer, deleteServer } from 'src/service/http'

// 送料無料設定税区分取得
export function taxSettingDetailsListAll(param: object,headers:object={}) {
    return getServer(`/tax-setting-details-list-all`, { ...param},{...headers})
}

// 税設定情報取得
export function taxSettingGet(param: object,headers: object={}) {
    return getServer(`/tax-setting-get`, { ...param },{...headers})
}