import {useEffect, useState} from 'react'
import {KTCard} from '@/_metronic/helpers'
import { useLayout } from 'src/_metronic/layout/core'
import {useNavigate} from 'react-router-dom'
import {parameterStoreGet} from 'src/app/modules/api'
import { useAuth } from 'src/app/modules/auth'
import { notificationMethodGet, mailSettingGet, mailSettingUpdate } from 'src/app/modules/api';

import MailSetting from './mailSetting'

export default function Notification() {
  const navigate = useNavigate()
  const {isContentLoading, setContentLoading} = useLayout()

  const [dataList, setDataList] = useState<any>([])
  const [mailClassification, setmailClassification] = useState<any>([])

  const getParameter = async () => {
    setContentLoading(true)
    const res: any = await parameterStoreGet({names: ['/Define/Notification/Method']}, {})
    const parameters = JSON.parse(res['/Define/Notification/Method'])
    console.log("parameters11", parameters)
    setmailClassification(parameters)
    const resp:any = await notificationMethodGet({})

    parameters.forEach((e:any) => {
      e.status = 0
      const obj = resp.find((k:any)=> k.id == e.id)
      if (obj?.status) e.status = obj.status
      if (obj?.method) e.method = obj.method
      if (obj?.target) e.target = obj.target
    });
    console.log('parameters22',parameters)
    setDataList(parameters)
    setContentLoading(false)
  }
  // 获取列表数据
  const initData = () => {
    getParameter()
  }

  useEffect(() => {
    initData()
  }, [])

  return (
    <div style={{position: 'relative'}}>
      <KTCard resetSidePaddings={true}>
        <div className='table-responsive py-5 '>
          <table
            className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer '
          >
            <thead className='table-light'>
              <tr className='text-start fs-6 text-uppercase gs-0'>
                <th id='mailClassification'>メール区分</th>
                <th id='usageStatus'>利用ステータス</th>
                <th id='eventMethod'>イベント方法</th>
                <th id='controls'>イベント受注者</th>
              </tr>
            </thead>
            <tbody className=''>
              {dataList.length > 0 ? (
                dataList.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{mailClassification.find((k: any) => k.id === item.id)?.label}</td>
                      <td>
                        {item.status == '1' ? <span className='badge text-bg-success text-light'>利用中</span> : <span className='badge text-bg-danger text-light'>利用停止中</span>}
                      </td>
                      <td>{item.method}</td>
                      <td>{item.target}</td>
                      <td style={{minWidth: 90}}>
                        <button
                          type='button'
                          className='btn  btn-sm  btn-primary'
                          onClick={() => {
                            navigate(`/notification/${item.id}`, {state: {item}})
                          }}
                        >
                          編集
                        </button>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={17}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {dataList.length === 0 && !isContentLoading && <span>データがありません</span>}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        </KTCard>

        {/* メールシステム設定 */}
        <MailSetting />
    </div>
  )
}
