import {Fragment, useEffect, useState, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import {KTCard, KTSVG} from '@/_metronic/helpers'
import {showSuccessMessage, showErrorMessage, showWarningMessage} from '@/app/components/sweetAlert'
import {Pagination} from '@/app/components/pagination'
import {Loading} from '@/app/components/loading'
import {CustomFieldActionsCell} from './tabel/endPointActionsCell'
import {TableExport} from './tabel/tableExport'
import {TableImport} from './tabel/tableImport'
import PreviewModal from 'src/app/pages/supplier/product-regist/components/PreviewModal'
import 'react-datepicker/dist/react-datepicker.css'
import {downloadFiles} from '@/utils'
import {useLayout} from 'src/_metronic/layout/core'
import {
  productListGet,
  productStatusBulkUpdate,
  productInfoDelete,
  productBulkDelete,
  productInfoDownload,
} from 'src/app/modules/api'
import {useIsWidthSize, formatNumber, getParameterStore} from 'src/app/modules/utils'
import {useAuth} from 'src/app/modules/auth'
import {use} from 'echarts'

const options = [
  {id: 'delete', name: '削除'},
  {id: 'publish', name: '公開'},
  {id: 'hidden', name: '非公開'},
  // {id: 'draft', name: '下書き'},
]

const masterClassOption = [
  {id: '0', name: 'なし'},
  {id: '1', name: '海鮮品'},
  {id: '2', name: '野菜'},
  {id: '3', name: '鶏肉'},
  {id: '4', name: 'チーズ・乳製品'},
  {id: '5', name: '乾物'},
]

export default function Product() {
  const {auth} = useAuth()
  let headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }
  const navigate = useNavigate()
  const {setContentLoading} = useLayout()
  const [totalData, setTotalData] = useState<any>({})
  const [modalShow, setModalShow] = useState(false)
  const [previewProduct, setPreviewProduct] = useState<any>({})
  const [datas, setDatas] = useState<any>([]) // 数据列表
  const [selectType, setSelectType] = useState<string>('') // 左上角选择框
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
  const [selectedList, setSelectList] = useState<any>([]) // 选中的项
  const [parameters, setParameters] = useState<any>({})
  const [filterData, setFilterData] = useState<any>({
    publish: 0,
    hidden: 0,
    draft: 0,
    all: 0,
  }) // 选中的项
  const [queryParams, setQueryParams] = useState<any>({
    status: '',
    product_name: '',
    page: 1,
    pageNum: '1',
    pageSize: '50',
    total: 0,
  }) // 分页参数
  const parentRef = useRef<HTMLDivElement>(null)

  // 获取列表数据
  const getData = async (pageNum: any, pageSize: any) => {
    console.log('pageNum', pageNum, pageSize)
    queryParams.pageSize = pageSize
    queryParams.pageNum = pageNum
    queryParams.page = pageNum
    // setQueryParams({...queryParams, pageNum: pageNum, pageSize: pageSize})

    // console.log(queryParams, '查询参数')

    setContentLoading(true)
    try {
      const res: any = await productListGet(
        {
          pageNum: queryParams.pageNum,
          pageSize: queryParams.pageSize,
          product_name: queryParams.product_name,
          status: queryParams.status,
        },
        headers
      )
      console.log('res', res)
      setTotalData(res.total)
      setFilterData({
        ...filterData,
        publish: res.publish,
        hidden: res.hidden,
        draft: res.draft,
        all: res.all,
      })
      setDatas(res.rows)
      setContentLoading(false)
    } catch (error) {
      setContentLoading(false)
      console.error('error', error)
    }
  }

  const search = () => {
    initData()
  }
  // 適用
  const apply = () => {
    if (selectType === 'delete') {
      showWarningMessage(
        '元に戻すことはできません。よろしいでしょうか？',
        () => {
          productBulkDelete({pkVal: JSON.stringify(selectedList)}).then((res) => {
            initData()
            showSuccessMessage('削除しました')
          })
        },
        '削除',
        'キャンセル'
      )
    } else if (selectType === 'draft') {
    } else {
      showWarningMessage(
        '選択した商品を、一括で更新しますか？',
        () => {
          productStatusBulkUpdate({pkVal: JSON.stringify(selectedList), status: selectType}).then(
            (res) => {
              initData()
              showSuccessMessage('更新しました')
            }
          )
        },
        '更新',
        'キャンセル'
      )
    }
  }

  // 改变选中列表
  const changeSelectList = (obj: any) => {
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = []
        datas.forEach((item: any) => {
          arr.push(item.pk)
        })
        setSelectList(arr)
      } else {
        selectedList.push(obj.id)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([])
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id
        })
        selectedList.splice(idx, 1)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    }
  }

  // 单行 选择操作
  const operationModification = (i: string | number, val: any) => {
    let newdata = JSON.parse(JSON.stringify(datas))
    newdata[i].controls = val
    setDatas(newdata)
  }

  const editStatus = (item: any, index: number) => {
    console.log('item.controls', item.controls, datas[index].controls)
    // 复制
    if (datas[index].controls == 'copy') {
      navigate(`/product/${item.product_id}?copy=1`, {state: item.pk})
    } else if (datas[index].controls == 'delete') {
      showWarningMessage(
        '商品基本情報することはできません',
        () => {
          productInfoDelete({pkVal: item.pk}).then((res) => {
            console.log('res', res)
            initData()
            showSuccessMessage('削除しました')
          })
        },
        '削除',
        'キャンセル'
      )
    } else if (datas[index].controls == 'publish' || datas[index].controls == 'hidden') {
      showWarningMessage(
        '商品を更新します',
        () => {
          productStatusBulkUpdate({
            pkVal: JSON.stringify([item.pk]),
            status: datas[index].controls,
          }).then((res) => {
            initData()
            showSuccessMessage('更新しました')
          })
        },
        '更新',
        'キャンセル'
      )
    } else if (datas[index].controls == 'draft') {
      showSuccessMessage('表示成功です')
    }
  }

  // 获取 导入格式
  const sendSearchRole = async (val: any) => {
    console.log('导入', val)
    const res: any = await productInfoDownload({})
    let thead = {
      product_id: '商品管理番号',
      product_name: '商品名',
      main_category: 'メインカテゴリ',
      condition: 'バリエーション有無',
      status: 'ステータス',
    }

    downloadFiles({
      data: res.url,
      tableHeader: thead,
      name: 'test',
    })
  }

  const goDetail = async (item: any) => {
    // #があるときは#で分割した最後の要素を取得
    const id = item.pk.split('#').pop()
    navigate(`/product/${id}`)
  }

  const goDetailVariation = async (item: any) => {
    // #があるときは#で分割した最後の要素を取得
    const id = item.pk.split('#').pop()
    navigate(`/product/${id}/variation`)
  }

  useEffect(() => {
    initParameters()
    initData()
  }, [])

  // 获取列表数据
  const initData = () => {
    getData(queryParams.page, queryParams.pageSize)
  }

  useEffect(() => {
    initData()
  }, [queryParams])

  const initParameters = async () => {
    let res: any = await getParameterStore({
      names: ['/Define/Product/Status'],
    })

    setParameters(res)
  }

  return (
    <div style={{position: 'relative'}}>
      <div className='d-flex justify-content-between align-item-center mb-6'>
        <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
          <select
            className='form-select '
            data-control='select2'
            style={{width: 200}}
            value={selectType}
            placeholder='一括操作'
            onChange={(e) => {
              setSelectType(e.target.value)
            }}
          >
            <option value={''}></option>
            {options.length !== 0 &&
              options.map((item: any, index: number) => {
                return (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                )
              })}
          </select>
          <button
            type='button'
            className='btn btn-primary'
            style={{marginLeft: 10}}
            onClick={() => {
              apply()
            }}
          >
            適用
          </button>
        </div>
        <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
          <div className='d-flex align-items-center position-relative'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              data-kt-user-table-filter='search'
              style={{marginRight: 10}}
              className='form-control w-250px ps-14'
              placeholder='検索'
              value={queryParams.product_name}
              onChange={(e) => {
                setQueryParams({...queryParams, product_name: e.target.value})
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.keyCode === 13) {
                  search()
                }
              }}
            />
          </div>
          {/* <div>
              <TableExport sendSearchRole={sendSearchRole} />
            </div> */}
          {/* <div>
              <TableImport sendSearchRole={sendSearchRole} />
            </div> */}
          <div>
            <button
              type='button'
              className='btn btn-primary'
              data-kt-docs-datatable-subtable='expand_row'
              onClick={() => {
                // catalog/add-product
                navigate('/product/edit')
              }}
            >
              新規商品登録
            </button>
          </div>
        </div>
      </div>
      <KTCard resetSidePaddings={true}>
        <div className='table-responsive '>
          <ScrollMenu
            parameters={parameters}
            parentRef={parentRef}
            queryParams={queryParams}
            bindClickStatus={(val: any) => {
              console.log('val', val)
              setQueryParams({...queryParams, status: val, pageNum: '1'})
            }}
            filterData={filterData}
          />
          {/* <p className='fw-bold' style={{cursor: 'pointer'}}>
            <span
              onClick={() => {
                queryParams.status = ''
                setQueryParams({...queryParams, status: ''})
                initData()
              }}
            >
              すべて（{filterData.all || 0}件）｜
            </span>
            <span
              onClick={() => {
                queryParams.status = 'publish'
                setQueryParams({...queryParams, status: 'publish'})
                initData()
              }}
            >
              公開（{filterData.publish || 0}件）｜
            </span>
            <span
              onClick={() => {
                queryParams.status = 'draft'
                setQueryParams({...queryParams, status: 'draft'})
                initData()
              }}
            >
              下書き（{filterData.draft || 0}件）｜　
            </span>
            <span
              onClick={() => {
                queryParams.status = 'hidden'
                setQueryParams({...queryParams, status: 'hidden'})
                initData()
              }}
            >
              非公開（{filterData.hidden || 0}件）｜
            </span>
            <span
              onClick={() => {
                // queryParams.status = ''
                // setQueryParams({ ...queryParams, status: '' })
                // initData()
              }}
            >
              ゴミ箱（{datas.filter((item: any) => item.status === '削除').length || 0}件）
            </span>
          </p> */}
          <table
            className='table align-middle table-row-bordered fs-6 gy-1 gx-6 gy-0 dataTable no-footer'
            style={{minWidth: 1000}}
          >
            <thead className='table-light'>
              <tr className='text-start fs-6 text-uppercase gs-0'>
                <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      data-kt-check-target='#kt_table_users .form-check-input'
                      checked={selectedList.length === datas.length && selectedList.length !== 0}
                      onChange={(e) => {
                        let obj = {
                          id: 'all',
                          value: e.target.checked,
                        }
                        changeSelectList(obj)
                      }}
                    />
                  </div>
                </th>
                <th>商品管理番号</th>
                <th>商品名</th>
                <th>メインカテゴリ</th>
                <th>バリエーション有無</th>
                <th>ステータス</th>
                <th>編集</th>
                <th></th>
              </tr>
            </thead>
            <tbody className=''>
              {datas.length > 0 ? (
                datas.map((item: any, index: number) => (
                  <Fragment key={index}>
                    <tr>
                      <td>
                        <div className='form-check form-check-sm form-check-custom'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check-target='#kt_table_users .form-check-input'
                            checked={selectedList.includes(item.pk)}
                            onChange={(e) => {
                              let obj = {
                                id: item.pk,
                                value: e.target.checked,
                              }
                              changeSelectList(obj)
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <a
                          href='#'
                          onClick={(e) => {
                            e.preventDefault()
                            goDetail(item)
                          }}
                        >
                          {item.product_id}
                        </a>
                      </td>
                      <td>{item.product_name}</td>
                      <td>
                        {item.categorynames && item.categorynames.length > 0 && (
                          <span>{item.categorynames[0].join(' > ')}</span>
                        )}
                      </td>
                      <td>
                        {item.variations && item.variations.length > 0 ? (
                          <>
                            <a
                              href='#'
                              onClick={(e) => {
                                e.preventDefault()
                                goDetailVariation(item)
                              }}
                            >
                              {item.variations.length}件
                            </a>
                          </>
                        ) : (
                          '0件'
                        )}
                      </td>
                      <td>
                        {item.status == 'publish' ? '公開中' : ''}
                        {item.status == 'hidden' ? '非公開' : ''}
                        {item.status == 'draft' ? '下書き' : ''}
                        {item.status == 'reserve' ? '公開予約' : ''}
                      </td>
                      <td style={{minWidth: 90}}>
                        <CustomFieldActionsCell
                          id={item.id}
                          item={item}
                          getAllList={initData}
                          nowPage={queryParams.page}
                          index={index}
                          status={item.status}
                          controls={item.controls}
                          operationModification={operationModification}
                        ></CustomFieldActionsCell>
                      </td>
                      <td className='text-end' style={{display: 'flex', alignItems: 'center'}}>
                        <button
                          type='button'
                          className='btn btn-primary btn-sm fs-6'
                          // disabled={true}
                          data-kt-docs-datatable-subtable='expand_row'
                          onClick={(event) => {
                            event.preventDefault()
                            setModalShow(true)
                            setPreviewProduct(item)
                            // editStatus(item, index)
                            // goDetail(item)
                          }}
                        >
                          プレビュー
                        </button>
                      </td>
                    </tr>
                  </Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={17}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {isLoading && <Loading></Loading>}
      </KTCard>

      <div className='row mt-6'>
        <div className='col-12 d-flex justify-content-center'>
          {totalData != 0 && (
            <Pagination
              currentPage={getData}
              page={queryParams.page}
              pageSize={queryParams.pageSize}
              total={totalData}
            />
          )}
        </div>
      </div>
      {modalShow && previewProduct && (
        <PreviewModal
          show={modalShow}
          handleModalShow={() => setModalShow(false)}
          product={previewProduct}
        />
      )}
    </div>
  )
}

const ScrollMenu: React.FC<any> = ({
  parameters,
  parentRef,
  queryParams,
  bindClickStatus,
  filterData,
}: {
  parameters: any
  parentRef: any
  queryParams: any
  bindClickStatus: (param: any) => void
  filterData: any
}) => {
  const [isScrollable, setIsScrollable] = useState(false)

  const handleScroll = (direction: 'left' | 'right') => {
    const scrollAmount = direction === 'left' ? -25 : 25
    const duration = 300 // スクロールの合計時間
    const startTime = performance.now()

    const scroll = (currentTime: number) => {
      const elapsed = currentTime - startTime
      const progress = Math.min(elapsed / duration, 1) // 0から1の範囲で進捗を計算

      if (parentRef.current) {
        // 新しいスクロール位置を計算して適用
        parentRef.current.scrollLeft += scrollAmount * progress
      }

      // 進捗が1未満なら次のフレームで続行
      if (progress < 1) {
        requestAnimationFrame(scroll)
      }
    }

    requestAnimationFrame(scroll)
  }

  useEffect(() => {
    console.log('queryParams', queryParams)
  }, [])

  useEffect(() => {
    const parent: any = parentRef.current

    if (!parent) return

    const checkScrollability = () => {
      if (parent.scrollWidth > parent.clientWidth) {
        setIsScrollable(true)
      } else {
        setIsScrollable(false)
      }
    }

    // 子要素のサイズ変更を監視
    const resizeObserver = new ResizeObserver(() => {
      checkScrollability()
    })

    Array.from(parent.children).forEach((child: any) => resizeObserver.observe(child))

    // ウィンドウのリサイズイベントを監視
    window.addEventListener('resize', checkScrollability)

    // 初回レンダリング後にスクロール可能かどうかをチェック
    checkScrollability()

    // コンポーネントがアンマウントされたときにリスナーをクリーンアップ
    return () => {
      resizeObserver.disconnect()
      window.removeEventListener('resize', checkScrollability)
    }
  }, [parameters])

  return (
    <>
      <div className='d-flex bg-white'>
        <div
          className={`flex-glow-1 d-flex text-nowrap overflow-auto w-100 `}
          ref={parentRef}
          style={{
            scrollbarWidth: 'none',
          }}
        >
          <div className='fw-normal p-2'>
            <a
              href='#'
              className={
                `btn btn-sm btn-active-light-primary me-2 fs-6 fw-bold` +
                (queryParams.status === '' ? ' active' : '')
              }
              onClick={(e) => {
                e.preventDefault()
                bindClickStatus('')
              }}
            >
              すべて({filterData.all || 0})
            </a>
            {parameters['/Define/Product/Status']?.map((item: any) => (
              <a
                href='#'
                className={
                  `btn btn-sm btn-active-light-primary me-2 fs-6 fw-bold` +
                  (queryParams.status === item.value ? ' active' : '')
                }
                onClick={(e) => {
                  e.preventDefault()
                  bindClickStatus(item.value)
                }}
              >
                {item.label}({filterData[item.value] || 0})
              </a>
            ))}
          </div>
        </div>
        <div className='d-flex align-items-center px-2'>
          {isScrollable && (
            <>
              <button
                type='button'
                className='btn btn-sm btn-white btn-outline btn-icon me-2'
                onClick={() => handleScroll('left')}
              >
                <i className='bi bi-arrow-left fs-2'></i>
              </button>

              <button
                type='button'
                className='btn btn-sm btn-white btn-outline btn-icon me-2'
                onClick={() => handleScroll('right')}
              >
                <i className='bi bi-arrow-right fs-2'></i>
              </button>
            </>
          )}
        </div>
      </div>
    </>
  )
}
