import { getServer, postServer, deleteServer } from 'src/service/http'

export function supplierInfoGet(param: object) {
    return getServer(`/supplier-info-get`, { ...param })
}

// 特定商取引法に基づき記載
export function specialCommerceLawInfoGet(param: object,headers:object={}) {
    return getServer('/special-commerce-law-info-get', { ...param },{...headers});
}

  // 特定商取引法に基づき記載設定
export function specialCommerceLawInfoUpdate(param: object,headers:object={}) {
    return postServer('/special-commerce-law-info-update', { ...param },{...headers});
}

  // ご契約中のプラン
export function userSubscriptionInfoGet(param: object,headers:object={}) {
    return getServer('/user-subscription-info-get', { ...param },{...headers});
}

  // 請求情報一览
export function billingInfoListGet(param: object,headers: object) {
    return getServer('/billing-info-list-get', { ...param },{...headers});
}

export function supplierInfoUpdatePost(param: object,headers: object) {
    return postServer('/supplier-info-other-update', { ...param },{...headers});
}

  // FTP/SFTP接続の確認
export function remoteConnectConfirm(param: object) {
    return getServer('/remote-connect-confirm', { ...param });
}