export const Steps = ( props: any ) => {
  const { StepNo } = props;

  return (
    <ul className='steps my-5'>
      {/* StepNo のあたいが1だったらclassにstep-activeを追加*/}
      <li className={`step ${StepNo === 1 ? 'step-active' : ''} ${StepNo > 1 ? 'step-success' : ''}`}>
        <div className='step-content'>
          <span className='step-circle'>1</span>
          <span className='step-text'>カート</span>
        </div>
      </li>
      <li className={`step ${StepNo === 2 ? 'step-active' : ''} ${StepNo > 2 ? 'step-success' : ''}`}>
        <div className='step-content'>
          <span className='step-circle'>2</span>
          <span className='step-text'>決済方法の選択</span>
        </div>
      </li>
      <li className={`step ${StepNo === 3 ? 'step-active' : ''} ${StepNo > 3 ? 'step-success' : ''}`}>
        <div className='step-content'>
          <span className='step-circle'>3</span>
          <span className='step-text'>注文内容の確認</span>
        </div>
      </li>
      <li className={`step ${StepNo === 4 ? 'step-active' : ''} ${StepNo > 4 ? 'step-success' : ''}`}>
        <div className='step-content'>
          <span className='step-circle'>4</span>
          <span className='step-text'>注文完了</span>
        </div>
      </li>
    </ul>
  );
}
