import {useState} from 'react'
import {useAuth} from '../core/Auth'
import {getUserByToken, login} from '../core/_requests'
import {useNavigate} from 'react-router-dom'

export function ModifySuccessfully() {
  const {saveAuth, setCurrentUser} = useAuth()
  const [telLoading, setTelLoading] = useState(false)
  const navigate = useNavigate()
  async function gotoLogin(): Promise<void> {
    setTelLoading(true)
    navigate('/auth')

    // try {
    //   const {data: auth} = await login('admin@demo.com', 'demo')
    //   saveAuth(auth)
    //   const {data: user} = await getUserByToken(auth.api_token)
    //   setTelLoading(false)
    //   setCurrentUser(user)
    // } catch (error) {
    //   console.error(error)
    // }
  }

  return (
    <div className='modify'>
      <div className='d-flex flex-column flex-center text-center p-10 succdiv'>
        <div className='card card-flush w-md-650px py-5'>
          <div className='card-body py-15 py-lg-20'>
            <div className='mb-7'>
              <a href='#' className=''>
                <img
                  alt='Logo'
                  src={
                    '/media/logos/custom-2.svg'
                  }
                  className='h-40px'
                />
              </a>
            </div>

            <h1 className='fw-bolder mb-5'>パスワードが変更されました </h1>

            <div className='fw-semibold fs-6 mb-7'>
              これは、創造性を発揮し、読者にアイデアを与える名前を付け
              <br />
              る機会です
            </div>
            <div className='mb-0'>
              <div className='btn btn-sm btn-green' onClick={() => gotoLogin()}>
                {!telLoading && <span className='indicator-label'>ログイン</span>}
                {telLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    お待ちください...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </div>
            </div>
            <div className='mb-0'>
              <img
                src={'https://preview.keenthemes.com/metronic8/demo1/assets/media/auth/ok.png'}
                alt=''
                className='mw-100 mh-300px theme-light-show'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
