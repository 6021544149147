/* eslint-disable react/jsx-no-target-blank */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
// import {refreshSupplierData} from 'src/app/modules/api'

interface MenuItem {
  name: string
  url: string
  roles: string[]

  capabilites: string[]

  sectiontitle?: boolean
  disabled: boolean

  hasBullet?: boolean
  hasBadge?: string | false
  icon?: string
  fontIcon?: string
  submenu?: MenuItem[]
}

const SidebarMenuMain = () => {
  const {auth} = useAuth() as any
  // console.log('????????', auth.menu)

  // auth.role = [supplier.userInfo_type]
  // const role = auth?.role
  // const caps = auth?.caps
  // // const menus = auth?.menus;
  // console.log('auth', auth)
  // console.log('role', role)
  // console.log('caps', caps)
  // console.log('menuData', menuData)
  // console.log('menus', menus)

  return (
    <>
      <div className='menu menu-column menu-rounded menu-sub-indention'>
        {auth.menu.map((item: MenuItem, index: number) => {
          return RenderMenuItem(item, index)
        })}
      </div>
    </>
  )
}

// メニューアイテムをレンダリングする関数（再帰的に処理）
const RenderMenuItem = (item: MenuItem, index: number) => {
  // sectiontitle=trueのとき
  if (item.sectiontitle) {
    return (
      <div className='menu-item' key={index}>
        <div className='menu-content pt-2 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>{item.name}</span>
        </div>
      </div>
    )
  }

  if (item.submenu && item.submenu.length > 0) {
    return <RenderMenuItemWithSub key={index} item={item} />
  } else {
    return (
      <SidebarMenuItem
        key={index}
        to={item.url}
        title={item.name}
        icon={item.icon}
        disabled={item.disabled}
        fontIcon={item.fontIcon}
        hasBullet={item.hasBullet}
      />
    )
  }
}

const RenderMenuItemWithSub: React.FC<{item: MenuItem}> = ({item}) => {
  return (
    <SidebarMenuItemWithSub
      to={item.url}
      title={item.name}
      icon={item.icon}
      fontIcon={item.fontIcon}
      hasBullet={item.hasBullet}
      hasBadge={item.hasBadge ? item.hasBadge : false}
      disabled={item.disabled}
    >
      {item.submenu?.map((subItem, index) => RenderMenuItem(subItem, index))}
    </SidebarMenuItemWithSub>
  )
}

export {SidebarMenuMain}
