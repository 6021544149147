import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAccount} from 'src/app/modules/accounts/AccountProvider'
import {toAbsoluteUrl} from '@/_metronic/helpers'
import {Steps} from './components/Steps'
import './style.css'

export default function CartCheckoutComplete() {
  const navigate = useNavigate()
  const {account, setAccount} = useAccount()
  useEffect(() => {
    setAccount({...account, cartCount: 0})
  }, [])

  return (
    <>
      <Steps StepNo={4} />
      <div>
        <div className='card' style={{textAlign: 'center', padding: 30, boxSizing: 'border-box'}}>
          <h1 className='fw-bolder fs-6 '>
            <span className='mt-3 d-inline-block'>ご利用ありがとうございます。</span>
            <br />
            <span className='mt-3 d-inline-block'>発注が完了しました。</span>
            <br />
            <span className='mt-3 d-inline-block'>詳細は以下の履歴よりご確認ください。</span>
          </h1>
          <div className='w-250px my-6 mx-auto'>
            <img
              alt=''
              src={toAbsoluteUrl('/media/cart/super_thank_you.svg')}
              className='h-100 w-100'
            />
          </div>
          <button
            className='btn btn-primary w-200px'
            style={{margin: 'auto'}}
            onClick={() => {
              navigate('/orderhistory')
            }}
          >
            ご注文履歴を見る
          </button>
        </div>
      </div>
    </>
  )
}
