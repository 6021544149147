/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react'
import {initialQueryState, KTSVG, useDebounce} from '@/_metronic/helpers'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {ja} from 'date-fns/locale'
// import {useQueryRequest} from '../../core/QueryRequestProvider'

const UsersListSearchComponent = (props: any) => {
  // const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    // let s = new Date(start).getTime()
    // let e = new Date(end).getTime()
    props.searchTime(start, end)
    setStartDate(start)
    setEndDate(end)
  }
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        // updateState({search: debouncedSearchTerm, ...initialQueryState})
        props.searchValues(debouncedSearchTerm)
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  )

  return (
    <>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control w-250px ps-14'
          placeholder='検索'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              props.changeTenantDeleteList()
            }
          }}
        />
      </div>
      {/* <div>
        <DatePicker
          className="custom-datepicker form-control w-250px ps-12 py-3"
          showIcon
          icon="fa-solid fa-calendar-days position-absolute top-50 translate-middle ms-8 fs-5"
          dateFormat="yyyy/MM/dd"
          locale={ja}
          isClearable
          onChange={handleDateChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              props.changeTenantDeleteList()
            }
          }}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          placeholderText="日付範囲を選択"
        />
      </div> */}
      {/* end::Search */}
    </>
  )
}

export {UsersListSearchComponent}
