import React, {useEffect, useState} from 'react'
import DisplayCards from './module/displayCardsDom'
import {
  useFormik,
  FormikProvider,
  Form,
  useFormikContext,
  useField,
  FieldArray,
  FieldHookConfig,
} from 'formik'
import * as yup from 'yup'
import {paymentProviderSuppliersList} from 'src/app/modules/api'
import {useLayout} from 'src/_metronic/layout/core'

export default function BankCardRegisterMulti(props: any) {
  // console.log('bankCardRegister',props)

  const {isContentLoading, setContentLoading} = useLayout()
  const [selectType, setSelectType] = useState<string>('') // 左上角选择框
  const [supplierList, setSupplierList] = useState<any[]>([])

  useEffect(() => {
    initData()
  }, [])

  const initData = async () => {
    setContentLoading(true)
    let res: any = await paymentProviderSuppliersList({})
    // let res:any = JSON.parse('{\"data\":[{\"id\":\"4gosl451c0snn4jjldn2780vtr#Suppliers#95a4adf4-7b04-4a4e-acd6-35b970fba30b\",\"provider\":\"smbc\",\"mode\":\"sandbox\",\"shopId\":\"tshop00005315\"}]}')
    // console.log(res)
    if (res) {
      setSupplierList(res.data)
    }
    setContentLoading(false)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header card-header-stretch pb-0 d-flex flex-wrap'>
        <div className='card-title'>
          <h3 className='m-0'>{props.title}</h3>
        </div>
      </div>
      <div className='card-body'>
        {supplierList.map((supplier: any, index: number) => (
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header card-header-stretch pb-0 d-flex flex-wrap'>
              <div className='card-title'>
                <h3 className='m-0'>{supplier.name}</h3>
              </div>
            </div>
            <div className='card-body'>
              <Suppliers
                key={index}
                supplier={supplier.name}
                id={supplier.id}
                shopId={supplier.shopId}
                mode={supplier.mode}
                provider={supplier.provider}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

interface SuppliersProps {
  supplier: string
  id?: string
  shopId?: string
  mode?: string
  provider?: string
}

const Suppliers: React.FC<SuppliersProps> = ({supplier, id, shopId, mode, provider}) => {
  useEffect(() => {
    // console.log('supplier:', supplier)
    // console.log('id:', id)
    // console.log('shopId:', shopId)
    // console.log('mode:', mode)
    // console.log('provider:', provider)
  }, [supplier, id, shopId, mode, provider])

  const formik = useFormik({
    initialValues: {
      id: id,
      mode: mode,
      provider: provider,
      siteId: id,
      shopId: shopId,
    },
    validationSchema: yup.object({
      // siteId: yup.string().required('必須項目です'),
      // sitePass: yup.string().required('必須項目です'),
      // shopId: yup.string().required('必須項目です'),
      // shopPass: yup.string().required('必須項目です'),
    }),
    onSubmit: (values) => {
      console.log('Submitted values:', values)
    },
  })

  return (
    <div>
      <div
        id='kt_billing_creditcard'
        className='tab-pane fade show active'
        role='tabpanel'
        aria-labelledby='kt_billing_creditcard_tab'
      >
        {/* <h3 className='mb-5'>{supplier}</h3> */}
        <FormikProvider value={formik}>
          <DisplayCards type={formik.values.provider} isEdit={true} />
        </FormikProvider>
      </div>
    </div>
  )
}
