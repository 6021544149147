import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '@/_metronic/helpers'
import {getSignedUrl, imageAdd, settings, settingsInit, slip, slipInit} from 'src/app/modules/api'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {showErrorMessage, showSuccessMessage} from '@/app/components/sweetAlert'
import axios from 'axios'
import {message, Spin} from 'antd'
import {useLayout} from 'src/_metronic/layout/core'
import {useAuth} from 'src/app/modules/auth'

const Settings: FC = () => {
  const {setContentLoading} = useLayout()
  useEffect(() => {
    apply()
  }, [])
  const [loading, setLoading] = useState<boolean>(false)
  const [pkVal, setPkVal] = useState<string>('')
  const {auth} = useAuth()
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }
  const apply = () => {
    setContentLoading(true)
    settingsInit({}, headers)
      .then((res: any) => {
        if (res?.rows.length) {
          setPkVal(res.rows[0].pk)
          formik.resetForm({
            values: {
              sender_info: res.rows[0].sender_info,
              seal: res.rows[0].seal,
              logo: res.rows[0].logo,
            },
          })
          setContentLoading(false)
        }
      })
      .catch((err: any) => {
        message.error('初始化失敗')
        setContentLoading(false)
      })
  }
  const validationSchema = Yup.object().shape({
    sender_info: Yup.string()
      .required('差出人情報設定は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(500, '最大500文字'),
    seal: Yup.string()
      .required('陰影は必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(255, '255文字以下で入力してください。'),
    logo: Yup.string()
      .required('会社ロゴは必須です。')
      .min(1, '1文字以上で入力してください。')
      .max(255, '255文字以下で入力してください。'),
  })

  const formik: any = useFormik<any>({
    initialValues: {
      sender_info: '',
      seal: '',
      logo: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setLoading(true)
      settings({...values, pkVal}, {headers})
        .then((res: any) => {
          setLoading(false)
          apply()
          showSuccessMessage(res.message)
        })
        .catch((err: any) => {
          setLoading(false)
        })
    },
  })

  const clearForm = () => {
    formik.resetForm({
      values: {
        sender_info: '',
        seal: '',
        logo: '',
      },
    })
  }

  interface IProps {
    name: string
    url: string
  }
  const Upload: React.FC<IProps> = ({name, url}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const defaultImage = toAbsoluteUrl(url || '/media/svg/files/blank-image.svg')
    const [image, setImage] = useState<string | null>(defaultImage)
    const [imageContainerClass, setImageContainerClass] = useState<string>(
      'image-input image-input-outline'
    )
    const [progress, setProgress] = useState(0)
    const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (event) => {
          setImage(event.target!.result as string)
          setImageContainerClass('image-input image-input-outline')
        }
        // reader.readAsDataURL(file)
        // 上传文件--------------------start
        setIsLoading(true)
        const {data}: any = await getSignedUrl({key: Date.now() + file.name, action: 'upload'})
        axios
          .put(data.url, file, {
            headers: {'Content-Length': file.size},
            onUploadProgress: (event: any) => {
              const percent = Math.floor((event.loaded / event.total) * 100)
              setProgress(percent)
              if (percent === 100) {
                setTimeout(() => setProgress(0), 1000)
              }
              // onProgress({percent: (event.loaded / event.total) * 100})
            },
          })
          .then((value) => {
            formik.setFieldValue(`${name}`, data.getUrl)
            setIsLoading(false)
            message.success('完了しました')
            imageAdd({name: Date.now() + file.name, path: data.url, status: 'publish'}, {headers})
          })
          .catch(() => {
            setIsLoading(false)
            message.error('挿入に失敗しました')
          })
        // end
      }
    }

    const handleDeleteImage = () => {
      formik.setFieldValue(`${name}`, '')
      setImage('')
      setImageContainerClass('image-input image-input-outline image-input-empty')
    }

    const imageStyle: React.CSSProperties = {
      backgroundImage: `url(${image})`,
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }

    return (
      <>
        <Spin tip='Loading' spinning={isLoading}>
          <div
            className={imageContainerClass}
            data-kt-image-input='true'
            style={{backgroundImage: `url(${toAbsoluteUrl('/media/svg/files/blank-image.svg')})`}}
          >
            <div
              className='image-input-wrapper w-125px h-125px bgi-position-center'
              style={imageStyle}
            ></div>

            <label
              data-kt-image-input-action='change'
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
            >
              <i className='ki-duotone ki-pencil fs-7' style={{paddingRight: '0'}}>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <input
                type='file'
                name='avatar'
                accept='.png, .jpg, .jpeg'
                onChange={handleImageUpload}
              ></input>
              <i className='bi bi-pencil fs-6x'></i>
              <input type='hidden' name='avatar_remove'></input>
            </label>

            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
              data-kt-image-input-action='remove'
              data-bs-toggle='tooltip'
              aria-label='Remove avatar'
              data-bs-original-title='Remove avatar'
              data-kt-initialized='1'
              onClick={handleDeleteImage}
              style={{display: formik.values[name] ? 'block' : 'none'}}
            >
              <i className='bi bi-x fs-2'></i>
              <i className='ki-duotone ki-cross fs-2' style={{paddingRight: '0'}}>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </span>
          </div>
          <div className='form-text'>許可されるファイルの種類：png、jpg、jpeg。</div>
        </Spin>
      </>
    )
  }

  return (
    <>
      <div className='content' />
      <div id='settings' className='card tab-pane active'>
        <div className='card-header'>
          <div className='card-title fs-3 fw-bold'>帳票出力設定</div>
        </div>
        <form onSubmit={formik.handleSubmit} noValidate>
          <div className='card-body p-9'>
            <div className='row mb-5'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3  required'>会社ロゴ</div>
              </div>
              <div className='col-lg-8'>
                <p>帳票に会社ロゴを表示できます。</p>
                <Upload name={'logo'} url={formik.values.logo} />
                {formik.touched.logo && formik.errors.logo && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.logo}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-5'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3 required'>陰影</div>
              </div>
              <div className='col-lg-8'>
                <p>帳票に印影を表示できます。</p>
                <Upload name={'seal'} url={formik.values.seal} />
                {formik.touched.seal && formik.errors.seal && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.seal}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-5'>
              <div className='col-xl-3'>
                <div className='fs-6 fw-semibold mt-2 mb-3 required'>差出人情報設定</div>
              </div>
              <div className='col-lg-8'>
                <textarea
                  className='form-control h-100px'
                  {...formik.getFieldProps('sender_info')}
                ></textarea>
                {formik.touched.sender_info && formik.errors.sender_info && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.sender_info}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button className='btn btn-lightbtn-active-light-primary me-2' onClick={clearForm}>
              キャンセル
            </button>
            <button
              type='submit'
              disabled={loading}
              className='btn btn-primary'
              data-kt-indicator={loading ? 'on' : 'off'}
            >
              <span className='indicator-label'>保存</span>
              <span className='indicator-progress'>
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
export {Settings}
