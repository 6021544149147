// Axios
import axios from 'axios'
// import {getAuth} from './AuthHelpers'
import message from '../../../components/Alert';
import {useAuth, getAuth, Logout} from '../../../modules/auth'
import {showSuccessMessage,showErrorMessage} from '../../../components/sweetAlert';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

let baseURL = "" as any;
baseURL = process.env.REACT_APP_API_URL;
let host = window.location.host // 主机
// let reg = /^localhost+/
// if (reg.test(host)) {
//   // 若本地项目调试使用
//   baseURL = '/api'
//   // baseURL = process.env.REACT_APP_API_URL
// } else {
//   // 远程访问 使用代理
//   // baseURL = '/api'
//   baseURL = process.env.REACT_APP_API_URL
// }
//  baseURL = '/api'
//  baseURL = process.env.REACT_APP_API_URL

export const getServer = (url: string, params: any) => {
  console.log("params",params);
  params={...params,host:window.location.host}
  return new Promise((resolve, reject) => {
    // const auth = getAuth()
    // if (auth && auth.api_token) {
    //   config.headers['Authorization'] = `Bearer ${auth.api_token}`
    // }
    axios
      .get(baseURL + url, { params: params })
      .then((resp: any) => {
        console.log('resp', resp)
        if (resp.status == 200) {
          resolve(resp.data as any)
        } else if (resp.status == 403) {
          Logout();
        } else {
          // throw new Error(resp.data.message)
          resolve(null)
          // console.log(resp.data.message)
          showErrorMessage(resp.data.message)
          // MySwal.fire({
          //   icon: 'error',
          //   text: resp.data.message
          // })
          // message.error({content: resp.data.message})
        }
      })
      .catch((error) => {
        console.log('error',error);
        reject(error)
      })
  })
}
export const postServer = (url: string, data: any, config = {} as any) => {
  console.log('post', 111, data)
  data=JSON.stringify({...data,host:window.location.host})
  return new Promise((resolve, reject) => {
    // const auth = getAuth()
    // if (auth && auth.api_token) {
    //   config.headers['Authorization'] = `Bearer ${auth.api_token}`
    // }
  
    axios
      .post(baseURL + url, data, config as any)
      .then((resp: any) => {
        console.log('resp', resp)
        resp.data.code = resp.status;
        // resolve(resp.data)
        if (resp.status == 200) {
          resolve(resp.data)
        } else if (resp.status == 403) {
          Logout();
        } else {
          resolve(null)
          // console.log(resp.data.message)
          showErrorMessage(resp.data.message)
          // MySwal.fire({
          //   icon: 'error',
          //   text: resp.data.message
          // })
          // message.error({content: resp.data.message})
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
