import React, {useEffect} from 'react'
import {Route, Routes} from 'react-router-dom'
import {ForgotPassword} from './components/ForgotPassword'
import {RegisterMember} from './components/registerMember'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import {ResetPassword} from './components/ResetPassword'
import {useLayout} from 'src/_metronic/layout/core'

const AuthPage = () => {
  const {setLoading} = useLayout()

  useEffect(() => {
    setLoading(false)
  }, [])

  // setLoading(false)

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        {/* <Route path='login' element={<Logo />} /> */}
        <Route path='login' element={<Login />} />
        {/* <Route path='registration' element={<Registration />} /> */}
        <Route path='RegisterMember' element={<RegisterMember />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        {/* <Route path='reset-password' element={<ResetPassword />} /> */}
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
