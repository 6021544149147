/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, Fragment} from 'react'
import React from 'react'
import {PageLink, PageTitle} from '@/_metronic/layout/core'
import {accountUserAdd} from 'src/app/modules/api'
import {useAuth} from 'src/app/modules/auth'
import {useLayout} from 'src/_metronic/layout/core'
import {useNavigate} from 'react-router-dom'
import {useIsWidthSize, formatNumber, getParameterStore} from 'src/app/modules/utils'
import moment from 'moment'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAlert} from 'src/_metronic/layout/core'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'ダッシュボード',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const validationSchema: any = {
  firstname: Yup.string().required('入力は必須です'),
  firstname_kana: Yup.string()
    .required('入力は必須です')
    .matches(/^[ァ-ヶー　]+$/, '全角カタカナで入力してください'),
  lastname: Yup.string().required('入力は必須です'),
  lastname_kana: Yup.string()
    .required('入力は必須です')
    .matches(/^[ァ-ヶー　]+$/, '全角カタカナで入力してください'),
  email: Yup.string().required('入力は必須です').email('メールアドレスの形式が正しくありません'),
}

export default function OrganizationUserInvitation() {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>新規ユーザー登録</PageTitle>
      <Pageconts />
    </>
  )
}

const Pageconts: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const {setMessage} = useAlert()
  const {setContentLoading} = useLayout()
  const formik: any = useFormik<any>({
    initialValues: {
      firstname: '',
      firstname_kana: '',
      lastname: '',
      lastname_kana: '',
      email: '',
    } as any,
    // validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('values', values)
      setContentLoading(true)
      const res = await accountUserAdd(values)
      setMessage({message: 'アカウント招待メールを送信しました', type: 'success'})
      setContentLoading(false)
    },
  })

  return (
    <>
      {useIsWidthSize('lg') ? (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className='card mb-lg-5 mb-9'>
              <div className='card-header cursor-pointer'>
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>新規ユーザー登録</h3>
                </div>
                <div className='card-toolbar'></div>
              </div>

              <div className='card-body py-3'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>メールアドレス</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      className='form-control form-control-lg'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('email')}
                    />
                    {formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.email}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>姓</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      className='form-control form-control-lg'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('lastname')}
                    />

                    {formik.errors.lastname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastname}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>名</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      className='form-control form-control-lg'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('firstname')}
                    />
                    {formik.errors.firstname && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.firstname}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>姓（フリガナ）</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      className='form-control form-control-lg'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('lastname_kana')}
                    />
                    {formik.errors.lastname_kana && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.lastname_kana}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>名（フリガナ）</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      className='form-control form-control-lg'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('firstname_kana')}
                    />
                    {formik.errors.firstname_kana && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.firstname_kana}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  type='submit'
                  data-kt-users-modal-action='submit'
                  className='btn btn-danger me-4'
                  onClick={() => {
                    navigate('/account/organization/userlist')
                  }}
                >
                  一覧に戻る
                </button>

                <button
                  type='submit'
                  data-kt-users-modal-action='submit'
                  className='btn btn-primary'
                >
                  {!loading && '保存'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      お待ちください...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <></>
        // <SupplierListSP data={data} parameters={parameters} />
      )}
    </>
  )
}

const SupplierListSP: React.FC<any> = ({data, parameters}) => {
  const navigate = useNavigate()

  return (
    <>
      {data.length > 0 &&
        data.map((item: any, index: number) => {
          return (
            <div className='row mb-6'>
              <div className='col-12 fs-7 mb-2'>
                {/* 注文日 {moment(item.order_date).format('YYYY-MM-DD HH:mm:ss')} */}
              </div>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row mb-2 border-bottom mb-2 pb-2'>
                      <div className='col-12 fs-4 fw-bold d-flex align-items-center'>
                        {parameters['/Define/Supplierapplications/Status']?.map((v: any) => {
                          if (v.value === item.status) {
                            return (
                              <span
                                className={`badge badge-${v.color} justify-content-center py-3 me-2`}
                              >
                                {v.label}
                              </span>
                            )
                          }
                        })}
                        {/* {item.status_name ? (
                          <span
                            className={`badge badge-${item.status_color} justify-content-center py-3 me-2`}
                          >
                            {item.status_name}
                          </span>
                        ) : (
                          <span className={`badge badge-light justify-content-center py-3 me-2`}>
                            {'未申請'}
                          </span>
                        )} */}
                        {item.company_name}
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-4 fs-7 '>仕入先ID</div>
                      <div className='col-8 fs-7 '>{item.display_id}</div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-4 fs-7 '>取扱ジャンル</div>
                      <div className='col-8 fs-7 '>{item.main_genre}</div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-4 fs-7 '>申請日時</div>
                      <div className='col-8 fs-7 '>
                        {item.application_date &&
                          moment(item.application_date).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12 d-flex justify-content-center'>
                        <a
                          href='#'
                          className='btn btn-white btn-outline w-75'
                          onClick={(e) => {
                            e.preventDefault()
                            let pk = item.pk.split('#').pop()
                            navigate(`/supplier/${pk}`)
                          }}
                        >
                          詳細情報・取引申請
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
    </>
  )
}
