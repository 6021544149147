import { MouseEventHandler, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../auth'

const local_URL = process.env.REACT_APP_LOCAL_URL

const initialValues = {
  code: '',
  newPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  code: Yup.string()
    .required('検証コードは必須です'),
  newPassword: Yup.string()
    .required('新パスワードは必須です'),
})

export function ForgotPasswordVerificationAndNewPassword() {
  const [loading, setLoading] = useState(false)
  const { auth } = useAuth();
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        console.log('values', values)
        const result = await auth.forgotPasswordSubmit(auth.username, values.code, values.newPassword);
        if (result.success) {
          console.log(result)
        } else {
          throw new Error(result.message)
        }

        setLoading(false);
      } catch (error) {
        const typedError = error as Error;
        console.error(typedError)
        setStatus(typedError.message)
        setSubmitting(false)
        setLoading(false)
      }
    }
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '54.5vh',
        }}
      >

        <div>
          <div className='text-start mb-10 '>
            <h1 className='text-dark mb-3 fs-3x'>パスワードをお忘れですか ？</h1>

            <div className='fw-semibold fs-6'>
              メールアドレス宛に送信された確認コードを入力してください。。
            </div>
          </div>

          {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
         )}

          <div className='fv-row mb-8'>
            <input
              type='text'
              placeholder='検証コード'
              autoComplete='off'
              {...formik.getFieldProps('code')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.code && formik.errors.code },
                {
                  'is-valid': formik.touched.code && !formik.errors.code,
                }
              )}
            />
            {formik.touched.code && formik.errors.code && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.code}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-8'>
            <input
              type='password'
              placeholder='新パスワード'
              autoComplete='off'
              {...formik.getFieldProps('newPassword')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword },
                {
                  'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                }
              )}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.newPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-flex flex-wrap  pb-lg-0   '>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-green me-4'>
              {!loading && <span className='indicator-label'>確定</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  お待ちください...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                キャンセル
              </button>
            </Link>{' '}
          </div>
        </div>


      </div>
    </form>
  )
}
