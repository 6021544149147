import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

// 创建一个自定义的弹窗对象
const myAlert = MySwal.mixin({
    // 设置默认的标题和图标
    // title: 'My Custom Alert',
    // 自定义按钮样式
    confirmButtonText: 'OK',
    cancelButtonText: 'cancel',
});
// 显示成功消息的函数
function showSuccessMessage(message: any, callback?: any) {
    MySwal.fire({
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
        allowOutsideClick: false
    }).then(callback);
}

// 显示错误消息的函数
function showErrorMessage(message: any, callback?: any, buttonText?: any) {
    MySwal.fire({
        text: message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: buttonText ? buttonText : 'OK',
        allowOutsideClick: false
    }).then(callback);
}

// 显示确认对话框的函数
function showWarningMessage(message: any, preConfirm?: any, confirmButtonText?: any, cancelButtonText?: any) {
    MySwal.fire({
        text: message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText ? confirmButtonText : 'OK',
        cancelButtonText: cancelButtonText ? cancelButtonText : 'cancel',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: preConfirm
    });
}

// 显示确认对话框的函数带输入框的
function showWarningMessage1(message: any) {
    MySwal.fire({
        text: message,
        input: "text",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "確定",
        cancelButtonText: "キャンセル",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: async (value) => {
            try {
                console.log(value);
                showSuccessMessage('金額が変更されました。')
            } catch (error) {
                showErrorMessage('動作異常です。', '閉じる')
            }
        }
    }).then((result) => {
        
    })
}


export {
    showSuccessMessage,
    showErrorMessage,
    showWarningMessage,
    showWarningMessage1
} 