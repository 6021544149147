import React, {Component, ErrorInfo, ReactNode, useEffect, useState} from 'react'
import {NavigateFunction, useNavigate} from 'react-router-dom'

interface ErrorBoundaryWrapperProps {
  children: ReactNode
  setHasError: (hasError: boolean) => void
}

interface ErrorBoundaryWrapperState {
  hasError: boolean
}

class ErrorBoundaryWrapper extends Component<ErrorBoundaryWrapperProps, ErrorBoundaryWrapperState> {
  constructor(props: ErrorBoundaryWrapperProps) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryWrapperState {
    return {hasError: true}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
    this.props.setHasError(true)
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}

const ErrorBoundary: React.FC<{children: ReactNode}> = ({children}) => {
  const [hasError, setHasError] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (hasError) {
      navigate('/error/500')
    }
  }, [hasError, navigate])

  return <ErrorBoundaryWrapper setHasError={setHasError}>{children}</ErrorBoundaryWrapper>
}

export default ErrorBoundary
