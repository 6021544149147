import { getServer, postServer, deleteServer } from 'src/service/http'

export function registerAddress(param: object, headers:object={}) {
  return postServer(`/user-shipping-address-register`, { ...param }, {...headers})
}
export function getAddress(param: object,headers:object={}) {
  return getServer(`/user-shipping-address-get`, { ...param},{...headers})
}
export function deleteAddress(param: object) {
  return deleteServer(`/user-shipping-address-delete`, { ...param })
}
export function updateAddress(param: object) {
  return postServer(`/user-shipping-address-update`, { ...param })
}

// カート画面初期表示
export function cartDataGet(param: object) {
  return getServer('/cart-data-get', { ...param });
}

//  カートの更新
export function cartDataUpdate(param: object) {
    return postServer(`/cart-data-update`, { ...param })
}

// 削除
export function cartItemDelete(params:any) {
    return deleteServer('/cart-item-delete', {
      ...params
    });
  }

  // お届け希望日を取得
export function cartShippingGroup(param: object) {
  return getServer(`/cart-shipping-group`, { ...param })
}

// カートに入っている数を取得
export function cartCountGet(param: object) {
  return getServer('/cart-count-get', { ...param });
}