import {KTCard} from 'src/_metronic/helpers'
import {Props, useMediaManagement} from './hooks'
import {MediaTable} from './components/MediaTable'
import {Fragment} from 'react'
import {Modal} from 'antd'
import {formatFileSize} from '@/utils'

const Component = (props: Props) => {
  const {
    listMedia,
    onSelectFolder,
    createFolderMedia,
    uploadFile,
    selectedFolder,
    setSelectedFolder,
    isContentLoading,
    refetch,
    mediaEditName,
    setMediaEditName,
    onSaveMediaEditName,
    filePreview,
    setFilePreview,
    totalMedias,
  } = props

  return (
    <div style={{position: 'relative'}}>
      <div className='card  mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title'>
            <h3>メディア管理</h3>
          </div>
        </div>
        <div className='card-body'>
          <dl className='row'>
            <dt className='col-sm-3 fs-4 fw-bold mb-5'>登録容量</dt>
            <dd className='col-sm-9 fs-6 mb-5'>
              <span className='pe-3 border-end border-dark'>
                {formatFileSize(totalMedias.size)}
              </span>
              <span className='ps-3'>{totalMedias.files} 件</span>
            </dd>
          </dl>
        </div>
      </div>
      <div className='card'>
        <div className='table-responsive card-body'>
          <div className='d-flex flex-stack my-3'>
            <div className='badge badge-lg badge-light-primary' style={{gap: 16}}>
              <div
                className='d-flex align-items-center flex-wrap cursor-pointer'
                onClick={() => setSelectedFolder([])}
              >
                <i className='bi bi-hexagon-fill fs-5 text-primary me-3'></i>
                <span>全て</span>
              </div>
              {selectedFolder?.map((select, i) => (
                <Fragment key={select.pk}>
                  <i className='bi bi-chevron-right text-primary'></i>
                  <div
                    onClick={() => setSelectedFolder((pre) => pre.slice(0, i + 1))}
                    className='d-flex align-items-center flex-wrap cursor-pointer'
                  >
                    <span>{select.name}</span>
                  </div>
                </Fragment>
              ))}
            </div>

            <div className='d-flex align-items-center justify-content-end badge text-bg-primary'>
              {listMedia?.length} 件表示
            </div>
          </div>
          <Modal
            onCancel={() => setFilePreview(undefined)}
            open={!!filePreview}
            width={'40%'}
            bodyStyle={{paddingTop: 30}}
            footer={null}
            centered
          >
            {filePreview ? (
              <img
                src={filePreview?.path}
                alt={filePreview?.name}
                style={{width: '100%', objectFit: 'contain', borderRadius: 8}}
              ></img>
            ) : null}
          </Modal>
          <MediaTable
            loading={isContentLoading}
            uploadFile={uploadFile}
            onCreateFolder={createFolderMedia}
            dataTable={listMedia ?? []}
            onSelectFolder={onSelectFolder}
            refetch={refetch}
            mediaEditName={mediaEditName}
            setMediaEditName={setMediaEditName}
            onSaveMediaEditName={onSaveMediaEditName}
            parent={selectedFolder?.[selectedFolder?.length - 1]?.pk}
          />
        </div>
      </div>
    </div>
  )
}

export default function MediaManagement() {
  return <Component {...useMediaManagement()} />
}
