import {FC, createContext, useContext, useState, useEffect} from 'react'
import {DefaultConfig} from './_LayoutConfig'
import {
  setLayoutIntoLocalStorage,
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './_LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
  LayoutType,
  ToolbarType,
} from './_Models'
import {WithChildren} from '../../helpers'
import {useAuth} from '../../../app/modules/auth'

export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  isLoading: boolean
  isContentLoading: boolean
  setLayout: (config: LayoutSetup) => void
  setLayoutType: (layoutType: LayoutType) => void
  setToolbarType: (toolbarType: ToolbarType) => void
  setLoading: (loading: boolean) => void
  setContentLoading: (loading: boolean) => void
}
const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  isLoading: false,
  isContentLoading: false,
  setLayout: (config: LayoutSetup) => {},
  setLayoutType: (layoutType: LayoutType) => {},
  setToolbarType: (toolbarType: ToolbarType) => {},
  setLoading: (loading: boolean) => {},
  setContentLoading: (loading: boolean) => {},
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: FC<WithChildren> = ({children}) => {
  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isContentLoading, setIsContentLoading] = useState<boolean>(false)
  const {auth} = useAuth()

  const setLayout = (_themeConfig: Partial<ILayout>) => {
    // enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    const updatedConfig = LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, updatedConfig))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    // setTimeout(() => {
    //   disableSplashScreen()
    // }, 500)
  }

  const setToolbarType = (toolbarType: ToolbarType) => {
    const updatedConfig = {...config}
    if (updatedConfig.app?.toolbar) {
      updatedConfig.app.toolbar.layout = toolbarType
    }

    setLayoutIntoLocalStorage(updatedConfig)
    window.location.reload()
  }

  const setLayoutType = (layoutType: LayoutType) => {
    const updatedLayout = {...config, layoutType}
    setLayoutIntoLocalStorage(updatedLayout)
    window.location.reload()
  }

  const setLoading = (loading: boolean) => {
    setIsLoading(loading)
  }

  const setContentLoading = (loading: boolean) => {
    setIsContentLoading(loading)
  }

  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    isLoading,
    isContentLoading,
    setLayout,
    setLayoutType,
    setToolbarType,
    setLoading,
    setContentLoading,
  }

  // useEffect(() => {
  //   // ログインしているかどうかでローディングを管理
  //   if (auth.isLoading) {
  //     setIsLoading(true)
  //   } else {
  //     setIsLoading(false)
  //   }
  //   // disableSplashScreen()
  // }, [auth.isLoading])

  // useEffect(() => {
  //   // console.log("isLoading", isLoading);
  //   // if( isLoading ){
  //   //   enableSplashScreen()
  //   // } else {
  //   //   disableSplashScreen()
  //   // }
  //   // disableSplashScreen()
  // }, [isLoading])

  useEffect(() => {
    if (isLoading) {
      enableSplashScreen()
    } else {
      disableSplashScreen()
    }
    // disableSplashScreen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export {LayoutContext, LayoutProvider}

export function useLayout() {
  return useContext(LayoutContext)
}
