/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, Fragment} from 'react'
import React from 'react'
import {Loading} from '@/app/components/loading'
import {PageLink, PageTitle} from '@/_metronic/layout/core'
import {toAbsoluteUrl} from '@/_metronic/helpers'
import {useLayout} from 'src/_metronic/layout/core'
import {Pagination} from '@/app/components/pagination'
import {useQueryResponseLoading} from '@/app/modules/accounts/users-list/core/QueryResponseProvider'
import {useQueryRequest} from '@/app/modules/accounts/users-list/core/QueryRequestProvider'
import {accountUsersListGet} from 'src/app/modules/api'
import {useAuth} from 'src/app/modules/auth'
import {useNavigate} from 'react-router-dom'
import {useIsWidthSize, formatNumber, getParameterStore} from 'src/app/modules/utils'
import moment from 'moment'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'ダッシュボード',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const statusObj: any = {
  unapplied: '未申请',
  pending: '承認待ち',
  non_approval: '非承認',
  approval: '承認済',
}

export default function OrganizationUserList() {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>組織ユーザー管理</PageTitle>
      <Pageconts />
    </>
  )
}

const Pageconts: FC = () => {
  const [parameters, setParameters] = useState<any>({})
  const navigate = useNavigate()
  const {setContentLoading} = useLayout()
  const [isCanLoading, setIsCanLoading] = useState<boolean>(false) // 加载状态
  const [data, setData] = useState<any>([]) // 数据列表
  // const [data, setData] = useState<any>([]) // 数据列表
  const [queryParams, setQueryParams] = useState<any>({
    pageNum: 1,
    pageSize: 10,
    total: 0,
  }) // 分页参数,

  useEffect(() => {
    setIsCanLoading(true)
    accountUsersListGet({}).then((res: any) => {
      setIsCanLoading(false)
      setData(res.rows)
      // setData(res)
    })
  }, [])

  return (
    <>
      {useIsWidthSize('lg') ? (
        <>
          <div className='card mb-lg-5 mb-9'>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3  text-center'>
                  <thead>
                    <tr className='fw-bold text-dark'>
                      <th className='min-w-150px'>メールアドレス</th>
                      <th className='min-w-150px'>担当者名</th>
                      {/* <th className='min-w-150px'>権限</th> */}
                      <th className='min-w-150px'>登録日</th>
                      <th className='min-w-150px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item: any, index: any) => {
                        return (
                          <Fragment key={index}>
                            <tr>
                              <td className='text-dark  '>{item.email}</td>
                              <td className=''>
                                {item?.lastname} {item?.firstname}
                              </td>
                              {/* <td className=''></td> */}
                              <td>
                                {item.create_at &&
                                  moment(item.create_at).format('YYYY-MM-DD HH:mm:ss')}
                              </td>
                              <td>
                                <a
                                  href='#'
                                  onClick={(e) => {
                                    e.preventDefault()
                                    navigate(`/account/organization/userlist/detail`, {
                                      state: item.user_id,
                                    })
                                  }}
                                  className='btn btn-white btn-outline'
                                >
                                  詳細
                                </a>
                              </td>
                            </tr>
                          </Fragment>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={17}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            {isCanLoading ? 'データの取得中' : '該当するレコードがありません。'}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SupplierListSP data={data} parameters={parameters} />
      )}
    </>
  )
}

const SupplierListSP: React.FC<any> = ({data, parameters}) => {
  const navigate = useNavigate()

  return (
    <>
      {data.length > 0 &&
        data.map((item: any, index: number) => {
          return (
            <div className='row mb-6'>
              <div className='col-12 fs-7 mb-2'>
                {/* 注文日 {moment(item.order_date).format('YYYY-MM-DD HH:mm:ss')} */}
              </div>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row mb-2 border-bottom mb-2 pb-2'>
                      <div className='col-12 fs-4 fw-bold d-flex align-items-center'>
                        {item?.lastname} {item?.firstname}
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-4 fs-7 '>メールアドレス</div>
                      <div className='col-8 fs-7 '>{item.email}</div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-4 fs-7 '>登録日</div>
                      <div className='col-8 fs-7 '>
                        {item.create_at && moment(item.create_at).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12 d-flex justify-content-center'>
                        <a
                          href='#'
                          className='btn btn-white btn-outline w-75'
                          onClick={(e) => {
                            e.preventDefault()
                            navigate(`/account/organization/userlist/detail`, {
                              state: {id: item.user_id},
                            })
                          }}
                        >
                          詳細
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
    </>
  )
}
