import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
// import { getMenuListDev } from '../../../../service/common'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {

  return useContext(AuthContext);

  // useEffect(() => {
  //   if (!isAuthenticated) {

  //     // URLにcodeやstateが含まれている場合はリダイレクトをスキップ
  //     if (window.location.search.includes('code=') || window.location.search.includes('state=')) {
  //       return;
  //     }

  //     // ユーザーがログインしていない場合、Auth0のログインページにリダイレクト
  //     loginWithRedirect();
  //   }
  // }, [isAuthenticated, loginWithRedirect]);

  // return isAuthenticated;

  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {

  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = async(auth: AuthModel | undefined) => {
    let obj:any = {...auth}
    if (auth?.api_token) {
      // const res:any = await getMenuListDev({userId:auth?.data.login_id,roleGroup: auth?.data.role_group})
      // if (res?.data) {
      //   obj = {...auth, menus: res?.data}
      // }
    }
    setAuth(obj)
    if (auth) {
      authHelper.setAuth(obj)
    } else {
      authHelper.removeAuth()
    }
  }

  function clearCookie() {
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
      for (var i = keys.length; i--;) {
        document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString();//清除当前域名下的,例如：m.kevis.com
        document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString();//清除当前域名下的，例如 .m.kevis.com
        document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString();//清除一级域名下的或指定的，例如 .kevis.com
      }
    }
    console.log('已清除');
  }

  const logout = () => {
    // log_out({ logoutParams: { returnTo: window.location.origin } })
    // clearCookie();
    saveAuth(undefined)
    authHelper.removeAuth();
    setCurrentUser(undefined)
  }

  const [user, setUser] = useState(null);

  // useEffect(() => {
  //   // ログイン状態をチェックする
  //   Auth.currentAuthenticatedUser()
  //     .then(currentUser => setUser(currentUser))
  //     .catch(err => setUser(null));
  // }, []);

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          // const {data} = await getUserByToken(apiToken)
          // const data = localStorage.getItem(authHelper.AUTH_LOCAL_STORAGE_KEY)
          const data = authHelper.getAuth();
          // console.log("data",data);
          if (data) {
            setCurrentUser(data as any)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
