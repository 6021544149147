import React, {useState, useEffect, FC} from 'react'
import {Spin} from 'antd'

interface ProcessButtonProps {
  onClick: () => Promise<void> | void
  children: React.ReactNode
  className?: string
  type?: 'button' | 'submit' | 'reset'
  style?: React.CSSProperties
  processingText?: string
  disabled?: boolean
}

const ProcessButton: FC<ProcessButtonProps> = ({
  onClick,
  children,
  type = 'button',
  className,
  style,
  processingText = '処理中',
  disabled = false,
}) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [btnClassName, setBtnClassName] = useState('')

  useEffect(() => {
    setBtnClassName(`btn ${className}`)
  }, [className])

  const handleClick = async (event: any) => {
    event.preventDefault()
    if (isProcessing) return

    setIsProcessing(true)
    try {
      await onClick()
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <button
      style={style}
      className={btnClassName}
      onClick={handleClick}
      disabled={isProcessing}
      type={type}
    >
      <Spin spinning={isProcessing}>{isProcessing ? processingText : children}</Spin>
    </button>
  )
}

export default ProcessButton
