import React, {useState, useEffect} from 'react'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from '@/app/components/sweetAlert'
import {useLocation, useNavigate } from 'react-router-dom'
import Item from 'antd/es/list/Item'
import {  Spin  } from 'antd';
import {
  subscriptionUsageInfoGet,
  subscriptionUsageInfoUpdate,
  subscriptionUsageInfoAdd,
  entrytran,
  exectran,
  usageInfo,
  subscriptionPaymentInfoGet,
  subscriptionPaymentInfoAdd,
  subscriptionPaymentInfoUpdate,
  supplierInfoAdd,
  supplierInfoUpdate, 
  buyerInfoUpdate,
  usageInfoAdd,
  usageInfoUpdate,
  buyerInfoAdd,
  getUserSubscriptionInfo
} from 'src/app/modules/api'
import { useAuth } from "src/app/modules/auth"

export default function PlanConfirm() {
  const { auth } = useAuth();
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${auth.token}`
  }
  const navigate = useNavigate()
  const location = useLocation()
  const [initialValues, setInitialValues] = useState<any>({
    corporation_or_individual: '',
    company_name: '',
    shop_name: '',
    shop_name_kana: '',
    representative_name: '',
    headoffice_address_postal_code: '',
    headoffice_address_prefecture: '',
    headoffice_address_city: '',
    headoffice_address_street: '',
    headoffice_address_building_name: '',
    corporate_number: '',
    invoice_number: '',
    establishment_date: '',
    fiscal_year_end: '',
    annual_sales: '',
    regular_holiday: '',
    business_hours: '',

    main_genre: '',
    secondary_genre: '',
    business_description: '',
    shop_introduction: '',
    primary_contact_email: '',
    person_in_charge: '',
    person_in_charge_kana: '',
    person_in_charge_email: '',
    person_in_charge_phone: '',
    person_in_charge_phone_secondary: '',
    contact_person: '',
    contact_person_kana: '',
    contact_person_email: '',
    contact_person_phone: '',
    contact_person_phone_secondary: '',
    referrer: '',
    initCost: '1',
    termsOfApplication: '0',
    privacyPolicy: '0',

    customer_id: '',
    // company_name: '',
    representative: '',
    department_name: '',
    // contact_person: '',
    // contact_person_kana: '',
    main_email_address: '',
    address_postal_code: '',
    address_prefecture: '',
    address_city: '',
    address_street: '',
    address_building_name: '',
    telephone_number: '',
    mobile_phone_number: '',
    fax_number: '',
    payment_method: '',
    // establishment_date: '',
    actual_industry: '',
    website_url: '',
    physical_store: '',
    email_notifications: '',
  })
  const [isLoading,setIsLoading] =useState<boolean>(false)
  const [pkVal,setPkVal] =useState<string>('')
  
  const [option, setOption] = useState<any>([
    {
      id: '0',
      title: 'ミニマムプラン',
      name: 'Best for startups',
      price: '39',
    },
    {
      id: '1',
      title: 'スタンダードプラン',
      name: 'Best for 100+ team size',
      price: '139',
    },
    {
      id: '2',
      title: 'プロフェッショナルプラン',
      name: 'Best value for 1000+ team',
      price: '339',
    },
  ])
  const [chooseASetMeal, setChooseASetMeal] = useState<any>({})
  const [card, setCard] = useState<any>({})
  const init = () => {
    let setMeal = localStorage.getItem('setMeal')
    let accountFormStorageInformation:any = localStorage.getItem('accountFormStorageInformation')
    if (setMeal) {
      setChooseASetMeal(JSON.parse(setMeal))
    } else {
      showErrorMessage('コース未選択', ()=>{
        localStorage.removeItem('setMeal')
        localStorage.removeItem('allMealList')
        localStorage.removeItem('accountFormStorageInformation')
        navigate(`/plan`)
      })
    }
    if (accountFormStorageInformation) {
      setInitialValues(JSON.parse(accountFormStorageInformation))
    }
  }
  
  const getUser = async () => {
    let params = {}
    const res: any = await getUserSubscriptionInfo(params,headers)
    setPkVal(res.pk)
  }
  const Settlement = async () => {
    // localStorage.removeItem('setMeal')
    // localStorage.removeItem('allMealList')
    // localStorage.removeItem('accountFormStorageInformation')
    // navigate('/plan/complete')

    try {
      setIsLoading(true)
      let accountFormStorageInformation:any = localStorage.getItem('accountFormStorageInformation')
      let dataParams = JSON.parse(accountFormStorageInformation)
      // console.log('3434',dataParams,location,card,supplier,pkVal);
      //取引取得
      const {data}:any =  await entrytran({
        amount:chooseASetMeal.fee * (Number(chooseASetMeal.tax_rate) / 100) + Number(chooseASetMeal.fee),
      })
      //決済実行
      const exectrans  =  await exectran({
        card_seq:card.cardSeq,
        access_id:data.accessID,
        access_pass:data.accessPass,
        order_id:data.orderId,
        amount:chooseASetMeal.fee * (Number(chooseASetMeal.tax_rate) / 100) + Number(chooseASetMeal.fee),
      })
      let obj:any = {usageInfos:'add',paymentinfos:'add'}
      // 利用状況取得
      // let dataParams = JSON.parse(accountFormStorageInformation)
      // console.log('3434',dataParams,data,location,card,supplier);

      const usageInfos: any = await usageInfo({
        plan_id: location.state.chooseASetMeal.pk,
      },headers)
      obj.usageInfos = usageInfos.data;
      // 支払い情報取得
      const paymentinfos:any  =  await subscriptionPaymentInfoGet({
        plan_id:location.state.chooseASetMeal.pk
      })
      obj.paymentinfos = paymentinfos.data;

      // データの挿入
      let srcParams = {
        plan_id: location.state.chooseASetMeal.pk,
        payment_method: dataParams.payment_method,
        cardSeq: card.cardSeq,
        pkVal:pkVal
      }
      if (obj.usageInfos == 'add') {
        const result = await usageInfoAdd(srcParams, {headers})
      } else {
        const result = await usageInfoUpdate(srcParams, {headers})
      }

      // 更新-登录
      if (auth.role.includes('buyer')) {// 利用者
        let data = JSON.parse(accountFormStorageInformation)
        // if(obj.usageInfos == 'add'){
        //   const res =  await buyerInfoAdd(data)
        // }else {
          const res =  await buyerInfoUpdate(data)
        // }
        let buyObj:any = {
          plan_id:location.state.chooseASetMeal.pk,
          payment_method:chooseASetMeal.payment_method,
          cardSeq:card.cardSeq,
          
        }
        // if(obj.paymentinfos == 'add'){
        //   const res =  await usageinfoAadd(buyObj)
        // }else {
        //   const res =  await usageinfoUpdate(buyObj)
        // }
        showSuccessMessage("支払いに成功しました")
        setIsLoading(false)
      }else { // 加盟店
        let data = JSON.parse(accountFormStorageInformation)
      //  if(obj.usageInfos == 'add'){
      //    const res =  await infoAdd(data)
      //  }else {
         const res =  await supplierInfoUpdate(data)
      //  }
       let payObj:any = {
          plan_id:location.state.chooseASetMeal.pk,
          amount:chooseASetMeal.fee * (Number(chooseASetMeal.tax_rate) / 100) + Number(chooseASetMeal.fee) + "",
          tax:chooseASetMeal.tax_rate,
          fee_info:JSON.stringify([{
            unit_price:chooseASetMeal.fee,
            title:chooseASetMeal.name,
            usage:'1',
            amount:chooseASetMeal.fee * (Number(chooseASetMeal.tax_rate) / 100) + Number(chooseASetMeal.fee) + "",
            tax_rate:chooseASetMeal.tax_rate,
            tax_price:chooseASetMeal.fee / chooseASetMeal.tax_rate,
          }]),
          // payment_date:chooseASetMeal.create_at,
          payment_method:chooseASetMeal.payment_method,
          payment_processor_id:card.cardSeq,
          status:'publish',
        }
        if (obj.paymentinfos == 'add') {
          const res = await subscriptionPaymentInfoAdd(payObj, {headers})
        } else {
          const res = await subscriptionPaymentInfoUpdate(payObj, {headers})
        }
      }
      // showSuccessMessage("支払いに成功しました",()=>{
      //   localStorage.removeItem('setMeal')
      //   localStorage.removeItem('allMealList')
      //   localStorage.removeItem('accountFormStorageInformation')
      //   navigate(`/static/ec/store/set/userUsageFee/userOverPages`)

      // })
      localStorage.removeItem('setMeal')
      localStorage.removeItem('allMealList')
      localStorage.removeItem('accountFormStorageInformation')
      navigate(`/plan/complete`)
    
      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)
      showErrorMessage("決済方法をご確認ください")
    }
  }

  useEffect(() => {
    init()
    getUser()
    setCard(location.state.card)
  }, [])

  return (
    <div>

      <div className='container-fluid'>
        <div className='card mb-6 py-6 shadow-sm'>
          <div className='text-center'>
            <h1 className='fs-2hx fw-bold mb-5'>最終確認画面</h1>
            <div className='fw-semibold fs-5'>
              内容ご確認いただき、「申込する」をクリックしてください。
            </div>
          </div>
        </div>
        <form>

          {auth.role.includes("buyer")?
              <div className='card mb-6 shadow-sm'>
              <div className='card-body p-9'>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>登録年月日</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.establishment_date}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>紹介者</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.referrer}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>顧客ID</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.display_id}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>会社名</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.company_name}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>代表者</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.representative}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>担当部署名</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.department_name}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>担当者</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.contact_person}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>担当者　フリガナ</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.contact_person_kana}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>担当者　メールアドレス</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.main_email_address}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>住所</label>

                  <div className='col-lg-8'>
    <span className='fs-6 text-dark'>
      {initialValues?.address_prefecture}
      {initialValues?.address_city}
      {initialValues?.address_street}
      {initialValues?.address_building_name}
    </span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>
                    電話番号
                    <i
                        className='fas fa-exclamation-circle ms-1 fs-7'
                        data-bs-toggle='tooltip'
                        title='Phone number must be active'
                    ></i>
                  </label>

                  <div className='col-lg-8 d-flex align-items-center'>
                    <span className='fs-6 me-2'>{initialValues?.telephone_number}</span>

                    <span className='badge badge-success'>
      {initialValues?.telephone_number ? 'Verified' : ''}
    </span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>携帯番号</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.mobile_phone_number}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>FAX番号</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.fax_number}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>決済方法</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.payment_method}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>設立年月</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.establishment_date}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>実態/業種</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.actual_industry}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>ホームページURL</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.website_url}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>実店舗</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.physical_store}</span>
                  </div>
                </div>
                <div className='row mb-7'>
                  <label className='col-lg-4 fw-bold text-dark'>メール案内</label>

                  <div className='col-lg-8'>
                    <span className='fs-6 text-dark'>{initialValues?.email_notifications}</span>
                  </div>
                </div>
              </div>
              </div>
             :
              <>
          <div className='card mb-6 shadow-sm'>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>基本情報</span>
            </div>
            <div className='card-body'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>法人・個人</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.corporation_or_individual}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>会社名</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.company_name}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>お店屋号</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.shop_name}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>お店屋号（カナ）</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.shop_name_kana}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>代表者名</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.representative_name}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>本社所在地 郵便番号</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.headoffice_address_postal_code}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>本社所在地 都道府県</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.headoffice_address_prefecture}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>本社所在地 市区町村</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.headoffice_address_city}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>本社所在地 丁目番地</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.headoffice_address_street}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>本社所在地 建物名</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.headoffice_address_building_name}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>法人番号</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.corporate_number}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>適格請求書発行事業者登録番号</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.invoice_number}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>設立</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.establishment_date}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>決算期</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.fiscal_year_end}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>年商</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.annual_sales}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>定休日</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.regular_holiday}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>営業時間</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.business_hours}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='card mb-6 shadow-sm'>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>その他情報</span>
            </div>
            <div className='card-body'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>メインの取り扱いジャンル</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.main_genre}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>その他の取り扱いジャンル</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.secondary_genre}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>事業内容</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.business_description}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>会社紹介文</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.shop_introduction}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='card mb-6 shadow-sm'>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>連絡先情報</span>
            </div>
            <div className='card-body'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>メインの連絡先メールアドレス</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.primary_contact_email}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>責任者</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.person_in_charge}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>責任者フリガナ</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.person_in_charge_kana}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>責任者　メールアドレス</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.person_in_charge_email}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>責任者　電話番号</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.person_in_charge_phone}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>責任者　電話番号２</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.person_in_charge_phone_secondary}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>担当者</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.contact_person}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>担当者フリガナ</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.contact_person_kana}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>担当者　メールアドレス</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.contact_person_email}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>担当者　電話番号</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.contact_person_phone}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>担当者　電話番号２</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    {initialValues?.contact_person_phone_secondary}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='card mb-6 shadow-sm'>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>その他</span>
            </div>
            <div className='card-body'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>紹介者</label>
                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{initialValues?.referrer}</span>
                </div>
              </div>
            </div>
          </div>
              </> }
          <div className='card mb-6 shadow-sm'>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>ご契約プラン</span>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <div className='row mb-6'>
                    <div className='col-12'>
                      <label className='col-form-label fs-3 fw-bold'>初期費用</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row' style={{maxWidth: 500}}>
                <div data-kt-buttons='true'>
                  <label className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5 active'>
                    <div className='d-flex align-items-center me-2'>
                      <div className='form-check form-check-custom form-check-primary me-6'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='plan'
                          value={initialValues.initCost}
                          checked={true}
                          onChange={() => {
                            // initialValues.initCost = '1'
                            // let obj = JSON.parse(JSON.stringify(initialValues))
                            // setInitialValues(obj)
                          }}
                        />
                      </div>
                      <div className='flex-grow-1'>
                        <h2 className='d-flex align-items-center fs-4 fw-bold flex-wrap'>
                          初期費用
                        </h2>
                        <div className='fw-semibold opacity-50'>初期にかかる費用です。</div>
                      </div>
                    </div>
                    <div className='ms-5'>
                      <span className='fs-2x fw-bold'>{chooseASetMeal.initial_fee}円</span>
                      <span className='fs-7 opacity-50'>
                        /<span data-kt-element='period'>初回</span>
                      </span>
                    </div>
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='row mb-6'>
                    <div className='col-12'>
                      <label className='col-form-label fs-3 fw-bold'>ご選択プラン</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row' style={{maxWidth: 500}}>
                <div data-kt-buttons='true'>
                  <label
                    className={
                      'btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5 mx-3 active'
                    }
                  >
                    <div className='d-flex align-items-center me-2'>
                      <div className='form-check form-check-custom form-check-primary me-6'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='plan1'
                          value={chooseASetMeal.pk}
                          checked={true}
                          onChange={(e) => {}}
                        />
                      </div>
                      <div className='flex-grow-1'>
                        <h2 className='d-flex align-items-center fs-4 fw-bold flex-wrap'>
                          {chooseASetMeal.name}
                        </h2>
                        <div className='fw-semibold opacity-50'>{chooseASetMeal.description}</div>
                      </div>
                    </div>
                    <div className='ms-5'>
                      <span className='fs-2x fw-bold'>
                        {chooseASetMeal.fee * (Number(chooseASetMeal.tax_rate) / 100) +
                          Number(chooseASetMeal.fee)}
                      </span>
                      <span className='mb-2'>円</span>
                      <span className='fs-7 opacity-50'>
                        /
                        <span data-kt-element='period'>
                          {chooseASetMeal.duration == '毎月' ? '月' : '年'}
                        </span>
                      </span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <button
          className='btn btn-secondary me-3'
          onClick={() => {
            navigate(-1)
          }}
        >
          戻る
        </button>
        <button
          data-kt-indicator={isLoading?'on':'off'}
          disabled={isLoading}
          className={isLoading? 'btn btn-secondary' : 'btn btn-primary'}
          onClick={() => {
            Settlement()
          }}
        >
          <Spin  spinning={isLoading}></Spin>
          申込する
        </button>
      </div>

    </div>
  )
}
