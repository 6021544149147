const calculatePrice = (item: any) => {
    // console.log('item111', item)
    let itemId: any = ''
    if (item.grade && item.customer_rank_specific_pricing.length > 0) {
        itemId = item.customer_rank_specific_pricing.findIndex((e: any) => e.id == item.grade)
    }
    // console.log('itemId', itemId)
    // 単価
    if (item.price_range == 'unit_price') {
        // 顧客ランク別価格設定
        if (itemId >= 0 && item.customer_rank_specific_pricing.length > 0 && item.customer_rank_specific_pricing[itemId]?.quantity_discount?.price ||
            itemId >= 0 && item.customer_rank_specific_pricing.length > 0 && item.customer_rank_specific_pricing[itemId]?.price
        ) {
            // 数量割引
            if (item.customer_rank_specific_pricing[itemId]?.quantity_discount?.price) {
                let calcPriceRange = item.customer_rank_specific_pricing[itemId]?.quantity_discount?.price?.split(',')
                if (calcPriceRange && calcPriceRange.length > 0) {
                    calcPriceRange = calcPriceRange?.map((str: string) => {
                        let [num, price] = str.split(':')
                        return { num: parseInt(num), price: parseInt(price) }
                    })
                    calcPriceRange?.sort((a: any, b: any) => a.num - b.num)
                    if (item.quantity < calcPriceRange[0].num) {
                        item.unit_price = item.calc_price * item.quantity
                    } else {
                        for (let i = 0; i < calcPriceRange?.length; i++) {
                            if (item.quantity == calcPriceRange[i].num) {
                                item.unit_price = calcPriceRange[i].price
                            } else if (item.quantity > calcPriceRange[i].num) {
                                if (item.customer_rank_specific_pricing[itemId]?.price) {
                                    item.unit_price = calcPriceRange[i].price + Number(item.customer_rank_specific_pricing[itemId]?.price) * Number(item.quantity - calcPriceRange[i].num)
                                } else {
                                    item.unit_price = calcPriceRange[i].price + item.calc_price * (item.quantity - calcPriceRange[i].num)
                                }
                            }
                        }
                    }
                }
            } else if (item.customer_rank_specific_pricing[itemId]?.price) {
                // 個別用価格設定
                item.unit_price = Number(item.customer_rank_specific_pricing[itemId]?.price) * item.quantity
            }
        } else if (item.quantity_discount && item.quantity_discount.length > 0) {
            // 数量割引
            const calcPriceRange = item.quantity_discount
            calcPriceRange?.sort((a: any, b: any) => a.unit - b.unit)
            if (item.quantity < Number(calcPriceRange[0].unit)) {
                item.unit_price = item.calc_price * item.quantity
            } else {
                for (let i = 0; i < calcPriceRange?.length; i++) {
                    if (item.quantity == Number(calcPriceRange[i].unit)) {
                        item.unit_price = Number(calcPriceRange[i].price)
                    }
                    if (item.quantity > Number(calcPriceRange[i].unit)) {
                        item.unit_price = Number(calcPriceRange[i].price) + item.calc_price * (item.quantity - calcPriceRange[i].unit)
                    }
                }
            }
        } else {
            // 通常単価
            item.unit_price = item.calc_price * item.quantity
        }
    } else if (item.price_range == 'price_range') {
        // 顧客ランク別価格設定
        if (itemId >= 0 && item.customer_rank_specific_pricing.length > 0 && item.customer_rank_specific_pricing[itemId]?.quantity_discount?.price_range ||
            itemId >= 0 && item.customer_rank_specific_pricing.length > 0 && item.customer_rank_specific_pricing[itemId]?.price_range
        ) {
            // 数量割引
            if (item.customer_rank_specific_pricing[itemId]?.quantity_discount?.price_range) {
                let calcPriceRange = item.customer_rank_specific_pricing[itemId]?.quantity_discount?.price_range?.split(',')
                // console.log('calcPriceRange1111', calcPriceRange)
                if (calcPriceRange && calcPriceRange.length > 0) {
                    calcPriceRange = calcPriceRange?.map((str: string) => {
                        let [num, price] = str.split(':')
                        return { num: parseInt(num), price: price }
                    })
                    calcPriceRange?.sort((a: any, b: any) => a.num - b.num)
                    // console.log('calcPriceRange2222', calcPriceRange)
                    if (item.quantity < calcPriceRange[0].num) {
                        // 価格レンジ
                        item.price_range_min = Number(item.calc_price_min) * item.quantity
                        item.price_range_max = Number(item.calc_price_max) * item.quantity
                    } else {
                        for (let i = 0; i < calcPriceRange?.length; i++) {
                            // console.log('calcPriceRange[i].price', calcPriceRange[i].price)
                            if (item.quantity == calcPriceRange[i].num) {
                                // 価格レンジ
                                item.price_range_min = Number(calcPriceRange[i].price.split('|')[0])
                                item.price_range_max = Number(calcPriceRange[i].price.split('|')[1])
                            } else if (item.quantity > calcPriceRange[i].num) {
                                // 価格レンジ
                                if (item.customer_rank_specific_pricing[itemId]?.price_range_min) {
                                    item.price_range_min = Number(calcPriceRange[i].price.split('|')[0]) + Number(item.customer_rank_specific_pricing[itemId]?.price_range_min) * Number(item.quantity - calcPriceRange[i].num)
                                } else {
                                    item.price_range_min = Number(calcPriceRange[i].price.split('|')[0]) + item.calc_price_min * (item.quantity - calcPriceRange[i].num)
                                }
                                if (item.customer_rank_specific_pricing[itemId]?.price_range_max) {
                                    item.price_range_max = Number(calcPriceRange[i].price.split('|')[1]) + Number(item.customer_rank_specific_pricing[itemId]?.price_range_max) * Number(item.quantity - calcPriceRange[i].num)
                                } else {
                                    item.price_range_max = Number(calcPriceRange[i].price.split('|')[1]) + item.calc_price_max * (item.quantity - calcPriceRange[i].num)
                                }
                            }
                        }
                    }
                }
            } else {
                if (item.customer_rank_specific_pricing[itemId]?.price_range_min) {
                    // 個別用価格設定
                    item.price_range_min = Number(item.customer_rank_specific_pricing[itemId]?.price_range_min) * item.quantity
                }
                if (item.customer_rank_specific_pricing[itemId]?.price_range_max) {
                    // 個別用価格設定
                    item.price_range_max = Number(item.customer_rank_specific_pricing[itemId]?.price_range_max) * item.quantity
                }
            }
        } else if (item.quantity_discount && item.quantity_discount.length > 0) {
            // 数量割引
            const calcPriceRange = item.quantity_discount
            calcPriceRange?.sort((a: any, b: any) => a.unit - b.unit)
            if (item.quantity < Number(calcPriceRange[0].unit)) {
                item.price_range_min = Number(item.calc_price_min) * item.quantity
                item.price_range_max = Number(item.calc_price_max) * item.quantity
            } else {
                for (let i = 0; i < calcPriceRange?.length; i++) {
                    if (item.quantity == Number(calcPriceRange[i].unit)) {
                        item.unit_price = Number(calcPriceRange[i].price)
                        item.price_range_min = Number(calcPriceRange[i].price_range_min)
                        item.price_range_max = Number(calcPriceRange[i].price_range_max)
                    }
                    if (item.quantity > Number(calcPriceRange[i].unit)) {
                        item.price_range_min = Number(calcPriceRange[i].price_range_min) + item.calc_price_min * (item.quantity - calcPriceRange[i].unit)
                        item.price_range_max = Number(calcPriceRange[i].price_range_max) + item.calc_price_max * (item.quantity - calcPriceRange[i].unit)
                    }
                }
            }
        } else {
            // 価格レンジ
            item.price_range_min = Number(item.calc_price_min) * item.quantity
            item.price_range_max = Number(item.calc_price_max) * item.quantity
        }
    }
}

export {
    calculatePrice
}