import {ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Checkbox from '../components/Checkbox'
import TableHeader from '../components/TableHeader'
import CreateFolder from '../components/CreateFolder'
import moment from 'moment/moment'
import {CloudDownloadOutlined} from '@ant-design/icons'
import CustomFieldActionsCell from '../components/CustomFieldActionsCell'
import {TMedia} from '../types'
import SearchFilter from '../components/SearchFilter'
import {Button, Modal, Tooltip, message} from 'antd'
import UploadFileCompont from './UploafFile'
import {deleteMedia, downloadMedia} from 'src/app/modules/api'
import {createZipWithFolderStructure, downloadFile, formatFileSize} from '@/utils'
import {getSignedUrl} from 'src/app/modules/api'
import {useAuth} from '@/app/modules/auth'
import {useLayout} from '@/_metronic/layout/core'
import {TypeIcon} from './TypeIcon'

type TableProps = {
  dataTable: TMedia[]
  onSelectFolder: (folder: TMedia) => void
  onDoubleRow?: (folder: TMedia) => void
  onCreateFolder: (folderName: string) => Promise<void>
  uploadFile: (file: File) => Promise<void>
  refetch: () => void
  loading: boolean
  mediaEditName?: TMedia
  parent?: string
  setMediaEditName?: (media?: TMedia) => void
  onSaveMediaEditName: (name?: string) => Promise<void>
}

const NameFile: FC<{name: string}> = ({name}) => {
  const [namePreview, setNamePreview] = useState('')

  useEffect(() => {
    if (name.length > 50) {
      setNamePreview(name.substring(0, 40))
    } else setNamePreview(name)
  }, [name])

  if (name !== namePreview) {
    return (
      <Tooltip title={name}>
        {namePreview}...{name.substring(name.length - 7, name.length)}
      </Tooltip>
    )
  }

  return <>{namePreview}</>
}

export const MediaTable = (props: TableProps) => {
  const {
    dataTable = [],
    onSelectFolder,
    onCreateFolder,
    uploadFile,
    onDoubleRow,
    loading,
    refetch,
    mediaEditName,
    setMediaEditName,
    onSaveMediaEditName,
    parent,
  } = props
  const {setContentLoading} = useLayout()
  const [list, setList] = useState<TMedia[]>(dataTable)
  const [selectedRows, setSelectedRows] = useState<TMedia[]>([])
  const [sort, setSort] = useState<{id?: string; type?: 'asc' | 'desc'; keyword?: string}>({
    id: 'name',
    type: 'asc',
  })
  const [btnMoldaDelteLoading, setMoldaDelteLoading] = useState<boolean>(false)
  const [showCreateNewFolder, setShowCreateNewFolder] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [openUpload, setOpenUpload] = useState(false)
  const [mediaDelete, setMediaDelete] = useState<TMedia>()
  const timeoutClick = useRef<NodeJS.Timeout>()
  const {auth} = useAuth()

  const related_id = useMemo(() => {
    return auth.group_id || auth.username
  }, [auth.group_id, auth.username])

  const supplierId = useMemo(() => {
    return related_id?.split('#Suppliers#')?.[1]
  }, [related_id])

  useEffect(() => {
    setSelectedRows([])
  }, [parent])

  const handleDeleteFolderOrFile = useCallback(async () => {
    setMoldaDelteLoading(true)
    let idsDelete = selectedRows.map((e) => e.pk)
    if (mediaDelete) {
      idsDelete = [mediaDelete.pk]
    }
    try {
      await deleteMedia(idsDelete)
    } catch {}
    setTimeout(refetch, 500)
    setMoldaDelteLoading(false)
    setIsModalOpen(false)
    if (mediaDelete) {
      setMediaDelete(undefined)
      setSelectedRows(selectedRows.filter((e) => e.pk !== mediaDelete.pk))
    } else setSelectedRows([])
  }, [selectedRows, mediaDelete, refetch])

  const effectSortData = useCallback(() => {
    const id = sort?.id as keyof TMedia
    const type = sort.type || 'desc'
    let listSort = [...dataTable]
      .filter((e) => e.name.toLowerCase().includes((sort.keyword || '').toLowerCase()))
      .sort((a, b) => {
        const pre = a[id]
        const next = b[id]
        if (type === 'asc') {
          if (typeof pre === 'number') return pre - (next as number)
          return pre > next ? 1 : -1
        } else {
          if (typeof pre === 'number') return (next as number) - pre
          return pre > next ? -1 : 1
        }
      })
      .sort((a, b) => (a.is_folder > b.is_folder ? -1 : 1))
    setList(listSort)
  }, [dataTable, sort])

  useEffect(() => {
    effectSortData()
  }, [effectSortData])

  const doModalCancel = useCallback(() => {
    setIsModalOpen(false)
    setMediaDelete(undefined)
  }, [])

  const onCheckedAllChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSelectedRows(e.target.checked ? list : [])
    },
    [list]
  )

  const onCheckedRow = useCallback((e: ChangeEvent<HTMLInputElement>, row: TMedia) => {
    setSelectedRows((pre) => {
      if (e.target.checked) return [...pre, row]
      return pre.filter((e) => e.pk !== row.pk)
    })
  }, [])

  const showModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleCreateFolder = useCallback(
    async (folderName: string) => {
      return onCreateFolder(folderName).then(() => {
        setShowCreateNewFolder(false)
      })
    },
    [onCreateFolder]
  )

  const handleSortColumn = useCallback((sortData: {id: string; type: 'asc' | 'desc'}) => {
    setSort(sortData)
  }, [])

  const onClickRow = useCallback(
    (folder: TMedia) => {
      if (timeoutClick.current) {
        clearTimeout(timeoutClick.current)
        timeoutClick.current = undefined
        if (onDoubleRow) {
          return onDoubleRow?.(folder)
        }
      }
      timeoutClick.current = setTimeout(() => {
        timeoutClick.current = undefined
        onSelectFolder(folder)
      }, 200)
    },
    [onDoubleRow, onSelectFolder]
  )

  const onSearch = useCallback((k: string) => {
    setSort((pre) => ({...pre, keyword: k}))
  }, [])

  const onDownload = useCallback(
    async (media: TMedia) => {
      setContentLoading(true)
      try {
        if (!media.is_folder) {
          const url = await getSignedUrl({
            key: `${supplierId}${media.path}`,
            action: 'get',
          }).then(({data}: any) => data.url)
          downloadFile(url.split('?')[0], media.name)
        } else {
          const response: any = await downloadMedia(media.pk)
          await createZipWithFolderStructure(
            response.urls,
            `downloadMedias_${moment().format('yyyymmddhis')}`,
            supplierId
          )
        }
      } catch (e: any) {
        message.error(e.message)
      }
      setContentLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [supplierId]
  )

  const onDownloadAll = useCallback(async () => {
    setContentLoading(true)
    try {
      const urls = await Promise.all(
        selectedRows.map(async (e) => {
          if (!e.is_folder) {
            return e.path
          }
          const response: any = await downloadMedia(e.pk).catch(() => ({urls: []}))
          return response.urls
        })
      ).then((res) => res.flat())
      await createZipWithFolderStructure(
        urls,
        `downloadMedias_${moment().format('yyyymmddhis')}`,
        supplierId
      )
    } catch (e: any) {
      message.error(e.message)
    } finally {
      setContentLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, supplierId])

  const onCopy = useCallback(
    async (media: TMedia) => {
      setContentLoading(true)
      getSignedUrl({key: `${supplierId}${media.path}`, action: 'get'})
        .then(({data}: any) => {
          navigator.permissions.query({name: 'clipboard-write' as any}).then((result) => {
            if (result.state === 'granted' || result.state === 'prompt') {
              navigator.clipboard.writeText(data.url.split('?')[0]).then(() => {
                message.success('コピーしました。')
              })
            } else message.error('コピー権限がありません')
          })
        })
        .catch((e) => message.error(e.message))
        .finally(() => setContentLoading(false))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [supplierId]
  )

  return (
    <>
      <div className='d-flex flex-stack mb-5 flex-sm-row flex-column' style={{gap: '15px'}}>
        <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
          <div className='d-flex align-items-center position-relative'>
            <SearchFilter onKeywordSearch={onSearch} />
          </div>
        </div>

        {selectedRows.length > 0 ? (
          <div className='d-flex justify-content-end align-items-center'>
            <div className='fw-bolder me-5'>
              <span className='me-2'>{selectedRows.length}</span> 選択された
            </div>

            <button type='button' className='btn btn-danger' onClick={showModal}>
              一括削除
            </button>
            <button
              type='button'
              className='btn btn-primary d-flex align-items-center gap-2'
              style={{marginLeft: 10}}
              onClick={onDownloadAll}
            >
              <CloudDownloadOutlined style={{marginTop: 1}} rev={undefined} />
              ダウンロード
            </button>
          </div>
        ) : (
          <div
            className='d-flex align-items-sm-center align-items-start flex-sm-row flex-column'
            style={{gap: '15px'}}
          >
            <button
              type='button'
              className='btn btn-primary d-flex align-items-center gap-1'
              onClick={() => {
                setShowCreateNewFolder(true)
              }}
            >
              <i className='bi bi-folder-plus'></i>
              新規フォルダ作成
            </button>

            <button
              type='button'
              className='btn btn-primary d-flex align-items-center'
              onClick={() => setOpenUpload(true)}
            >
              <i className='bi bi-file-earmark-arrow-up mt-1'></i>
              ファイルアップロード
            </button>
          </div>
        )}
      </div>
      <table
        className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer'
      >
        <thead className='table-light'>
          <tr className='text-start fs-6 text-uppercase gs-0'>
            <th className='pe-2' style={{minWidth: 43, maxWidth: 43, width: 43}}>
              <Checkbox
                checked={selectedRows.length === list?.length && selectedRows.length !== 0}
                onChange={onCheckedAllChange}
              />
            </th>
            <TableHeader id='name' title='名前' sort={sort} onSortColumn={handleSortColumn} />
            <TableHeader
              textAlign='center'
              id='size'
              title='サイズ'
              sort={sort}
              onSortColumn={handleSortColumn}
              style={{width: 200}}
            />
            <TableHeader
              id='updateDate'
              style={{width: 150}}
              title='最終更新日'
              sort={sort}
              onSortColumn={handleSortColumn}
            />
            <th style={{width: 110, textAlign: 'end'}}>操作</th>
          </tr>
        </thead>
        <tbody>
          {showCreateNewFolder && (
            <CreateFolder
              onCreateFolder={handleCreateFolder}
              onHide={() => {
                setShowCreateNewFolder(false)
              }}
            />
          )}
          {list?.map((item) => {
            return (
              <tr key={item.pk}>
                {mediaEditName?.pk === item.pk ? (
                  <CreateFolder
                    isFolder={mediaEditName.is_folder}
                    defaultValue={mediaEditName.name}
                    onHide={() => setMediaEditName?.(undefined)}
                    isEdit
                    onCreateFolder={onSaveMediaEditName}
                  />
                ) : (
                  <>
                    <td>
                      <div className='form-check form-check-sm form-check-custom me-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          data-kt-check-target='#kt_table_users .form-check-input'
                          checked={selectedRows.some((e) => e.pk === item.pk)}
                          onChange={(e) => onCheckedRow(e, item)}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-center' onClick={() => onClickRow(item)}>
                        <TypeIcon isFolder={!!item.is_folder} />
                        <span className={'cursor-pointer'}>
                          <NameFile name={item.name} />
                        </span>
                      </div>
                    </td>
                  </>
                )}
                <td style={{textAlign: 'center'}}>{formatFileSize(item.size)}</td>
                <td>{moment(item.update_at).format('YYYY-MM-DD')}</td>

                <td style={{minWidth: 90, display: 'flex', justifyContent: 'flex-end'}} colSpan={5}>
                  <CustomFieldActionsCell
                    setMediaEditName={() => setMediaEditName?.(item)}
                    onDeleteMedia={() => setMediaDelete(item)}
                    onDownload={() => onDownload(item)}
                    onCopy={() => onCopy(item)}
                    isFolder={!!item.is_folder}
                  />
                </td>
              </tr>
            )
          })}
          {!list?.length ? (
            <tr>
              <td colSpan={17}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  {loading ? 'データの取得中' : '該当するレコードがありません。'}
                </div>
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
      <UploadFileCompont
        open={openUpload}
        onClose={() => setOpenUpload(false)}
        onUpload={uploadFile}
        accept='.jpg,.jpeg,.png'
        medias={dataTable}
      />
      <Modal
        title='削除しますか?'
        open={isModalOpen || !!mediaDelete}
        onOk={handleDeleteFolderOrFile}
        onCancel={doModalCancel}
        closable={!btnMoldaDelteLoading}
        footer={[
          <Button key='cancel' disabled={btnMoldaDelteLoading} onClick={doModalCancel}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={btnMoldaDelteLoading}
            onClick={handleDeleteFolderOrFile}
            disabled={btnMoldaDelteLoading}
          >
            OK
          </Button>,
        ]}
      >
        <p>回復することはできません。</p>
      </Modal>
    </>
  )
}
