/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { ID, KTSVG, QUERIES } from '@/_metronic/helpers'
import { useNavigate } from 'react-router-dom';
import { MenuComponent } from 'src/_metronic/assets/ts/components'
import { showSuccessMessage, showErrorMessage, showWarningMessage } from 'src/app/components/sweetAlert';
import { customerDataDelete } from 'src/app/modules/api'
import { useAuth } from 'src/app/modules/auth'

const CustomFieldActionsCell = (props: any) => {
  const navigate = useNavigate()
  const {auth} = useAuth();
  let headers = {
    "Content-Type":"application/json",
    Authorization:`Bearer ${auth.token}`
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // 删除某一项
  const deleteList = (item:any) => {
    console.log('deleteList',item);
    if (item.status !== 'non_approval') {
      showWarningMessage(
        '元に戻すことはできません。よろしいでしょうか？', () => {
          customerDataDelete({pkVal: item.pk}, headers).then((res:any)=> {
            console.log('res',res)
            props.getAllList()
            showSuccessMessage('削除しました')
          })
        },
        '削除',
        'キャンセル'
      )
    } else {
      showWarningMessage('非承認状態のため、削除できません。');
    }
  }

  const goDetail = async (item: any) => {
    navigate(`/customer/${item.customer_id}`, { state: { pk: item.pk, type: 'detail', group_id: item.group_id, status: item.status } })
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        操作
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        <div className="menu-item px-3">
          <a style={{ fontSize: '13px' }}
          onClick={() => goDetail(props.item)}
          className={ "menu-link px-3 py-2  btn"} data-kt-docs-table-filter="edit_row">
            詳細
          </a>
        </div>
        <div className="menu-item px-3">
          <a style={{ fontSize: '13px' }}
          onClick={() => deleteList(props.item)}
          className={ "menu-link px-3 py-2  btn"} data-kt-docs-table-filter="delete_row">
            削除
          </a>
        </div>
      </div>
    </>
  )
}

export { CustomFieldActionsCell }
