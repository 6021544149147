import { getServer, postServer, deleteServer } from 'src/service/http'

// 仕入先申請一覧取得-状态排序
export function supplierApplicationListGet(param: object,headers:object={}) {
    return getServer(`/supplier-application-list-get`, { ...param},{...headers})
}

// 仕入先申請
export function supplierApplication(param: object,headers:object={}) {
    return postServer(`/supplier-application`, { ...param },{...headers})
}

// 仕入先申請取消
export function supplierApplicationCancel(param: object,headers:object={}) {
    return postServer(`/supplier-application-cancel`, { ...param },{...headers})
}