import { getServer, postServer, deleteServer } from 'src/service/http'

// 利用状況取得
export function subscriptionUsageInfoGet(param: object,headers:object={}) {
    return getServer(`/subscription-usage-info-get`, {...param},{...headers})
}

// 利用状況更新
export function subscriptionUsageInfoUpdate(param: object) {
    return postServer(`/subscription-usage-info-update`, {...param})
}

// 利用状況登録
export function subscriptionUsageInfoAdd(param: object) {
    return postServer(`/subscription-usage-info-add`, {...param})
}

// 顧客データダウンロード
export function subscriptionInfoGet(param: object) {
    // return getServer(`/subscription-info-get`, {...param,target_type:supplier.userInfo_type})
    return getServer(`/subscription-info-get`, {...param})
}

export function getUserSubscriptionInfo(json: object,headers:object={}) {
    return getServer('/user-subscription-info-get', json,{...headers});
}

// 利用状況取得
export function usageInfo(json: object,headers:object={}) {
    return getServer('/subscription-usage-info-get', json,{...headers});
}
export function usageInfoAdd(json: object, headers:object={}) {
    // return postServer('/subscription-usage-info-add', {...json,user_id:supplier.update_user,pk:supplier.supplier_pk});
    return postServer('/subscription-usage-info-add', {...json}, {...headers});
}
export function usageInfoUpdate(json: object, headers:object={}) {
    // return postServer('/subscription-usage-info-update', {...json,user_id:supplier.update_user,pk:supplier.supplier_pk});
    return postServer('/subscription-usage-info-update', {...json}, {...headers});
}

// 支払い情報取得
export function subscriptionPaymentInfoGet(json: object) {
    return getServer('/subscription-payment-info-get', json);
}

export function subscriptionPaymentInfoAdd(json: object, headers:object={}) {
    // return postServer('/subscription-payment-info-add', {...json,user_id:supplier.update_user,update_user:supplier.update_user,group_id:supplier.supplier_pk});
    return postServer('/subscription-payment-info-add', {...json},{...headers});
  }
  export function subscriptionPaymentInfoUpdate(json: object, headers:object={}) {
    // return postServer('/subscription-payment-info-update', {...json,user_id:supplier.update_user,update_user:supplier.update_user,group_id:supplier.supplier_pk,pkVal:supplier.supplier_pk});
    return postServer('/subscription-payment-info-update', {...json},{...headers});
  }