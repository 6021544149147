import React, { useState } from 'react';
// import { BankCard } from './components/BankCard';
import BankCardRegisterMulti from '@/app/components/bankCard/bankCardRegisterMulti';

export default function UserCardManagement() {

  return (
    <div>
      {/* <BankCard headTitle={'仕入に関してのお支払方法'} name={'paymentPurchase'} closeRightTitle /> */}
      <BankCardRegisterMulti title={'仕入れ先別お支払い方法管理'} type={'SMBC'} />
    </div>
  );
}
