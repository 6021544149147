import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '@/_metronic/helpers'
import {useLocation, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Modal} from 'react-bootstrap'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from '@/app/components/sweetAlert'
import {BankCard} from '@/app/pages/supplier/account/components/settings/components/BankCard'
import BankCardPaymentPlan from '@/app/components/bankCard/bankCardPayment/bankCardPaymentPlan';
interface FormType {
  id: string
  name: string
  tag: string[]
  save: boolean
  url: string
  idCard: string
  card: string
  date: string
  year: string
  month: string
  cvv: string
}

export default function PlanPayment() {
  const location = useLocation()
  const [showModalCard, setShowModalCard] = useState(false)
  const [data, setData] = useState<any>({})
  const navigate = useNavigate()
  const [id, setId] = useState<any>('')
  const [cardList, setCardList] = useState<any>([])
  const validationSchema = Yup.object({
    name: Yup.string().required('カード名義は必須です。'),
    idCard: Yup.string().length(16, 'カード番号は無効です').required('カード番号は必須です。'),
    year: Yup.string().required('有効期限は必須です。'),
    month: Yup.string().required('有効期限は必須です。'),
    cvv: Yup.string().required('CVVは必須です。'),
  })
  const [initialValues, setInitialValues] = useState<FormType>({
    id: '',
    name: '',
    tag: ['new'],
    save: false,
    url: '/media/svg/card-logos/american-express.svg',
    idCard: '',
    card: '',
    date: '',
    year: '',
    month: '',
    cvv: '',
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (id) {
          let idx = cardList.findIndex((item: any) => {
            return item.id === id
          })
          cardList[idx] = values
          let arr = JSON.parse(JSON.stringify(cardList))
          setCardList(arr)
          showSuccessMessage('更新しました')
          handleCloseCard()
        } else {
          values.id = String(Date.now())
          cardList.push(values)
          let arr = JSON.parse(JSON.stringify(cardList))
          setCardList(arr)
          showSuccessMessage('更新しました')
          handleCloseCard()
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  let monthArray = []
  for (let i = 1; i <= 12; i++) {
    monthArray.push(i)
  }
  let yearArray = []
  const currentYear = new Date().getFullYear()
  for (let i = currentYear; i > currentYear - 100; i--) {
    yearArray.push(i)
  }
  const [years, setYears] = useState(yearArray)
  const [chooseASetMeal, setChooseASetMeal] = useState<any>({})

  const deleteCard = (id: any) => {
    showWarningMessage(
      'このカード情報を削除してよろしいですか？',
      async () => {
        let idx = cardList.findIndex((item: any) => {
          return item.id === id
        })
        if (idx !== -1) {
          cardList.splice(idx, 1)
          let arr = JSON.parse(JSON.stringify(cardList))
          setCardList(arr)
          showSuccessMessage('カード情報を削除しました。')
        }
      },
      'OK',
      'キャンセル'
    )
  }
  const handleCloseCard = () => {
    setShowModalCard(false)
  }
  const handleShowCard = () => {
    setShowModalCard(true)
  }

  const init = () => {
    let setMeal = localStorage.getItem('setMeal')
    if (setMeal) {
      setChooseASetMeal(JSON.parse(setMeal))
    }else {
      showErrorMessage('コース未選択', ()=>{
        localStorage.removeItem('setMeal')
        localStorage.removeItem('allMealList')
        localStorage.removeItem('accountFormStorageInformation')
        navigate(`/plan`)
      })
    }
    // let obj = location.state
    // setData(obj)
  }

  useEffect(() => {
    init()
  }, [])
  const [card,setCard] = useState<any>({})
  const getCardData = (card:any)=>{
    setCard(card)
    // setPageGetData({...pageGetData,card})
  }
  return (
    <div>
      <div className='card mb-3'>
        <div className='card-body'>
          <div className='mb-13 text-center'>
            <h1 className='fs-2hx fw-bold mb-5'>決済登録</h1>
            <div className='fw-semibold fs-5'>
              お支払するクレジットカードをご登録ください。
            </div>
          </div>
          <div className='mw-lg-950px mx-auto w-100'>
            <div className='d-flex flex-column gap-7 gap-md-10'>
              <div className='d-flex justify-content-between flex-column'>
                <div className='d-flex justify-content-center border-bottom mb-9 mx-auto' style={{maxWidth: '600px'}}>

                  <dl className='row'>
                    <dt className='col-sm-3 fs-4 fw-bold mb-5'>プラン</dt>
                    <dd className='col-sm-9 fs-6 mb-5'>{chooseASetMeal ? chooseASetMeal.name : 'スタンダードプラン'}</dd>

                    <hr />

                    <dt className='col-sm-3 fs-4 fw-bold mb-5'>初期費用</dt>
                    {/* <dd className='col-sm-9 fs-6 mb-5'>{chooseASetMeal.fee * (Number(chooseASetMeal.tax_rate) / 100) + Number(chooseASetMeal.fee) + chooseASetMeal.initial_fee*1}円</dd> */}
                    <dd className='col-sm-9 fs-6 mb-5'>{Number(chooseASetMeal.initial_fee)}円</dd>
                    <hr />

                    <dt className='col-sm-3 fs-4 fw-bold mb-5'>契約期間</dt>
                    <dd className='col-sm-9 fs-6 mb-5'>
                    {chooseASetMeal
                            ? chooseASetMeal.duration == '毎月'
                              ? '1ヶ月更新'
                              : chooseASetMeal.duration
                            : '1ヶ月更新'}
                    </dd>

                    <hr />

                    <dt className='col-sm-9 fs-4 fw-bold mb-5 text-end'>総計</dt>
                    {/* <dd className='col-sm-3 fs-6 mb-5 text-end'>{chooseASetMeal.fee * (Number(chooseASetMeal.tax_rate) / 100) + Number(chooseASetMeal.fee)}円</dd> */}
                    <dd className='col-sm-3 fs-6 mb-5 text-end'>{chooseASetMeal.initial_fee}円</dd>
                  </dl>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <BankCard  headTitle={"決済方法の選択"} closeRightTitle={true}   title={'カード情報'} getCardData={getCardData}/> */}
      <BankCardPaymentPlan getCardData={getCardData}/>

      {/*<div className='card mb-3'>*/}
      {/*  <div className='card card-custom'>*/}
      {/*    <div className='card-header'>*/}
      {/*      <h3 className='card-title fw-bolder fs-6 '>*/}
      {/*        決済するクレジットカードを登録する*/}
      {/*      </h3>*/}
      {/*    </div>*/}
      {/*    <div className='card-body'>*/}
      {/*      <label className='fw-bolder fs-6 '>カード情報</label>*/}
      {/*      <div className='row gx-9 gy-6'>*/}
      {/*        {cardList.length !== 0 &&*/}
      {/*          cardList.map((item: any, index: number) => {*/}
      {/*            return (*/}
      {/*              <div className='col-xl-6' data-kt-billing-element='card' key={index}>*/}
      {/*                <div className='card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'>*/}
      {/*                  <div className='d-flex flex-column py-2'>*/}
      {/*                    <div className='d-flex align-items-center fs-4 fw-bold mb-5'>*/}
      {/*                      {item.name}*/}
      {/*                      {item.tag.length !== 0 &&*/}
      {/*                        item.tag.map((i: any, idx: number) => {*/}
      {/*                          return (*/}
      {/*                            <span className='badge badge-light-success fs-7 ms-2' key={idx}>*/}
      {/*                              {i}*/}
      {/*                            </span>*/}
      {/*                          )*/}
      {/*                        })}*/}
      {/*                    </div>*/}
      {/*                    <div className='d-flex align-items-center'>*/}
      {/*                      <img src={toAbsoluteUrl(item.url)} alt='' className='h-50px me-3' />*/}
      {/*                      <div>*/}
      {/*                        <div className='fs-4 fw-bold'>{item.idCard}</div>*/}
      {/*                        <div className='fs-6 fw-semibold '>*/}
      {/*                          {item.card} {item.year}/*/}
      {/*                          {item.month.length === 1 ? `0${item.month}` : item.month}*/}
      {/*                        </div>*/}
      {/*                      </div>*/}
      {/*                    </div>*/}
      {/*                  </div>*/}
      {/*                  <div className='d-flex align-items-center py-2'>*/}
      {/*                    <button*/}
      {/*                      className='btn btn-sm btn-light btn-active-light-primary me-3'*/}
      {/*                      onClick={() => {*/}
      {/*                        deleteCard(item.id)*/}
      {/*                      }}*/}
      {/*                    >*/}
      {/*                      <span className='indicator-label'>Delete</span>*/}
      {/*                      <span className='indicator-progress'>*/}
      {/*                        Please wait...*/}
      {/*                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>*/}
      {/*                      </span>*/}
      {/*                    </button>*/}
      {/*                    <button*/}
      {/*                      className='btn btn-sm btn-light btn-active-light-primary'*/}
      {/*                      onClick={() => {*/}
      {/*                        handleShowCard()*/}
      {/*                        let obj = JSON.parse(JSON.stringify(item))*/}
      {/*                        setInitialValues(obj)*/}
      {/*                        formik.setValues(obj)*/}
      {/*                        setId(item.id)*/}
      {/*                      }}*/}
      {/*                    >*/}
      {/*                      Edit*/}
      {/*                    </button>*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              </div>*/}
      {/*            )*/}
      {/*          })}*/}

      {/*        /!* 添加银行卡 *!/*/}
      {/*        <div className='col-xl-6'>*/}
      {/*          <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>*/}
      {/*            <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>*/}
      {/*              <div className='mb-3 mb-md-0 fw-semibold'>*/}
      {/*                <h4 className='fw-bold'>*/}
      {/*                  ここから新しいカードのご登録を行って頂けます。*/}
      {/*                </h4>*/}
      {/*                <div className='fs-6 pe-7'>*/}
      {/*                  以下のカードがご利用頂けます。*/}
      {/*                  <br /> visa master amex jcb dinners*/}
      {/*                </div>*/}
      {/*              </div>*/}

      {/*              <button*/}
      {/*                className='btn btn-primary px-6 align-self-center text-nowrap'*/}
      {/*                onClick={() => {*/}
      {/*                  handleShowCard()*/}
      {/*                  setId('')*/}
      {/*                  formik.setValues({*/}
      {/*                    id: '',*/}
      {/*                    name: '',*/}
      {/*                    save: false,*/}
      {/*                    tag: ['new'],*/}
      {/*                    url: '/media/svg/card-logos/american-express.svg',*/}
      {/*                    idCard: '',*/}
      {/*                    card: '',*/}
      {/*                    date: '',*/}
      {/*                    year: '',*/}
      {/*                    month: '',*/}
      {/*                    cvv: '',*/}
      {/*                  })*/}
      {/*                  setInitialValues({*/}
      {/*                    id: '',*/}
      {/*                    name: '',*/}
      {/*                    tag: ['new'],*/}
      {/*                    save: false,*/}
      {/*                    url: '/media/svg/card-logos/american-express.svg',*/}
      {/*                    idCard: '',*/}
      {/*                    card: '',*/}
      {/*                    date: '',*/}
      {/*                    year: '',*/}
      {/*                    month: '',*/}
      {/*                    cvv: '',*/}
      {/*                  })*/}
      {/*                }}*/}
      {/*              >*/}
      {/*                カード登録*/}
      {/*              </button>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <Modal*/}
      {/*    show={showModalCard}*/}
      {/*    onHide={handleCloseCard}*/}
      {/*    dialogClassName='modal-dialog-centered mw-650px'*/}
      {/*  >*/}
      {/*    <div className='modal-header'>*/}
      {/*      <h5 className='modal-title'>新しいクレジットカードを追加</h5>*/}
      {/*      <div*/}
      {/*        className='btn btn-icon btn-sm btn-active-light-primary ms-2'*/}
      {/*        onClick={() => {*/}
      {/*          handleCloseCard()*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        <KTSVG*/}
      {/*          path='/media/icons/duotune/arrows/arr061.svg'*/}
      {/*          className='svg-icon svg-icon-2x'*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>*/}
      {/*      <form*/}
      {/*        className='form fv-plugins-bootstrap5 fv-plugins-framework'*/}
      {/*        onSubmit={formik.handleSubmit}*/}
      {/*      >*/}
      {/*        /!* begin card_name *!/*/}
      {/*        <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>*/}
      {/*          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>*/}
      {/*            <span className='required'>カード名義</span>*/}
      {/*            <i*/}
      {/*              className='fas fa-exclamation-circle ms-1 fs-7'*/}
      {/*              style={{color: '#a1a5b796'}}*/}
      {/*              data-bs-toggle='tooltip'*/}
      {/*              title='カード名義'*/}
      {/*            ></i>*/}
      {/*          </label>*/}
      {/*          <input*/}
      {/*            className='form-control'*/}
      {/*            placeholder='カード名義'*/}
      {/*            {...formik.getFieldProps('name')}*/}
      {/*            value={formik.values.name}*/}
      {/*          />*/}
      {/*          {formik.touched.name && formik.errors.name && (*/}
      {/*            <div className='fv-plugins-message-container'>*/}
      {/*              <div className='fv-help-block'>{formik.errors.name}</div>*/}
      {/*            </div>*/}
      {/*          )}*/}
      {/*          <br />*/}
      {/*        </div>*/}
      {/*        <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>*/}
      {/*          <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>*/}
      {/*            <span className='required'>カード番号</span>*/}
      {/*          </label>*/}
      {/*          <div className='position-relative'>*/}
      {/*            <input*/}
      {/*              className='form-control'*/}
      {/*              placeholder='カード番号'*/}
      {/*              {...formik.getFieldProps('idCard')}*/}
      {/*              value={formik.values.idCard}*/}
      {/*            />*/}
      {/*            {formik.touched.idCard && formik.errors.idCard && (*/}
      {/*              <div className='fv-plugins-message-container'>*/}
      {/*                <div className='fv-help-block'>{formik.errors.idCard}</div>*/}
      {/*              </div>*/}
      {/*            )}*/}
      {/*            <div className='position-absolute translate-middle-y top-50 end-0 me-5'>*/}
      {/*              <img*/}
      {/*                src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}*/}
      {/*                alt=''*/}
      {/*                className='h-25px'*/}
      {/*              />*/}
      {/*              <img*/}
      {/*                src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}*/}
      {/*                alt=''*/}
      {/*                className='h-25px'*/}
      {/*              />*/}
      {/*              <img*/}
      {/*                src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}*/}
      {/*                alt=''*/}
      {/*                className='h-25px'*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        /!* end card_number *!/*/}
      {/*        <div className='row mb-10'>*/}
      {/*          <div className='col-md-8 fv-row'>*/}
      {/*            <label className='required fs-6 fw-semibold form-label mb-2'>有効期限</label>*/}
      {/*            <div className='row fv-row fv-plugins-icon-container'>*/}
      {/*              <div className='col-6'>*/}
      {/*                <select*/}
      {/*                  className='form-select select2-hidden-accessible'*/}
      {/*                  placeholder='Month'*/}
      {/*                  {...formik.getFieldProps('month')}*/}
      {/*                  value={formik.values.month}*/}
      {/*                >*/}
      {/*                  <option data-select2-id='select2-data-21-a82n'></option>*/}
      {/*                  <option value='1'>1</option>*/}
      {/*                  <option value='2'>2</option>*/}
      {/*                  <option value='3'>3</option>*/}
      {/*                  <option value='4'>4</option>*/}
      {/*                  <option value='5'>5</option>*/}
      {/*                  <option value='6'>6</option>*/}
      {/*                  <option value='7'>7</option>*/}
      {/*                  <option value='8'>8</option>*/}
      {/*                  <option value='9'>9</option>*/}
      {/*                  <option value='10'>10</option>*/}
      {/*                  <option value='11'>11</option>*/}
      {/*                  <option value='12'>12</option>*/}
      {/*                </select>*/}
      {/*                {formik.touched.month && formik.errors.month && (*/}
      {/*                  <div className='fv-plugins-message-container'>*/}
      {/*                    <div className='fv-help-block'>{formik.errors.month}</div>*/}
      {/*                  </div>*/}
      {/*                )}*/}
      {/*              </div>*/}
      {/*              <div className='col-6'>*/}
      {/*                <select*/}
      {/*                  className='form-select select2-hidden-accessible'*/}
      {/*                  placeholder='Year'*/}
      {/*                  {...formik.getFieldProps('year')}*/}
      {/*                  value={formik.values.year}*/}
      {/*                >*/}
      {/*                  <option data-select2-id='select2-data-21-a82n'></option>*/}
      {/*                  {years.map((year) => {*/}
      {/*                    return (*/}
      {/*                      <option key={year} value={year}>*/}
      {/*                        {year}*/}
      {/*                      </option>*/}
      {/*                    )*/}
      {/*                  })}*/}
      {/*                </select>*/}
      {/*                {formik.touched.year && formik.errors.year && (*/}
      {/*                  <div className='fv-plugins-message-container'>*/}
      {/*                    <div className='fv-help-block'>{formik.errors.year}</div>*/}
      {/*                  </div>*/}
      {/*                )}*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className='col-md-4 fv-row fv-plugins-icon-container'>*/}
      {/*            <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>*/}
      {/*              <span className='required'>CVV</span>*/}
      {/*              <i*/}
      {/*                className='fas fa-exclamation-circle ms-1 fs-7'*/}
      {/*                style={{color: '#a1a5b796'}}*/}
      {/*                data-bs-toggle='tooltip'*/}
      {/*                title='CVV'*/}
      {/*              ></i>*/}
      {/*            </label>*/}
      {/*            <div className='position-relative'>*/}
      {/*              <input*/}
      {/*                type='text'*/}
      {/*                className='form-control'*/}
      {/*                placeholder='CVV'*/}
      {/*                {...formik.getFieldProps('cvv')}*/}
      {/*                value={formik.values.cvv}*/}
      {/*              />*/}
      {/*              {formik.touched.cvv && formik.errors.cvv && (*/}
      {/*                <div className='fv-plugins-message-container'>*/}
      {/*                  <div className='fv-help-block'>{formik.errors.cvv}</div>*/}
      {/*                </div>*/}
      {/*              )}*/}
      {/*              <div className='position-absolute translate-middle-y top-50 end-0 me-3'>*/}
      {/*                <KTSVG*/}
      {/*                  path='/media/icons/duotune/finance/fin002.svg'*/}
      {/*                  className='svg-icon svg-icon-2hx'*/}
      {/*                />*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div className='d-flex flex-stack mb-5'>*/}
      {/*          <div className='me-5'>*/}
      {/*            <label className='fs-6 fw-semibold form-label'>*/}
      {/*              Save Card for further billing?*/}
      {/*            </label>*/}
      {/*            <div className='fs-7 fw-semibold text-dark'>*/}
      {/*              If you need more info, please check budget planning*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <label className='form-check form-switch form-check-custom min-w-150px'>*/}
      {/*            <input*/}
      {/*              className='form-check-input'*/}
      {/*              type='checkbox'*/}
      {/*              {...formik.getFieldProps('save')}*/}
      {/*              id='flexSwitchChecked'*/}
      {/*              checked={formik.values.save}*/}
      {/*            />*/}
      {/*            <span className='form-check-label fw-semibold text-dark'>Save Card</span>*/}
      {/*          </label>*/}
      {/*        </div>*/}

      {/*        <div style={{display: 'flex', justifyContent: 'center'}}>*/}
      {/*          <button*/}
      {/*            type='button'*/}
      {/*            className='btn btn-light me-3'*/}
      {/*            onClick={() => {*/}
      {/*              handleCloseCard()*/}
      {/*              formik.setValues({*/}
      {/*                id: '',*/}
      {/*                name: '',*/}
      {/*                save: false,*/}
      {/*                tag: ['new'],*/}
      {/*                url: '/media/svg/card-logos/american-express.svg',*/}
      {/*                idCard: '',*/}
      {/*                card: '',*/}
      {/*                date: '',*/}
      {/*                year: '',*/}
      {/*                month: '',*/}
      {/*                cvv: '',*/}
      {/*              })*/}
      {/*              setInitialValues({*/}
      {/*                id: '',*/}
      {/*                name: '',*/}
      {/*                tag: ['new'],*/}
      {/*                save: false,*/}
      {/*                url: '/media/svg/card-logos/american-express.svg',*/}
      {/*                idCard: '',*/}
      {/*                card: '',*/}
      {/*                date: '',*/}
      {/*                year: '',*/}
      {/*                month: '',*/}
      {/*                cvv: '',*/}
      {/*              })*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Discard*/}
      {/*          </button>*/}
      {/*          <button type='submit' className='btn btn-primary'>*/}
      {/*            Submit*/}
      {/*          </button>*/}
      {/*        </div>*/}
      {/*      </form>*/}
      {/*    </div>*/}
      {/*  </Modal>*/}
      {/*</div>*/}
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <button
          className='btn btn-secondary me-3'
          onClick={() => {
            navigate(-1)
          }}
        >
          戻る
        </button>
        <button
          className='btn btn-primary'
          onClick={() => {
            navigate('/plan/confirm', {state: {chooseASetMeal,card}})
          }}
        >
          最終確認画面へ進む
        </button>
      </div>
    </div>
  )
}
