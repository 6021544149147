import React, {useEffect, useState} from 'react'
import DataTransferSetting from './DataTransferSetting'
import DataTransferList from './DataTransferList'
import DataTransferHistory from './DataTransferHistory'

export default function DataTransferIndex() {
  const [activeTab, setActiveTab] = useState('setting')

  const handleTabChange = (event: any, tab: string) => {
    event.preventDefault()
    setActiveTab(tab)
  }

  return (
    <>
      <div className='mb-6'>
        <div className='card'>
          <div className='card-body'>
            <ul className='nav nab-tabs nav-line-tabs fs-6'>
              <li className='nav-item'>
                <a
                  href='#'
                  className={`nav-link ${activeTab === 'list' && 'active'}`}
                  data-bs-toggle='datatransfer-tab'
                  onClick={(event) => {
                    handleTabChange(event, 'list')
                  }}
                >
                  設定一覧
                </a>
              </li>
              <li className='nav-item'>
                <a
                  href='#'
                  className={`nav-link ${activeTab === 'setting' && 'active'}`}
                  data-bs-toggle='datatransfer-tab'
                  onClick={(event) => {
                    handleTabChange(event, 'setting')
                  }}
                >
                  新規設定登録
                </a>
              </li>
              <li className='nav-item'>
                <a
                  href='#'
                  className={`nav-link ${activeTab === 'history' && 'active'}`}
                  data-bs-toggle='datatransfer-tab'
                  onClick={(event) => {
                    handleTabChange(event, 'history')
                  }}
                >
                  実行履歴
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>{activeTab === 'setting' && <DataTransferSetting />}</div>
      <div>{activeTab === 'list' && <DataTransferList />}</div>
      <div>{activeTab === 'history' && <DataTransferHistory />}</div>
    </>
  )
}
