import {getServer, postServer} from 'src/service/http'

export function paymentProviderSettingGet(param: object) {
  return getServer(`/payment-provider-setting-get`, {...param})
}

export function paymentProviderSettingUpdate(param: object, headers: object = {}) {
  return postServer('/payment-provider-setting-update', {...param}, {...headers})
}

export function paymentProviderSuppliersList(param: object, headers: object = {}) {
  return postServer('/payment-provider-suppliers-list', {...param}, {...headers})
}

export function paymentTransaction(param: object, headers: object = {}) {
  return postServer('/payment-transaction', {...param}, {...headers})
}

export function paymentRefund(param: object) {
  return postServer('/payment-refund', {...param})
}
