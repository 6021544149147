import React from 'react';

interface FormRowProps {
  label: string;
  required?: boolean;
  meta?: {
    touched: boolean;
    error?: string;
  };
  children: React.ReactNode;
}

const FormRow: React.FC<FormRowProps> = ({ label, required, meta, children }) => {
  return (
    <div className='row mb-6'>
      <label className='col-lg-2 col-form-label fs-7'>
        <span className={required ? 'required' : ''}>{label}</span>
      </label>
      <div className='col-lg-10 align-self-center fs-7'>
        {children}
      </div>
    </div>
  );
};

export default FormRow;
