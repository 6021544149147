import React, {useEffect, useState, useRef} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  showErrorMessage,
  showWarningMessage,
  showSuccessMessage,
} from 'src/app/components/sweetAlert'
import UploadFileCompont from 'src/app/components/uploadFile'
import {Loading} from 'src/app/components/loading'
import {Pagination} from 'src/app/components/pagination'
import {CustomFieldActionsCell} from './table/endPointActionsCell'
import {
  productCategoryListGet,
  productCategoryInfoGet,
  productCategoryAdd,
  productCategoryUpdate,
} from 'src/app/modules/api'
import {getCommonDetails} from 'src/app/modules/api'
import {useAuth} from 'src/app/modules/auth'
import Header from './Header'

const Options1: SubscribeType[] = [
  {id: 'enabled', name: '有効'},
  {id: 'disabled', name: '無効'},
]
const options = [
  {value: '0', label: 'なし'},
  {value: '1', label: '海鮮品'},
  {value: '2', label: '野菜'},
  {value: '3', label: '鶏肉'},
  {value: '4', label: 'チーズ・乳製品'},
  {value: '5', label: '乾物'},
]
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('商品カテゴリ名は必須です。')
    .min(1, '最小8文字')
    .max(255, '最大16文字'),
  //.matches(/^[a-zA-Z0-9/]+$/, '半角英数字のみで入力してください。'),
  id: Yup.string().required('識別文字列は必須です。'),
  // .matches(/^[0-9]+$/, '半角数字のみで入力してください。'),
  // status: Yup.string().required('状態は必須です。'),
  // parent: Yup.string().required('親カテゴリは必須です。'),
  // headerimage: Yup.string().required('ヘッダ画像パスは必須です。'),
  // thumbnail: Yup.string().required('サムネイル画像パスは必須です。'),
  // description: Yup.string().required('カテゴリ説明は必須です。'),
  // meta_title: Yup.string().required('titleは必須です。'),
  // meta_decsription: Yup.string().required('descriptionは必須です。'),
  order: Yup.string()
    // .required('表示優先度は必須です。')
    .matches(/^[0-9]+$/, '半角数字のみで入力してください。'),
})

export default function ProductCategory() {
  const headerimageDom: any = useRef('null')
  const thumbnailDom: any = useRef('null')
  const [datas, setDatas] = useState<any>([]) // 数据列表
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
  const [selectedList, setSelectList] = useState<any>([]) // 选中的项
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    pageSize: 10,
    total: 0,
  }) // 分页参数
  const {auth} = useAuth()
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }
  let [id, setId] = useState<any>(null) // 索引
  const [formData, setFormData] = useState<any>({
    status: 'enabled',
    name: '',
    id: '',
    parent: '',
    headerimage: '',
    thumbnail: '',
    description: '',
    meta_title: '',
    meta_decsription: '',
    order: '',
  }) // 表单数据

  const updateData = (fieldsToUpdate: Partial<any>) => {
    const updatedData = {...formik.values, ...fieldsToUpdate}
    formik.setValues(updatedData)
  }

  useEffect(() => {
    initData()
  }, [])

  // 获取列表数据
  const initData = () => {
    headerimageDom.current && headerimageDom.current.init()
    thumbnailDom.current && thumbnailDom.current.init()
    reset()
    getData(queryParams.page, queryParams.pageSize)
  }

  // 获取列表数据
  const getData = async (pageNum: any, pageSize: any) => {
    // console.log('pageNum', pageNum, pageSize)
    setIsLoading(true)
    try {
      queryParams.page = pageNum
      queryParams.pageSize = pageSize
      const res: any = await productCategoryListGet({pageNum, pageSize})
      console.log('res', res)
      setQueryParams({...queryParams, total: res.total})
      setDatas(res.rows)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('error', error)
    }
  }

  // 改变选中列表
  const changeSelectList = (obj: any) => {
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = []
        datas.forEach((item: any) => {
          arr.push(item.id)
        })
        setSelectList(arr)
      } else {
        selectedList.push(obj.id)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([])
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id
        })
        selectedList.splice(idx, 1)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    }
  }

  // 重置
  const reset = () => {
    setId(null)
    updateData({
      status: 'enabled',
      name: '',
      id: '',
      parent: '',
      headerimage: '',
      thumbnail: '',
      description: '',
      meta_title: '',
      meta_decsription: '',
      order: '',
    })
  }

  const getInfo = async (item: any) => {
    let res: any = await getCommonDetails({
      pkVal: item.pk,
    })
    console.log('getCommonDetails', res)
    setIsLoading(false)
    formik.setValues({
      pkVal: res.pk,
      status: res.status,
      name: res.name,
      id: res.id,
      parent: res.parent,
      headerimage: res.headerimage,
      thumbnail: res.thumbnail,
      description: res.description,
      meta_title: res.meta_title,
      meta_decsription: res.meta_decsription,
      order: res.order,
    })
    headerimageDom.current && headerimageDom.current.init(res.headerimage)
    thumbnailDom.current && thumbnailDom.current.init(res.thumbnail)
  }

  const formik: any = useFormik<any>({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('onSubmit', JSON.stringify(values))
      setSubmitting(true)
      if (id) {
        productCategoryUpdate(values, {headers})
          .then((res: any) => {
            initData()
            showSuccessMessage(res.message)
          })
          .catch((err: any) => {
            console.log('err', err)
          })
      } else {
        productCategoryAdd(values, {headers})
          .then((res: any) => {
            initData()
            showSuccessMessage(res.message)
          })
          .catch((err: any) => {
            console.log('err', err)
          })
      }
    },
  })

  return (
    <>
      <Header />
      <div className='card mb-5 mb-xl-6 shadow-sm' id='kt_profile_details_view'>
        <form className='card-body mx-20' onSubmit={formik.handleSubmit} noValidate>
          {/* 状態 */}
          <div className='row my-6'>
            <label className='col-lg-2 col-form-label  fs-6'>
              <span className=''>状態</span>
            </label>

            <div className='col-lg-9 align-self-center'>
              {Options1.map((item2: any) => {
                return (
                  <div className='form-check form-check-inline pe-3' key={item2.id}>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='status'
                      value={item2.id}
                      checked={formik.values.status === item2.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          formik.values.status = item2.id
                          updateData(formik.values)
                        }
                      }}
                    />
                    <label className='form-check-label'>{item2.name}</label>
                  </div>
                )
              })}
            </div>
          </div>
          {/* 商品カテゴリ名 */}
          <div className='row my-6'>
            <label className='col-lg-2 col-form-label  fs-6'>
              <span className='required'>商品カテゴリ名</span>
            </label>

            <div className='col-9 align-self-center'>
              <input
                className='form-control'
                type='text'
                placeholder='商品カテゴリ名'
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>

          {/* 識別文字列 */}
          <div className='row my-6'>
            <label className='col-lg-2 col-form-label  fs-6'>
              <span className='required'>識別文字列</span>
            </label>

            <div className='col-lg-9 align-self-center'>
              <input
                className='form-control'
                type='text'
                placeholder='識別文字列'
                {...formik.getFieldProps('id')}
              />
              {formik.touched.id && formik.errors.id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.id}</div>
                </div>
              )}
            </div>
          </div>
          {/* 親カテゴリ */}
          <div className='row my-6'>
            <label className='col-lg-2 col-form-label  fs-6'>
              <span className='required'>親カテゴリ</span>
            </label>

            <div className='col-lg-9 align-self-center'>
              <select
                className='form-select'
                data-control='select2'
                {...formik.getFieldProps('parent')}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {formik.touched.parent && formik.errors.parent && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.parent}</div>
                </div>
              )}
            </div>
          </div>
          {/* ヘッダ画像パス */}
          <div className='row my-6'>
            <label className='col-lg-2 col-form-label  fs-6'>
              <span className=''>ヘッダ画像パス</span>
            </label>

            <div className='col-lg-9 align-self-center'>
              <UploadFileCompont
                value={formik.values.headerimage}
                ref={headerimageDom}
                setIsLoading={(val: any) => {
                  console.log(val)
                  setIsLoading(val)
                }}
                // {...formik.getFieldProps('headerimage')}
                onChange={(e: any) => {
                  console.log('pk', e)
                  formik.values.headerimage = e
                }}
              />
            </div>
          </div>
          {/* サムネイル画像パス */}
          <div className='row my-6'>
            <label className='col-lg-2 col-form-label  fs-6'>
              <span className=''>サムネイル画像パス</span>
            </label>
            <div className='col-lg-9 align-self-center'>
              <UploadFileCompont
                value={formik.values.thumbnail}
                ref={thumbnailDom}
                setIsLoading={(val: any) => {
                  setIsLoading(val)
                }}
                // {...formik.getFieldProps('thumbnail')}
                rules={{}}
                onChange={(e: any) => {
                  console.log('pk', e)
                  formik.values.thumbnail = e
                }}
              />
            </div>
          </div>
          {/* カテゴリ説明 */}
          <div className='row my-6'>
            <label className='col-lg-2 col-form-label  fs-6'>
              <span className=''>カテゴリ説明</span>
            </label>

            <div className='col-lg-9 align-self-center'>
              <textarea
                className='form-control mb-8'
                rows={3}
                placeholder='カテゴリ説明'
                {...formik.getFieldProps('description')}
              ></textarea>
              {formik.touched.description && formik.errors.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.description}</div>
                </div>
              )}
            </div>
          </div>
          {/* title */}
          <div className='row my-6'>
            <label className='col-lg-2 col-form-label  fs-6'>
              <span className=''>title</span>
            </label>

            <div className='col-lg-9 align-self-center'>
              <input
                className='form-control'
                type='text'
                placeholder='title'
                {...formik.getFieldProps('meta_title')}
              />
              {formik.touched.meta_title && formik.errors.meta_title && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.meta_title}</div>
                </div>
              )}
            </div>
          </div>
          {/* description */}
          <div className='row my-6'>
            <label className='col-lg-2 col-form-label  fs-6'>
              <span className=''>description</span>
            </label>

            <div className='col-lg-9  align-self-center'>
              <input
                className='form-control'
                type='text'
                placeholder='description'
                {...formik.getFieldProps('meta_decsription')}
              />
              {formik.touched.meta_decsription && formik.errors.meta_decsription && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.meta_decsription}</div>
                </div>
              )}
            </div>
          </div>
          {/* 表示優先度 */}
          <div className='row my-6'>
            <label className='col-lg-2 col-form-label  fs-6'>
              <span className=''>表示優先度</span>
            </label>

            <div className='col-lg-9  align-self-center'>
              <input
                className='form-control'
                type='text'
                placeholder='表示優先度'
                {...formik.getFieldProps('order')}
              />
              {formik.touched.meta_decsription && formik.errors.order && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.order}</div>
                </div>
              )}
            </div>
          </div>
          {/* ボタン */}
          <div className='flex-wrap w-50 d-flex justify-content-between'>
            <button
              className='btn btn-primary'
              type='button'
              onClick={() => {
                reset()
              }}
            >
              戻る
            </button>
            <button type='submit' className='btn btn-primary'>
              {id ? '更新' : '登録'}
            </button>
          </div>
        </form>
      </div>

      <div className='card my-6 shadow-sm'>
        <div className='d-flex align-items-center card-header'>
          <span className='card-title fw-bold m-0'>商品カテゴリ一覧</span>
        </div>
        <div className='card-body'>
          <div className='table-responsive ' style={{margin: '0px 30px 0px 30px'}}>
            <table className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer min-w-1000px'>
              <thead>
                <tr className='text-start fs-6 text-uppercase gs-0'>
                  <th>
                    <div className='form-check form-check-sm form-check-custom me-3'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        data-kt-check-target='#kt_table_users .form-check-input'
                        checked={selectedList.length === datas.length && selectedList.length !== 0}
                        onChange={(e) => {
                          let obj = {
                            id: 'all',
                            value: e.target.checked,
                          }
                          changeSelectList(obj)
                        }}
                      />
                    </div>
                  </th>
                  <th>商品親カテゴリ名</th>
                  <th>商品サブカテゴリ名</th>
                  <th>状態</th>
                  <th>利用状況</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {datas.length > 0 ? (
                  datas.map((item: any, index: number) => {
                    return (
                      <tr key={item.pk}>
                        <td>
                          <div className='form-check form-check-sm form-check-custom me-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              data-kt-check-target='#kt_table_users .form-check-input'
                              checked={selectedList.includes(item.pk)}
                              onChange={(e) => {
                                let obj = {
                                  id: item.pk,
                                  value: e.target.checked,
                                }
                                changeSelectList(obj)
                              }}
                            />
                          </div>
                        </td>
                        <td>{options.find((option) => option.value === item.parent)?.label}</td>
                        <td>{item.name}</td>
                        <td>{item.status == 'enabled' ? '有効' : '無効'}</td>
                        <td>{item.count}</td>
                        <td style={{minWidth: 90}}>
                          <CustomFieldActionsCell
                            getAllList={initData}
                            item={item}
                            onEmit={(e: any) => {
                              setId(item.pk)
                              getInfo(item)
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {queryParams.total != 0 && (
              <Pagination
                currentPage={getData}
                page={queryParams.page}
                pageSize={queryParams.pageSize}
                total={queryParams.total}
              />
            )}
          </div>
        </div>
      </div>
      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          {/* <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>

        <div className='col-xl-6'>
          {/* <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>
      </div>

      <div className='row gy-10 gx-xl-10'>
        <div className='col-xl-6'>
          {/* <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>

        <div className='col-xl-6'>
          {/* <TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10' /> */}
        </div>
      </div>
      {isLoading && <Loading></Loading>}
    </>
  )
}
