import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '@/_metronic/helpers'
import * as Yup from 'yup'
import {useFormik, useFormikContext} from 'formik'
import {Modal} from 'react-bootstrap'
import {showSuccessMessage, showErrorMessage, showWarningMessage} from '@/app/components/sweetAlert'
// import { supplier } from 'src/app/modules/api'
import {getCreditInfo, creditInfoRegister, creditInfoDelete} from 'src/app/modules/api'
import {Spin} from 'antd'
import {useAuth} from 'src/app/modules/auth'
import {useAlert} from 'src/_metronic/layout/core'
import {co} from '@fullcalendar/core/internal-common'
interface FormType {
  id: string
  cardName: string
  tag: string[]
  save: boolean
  url: string
  cardNo: string
  card: string
  date: string
  year: string
  month: string
  cvv: string
}

const validationSchema = Yup.object({
  cardName: Yup.string().required('カード名義は必須です。'),
  cardNo: Yup.string().required('カード番号は必須です。'),
  year: Yup.string().required('有効期限は必須です。'),
  month: Yup.string().required('有効期限は必須です。'),
  cvv: Yup.string().required('セキュリティコードは必須です。'),
})

export default function DisplayCards(props: any) {
  const {auth} = useAuth()
  const {setMessage} = useAlert()
  // console.log('displayCards', props)
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [cardList, setCardList] = useState<any>()
  const [id, setId] = useState<any>('')
  const {values: supplierParams} = useFormikContext<any>()
  const [initialValues, setInitialValues] = useState<FormType>({
    id: '',
    cardName: '',
    tag: [''],
    save: false,
    url: '/media/svg/card-logos/american-express.svg',
    cardNo: '',
    card: '',
    date: '',
    year: '',
    month: '',
    cvv: '',
  })
  const [token, setToken] = useState<any>('')

  let monthArray = []
  for (let i = 1; i <= 12; i++) {
    monthArray.push(i)
  }

  const initData = async () => {
    // const res = await gettoken()
    // setToken(res)
    getCardList()
  }

  useEffect(() => {
    initData()
  }, [])

  const appendScripts = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const scriptUrls: any = {
        smbc: {
          sandbox: 'https://stg-static.smbc-gp.co.jp/ext/js/token.js',
          production: 'https://static.smbc-gp.co.jp/ext/js/token.js',
        },
        gmopg: {
          sandbox: 'https://stg.static.mul-pay.jp/ext/js/token.js',
          production: 'https://static.mul-pay.jp/ext/js/token.js',
        },
      }

      const scriptUrl = scriptUrls[supplierParams.provider][supplierParams.mode]
      const scriptId = `payment-script`

      // 既存のスクリプトをチェックして削除
      const existingScript = document.getElementById(scriptId)
      if (existingScript) {
        existingScript.remove()
      }

      const script = document.createElement('script')
      script.src = scriptUrl
      script.id = scriptId // 特定のIDを設定
      script.async = true

      script.onload = () => {
        resolve()
      }

      script.onerror = (e) => {
        reject(new Error(`Script load error for ${script.src}`))
      }

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    })
  }

  const gettoken = async (json: any) => {
    console.log('json', json)
    await appendScripts()
    return new Promise((reslove, reject) => {
      Multipayment.init(supplierParams.shopId)
      Multipayment.getToken(
        {
          cardno: json.cardNo,
          expire: json.expire,
          holdername: json.cardName,
          securitycode: json.cvv,
          tokennumber: 1,
        },
        (res: any) => {
          console.log('token', res)

          if (res.resultCode == '000') {
            reslove(res.tokenObject.token[0])
          }

          if (res.resultCode !== '000') {
            setMessage({
              type: 'error',
              message: 'カードの登録に失敗しました。改めてお試しください。',
            })
            reject("Can't get token")
          }
        }
      )
    })
  }

  // 获取银行卡列表
  const getCardList = async () => {
    console.log('getCardList.supplierParams', supplierParams)
    try {
      let paramType = ''
      if (supplierParams.provider == 'smbc') {
        paramType = '0'
      }
      if (supplierParams.provider == 'gmopg') {
        paramType = '1'
      }
      const response: any = await getCreditInfo({
        // memberID: auth.group_id,
        // type: props.type == paramType
        type: paramType,
        id: supplierParams.id,
      })
      // console.log('response', response)
      if (response.code === 200) {
        for (let i = 0; i < response.data.length; i++) {
          console.log(response.data[i])
          response.data[i].year = '20' + response.data[i].expire.slice(0, 2)
          response.data[i].month = response.data[i].expire.slice(2)
          response.data[i].url = '/media/svg/card-logos/visa.svg'
          response.data[i].holderName = response.data[i].holderName
            ? response.data[i].holderName
            : ''
          response.data[i].cvv = response.data[i].cvv ? response.data[i].cvv : ''
        }
        //   console.log('props.getCardData', props.getCardData)
        if (response.data && response.data.length) {
          if (props.getCardData) {
            props.getCardData(response.data[0])
          }
        }
        let newList = JSON.parse(JSON.stringify(response.data))
        //   console.log(response)
        setCardList(newList)
      } else {
        showErrorMessage(`HTTP error! Status: ${response.code}`)
        throw new Error(`HTTP error! Status: ${response.code}`)
      }
    } catch (error) {
      setCardList([])
    }
  }

  const addCard = async (json: any) => {
    let token1 = await gettoken(json)
    console.log('token1', token1)
    try {
      let url = ''
      let paramType = ''
      let id = ''
      if (supplierParams.provider == 'smbc') {
        url = '/credit-info-add'
        paramType = '0'
      }
      if (supplierParams.provider == 'gmopg') {
        url = '/credit-info-register'
        paramType = '1'
      }
      // let url = props.type == 'SMBC' ? '/credit-info-add' : '/credit-info-register';
      const response: any = await creditInfoRegister(url, {
        // memberID: auth.group_id,
        cardName: json.cardName,
        type: paramType,
        // authorizationToken: auth.token,
        token: token1,
        id: supplierParams.id,
      })

      if (response.code == 200) {
        showSuccessMessage(response.message)
        handleClose()
        getCardList()
      } else {
        handleClose()
        showErrorMessage(`HTTP error! Status: ${response.code}`)
        throw new Error(`HTTP error! Status: ${response.code}`)
      }
    } catch (error) {
      handleClose()
      console.error('There was a problem with the fetch operation:', error)
    }
  }

  const deleteCard = (item: any, e: any) => {
    e.stopPropagation()
    console.log('supplierParams', supplierParams)
    showWarningMessage(
      'このカード情報を削除してよろしいですか？',
      async () => {
        creditInfoDelete({
          id: supplierParams.id,
          cardSeq: item.cardSeq,
          type: props.type == 'SMBC' ? '1' : '0',
        })
          .then(async (result: any) => {
            await getCardList()
            showSuccessMessage('選択したカード情報の削除が完了しました。')
          })
          .catch((err: any) => {})
      },
      '削除',
      'キャンセル'
    )
  }

  const handleClose = () => {
    setShowModal(false)
    formik.resetForm()
  }
  const handleShow = () => {
    setShowModal(true)
  }
  const [falgIndex, setFalgIndex] = useState<any>(0)
  const handCord = (index: any, item: any, e: any) => {
    setFalgIndex(index)
    if (props.getCardData) {
      props.getCardData(item)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      // console.log(values)
      setSubmitting(true)
      // console.log(formik)
      try {
        if (id) {
          let idx = cardList.findIndex((item: any) => {
            return item.id === id
          })
          cardList[idx] = values
          let arr = JSON.parse(JSON.stringify(cardList))
          setCardList(arr)
          showSuccessMessage('更新しました')
          handleClose()
        } else {
          let params = {
            ...values,
            memberID: auth.group_id,
            expire: '',
          }
          let month = params.month.length === 1 ? `0${params.month}` : params.month
          params.expire = params.year.slice(2) + month
          const resAddCard: any = await addCard(params)
          // console.log(values)
          // console.log(cardList)

          // エラーオブジェクトじゃなかったら
          if (resAddCard instanceof Error) {
            showErrorMessage('エラーが発生しました')
          } else {
            getCardList()
            handleClose()
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  useEffect(() => {}, [])

  return (
    <>
      <div className='row gx-9 gy-6'>
        {cardList?.length !== 0 &&
          cardList?.map((item: any, index: number) => {
            return (
              <div className='col-xl-6' data-kt-billing-element='card' key={index}>
                <div
                  onClick={(e) => handCord(index, item, e)}
                  className={
                    index == falgIndex
                      ? 'card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6 bg-light-primary'
                      : 'card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'
                  }
                >
                  <div className='d-flex flex-column py-2  '>
                    {/* <div className='d-flex align-items-center fs-4 fw-bold mb-5'>
                      {item.name}
                      {item.tag?.length !== 0 &&
                        item.tag?.map((i: any, idx: number) => {
                          return (
                            <span className='badge badge-light-success fs-7 ms-2' key={idx}>
                              {i}
                            </span>
                          )
                        })}
                    </div> */}
                    <div className='d-flex align-items-center'>
                      {/* <img src={toAbsoluteUrl(item.url)} alt='' className='h-50px me-3' /> */}
                      <div>
                        <span className='fs-4 fw-bold'>{item.holderName}</span>
                        <span className='fs-4 fw-bold me-4'>({item.cardNo})</span>
                        <span className='fs-6 fw-semibold '>
                          有効期限: {item.card} {item.year}/
                          {item.month.length === 1 ? `0${item.month}` : item.month}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center py-2'>
                    {props?.isEdit == 1 && (
                      <button
                        className='btn btn-sm btn-light btn-active-light-primary me-3'
                        onClick={(e) => {
                          deleteCard(item, e)
                        }}
                      >
                        <span className='indicator-label'>削除</span>
                        <span className='indicator-progress'>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )
          })}

        {/* 添加银行卡 */}
        <div className='col-xl-6'>
          <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6'>
            <div className='d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap'>
              <div className='mb-3 mb-md-0 fw-semibold'>
                <h4 className='fw-bold'>お支払い方法を追加</h4>
                <div className='fs-8 pe-7'>
                  お支払いに利用するカードを追加できます。
                  <br />
                  カード情報は解読不能なランダムな数字等に置き換えられ、システム上では保持されません。
                </div>
              </div>

              <button
                className='btn btn-primary px-6 align-self-center text-nowrap'
                onClick={() => {
                  if (props.isEdit) {
                    handleShow()
                    setId('')
                    formik.setValues({
                      id: '',
                      cardName: '',
                      save: false,
                      tag: ['new'],
                      url: '/media/svg/card-logos/american-express.svg',
                      cardNo: '',
                      card: '',
                      date: '',
                      year: '',
                      month: '',
                      cvv: '',
                    })
                    setInitialValues({
                      id: '',
                      cardName: '',
                      tag: ['new'],
                      save: false,
                      url: '/media/svg/card-logos/american-express.svg',
                      cardNo: '',
                      card: '',
                      date: '',
                      year: '',
                      month: '',
                      cvv: '',
                    })
                  } else {
                    if (props.type == 'SMBC') {
                      navigate('/account/paymentPurchase')
                    } else if (props.type == 'GMOPG') {
                      if (auth.role.includes('buyer')) {
                        navigate('/account/paymentUsage')
                      } else {
                        navigate('/account/payment')
                      }
                    }
                  }
                }}
              >
                追加
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose} dialogClassName='modal-dialog-centered mw-650px'>
        <div className='modal-header'>
          <h5 className='modal-title'>新規クレジットカード追加</h5>
          <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={handleClose}>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
          <form
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
            onSubmit={formik.handleSubmit}
          >
            {/* begin card_name */}
            <div className='d-flex flex-column mb-3 fv-row fv-plugins-icon-container'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>カード名義</span>
                <i
                  className='fas fa-exclamation-circle ms-1 fs-7'
                  style={{color: '#a1a5b796'}}
                  data-bs-toggle='tooltip'
                  title='カード名義'
                ></i>
              </label>
              <input
                className='form-control'
                placeholder='カード名義'
                {...formik.getFieldProps('cardName')}
                value={formik.values.cardName}
              />
              {formik.touched.cardName && formik.errors.cardName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.cardName}</div>
                </div>
              )}
              <br />
            </div>
            <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
              <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                <span className='required'>カード番号</span>
              </label>
              <div className='position-relative'>
                <input
                  className='form-control'
                  placeholder='カード番号'
                  {...formik.getFieldProps('cardNo')}
                  value={formik.values.cardNo}
                />
                {formik.touched.cardNo && formik.errors.cardNo && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.cardNo}</div>
                  </div>
                )}
                <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/visa.svg')}
                    alt=''
                    className='h-25px'
                  />
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/mastercard.svg')}
                    alt=''
                    className='h-25px'
                  />
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/american-express.svg')}
                    alt=''
                    className='h-25px'
                  />
                </div>
              </div>
            </div>
            {/* end card_number */}
            <div className='row mb-10'>
              <div className='col-md-8 fv-row'>
                <label className='required fs-6 fw-semibold form-label mb-2'>有効期限</label>
                <div className='row fv-row fv-plugins-icon-container'>
                  <div className='col-6'>
                    <select
                      className='form-select select2-hidden-accessible'
                      placeholder='Month'
                      {...formik.getFieldProps('month')}
                      value={formik.values.month}
                    >
                      <option data-select2-id='select2-data-21-a82n'></option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                      <option value='11'>11</option>
                      <option value='12'>12</option>
                    </select>
                    {formik.touched.month && formik.errors.month && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.month}</div>
                      </div>
                    )}
                  </div>
                  <div className='col-6'>
                    <select
                      className='form-select select2-hidden-accessible'
                      placeholder=''
                      {...formik.getFieldProps('year')}
                      value={formik.values.year}
                    >
                      <option value='' disabled hidden></option>
                      {Array.from({length: 11}, (_, index) => {
                        const year = new Date().getFullYear() + index
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.year && formik.errors.year && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.year}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-md-4 fv-row fv-plugins-icon-container'>
                <label className='d-flex align-items-center fs-6 fw-semibold form-label mb-2'>
                  <span className='required'>CVV</span>
                  <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    style={{color: '#a1a5b796'}}
                    data-bs-toggle='tooltip'
                    title='CVV'
                  ></i>
                </label>
                <div className='position-relative'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='CVV'
                    {...formik.getFieldProps('cvv')}
                    value={formik.values.cvv}
                  />
                  {formik.touched.cvv && formik.errors.cvv && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.cvv}</div>
                    </div>
                  )}
                  <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                    <KTSVG
                      path='/media/icons/duotune/finance/fin002.svg'
                      className='svg-icon svg-icon-2hx'
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'center'}}>
              <button
                type='button'
                className='btn btn-light me-3'
                onClick={() => {
                  handleClose()
                  formik.setValues({
                    id: '',
                    cardName: '',
                    save: false,
                    tag: ['new'],
                    url: '/media/svg/card-logos/american-express.svg',
                    cardNo: '',
                    card: '',
                    date: '',
                    year: '',
                    month: '',
                    cvv: '',
                  })
                  setInitialValues({
                    id: '',
                    cardName: '',
                    tag: ['new'],
                    save: false,
                    url: '/media/svg/card-logos/american-express.svg',
                    cardNo: '',
                    card: '',
                    date: '',
                    year: '',
                    month: '',
                    cvv: '',
                  })
                }}
              >
                キャンセル
              </button>
              <Spin spinning={loading}>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting || loading}
                >
                  登録
                </button>
              </Spin>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}
