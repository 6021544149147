import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '@/_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from '@/app/components/sweetAlert'
import {InfoList} from './components/InfoList'
// import {BankCard} from './components/BankCard'
import BankCardRegister from '@/app/components/bankCard/bankCardRegister';
import {userSubscriptionInfoGet} from 'src/app/modules/api'
import {useAuth} from 'src/app/modules/auth'
import {Spin} from 'antd'
import moment from 'moment'
const statusObj: any = {
  actived: 'アクティブ ',
  pause: '一時停止',
  deactived: '終了',
}

export default function UserCardManagement() {
  const navigate = useNavigate()
  const {auth} = useAuth()
  const [loading, setLoading] = useState<any>(false)
  const [userdata, setUserdata] = useState<any>({
    // planName: 'ライトプラン',
    // nowDatetime: '2023/11/1～2023/11/30',
    // tukiDatetime: '2023/11/1～2023/11/30',
    // kigaku: '￥14.080',
    // datetime: '2023/10/13',
  }) // 表单数据
  let headers = {
    "Content-Type":"application/json",
    Authorization:`Bearer ${auth.token}`
  }


  useEffect(() => {
    initData()
  }, [])

  // 获取列表数据
  const initData = async () => {
    setLoading(true)
    let res = await userSubscriptionInfoGet({},headers)
    
    if (res) {
      setUserdata(res)
      setLoading(false)
    }else{
        setLoading(false)
    }
  }

  return (
    <Spin spinning={loading} tip=' ' size='large'>
      <div className='container-fluid'>
        <div className='card mb-6 shadow-sm'>
          <div className='d-flex align-items-center card-header'>
            <span className='card-title fw-bold m-0'>ご契約中のプラン</span>
          </div>
          <div className='card-body'>
            <div
              className='row'
              style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            >
              <div
                className='col'
                style={{display: 'flex', flexDirection: 'column', alignContent: 'right'}}
              >
                <div className='container'>
                  <dl className='row'>
                    <dt className='col-sm-3 fs-4 fw-bold mb-5'>プラン名</dt>
                    <dd className='col-sm-9 fs-6 mb-5'>{userdata.planName || 'フリープラン'}</dd>

                    <dt className='col-sm-3 fs-4 fw-bold mb-5'>現在の契約期間</dt>
                    <dd className='col-sm-9 fs-6 mb-5'>{moment(userdata.start_date).format('YYYY-MM-DD')}~{moment(userdata.end_date).format('YYYY-MM-DD')}</dd>

                    <dt className='col-sm-3 fs-4 fw-bold mb-5'>次の契約期間</dt>
                    <dd className='col-sm-9 fs-6 mb-5'>{moment(userdata.next_payment_date).format('YYYY-MM-DD')}</dd>

                    <dt className='col-sm-3 fs-4 fw-bold mb-5'>ご請求金額</dt>
                    <dd className='col-sm-9 fs-6 mb-5'>{userdata.requestPrice}</dd>

                    <dt className='col-sm-3 fs-4 fw-bold mb-5'>ご請求金額確定日</dt>
                    <dd className='col-sm-9 fs-6 mb-5'>{moment(userdata.last_payment_date).format('YYYY-MM-DD')}</dd>
                  </dl>
                </div>

                <div className='d-flex flex-wrap  mb-3 mt-5' style={{gap: "20px"}}>
                  <button
                    className='btn btn-icon btn-primary btn-sm'
                    style={{width: 120}}
                    type='button'
                    onClick={() => {
                      navigate('/plan', {
                        state: {
                          pk: '1',
                        },
                      })
                    }}
                  >
                    契約内容の変更
                  </button>

                  <button
                    className='btn btn-icon btn-primary btn-sm'
                    style={{width: 120}}
                    type='button'
                    onClick={() => {
                      navigate('/contact', {
                        state: {
                          pk: '1',
                        },
                      })
                    }}
                  >
                    解約する
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <BankCard headTitle={'利用料のお支払方法'} closeRightTitle /> */}
        <BankCardRegister title={'利用料のお支払方法'} type={'GMOPG'} />
        <InfoList />
      </div>
    </Spin>
  )
}
