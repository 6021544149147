import {useState} from 'react'
import {useAuth} from '../core/Auth'
import {getUserByToken, login, getRoleGroupList } from '../core/_requests'
import {useLocation} from 'react-router-dom'
import {RoleGroupFilter} from "./RoleGroupFilter";

export function SuccessfulAuthentication() {
  const {auth, saveAuth, setCurrentUser} = useAuth()
  const [telLoading, setTelLoading] = useState(false)
  const location = useLocation()
  async function goToDashboard(): Promise<void> {
    setTelLoading(true)
    try {
      console.log(location.state)
      // flag 为true表示登录跳转 ,values为密码
      const {user, username, values, flag} = location.state
      // user为登录成功后返回信息
      if (user && flag) {
        setCurrentUser(user)
      } else {
        const resp = await login({username, password: values,isProfileLogin: 0}) as any
        // console.log("login", resp);
        const list = await getRoleGroupList({page: 1, items_per_page: 9999}) as any;
        const role = resp?.data?.role_group && JSON.parse(resp.data.role_group);
        saveAuth({
          api_token: resp.data.token,
          tf_auth_enable: true,
          data: resp.data,
          role: RoleGroupFilter(role, list)
        })
        const user = {
          id: 0,
          username: resp.data.login_id,
          password: resp.data.password,
          email: resp.data.email,
          first_name: '',
          last_name: '',
        }
        setCurrentUser(user)
      }
      // const {data: auth} = await login(username, values)
      // setCurrentUser(auth)
      // // const {data: user} = await getUserByToken(auth.api_token)

      setTelLoading(false)
    } catch (error) {
      setTelLoading(false)
      saveAuth(undefined)
      console.error(error)
    }
  }

  return (
    <div className='succ'>
      <div className='d-flex flex-column flex-center text-center p-10 succdiv'>
        <div className='card card-flush w-md-650px py-5'>
          <div className='card-body py-15 py-lg-20'>
            <div className='mb-7'>
              <a href='#' className=''>
                <img
                  alt='Logo'
                  src={
                    '/media/logos/custom-2.svg'
                  }
                  className='h-40px'
                />
              </a>
            </div>

            <h1 className='fw-bolder mb-5'>ようこそ！</h1>

            <div className='fw-semibold fs-6 mb-7'>
              これは、創造性を発揮し、読者にアイデアを与える名前を付け
              <br />
              る機会です
            </div>

            <div className='mb-0'>
              <img
                src={
                  '/media/auth/welcome.png'
                }
                alt=''
                className='mw-100 mh-300px theme-light-show'
              />
            </div>

            <div className='mb-0'>
              <div className='btn btn-sm btn-green' onClick={() => goToDashboard()}>
                {!telLoading && <span className='indicator-label'> ダッシュボードへ</span>}
                {telLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    お待ちください...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
