import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '@/_metronic/layout/core'
import {AccountUserInfo} from 'src/app/pages/common/account/AccountUserInfo'
import {AccountInfo} from '@/app/pages/common/account/AccountInfo'
import {AccountInfoSetting} from '@/app/pages/common/account/AccountInfoSetting'

import {CustomerInfo} from './components/CustomerInfo'
import {CustomerInfoSettings} from './components/settings/Settings'
import {DeliveryInfo} from './components/settings/DeliveryInfo'
import PaymentManagement from './components/settings/PaymentManagement'
import {PaymentLogin} from './components/settings/PaymentLogin'
import {Report} from './components/settings/Report'
import {PaymentLoginSet} from './components/settings/PaymentLoginSet'
// import Header from './Header'
import Header from '@/app/pages/common/account/Header'
import {HelmetComponent} from 'src/app/modules/helmet'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'ホーム',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

// const auth = getAuth();
// async function getUser() {
//   const user = await accountById(auth?.data.login_id) as any;
//   return user.data
// }

export default function BuyerAccount() {
  return (
    <Routes>
      <Route>
        <Route
          path='/buyer-account'
          element={
            <>
              <Header type='buyer' />
              <HelmetComponent title='アカウント情報表示' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>アカウント情報表示</PageTitle>
              <AccountUserInfo type='buyer' />
            </>
          }
        />
        <Route
          path='/buyer-account/edit'
          element={
            <>
              <Header type='buyer' />
              <HelmetComponent title='アカウント情報設定' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>アカウント情報設定</PageTitle>
              <AccountUserInfo type='buyer' />
            </>
          }
        />
        <Route
          path='/buyer-organization'
          element={
            <>
              <Header type='buyer' />
              <HelmetComponent title='組織情報' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>組織情報表示</PageTitle>
              <AccountInfo type='buyer' />
            </>
          }
        />
        <Route
          path='/buyer-organization/edit'
          element={
            <>
              <Header type='buyer' />
              <HelmetComponent title='組織情報設定' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>組織情報設定</PageTitle>
              <AccountInfoSetting />
            </>
          }
        />
        <Route
          path='/buyer-shipping'
          element={
            <>
              <Header type='buyer' />
              <HelmetComponent title='配送先情報' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>配送先情報</PageTitle>
              <DeliveryInfo />
            </>
          }
        />
        <Route
          path='/buyer-report'
          element={
            <>
              <Header type='buyer' />
              <HelmetComponent title='レポート' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>レポート</PageTitle>
              <Report />
            </>
          }
        />
        <Route
          path='/buyer-paymentPurchase'
          element={
            <>
              <Header type='buyer' />
              <HelmetComponent title='仕入のお支払管理' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>仕入のお支払管理</PageTitle>
              <PaymentManagement />
            </>
          }
        />
        <Route
          path='/buyer-paymentUsage'
          element={
            <>
              <Header type='buyer' />
              <HelmetComponent title='利用料のお支払管理' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>利用料のお支払方法登録</PageTitle>
              <PaymentLogin />
            </>
          }
        />
        <Route
          path='/buyer-paymentLoginSet'
          element={
            <>
              <Header type='buyer' />
              <HelmetComponent title='その他共通設定' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>その他共通設定</PageTitle>
              <PaymentLoginSet />
            </>
          }
        />
        <Route index element={<Navigate to='/buyer-account' />} />
      </Route>
    </Routes>
  )
}
