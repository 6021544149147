import { useEffect, useState } from 'react'
import { KTCard, KTSVG } from '@/_metronic/helpers'
import { UsersListLoading } from './tabel/UsersListLoading'
import { useNavigate } from 'react-router-dom'
import { showSuccessMessage} from '@/app/components/sweetAlert';
import "react-datepicker/dist/react-datepicker.css"

const class1 = 'page-item previous'
const class2 = 'page-item previous active'
const class3 = 'page-item previous disabled'


function LoginHistory() {
    const [keyWords, setKeyWords] = useState<string>('') // 检索
    const [arr, setArr] = useState<any>([
        {
            customerId: '#123456',
            loginData: '2023-10-25 11:21:12',
            loginNm: '株式会社アップシェア',
            tatosha: '担当 太郎',
            rank: '特別会員',
            terminal: 'パソコン（Win)',
            browser: 'Chrome',
            finalLogin: '5分前'
        },
        {
            customerId: '#789101',
            loginData: '2023-10-26 11:21:12',
            loginNm: '株式会社●●商会',
            tatosha: '担当田 花子',
            rank: '特別会員',
            terminal: 'iPhone',
            browser: 'Safari',
            finalLogin: '40分前'
        },
        {
            customerId: '#789102',
            loginData: '2023-10-26 11:21:12',
            loginNm: '株式会社●●商会',
            tatosha: '担当田 花子',
            rank: '特別会員',
            terminal: 'iPhone',
            browser: 'Safari',
            finalLogin: '40分前'
        },
        {
            customerId: '#789103',
            loginData: '2023-10-26 11:21:12',
            loginNm: '株式会社●●商会',
            tatosha: '担当田 花子',
            rank: '特別会員',
            terminal: 'iPhone',
            browser: 'Safari',
            finalLogin: '40分前'
        },
        {
            customerId: '#789104',
            loginData: '2023-10-26 11:21:12',
            loginNm: '株式会社●●商会',
            tatosha: '担当田 花子',
            rank: '特別会員',
            terminal: 'iPhone',
            browser: 'Safari',
            finalLogin: '40分前'
        },
        {
            customerId: '#789105',
            loginData: '2023-10-26 11:21:12',
            loginNm: '株式会社●●商会',
            tatosha: '担当田 花子',
            rank: '特別会員',
            terminal: 'iPhone',
            browser: 'Safari',
            finalLogin: '40分前'
        }
    ]); //总数据
    const [data, setData] = useState<any>([]) // 数据列表
    const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
    const [selectedList, setSelectList] = useState<any>([]) // 选中的项
    const [nowPage, setNowPage] = useState<any>(1) // 当前页
    const [pageSize, setPageSize] = useState<any>(10) // 每页多少条
    let [page, setPage] = useState<any>([]) // 页码列表


    // 適用
    const apply = () => {

    }
    //处理页码
    const changePage = (sumPage: any, size?: number | string) => {
        page = []
        let num = Math.ceil(sumPage / Number(size ? size : pageSize))
        for (let i = 1; i < num + 1; i++) {
            page.push(i)
        }
        let newPage = JSON.parse(JSON.stringify([...page]))
        setPage(newPage)
    }
    //获取当前页码的列表
    const getPageList = async (page: any) => {
        setIsLoading(true);
        let newArr = arr.slice((page - 1) * pageSize, page * pageSize);
        setData(newArr);
        setNowPage(page);
        setIsLoading(false);
    }
    // 改变当前显示数量
    const changePageSize = (size: string | number) => {
        setPageSize(size);
        changePage(arr.length, size);
        let newArr = arr.slice((nowPage - 1) * Number(size), nowPage * Number(size));
        setData(newArr);
    }
    // 改变选中列表
    const changeSelectList = (obj: any) => {
        if (obj.value) {
            if (obj.id === 'all') {
                let arr: any = []
                data.forEach((item: any) => {
                    arr.push(item.id)
                });
                setSelectList(arr)
            } else {
                selectedList.push(obj.id)
                let newSelectedList = JSON.parse(JSON.stringify(selectedList))
                setSelectList(newSelectedList)
            }
        } else {
            if (obj.id === 'all') {
                setSelectList([])
            } else {
                let idx = selectedList.findIndex((item: any) => {
                    return item === obj.id
                })
                selectedList.splice(idx, 1)
                let newSelectedList = JSON.parse(JSON.stringify(selectedList))
                setSelectList(newSelectedList)
            }
        }
    }
    // 删除某项
    const deleteList = (id: any) => {
        let idx = data.findIndex((item: any) => {
            return item.id === id;
        })
        data.splice(idx, 1)
        let nowArr = JSON.parse(JSON.stringify(data))
        let idx1 = arr.findIndex((item: any) => {
            return item.id === id;
        })
        arr.splice(idx1, 1)
        let nowArr1 = JSON.parse(JSON.stringify(arr))
        showSuccessMessage('削除しました')
        setData(nowArr);
        setArr(nowArr1);
    }
    // 获取列表数据
    const getAllList = () => {
        changePage(arr.length);
        let newArr = arr.slice((nowPage - 1) * pageSize, nowPage * pageSize);
        setData(newArr);
    }

    useEffect(() => {
        getAllList()
    }, [])

    return (
        <div style={{ position: 'relative' }}>
            <KTCard resetSidePaddings={true}>
                <div className='card-header border-0' style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', height: 43 }}>
                        <span className="card-title fw-bold m-0">ログイン履歴一覧</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', height: 43 }}>
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTSVG
                                path='/media/icons/duotune/general/gen021.svg'
                                className='svg-icon-1 position-absolute ms-6'
                            />
                            <input
                                type='text'
                                data-kt-user-table-filter='search'
                                style={{ marginRight: 10 }}
                                className='form-control w-250px ps-14'
                                placeholder='検索'
                                value={keyWords}
                                onChange={(e) => setKeyWords(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="table-responsive " style={{ margin: '0px 30px 50px 30px ' }}>
                    <table className="table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer ">
                        <thead className='table-light'>
                            <tr className="text-start fs-6 text-uppercase gs-0">
                                <th id='customerId'>顧客ID</th>
                                <th id='loginData'>ログイン日時</th>
                                <th id='loginNm'>名称</th>
                                <th id='tatosha'>担当者</th>
                                <th id='rank'>顧客ランク</th>
                                <th id='terminal'>端末</th>
                                <th id='browser'>ブラウザ</th>
                                <th id='finalLogin'>最終ログイン</th>
                            </tr>
                        </thead>
                        <tbody className=''>
                            {
                                data.length > 0 ? data.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <tr key={item.customerId}>
                                                <td><a href='' style={{ textDecorationLine: 'underline' }}>{item.customerId}</a></td>
                                                <td>{item.loginData}</td>
                                                <td>{item.loginNm}</td>
                                                <td>{item.tatosha}</td>
                                                <td>{item.rank}</td>
                                                <td>{item.terminal}</td>
                                                <td>{item.browser}</td>
                                                <td>{item.finalLogin}</td>
                                            </tr>
                                        </>
                                    )
                                }) : (
                                    <tr>
                                        <td colSpan={17}>
                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
                    <select className="form-select " data-control="select2"
                        style={{ width: 80, position: 'absolute', left: 20, bottom: 10 }}
                        value={pageSize}
                        onChange={(e) => {
                            changePageSize(e.target.value);
                        }}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>

                    {/* page */}
                    <ul className="pagination" style={{ position: 'absolute', right: 20, bottom: 10 }}>
                        <li className={nowPage === page[0] ? class3 : class1}>
                            <a href="#" className="page-link" onClick={(e) => {
                                getPageList(nowPage - 1)
                                e.preventDefault()
                            }}>
                                <i className="previous"></i>
                            </a>
                        </li>
                        {
                            page.map((item: any, index: number) => {
                                return <li className={nowPage === item ? class2 : class1} key={index} >
                                    <a href="#" className="page-link" onClick={(e) => {
                                        getPageList(item)
                                        e.preventDefault()
                                    }}>
                                        {item}
                                    </a>
                                </li>
                            })
                        }
                        <li className={nowPage === page[page.length - 1] ? class3 : class1}>
                            <a href="#" className="page-link" onClick={(e) => {
                                getPageList(nowPage + 1)
                                e.preventDefault()
                            }}>
                                <i className="next"></i>
                            </a>
                        </li>
                    </ul>
                    {
                        isLoading && <UsersListLoading></UsersListLoading>
                    }
                </div>

            </KTCard>
        </div>
    )
}


export default LoginHistory;
