import React, {Fragment, useEffect, useRef, useState} from 'react'
import {useNavigate, useLocation, useParams, useSearchParams} from 'react-router-dom'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import {Modal} from 'react-bootstrap'
import {formatNumber, nl2br, useIsWidthSize} from 'src/app/modules/utils'
import {orderDataUpdate, paymentRefund, ledgerDataGet, parameterStoreGet} from 'src/app/modules/api'
import {Invoice} from 'src/app/modules/ledger/Invoice'
import {Shipping} from 'src/app/modules/ledger/Shipping'
import ProcessButton from 'src/app/components/part/ProcessButton'
import {Spin} from 'antd'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useAlert} from 'src/_metronic/layout/core'

// ▼利用者へ特別なお知らせを選択する
const quickAnswer = [
  {id: '0', name: '内容確認しました。'},
  {id: '1', name: 'メールお送りしましたので、ご確認ください。'},
  {id: '2', name: '担当よりご連絡致します。'},
]

moment.locale('ja')

const orderTitle: any = {
  buyer: '注文',
  supplier: '注文',
}

export const OrderLineItems: React.FC<any> = ({
  orderData,
  setOrderData,
  handleInit,
  role,
}: {
  orderData: any
  setOrderData: void
  handleInit: () => void
  role: string
}) => {
  const [caliculate, setCaliculate] = useState<any>({})
  const [dataReady, setDataReady] = useState<boolean>(false)
  const [isShow, setIsShow] = useState<boolean>(false)

  useEffect(() => {
    let result: any = {
      subtotal: 0,
      shipping_fee: 0,
      shippings: {},
      taxes: {}, //{ [name]: { rate: tax } }
      total: 0,
    }

    if (orderData.groupItem.length > 0) {
      // console.log('orderData.groupItem', orderData.groupItem)
      for (let [v, i] of orderData.groupItem.entries()) {
        const shipping_title = i.shippings_title ?? '配送グループ名なし'
        const name =
          orderData.orderShippings.length > 0
            ? orderData.orderShippings[0].shipping_group_name
            : '配送グループ名なし'
        if (!result.shippings[shipping_title]) {
          result.shippings[shipping_title] = {
            amount: 0,
            name: name,
          }
        }

        result.shippings[shipping_title].amount +=
          i.shippings_amount !== '' ? Number(i.shippings_amount) : 0
        result.shipping_fee += i.shippings_amount !== '' ? Number(i.shippings_amount) : 0

        for (let k of i.list) {
          result.subtotal += Number(k.total_amount)
          result.shipping_fee += Number(k.individual_shipping_fee)
          result.total += Number(k.total_amount)

          // let tax_price = (k.unit_price * k.quantity) / (1 + k.tax_rate)
          // let tax_price = k.sales_tax
          let tax_price = (result.subtotal * (k.tax_rate / 100)) / (1 + k.tax_rate / 100)
          let tax_setting_name = k.tax_rate + '％'

          // result[k.tax_setting]が存在しない場合、初期化
          if (!result.taxes[tax_setting_name]) {
            result.taxes[tax_setting_name] = {
              rate: Number(k.tax_rate),
              name: k.tax_rate + '％',
              type: 'included',
              amount: 0,
            }
          }

          result.taxes[tax_setting_name].amount += tax_price
        }
      }
    }

    if (orderData.orderShippings.length > 0) {
      for (let i of orderData.orderShippings) {
        const fee = Number(i.fee)
        const tax_rate = Number(i.tax_rate)
        const tax_setting_name = i.tax_rate + '％'
        // const tax_price = (fee * tax_rate) / (1 + tax_rate)
        result.shipping_fee += fee
        result.total += fee
        const tax_price = (result.shipping_fee * (tax_rate / 100)) / (1 + tax_rate / 100)

        // result[k.tax_setting]が存在しない場合、初期化
        if (!result.taxes[tax_setting_name]) {
          result.taxes[tax_setting_name] = {
            rate: tax_rate,
            name: i.tax_rate + '％',
            type: 'included',
            amount: 0,
          }
        }

        result.taxes[tax_setting_name].amount += tax_price
      }
    }

    setCaliculate(result)
    setDataReady(true)
    // console.log('result', result)
  }, [orderData])

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>{`${orderTitle[role]}`}内容</div>
          <div className='card-toolbar'>
            {role === 'supplier' && (
              <button
                type='button'
                className='btn btn-sm btn-white btn-outline'
                onClick={() => setIsShow(true)}
              >
                編集
              </button>
            )}
          </div>
        </div>
        <div className='card-body'>
          {dataReady && (
            <>
              {orderData?.groupItem.map((groupItem: any, groupIndex: number) => (
                <div key={`group-${groupIndex}`}>
                  {groupItem.list.map((item: any, itemIndex: number) => (
                    <div className='row border-bottom py-2' key={`item-${itemIndex}`}>
                      <div className='col'>
                        <span>
                          {item.product_name} {item.product_variation_name}
                        </span>
                        <br />
                        SKU: {item.sku}
                      </div>
                      <div className='col-lg-2 fw-semibold text-end'>
                        {formatNumber(item.unit_price)} x {formatNumber(item.quantity)}
                        {item.unit}
                      </div>
                      <div className='col-lg-2 fw-semibold text-end'>
                        {formatNumber(item.total_amount)}円
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              {orderData?.orderShippings.map((item: any, index: number) => (
                <div key={`orderShippings-${index}`}>
                  <div className='row border-bottom py-2'>
                    <div className='col'>
                      <span>（送料）{item.shipping_group_name}</span>
                    </div>
                    <div className='col-lg-2 fw-semibold text-end'>{formatNumber(item.fee)}円</div>
                  </div>
                </div>
              ))}
              <div className='row border-bottom py-2'>
                <div className='col text-end'>
                  <span>送料合計</span>
                </div>
                <div className='col-lg-2 fw-semibold text-end'>
                  {formatNumber(caliculate.shipping_fee)}円
                </div>
              </div>
              <div className='row border-bottom py-2'>
                <div className='col text-end'>
                  <span>商品合計</span>
                </div>
                <div className='col-lg-2 fw-semibold text-end'>
                  {formatNumber(caliculate.subtotal)}円
                </div>
              </div>
              {caliculate.taxes &&
                Object.values(caliculate.taxes).map((item: any, index: number) => {
                  return (
                    <div className='row border-bottom py-2' key={index}>
                      <div className='col text-end'>
                        <span>
                          {index == 0 && '軽減税率（8%）'}
                          {index == 1 && '標準税率（10%）'}
                        </span>
                      </div>
                      <div className='col-lg-2 fw-semibold text-end'>
                        {formatNumber(item.amount)}円
                      </div>
                    </div>
                  )
                })}
              <div className='row py-2'>
                <div className='col text-end'>
                  <span>総合計</span>
                </div>
                <div className='col-lg-2 fw-semibold text-end'>
                  <span className='fw-bold' style={{color: 'red'}}>
                    {formatNumber(caliculate.total)}円
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {isShow && (
        <OrderLineItemsEditModal
          orderData={orderData}
          setOrderData={setOrderData}
          isShow={isShow}
          caliculate={caliculate}
          handleClose={() => setIsShow(false)}
          handleInit={handleInit}
        />
      )}
    </>
  )
}

export const OrderLineItemsEditModal: React.FC<any> = ({
  orderData,
  setOrderData,
  isShow,
  handleClose,
  caliculate,
  handleInit,
  role,
}: {
  orderData: any
  setOrderData: (data: any) => void
  isShow: boolean
  handleClose: () => void
  caliculate: any
  handleInit: () => void
  role: string
}) => {
  const [orderDataNew, setOrderDataNew] = useState<any>({})
  const [caliculateNew, setCaliculateNew] = useState<any>({})
  const {id} = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const {setMessage} = useAlert()

  const initialValues: any = {}

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      handleSubmit()
    },
  })

  const handleSubmit = async () => {
    setLoading(true)
    await handleUpdate()
    await handleInit()
    setLoading(false)
  }

  const handleSubmitRefund = async () => {
    setLoading(true)
    await handleUpdate(true)
    await handleInit()
    setLoading(false)
  }

  const handleUpdate = async (refund: boolean = false) => {
    const result: any = await orderDataUpdate({
      type: 'order_items',
      order_ids: [id],
      update_order_items: orderDataNew.groupItem[0].list,
      update_order_shippings: orderDataNew.orderShippings,
    })

    // console.log('result', result)

    if (result.success === true) {
      setMessage({type: 'success', message: '更新しました'})
      handleInit()

      const refundResult: any = await paymentRefund({
        paymentId: orderData.payments.pk,
        orderId: orderData.order.pk,
        amount: caliculateNew.total,
        reason: '商品明細が変更されました',
        refundFlag: refund,
      })

      if (refundResult.success === true) {
        setMessage({type: 'success', message: '決済情報を変更しました'})
      } else {
        setMessage({type: 'error', message: '決済情報の変更に失敗しました'})
      }

      handleClose()
    } else {
      setMessage({type: 'error', message: '更新に失敗しました。再度お試しください。'})
      handleClose()
    }
  }

  // orderDataをnewOrderDataにコピー
  useEffect(() => {
    setOrderDataNew(orderData)
    setCaliculateNew(caliculate)
  }, [orderData, caliculate])

  // orderDataNewとcaliculateNewをformikに設定
  useEffect(() => {
    formik.setValues({
      ...orderDataNew,
    })
  }, [orderDataNew])

  // caliculateをcaliculateNewにコピー
  useEffect(() => {
    // console.log('formik.values', formik.values)
    setOrderDataNew((prev: any) => {
      return {
        ...prev,
        ...formik.values,
      }
    })
  }, [formik.values.groupItem])

  useEffect(() => {
    // console.log('formik.values', formik.values)
    setOrderDataNew((prev: any) => {
      return {
        ...prev,
        ...formik.values,
      }
    })
  }, [formik.values.orderShippings])

  // orderDataNewが変更された場合、caliculateNewを再計算
  useEffect(() => {
    // // formik.valuesが{}の場合は何もしない
    if (!orderDataNew || Object.keys(orderDataNew).length === 0) {
      return
    }

    // console.log('orderDataNew', orderDataNew)

    let result: any = {
      subtotal: 0,
      shipping_fee: 0,
      shippings: {},
      taxes: {},
      total: 0,
      difference: 0,
    }

    if (orderDataNew.groupItem.length > 0) {
      orderDataNew.groupItem.forEach((item: any, index: number) => {
        const shipping_title = item.shippings_title ?? '配送グループ名なし'
        const name =
          orderDataNew.orderShippings.length > 0
            ? orderDataNew.orderShippings[0].shipping_group_name
            : '配送グループ名なし'
        if (!result.shippings[shipping_title]) {
          result.shippings[shipping_title] = {
            amount: 0,
            name: name,
          }
        }

        result.shippings[shipping_title].amount +=
          item.shippings_amount !== '' ? Number(item.shippings_amount) : 0
        result.shipping_fee += item.shippings_amount !== '' ? Number(item.shippings_amount) : 0

        // for (let k of i.list) {
        item.list.forEach((listItem: any, listIndex: number) => {
          const tax_setting_name = listItem.tax_rate + '％'
          const tax_rate = Number(listItem.tax_rate)
          const unit_price = Number(listItem.unit_price)
          const quantity = Number(listItem.quantity)
          const individual_shipping_fee = Number(listItem.individual_shipping_fee)

          if (!result.taxes[tax_setting_name]) {
            result.taxes[tax_setting_name] = {
              rate: tax_rate,
              name: listItem.tax_rate + '％',
              type: 'included',
              amount: 0,
            }
          }

          const total = unit_price * quantity
          let tax_price = (total * (tax_rate / 100)) / (1 + tax_rate / 100)

          result.subtotal += total
          result.shipping_fee += individual_shipping_fee
          result.total += total

          result.taxes[tax_setting_name].amount += Math.round(tax_price)
        })
      })

      if (orderDataNew.orderShippings.length > 0) {
        for (let i of orderDataNew.orderShippings) {
          const fee = Number(i.fee)
          const tax_rate = Number(i.tax_rate)
          const tax_setting_name = i.tax_rate + '％'
          result.shipping_fee += fee
          const tax_price = (result.shipping_fee * (tax_rate / 100)) / (1 + tax_rate / 100)
          // console.log('tax_price', tax_price)

          // result[k.tax_setting]が存在しない場合、初期化
          if (!result.taxes[tax_setting_name]) {
            result.taxes[tax_setting_name] = {
              rate: tax_rate,
              name: tax_setting_name,
              type: 'included',
              amount: 0,
            }
          }

          result.taxes[tax_setting_name].amount += tax_price
          result.total += fee
        }
      }

      result.difference = result.total - caliculate.total

      setCaliculateNew(result)
      // setDataReady(true)
      // console.log('result', result)
    }
  }, [orderDataNew])

  return (
    <Modal show={isShow} onHide={handleClose} dialogClassName='modal-dialog-centered modal-lg'>
      <form onSubmit={formik.handleSubmit}>
        <Spin tip='更新中' spinning={loading} size='large'>
          <Modal.Header closeButton>受注商品を更新</Modal.Header>
          <Modal.Body>
            <>
              {formik.values.groupItem &&
                formik.values.groupItem.map((groupItem: any, groupIndex: number) => (
                  <div key={`group-${groupIndex}`}>
                    {groupItem.list.map((item: any, itemIndex: number) => (
                      <div className='row border-bottom py-2' key={`item-${itemIndex}`}>
                        <div className='col'>
                          <span>
                            {item.product_name} {item.product_variation_name}
                          </span>
                          <br />
                          SKU: {item.sku}
                        </div>
                        <div className='col-lg-4 fw-semibold text-end d-flex align-items-center gap-2'>
                          <input
                            type='number'
                            name={`groupItem[${groupIndex}].list[${itemIndex}].unit_price`}
                            value={formik.values.groupItem[groupIndex].list[itemIndex].unit_price}
                            className='form-control flex-grow-1 flex-shrink-1'
                            onChange={(e: any) => {
                              formik.setFieldValue(
                                `groupItem[${groupIndex}].list[${itemIndex}].unit_price`,
                                e.target.value
                              )

                              const total =
                                e.target.value *
                                formik.values.groupItem[groupIndex].list[itemIndex].quantity
                              formik.setFieldValue(
                                `groupItem[${groupIndex}].list[${itemIndex}].total_amount`,
                                total
                              )

                              const tax_price =
                                (total *
                                  formik.values.groupItem[groupIndex].list[itemIndex].tax_rate) /
                                (1 + formik.values.groupItem[groupIndex].list[itemIndex].tax_rate)

                              formik.setFieldValue(
                                `groupItem[${groupIndex}].list[${itemIndex}].sales_tax`,
                                tax_price
                              )
                            }}
                          />
                          <span>ｘ</span>
                          <input
                            type='number'
                            name={`groupItem[${groupIndex}].list[${itemIndex}].quantity`}
                            value={formik.values.groupItem[groupIndex].list[itemIndex].quantity}
                            className='form-control flex-grow-1 flex-shrink-1'
                            onChange={(e: any) => {
                              formik.setFieldValue(
                                `groupItem[${groupIndex}].list[${itemIndex}].quantity`,
                                e.target.value
                              )

                              const total =
                                e.target.value *
                                formik.values.groupItem[groupIndex].list[itemIndex].unit_price
                              formik.setFieldValue(
                                `groupItem[${groupIndex}].list[${itemIndex}].total_amount`,
                                total
                              )

                              const tax_price =
                                (total *
                                  formik.values.groupItem[groupIndex].list[itemIndex].tax_rate) /
                                (1 + formik.values.groupItem[groupIndex].list[itemIndex].tax_rate)

                              formik.setFieldValue(
                                `groupItem[${groupIndex}].list[${itemIndex}].sales_tax`,
                                tax_price
                              )
                            }}
                          />
                        </div>
                        <div className='col-lg-2 fw-semibold text-end'>
                          {/* {formatNumber(item.total_amount)}円 */}
                          {formatNumber(
                            formik.values.groupItem[groupIndex].list[itemIndex].unit_price *
                              formik.values.groupItem[groupIndex].list[itemIndex].quantity
                          )}
                          円
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              {formik.values?.orderShippings &&
                formik.values?.orderShippings.map((item: any, index: number) => (
                  <div key={`orderShippings-${index}`}>
                    <div className='row border-bottom py-2'>
                      <div className='col'>
                        <span>（送料）{item.shipping_group_name}</span>
                      </div>
                      <div className='col-lg-2 fw-semibold text-end'>
                        <input
                          type='number'
                          name={`orderShippings[${index}].fee`}
                          value={formik.values.orderShippings[index].fee}
                          className='form-control text-end'
                          onChange={(e: any) => {
                            formik.setFieldValue(`orderShippings[${index}].fee`, e.target.value)

                            const tax_price =
                              (e.target.value * formik.values.orderShippings[index].tax_rate) /
                              (1 + formik.values.orderShippings[index].tax_rate)
                            formik.setFieldValue(`orderShippings[${index}].tax`, tax_price)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              <div className='row border-bottom py-2'>
                <div className='col text-end'>
                  <span>送料合計</span>
                </div>
                <div className='col-lg-2 fw-semibold text-end'>
                  {formatNumber(caliculateNew.shipping_fee)}円
                </div>
              </div>
              <div className='row border-bottom py-2'>
                <div className='col text-end'>
                  <span>商品合計</span>
                </div>
                <div className='col-lg-2 fw-semibold text-end'>
                  {formatNumber(caliculateNew.subtotal)}円
                </div>
              </div>
              {caliculateNew.taxes &&
                Object.values(caliculateNew.taxes).map((item: any, index: number) => {
                  return (
                    <div className='row border-bottom py-2' key={index}>
                      <div className='col text-end'>
                        <span>
                          {index == 0 && '軽減税率（8%）'}
                          {index == 1 && '標準税率（10%）'}
                        </span>
                      </div>
                      <div className='col-lg-2 fw-semibold text-end'>
                        {formatNumber(item.amount)}円
                      </div>
                    </div>
                  )
                })}
              <div className='row border-bottom py-2'>
                <div className='col text-end'>
                  <span>総合計</span>
                </div>
                <div className='col-lg-2 fw-semibold text-end'>
                  <span className='fw-bold' style={{color: 'red'}}>
                    {formatNumber(caliculateNew.total)}円
                  </span>
                </div>
              </div>
              <div className='row border-bottom py-2'>
                <div className='col text-end'>
                  <span>差額</span>
                </div>
                <div className='col-lg-2 fw-semibold text-end'>
                  <span className='fw-bold' style={{color: 'red'}}>
                    {formatNumber(caliculateNew.difference)}円
                  </span>
                </div>
              </div>
              <div className='row py-2'>
                <div className='col text-end'>
                  <span>直接返金する場合はチェックを入れてください</span>
                </div>
                <div className='col-lg-2 fw-semibold text-end'></div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                onClick={handleClose}
              >
                キャンセル
              </button>
              <button
                type='button'
                className='btn btn-primary fw-bold me-2 px-6'
                onClick={handleSubmitRefund}
              >
                更新して差額を返金
              </button>
              <button type='submit' className='btn btn-primary fw-bold px-6'>
                金額のみ更新
              </button>
            </div>
          </Modal.Footer>
        </Spin>
      </form>
    </Modal>
  )
}

export const Payments: React.FC<any> = ({
  orderData,
  handleInit,
  parameters,
  role,
}: {
  orderData: any
  handleInit: void
  parameters: any
  role: string
}) => {
  const [items, setItems] = useState<any>({})
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalType, setShowModalType] = useState<number>(1)
  const [isInvoiceLoading, setIsInvoiceLoading] = useState<boolean>(false)
  const [isReceiptLoading, setIsReceiptLoading] = useState<boolean>(false)
  const [isDLLoading, setIsDLLoading] = useState<boolean>(false)
  const [invoiceTitle, setInvoiceTitle] = useState<string>('')
  const [modalParameters, setModalParameters] = useState<any>({})
  const [invoiceInitData, setInvoiceInitData] = useState<any>({})

  const handleInvoiceClose = () => {
    setShowModal(false)
  }
  const handleInvoiceShow = () => {
    setShowModal(true)
  }

  const showInvoiceModel = async (type: string, item: any) => {
    let ledgerFlg: any = 0
    let ledgerType: any = 0
    let showModalType: any = 1
    let title: string = ""
    if (type == '0') {
      setIsInvoiceLoading(true)
      title = '請求書'
      ledgerFlg = 1
      ledgerType = 0
      showModalType = 1
    } else if (type == '1') {
      setIsReceiptLoading(true)
      title = '領収書'
      ledgerFlg = 1
      ledgerType = 1
      showModalType = 1
    } else {
      setIsDLLoading(true)
      ledgerFlg = 0
      ledgerType = 0
      showModalType = 2
    }
    setInvoiceTitle('title')
    try {
      const modelParameterPromise = new Promise(async (resolve) => {
        let res: any = await parameterStoreGet({
          names: ['/Define/Product/Units', '/Define/General/Prefecture'],
        })
        if (res) {
          const modalParameters = {
            units: JSON.parse(res['/Define/Product/Units']),
            prefecture: JSON.parse(res['/Define/General/Prefecture']),
          }
          setModalParameters({
            modalParameters,
            title,
            ledgerFlg,
            ledgerType,
            showModalType,
          })
          resolve(res)
        }
      })

      const ledgerDataPromise = new Promise(async (resolve) => {
        let res: any = await ledgerDataGet({
          pkVal: orderData.order.pk,
          shippingDetailsPkVal: item?.pk,
          ledgerFlg: ledgerFlg,
          ledgerType: ledgerType,
        })
        if (res) {
          setInvoiceInitData(res)
          setItems(orderData)
          setShowModalType(showModalType)
          resolve(res)
        }
      })

      await Promise.all([modelParameterPromise, ledgerDataPromise]).then((result) => {
        if (type == '0') {
          setIsInvoiceLoading(false)
        } else if (type == '1') {
          setIsReceiptLoading(false)
        } else {
          setIsDLLoading(false)
        }
        handleInvoiceShow()
      })
    } catch (err) {
      if (type == '0') {
        setIsInvoiceLoading(false)
      } else if (type == '1') {
        setIsReceiptLoading(false)
      } else {
        setIsDLLoading(false)
      }
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>決済情報</div>
        </div>
        <div className='card-body'>
          <div className='row mb-2'>
            <div className='col-lg-4'>
              <span className=''>決済番号</span>
            </div>
            <div className='col-lg-8'>{orderData.payments.payment_order_id}</div>
          </div>
          <div className='row mb-2'>
            <div className='col-lg-4'>
              <span className=''>決済金額</span>
            </div>
            <div className='col-lg-8'>
              <span className='' style={{color: 'red'}}>
                {formatNumber(orderData.payments.payment_amount)}円
              </span>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-lg-4'>
              <span className=''>決済方法</span>
            </div>
            <div className='col-lg-8'>
              {
                parameters.paymentMethod.find(
                  (item: {value: any}) => item.value === orderData?.payments?.payment_method
                )?.label
              }

              {/* {
              parameters.paymentMethod.find(
                (item: {value: any}) => item.value === orderData?.order?.status
              )?.label
            } */}
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-lg-4'>
              <span className=''>決済ステータス</span>
            </div>
            <div className='col-lg-8'>
              {
                parameters.paymentStatus.find(
                  (item: {value: any}) => item.value === orderData?.payments?.payment_status
                )?.label
              }
              {/* <span className='badge badge-light-success'>
                {
                  parameters.paymentStatus.find(
                    (item: {value: any}) => item.value === orderData?.payments?.payment_status
                  )?.label
                }
              </span> */}
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4'>
              <span className=''>帳票のダウンロード</span>
            </div>
            <div className='col-lg-8 d-flex justify-content-start gap-3'>
              <button
                className='btn btn-sm btn-white btn-outline'
                onClick={() => {
                  showInvoiceModel('0', null)
                }}
              >
                {!isInvoiceLoading && '請求書ダウンロード'}
                {isInvoiceLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    お待ちください...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <button
                className='btn btn-sm btn-white btn-outline'
                onClick={() => {
                  showInvoiceModel('1', null)
                }}
              >
                {!isReceiptLoading && '領収書ダウンロード'}
                {isReceiptLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    お待ちください...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <LedgerModal
          parameters={modalParameters}
          data={invoiceInitData}
          items={items}
          isShow={showModal}
          handleClose={handleInvoiceClose}
        />
      )}
    </>
  )
}

export const ShippingDetails: React.FC<any> = ({
  orderData,
  handleInit,
  role,
}: {
  orderData: any
  handleInit: void
  role: string
}) => {
  const [items, setItems] = useState<any>({})
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalType, setShowModalType] = useState<number>(1)
  const [isInvoiceLoading, setIsInvoiceLoading] = useState<boolean>(false)
  const [isReceiptLoading, setIsReceiptLoading] = useState<boolean>(false)
  const [isDLLoading, setIsDLLoading] = useState<boolean>(false)
  const [invoiceTitle, setInvoiceTitle] = useState<string>('')
  const [modalParameters, setModalParameters] = useState<any>({})
  const [invoiceInitData, setInvoiceInitData] = useState<any>({})

  const handleInvoiceClose = () => {
    setShowModal(false)
  }
  const handleInvoiceShow = () => {
    setShowModal(true)
  }

  const showInvoiceModel = async (type: string, item: any) => {
    let ledgerFlg: any = 0
    let ledgerType: any = 0
    let showModalType: any = 2
    setIsDLLoading(true)

    try {
      const modelParameterPromise = new Promise(async (resolve) => {
        let res: any = await parameterStoreGet({
          names: ['/Define/Product/Units', '/Define/General/Prefecture'],
        })
        if (res) {
          const modalParameters = {
            units: JSON.parse(res['/Define/Product/Units']),
            prefecture: JSON.parse(res['/Define/General/Prefecture']),
          }
          setModalParameters({
            modalParameters,
            ledgerFlg,
            ledgerType,
            showModalType,
          })
          resolve(res)
        }
      })

      const ledgerDataPromise = new Promise(async (resolve) => {
        let res: any = await ledgerDataGet({
          pkVal: orderData.order.pk,
          shippingDetailsPkVal: item?.pk,
          ledgerFlg: ledgerFlg,
          ledgerType: ledgerType,
        })
        if (res) {
          setInvoiceInitData(res)
          setItems(orderData)
          setShowModalType(showModalType)
          resolve(res)
        }
      })

      await Promise.all([modelParameterPromise, ledgerDataPromise]).then((result) => {
        setIsDLLoading(false)
        handleInvoiceShow()
      })
    } catch (err) {
      setIsDLLoading(false)
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>出荷情報</div>
        </div>
        <div className='card-body'>
          {orderData?.shippingDetails.map((item: any, index: number) => (
            <div className='border p-4' key={`orderShippings-${index}`}>
              <div className='row mb-2'>
                <div className='col-lg-4'>
                  <span className=''>出荷番号</span>
                </div>
                <div className='col-lg-8'>{item.display_id}</div>
              </div>
              <div className='row mb-2'>
                <div className='col-lg-4'>
                  <span className=''>配送方法</span>
                </div>
                <div className='col-lg-8'>{item.delivery_method}</div>
              </div>
              <div className='row mb-2'>
                <div className='col-lg-4'>
                  <span className=''>出荷ステータス</span>
                </div>
                <div className='col-lg-8'>
                  <span>
                    {item.status === 'awaiting' ? <span>出荷待ち</span> : <span></span>}
                    {item.status === 'shipped' ? <span>出荷済み</span> : <span></span>}
                    {item.status === 'cancelled' ? <span>キャンセル</span> : <span></span>}
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-4'>{/* <span className=''>納品書</span> */}</div>
                <div className='col-lg-8 d-flex justify-content-start gap-3'>
                  <button
                    className='btn btn-sm btn-white btn-outline w-100 w-lg-auto'
                    onClick={() => {
                      showInvoiceModel('1', item)
                    }}
                  >
                    {!isDLLoading && '納品書ダウンロード'}
                    {isDLLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        お待ちください...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <LedgerModal
          parameters={modalParameters}
          data={invoiceInitData}
          items={items}
          isShow={showModal}
          handleClose={handleInvoiceClose}
        />
      )}
    </>
  )
}

export const OrderData: React.FC<any> = ({
  orderData,
  handleInit,
  parameters,
  role,
}: {
  orderData: any
  handleInit: () => void
  parameters: any
  role: string
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [status, setStatus] = useState<any>(orderData?.order?.status)
  const {setMessage} = useAlert()
  const {id} = useParams()

  const handleUpdate = async () => {
    const result: any = await orderDataUpdate({
      type: 'status',
      order_ids: [id],
      status: status,
    })

    // console.log('result', result)

    if (result.success === true) {
      setMessage({type: 'success', message: '更新しました'})
      // setOrderData(orderDataNew)

      if (result.success === true) {
        setMessage({type: 'success', message: '受注ステータスを変更しました'})
        handleInit()
      } else {
        setMessage({type: 'error', message: '受注ステータスの更新に失敗しました'})
      }

      setIsEdit(false)
    } else {
      setMessage({type: 'error', message: '受注ステータスの更新に失敗しました。再度お試しください'})
      setIsEdit(false)
    }
  }

  // useEffect(() => {
  //   console.log('orderData', orderData.order.status)
  // })

  return (
    <div className='card'>
      <div className='card-header'>
        <div className='card-title'>{`${orderTitle[role]}`}情報</div>
        <div className='card-toolbar'>
          {role === 'supplier' && (
            <button
              type='button'
              className='btn btn-sm btn-white btn-outline'
              onClick={() => setIsEdit(true)}
            >
              編集
            </button>
          )}
        </div>
      </div>
      <div className='card-body'>
        <div className='row border-bottom py-2'>
          <div className='col-lg-3 fw-semibold flex-shrink-0'>ステータス</div>
          <div className='col-lg-9 flex-grow-1'>
            {!isEdit && (
              <>
                {
                  parameters.orderStatus.find(
                    (item: {value: any}) => item.value === orderData?.order?.status
                  )?.label
                }
              </>
              // <span className='badge badge-light-success'>
              //   {
              //     parameters.orderStatus.find(
              //       (item: {value: any}) => item.value === orderData?.order?.status
              //     )?.label
              //   }
              // </span>
            )}

            {isEdit && (
              <>
                <select
                  className='form-select form-select-sm w-auto'
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {parameters.orderStatus.map((item: any, index: number) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>

                <div className='mt-4'>
                  <button
                    type='button'
                    className='btn btn-primary btn-sm d-inline-block me-2'
                    onClick={handleUpdate}
                  >
                    保存
                  </button>
                  <button
                    type='button'
                    className='btn btn-danger btn-sm d-inline-block'
                    onClick={() => setIsEdit(false)}
                  >
                    キャンセル
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='row border-bottom py-2'>
          <div className='col-lg-3 fw-semibold'>{`${orderTitle[role]}`}番号</div>
          <div className='col-lg-9'>{orderData?.order?.display_id}</div>
        </div>
        <div className='row py-2'>
          <div className='col-lg-3 fw-semibold'>{`${orderTitle[role]}`}日時</div>
          <div className='col-lg-9'>
            {moment(orderData?.order?.order_date).format('YYYY/MM/DD HH:mm:ss')}
          </div>
        </div>
        {/* <div className='row py-2'>
          <div className='col-lg-3 fw-semibold'>価格変更の自動承認</div>
          <div className='col-lg-9'></div>
        </div> */}
      </div>
    </div>
  )
}

export const BillingData: React.FC<any> = ({
  orderData,
  handleInit,
  parameters,
  role,
}: {
  orderData: any
  handleInit: () => void
  parameters: any
  role: string
}) => {
  return (
    <div className='card'>
      <div className='card-header'>
        <div className='card-title'>請求先情報</div>
      </div>
      <div className='card-body'>
        <div className='row border-bottom py-2'>
          <div className='col-lg-4 fw-semibold'>郵便番号</div>
          <div className='col-lg-8'>{orderData?.buyerInfo.billing_address_postal_code}</div>
        </div>
        <div className='row border-bottom py-2'>
          <div className='col-lg-4 fw-semibold'>都道府県</div>
          <div className='col-lg-8'>
            {
              parameters.prefecture.find(
                (item: {value: any}) =>
                  item.value === orderData?.buyerInfo.billing_address_prefecture
              )?.label
            }
          </div>
        </div>
        <div className='row border-bottom py-2'>
          <div className='col-lg-4 fw-semibold'>市区町村</div>
          <div className='col-lg-8'>{orderData?.buyerInfo.billing_address_city}</div>
        </div>
        <div className='row border-bottom py-2'>
          <div className='col-lg-4 fw-semibold'>丁目番地</div>
          <div className='col-lg-8'>{orderData?.buyerInfo.billing_address_street}</div>
        </div>
        <div className='row border-bottom py-2'>
          <div className='col-lg-4 fw-semibold'>建物名</div>
          <div className='col-lg-8'>{orderData?.buyerInfo.billing_address_building_name}</div>
        </div>
        <div className='row border-bottom py-2'>
          <div className='col-lg-4 fw-semibold'>事業者名</div>
          <div className='col-lg-8'>{orderData?.buyerInfo.billing_company_name}</div>
        </div>
        <div className='row border-bottom py-2'>
          <div className='col-lg-4 fw-semibold'>氏名</div>
          <div className='col-lg-8'>
            {orderData?.buyerInfo.billing_manager_lastname}
            {orderData?.buyerInfo.billing_manager_firstname}
          </div>
        </div>
        <div className='row py-2'>
          <div className='col-lg-4 fw-semibold'>電話番号</div>
          <div className='col-lg-8'>{orderData?.buyerInfo.billing_tel}</div>
        </div>
        {/* <div className='row py-2'>
          <div className='col-lg-4 fw-semibold'>メールアドレス</div>
          <div className='col-lg-8'>{orderData?.buyerInfo.display_id}</div>
        </div> */}
      </div>
    </div>
  )
}

export const OrderInfo: React.FC<any> = ({
  orderData,
  handleInit,
  role,
}: {
  orderData: any
  handleInit: () => void
  role: string
}) => {
  return (
    <div className='card'>
      <div className='card-header'>
        <div className='card-title'>{`${orderTitle[role]}`}者情報</div>
      </div>
      <div className='card-body'>
        {/* <div className='row border-bottom py-2'>
          <div className='col-lg-4 fw-semibold'>事業者名</div>
          <div className='col-lg-8'>{orderData?.order?.display_id}</div>
        </div> */}
        <div className='row py-2'>
          <div className='col-lg-4 fw-semibold'>担当者名</div>
          <div className='col-lg-8'>
            {orderData?.order?.staff_lastname} {orderData?.order?.staff_firstname}
          </div>
        </div>
        {/* <div className='row border-bottom py-2'>
          <div className='col-lg-4 fw-semibold'>メールアドレス</div>
          <div className='col-lg-8'>{orderData?.order?.display_id}</div>
        </div>
        <div className='row py-2'>
          <div className='col-lg-4 fw-semibold'>電話番号</div>
          <div className='col-lg-8'>{orderData?.order?.display_id}</div>
        </div> */}
      </div>
    </div>
  )
}

export const Note: React.FC<any> = ({
  orderData,
  handleInit,
  role,
}: {
  orderData: any
  handleInit: () => void
  role: string
}) => {
  const [orderNotes, setOrderNotes] = useState<any>('')
  const {setMessage} = useAlert()
  const {id} = useParams()

  const handleUpdate = async () => {
    const result: any = await orderDataUpdate({
      type: 'order_notes_update',
      order_ids: [id],
      order_notes: orderNotes,
    })

    // console.log('result', result)

    if (result.success === true) {
      setMessage({type: 'success', message: '社内メモを更新しました'})
      handleInit()
    } else {
      setMessage({type: 'error', message: '社内メモの更新に失敗しました。再度お試しください'})
    }
  }

  // useEffect(() => {
  //   console.log('orderData', orderData.order.status)
  // })

  return (
    <div className='card'>
      <div className='card-header fs-6'>
        <div className='card-title'>社内メモ</div>
      </div>
      <div className='card-body'>
        <div className='d-flex flex-column gap-4 mb-4'>
          <div>
            {orderData?.orderNotes.map((item: any, index: number) => (
              <div className='bg-light p-4 mb-2' key={`orderShippings-${index}`}>
                <div className='row py-2'>
                  <div className='col-12'>
                    <span>{moment(item.create_at).format('YYYY/MM/DD HH:mm:ss')}</span>
                    <span> {item.user_name}</span>
                  </div>
                  <div className='col'>{nl2br(item.detail)}</div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <textarea
              className='form-control'
              rows={3}
              onChange={(e) => {
                setOrderNotes(e.target.value)
              }}
            ></textarea>
          </div>
          <div>
            <button type='button' className='btn btn-sm btn-primary' onClick={handleUpdate}>
              社内メモを保存
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const OrderTimeLine: React.FC<any> = ({orderData, role}: {orderData: any; role: string}) => {
  return (
    <div className='card'>
      <div className='card-header fs-6'>
        <div className='card-title'>{`${orderTitle[role]}`}変更履歴</div>
      </div>
      <div className='card-body'>
        <div className='row mb-4'>
          <div className='col-lg-12'>
            {useIsWidthSize('lg') && (
              <div className='row py-2'>
                <div className='col-12 col-lg-2'>
                  <span>日時</span>
                </div>
                <div className='col-12 col-lg-2'>
                  <span>実行ユーザー</span>
                </div>
                <div className='col'>詳細</div>
              </div>
            )}
            {orderData?.orderTimeline.map((item: any, index: number) => (
              <div className='bg-light p-4 mb-2' key={`orderShippings-${index}`}>
                <div className='row py-2'>
                  <div className='col-12 col-lg-2'>
                    <span>{moment(item.create_at).format('YYYY/MM/DD HH:mm:ss')}</span>
                  </div>
                  <div className='col-12 col-lg-2'>
                    <span>{item.user_name}</span>
                  </div>
                  <div className='col'>{nl2br(item.detail)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const LedgerModal: React.FC<any> = ({
  parameters,
  data,
  items,
  isShow,
  handleClose,
}: {
  parameters: any
  data: any
  items: any
  isShow: boolean
  handleClose: () => void
}) => {
  const initialValues = {
    amount: 0,
    reason: '',
  }

  const invoiceRef: any = useRef('null')

  const submit = async () => {
    await new Promise((resolve, reject) => {
      invoiceRef.current &&
        invoiceRef.current.submitData(function () {
          resolve(true)
          handleClose()
        })
    })
  }

  // const handleSubmit = (values: any) => {
  //   // paymentRefund({
  //   //   paymentId: detail.payments[0].pk,
  //   //   orderId: qramesObj.pkVal,
  //   //   amount: values.amount,
  //   //   reason: values.reason,
  //   // });
  // }

  return (
    <>
      <Modal show={isShow} onHide={handleClose} dialogClassName='modal-dialog-centered mw-1000px'>
        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
          {parameters.showModalType == 1 && (
            <Invoice
              title={parameters.title}
              items={items}
              ref={invoiceRef}
              invoiceInitData={data}
              modalParameters={parameters.modalParameters}
            />
          )}
          {parameters.showModalType == 2 && (
            <Shipping
              items={items}
              ref={invoiceRef}
              invoiceInitData={data}
              modalParameters={parameters.modalParameters}
            />
          )}
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <button
              type='button'
              className='btn btn-light me-3'
              onClick={() => {
                handleClose()
              }}
            >
              キャンセル
            </button>
            <ProcessButton
              type='submit'
              className='btn btn-primary'
              onClick={() => {
                submit()
              }}
            >
              ダウンロード
            </ProcessButton>
          </div>
        </div>
      </Modal>
    </>
  )
}
