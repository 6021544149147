import React, {useEffect, useState, useRef} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useNavigate, useLocation} from 'react-router-dom'
import {useLayout} from 'src/_metronic/layout/core'
import {orderDataAdd, paymentTransaction, orderStatusUpdate} from 'src/app/modules/api'
import {useIsWidthSize, formatNumber, getParameterStore} from 'src/app/modules/utils'
import {Spin} from 'antd'
import {SupplierCheckoutModal} from '../cart/components/CheckoutModal'
import {useAlert} from 'src/_metronic/layout/core'

export default function LaterPaymentConfirm() {
  const [watingList, setWatingList] = useState<any>([])
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const [orderData, setOrderData] = useState<any>([])
  const [ordering, setOrdering] = useState<boolean>(false)
  const newTab = useRef<any>(null)
  const [modalData, setModalData] = useState<any>({
    show: false,
    company_name: '',
    amount: '',
    redirectUrl: '',
    order_id: '',
    loading: false,
    supplier_id: '',
    payment_method: '',
    status: '',
    index: 0,
    max: 0,
    handleShow: () => {},
    handleHide: () => {},
    handleSubmit: async () => {},
  })
  const proceccedList = useRef<any>([])
  const [errorList, setErrorList] = useState<any>([])
  const [completeList, setCompleteList] = useState<any>([])
  const navigate = useNavigate()
  let location = useLocation()
  const supplier_data: any = location.state ? location.state : null
  const {setMessage} = useAlert()
  const returnState = useRef<any>({})
  const completeListRef = useRef(completeList)
  const errorListRef = useRef(errorList)

  useEffect(() => {
    console.log('supplier_data', supplier_data)
  }, [])

  // completeListとerrorListの合計がsupplier_dataの数と同じになったら完了ページへ遷移する
  useEffect(() => {
    if (completeList.length + errorList.length === Object.keys(supplier_data).length) {
      // errorListが0なら完了ページへ遷移
      if (errorList.length === 0) {
        navigate('/later/checkout/complete', {state: {supplier_data: supplier_data}, replace: true})
      }

      // errorListがある場合はメッセージを表示して/checkoutへ遷移
      if (errorList.length > 0) {
        setMessage({type: 'error', message: '決済に失敗した取引があります。再度お試しください。'})
        navigate('/later/checkout', {state: {...supplier_data}, replace: true})
      }
    }
  }, [completeList, errorList])

  useEffect(() => {
    console.log(watingList, errorList, completeList)
  }, [watingList, errorList, completeList])

  useEffect(() => {
    completeListRef.current = completeList

    // 完了したものはreturnStateから削除する
    // if (completeListRef.current.length > 0) {
    //   returnState.current.supplier_items = qramesObj.supplier_items.filter(
    //     (k: any) => !completeListRef.current.includes(k.id)
    //   )
    // }
  }, [completeList])

  useEffect(() => {
    errorListRef.current = errorList
  }, [errorList])

  const handleSubmit = async () => {
    Object.keys(supplier_data).map(async (key: string, index: number) => {
      setWatingList((prev: any) => [...prev, key])
    })
    await execCheckout(supplier_data)
  }

  useEffect(() => {
    // console.log('completeList', completeList)
    // console.log('completeList.length', completeList.length)
    // console.log('errorList', errorList)
    // console.log('errorList.length', errorList.length)
    // console.log('proceccedList', proceccedList)
    // console.log('proceccedList.length', proceccedList.length)
    // console.log('supplier_items.length', Object.keys(supplier_data).length)

    // completeListとerrorListの合計がsupplier_itemsの長さが一致したら、全ての注文が完了
    if (completeList.length + errorList.length === Object.keys(supplier_data).length) {
      console.log('終了処理')

      // completeListが0かつerrorListが1以上の場合、エラーがある
      if (completeList.length === 0 && errorList.length > 0) {
        setMessage({
          type: 'error',
          message: '注文に失敗しました。注文内容またはクレジットカード情報をご確認ください。',
        })

        // 前のページに戻る
        navigate('/later/checkout', {state: {...supplier_data}, replace: true})
      }

      // errorListが1以上かつ、completeListが1以上の場合、一部の注文が完了している
      if (errorList.length > 0 && completeList.length > 0) {
        setMessage({
          type: 'warning',
          message: '一部の注文が完了できませんでした。完了できなかった注文はカートに残っています。',
        })
        setIsComplete(true)
      }

      // completeListとproceccedListが一致したら、全ての注文が完了
      if (completeList.length === Object.keys(supplier_data).length) {
        setIsComplete(true)
      }
    }
  }, [completeList, errorList, proceccedList])

  if (!supplier_data) {
    navigate('/buy/cart', {replace: true})
    return null
  }

  const execCheckout = async (supplier_data: any) => {
    console.log('supplier_data', supplier_data)
    console.log('execOrder', orderData)
    setOrdering(true)

    for (const data_key of Object.keys(supplier_data)) {
      if (!errorList.find((k: any) => k[data_key])) {
        try {
          // complete が trueになるまで3秒ごとに繰り返す
          let complete = false
          proceccedList.current = [...proceccedList.current, data_key]

          setModalData({
            show: true,
            company_name: supplier_data[data_key].company_name,
            amount: supplier_data[data_key].total,
            redirectUrl: '',
            order_id: '',
            loading: false,
            supplier_id: data_key,
            payment_method: supplier_data[data_key].payment_method,
            status: 'payment',
            index: proceccedList.current.length,
            max: Object.keys(supplier_data).length,
            handleShow: () => setModalData((prev: any) => ({...prev, show: true})),
            handleHide: () => {
              // setModalData((prev: any) => ({...prev, show: false}))
              setErrorList((prev: string[]) => [...prev, data_key])
            },
            handleSubmit: async () => secureTraceWindow('', ''),
          })

          if (!complete) {
            let res: any = await paymentTransaction({
              payment_id: Object.keys(supplier_data[data_key].list),
              supplier: data_key,
              card_seq: supplier_data[data_key].cardSeq,
              amount: supplier_data[data_key].total,
              provider: 'smbc',
              payment_method: supplier_data[data_key].payment_method,
            })

            if (res.data?.redirectUrl) {
              // リダイレクトURLがあるときはリダイレクト
              setModalData((prev: any) => ({
                ...prev,
                order_id: '',
                redirectUrl: res.data.redirectUrl,
                status: 'pending',
                handleSubmit: async () => secureTraceWindow(res.data.redirectUrl, data_key),
              }))

              let count = 0
              try {
                await new Promise<void>((resolve, reject) => {
                  const intervalId = setInterval(() => {
                    const nextFlag =
                      completeListRef.current.includes(data_key) ||
                      errorListRef.current.includes(data_key)

                    console.log('nextFlag', nextFlag, completeListRef.current, data_key, newTab)

                    if (!nextFlag && newTab.current && newTab.current.closed) {
                      clearInterval(intervalId)
                      setModalData((prev: any) => ({...prev, status: 'error'}))
                      setMessage({
                        type: 'info',
                        message: '決済がキャンセルされました。支払い画面に戻ります。',
                      })
                      navigate('/later/checkout', {state: {...supplier_data}, replace: true})
                      reject(new Error('決済がキャンセルされました'))
                    }

                    if (count >= 180) {
                      clearInterval(intervalId)
                      setModalData((prev: any) => ({...prev, status: 'error'}))
                      setMessage({
                        type: 'info',
                        message: '決済がタイムアウトしました。支払い画面に戻ります。',
                      })
                      navigate('/later/checkout', {state: {...supplier_data}, replace: true})
                      reject(new Error('決済がタイムアウトしました'))
                    }

                    if (nextFlag) {
                      clearInterval(intervalId)

                      // completeListにあるときは成功、ないときはエラー
                      if (completeListRef.current.includes(data_key)) {
                        setModalData((prev: any) => ({...prev, status: 'payment_success'}))
                      } else {
                        setModalData((prev: any) => ({...prev, status: 'error'}))
                      }
                      newTab.current = null
                      resolve()
                    }
                    count++
                  }, 1000)
                })

                // 5秒待ってから次の処理に進む
                await new Promise((resolve) => setTimeout(resolve, 5000))
              } catch (e) {
                // 5秒待ってから次の処理に進む
                await new Promise((resolve) => setTimeout(resolve, 5000))
                // setModalData((prev: any) => ({...prev, show: false}))
                return false
              }
            } else {
              setModalData((prev: any) => ({...prev, status: 'in_progress'}))

              if (res.message === 'ok') {
                setModalData((prev: any) => ({...prev, status: 'success'}))
                await new Promise((resolve) => setTimeout(resolve, 5000))
                setCompleteList((prev: any) => [...prev, data_key])
              } else {
                setModalData((prev: any) => ({...prev, status: 'error'}))
                await new Promise((resolve) => setTimeout(resolve, 5000))
                throw new Error('決済に失敗しました')
              }
            }
          }
        } catch (err) {
          console.log('err', err)
          setErrorList((prev: any) => [...prev, data_key])
          break
        }
      }
    }

    setOrdering(false)
  }

  const secureTraceWindow = async (redirectUrl: string, supplier_id: string) => {
    // console.log('modalData', redirectUrl, order_id, supplier_id)
    // let newTab: any = null
    try {
      newTab.current = window.open(redirectUrl, '_blank')
      setModalData((prev: any) => ({...prev, status: 'in_progress'}))
      // setModalData((prev: any) => ({...prev, loading: true}))
    } catch (e) {
      // alert(
      //   'ポップアップがブロックされたため、決済を中断します。ブラウザの設定を変更して、再度お試しください。'
      // )
      setMessage({
        type: 'info',
        message:
          'ポップアップがブロックされたため、決済を中断します。ブラウザの設定を変更して、再度お試しください。',
      })
      setErrorList((prev: any) => [...prev, supplier_id])
      return false
    }

    const handleMessage = (event: MessageEvent) => {
      // console.log('event', event)

      if (event.origin === process.env.REACT_APP_API_ORIGIN) {
        if (event.data.checkout === 'ok') {
          setCompleteList((prev: string[]) => [...prev, supplier_id])
          window.removeEventListener('message', handleMessage)
        }
        if (event.data.auth === 'ng') {
          window.removeEventListener('message', handleMessage)
          setErrorList((prev: string[]) => [...prev, supplier_id])
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return
  }

  return (
    <>
      {/* <FormikProvider value={formik}>
        <Form> */}
      {supplier_data &&
        Object.keys(supplier_data).map((key: string, index: number) => (
          <SectionSuppliers
            key={key}
            supplier_data={supplier_data[key]}
            dataKey={key}
            index={index}
            watingList={watingList}
            completeList={completeList}
            errorList={errorList}
          />
        ))}
      <div className='row'>
        <div className='mt-4 d-flex w-100 justify-content-between'>
          <button
            className='btn btn-white'
            style={{border: '1px solid'}}
            onClick={() => {
              navigate('/dashboard', {state: {...supplier_data}, replace: true})
            }}
          >
            ダッシュボードに戻る
          </button>

          <button
            type='button'
            className='btn btn-dark d-flex align-items-center justify-content-center'
            style={{border: '1px solid'}}
            onClick={handleSubmit}
          >
            お支払いへ進む
            <KTSVG
              path='/media/icons/duotune/arrows/arr001.svg'
              className='svg-icon-muted svg-icon-fs-2qx ms-2'
            />
          </button>
        </div>
      </div>
      {modalData && modalData.show && (
        // <Spin spinning={modalData}>
        <SupplierCheckoutModal {...modalData} />
        // </Spin>
      )}
      {/* </Form>
      </FormikProvider> */}
    </>
  )
}

interface SectionSuppliersProps {
  supplier_data: any
  dataKey: string
  index: number
  watingList: any[]
  completeList: any[]
  errorList: any[]
}

const SectionSuppliers: React.FC<SectionSuppliersProps> = ({...props}) => {
  useEffect(() => {
    // console.log('props', props)
  })

  return (
    <>
      {useIsWidthSize('lg') ? (
        <PaymentSupplierAreaSP {...props} />
      ) : (
        <PaymentSupplierAreaSP {...props} />
      )}
    </>
  )
}

const PaymentSupplierArea: React.FC<SectionSuppliersProps> = ({...props}) => {
  return <></>
}

const PaymentSupplierAreaSP: React.FC<SectionSuppliersProps> = ({...props}) => {
  const [index, setIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loading2, setLoading2] = useState(false)
  const [showDetail, setShowDetail] = useState(true)
  const [supplier_data, setSupplierData] = useState<any>({})

  useEffect(() => {
    setLoading(true)
    // let key = Object.keys(formik.values['supplier_data'])[key]
    // console.log('key', props.dataKey)
    if (props.dataKey) {
      setSupplierData(props.supplier_data)
      setLoading(false)
    }
  }, [])

  // watingListにprops.dataKeyがあるときはloading2をtrue
  useEffect(() => {
    if (props.watingList.includes(props.dataKey)) {
      setLoading2(true)
    } else {
      setLoading2(false)
    }
  }, [props.watingList])

  // completeListにprops.dataKeyがあるときはshowDetailをfalse
  useEffect(() => {
    if (props.completeList.includes(props.dataKey)) {
      setShowDetail(false)
    } else {
      setShowDetail(true)
    }
  }, [props.completeList])

  return (
    <>
      {!loading && (
        <>
          {showDetail && (
            <Spin spinning={loading2}>
              <div className='card mb-4'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h3>{supplier_data.company_name}</h3>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
                      <span className='fw-bold'>支払金額</span>
                    </div>
                    <div className='col-12 col-lg-8 d-flex align-items-center'>
                      <span className='fw-bold' style={{color: 'red'}}>
                        {formatNumber(supplier_data.total)}円
                      </span>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0 py-2'>
                      <span className='fw-bold'>支払方法</span>
                    </div>
                    <div className='col-12 col-lg-8 d-flex align-items-center py-2'>
                      {supplier_data.payment_method === 'bank_transfer' && <span>銀行振込</span>}
                      {supplier_data.payment_method === 'credit_card' && (
                        <span>クレジットカード</span>
                      )}
                    </div>
                  </div>
                  {supplier_data.payment_method.payment_method === 'credit_card' && <></>}
                </div>
              </div>
            </Spin>
          )}
        </>
      )}
    </>
  )
}
