import React from 'react';
import { FieldArray, useFormikContext } from 'formik';

interface ProductCustomFieldSelectorProps {
  options: { id: string; name: string }[];
  updateData: (values: any) => void;
}

const ProductCustomFieldSelector: React.FC<ProductCustomFieldSelectorProps> = ({ options, updateData }) => {
  const formik = useFormikContext<any>();
  // console.log("ProductCustomFieldSelector options",options);

  return (
    <div className='row mb-6'>
      <label className='col-lg-2 col-form-label fs-7'>
        <span>商品カスタム項目</span>
      </label>
      <div className='col-lg-10 align-self-center'>
        <FieldArray
          name="product_custom_field"
          render={(arrayHelpers) => (
            <>
              {formik.values.product_custom_field && formik.values.product_custom_field.map((item: any, index: number) => (
                <div className='row gx-0 pb-3' key={index}>
                  <div
                    className='col-lg-3'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#dfe2e9',
                      borderTopLeftRadius: '0.475rem',
                      borderBottomLeftRadius: '0.475rem',
                      borderRight: 'none',
                      padding: '5px 0',
                    }}
                  >
                    項目名を選択
                  </div>
                  <div className='col-lg-7'>
                    <div style={{ display: 'flex' }}>
                      <select
                        className='form-select'
                        data-control='select2'
                        placeholder='選択してください'
                        value={item.id}
                        onChange={(e) => {
                          arrayHelpers.replace(index, {
                            ...item,
                            id: e.target.value
                          });
                          // updateData(formik.values);
                        }}
                        style={{
                          borderTopRightRadius: '0.475rem',
                          borderBottomRightRadius: '0.475rem',
                          borderTopLeftRadius: '0',
                          borderBottomLeftRadius: '0',
                        }}
                      >
                        <option value=''></option>
                        {options &&options.map((option, optIndex) => (
                          <option key={optIndex} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      <input
                        type='text'
                        className='form-control'
                        value={item.value}
                        onChange={(e) => {
                          arrayHelpers.replace(index, {
                            ...item,
                            value: e.target.value,
                          });
                          // updateData(formik.values);
                        }}
                        style={{
                          borderTopLeftRadius: '0.475rem',
                          borderBottomLeftRadius: '0.475rem',
                          marginLeft: '5px',
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-lg-2 d-flex justify-content-end'>
                    <div
                      onClick={() => {
                        if (formik.values.product_custom_field.length > 1) {
                          arrayHelpers.remove(index);
                          // updateData(formik.values);
                        }
                      }}
                      className='btn btn-danger'
                    >
                      削除
                    </div>
                  </div>
                  {/* {options.find((k: any) => k.id === item.id)?.status === 'disabled' &&
                    <div style={{ color: 'red' }}>この項目は設定で無効化されています</div>
                  } */}
                </div>
              ))}
              <div style={{ textAlign: 'right' }}>
                <button
                  type='button'
                  className='btn btn-primary w-100px'
                  onClick={() => {
                    arrayHelpers.push({ id: '', value: '' });
                    // updateData(formik.values);
                  }}
                >
                  ＋追加
                </button>
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default ProductCustomFieldSelector;
