import React from 'react'

export default function Checkbox(props: any) {
  const { checked, onChange } = props;

  return (
    <div className='form-check form-check-sm form-check-custom me-3'>
      <input
        className='form-check-input'
        type='checkbox'
        data-kt-check-target='#kt_table_users .form-check-input'
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
};
