/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'

const class1 = 'page-item previous'
const class2 = 'page-item previous active'
const class3 = 'page-item previous disabled'

const Pagination = (props: any) => {
  const [nowPage, setNowPage] = useState<any>(props.page || 1) // 当前页
  const [pageSize, setPageSize] = useState<any>(props.pageSize || 10) // 每页多少条
  let [pageNum, setPageNum] = useState<any>([]) // 页码列表
  const [pagenation, setPagenation] = useState<any>([]) // 页码列表

  //处理页码
  const changePage = (sumPage: any, size?: number | string) => {
    pageNum = []
    if (sumPage === 0) {
      setPageNum([1])
      return
    }
    let num = Math.ceil(sumPage / Number(size ? size : pageSize))
    // console.log('num', sumPage, pageSize, num)
    for (let i = 1; i < num + 1; i++) {
      pageNum.push(i)
    }
    let newPage = JSON.parse(JSON.stringify([...pageNum]))
    setPageNum(newPage)
    setNowPage(1)
  }
  //获取当前页码的列表
  const getPageList = async (page: any) => {
    setNowPage(page)
    props.currentPage(page, pageSize)
  }
  // 改变当前显示数量
  const changePageSize = (size: string | number) => {
    setPageSize(size)
    setNowPage(1)
    changePage(props.total, size)
    props.currentPage(1, size)
  }

  useEffect(() => {
    console.log('props.total', props)
  })

  useEffect(() => {
    // console.log('props.total',props.total)
    changePage(props.total)
  }, [props.total])

  return (
    <ul className='pagination' style={{}}>
      <li className={nowPage === pageNum[0] ? class3 : class1}>
        <a
          href='#'
          className='page-link'
          onClick={(e) => {
            getPageList(nowPage - 1)
            e.preventDefault()
          }}
        >
          <i className='previous'></i>
        </a>
      </li>
      {pageNum.map((item: any, index: number) => {
        // 表示するのは現在地 -1と+1のページ番号と、末尾3ページを表示のみ

        // if (item < nowPage - 1 || item > nowPage + 1) {
        //   if (item !== pageNum[0] && item !== pageNum[1] && item !== pageNum[2]) {
        //     if (
        //       item !== pageNum[pageNum.length - 1] &&
        //       item !== pageNum[pageNum.length - 2] &&
        //       item !== pageNum[pageNum.length - 3]
        //     ) {
        //       return <></>
        //     }
        //   }
        // }

        // if (item < nowPage - 1 || item > nowPage + 1) {
        //   if (
        //     item !== pageNum[index] &&
        //     item !== pageNum[index + 1] &&
        //     item !== pageNum[index + 2]
        //   ) {
        //     if (
        //       item !== pageNum[pageNum.length - 1] &&
        //       item !== pageNum[pageNum.length - 2] &&
        //       item !== pageNum[pageNum.length - 3]
        //     ) {
        //       return <></>
        //     }
        //   }
        // }

        // itemがnowPageより小さい
        if (item < nowPage) {
          // itemの大きさがnowPageの-3以内ではない
          if (item < nowPage - 3) {
            return <></>
          }
        }

        // itemがnuwPageより大きい
        if (item > nowPage) {
          let max = 3

          // nowpageが3以下のとき、差分をmaxに加算する
          if (nowPage <= 3) {
            max += 3 - nowPage + 1
          }

          // itemの大きさがnowPageの+3以内ではない
          if (item > nowPage + max) {
            return <></>
          }
        }

        return (
          <li className={nowPage === item ? class2 : class1} key={index}>
            <a
              href='#'
              className='page-link'
              onClick={(e) => {
                getPageList(item)
                e.preventDefault()
              }}
            >
              {item}
            </a>
          </li>
        )
      })}
      <li className={nowPage === pageNum[pageNum.length - 1] ? class3 : class1}>
        <a
          href='#'
          className='page-link'
          onClick={(e) => {
            getPageList(nowPage + 1)
            e.preventDefault()
          }}
        >
          <i className='next'></i>
        </a>
      </li>
    </ul>
  )

  //   return (
  //     <div className='row border-bottom py-4 px-6 m-0'>
  //       <div className='d-flex justify-content-between px-0'>
  //         {/* page */}
  //         <ul className='pagination'>
  //           <li className={nowPage === pageNum[0] ? class3 : class1}>
  //             <a
  //               href='#'
  //               className='page-link'
  //               onClick={(e) => {
  //                 getPageList(nowPage - 1)
  //                 e.preventDefault()
  //               }}
  //             >
  //               <i className='previous'></i>
  //             </a>
  //           </li>
  //           {pageNum.map((item: any, index: number) => {
  //             return (
  //               <li className={nowPage === item ? class2 : class1} key={index}>
  //                 <a
  //                   href='#'
  //                   className='page-link'
  //                   onClick={(e) => {
  //                     getPageList(item)
  //                     e.preventDefault()
  //                   }}
  //                 >
  //                   {item}
  //                 </a>
  //               </li>
  //             )
  //           })}
  //           <li className={nowPage === pageNum[pageNum.length - 1] ? class3 : class1}>
  //             <a
  //               href='#'
  //               className='page-link'
  //               onClick={(e) => {
  //                 getPageList(nowPage + 1)
  //                 e.preventDefault()
  //               }}
  //             >
  //               <i className='next'></i>
  //             </a>
  //           </li>
  //         </ul>
  //       </div>
  //     </div>
  //   )
}

export {Pagination}
