/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {Error500} from './components/Error500'
import {Error404} from './components/Error404'
import {ErrorsLayout} from './ErrorsLayout'
import { MasterLayout } from 'src/_metronic/layout/MasterLayout'
import {PageTitle} from '@/_metronic/layout/core'
import { HelmetComponent } from 'src/app/modules/helmet'

const ErrorsPage = () => (
  <Routes>
    <Route element={<MasterLayout />}>
      <Route path='404' element={
        <>
          <HelmetComponent title='ページが見つかりません' options={{}} />
          <PageTitle>ページが見つかりません</PageTitle>
          <Error404 />
        </>} />
      <Route path='500' element={
        <>
          <HelmetComponent title='エラーが発生しました' options={{}} />
          <PageTitle>エラーが発生しました</PageTitle>
          <Error500 />
        </>
      } />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export {ErrorsPage}