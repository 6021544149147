import {useEffect, useState} from 'react'
import {useLocation, useParams, useSearchParams, useNavigate} from 'react-router-dom'
import {showSuccessMessage} from '@/app/components/sweetAlert'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {customerRankAdd, customerRankCopy, customerRankUpdate} from 'src/app/modules/api'
import {getCommonDetails} from 'src/app/modules/api'
import {Alert} from 'antd'
import {useAuth} from 'src/app/modules/auth'

const functionOptions: any = [
  {id: 'publish', label: '表示'},
  {id: 'hidden', label: '非表示'},
]

const validationSchema = Yup.object({
  group_name: Yup.string()
    .required('ランク名は必須です。')
    .min(1, '最小8文字')
    .max(255, '255文字以下で入力してください。'),
  status: Yup.string().required('状態必須です。'),
  default: Yup.string().required('基本グループ必須です。'),
  rate: Yup.string()
    .required('掛け率は必須です。')
    .matches(/^[0-9]+$/, '半角数字のみで入力してください。')
    .matches(/^(?:\d|[1-9]\d|100)$/, '掛け率は0-100以内で入力してください。。'),
})
export default function CustomerRankDetail() {
  const navigate = useNavigate()
  const {id} = useParams()
  const currentLocation = useLocation()
  const [getSearchArr] = useSearchParams()
  const searchValue = getSearchArr.getAll('copy')[0]
  const {auth} = useAuth()
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }

  const [formData, setFormData] = useState<any>({
    // group_id: '',
    group_name: '',
    default: 'no',
    status: 'hidden',
    rate: 100,
  })
  const [flg, setflg] = useState<boolean>(false)
  // 表单数据
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const updateData = (fieldsToUpdate: Partial<any>) => {
    const updatedData = {...formik.values, ...fieldsToUpdate}
    formik.setValues(updatedData)
  }

  useEffect(() => {
    initData()
  }, [])

  const initData = async () => {
    console.log('id', id, currentLocation, searchValue)

    if (id == undefined && searchValue == undefined) {
      setflg(true)
    }

    if (id != undefined && searchValue != undefined) {
      setflg(true)
    }

    if (id || searchValue) {
      setIsLoading(true)
      let res: any = await getCommonDetails({
        pkVal: currentLocation.state,
      })
      console.log('getCommonDetails', res)
      setIsLoading(false)
      formik.setValues({
        pkVal: searchValue ? '' : res.pk,
        // group_id: res.group_id,
        group_name: res.group_name,
        default: res.default,
        status: res.status,
        rate: res.rate,
      })
    }
  }

  const showMessage: any = (message: string) => {
    ;<Alert message='Success Tips' type='success' showIcon closable />
  }

  const formik: any = useFormik<any>({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('onSubmit', JSON.stringify(values))
      setSubmitting(true)
      setLoading(false)
      if (id && !searchValue) {
        customerRankUpdate(values, {headers})
          .then((res: any) => {
            setLoading(false)
            showSuccessMessage(res.message)
            navigate(`/customer/rank`)
          })
          .catch((err: any) => {
            setLoading(false)
          })
      } else if (searchValue) {
        customerRankCopy(values, {headers})
          .then((res: any) => {
            setLoading(false)
            showSuccessMessage(res.message)
            navigate(`/customer/rank`)
          })
          .catch((err: any) => {
            setLoading(false)
          })
      } else {
        customerRankAdd(values, {headers})
          .then((res: any) => {
            setLoading(false)
            showSuccessMessage(res.message)
            navigate(`/customer/rank`)
            // navigate(`/customer/rank/${formik.values.group_id}`)
          })
          .catch((err: any) => {
            setLoading(false)
          })
      }
    },
  })

  return (
    <>
      <form className='container-fluid' onSubmit={formik.handleSubmit} noValidate>
        {/* 顧客ランク詳細設定 */}
        <div className='card mb-6 shadow-sm'>
          <div className='d-flex align-items-center card-header'>
            <span className='card-title fw-bold m-0'>顧客ランク詳細設定</span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                {/* 表示グループID */}
                {/* <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label  fs-6'>
                      <span>表示グループID</span>
                    </label>

                    <div className='col-lg-9 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='表示グループID'
                        disabled={id ? true : false}
                        {...formik.getFieldProps('group_id')}
                      />
                      {formik.errors.group_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.group_id}</div>
                        </div>
                      )}
                    </div>
                  </div> */}

                {/* 表示グループ名 */}
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label  fs-6'>
                    <span>ランク名</span>
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <input
                      className='form-control form-control-lg'
                      type='text'
                      placeholder='ランク名'
                      {...formik.getFieldProps('group_name')}
                    />
                    {formik.errors.group_name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.group_name}</div>
                      </div>
                    )}
                  </div>
                </div>

                {/* 基本グループ */}
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label  fs-6'>
                    <span>基本グループ</span>
                  </label>

                  <div className='col-lg-8 align-self-center'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='default'
                      value={formik.values.default}
                      checked={formik.values.default === 'yes'}
                      onChange={(e) => {
                        if (e.target.checked) {
                          formik.values.default = 'yes'
                        } else {
                          formik.values.default = 'no'
                        }
                        updateData(formik.values)
                      }}
                    />
                    <span className='form-check-label' style={{marginLeft: 10}}>
                      このグループを新規顧客の基本グループに設定する。
                    </span>
                    {formik.errors.default && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.default}</div>
                      </div>
                    )}
                  </div>
                </div>
                {/* 状態 */}
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label  fs-6'>
                    <span>状態</span>
                  </label>

                  <div className='col-lg-8 align-self-center'>
                    {functionOptions.map((item: any) => {
                      return (
                        <div className='form-check form-check-inline pe-3' key={item.id}>
                          <input
                            className='form-check-input'
                            type='radio'
                            value={item.id}
                            checked={formik.values.status === item.id}
                            onChange={() => {
                              formik.values.status = item.id
                              updateData(formik.values)
                            }}
                          />
                          <label className='form-check-label'>{item.label}</label>
                        </div>
                      )
                    })}
                    {formik.errors.status && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.status}</div>
                      </div>
                    )}
                  </div>
                </div>
                {/* 掛け率 */}
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label  fs-6'>
                    <span>掛け率</span>
                  </label>

                  <div className='col-lg-9 fv-row row gx-0'>
                    <div className='input-group w-150px'>
                      <input
                        className='form-control form-control-lg'
                        type='number'
                        min={0}
                        max={100}
                        placeholder='掛け率'
                        {...formik.getFieldProps('rate')}
                      />
                      <span className='input-group-text'>%</span>
                      {formik.errors.rate && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.rate}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='w-50 d-flex flex-wrap justify-content-between'>
          <button
            className='btn btn-primary'
            type='button'
            onClick={() => navigate('/customer/rank')}
          >
            戻る
          </button>
          {flg && (
            <button
              className='btn btn btn-primary'
              type='submit'
              data-kt-users-modal-action='submit'
            >
              {!loading && <span className='indicator-label'>登録</span>}
              {loading && (
                <span>
                  お待ちください...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
          {!flg && (
            <button
              className='btn btn btn-primary'
              type='submit'
              data-kt-users-modal-action='submit'
            >
              {!loading && <span className='indicator-label'>更新</span>}
              {loading && (
                <span>
                  お待ちください...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
      </form>
    </>
  )
}
