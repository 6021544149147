import {Fragment, useEffect, useState} from 'react'
import {KTCard, KTSVG} from '@/_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import image from '@/utils/img/SAMPLE.png'
import {ja} from 'date-fns/locale'
import {orderListGet, orderDetailGet, orderDownA, orderDownB} from 'src/app/modules/api'
import {useLayout} from 'src/_metronic/layout/core'
import {useIsWidthSize} from 'src/app/modules/utils'
import {formatNumber, getParameterStore} from 'src/app/modules/utils'
import e from 'express'
import Item from 'antd/es/list/Item'
import {Pagination} from '@/app/components/pagination'

const class1 = 'page-item previous'
const class2 = 'page-item previous active'
const class3 = 'page-item previous disabled'

export default function OrderHistory() {
  const navigate = useNavigate()
  const [keyWords, setKeyWords] = useState<string>('') // 检索
  const [parameters, setParameters] = useState<any>({})
  const {isContentLoading, setContentLoading} = useLayout()

  const initParameters = async () => {
    let res: any = await getParameterStore({
      names: ['/Define/Order/Status', '/Define/Payment/Status', '/Define/Shippingdetails/Status'],
    })

    setParameters(res)
  }

  const [queryParams, setQueryParams] = useState<any>({
    pageNum: 1,
    pageSize: 10,
  }) // 分页参数
  const [data, setData] = useState<any>([]) // 数据列表
  const [showdata, setshowData] = useState<any>([]) // 展示数据列表
  let [selectType, setSelectType] = useState<string>('') // 左上角选择框
  const [nowPage, setNowPage] = useState<any>(1) // 当前页
  const [pageSize, setPageSize] = useState<any>(10) // 每页多少条
  let [page, setPage] = useState<any>([]) // 页码列表
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const handleDateChange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    queryParams.orderDateStart = start
    queryParams.orderDateEnd = end
    if ((start && end) || (!start && !end)) {
      getAllList()
    }
    return
  }

  // 適用
  const apply = () => {
    queryParams.stateVal = selectType
    getAllList()
  }
  const currentPage = (pageNum: any, pageSize: any) => {
    queryParams.pageNum = pageNum
    queryParams.pageSize = pageSize
    getAllList()
  }
  //处理页码
  const changePage = (sumPage: any, size?: number | string) => {
    page = []
    let num = Math.ceil(sumPage / Number(size ? size : pageSize))
    for (let i = 1; i < num + 1; i++) {
      page.push(i)
    }
    let newPage = JSON.parse(JSON.stringify([...page]))

    setPage(newPage)
  }
  // 改变当前显示数量
  const changePageSize = (size: string | number) => {
    queryParams.pageSize = size
    queryParams.pageNum = 1
    setPageSize(size)
    setNowPage(1)
    changePage(data.length, size)
    let newArr = data.slice((1 - 1) * Number(size), 1 * Number(size))
    // setshowData(newArr)
    setData(newArr)
    getAllList()
  }
  // 获取列表数据
  const getAllList = async () => {
    try {
      setContentLoading(true)

      let res: any = await orderListGet(queryParams)
      let arr = res.rows.map((item: any) => {
        item.children = []
        item.id = item.pk

        if (parameters) {
          parameters['/Define/Order/Status']?.map((v: any) => {
            if (item.status == v.value) {
              item.status_name = v.label
              item.status_color = v.color
            }
          })
          parameters['/Define/Payment/Status']?.map((v: any) => {
            if (item.payment_status == v.value) {
              item.payment_status_name = v.label
              item.payment_status_color = v.color
            }
          })
          parameters['/Define/Shippingdetails/Status']?.map((v: any) => {
            if (item.shipment_status == v.value) {
              item.shipment_status_name = v.label
              item.shipment_status_color = v.color
            }
          })
        }
        return item
      })

      changePage(res.total, queryParams.pageSize)
      setQueryParams((prev: any) => {
        return {...prev, total: res.total}
      })
      setData(arr)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    initParameters()
  }, [])

  useEffect(() => {
    if (Object.keys(parameters).length > 0) {
      getAllList()
    }
  }, [parameters])

  useEffect(() => {
    if (data.length > 0) {
      setshowData(data)
    }
    setContentLoading(false)
  }, [data])

  return (
    <>
      {useIsWidthSize('lg') ? (
        <div style={{position: 'relative'}}>
          <div className='d-flex flex-stack mb-4'>
            <div></div>
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              {/* <div className='d-flex align-items-center position-relative'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute ms-6'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  style={{marginRight: 10}}
                  className='form-control w-250px ps-14'
                  placeholder='検索'
                  value={keyWords}
                  onChange={(e) => setKeyWords(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.keyCode === 13) {
                      queryParams.orderManagementId = keyWords
                      getAllList()
                      return
                    }
                  }}
                />
              </div> */}
              <div>
                <DatePicker
                  className='custom-datepicker form-control w-250px ps-10 py-3'
                  showIcon
                  icon='fa-solid fa-calendar-days position-absolute top-50 translate-middle ms-6'
                  dateFormat='yyyy/MM/dd'
                  locale={ja}
                  isClearable
                  onChange={handleDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  placeholderText='日付範囲'
                />
              </div>
            </div>
          </div>

          <KTCard resetSidePaddings={true}>
            <div className='table-responsive '>
              <table
                className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer'
                style={{minWidth: 1000}}
              >
                <thead className='table-light'>
                  <tr className='text-start fs-6 text-uppercase gs-0'>
                    <th id='id'>注文番号</th>
                    <th id='orderData'>注文日時</th>
                    <th id='name'>取引先名</th>
                    <th id='total_amount'>総合計</th>
                    <th id='status'>受注</th>
                    <th id='payment_status'>決済</th>
                    <th id='shipping_status'>出荷</th>
                    <th id='bill'>帳票</th>

                    {/* <th id='reason'>対応状況</th> */}
                  </tr>
                </thead>

                <tbody className=''>
                  {showdata.length > 0 ? (
                    showdata.map((item: any, index: number) => {
                      return (
                        <Fragment key={item.id}>
                          <tr>
                            <td>
                              <a
                                onClick={() => {
                                  navigate(`/orderhistory/${item.id.split('#').pop()}`)
                                }}
                                style={{
                                  textDecorationLine: 'underline',
                                  color: '#009ef7',
                                  cursor: 'pointer',
                                }}
                              >
                                {item.display_id}
                              </a>
                            </td>
                            <td>{moment(item.order_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>{item.company_name}</td>
                            <td className=''>
                              <span className='fw-bold' style={{color: 'red'}}>
                                {formatNumber(item.total_amount)}円
                              </span>
                            </td>
                            <td>
                              <span
                                className={`badge badge-lg py-3 px-4 badge-${item.status_color}`}
                              >
                                {item.status_name}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`badge badge-lg py-3 px-4 badge-${item.payment_status_color}`}
                              >
                                {item.payment_status_name}
                              </span>
                            </td>
                            <td>
                              <span
                                className={`badge badge-lg py-3 px-4 badge-${item.shipment_status_color}`}
                              >
                                {item.shipment_status_name}
                              </span>
                            </td>
                            <td>
                              <div className='d-inline-flex flex-row gap-2'>
                                <a
                                  href='#'
                                  className={`btn btn-sm btn-white btn-outline fs-6 w-100 disabled`}
                                >
                                  領収書
                                </a>
                                <a
                                  href='#'
                                  className={`btn btn-sm btn-white btn-outline fs-6 w-100 disabled`}
                                >
                                  納品書
                                </a>
                              </div>
                            </td>
                          </tr>
                          {item.children.length !== 0 &&
                            item.show &&
                            item.children.map((i: any, idx: number) => {
                              return (
                                <tr key={idx}>
                                  <td style={{width: 50, height: 50}}>
                                    <img
                                      className='w-100'
                                      style={{height: '50px', width: '50px', objectFit: 'cover'}}
                                      src={i.img ? i.img : ''}
                                    />
                                  </td>
                                  <td>{i.product_name}</td>
                                  <td>
                                    <b>
                                      <p>単価</p>
                                    </b>
                                    <p>{i.unit_price}円（税込）</p>
                                  </td>
                                  <td>
                                    <b>
                                      {' '}
                                      <p>数量</p>
                                    </b>
                                    <p>{i.quantity}</p>
                                  </td>
                                  <td>
                                    <b>
                                      {' '}
                                      <p>単位</p>
                                    </b>
                                    <p>{i.unit}</p>
                                  </td>
                                  <td>
                                    <b>
                                      {' '}
                                      <p>合計</p>
                                    </b>
                                    <p>{i.total_amount}円（税込）</p>
                                  </td>
                                  <td></td>
                                  <td>
                                    <p>ステータス</p>
                                    <p>
                                      {i.status === '処理中' && (
                                        <span className={'badge badge-info'}>{i.status}</span>
                                      )}
                                      {i.status === '出荷準備完了' && (
                                        <span className={'badge badge-warning'}>{i.status}</span>
                                      )}
                                      {i.status === '出荷済み' && (
                                        <span className={'badge badge-success'}>{i.status}</span>
                                      )}
                                      {i.status === '保留' && (
                                        <span className={'badge badge-danger'}>{i.status}</span>
                                      )}
                                      {i.status === 'キャンセル' && (
                                        <span className={'badge badge-danger'}>{i.status}</span>
                                      )}
                                    </p>
                                  </td>
                                  <td></td>
                                </tr>
                              )
                            })}
                        </Fragment>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={17}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {isContentLoading ? 'データの取得中' : '注文データはありません'}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>

                <tfoot>
                  <tr>
                    <td colSpan={17}>
                      <select
                        className='form-select '
                        data-control='select2'
                        style={{width: 80}}
                        value={pageSize}
                        onChange={(e) => {
                          changePageSize(e.target.value)
                        }}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </td>

                    {/* page */}
                  </tr>
                </tfoot>
              </table>
              <div className='row'>
                <div className='col-12 d-flex justify-content-center'>
                  <Pagination
                    currentPage={currentPage}
                    page={queryParams.pageNum}
                    pageSize={queryParams.pageSize}
                    total={queryParams.total}
                  />
                </div>
              </div>
            </div>
          </KTCard>
        </div>
      ) : (
        <>
          <SearchMenuSP
            parameters={parameters}
            selectType={selectType}
            setSelectType={setSelectType}
            apply={apply}
          />
          <OrderListSP data={showdata} />
          <div className='row'></div>
        </>
      )}
    </>
  )
}

const SearchMenuSP: React.FC<any> = ({parameters, selectType, setSelectType, apply}: any) => {
  return <></>
}

const OrderListSP: React.FC<any> = ({data}) => {
  const navigate = useNavigate()

  return (
    <>
      {data.length > 0 ? (
        data.map((item: any, index: number) => {
          return (
            <div className='row mb-6'>
              <div className='col-12 fs-7 mb-2'>
                注文日 {moment(item.order_date).format('YYYY-MM-DD HH:mm:ss')}
              </div>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row mb-4'>
                      <div className='col-4 fs-2x d-flex align-items-center px-1'>
                        <span
                          className={`badge badge-${item.status_color} w-100 justify-content-center py-3`}
                        >
                          {item.status_name}
                        </span>
                      </div>
                      <div className='col-4 fs-2x d-flex align-items-center px-1'>
                        <span
                          className={`badge badge-${item.payment_status_color} w-100 justify-content-center py-3`}
                        >
                          {item.payment_status_name}
                        </span>
                      </div>
                      <div className='col-4 fs-2x d-flex align-items-center px-1'>
                        <span
                          className={`badge badge-${item.shipment_status_color} w-100 justify-content-center py-3`}
                        >
                          {item.shipment_status_name}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-6 fs-7 text-center border-end'>
                        仕入れ先 <br />
                        {item.company_name}
                      </div>
                      <div className='col-6 fs-7 text-center'>
                        注文番号
                        <br />
                        {item.display_id}
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-12 fs-7 text-center'>
                        総合計
                        <br />
                        <span className='fs-5 fw-bold' style={{color: 'red'}}>
                          {formatNumber(item.total_amount)}円
                        </span>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12 d-flex justify-content-center'>
                        <a
                          href='#'
                          className='btn btn-secondary btn-color-primary w-75'
                          onClick={(e) => {
                            navigate(`/orderhistory/${item.id.split('#').pop()}`)
                            e.preventDefault()
                          }}
                        >
                          注文明細
                        </a>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-6 d-flex justify-content-center'>
                        <a href='#' className='btn btn-white btn-outline fs-6 w-100 disabled'>
                          領収書
                        </a>
                      </div>
                      <div className='col-6 d-flex justify-content-center'>
                        <a href='#' className='btn btn-white btn-outline fs-6 w-100 disabled'>
                          納品書
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <div className='row'>
          <div className='col-12 d-flex text-center w-100 align-content-center justify-content-center'>
            注文データはありません
          </div>
        </div>
      )}
    </>
  )
}
