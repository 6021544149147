import {FC, useState, useEffect, Key, Fragment} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {
  buyerProductDataGet,
  buyerProductVariationGet,
  cartDataUpdate,
  productFavoritesGet,
  productFavoritesAdd,
  productFavoritesDelete,
  parameterStoreGet,
} from 'src/app/modules/api'
import {Skeleton} from 'antd'
import {useAuth} from 'src/app/modules/auth'
import {useAlert} from 'src/_metronic/layout/core'
import {calculatePrice} from './utils'
import {formatNumber} from 'src/app/modules/utils'
import {PageLink, PageTitle} from 'src/_metronic/layout/core'
import {HelmetComponent} from 'src/app/modules/helmet'

const OrderingProductDetail: FC = () => {
  const {auth} = useAuth()
  const {setMessage} = useAlert()

  const navigate = useNavigate()
  const location = useLocation()
  const itemGrade = location.state.grade
  console.log('itemGrade', itemGrade)
  const [pk, setpk] = useState<any>('')
  const [basicInformation, setBasicInformation] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态

  const [commodityOfficial, setCommodityOfficial] = useState<any>([])
  const [showAllRecommendations, setShowAllRecommendations] = useState(false)
  const [s3Url, setS3Url] = useState('')

  const getParameterStore = async () => {
    try {
      const res: any = await parameterStoreGet({names: ['/Define/Common']}, {})
      setS3Url(JSON.parse(res['/Define/Common'])?.S3URL)
    } catch (error) {
      console.error('Failed to fetch parameter store', error)
    }
  }

  let baseBreadCrumbs: Array<PageLink> = [
    {
      title: '商品一覧',
      path: '/buy/product',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '-',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  // 根据状态决定要显示的推荐列表数量
  const recommendationsToShow = showAllRecommendations ? basicInformation.recommendList.length : 4

  // 製品詳細の表示
  const viewProductDetails = (item: {pk: any; id: string}) => {
    navigate(`/buy/product/${item.id}`, {
      state: {pk: item.pk},
    })
  }

  // カートの追加
  const addShoppingCart = async (item: any) => {
    console.log(item)
    try {
      const resp: any = await cartDataUpdate({
        buyer_id: auth.group_id,
        product_id: item.product_id,
        quantity: item.quantity.toString(),
        variation_id: item.pk,
      })
      console.log('resp', resp)
      if (resp.message == 'success') {
        setMessage({type: 'info', message: 'カートに追加しました'})
      }
    } catch (error: any) {
      if (error && error?.message == 'out of stock') {
        setMessage({type: 'error', message: '在庫が不足しています'})
      }
    }
  }

  // データ減少
  const itemNumberReduce = (item: {quantity: number; minimum_order_quantity: number}) => {
    if (
      item.quantity == 1 ||
      (item.minimum_order_quantity && Number(item.quantity) <= Number(item.minimum_order_quantity))
    ) {
      return
    }

    item.quantity--
    calculatePrice(item)
    let resp = JSON.parse(JSON.stringify(basicInformation))

    setBasicInformation(resp)
  }

  // データ増加
  const itemNumberAdd = (item: {quantity: number; maximum_order_quantity: number}) => {
    if (
      item.maximum_order_quantity &&
      Number(item.quantity) >= Number(item.maximum_order_quantity)
    ) {
      return
    }

    item.quantity++
    calculatePrice(item)

    let resp = JSON.parse(JSON.stringify(basicInformation))
    setBasicInformation(resp)
  }
  const clickOnFavorites = async (item: {isCollect: any; pk: any}) => {
    if (item.isCollect) {
      // ら削除
      let isCollect = (await productFavoritesDelete({
        // update_user: supplier.update_user,
        // user_id: supplier.update_user,
        variation_id: item.pk,
        product_id: basicInformation.pk,
        // buyer_id: auth.group_id,
      })) as {message: string}
      console.log(isCollect)

      if (isCollect.message == 'success') {
        setMessage({type: 'info', message: '「いつもの商品」を解除しました'})
        item.isCollect = false
        let resp = JSON.parse(JSON.stringify(basicInformation))
        setBasicInformation(resp)
      }
    } else {
      let isCollect = (await productFavoritesAdd(
        {
          variation_id: item.pk,
          product_id: basicInformation.pk,
        },
        {}
      )) as {message: string}
      console.log(isCollect)

      if (isCollect.message == 'success') {
        setMessage({type: 'info', message: '「いつもの商品」に追加しました'})
        item.isCollect = true
        let resp = JSON.parse(JSON.stringify(basicInformation))
        setBasicInformation(resp)
      }
    }
    // getInitialInformation()
  }

  const [loading, setLoading] = useState<boolean>(false)
  const getInitialInformation = async () => {
    setLoading(true)
    const itemPK = location.state.pk
    setpk(itemPK)
    // 商品情報の取得
    let resp: any = await buyerProductDataGet({pk: itemPK})
    // if (resp?.categories) {
    // resp.categories = JSON.parse(resp?.categories)
    // }
    baseBreadCrumbs.push({
      title: resp.product_name,
      path: '',
      isSeparator: false,
      isActive: true,
    })

    if (resp?.labelList) {
      resp.labelList = JSON.parse(resp?.labelList)
    }
    setBasicInformation(resp)
    console.log('getInitialInformation', resp)
    // 商品仕様の取得
    let specificationsResp: any = await buyerProductVariationGet({
      product_pk: itemPK,
    })

    const res: any = await parameterStoreGet({names: ['/Define/Product/Units']}, {})
    const parameters = JSON.parse(res['/Define/Product/Units'])
    console.log('parameters', res, parameters)

    // favoriteList : { total: num, rows: []}
    let favoriteList = (await productFavoritesGet({pageNum: 1, pageSize: 999999})) as {
      total: number
      rows: any
    }
    specificationsResp.map((el: {isCollect: any; pk: any; quantity: number}) => {
      el.quantity = 1
      el.isCollect =
        favoriteList.total > 0 &&
        favoriteList.rows.find((e: any) => e.pk == el.pk && e.product_id == itemPK)
          ? true
          : false
    })

    specificationsResp = specificationsResp.map((item: any) => {
      if (item.customer_rank_specific_pricing) {
        item.customer_rank_specific_pricing = JSON.parse(item.customer_rank_specific_pricing)
      }
      let itemId: any = ''
      if (itemGrade && item.customer_rank_specific_pricing.length > 0) {
        itemId = item.customer_rank_specific_pricing.findIndex((e: any) => e.id == itemGrade)
      }
      if (
        itemId >= 0 &&
        item.customer_rank_specific_pricing.length > 0 &&
        item.customer_rank_specific_pricing[itemId]?.price
      ) {
        item.unit_price = item.customer_rank_specific_pricing[itemId]?.price
      }
      if (
        itemId >= 0 &&
        item.customer_rank_specific_pricing.length > 0 &&
        item.customer_rank_specific_pricing[itemId]?.price_range_min
      ) {
        item.price_range_min = item.customer_rank_specific_pricing[itemId]?.price_range_min
      }
      if (
        itemId >= 0 &&
        item.customer_rank_specific_pricing.length > 0 &&
        item.customer_rank_specific_pricing[itemId]?.price_range_max
      ) {
        item.price_range_max = item.customer_rank_specific_pricing[itemId]?.price_range_max
      }
      item.default_price = item.unit_price
      if (Number(item.minimum_order_quantity) > 0) {
        item.quantity = item.minimum_order_quantity
      }
      if (parameters && parameters.length > 0) {
        item.unit = parameters.find((e: any) => item.unit == e.id)?.label
      }
      item.calc_price = item.unit_price
      item.calc_price_min = item.price_range_min
      item.calc_price_max = item.price_range_max
      if (item.quantity_discount) item.quantity_discount = JSON.parse(item.quantity_discount)
      calculatePrice(item)
      return item
    })

    setCommodityOfficial(specificationsResp)
    setLoading(false)
    console.log('specificationsResp', specificationsResp)
  }

  useEffect(() => {
    if (location.state && location.state.pk) {
      getInitialInformation()
    }
    getParameterStore()
  }, [location.state])

  return (
    <>
      {!loading && (
        <>
          <PageTitle breadcrumbs={baseBreadCrumbs}>{basicInformation.product_name}</PageTitle>
          <HelmetComponent title={basicInformation.product_name} options={{}} />
        </>
      )}
      <div className='container-xxl px-0'>
        <div className='card mb-12'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-12 col-md-12 col-lg-6 mb-6 mb-sm-6 mb-md-6'>
                {loading ? (
                  <Skeleton.Image active style={{minHeight: '500px'}} className='w-100' />
                ) : (
                  <img
                    className='w-100 mw-100'
                    src={
                      basicInformation.main_image
                        ? s3Url +
                          '/' +
                          encodeURIComponent(
                            basicInformation.supplier_id.split('#Suppliers#')?.[1]
                          ) +
                          basicInformation.main_image
                        : toAbsoluteUrl('/media/products/sample.png')
                    }
                  />
                )}
              </div>
              <div className='col-md-12 col-lg-6 col-md-12'>
                <div>
                  <div className='mb-6' style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                      <h1 className='d-flex flex-column gap-2'>
                        {loading ? (
                          <>
                            <Skeleton.Button active style={{width: '100px'}} />
                            <Skeleton.Button active style={{width: '150px'}} />
                            <Skeleton.Button active style={{width: '180px'}} />
                          </>
                        ) : (
                          <>
                            <span className='fs-6'>{basicInformation.shop_name}</span>
                            <span className='fs-1'>{basicInformation.product_name}</span>
                            <span className='fs-7 fw-normal'>
                              商品番号：{basicInformation.product_id}
                            </span>
                          </>
                        )}
                      </h1>
                      <div>
                        {loading && basicInformation.labelList ? <Skeleton.Button active /> : ''}
                        {!loading &&
                          basicInformation.labelList &&
                          basicInformation.labelList.map((value: any, idx: any) => (
                            <span className='badge badge-light-dark py-3 px-10 me-3 my-2' key={idx}>
                              {value.name}
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className='mb-10'>
                    {loading ? <Skeleton.Button active /> : <h1 className='fs-4'>価格</h1>}

                    {loading ? (
                      <div className='row d-flex align-items-center my-4 p-2 border border-dashed'>
                        <div className='col col-4 d-flex align-middle flex-column'>
                          <Skeleton.Button
                            active
                            style={{width: '30px'}}
                            className='fw-semibold fs-6 mb-2'
                          />
                          <Skeleton.Button
                            active
                            style={{width: '60px'}}
                            className='fw-semibold fs-6 '
                          />
                        </div>
                        <div className='col col-6 flex-grow-1 d-flex align-items-center pe-0'>
                          <Skeleton.Input active style={{width: '60px'}} />
                          <Skeleton.Button active style={{width: '30px', marginLeft: '5px'}} />
                          <Skeleton.Button active style={{width: '30px', marginLeft: '5px'}} />
                          <span className='d-flex align-items-center'>
                            <em className='ki-duotone ki-plus-square fs-2x'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                            </em>
                            <em className='ki-duotone ki-minus-square fs-2x'>
                              <span className='path1' />
                              <span className='path2' />
                              <span className='path3' />
                            </em>
                          </span>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {!loading &&
                      commodityOfficial.length !== 0 &&
                      commodityOfficial.map((item: any, index: number) => {
                        return (
                          <div
                            className='row d-flex align-items-center my-4 p-2 border border-dashed'
                            key={index}
                          >
                            <div className='col col-6 d-flex align-middle flex-column'>
                              {loading ? (
                                <>
                                  <Skeleton.Button
                                    active
                                    style={{width: '30px'}}
                                    className='fw-semibold fs-6 mb-2'
                                  />
                                  <Skeleton.Button
                                    active
                                    style={{width: '60px'}}
                                    className='fw-semibold fs-6 '
                                  />
                                </>
                              ) : (
                                <>
                                  <span className='fw-semibold fs-6 '>
                                    {item.variation_product_name}
                                  </span>
                                  <strong className='fs-6' style={{color: 'red'}}>
                                    {item.visible_price === 'false' && <span>価格非表示</span>}
                                    {item.visible_price !== 'false' &&
                                      item.price_range === 'unit_price' && (
                                        <span>{formatNumber(item.unit_price)}円</span>
                                      )}
                                    {item.visible_price !== 'false' &&
                                      item.price_range === 'price_range' && (
                                        <span>
                                          {formatNumber(item.price_range_min)}~
                                          {formatNumber(item.price_range_max)}円
                                        </span>
                                      )}
                                  </strong>
                                </>
                              )}
                            </div>
                            <div className='col col-6 flex-grow-1 d-flex justify-content-end align-items-center pe-0'>
                              {loading ? (
                                <>
                                  <Skeleton.Input active style={{width: '60px'}} />
                                  <Skeleton.Button
                                    active
                                    style={{
                                      width: '30px',
                                      marginLeft: '5px',
                                    }}
                                  />
                                  <Skeleton.Button
                                    active
                                    style={{
                                      width: '30px',
                                      marginLeft: '5px',
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <div>
                                    {item.stock_display == 'publish' && (
                                      <div className='d-flex justify-content-center fs-8'>
                                        {item.stock_display_method == 'publish' && (
                                          <span>
                                            {item.unlimited_stock_quantity == 'yes'
                                              ? '在庫有り'
                                              : formatNumber(item.stock_quantity) || 0}{' '}
                                          </span>
                                        )}
                                        {item.stock_display_method == 'hidden' && (
                                          <div>
                                            <span>
                                              {Number(item.stock_quantity) >
                                                Number(item.low_stock_threshold) && '在庫：〇'}
                                            </span>
                                            <span>
                                              {Number(item.stock_quantity) <=
                                                Number(item.low_stock_threshold) &&
                                                Number(item.stock_quantity) != 0 &&
                                                '在庫：△'}
                                            </span>
                                            <span>
                                              {Number(item.stock_quantity) <= 0 && '在庫：×'}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                    <div className='d-flex align-items-center'>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px my-5'
                                      >
                                        <i
                                          className='bi bi-dash-lg fs-4 text-black'
                                          data-kt-docs-datatable-subtable='expand_row'
                                          onClick={() => {
                                            itemNumberReduce(item)
                                          }}
                                        ></i>
                                      </button>
                                      <span style={{margin: '0px 10px'}}>{item.quantity}</span>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px my-5'
                                      >
                                        <i
                                          className='bi bi-plus-lg fs-4 text-black'
                                          data-kt-docs-datatable-subtable='expand_row'
                                          onClick={() => {
                                            itemNumberAdd(item)
                                          }}
                                        ></i>
                                      </button>
                                    </div>
                                    {item.number_per_case && (
                                      <div className='d-flex justify-content-center fs-8'>
                                        {formatNumber(item.number_per_case)}
                                        {item.unit}
                                      </div>
                                    )}
                                  </div>

                                  <a
                                    href='#'
                                    className='btn btn-white btn-active-light'
                                    style={{
                                      float: 'left',
                                      width: 30,
                                      height: 40,
                                      border: '1px solid',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginLeft: '20px',
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      addShoppingCart(item)
                                    }}
                                  >
                                    <i className='bi bi-bag p-0'></i>
                                  </a>
                                  <a
                                    href='#'
                                    className={
                                      item.isCollect
                                        ? 'btn btn-white btn-warning'
                                        : 'btn btn-white btn-active-warning'
                                    }
                                    style={{
                                      float: 'left',
                                      width: 30,
                                      height: 40,
                                      border: '1px solid',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginLeft: '10px',
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      clickOnFavorites(item)
                                    }}
                                  >
                                    <i className='bi bi-heart p-0'></i>
                                  </a>
                                </>
                              )}

                              <span className='d-flex align-items-center'>
                                <em className='ki-duotone ki-plus-square fs-2x'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                </em>
                                <em className='ki-duotone ki-minus-square fs-2x'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                </em>
                              </span>
                            </div>
                          </div>
                        )
                      })}
                  </div>

                  <div className='mb-10'>
                    {loading ? (
                      <>
                        <Skeleton.Button active style={{width: '30px'}} className='mb-3' />
                        <Skeleton active />
                      </>
                    ) : (
                      <>
                        <h1 className='fs-4'>商品説明</h1>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              (basicInformation.upper_free_space || '') +
                              (basicInformation.middle_free_space || '') +
                              (basicInformation.lower_free_space || ''),
                          }}
                        />
                      </>
                    )}
                  </div>

                  <div className='mb-6'>
                    {loading ? (
                      <>
                        <Skeleton.Button active style={{width: '30px'}} className='mb-3' />
                        <Skeleton active />
                      </>
                    ) : (
                      <>
                        <h1 className='fs-4'>商品スペック</h1>
                        <div className='table-responsive'>
                          <table className='table'>
                            <thead>
                              <tr className='fw-bold fs-6 text-center border-0 bg-light'>
                                <th>項目</th>
                                <th>値</th>
                              </tr>
                            </thead>
                            <tbody className='border-bottom border-dashed'>
                              {basicInformation.productCustomFields &&
                                basicInformation.productCustomFields.map(
                                  (item: any, index: number) => (
                                    <Fragment key={index}>
                                      {item.status != 'disabled' && (
                                        <tr className='fw-semibold fs-6 ' key={index}>
                                          <td className='min-w-60px text-center'>{item.name}</td>
                                          <td className='text-center'>
                                            {
                                              JSON.parse(
                                                basicInformation.product_custom_field
                                              )?.find((k: any) => k.id == item.id)?.value
                                            }
                                          </td>
                                        </tr>
                                      )}
                                    </Fragment>
                                  )
                                )}
                            </tbody>
                          </table>
                          <table className='table'>
                            <tbody className='border-bottom border-dashed'>
                              <tr className='fw-semibold fs-6 '>
                                <td>商品カテゴリー</td>
                                <td>
                                  {/* <p>商品コード:    {formData.product_id}</p> */}
                                  {basicInformation.categories?.length > 0 &&
                                    basicInformation.categories.map((item: any, index: number) => {
                                      return (
                                        <div>
                                          {item[0] && item[0]}
                                          {item[1] && ' / ' + item[1]}
                                          {item[2] && ' / ' + item[2]}
                                        </div>
                                      )
                                    })}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className='mb-10'>
                  {loading ? (
                    <>
                      <Skeleton.Button active style={{width: '30px'}} className='mb-3' />
                      <Skeleton active />
                    </>
                  ) : (
                    <>
                      <h1 className='fs-4'>注意事項</h1>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: basicInformation.notes || '',
                        }}
                      ></div>
                    </>
                  )}
                </div>
                <div className='mb-10'>
                  <h1 className='fs-4'>配送グループ</h1> {basicInformation.delivery_master_name}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='card mb-12'>
          <div className='card-header border-0 py-3 align-items-center bg-light'>
            <div className='col col-6'>
              <h1 className='fs-4 mb-0'>こちらの商品もおすすめ</h1>
            </div>
            <div className='col col-6 d-flex justify-content-end gap-4 px-0'></div>
          </div>
          <div className='card-body'>
            <div className='row'>
              {basicInformation.recommendList && basicInformation.recommendList.length > 0 ? (
                basicInformation.recommendList
                  .slice(0, recommendationsToShow)
                  .map((item: any, index: Key | null | undefined) => {
                    return (
                      <div
                        key={index}
                        className='col-6 col-lg-3 col-xl-3 col-xxl-3 mb-4'
                        onClick={() => {
                          viewProductDetails(item)
                        }}
                      >
                        <div className='mb-4 position-relative'>
                          <img
                            className='w-100'
                            src={
                              item.main_image
                                ? s3Url +
                                  '/' +
                                  encodeURIComponent(item.supplier_id.split('#Suppliers#')?.[1]) +
                                  item.main_image
                                : toAbsoluteUrl('/media/products/sample.png')
                            }
                          />
                        </div>
                        <div>
                          <div>
                            <p className='fs-7 mb-0'>{item.shop_name}</p>
                            <h1 className='fs-3'>{item.product_name}</h1>
                            <p className='fs-6'>{item.catch_copy}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })
              ) : (
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-6 col-lg-2'>
            <button className='btn btn-dark' type='button' onClick={() => navigate(-1)}>
              戻る
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderingProductDetail
