import {KTSVG} from '@/_metronic/helpers'

type SearchProps = {
  onKeywordSearch: (keyword: string) => void
  keyword?: string
}
export default function SearchFilter(props: SearchProps) {
  const {onKeywordSearch, keyword} = props
  return (
    <div className='d-flex align-items-center position-relative my-1'>
      <KTSVG
        path='/media/icons/duotune/general/gen021.svg'
        className='svg-icon-1 position-absolute ms-6'
      />
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='form-control w-250px ps-14'
        placeholder='検索'
        value={keyword}
        onChange={(e) => onKeywordSearch(e.target.value)}
      />
    </div>
  )
}
