import {useState, useEffect, SetStateAction} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useNavigate, useLocation} from 'react-router-dom'

import {CKEditor} from '@ckeditor/ckeditor5-react'
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {ja} from 'date-fns/locale'

export default function NewsSetting() {
  const navigate = useNavigate()
  const location = useLocation()

  const [distinguishOptions, setDistinguishOptions] = useState<any>([
    {id: '1', label: 'お知らせ'},
    {id: '2', label: '障害'},
    {id: '3', label: '復旧'},
    {id: '4', label: 'バージョンアップ'},
    {id: '5', label: 'リリース'},
    {id: '6', label: 'メンテナンス'},
  ])

  const outputObjectOptions: any[] = [
    {id: '1', label: '利用者'},
    {id: '2', label: '加盟店'},
  ]

  const targetSetUseOptions: targetSetUseOptions[] = [
    {id: '1', label: '基本項目'},
    {id: '2', label: 'バリエーション項目'},
  ]

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('タイトル必須です。'),
    distinguish: Yup.string().required('区分必須です。'),
    outputObject: Yup.string().required('出力対象必須です。'),
    startTime: Yup.string().required('掲載日時必須です。'),
    pleaseEnterTheText: Yup.string().required('本文必須です。'),
  })
  const [userFormData, setuserFormData] = useState<any>({
    title: '',
    distinguish: '',
    outputObject: '',
    startTime: '',
    pleaseEnterTheText: '',
  })

  const [isInputVisible, setIsInputVisible] = useState(false) // 控制输入框的显示和隐藏
  const [newDistinguish, setNewDistinguish] = useState('') // 存储用户输入的值
  const handleAddDistinguish = () => {
    setIsInputVisible(true) // 显示输入框
  }

  const handleDistinguishInputChange = (event: {target: {value: SetStateAction<string>}}) => {
    setNewDistinguish(event.target.value) // 存储用户输入的值
  }

  const handleDistinguishInputEnter = (event: {key: string}) => {
    if (newDistinguish == '' || newDistinguish == null) {
      setNewDistinguish('') // 清空输入框
      setIsInputVisible(false) // 隐藏输入框
      return
    }
    if (event.key === 'Enter') {
      // 判断用户是否按下了回车键

      let idNumber: any = distinguishOptions[distinguishOptions.length - 1].id

      const newId = (idNumber + 1).toString() // 自动生成的 ID 值
      const newOption = {id: newId, label: newDistinguish} // 构造新的选项对象
      setNewDistinguish('') // 清空输入框
      setIsInputVisible(false) // 隐藏输入框
      setDistinguishOptions([...distinguishOptions, newOption]) // 添加新选项到 distinguishOptions 数组中
    }
  }

  const formik: any = useFormik<any>({
    initialValues: userFormData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
    },
  })

  useEffect(() => {
    if (location.state?.item) {
      userFormData.tradeName = location.state.item.name
      userFormData.productManagementDesignation = location.state.item.id
      userFormData.darkMarketSetting = location.state.item.status
      userFormData.targetSetUse = location.state.item.basicVariation
      let newuserFormData = {...userFormData}
      setuserFormData(newuserFormData)
    }
  }, [])

  return (
    <div className='container-fluid'>
      <form className='my-3' onSubmit={formik.handleSubmit}>
        <div className='card mb-5'>
          <div className='card-body'>
            <h4 className='card-title'>連絡事項</h4>

            {/* タイトル */}
            <div className='row mb-6'>
              <div className='col-lg-3 col-form-label'>
                <label className='col-form-label required'>タイトル</label>
              </div>
              <div className='col-lg-9 align-self-center'>
                <input
                  className='form-control'
                  type='text'
                  placeholder='タイトルを入力してください。'
                  {...formik.getFieldProps('title')}
                  value={formik.values.title}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
                )}
              </div>
            </div>

            {/* 区分 */}
            <div className='row mb-6'>
              <div className='col-lg-3 col-form-label'>
                <label className='col-form-label required'>区分</label>
              </div>
              <div className='col-lg-9 align-self-center'>
                <div className='gx-0'>
                  <div className='col'>
                    <select
                      className='form-select finput'
                      data-control='select2'
                      placeholder='選択してください'
                      {...formik.getFieldProps('distinguish')}
                      style={{
                        borderTopRightRadius: '0.475rem',
                        borderBottomRightRadius: '0.475rem',
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                      }}
                    >
                      <option value=''></option>
                      {distinguishOptions?.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.label}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.distinguish && formik.errors.distinguish && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.distinguish}</div>
                      </div>
                    )}
                  </div>

                  <div className='col my-3'>
                    {isInputVisible ? (
                      <input
                        type='text'
                        className='form-control finput'
                        placeholder='Enter a new category...'
                        onChange={handleDistinguishInputChange}
                        onKeyUp={handleDistinguishInputEnter}
                        value={newDistinguish}
                        autoFocus
                      />
                    ) : (
                      <button
                        className='btn btn-primary'
                        type='button'
                        onClick={handleAddDistinguish}
                      >
                        + カテゴリーを追加する
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* 出力対象 */}
            <div className='row mb-6'>
              <div className='col-lg-3 col-form-label'>
                <label className='col-form-label required'>出力対象</label>
              </div>
              <div className='col-lg-9 align-self-center'>
                <div className='gx-0'>
                  <div className='col'>
                    <select
                      className='form-select finput'
                      data-control='select2'
                      placeholder='選択してください'
                      {...formik.getFieldProps('outputObject')}
                      style={{
                        borderTopRightRadius: '0.475rem',
                        borderBottomRightRadius: '0.475rem',
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                      }}
                    >
                      <option value=''></option>
                      {outputObjectOptions?.map((item: any, index: number) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.label}
                          </option>
                        )
                      })}
                    </select>
                    {formik.touched.outputObject && formik.errors.outputObject && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.outputObject}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* 掲載日時 */}
            <div className='row mb-6'>
              <div className='col-lg-3 col-form-label'>
                <label className='col-form-label required'>掲載日時</label>
              </div>
              <div className='col-lg-9 align-self-center'>
                <DatePicker
                  className='custom-datepicker form-control ps-10 py-3'
                  showIcon
                  icon='fa-solid fa-calendar-days position-absolute top-50 translate-middle ms-6 fs-5'
                  dateFormat='yyyy/MM/dd HH:mm:ss'
                  timeFormat='HH:mm'
                  locale='ja'
                  selected={formik.values.startTime}
                  onChange={(date) => formik.setFieldValue('startTime', date)}
                  placeholderText='日付と時刻を選択してください'
                  showTimeSelect
                  timeIntervals={15}
                  timeCaption='時間'
                />
                {!formik.values.startTime && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{'掲載日時必須です。'}</div>
                  </div>
                )}
              </div>
            </div>

            {/* 本文 */}
            <div className='row'>
              <div className='col'>
                <div className='row mb-6'>
                  <div className='col-lg-3 col-form-label'>
                    <label className='col-form-label required'>本文</label>
                  </div>
                  <div className='col-lg-9 align-self-center'>
                    <textarea
                      placeholder='本文を入力してください。'
                      className='form-control'
                      rows={5}
                      {...formik.getFieldProps('pleaseEnterTheText')}
                      value={formik.values.pleaseEnterTheText}
                    ></textarea>
                    {formik.touched.pleaseEnterTheText && formik.errors.pleaseEnterTheText && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.pleaseEnterTheText}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* </form> */}
          </div>
        </div>

        {/* 按键组 */}

        <div
          className='row mb-6'
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <button
            type='button'
            className='btn btn-primary w-150px my-3'
            onClick={() => {
              navigate(-1)
            }}
          >
            プレビュー
          </button>
          <button
            type='button'
            className='btn btn-primary w-100px my-3 ms-10'
            onClick={() => {
              navigate(-1)
            }}
          >
            {location.state?.item ? '更新' : '登録'}
          </button>
        </div>
      </form>
    </div>
  )
}
