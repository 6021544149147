import {getServer, postServer, deleteServer} from 'src/service/http'

// 受注CSVテンプレート一覧取得
export function tempLateList(param: object, headers: object = {}) {
  return getServer(`/order-csv-template-list-get`, {...param}, {...headers})
}

// 受注CSVテンプレート新規登録
export function templateDdd(param: object, headers: object = {}) {
  // console.log(headers,"headersheadersheaders");

  return postServer(`/order-csv-template-add`, {...param}, {...headers})
}

// 受注CSVテンプレート情報更新
export function templateUpdate(param: object, headers: object = {}) {
  return postServer(`/order-csv-template-update`, {...param}, {...headers})
}

// 受注CSVテンプレート削除
export function deleteTemplate(id: any) {
  return deleteServer('/order-csv-template-delete', id)
}
// order-csv-template-info-get
// 受注CSVテンプレート详细
export function templateDataGet(param: object, headers: object = {}) {
  return getServer(`/order-csv-template-info-get`, {pkVal: param}, {...headers})
}

export function orderCsvTemplateListAllGet() {
  return getServer(`/order-csv-template-listAll-get`, {})
}
