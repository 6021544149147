import { useFormik } from 'formik'
import { Key, ReactChild, ReactFragment, ReactPortal, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useLayout } from 'src/_metronic/layout/core'
import * as Yup from 'yup'
import {
  showErrorMessage,
  showWarningMessage,
  showSuccessMessage,
} from '@/app/components/sweetAlert'
import { mailSettingGet, mailSettingUpdate, parameterStoreGet } from 'src/app/modules/api';

const validationSchema = Yup.object().shape({
  from_address: Yup.string().required('送信元メールアドレス必須です。'),
  from_name: Yup.string().required('差出人名必須です。'),
  header_contents: Yup.string().required('共通 header必須です。'),
  footer_contents: Yup.string().required('共通 footer必須です。'),
})
export default function NotificationDetail() {
  const {isContentLoading, setContentLoading} = useLayout()
  const navigate = useNavigate()
  const [mailDefaultCommonData, setMailDefaultCommonData] = useState<any>()
  const [formData, setFormData] = useState<any>({
    from_address: '',
    from_name: '',
    header_contents: '',
    footer_contents: '',
  })

  const formik: any = useFormik<any>({
    initialValues: formData as any,
    validationSchema: validationSchema,
    onSubmit: async (values: any, { setSubmitting }: any) => {
      setContentLoading(true)
      console.log('values', values)
      // setSubmitting(true)を呼び出すと、フォームは「送信中」状態になります。
      // この状態では、通常、送信ボタンを無効化して、ユーザーが同じフォームを複数回送信するのを防ぎます。
      // 送信処理が完了した後、setSubmitting(false)を呼び出して、「送信中」状態を解除します。
      setSubmitting(true)
      mailSettingUpdate(values).then((res: any) => {
        showSuccessMessage(res.message)
        navigate('/notification')
        setSubmitting(false)
        setContentLoading(false)
      }).catch((err: any) => {
        setSubmitting(false)
        setContentLoading(false)
      })
    },
  })

  const updateData = (fieldsToUpdate: Partial<userFormDataType>) => {
    const updatedData = { ...formData, ...fieldsToUpdate }
    if( !updatedData?.from_name) updatedData.from_name = mailDefaultCommonData?.SUPPLIER_FROM_NAME
    if( !updatedData?.header_contents ) updatedData.header_contents = mailDefaultCommonData?.SUPPLIER_HEADER
    if( !updatedData?.footer_contents ) updatedData.footer_contents = mailDefaultCommonData?.SUPPLIER_FOOTER
    formik.setValues(updatedData)
  }

  const getParameterStore = async () => {
    try {
      let data: any = await parameterStoreGet({ names: ['/Define/Mail/Common'] }, {})
      setMailDefaultCommonData(JSON.parse(data['/Define/Mail/Common']))
    } catch (error) {
      console.error('Failed to fetch parameter store', error)
    }
  }

  const getMailSetting = async () => {
    const res: any = await mailSettingGet({})
    updateData(res)
  }

  useEffect(() => {
    getMailSetting()
  }, [mailDefaultCommonData])

  useEffect(() => {
    getParameterStore()
  }, [])

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* メールシステム設定 */}
      <div className='card my-10 shadow-sm'>
        <div className='d-flex align-items-center card-header'>
          <span className='card-title fw-bold m-0'>メールシステム設定</span>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col'>
              {/* 自動返信設定 */}
              <div className='row mb-2'>

                <div className='col-lg-9 align-self-center'>
                </div>
              </div>

              {/* 送信元メールアドレス */}
              <div className='row mb-6'>
                <div className='col-lg-3 col-form-label'>
                  <label className='col-form-label required'>送信元メールアドレス</label>
                </div>
                <div className='col-lg-9 align-self-center'>
                  <input
                    className='form-control'
                    type='text'
                    disabled
                    placeholder='送信元メールアドレス'
                    {...formik.getFieldProps('from_address')}
                    value={formik.values.from_address}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.from_address && formik.errors.from_address && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.from_address}</div>
                  </div>
                )}
              </div>

              {/* 差出人名 */}
              <div className='row mb-6'>
                <div className='col-lg-3 col-form-label'>
                  <label className='col-form-label required'>差出人名</label>
                </div>
                <div className='col-lg-9 align-self-center'>
                  <input
                    className='form-control'
                    type='text'
                    placeholder='差出人名'
                    {...formik.getFieldProps('from_name')}
                    value={formik.values.from_name}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.from_name && formik.errors.from_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.from_name}</div>
                  </div>
                )}
              </div>

              <div className='row mb-6'>
                <div className='col-lg-3 col-form-label'>
                  <label className='col-form-label required'>共通 header</label>
                </div>
                <div className='col-lg-9 align-self-center'>
                  <textarea
                    className='form-control'
                    rows={5}
                    {...formik.getFieldProps('header_contents')}
                    value={formik.values.header_contents}
                    onBlur={formik.handleBlur}
                  ></textarea>
                </div>
                {formik.touched.header_contents && formik.errors.header_contents && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.header_contents}</div>
                  </div>
                )}
              </div>

              <div className='row mb-6'>
                <div className='col-lg-3 col-form-label'>
                  <label className='col-form-label required'>共通 footer</label>
                </div>
                <div className='col-lg-9 align-self-center'>
                  <textarea
                    className='form-control'
                    rows={5}
                    {...formik.getFieldProps('footer_contents')}
                    value={formik.values.footer_contents}
                    onBlur={formik.handleBlur}
                  ></textarea>
                </div>
                {formik.touched.footer_contents && formik.errors.footer_contents && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.footer_contents}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}>
        <button
          className={`rounded mx-2 my-2 btn ${formik.isSubmitting ? 'btn-light-primary' : 'btn-primary'}`}
          type='submit'
          data-kt-users-modal-action='submit'
          disabled={formik.isSubmitting}
        >
          更新
        </button>
      </div>
    </form>
  )
}
