import React, { useEffect, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { ledgerDownload } from 'src/app/modules/api';
import moment from 'moment'
import { formatNumber } from 'src/app/modules/utils'
import { useAlert } from 'src/_metronic/layout/core'
export const Shipping = forwardRef((props: any, ref) => {
    console.log('props', props)
    const {setMessage} = useAlert()
    const shippingRef = useRef<any>(null)
    const [dataItem, setDataItem] = useState<any>({}) // 选中的项
    const [prefectureParametersVMap, setPrefectureParametersVMap] = useState<Map<any, any>>()
    const [prefectureParametersIMap, setPrefectureParametersIMap] = useState<Map<any, any>>()
    const [productUnitParametersMap, setProductUnitParametersMap] = useState<Map<any, any>>()

    const initData = async () => {
        setDataItem(props.invoiceInitData)
        let unitParametersMap: any = new Map(props.modalParameters?.units?.map((unit: any) => [unit.id, unit]));
        let prefectureParametersVMap: any = new Map(props.modalParameters?.prefecture?.map((prefecture: any) => [prefecture.value, prefecture]));
        let prefectureParametersIMap: any = new Map(props.modalParameters?.prefecture?.map((prefecture: any) => [prefecture.id, prefecture]));
        setProductUnitParametersMap(unitParametersMap)
        setPrefectureParametersVMap(prefectureParametersVMap)
        setPrefectureParametersIMap(prefectureParametersIMap)
    }

    useEffect(()=> {
        initData()
    }, [])

    const submitData = (callback:any) => {
        console.log('shippingRef', shippingRef.current, shippingRef.current.innerHTML)
        const htmlbody = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Document</title>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css" rel="stylesheet"
                    integrity="sha384-GLhlTQ8iRABdZLl6O3oVMWSktQOp6b7In1Zl3/Jr59b6EGGoI1aFkw7cmDA6j6gD" crossorigin="anonymous">
                <link href="https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,200,300,regular,500,600,700,800,900" rel="stylesheet" />
            </head>
            <style>
            body{
                font-family: "Noto Sans JP", sans-serif;
                font-optical-sizing: auto;
                font-style: normal;
            }
                @media print {
                    @page {
                        size: A4;
                        margin: 0;
                    }
            
                    #kt_app_header {
                        display: none;
                    }
            
                    #kt_app_footer {
                        display: none;
                    }
            
                    body {
                        margin: 0;
                    }
                }
            </style>
            </head>
            <body>
                ${shippingRef.current.innerHTML}
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-/mhDoLbDldZc3qpsJHpLogda//BVZbgYuw6kof4u2FrCedxOtgRZDTHgHUhOCVim"
                    crossorigin="anonymous"></script>
            </body>
            </html>
        `
        console.log("htmlbody", htmlbody)
        ledgerDownload({
            ledgerKbn: 0,
            title:'出荷指示書',
            displayId:dataItem?.suppliers?.display_id,
            pdfHtml: htmlbody
        }).then((res: any) => {
            console.log('res', res)
            const url = res.clientUrl
            const fileName = res.fileName
            const link = document.createElement('a')
            link.href = url
            link.download = `${fileName}.csv`
            document.body.appendChild(link)
            link.click()
        
            // 作成したURL対象を削除
            URL.revokeObjectURL(url)
            if(callback){
                callback()
            }
            setMessage({ type: 'success', message: 'PDFファイルのダウンロードを成功しました。' })
        }).catch((res:any)=>{
            if(callback){
                callback()
            }
            setMessage({ type: 'error', message: 'PDFファイルのダウンロードを失敗しました。' })
        })
    }
    useImperativeHandle(ref, () => ({
        submitData: submitData,
    }))
    return (
        <>
            <style>
                {`
                    @media print {
                        @page {
                            size: A4;
                            margin: 0;
                        }
                        #kt_app_header {
                            display: none;
                        }
                        #kt_app_footer {
                            display: none;
                        }
                        body {
                            margin: 0;
                        }
                    }
                `}
            </style>
            <div ref={shippingRef} className="bg-white mw-lg-950px mx-auto w-100">
                <div className="p-5">
                    <div className="">
                        {/* ヘッダ */}
                        <div className="d-flex justify-content-center flex-column flex-sm-row">
                            <h1 className="fw-bolder fs-4 mb-4">出荷指示書</h1>
                        </div>

                        {/* 情報 */}
                        <div className="row border border-dark">
                            <div className="col-6 border-end border-dark py-1">
                                <div className="text-sm-start fw-semibold fs-8 border-bottom border-dark">
                                    <div>【購入者】</div>
                                    <div><span className="fw-bold">{dataItem?.order?.billing_store_name}</span></div>
                                    <div>{dataItem?.order?.billing_zip_code}</div>
                                    <div>
                                        {prefectureParametersVMap?.get(dataItem?.order?.billing_prefecture)?.label}
                                        {dataItem?.order?.billing_city}
                                        {dataItem?.order?.billing_street_address}
                                        {dataItem?.order?.billing_building_name}
                                    </div>
                                </div>
                                <div className="text-sm-start fw-semibold fs-8 py-1 border-bottom border-dark">
                                    <div>【発送先】</div>
                                    <div><span className="fw-bold">{dataItem?.shippingDetails?.store_name}</span></div>
                                    <div>{dataItem?.shippingDetails?.zip_code}</div>
                                    <div>
                                        {prefectureParametersVMap?.get(dataItem?.shippingDetails?.prefecture)?.label}
                                        {dataItem?.shippingDetails?.city}
                                        {dataItem?.shippingDetails?.street_address}
                                        {dataItem?.shippingDetails?.building_name}
                                    </div>
                                </div>
                                <div className="text-sm-start fw-semibold fs-8 py-1">
                                    <div>【特記事項】</div>
                                    <div>
                                        {dataItem?.shippingDetails?.notes}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 fs-8">
                                <div className="row">
                                    <div className="col-4 py-1 border-bottom border-end border-dark" >
                                        発注番号
                                    </div>
                                    <div className="col-8 py-1 border-bottom border-dark">
                                        {dataItem?.shippingDetails?.id}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4 py-1 border-bottom border-end border-dark" >
                                        受注日
                                    </div>
                                    <div className="col-8 py-1 border-bottom border-dark">
                                        {moment(dataItem?.order?.order_date).format('YYYY-MM-DD')}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4 py-1 border-bottom border-end border-dark" >
                                        店舗
                                    </div>
                                    <div className="col-8 py-1 border-bottom border-dark">
                                        {dataItem?.order?.billing_store_name}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4 py-1 border-bottom border-end border-dark" >
                                        決済方法
                                    </div>
                                    <div className="col-8 py-1 border-bottom border-dark">
                                        {dataItem?.payments?.payment_method}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4 py-1 border-bottom border-end border-dark" >
                                        出荷確定日
                                    </div>
                                    <div className="col-8 py-1 border-bottom border-dark">
                                        {dataItem?.shippingDetails?.shipment_date?moment(dataItem?.shippingDetails?.shipment_date).format('YYYY-MM-DD'):''}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4 py-1 border-bottom border-end border-dark" >
                                        発送方法
                                    </div>
                                    <div className="col-8 py-1 border-bottom border-dark">
                                        {dataItem?.shippingDetails?.delivery_method}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-sm-start fw-semibold my-2">
                                            <div className='mb-4'>
                                                <div>【出荷元】</div>
                                                <div><span className="fw-bold">{dataItem?.suppliers?.slip_company_name}</span></div>
                                                <div>{dataItem?.suppliers?.slip_address_postal_code}</div>
                                                <div>
                                                    {prefectureParametersIMap?.get(dataItem?.suppliers?.slip_address_prefecture)?.label}
                                                    {dataItem?.suppliers?.slip_address_city}
                                                    {dataItem?.suppliers?.slip_address_street}
                                                </div>
                                                <div>{dataItem?.suppliers?.slip_address_building_name}</div>
                                            </div>
                                            <div className=''>
                                                <div>TEL: {dataItem?.suppliers?.slip_tel}</div>
                                                <div>FAX: {dataItem?.suppliers?.slip_fax}</div>
                                                <div>登録番号: {dataItem?.suppliers?.invoice_number}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* 注文明細テーブル */}
                        <div className="row border border-top-0 border-dark">
                            <div className="col-12 py-1">
                                <table className='table table-bordered border-dark'>
                                    <thead>
                                        <tr>
                                            <th scope="col" className='fs-8'>No</th>
                                            <th scope="col" className='fs-8'>品名</th>
                                            <th scope="col" className='fs-8'>商品コード</th>
                                            <th scope="col" className='fs-8'>数量</th>
                                            <th scope="col" className='fs-8'>単位</th>
                                            <th scope="col" className='fs-8'>単価</th>
                                            <th scope="col" className='fs-8'>税率</th>
                                            <th scope="col" className='fs-8'>金額</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataItem?.orderItems?.map((item:any, index: number)=> {
                                            return (
                                                <tr>
                                                    <td scope="row" className='fs-8'>{index + 1}</td>
                                                    <td className='fs-8'>
                                                        {item.product_name} <br />
                                                        {item.product_variation_name}
                                                    </td>
                                                    <td className='fs-8'>{item.sku}</td>
                                                    <td className='fs-8'>{item.quantity}</td>
                                                    <td className='fs-8'>
                                                        {productUnitParametersMap?.get(item.unit)?.label}
                                                    </td>
                                                    <td className='fs-8'>{formatNumber(item.unit_price)}</td>
                                                    <td className='fs-8'>{item.tax_rate}%</td>
                                                    <td className='fs-8'>{formatNumber(item.total_amount)}</td>
                                                </tr>
                                            )

                                        })}
                                        {
                                            (dataItem?.orderItems?.length > 0 && dataItem?.orderItems?.length < 20) ? (
                                                <EmptyRow rows={20 - dataItem?.orderItems?.length} />
                                            ) : ('')
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
})

interface EmptyRowProps {
    rows?: number; // `rows` はオプショナルで、デフォルト値は 1 です
}
function EmptyRow({ rows = 1 }: EmptyRowProps) {
    return (
        <>
            {rows > 0 && Array.from({ length: rows }, (_, index) => (
                <tr>
                    <td scope="row" className='fs-8'></td>
                    <td className='fs-8'></td>
                    <td className='fs-8'></td>
                    <td className='fs-8'></td>
                    <td className='fs-8'></td>
                    <td className='fs-8'></td>
                    <td className='fs-8'></td>
                    <td className='fs-8'></td>
                </tr>
            ))}
        </>
    )
}