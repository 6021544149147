import { useEffect, useState } from 'react'
import { Spin } from 'antd'

import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd'
import { dataImport, dataImportHistoryGet } from 'src/app/modules/api'
import { Loading } from 'src/app/components/loading'
import { Pagination } from 'src/app/components/pagination'
import {
  showErrorMessage,
  showWarningMessage,
  showSuccessMessage,
} from '@/app/components/sweetAlert'
import { useAuth } from 'src/app/modules/auth'
import papa from 'papaparse'

const optionsCsv = [
  { id: 1, label: '商品基本情報登録CSV' },
  { id: 2, label: '商品バリエーション情報登録CSV' },
  { id: 3, label: '受注データCSV' },
  { id: 4, label: '在庫管理CSV' }
]

export default function DataImport() {

  const { auth } = useAuth();
  const [loading, setLoading] = useState(false)
  const [fileData, setFileData] = useState<any>([])
  const [selectedType, setSelectedType] = useState('1')
  const [resData, setResData] = useState<any>({})

  const { Dragger } = Upload;

  const props: UploadProps = {
    // name: 'file',
    accept: '.csv',
    maxCount: 1,
    multiple: false,
    disabled: false,
    fileList: fileData,
    // サーバー送信停止
    beforeUpload: (file) => {
      console.log('file', file)
      return false
    },
    // action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info: any) {
      console.log('info', info)

      // csvファイルのみ許可かつ単一ファイルのみ許可
      if (info.file.type !== 'text/csv') {
        message.error('CSVファイルのみアップロードできます');
        setFileData(() => [])
        return false
      }
      if (info.fileList.length > 1) {
        message.error('ファイルは1つだけアップロードできます');
        setFileData(() => [])
        return false
      }
      if (info.file.size > 2097152) {
        message.error('ファイルサイズを超えました。（最大2M）');
        return false
      }

      setFileData([info.file])

      // const { status } = info.file;
      // if (status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      // if (status === 'done') {
      //   message.success(`${info.file.name} file uploaded successfully.`);
      // } else if (status === 'error') {
      //   message.error(`${info.file.name} file upload failed.`);
      // }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
      // csvファイルのみ許可かつ単一ファイルのみ許可
      if (e.dataTransfer.files.length > 1) {
        message.error('ファイルは1つだけアップロードできます');
        setFileData([])
        return false
      }
      if (e.dataTransfer.files[0].type !== 'text/csv') {
        message.error('CSVファイルのみアップロードできます');
        setFileData([])
        return false
      }

      setFileData([e.dataTransfer.files[0]])
    },
  };

  // CSV登録
  const csvUpload = () => {
    setLoading(true)
    console.log('fileData', fileData)

    // ラジオボタンが選択されているかチェック
    if (!selectedType) {
      setLoading(false)
      message.error('ファイルタイプを選択してください');
      return false
    }

    // ファイルが選択されているかチェック
    if (fileData.length === 0) {
      setLoading(false)
      // message.error('ファイルを選択してください');
      message.error('ファイルをアップロードしてください');
      return false
    }

    const reader: any = new FileReader();
    console.log('reader', reader)
    reader.readAsText(fileData[0]);
    let dataList: any = [];
    reader.onload = function () {
      console.log('reader.result', reader.result)

      // 文字コードがUTF-8以外の場合はエラー
      if (typeof reader.result === 'string' && reader.result.indexOf('�') !== -1) {
        setLoading(false)
        message.error('文字コードがUTF-8ではありません。');
        return false
      }

      dataList = papa.parse(reader.result, {
        header: true,
        skipEmptyLines: true,
      }).data;

      console.log('dataList', dataList)

      dataImport({
        file_name: fileData[0]?.name,
        file_type: fileData[0]?.type,
        importType: selectedType,
        dataList: dataList
      }).then((res: any) => {
        console.log('res', res)
        setResData(res)
        if (res?.errInfo?.length == 0) {
          showSuccessMessage('データインポートは成功しました。')
        }
        setLoading(false)
      }).catch((err: any) => {
        console.log('err', err)
        // showErrorMessage(err.message)
        message.error(err.message)
        setLoading(false)
      })
    };
    reader.onerror = function () {
      console.log('reader.error', reader.error)
    };
  }

  useEffect(() => {
    initData()
  }, [])

  const [datas, setDatas] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    pageSize: 10,
    total: 0,
  }) // 分页参数

  // 获取列表数据
  const initData = async () => {
    await getData(queryParams.page, queryParams.pageSize)
  }

  // 获取列表数据
  const getData = async (pageNum: any, pageSize: any) => {
    // console.log('pageNum', pageNum, pageSize)
    setIsLoading(true)
    try {
      const res: any = await dataImportHistoryGet({
        pageNum,
        pageSize,
      })
      console.log('res', res)

      setQueryParams({ ...queryParams, total: res.total })
      setDatas(res.rows)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('error', error)
    }
  }

  return (
    <>
      <Spin spinning={loading} tip=' ' size='large'>

        <div className='card mb-6 shadow-sm'>
          <div className='card-body'>


            {/* <!-- タブ切り替えのナビゲーション --> */}
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a href="#csv" className="nav-link active" data-bs-toggle="tab">インポート</a>
              </li>
              <li className="nav-item">
                <a href="#restore" className="nav-link" data-bs-toggle="tab">インポート履歴</a>
              </li>
            </ul>

            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
              <symbol id="check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </symbol>
              <symbol id="info-fill" viewBox="0 0 16 16">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
              </symbol>
              <symbol id="exclamation-triangle-fill" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </symbol>
            </svg>

            {/* <!-- タブコンテンツ --> */}
            <div className="tab-content">
              {/* <!-- CSV変換タブ --> */}
              <div className="tab-pane active" id="csv">
                <div className="container mt-5">

                  <div className="alert alert-warning d-flex align-items-center mb-6" role="alert">
                    <svg className="bi flex-shrink-0 me-3" role="img" aria-label="Warning:" style={{ width: '1.5em', height: '1.5em', verticalAlign: '-0.125em', fill: 'currentcolor' }}>
                      <use xlinkHref="#exclamation-triangle-fill" />
                    </svg>
                    <div>
                      ファイルを再インポートする際は、インポートできなかった行のみのデータに編集してから取り込むようにしてください。<br />
                      データにインポート不可の行が含まれていても、全てキャンセルにはならず、インポート可能な行は取り込まれます。そのため、インポートに成功した行を残したまま再インポートすると、成功した行は重複してしまいます。インポート結果の確認は履歴から詳細をご覧ください。
                    </div>
                  </div>

                  {/* <!-- CSV変換のコンテンツをここに追加 --> */}
                  {/* 1step */}
                  <div className="mb-15">
                    <h2 className="mb-10">1. アップロードするファイルタイプを選択</h2>

                    <div className="d-flex align-items-center fs-5" style={{ gap: '30px' }}>
                      {/* selectedttype がidといっちしたらチェックする */}
                      {optionsCsv.map((item: any, index: number) => (
                        <div className='form-check form-check-inline pe-3' key={index}>
                          <input
                            className='form-check-input'
                            type='radio'
                            value={item.id}
                            checked={selectedType == item.id}
                            onChange={() => setSelectedType(item.id)}
                          />
                          <label className='form-check-label'>{item.label}</label>
                        </div>
                      ))}
                    </div>
                  </div>


                  {/* 2step */}
                  <div className="mb-15">
                    <h2 className="mb-10">2. CSVファイルを選択</h2>

                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined rev={undefined} />
                      </p>
                      <p className="ant-upload-text">CSVファイルをドラッグ&ドロップ</p>
                      <p className="ant-upload-hint">
                        またはクリックしてファイルを選択してください
                      </p>
                    </Dragger>
                  </div>


                  {/* 3step */}
                  <div className="mb-15">
                    <h2 className="mb-10">3. 登録データの内容確認</h2>

                    <div className="container my-5 table-responsive">
                      <table className="table table-bordered" id="csvDataTable">
                        <thead>
                          {/* <!-- Header will be populated here --> */}
                        </thead>
                        <tbody className="table-group-divider">
                          {/* fileData が空の時、データがありませんを表示 */}
                          {!resData?.allNum && <tr><td>データがありません</td></tr>}
                          {resData?.errInfo?.length > 0 && resData?.errInfo.map((err: any, index: number) => (
                            <tr key={index}><td style={{ color: 'red' }}>{err?.rowNum}行目がエラーです。（{err?.message.join(', ')}）</td></tr>
                          ))}
                          {/* <!-- Data will be populated here --> */}
                          {resData?.allNum &&
                            <tr><td>{resData.allNum}行中 <span style={{ color: 'red' }}>{resData.successNum}行が正常</span> です。（1行目ヘッダー行をのぞく）</td></tr>
                          }
                        </tbody>
                      </table>
                    </div>

                  </div>

                  {/* <!-- Button to download the data as CSV --> */}
                  <button className="csvBtn btn btn-secondary mb-4" onClick={csvUpload}>登録する</button>

                </div>
              </div>

              {/* <!-- 履歴の復元タブ --> */}
              <div className="tab-pane" id="restore">
                <div className="container mt-5">

                  <div className="alert alert-warning d-flex align-items-center mb-6" role="alert">
                    <svg className="bi flex-shrink-0 me-3" role="img" aria-label="Warning:" style={{ width: '1.5em', height: '1.5em', verticalAlign: '-0.125em', fill: 'currentcolor' }}>
                      <use xlinkHref="#exclamation-triangle-fill" />
                    </svg>
                    <div>
                      インポートしたデータは下の一覧から確認できます。
                    </div>
                  </div>

                  <div className="container my-5 table-responsive">
                    <h2 className="mb-4">CSVデータの表示</h2>

                    {/* <!-- Table to display restored data --> */}
                    <table className="table table-bordered" id="restoredDataTable">
                      <thead>
                        {/* <!-- Header will be populated here --> */}
                        <tr>
                          <th>日付</th>
                          <th>ファイル名</th>
                          <th>種類</th>
                          <th>取引件数</th>
                          <th>ステータス</th>
                          <th>ユーザー</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datas.length > 0 ? (
                          datas.map((item: any, index: number) => {
                            return (
                              <tr key={item.pk}>
                                <td>{item.create_at}</td>
                                <td>{item.file_name}</td>
                                <td>{item.file_type}</td>
                                <td>{item.count}</td>
                                <td>{item.status}</td>
                                <td>{item.update_user}</td>
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan={17}>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {queryParams.total != 0 && (
                      <Pagination
                        currentPage={getData}
                        page={queryParams.page}
                        pageSize={queryParams.pageSize}
                        total={queryParams.total}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Spin>
    </>
  )
}

