import React, {useEffect, useState} from 'react'
import {
  getCreditInfo,
  entrytran,
  exectran,
  execsecuretran,
  paymentTransaction,
} from 'src/app/modules/api'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAlert} from 'src/_metronic/layout/core'
import {
  useFormik,
  FormikProvider,
  Form,
  useFormikContext,
  useField,
  FieldArray,
  FieldHookConfig,
} from 'formik'
import {API_URL} from 'src/service/api'
import amount from '@/app/modules/pages/subscriptions/table/columns/amount'

export default function Transaction() {
  const [cardList, setCardList] = useState<any>({})
  const [supplierList, setSupplierList] = useState<any>([])
  const [isComplete, setIsComplete] = useState<boolean>(false)
  const {setMessage} = useAlert()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {id: '', amount: 0, cardSeq: ''},
    onSubmit: (values: any) => {
      console.log(values)
      execTransaction({...values})
    },
  })

  const execTransaction = async (data: {id: string; cardSeq: string; amount: number}) => {
    // const responseEntryTran: any = await entrytran({
    //   id: data.id,
    //   amount: data.amount,
    //   provider: 'smbc',
    // })

    // const responseExecTran: any = await exectran({
    //   id: data.id,
    //   card_seq: data.cardSeq,
    //   access_id: responseEntryTran.data.accessID,
    //   access_pass: responseEntryTran.data.accessPass,
    //   order_id: responseEntryTran.data.orderId,
    //   amount: data.amount,
    //   provider: 'smbc',
    // })

    const responsePaymentTransaction: any = await paymentTransaction({
      supplier: data.id,
      card_seq: data.cardSeq,
      amount: data.amount,
      provider: 'smbc',
      payment_method: 'credit_card',
    })

    if (responsePaymentTransaction.data.message === 'ok') {
      // if (responseExecTran.data.message === 'ok') {
      const responseExecSecureTran: any = await execSecurreTransaction({
        id: data.id,
        access_id: responsePaymentTransaction.data.accessID,
        access_pass: responsePaymentTransaction.data.accessPass,
      })

      if (responseExecSecureTran) {
        setIsComplete(true)
      } else {
        setMessage({type: 'error', message: '3DS認証に失敗しました。再度お試しください。'})
      }
    }

    if (responsePaymentTransaction.data.redirectUrl) {
      const newTab = window.open(responsePaymentTransaction.data.redirectUrl, '_blank')

      window.addEventListener('message', handleMessage)

      return () => {
        window.removeEventListener('message', handleMessage)
      }
    }
  }

  const execSecurreTransaction = async (data: {
    id: string
    access_id: string
    access_pass: string
  }) => {
    const response: any = await execsecuretran({
      id: data.id,
      access_id: data.access_id,
      access_pass: data.access_pass,
      provider: 'smbc',
    })

    if (response.message === 'ok') {
      return true
    } else {
      return false
    }
  }

  const handleMessage = (event: any) => {
    console.log('API_URL', process.env.REACT_APP_API_ORIGIN)
    if (event.origin !== process.env.REACT_APP_API_ORIGIN || !event.data.auth) {
      return
    }
    console.log('handleMessage', event.data)

    if (event.data.auth === 'ok') {
      setIsComplete(true)
    } else {
      setMessage({type: 'error', message: '3DS認証に失敗しました。再度決済をお試しください。'})
    }

    // イベントリスナーを削除
    window.removeEventListener('message', handleMessage)
  }

  useEffect(() => {
    setSupplierList([
      {
        id: '4gosl451c0snn4jjldn2780vtr#Suppliers#95a4adf4-7b04-4a4e-acd6-35b970fba30b',
        name: '株式会社カクヤス',
      },
      {
        id: '4gosl451c0snn4jjldn2780vtr#Suppliers#85f699ca-bc00-4b8f-aa5a-065eda94b099',
        name: '株式会社アルカン',
      },
    ])
  }, [])

  useEffect(() => {
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])

  useEffect(() => {
    if (supplierList.length > 0) {
      supplierList.map((supplier: any) => {
        getCreditInfo({
          type: 0,
          id: supplier.id,
        }).then((res: any) => {
          setCardList((prev: any) => {
            return {...prev, [supplier.id]: res.data}
          })
        })
      })
    }
  }, [supplierList])

  useEffect(() => {
    if (isComplete) {
      navigate('/buy/checkout/complete', {replace: true})
    }
  }, [isComplete])

  return (
    <div className='card border mb-6'>
      <div className='card-header bg-light'>
        <div className='card-title'>
          <h3 className='fs-6'>金額指定決済</h3>
        </div>
      </div>
      <div className='card-body'>
        {supplierList.length === 0 && <p>取引先がありません</p>}
        {supplierList.length > 0 && (
          <FormikProvider value={formik}>
            <Form>
              <div className='mb-5'>
                <label className='form-label'>仕入れ先</label>
                <select className='form-select' {...formik.getFieldProps(`id`)}>
                  <option value=''>選択してください</option>
                  {supplierList.map((supplier: any, index: number) => (
                    <option key={index} value={supplier.id}>
                      {supplier.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='mb-5'>
                <label className='form-label'>金額</label>
                <input type='number' className='form-control' {...formik.getFieldProps(`amount`)} />
              </div>
              <div className='mb-5'>
                <label className='form-label'>カード</label>
                {formik.values.id && cardList[formik.values.id]?.length === 0 && (
                  <p>カードがありません</p>
                )}
                {formik.values.id && cardList[formik.values.id].length > 0 && (
                  <select className='form-select' {...formik.getFieldProps(`cardSeq`)}>
                    <option value=''>選択してください</option>
                    {cardList[formik.values.id]?.map((card: any, index: number) => (
                      <option key={index} value={card.cardSeq}>
                        {card.holderName} {card.cardNo}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <button type='submit' className='btn btn-primary'>
                決済
              </button>
            </Form>
          </FormikProvider>
        )}
      </div>
    </div>
  )
}
