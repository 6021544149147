import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate, useLocation  } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { PageLink, PageTitle } from '../../_metronic/layout/core';
import { HelmetComponent } from 'src/app/modules/helmet';

// 加盟店
import Customer from '@/app/pages/supplier/customer';
import CustomerDetail from '@/app/pages/supplier/customer-detail';
import CustomerRank from '@/app/pages/supplier/customer-rank';
import CustomerRankDetail from '@/app/pages/supplier/customer-rank-detail';
import CustomerApproval from '@/app/pages/supplier/customer-approval';
import Product from '@/app/pages/supplier/product'
import ProductRegist from '@/app/pages/supplier/product-regist'
import VariationRegist from '@/app/pages/supplier/product-regist/VariationRegist'
import ProductOption from '@/app/pages/supplier/product-option'
import ProductOptionDetail from '@/app/pages/supplier/product-option/ProductOptionDetail'
import ProductCustomField from '@/app/pages/supplier/product-custom-field'
import ProductCustomFieldDetail from '@/app/pages/supplier/product-custom-field-detail'
import Order from '@/app/pages/supplier/order';
import OrderDetail from '@/app/pages/supplier/order-detail';
import CsvTemplate from '@/app/pages/supplier/csv-template';
import CsvTemplateDetail from '@/app/pages/supplier/csv-template-detail';
import Logistics from '@/app/pages/supplier/logistics';
import LogisticsDetail from '@/app/pages/supplier/logistics/logisticsDetail';
import SalesDays from '@/app/pages/supplier/sales-days';
import DeliveryAddress from '@/app/pages/supplier/delivery-address';
import DeliveryAddressDetail from '@/app/pages/supplier/delivery-address-detail';
import LedgerSetting from '@/app/pages/supplier/ledger-setting';
import SupplierAccountRoute from '@/app/pages/supplier/account';
import MediaManagement from  '@/app/pages/supplier/media-management';
import DataImport from '@/app/pages/supplier/data-import';

// 利用者
import LaterPayment from '@/app/pages/buyer/later-payment'
import LaterPaymentConfirm from '@/app/pages/buyer/later-payment/confirm'
import LaterPaymentComplete from '@/app/pages/buyer/later-payment-complete'
import Transaction from '@/app/pages/buyer/transaction'
import TransactionAuth from '@/app/pages/buyer/transaction/auth'
import OrderHistory from '@/app/pages/buyer/order-history';
import OrderHistoryDetail from '@/app/pages/buyer/order-history-detail';
import BuyerAccountRoute from '@/app/pages/buyer/mypage';

import ProductList from '@/app/pages/buyer/product-list';
import ProductListDetail from '@/app/pages/buyer/product-list/product-list-detail';

import Cart from '@/app/pages/buyer/cart';
import CartCheckout from '@/app/pages/buyer/cart/cart-checkout';
import CartCheckoutConfirm from '@/app/pages/buyer/cart/cart-checkout-confirm' 
import CartCheckoutOrdering from '@/app/pages/buyer/cart/cart-checkout-ordering'
import CartCheckoutComplete from '@/app/pages/buyer/cart/cart-checkout-complete'

import FavoriteProductList from '@/app/pages/buyer/favorite-product-list';
import SupplierList from '@/app/pages/buyer/supplier-list';
import SupplierDetail from '@/app/pages/buyer/supplier-detail';

// 共通
import Plan from '@/app/pages/common/plan';
import PlanInput from '@/app/pages/common/plan/components/plan-input';
import PlanPayment from '@/app/pages/common/plan/components/plan-payment';
import PlanConfirm from '@/app/pages/common/plan/components/plan-confirm';
import PlanComplete from '@/app/pages/common/plan/components/plan-complete';
import Faq from '@/app/pages/common/faq';
import Low from '@/app/pages/common/low';
import Privacy from '@/app/pages/common/privacy';
import Terms from '@/app/pages/common/terms';

// 静的
import ProductCategory from '@/app/pages/supplier/product-category';
import ProductLabel from '@/app/pages/supplier/product-label'
import Notification from '@/app/pages/supplier/notification';
import NotificationDetail from '@/app/pages/supplier/notification/notification-detail';

import LoginHistory from '@/app/pages/static/login-history';
import TaxSetting from '@/app/pages/static/tax-setting'

import { Invoice, Shipping } from 'src/app/modules/ledger'
import DocumentReceipt from '@/app/pages/static/document/receipt';
import DocumentSlip from '@/app/pages/static/document/slip';

import CsvOrder from '@/app/pages/static/csv-order';
import CsvUpdate from '@/app/pages/static/csv-update';
import NewsSetting from '@/app/pages/static/news-setting';
import CrossSellManagement from '@/app/pages/static/crossSell-management';

const baseBreadCrumbs: Array<PageLink> = [
  {
    title: 'ダッシュボード',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '-',
    path: '',
    isSeparator: false,
    isActive: false,
  },
];

interface PageTitleRouteProps {
  title: string;
  component: React.ComponentType;
}

const PageTitleRoute: React.FC<PageTitleRouteProps> = ({ title, component: Component }) => (
  <>
    <HelmetComponent title={title} options={{}} />
    <PageTitle breadcrumbs={baseBreadCrumbs}>{title}</PageTitle>
    <Component key={useLocation().pathname} />
  </>
);

const PrivateRoutes = () => {
  // const { auth } = useAuth();
  // console.log(auth.role);

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='/dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='/account/*'
          element={
            <SuspensedView>
              <SupplierAccountRoute />
              <BuyerAccountRoute />
            </SuspensedView>
          }
        />

        {/* 加盟店 */}
        <Route path='/customer' element={<PageTitleRoute title='顧客一覧' component={Customer} />} />
        <Route path='/customer/:id' element={<PageTitleRoute title='顧客情報詳細' component={CustomerDetail} />} />
        <Route path='/customer/rank' element={<PageTitleRoute title='顧客ランク設定' component={CustomerRank} />} />
        <Route path='/customer/rank/id' element={<PageTitleRoute title='顧客ランク詳細設定' component={CustomerRankDetail} />} />
        <Route path='/customer/rank/:id' element={<PageTitleRoute title='顧客ランク詳細設定' component={CustomerRankDetail} />} />
        <Route path='/customer/approval' element={<PageTitleRoute title='取引先承認一覧' component={CustomerApproval} />} />
        <Route path='/product' element={<PageTitleRoute title='商品一覧' component={Product} />} />
        <Route path='/product/:id' element={<PageTitleRoute title='商品基本情報登録' component={ProductRegist} />} />
        <Route path='/product/:id/variation' element={<PageTitleRoute title='バリエーション情報登録' component={VariationRegist} />} />
        <Route path='/product/option' element={<PageTitleRoute title='商品オプション項目' component={ProductOption} />} />
        <Route path='/product/option/id' element={<PageTitleRoute title='商品オプション項目登録・編集' component={ProductOptionDetail} />} />
        <Route path='/product/option/:id' element={<PageTitleRoute title='商品オプション項目登録・編集' component={ProductOptionDetail} />} />
        <Route path='/product/customcell' element={<PageTitleRoute title='商品カスタム項目一覧' component={ProductCustomField} />} />
        <Route path='/product/customcell/:id' element={<PageTitleRoute title='商品カスタム項目登録・編集' component={ProductCustomFieldDetail} />} />
        <Route path='/product/customcell/id' element={<PageTitleRoute title='商品カスタム項目登録・編集' component={ProductCustomFieldDetail} />} />
        <Route path='/order' element={<PageTitleRoute title='注文一覧' component={Order} />} />
        <Route path='/order/:id' element={<PageTitleRoute title='注文詳細' component={OrderDetail} />} />
        <Route path='/order/csv' element={<PageTitleRoute title='注文CSVテンプレート一覧' component={CsvTemplate} />} />
        <Route path='/order/csv/:id' element={<PageTitleRoute title='注文CSVテンプレート設定' component={CsvTemplateDetail} />} />
        <Route path='/logistics' element={<PageTitleRoute title='出荷一覧' component={Logistics} />} />
        <Route path='/logistics/:id' element={<PageTitleRoute title='出荷詳細' component={LogisticsDetail} />} />
        <Route path='/store/schedule' element={<PageTitleRoute title='営業日設定' component={SalesDays} />} />
        <Route path='/store/shipping' element={<PageTitleRoute title='配送先マスタ設定一覧' component={DeliveryAddress} />} />
        <Route path='/store/shipping/id' element={<PageTitleRoute title='配送先マスタ設定詳細' component={DeliveryAddressDetail} />} />
        <Route path='/store/shipping/:id' element={<PageTitleRoute title='配送先マスタ設定詳細' component={DeliveryAddressDetail} />} />
        <Route path='/ledger' element={<PageTitleRoute title='帳票設定' component={LedgerSetting} />} />
        <Route path='/media' element={<PageTitleRoute title='メディア管理' component={MediaManagement} />} />
        <Route path='/import' element={<PageTitleRoute title='データインポート管理' component={DataImport} />} />

        {/* 利用者 */}
        <Route path='/orderhistory' element={<PageTitleRoute title='注文履歴一覧' component={OrderHistory} />} />
        <Route path='/orderhistory/:id' element={<PageTitleRoute title='注文詳細' component={OrderHistoryDetail} />} />
        
        <Route path='/buy/product' element={<PageTitleRoute title='商品一覧' component={ProductList} />} />
        <Route path='/buy/product/:id' element={<ProductListDetail />} />
        
        <Route path='/buy/cart' element={<PageTitleRoute title='カート' component={Cart} />} />
        <Route path='/buy/checkout' element={<PageTitleRoute title='決済選択/配送先選択' component={CartCheckout} />} />
        <Route path='/buy/checkout/confirm' element={<PageTitleRoute title='注文詳細' component={CartCheckoutConfirm} />} />
        <Route path='/buy/checkout/ordering' element={<PageTitleRoute title='注文処理中' component={CartCheckoutOrdering} />} />
        <Route path='/buy/checkout/complete' element={<PageTitleRoute title='注文完了' component={CartCheckoutComplete} />} />
        <Route path='/buy/favorite' element={<PageTitleRoute title='いつもの商品' component={FavoriteProductList} />} />
        <Route path='/supplier' element={<PageTitleRoute title='仕入先申請一覧' component={SupplierList} />} />
        <Route path='/supplier/:id' element={<PageTitleRoute title='仕入先申請' component={SupplierDetail} />} />
        <Route path='/later/checkout' element={<PageTitleRoute title='後払い決済/決済選択' component={LaterPayment} />} />
        <Route path='/later/checkout/confirm' element={<PageTitleRoute title='後払い決済/決済確認' component={LaterPaymentConfirm} />} />
        <Route path='/later/checkout/complete' element={<PageTitleRoute title='後払い決済完了' component={LaterPaymentComplete} />} />
        <Route path='/transaction' element={<PageTitleRoute title='金額指定決済' component={Transaction} />} />
        <Route path='/transaction/auth' element={<PageTitleRoute title='認証中' component={TransactionAuth} />} />

        {/* 共通 */}
        <Route path='/plan' element={<PageTitleRoute title='プラン選択' component={Plan} />} />
        <Route path='/plan/input' element={<PageTitleRoute title='お申込みフォーム' component={PlanInput} />} />
        <Route path='/plan/payment' element={<PageTitleRoute title='決済ページ' component={PlanPayment} />} />
        <Route path='/plan/confirm' element={<PageTitleRoute title='最終確認画面' component={PlanConfirm} />} />
        <Route path='/plan/complete' element={<PageTitleRoute title='完了' component={PlanComplete} />} />
        <Route path='/faq' element={<PageTitleRoute title='よくあるご質問' component={Faq} />} />
        <Route path='/low' element={<PageTitleRoute title='特定商取引法に基づく表記' component={Low} />} />
        <Route path='/privacy' element={<PageTitleRoute title='プライバシーポリシー' component={Privacy} />} />
        <Route path='/terms' element={<PageTitleRoute title='ご利用規約' component={Terms} />} />


        {/* 静的 */}
        <Route path='/ledger/invoice' element={<PageTitleRoute title='帳票設定画面（請求書）' component={Invoice} />} />
        <Route path='/ledger/shipping' element={<PageTitleRoute title='帳票設定画面（請求書）' component={Shipping} />} />
        <Route path='/ledger/deliveryslip' element={<PageTitleRoute title='帳票設定画面（領収書）' component={DocumentReceipt} />} />
        <Route path='/ledger/receipt' element={<PageTitleRoute title='帳票設定画面（納品書）' component={DocumentSlip} />} />
        <Route path='/order/weight' element={<PageTitleRoute title='目方更新一覧' component={CsvOrder} />} />
        <Route path='/notification' element={<PageTitleRoute title='通知イベント設定一覧' component={Notification} />} />
        <Route path='/notification/:id' element={<PageTitleRoute title='通知イベント設定' component={NotificationDetail} />} />
        <Route path='/order/weight/:id' element={<PageTitleRoute title='目方更新詳細' component={CsvUpdate} />} />
        <Route path='/news' element={<PageTitleRoute title='ニュース設定' component={NewsSetting} />} />
        <Route path='/product/crosssell' element={<PageTitleRoute title='アップセル・クロスセル管理' component={CrossSellManagement} />} />
        <Route path='/store/tax' element={<PageTitleRoute title='消費税設定' component={TaxSetting} />} />
        <Route path='/loginhistory' element={<PageTitleRoute title='ログイン履歴一覧' component={LoginHistory} />} />
        <Route path='/product/category' element={<PageTitleRoute title='商品カテゴリ' component={ProductCategory} />} />
        <Route path='/product/label' element={<PageTitleRoute title='商品ラベル' component={ProductLabel} />} />

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
