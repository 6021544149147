/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
// import {Link} from 'react-router-dom'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'
// import {useQueryResponseLoading} from '../modules/apps/user-management/users-list/core/QueryResponseProvider'
// import {useQueryRequest} from '../../modules/apps/user-management/users-list/core/QueryRequestProvider'
import {ChartsWidget11 } from '../../../_metronic/partials/widgets'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'ホーム',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Pageconts: FC = () => {
	
    return (
    <>
    <div className='card p-10'>
	<div className="card card-flush h-md-50 mb-10 w-md-400px">
    <div className="card-header pt-5">
        <div className="card-title d-flex flex-column">   
            <div className="d-flex align-items-center">
                <span className="fs-2x fw-bold me-2 lh-1 ls-n2">580,000円</span>
                <span className="badge badge-light-success fs-base">                                
                    <i className="ki-duotone ki-arrow-up fs-5 text-success ms-n1"><span className="path1"></span><span className="path2"></span></i> 
                    2.2%
                </span>            
            </div>
            <span className="pt-1 fw-semibold fs-6">月の売上</span>
        </div>
    </div>
	<ChartsWidget11 className='card-xxl-stretch' />
</div>
		<div className='row mb-5 justify-content-between'>
			<h2 className='col-xl-3'>加盟店</h2>
			<div className="position-relative w-md-300px me-md-2">
				<i className="ki-duotone ki-magnifier fs-2 text-lg-1 position-absolute top-50 translate-middle-y ms-0" style={{color:'#A466EA'}}><span className="path1"></span><span className="path2"></span></i>
				<input
					type='text'
					className='form-control ps-10'
					name='search'
					placeholder='検索'
				/>
			</div>
		</div>
		<div className='card mb-lg-5 mb-9'>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 text-center fs-5'>
            <thead>
              <tr className='fw-bold text-dark'>
                <th className='min-w-120px'>顧客ID</th>
                <th className='min-w-150px'>顧客名</th>
                <th className='min-w-150px'>申込プラン</th>
                <th className='min-w-150px'>オプション利用</th>
                <th className='min-w-150px'>担当者</th>
                <th className='min-w-150px'>電話番号</th>
                <th className='min-w-150px'>支払金額（月）</th>
              </tr>
            </thead>
            <tbody>
              <tr>
				<td><a href="#">#123456</a></td>
                <td>●●商店</td>
                <td>ミニマム</td>
                <td>利用中</td>
                <td>担当　太郎</td>
                <td>03-1234-5678</td>
                <td>20,000円</td>
              </tr>
			  <tr>
				<td><a href="#">#78910</a></td>
                <td>▲▲▲屋</td>
                <td>スタンダード</td>
                <td>利用中</td>
                <td>担当田　花子</td>
                <td>045-123-4567</td>
                <td>50,000円</td>
              </tr>
            </tbody>
          </table>
        </div>
		<div className='d-flex flex-stack'>
		<ul className="pagination">
			<li className="page-item previous disabled">
				<a href="#" className="page-link">
				<i className="previous"></i>
				</a>
			</li>
			<li className="page-item active">
				<a href="#" className="page-link">
				1
				</a>
			</li>
			<li className="page-item">
				<a href="#" className="page-link">
				2
				</a>
			</li>
			<li className="page-item">
				<a href="#" className="page-link">
				3
				</a>
			</li>
			<li className="page-item">
				<a href="#" className="page-link">
				4
				</a>
			</li>
			<li className="page-item">
				<a href="#" className="page-link">
				5
				</a>
			</li>
			<li className="page-item next">
				<a href="#" className="page-link">
				<i className="next"></i>
				</a>
			</li>
		</ul>
	</div>
      </div>
	</div>
	</div>
    </>
    )
}

const DashboardAdmin: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>ダッシュボード</PageTitle>
      <Pageconts />
    </>
  )
}
export {DashboardAdmin}