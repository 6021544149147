/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {ChartsWidget9} from 'src/_metronic/partials/widgets'
import { getCommonDetails } from 'src/app/modules/api'
import { customerDataEdit } from 'src/app/modules/api'

export const CustomerReport: FC = () => {
    const location = useLocation()
    const [userdata, setUserdata] = useState<any>({
        store_status: '公開',
        registration_date: '2023/10',
        customer_id: '#123476',
        company_name: 'アップシェア商会',
        representative: 'zhang',
        contact_person: '担当　太郎',
        main_email_address: 'UPS@gmail.com',
        telephone_number: '045-478-6870',
        mobile_phone_number: '1232111111',
        fax_number: '',
        payment_method: '',
        establishment_date: '',
        actual_industry: '',
        website_url: '',
        physical_store: '有り',
        email_notifications: '可能'

    })  // 表单数据

    useEffect(() => {
        initData()
    }, [])

    // 获取列表数据
    const initData = () => {
        // getCommonDetails({}).then((res: any) => {
        //     console.log('res', res)
        //     if (res && res.rows.length > 0) {
        //         setUserdata(res.rows[0]);
        //     }
        // })
    }

    return (
        <>
			{/* <div className='card mb-5'>
				<div className="card-header card-header-stretch">
					<h3 className="card-title fs-2 fw-bold">今月レポートサマリー</h3>
				</div>
				<div className="card-body">
					<div className='d-flex flex-wrap mb-5 text-center'>
						<div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
							<div className='fs-6 fw-bolder text-success'>利用金額</div>
							<div className='fw-bold fs-2'>154,600円</div>
						</div>
						<div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
							<div className='fs-6 fw-bolder text-danger'>注文回数</div>
							<div className='fw-bold fs-2'>12回</div>
						</div>
					</div>

					<div className='Chart'>
						<ChartsWidget9 className='card-xxl-stretch' />
					</div>

				</div>
			</div> */}
			<div className='card'>
				<div className="card-header card-header-stretch">
					<h3 className="card-title fs-2 fw-bold">顧客レポート</h3>
				</div>
				<div className="card-body">
					<div className='table-responsive'>
						<table className='table align-middle table-row-bordered table-row-solid gy-4 gs-9'>
							<thead className='border-gray-200 fs-5 fw-semibold bg-lighten'>
								<tr>
									<th className='min-w-250px'>年月日</th>
									<th className='min-w-250px'>注文回数</th>
									<th className='min-w-250px'>お支払い金額（税込）</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className='fs-3'>2023/10</td>
									<td>15回</td>
									<td>150,700円</td>
								</tr>
								<tr>
									<td className='fs-3'>2023/09</td>
									<td>12回</td>
									<td>115,400円</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
    )
}
