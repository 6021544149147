import { getServer, postServer, deleteServer } from 'src/service/http'

// 画像登録 ---------------------------------------------------


  
// 画像登録
export function imageAdd(param: object, headers: object = {}) {
    return postServer('/image-add', { ...param },{...headers});
}

// 画像登録
export function imageUpload(obj: any) {
    return postServer('/image-upload', obj)
}


// 画像获取
export function imageGet(param: object) {
    return getServer('/image-get', { ...param });
}

// 画像削除
export function imageDelete(obj: any) {
    return deleteServer('/image-delete', obj)
}