/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Spin} from 'antd'
import {KTSVG} from '@/_metronic/helpers'
import {useAuth} from '@/app/modules/auth'
import {DeliveryInfo} from '../../supplier/account/components/settings/DeliveryInfo'
import {ChangeEmail} from './ChangeEmail'
import {accountInfoGet, productCategoryListGet} from 'src/app/modules/api'
import {parameterStoreGet} from 'src/app/modules/api'
// import {productCategoryListGet} from '@/app/pages/supplier/product-category/core/api'
import {filterTree} from 'src/utils/filter'

export function AccountInfo({type}: {type: 'buyer' | 'supplier'}) {
  // const { auth } = useAuth()
  const navigate = useNavigate()
  const {auth} = useAuth()
  const [addressList, setAddressList] = useState<any>([])
  const [loading, setLoading] = useState<any>(false)
  const [userdata, setUserdata] = useState<any>({
    store_status: '',
    registration_date: '',
    supplier_id: '',
    company_name: '',
    corporation_or_individual: '',
    shop_name: '',
    shop_name_kana: '',
    representative_name: '',
    establishment_date: '',
    fiscal_year_end: '',
    annual_sales: '',
    business_hours: '',
    regular_holiday: '',
    business_description: '',
    main_genre: '',
    secondary_genre: '',
    website_url: '',
    corporate_number: '',
    invoice_number: '',
    primary_contact_email: '',
    person_in_charge: '',
    person_in_charge_kana: '',
    person_in_charge_email: '',
    person_in_charge_phone: '',
    person_in_charge_phone_secondary: '',
    contact_person: '',
    contact_person_kana: '',
    contact_person_email: '',
    contact_person_phone: '',
    contact_person_phone_secondary: '',
    email_guidance: '',
    // payment_method: '',
    shop_introduction: '',
  }) // 表单数据

  const [categoryOptions, setCategoryOptions] = useState<any>([])
  const [parameters, setParameters] = useState<any>([])

  const typeOptions: any = {
    viewPath: {
      buyer: '/account/buyer-organization',
      supplier: '/account/organization',
    },
    editPath: {
      buyer: '/account/buyer-organization/edit',
      supplier: '/account/organization/edit',
    },
  }

  useEffect(() => {
    initData()
    getParameterStore()
  }, [])

  // 获取列表数据
  const initData = () => {
    setLoading(true)
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token}`,
    }

    accountInfoGet('auth.username', headers).then((res: any) => {
      console.log('res', res)
      if (res) {
        setUserdata(res)
        let headoffice = {
          title: '本社所在地',
          company_name: res.headoffice_company_name,
          company_name_kana: res.headoffice_company_name_kana,
          address_postal_code: res.headoffice_address_postal_code,
          address_prefecture: res.headoffice_address_prefecture,
          address_city: res.headoffice_address_city,
          address_street: res.headoffice_address_street,
          address_building_name: res.headoffice_address_building_name,
          tel: res.headoffice_tel,
          fax: res.headoffice_fax,
          manager_lastname: res.headoffice_manager_lastname,
          manager_firstname: res.headoffice_manager_firstname,
          manager_lastname_kana: res.headoffice_manager_lastname_kana,
          manager_firstname_kana: res.headoffice_manager_firstname_kana,
        }
        let billing = {
          title: 'ご請求先住所',
          company_name: res.billing_company_name,
          company_name_kana: res.billing_company_name_kana,
          address_postal_code: res.billing_address_postal_code,
          address_prefecture: res.billing_address_prefecture,
          address_city: res.billing_address_city,
          address_street: res.billing_address_street,
          address_building_name: res.billing_address_building_name,
          tel: res.billing_tel,
          fax: res.billing_fax,
          manager_lastname: res.billing_manager_lastname,
          manager_firstname: res.billing_manager_firstname,
          manager_lastname_kana: res.billing_manager_lastname_kana,
          manager_firstname_kana: res.billing_manager_firstname_kana,
        }
        let slip = {
          title: '伝票記載住所',
          company_name: res.slip_company_name,
          company_name_kana: res.slip_company_name_kana,
          address_postal_code: res.slip_address_postal_code,
          address_prefecture: res.slip_address_prefecture,
          address_city: res.slip_address_city,
          address_street: res.slip_address_street,
          address_building_name: res.slip_address_building_name,
          tel: res.slip_tel,
          fax: res.slip_fax,
          manager_lastname: res.slip_manager_lastname,
          manager_firstname: res.slip_manager_firstname,
          manager_lastname_kana: res.slip_manager_lastname_kana,
          manager_firstname_kana: res.slip_manager_firstname_kana,
        }
        setAddressList([headoffice, billing, slip])
        setLoading(false)
      }
    })
    productCategoryListGet({pageNum: 1, pageSize: 100}).then((res: any) => {
      const arr = filterTree(res.rows)
      setCategoryOptions(arr)
    })
  }

  const getParameterStore = async () => {
    let res: any = await parameterStoreGet({
      names: ['/Define/user/EmailNotifications', '/Define/General/Prefecture'],
    })
    const data = {
      emailNotifications: JSON.parse(res['/Define/user/EmailNotifications']),
      prefecture: JSON.parse(res['/Define/General/Prefecture']),
    }
    console.log('parameters', data)
    setParameters(data)
  }

  return (
    <>
      <Spin spinning={loading} tip='Processing' size='large'>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>アカウント情報詳細</h3>
            </div>
            <div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  navigate(`${typeOptions.editPath[type]}`, {state: '1'})
                }}
              >
                編集
              </button>
            </div>
          </div>
          <div className='card-body p-9'>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>店舗状態</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>
                  {userdata?.store_status == 'publish' ? '公開' : '非公開'}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>登録年月日</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.registration_date}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>加盟店ID</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.display_id}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>紹介者</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.referrer}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>会社名</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.company_name}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>法人／個人</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.corporation_or_individual}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>お店屋号</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.shop_name}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>お店屋号（カナ）</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.shop_name_kana}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>代表者名</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.representative_name}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>設立</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.establishment_date}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>決算期</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.fiscal_year_end}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>年商</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.annual_sales}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>営業時間</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.business_hours}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>定休日</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.regular_holiday}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>事業内容</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.business_description}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>メインの取り扱いジャンル</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>
                  {categoryOptions?.find((k: any) => k.id == userdata?.main_genre)?.name}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>取り扱いジャンル　その２</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>
                  {categoryOptions?.find((k: any) => k.id == userdata?.secondary_genre)?.name}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>ホームページURL</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.website_url}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>法人番号</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.corporate_number}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>適格請求書発行事業者登録番号</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.invoice_number}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>メインのメールアドレス</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.primary_contact_email}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>責任者</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.person_in_charge}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>責任者　フリガナ</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.person_in_charge_kana}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>責任者　メールアドレス</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.person_in_charge_email}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>責任者　電話番号</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.person_in_charge_phone}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>責任者　電話番号２</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.person_in_charge_phone_secondary}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>担当者</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.contact_person}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>担当者　フリガナ</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.contact_person_kana}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>担当者　メールアドレス</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.contact_person_email}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>担当者　電話番号</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.contact_person_phone}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>担当者　電話番号２</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.contact_person_phone_secondary}</span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>HatChupayからのメール案内</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>
                  {
                    parameters?.emailNotifications?.find(
                      (k: any) => k.id == userdata?.email_guidance
                    )?.label
                  }
                </span>
              </div>
            </div>
            {/* <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>決済方法</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.payment_method}</span>
              </div>
            </div> */}
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>ショップ紹介文</label>

              <div className='col-lg-8'>
                <span className='fs-6 text-dark'>{userdata?.shop_introduction}</span>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      {addressList && addressList.length ? (
        <>
          <CopmanyInfo
            type={2}
            parameters={parameters}
            company={addressList[0]}
            editPath={typeOptions.editPath[type]}
          />
          <CopmanyInfo
            type={3}
            parameters={parameters}
            company={addressList[1]}
            editPath={typeOptions.editPath[type]}
          />
          <CopmanyInfo
            type={4}
            parameters={parameters}
            company={addressList[2]}
            editPath={typeOptions.editPath[type]}
          />
        </>
      ) : null}
    </>
  )
}

function CopmanyInfo(props: any) {
  const navigate = useNavigate()
  return (
    <div className='card pt-4 mb-6 mb-xl-9'>
      <div className='card-header border-0'>
        <div className='card-title'>
          <h2 className='fw-bold mb-0'>{props.company.title}</h2>
        </div>
        <div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
          <button
            className='btn btn-primary align-self-center'
            onClick={() => {
              navigate(`${props.editPath}`, {state: props.type})
            }}
          >
            編集
          </button>
        </div>
      </div>
      <div className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>会社名・店名</label>
          <div className='col-lg-8'>{props.company.company_name}</div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>会社名・店名（フリガナ）</label>
          <div className='col-lg-8'>{props.company.company_name_kana}</div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>郵便番号</label>
          <div className='col-lg-8'>{props.company.address_postal_code}</div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>都道府県</label>
          <div className='col-lg-8'>
            {
              props.parameters?.prefecture?.find(
                (k: any) => k.id == props.company.address_prefecture
              )?.label
            }
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>市区町村</label>
          <div className='col-lg-8'>{props.company.address_city}</div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>丁目番地</label>
          <div className='col-lg-8'>{props.company.address_street}</div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>建物名</label>
          <div className='col-lg-8'>{props.company.address_building_name}</div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>電話番号</label>
          <div className='col-lg-8'>{props.company.tel}</div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>FAX番号</label>
          <div className='col-lg-8'>{props.company.fax}</div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>担当者 姓</label>
          <div className='col-lg-8'>{props.company.manager_lastname}</div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>担当者 名</label>
          <div className='col-lg-8'>{props.company.manager_firstname}</div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>担当者 姓（フリガナ）</label>
          <div className='col-lg-8'>{props.company.manager_lastname_kana}</div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>担当者 名（フリガナ）</label>
          <div className='col-lg-8'>{props.company.manager_firstname_kana}</div>
        </div>
      </div>
    </div>
  )
}
