import {useEffect, useState} from 'react'
import {useParams, useNavigate, useLocation, useSearchParams} from 'react-router-dom'
import {showSuccessMessage, showErrorMessage, showWarningMessage} from '@/app/components/sweetAlert'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {KTSVG} from '@/_metronic/helpers'
import {getCommonDetails} from 'src/app/modules/api'
import {
  productOptionItemAdd,
  productOptionItemUpdate,
  productOptionItemCopy,
} from 'src/app/modules/api'
import {FloatingLabel} from 'react-bootstrap'
import {useAuth} from '@/app/modules/auth'
import {useLayout} from '@/_metronic/layout/core'

const option = [
  {id: 'enabled', label: '有効'},
  {id: 'disabled', label: '無効'},
]

const option1 = [
  {id: 'select', label: 'プルダウン'},
  {id: 'checkbox', label: 'チェックボックス'},
  {id: 'radio', label: 'ラジオボタン'},
  {id: 'text', label: 'テキストフィールド'},
]
const validationSchema: any = Yup.object({
  id: Yup.string()
    .required('商品オプション項目IDは必須です。')
    .matches(/^[0-9]+$/, '半角数字のみで入力してください。'),
  name: Yup.string()
    .required('商品オプション項目名は必須です。')
    .min(1, '最小8文字')
    .max(255, '最大16文字'),
  // .matches(/^[a-zA-Z0-9/]+$/, '半角英数字のみで入力してください。'),
  // type: Yup.string().required('フォームタイプは必須です。'),
  // decstiprion: Yup.string().required('説明は必須です。'),
  // priority: Yup.string().matches(
  //   /-?(1[0-1][0-9]|12[0-7]|[0-9]{1,2})$/,
  //   '－127～127の歯に出入力してください。。'
  // ),
})

export default function ProductOptionDetail() {
  const {setContentLoading} = useLayout()
  const [flg, setflg] = useState<boolean>(true)
  const navigate = useNavigate()
  const {id} = useParams()
  const currentLocation = useLocation()
  const [getSearchArr] = useSearchParams()
  const searchValue = getSearchArr.getAll('copy')[0]
  const [initialValues, setInitialValues] = useState<any>({
    id: '',
    name: '',
    status: 'enabled',
    type: 'select',
    selection: [
      {
        value: '',
        label: '',
      },
      {
        value: '',
        label: '',
      },
    ],
    decstiprion: '',
  })
  const {auth} = useAuth()
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }

  const updateData = (fieldsToUpdate: Partial<any>) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    formik.setValues(updatedData)
  }

  useEffect(() => {
    initData()
  }, [])

  const initData = async () => {
    if (currentLocation.search != '') {
      setflg(false)
    }
    if (id == undefined && searchValue == undefined) {
      setflg(false)
    }
    if (id || searchValue) {
      setContentLoading(true)
      let res: any = await getCommonDetails({
        pkVal: currentLocation.state,
      })
      setContentLoading(false)
      updateData({
        pkVal: res.pk,
        id: res.id,
        name: res.name,
        status: res.status,
        type: res.type,
        selection: JSON.parse(res.selection),
        decstiprion: res.decstiprion,
      })
    }
  }

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('onSubmit', JSON.stringify(values))
      setContentLoading(true)
      setSubmitting(true)
      const obj = JSON.parse(JSON.stringify(values))
      obj.selection = JSON.stringify(obj.selection)
      if (id && searchValue == undefined) {
        productOptionItemUpdate(obj, {headers})
          .then((res: any) => {
            setContentLoading(false)
            showSuccessMessage(res.message)
            navigate('/product/option')
          })
          .catch((err: any) => {
            setContentLoading(false)
          })
      } else if (id && searchValue) {
        productOptionItemCopy(obj, {headers})
          .then((res: any) => {
            setContentLoading(false)
            if (res.message === '商品オプション項目IDが重複しています') {
              showErrorMessage('IDが重複しています')
            } else {
              showSuccessMessage(res.message)
              navigate('/product/option')
            }
          })
          .catch((err: any) => {
            setContentLoading(false)
          })
      } else {
        productOptionItemAdd(obj, {headers})
          .then((res: any) => {
            setContentLoading(false)
            if (res.message === '商品オプション項目IDが重複しています') {
              showErrorMessage('IDが重複しています')
            } else {
              showSuccessMessage(res.message)
              navigate('/product/option')
            }
          })
          .catch((err: any) => {
            setContentLoading(false)
          })
      }
    },
  })

  return (
    <div>
      <div className='container-fluid'>
        <form onSubmit={formik.handleSubmit}>
          <div className='card mb-6 shadow-sm'>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>商品オプション項目</span>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <div className='row mb-6'>
                    <div className='col-3'>
                      <label className='col-form-label required'>商品オプション項目ID</label>
                    </div>
                    <div className='col align-self-center'>
                      <input
                        className='form-control'
                        type='text'
                        placeholder='商品オプション項目ID'
                        disabled={flg ? true : false}
                        {...formik.getFieldProps('id')}
                      />
                      {formik.touched.id && formik.errors.id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.id}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <div className='col-3'>
                      <label className='col-form-label required'>商品オプション項目名</label>
                    </div>
                    <div className='col align-self-center'>
                      <input
                        className='form-control'
                        type='text'
                        placeholder='商品オプション項目名'
                        {...formik.getFieldProps('name')}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <div className='col-3'>
                      <label className='col-form-label'>状態</label>
                    </div>
                    <div className='col align-self-center'>
                      {option.map((item: any, index: number) => {
                        return (
                          <div className='form-check form-check-inline pe-3' key={index}>
                            <input
                              className='form-check-input'
                              type='radio'
                              value={item.id}
                              checked={formik.values.status === item.id}
                              onChange={() => {
                                formik.values.status = item.id
                                updateData(formik.values)
                              }}
                            />
                            <label className='form-check-label'>{item.label}</label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <div className='col-3'>
                      <label className='col-form-label'>フォームタイプ</label>
                    </div>
                    <div className='col align-self-center'>
                      {option1.map((item: any, index: number) => {
                        return (
                          <div className='form-check form-check-inline pe-3' key={index}>
                            <input
                              className='form-check-input'
                              type='radio'
                              value={item.id}
                              checked={formik.values.type === item.id}
                              onChange={() => {
                                formik.values.type = item.id
                                updateData(formik.values)
                              }}
                            />
                            <label className='form-check-label'>{item.label}</label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <div className='col-3'>
                      <label className='col-form-label'>選択肢の登録</label>
                    </div>
                    <div className='col align-self-center'>
                      <table style={{borderCollapse: 'collapse'}} className='table table-bordered'>
                        <thead style={{backgroundColor: '#7f7f7f'}}>
                          <tr>
                            <th scope='col' style={{color: '#fff', paddingLeft: 10}}>
                              値
                            </th>
                            <th scope='col' style={{color: '#fff', paddingLeft: 10}}>
                              表示名
                            </th>
                            <th scope='col' style={{width: 80, color: '#fff'}}></th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            borderCollapse: 'separate',
                            borderSpacing: '1px',
                          }}
                        >
                          {formik.values.selection.map((item: any, idx: number) => (
                            <tr key={idx}>
                              <td>
                                <input
                                  className='form-control'
                                  type='text'
                                  value={item.label}
                                  onChange={(e) => {
                                    item.label = e.target.value
                                    updateData(formik.values)
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  className='form-control'
                                  type='text'
                                  value={item.value}
                                  onChange={(e) => {
                                    item.value = e.target.value
                                    updateData(formik.values)
                                  }}
                                />
                              </td>
                              <td>
                                <button
                                  style={{
                                    display: formik.values.selection.length <= 1 ? 'none' : 'block',
                                  }}
                                  className='btn btn-icon btn-light-danger btn-sm'
                                  type='button'
                                  disabled={formik.values.selection.length <= 1}
                                  onClick={() => {
                                    formik.values.selection.splice(idx, 1)
                                    updateData(formik.values)
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr011.svg'
                                    className='svg-icon-muted svg-icon-2x'
                                  />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button
                        className='btn btn-icon btn-light-primary btn-sm'
                        style={{width: 150, margin: '10px 0px 0px 0px'}}
                        type='button'
                        onClick={() => {
                          let obj = {
                            value: '',
                            label: '',
                          }
                          formik.values.selection.push(obj)
                          updateData(formik.values)
                        }}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr009.svg'
                          className='svg-icon-muted svg-icon-5'
                        />
                        選択肢を追加する
                      </button>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <div className='col-3'>
                      <label className='col-form-label'>説明</label>
                    </div>
                    <div className='col align-self-center'>
                      <CKEditor
                        id='editor'
                        editor={ClassicEditor}
                        data={formik.values.decstiprion}
                        config={{
                          toolbar: [
                            'undo',
                            'redo',
                            '|',
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'link',
                            // "uploadImage",
                            'insertTable',
                            'blockQuote',
                            'mediaEmbed',
                            '|',
                            'bulletedList',
                            'numberedList',
                            'outdent',
                            'indent',
                          ],
                        }}
                        onReady={(editor: any) => {
                          // console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event: any, editor: any) => {}}
                        onBlur={(event: any, editor: any) => {
                          // console.log('Blur.', editor);
                          let data = editor.getData()
                          formik.values.decstiprion = data
                          updateData(formik.values)
                        }}
                        onFocus={(event: any, editor: any) => {
                          // console.log('Focus.', editor);
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className='row'>
                    <div className='col-3'>
                      <label className='col-form-label'>表示優先度</label>
                    </div>
                    <div className='col align-self-center'>
                      <input
                        className='form-control'
                        type='number'
                        placeholder='表示優先度'
                        {...formik.getFieldProps('priority')}
                        value={formik.values.priority}
                      />
                      {formik.touched.priority && formik.errors.priority && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.priority}</div>
                        </div>
                      )}
                    </div>
                  </div> */}
                  {/* <div className='row mb-6'>
                    <div className='col-3'>
                      <label className='col-form-label'></label>
                    </div>
                    <div className='col align-self-center'>
                      <span>－127～127の歯に出入力してください。。</span>
                      <br />
                      <span>入力画面などで数値の大きい項目から順に表示されます。</span>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* 按键组 */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <button
              className='btn btn-primary'
              type='button'
              style={{marginRight: '10px'}}
              onClick={() => navigate(-1)}
            >
              戻る
            </button>
            <button type='submit' className='btn btn-primary'>
              {flg ? '更新' : '登録'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
