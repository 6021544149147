import { getRoleGroupList } from '../core/_requests'


export const RoleGroupFilter = (role_group: any, list: any) => {
  const newList = JSON.parse(JSON.stringify(list))
  let arr = newList.data.filter((item:any) => {
    item.roles = item && item?.roles && JSON.parse(item?.roles);
    return role_group.includes(item.role_group_id)
  })
  console.log('arr',arr);
  let obj = JSON.parse(JSON.stringify(arr[0].roles)) as any;
  arr.reverse().forEach((item:any,idx:number)=>{
    for (let i in item.roles) {
      if(obj[i] === undefined){
        obj[i] = [];
      }
      // obj[i] = Array.from(new Set([...obj[i], ...item.roles[i]]));
      obj[i] = obj[i].concat(item.roles[i].filter((v:any) => !obj[i].includes(v)));
    }
  })
  // console.log('obj',obj);
  return obj;
}