import {postServer, putServer} from '@/service/http'

export const getListMedia = async (params?: any) => {
  return postServer(`/media-filter`, {...params}, {headers: {'Content-Type': 'application/json'}})
}

export const createMedia = async (data: {
  parent?: string
  name: string
  path: string
  isFolder: 0 | 1
  size?: number
}) => {
  return postServer('/media-create', data, {
    headers: {'Content-Type': 'application/json'},
  })
}

export const deleteMedia = async (pks: string[]) => {
  return putServer('/media-delete', {pks}, {headers: {'Content-Type': 'application/json'}})
}

export const updateMedia = async (media: {pk: string; name: string}) => {
  return putServer('/media-update', media, {
    headers: {'Content-Type': 'application/json'},
  })
}

export const downloadMedia = async (pk: string) => {
  return postServer('/media-url-get', {pk}, {headers: {'Content-Type': 'application/json'}})
}
