import { getServer, postServer, deleteServer } from 'src/service/http'

// 顧客ランクの一覧を取得する
export function customerRankListGet(param: object, headers: object = {}) {
    return getServer(`/customer-rank-list-get`, { ...param }, {...headers})
}

// 顧客ランク情報を新規追加する
export function customerRankAdd(param: object, headers: object = {}) {
    return postServer(`/customer-rank-add`, { ...param }, {...headers})
}

// 顧客ランク情報をコピーする
export function customerRankCopy(param: object, headers: object = {}) {
    return postServer(`/customer-rank-copy`, { ...param }, {...headers})
}

// 顧客ランク情報を更新する
export function customerRankUpdate(param: object, headers: object = {}) {
    return postServer(`/customer-rank-update`, { ...param }, {...headers})
}

// 顧客ランク情報を削除する
export function customerRankDelete(param: object, headers: object = {}) {
    return deleteServer(`/customer-rank-delete`, { ...param }, {...headers})
}