import React, { useRef, useState, useEffect,lazy } from 'react'
import { Navigate, Route, Routes, Outlet, useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { PageLink, PageTitle } from 'src/_metronic/layout/core'
import { ProductInfo } from './components/ProductInfo'
import { ProductVariation }   from './components/ProductVariation'
import { ProductLeft } from './ProductLeft'
import { productInfoGet } from 'src/app/modules/api'
import { useAlert } from 'src/_metronic/layout/core'

export default function ProductRegist() {
  const navigate = useNavigate()
  const {id} = useParams()
  const { setMessage } = useAlert()
  const currentLocation = useLocation()
  const [getSearchArr] = useSearchParams();
  const searchValue = getSearchArr.getAll('copy')[0]
  const leftRef: any = useRef('null')
  const [falg, setFalg] = useState<boolean>(false)
  
  // 商品画像コンテキストを作成
  const [productMainImage, setProductMainImage] = useState('')

  // const imageProps = {
  //   image: productMainImage,
  //   setImage: setProductMainImage
  // };

  // const navS = (e:any)=>{
  //  if(currentLocation.state){
    //  let nav:any =  document.getElementById("nav")
    //  nav.click()
    //  e.target.classList.add('active')
    //  setFalg(true)
  //  }else {
    //  let nav1:any =  document.getElementById("nav1")
    //  nav1.click()
    //  setMessage({ type: 'error', message: '商品基本情報を登録してください'})
  //  }
  // }

  return (
    <div className='row'>
      {/* <div className='col-lg-3 mb-5'>
        <ProductLeft ref={leftRef} props={imageProps} />
      </div> */}
      <div className='col-lg-12'>
        <div className='container-fluid p-0'>
          {/* {id !== 'edit' && (
          <div className='card mb-5'>
            <div className='card-body pt-0 pb-0'>
              <div className='d-flex overflow-auto h-55px'>
                <ul className="w-100 d-flex nav nav-tabs nav-line-tabs nav-stretch nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                  <li className="nav-item">
                    <a
                      className="btn nav-link text-active-primary me-6 active"
                      id="nav1"
                    >
                      商品基本情報登録
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="btn nav-link text-active-primary me-6"
                      onClick={(e) => { e.preventDefault(); navigate(`/product/${id}/variation`) }}
                    >
                      バリエーション情報登録
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          )} */}

          <ProductInfo />

          {/* <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="kt_tab_pane_3"
              role="tabpanel"
            >

            </div>
            <div
              className="tab-pane fade"
              id="kt_tab_pane_4"
              role="tabpanel"
            >
              {falg && <ProductVariation />}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

