import React, {useEffect, useState, useRef} from 'react'
import {
  useFormik,
  FormikProvider,
  Form,
  useFormikContext,
  useField,
  FieldArray,
  FieldHookConfig,
} from 'formik'
import {connect} from 'http2'
import {dir} from 'console'

export default function DataTransferList() {
  const formik = useFormik({
    initialValues: {
      checked: [],
    },
    onSubmit: (values) => {
      console.log(values)
    },
  })

  const editMenu = [
    {
      name: '編集',
      action: 'edit',
    },
    {
      name: '削除',
      action: 'delete',
    },
  ]

  const handleCheck = (e: any) => {
    const {name, checked} = e.target
    if (checked) {
      formik.setFieldValue('checked', [...formik.values.checked, name])
    } else {
      formik.setFieldValue(
        'checked',
        formik.values.checked.filter((value: any) => value !== name)
      )
    }
  }

  return (
    <FormikProvider value={formik}>
      <div className='card'>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table table-row-bordered align-middle gy-4 gs-9'>
              <thead className='border-bottom border-gray-200 fs-6 fw-bold bg-light bg-opacity-75'>
                <tr>
                  <td>接続名</td>
                  <td>接続タイプ</td>
                  <td>頻度</td>
                  <td>ステータス</td>
                  <td>操作</td>
                </tr>
              </thead>
              <tbody className=''>
                <tr>
                  <td>受注データ出力</td>
                  <td>出力</td>
                  <td>毎日</td>
                  <td>有効</td>
                  <td>
                    <select className='form-select form-select-sm'>
                      {editMenu.map((menu) => (
                        <option value={menu.action}>{menu.name}</option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </FormikProvider>
  )
}
