/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import React from 'react'
import {PageLink, PageTitle} from '@/_metronic/layout/core'
import {useNavigate} from 'react-router-dom'
import {
  cartDataGet,
  productFavoritesDelete,
  productFavoritesAdd,
  cartItemDelete,
  cartDataUpdate,
  parameterStoreGet,
} from 'src/app/modules/api'
import {useLayout} from 'src/_metronic/layout/core'
import {useAuth} from 'src/app/modules/auth'
import {useAlert} from 'src/_metronic/layout/core'
import {Steps} from './components/Steps'
import './style.css'
import {useAccount} from 'src/app/modules/accounts/AccountProvider'
import {formatNumber} from 'src/app/modules/utils'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'ホーム',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },

  {
    title: '商品一覧',
    path: '/buy/product',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Pageconts: FC = () => {
  const navigate = useNavigate()
  const {setMessage} = useAlert()
  const [shippingError, setShippingError] = useState<boolean>(false)
  const [showType, setShowType] = useState<any>(false)
  const [role, setRole] = useState<string | undefined>()
  const [dataList, setDataList] = useState<any>([])
  const [totalData, setTotalData] = useState({
    productPriceAll: 0,
    shippingPriceAll: 0,
    endTaxPrice: [],
    endPrice: 0,
    taxInPrice: 0,
  })
  const {isContentLoading, setContentLoading} = useLayout()
  const {account, setAccount} = useAccount()

  const [selectedList, setSelectList] = useState<any>([])
  const {auth} = useAuth()
  const [s3Url, setS3Url] = useState('')

  const getParameterStore = async () => {
    try {
      const res: any = await parameterStoreGet({names: ['/Define/Common']}, {})
      setS3Url(JSON.parse(res['/Define/Common'])?.S3URL)
    } catch (error) {
      console.error('Failed to fetch parameter store', error)
    }
  }

  // まとめてカートの追加
  const addShoppingCartL = async (selectedList: any) => {
    setContentLoading(true)
    let obj: any = {}
    if (selectedList.length == 1) {
      obj = {
        buyer_id: auth.group_id,
        product_id: selectedList[0].product_id,
        quantity: selectedList[0].quantity?.toString(),
        type: 0,
        variation_id: selectedList[0].variation_id,
      }
    } else {
      obj = {
        type: 1,
        buyer_id: auth.group_id,
        list: selectedList,
      }
    }

    let resp: any = await cartDataUpdate(obj)
    // console.log('resp', resp)
    if (resp.message == 'success') {
      // カート商品個数を更新

      setAccount((prev: any) => ({
        ...prev,
        cartCount: resp.count,
        cartItem: resp.itemList,
        cartKey: resp.cartkey,
      }))

      setMessage({type: 'success', message: '更新しました'})
      getData()
    } else if (resp.message == 'out of stock') {
      setMessage({type: 'error', message: '在庫が不足しています'})
    }
    setContentLoading(false)
  }

  // 在庫＋１
  const addQuantity = (item: any) => {
    let obj = selectedList.find((obj: any) => obj.variation_id == item.variation_id)
    if (obj) {
      obj.quantity++
    }
    setSelectList([...selectedList])
  }
  // 在庫-1
  const reduceQuantity = (item: any) => {
    let obj = selectedList.find((obj: any) => obj.variation_id == item.variation_id)
    if (obj && obj.quantity > 1) {
      obj.quantity--
    }
    setSelectList([...selectedList])
  }

  const getData = async () => {
    setContentLoading(true)
    const res: any = await parameterStoreGet({names: ['/Define/Product/Units']}, {})
    const parameters = JSON.parse(res['/Define/Product/Units'])

    cartDataGet({shippingPk: ''})
      .then((res: any) => {
        console.log('res.rows', res.rows)
        let newDataList = res.rows || []
        newDataList.forEach((e: any) => {
          e.list.forEach((k: any) => {
            if (parameters && parameters.length > 0) {
              k.unit = parameters.find((j: any) => j.id == k.unit)?.label
            }
          })
        })

        setDataList(newDataList)
        setTotalData(res.total)

        const newSelectList = newDataList.flatMap((item: any) => {
          return item.list.map((shopItem: any) => ({
            product_id: shopItem.product_id,
            quantity: shopItem.quantity,
            variation_id: shopItem.variation_id,
          }))
        })

        setAccount((prev: any) => ({
          ...prev,
          cartCount: res.count,
          cartKey: res.cartkey,
          cartItem: res.itemList,
        }))

        setSelectList(newSelectList || [])
        setContentLoading(false)
      })
      .catch((err) => {
        if (err.response) {
          // サーバーからのレスポンスがある場合
          console.log('Error response:', err.response.data)
          if (err.response.data.message === 'default shipping address is empty') {
            setShippingError(true)
          }
        } else {
          // サーバーからのレスポンスがない場合（ネットワークエラーなど）
          console.log('Error:', err.message)
        }
        setContentLoading(false)
      })
  }

  useEffect(() => {
    getData()
    getParameterStore()
  }, [])

  const clickOnFavorites = async (itemAll: any, item: any, index: any) => {
    // console.log(item)

    if (item.isCollect) {
      // console.log(item, 2)
      // ら削除
      let isCollect = (await productFavoritesDelete({
        // update_user: supplier.update_user,
        // user_id: supplier.update_user,
        variation_id: item.variation_id,
        product_id: item.product_id,
      })) as {message: string}
      // console.log(isCollect)

      if (isCollect.message == 'success') {
        setMessage({type: 'info', message: '「いつもの商品」を解除しました'})
        item.isCollect = false
        itemAll[index] = item
        setDataList([...dataList])
      }
    } else {
      let isCollect = (await productFavoritesAdd(
        {
          variation_id: item.variation_id,
          product_id: item.product_id,
        },
        {}
      )) as {message: string}

      if (isCollect.message == 'success') {
        setMessage({type: 'info', message: '「いつもの商品」に追加しました'})
        item.isCollect = true
        itemAll[index] = item
        setDataList([...dataList])
      }
    }
  }

  return (
    <>
      <Steps StepNo={1} />
      <div className='alert alert-primary d-flex align-items-center p-5'>
        <div className='d-flex flex-column'>
          <span>
            送料はデフォルト住所へ配送した場合の送料です。実際の送料は「決済方法の選択」にてご確認ください。
          </span>
        </div>
      </div>
      <div className=' card-body py-4'>
        <div className='cart-detail-box'>
          {shippingError && (
            <div className='alert alert-danger w-100'>
              <div className='row'>
                <div className='col-12'>
                  <span>デフォルト配送先が設定されていません。住所を設定してください。</span>
                </div>
                <div className='col-12 mt-4'>
                  <button
                    className='btn btn-primary'
                    onClick={() => {
                      navigate('/account/shipping')
                    }}
                  >
                    配送先を登録
                  </button>
                </div>
              </div>
            </div>
          )}
          {!shippingError && dataList?.length === 0 && (
            <div className='card p-5 l-box text-center'>
              {!isContentLoading && 'ショッピングカートに商品が入っていません。'}
            </div>
          )}
          {!shippingError && dataList?.length > 0 && (
            <div className='l-box'>
              {dataList.map((item: any, itemIndex: number) => (
                <div className='card p-5 mb-5' key={item.id}>
                  <h2>{item.name}</h2>
                  <div className='mt-2'>
                    <div className='table-responsive mb-5'>
                      <table className='table no-footer table-row-bordered wid'>
                        <thead className='fs-7'>
                          <tr className='bg-light'>
                            <th className='w-50 ps-4'>商品情報</th>
                            <th>注文数</th>
                            <th>小計</th>
                            <th className='pe-4'>消費税</th>
                          </tr>
                        </thead>
                        <tbody className='table-row-bordered'>
                          {item?.list.map((shopItem: any, shopItemIndex: number) => (
                            <tr key={`${itemIndex}-${shopItemIndex}}`}>
                              <td>
                                <div>
                                  <div className='row'>
                                    <div className='col-3 col-lg-2'>
                                      {shopItem.product_main_image &&
                                      shopItem.product_main_image.indexOf('.') != -1 ? (
                                        <img
                                          src={
                                            s3Url +
                                            '/' +
                                            encodeURIComponent(
                                              shopItem.supplier_pk.split('#Suppliers#')?.[1]
                                            ) +
                                            shopItem.product_main_image
                                          }
                                          className='mw-100'
                                        />
                                      ) : (
                                        <div
                                          style={{
                                            width: 50,
                                            height: 50,
                                            objectFit: 'cover',
                                            background: '#F5F8FA',
                                            border: '1px solid #CBCCCC',
                                          }}
                                        ></div>
                                      )}
                                    </div>

                                    <div className='col-9 col-lg-10 d-flex flex-wrap'>
                                      <div className='col-12 mb-2'>
                                        <a
                                          href='#'
                                          className='fw-bold link-underline-primary'
                                          onClick={(e) => {
                                            e.preventDefault()
                                            navigate(`/buy/product/${shopItem.product_id}`, {
                                              state: {pk: shopItem.product_id, grade: shopItem?.grade},
                                            })
                                          }}
                                        >
                                          {shopItem.product_name}
                                        </a>
                                      </div>
                                      <div className='col-12 mb-2 px-2 bg-light'>
                                        {shopItem.variation_name}
                                      </div>
                                      <div className='col-12 mb-2'>品番: {shopItem.sku}</div>
                                      <div className='col-12'>
                                        <div className='row'>
                                          <div className='col-3'>
                                            <a
                                              // className='btn btn-white btn-active-success mx-2'
                                              className={
                                                shopItem.isCollect
                                                  ? 'btn btn-sm btn-white btn-warning border w-100'
                                                  : 'btn btn-sm btn-white btn-active-warning border w-100'
                                              }
                                              onClick={() => {
                                                clickOnFavorites(item, shopItem, itemIndex)
                                              }}
                                            >
                                              <i className='bi bi-heart p-0'></i>
                                            </a>
                                          </div>
                                          <div className='col-3'>
                                            <a
                                              className='btn btn-sm btn-white btn-active-warning border w-100'
                                              onClick={() => {
                                                setContentLoading(true)
                                                cartItemDelete({
                                                  variation_id: shopItem.variation_id,
                                                  product_id: shopItem.product_id,
                                                  pkVal: shopItem.pk,
                                                }).then((res: any) => {
                                                  if (res?.message) {
                                                    setMessage({
                                                      type: 'success',
                                                      message: res.message,
                                                    })
                                                  }
                                                  getData()
                                                  setContentLoading(false)
                                                })
                                              }}
                                            >
                                              <i className='bi bi-trash p-0'></i>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td className='border border-1'>
                                <div className='row'>
                                  <div className='col-6'>
                                    <div className='row'>
                                      <div className='col-12'>
                                        {shopItem.price_range_min && shopItem.price_range_max ? (
                                          <span>
                                            {formatNumber(shopItem.price_range_min)} ~{' '}
                                            {formatNumber(shopItem.price_range_max)}
                                          </span>
                                        ) : (
                                          <span>{formatNumber(shopItem.unit_price)}</span>
                                        )}
                                        円
                                      </div>
                                      <div className='col-12 fs-8'>(単位：{shopItem.unit})</div>
                                    </div>
                                  </div>
                                  <div className='col-6'>
                                    <div className='d-flex align-items-center'>
                                      <button
                                        type='button'
                                        className='btn btn-icon btn-sm btn-light btn-icon-gray-500'
                                        onClick={() => {
                                          reduceQuantity(shopItem)
                                        }}
                                      >
                                        <i className='bi bi-dash fs-3 p-0'></i>
                                      </button>
                                      <input
                                        type='text'
                                        className='col form-control border-0 text-center px-0 fs-4 fw-bold text-gray-800 w-30px'
                                        name='manageBudget'
                                        placeholder='Amount'
                                        // value={shopItem.quantity}
                                        value={
                                          selectedList.find(
                                            (obj: any) => obj.variation_id == shopItem.variation_id
                                          )?.quantity
                                        }
                                        onChange={(e) => {
                                          console.log(e.target.value)
                                          shopItem.quantity = e.target.value
                                        }}
                                      />
                                      <button
                                        type='button'
                                        className='btn btn-icon btn-sm btn-light btn-icon-gray-500'
                                        onClick={() => {
                                          addQuantity(shopItem)
                                        }}
                                      >
                                        <i className='bi bi-plus fs-3 p-0'></i>
                                      </button>
                                    </div>
                                    <div className='row justify-content-end'>
                                      <div className='col-12'>
                                        <button
                                          className='btn btn-sm btn-white btn-sm w-100 border'
                                          onClick={() => {
                                            let obj: any = {}
                                            obj = selectedList.map((selectedItem: any) => {
                                              if (
                                                selectedItem.variation_id == shopItem.variation_id
                                              ) {
                                                if (
                                                  !Number.isInteger(
                                                    parseInt(selectedItem.quantity)
                                                  ) ||
                                                  selectedItem.quantity < 1
                                                ) {
                                                  setMessage({
                                                    type: 'error',
                                                    message:
                                                      '数量は1以上の整数で入力してください。',
                                                  })
                                                  return
                                                }
                                                if (selectedItem.quantity != shopItem.quantity) {
                                                  let newQuantityDiff =
                                                    selectedItem.quantity - shopItem.quantity
                                                  return {
                                                    ...selectedItem,
                                                    quantity: newQuantityDiff,
                                                  }
                                                }
                                              }
                                            })

                                            obj = obj.filter((obj: any) => obj != undefined)
                                            if (obj.length > 0) {
                                              addShoppingCartL(obj)
                                            }
                                          }}
                                        >
                                          更新
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td className='sp-none text-end'>
                                {formatNumber(shopItem.calcPrice)} 円
                              </td>
                              <td className='sp-none text-end' style={{position: 'relative'}}>
                                {formatNumber(shopItem.taxPrice)} 円
                                {/* <span className='sml-text'>※軽減税率対象商品</span> */}
                              </td>
                              <td className='pc-none text-end'>
                                （小計）{formatNumber(shopItem.calcPrice)} 円
                                <span style={{display: 'block'}}>
                                  （消費税）{formatNumber(shopItem.taxPrice)} 円
                                </span>
                              </td>
                            </tr>
                          ))}
                          {item?.shipping?.map((shippingItem: any) => (
                            <tr key={shippingItem.id}>
                              <td colSpan={2}>{shippingItem.name}</td>
                              <td className='text-end'>
                                {formatNumber(shippingItem.shippingPrice)} 円
                              </td>
                              <td className='text-end'>
                                {formatNumber(shippingItem.shippingTaxPrice)} 円
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className='w-500px' style={{margin: '0 0 0 auto'}}>
                      <table
                        className='table no-footer table-row-bordered'
                        style={{textAlign: 'right'}}
                      >
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>送料合計</td>
                            <td>{formatNumber(item.shippingPrice)} 円</td>
                          </tr>

                          {item.supplierTaxPrice?.map((taxItem: any, taxItemIndex: number) => (
                            <tr key={taxItemIndex}>
                              <td></td>
                              <td></td>
                              <td>{taxItem.name}</td>
                              <td>{formatNumber(taxItem.taxInPrice)} 円</td>
                            </tr>
                          ))}
                          {/* <tr>
                            <td></td>
                            <td></td>
                            <td>割引</td>
                            <td>{item.splitPrice} 円</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>ポイント</td>
                            <td>{item.pound}pt</td>
                          </tr> */}
                          <tr>
                            <td></td>
                            <td></td>
                            <td>総計（税込）</td>
                            <td>
                              <span className='fw-bold' style={{color: 'red'}}>
                                {formatNumber(item.supplierPrice)} 円
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className='card p-4 r-box'>
            <h3 style={{textAlign: 'center'}}>総合計</h3>
            <table className='table align-middle no-footer text-center table-row-bordered'>
              <tbody>
                <tr>
                  <th>商品小計</th>
                  <td>{formatNumber(totalData.productPriceAll)} 円</td>
                </tr>
                {totalData.endTaxPrice?.map((item: any, index: number) => (
                  <tr key={index}>
                    <th>{item.name}</th>
                    <td>{formatNumber(item.taxInPrice)} 円</td>
                  </tr>
                ))}
                <tr>
                  <th>送料</th>
                  <td>{formatNumber(totalData.shippingPriceAll)} 円</td>
                </tr>
                <tr>
                  <th>総合計</th>
                  <td className='fs-3' style={{color: 'red'}}>
                    <strong>{formatNumber(totalData.endPrice)} 円</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            {dataList.length !== 0 && (
              <button
                className='btn btn-dark'
                style={{width: 200, border: '1px solid', margin: '0 auto'}}
                disabled={dataList.length === 0}
                onClick={() => {
                  navigate('/buy/checkout')
                  sessionStorage.removeItem('cartData')
                  sessionStorage.removeItem('falgIndex')
                }}
              >
                決済選択へ
              </button>
            )}

            <button
              className='btn btn-white mt-4'
              style={{width: 200, border: '1px solid', margin: '0 auto'}}
              onClick={() => {
                navigate('/buy/product')
              }}
            >
              お買い物を続ける
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

const Cart: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>買い物かご</PageTitle>
      <Pageconts />
    </>
  )
}

export default Cart
