import { getServer, postServer, deleteServer } from 'src/service/http'

// 利用者アカウント情報取得
export function buyerSettingGet(param: any, headers: object = {}) {
    return getServer('/buyer-setting-get', {param} ,{...headers});
  }
  //利用者共同设定更新
  export function buyerSettingUpdatePost(param: object,headers: object) {
    return postServer('/buyer-setting-update', { ...param },{...headers});
  }

  export function buyerInfoUpdate(param: object) {
    return postServer('/buyer-info-update', { ...param });
  }
  
  // 利用者
export function buyerInfoAdd(json: object) {
  let obj:any = {...json}
  // for(let i in buyer){
  //   buyer[i] =obj[i]
  // }
  return postServer('/buyer-info-add', obj);
}