import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '@/_metronic/helpers'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from '@/app/components/sweetAlert'
import * as Yup from 'yup'
import {useFormik} from 'formik'

const options1: any = [
  {id: 0, label: '表示しない'},
  {id: 1, label: '表示する'},
]

export default function CrossSellManagement() {
  const [initialValues, setInitialValues] = useState<any>({
    productTopShow: 0,
    productDetailBottomShow: 0,
    productTopRecommend1: '',
    productTopRecommend2: '',
    productBottomRecommend1: '',
    productBottomRecommend2: '',
    cartBottomRecommend1: '',
    cartBottomRecommend2: '',
    orderRecommend1: '',
    orderRecommend2: '',
  })
  const formik: any = useFormik({
    initialValues: initialValues,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  const updateData = (fieldsToUpdate: Partial<any>) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    setInitialValues(updatedData)
    formik.setValues(updatedData)
  }

  return (
    <div className='container-fluid'>
      <div className='card mb-6 shadow-sm'>
        <div className='d-flex align-items-center card-header'>
          <span className='card-title fw-bold m-0'>クロスセル管理</span>
        </div>
        <div className='collapse show'>
          <form
            id='kt_modal_add_user_form'
            className='container-fluid'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='card-body p-9'>
              <div className='mb-6'>
                <div className='form-label fs-5 fw-bold mb-4'>商品一覧画面の上段に出す広告枠</div>
                <p>優先6商品まで表示可能です。</p>
                <div className='mb-6'>
                  {options1.map((item: any) => {
                    return (
                      <div
                        className='form-check form-check-inline pe-3'
                        key={item.id}
                      >
                        <input
                          className='form-check-input'
                          type='radio'
                          value={item.id}
                          checked={formik.values.productTopShow === item.id}
                          onChange={() => {
                            formik.values.productTopShow = item.id
                            updateData(formik.values)
                          }}
                        />
                        <label className='form-check-label'>{item.label}</label>
                      </div>
                    )
                  })}
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-3 col-form-label  fs-6'>
                    <label className='col-form-label'>レコメンド</label>
                    <br />
                    <span>優先</span>
                  </label>

                  <div className='col align-self-center'>
                    <input
                      className='form-control'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('productTopRecommend1')}
                      value={formik.values.productTopRecommend1}
                    />
                    <span>商品IDを指定してレコメンドを表示させることができます。</span>
                    <br />
                    <span>(カンマ区切りで複数入力可)</span>
                    <input
                      className='form-control'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('productTopRecommend2')}
                      value={formik.values.productTopRecommend2}
                    />
                    <span>商品IDを指定してレコメンドを表示させることができます。</span>
                    <br />
                    <span>(カンマ区切りで複数入力可)</span>
                  </div>
                </div>
              </div>

              <div className='mb-6'>
                <div className='form-label fs-5 fw-bold mb-4'>商品詳細の下段に出す広告枠</div>
                <p>優先6商品まで表示可能です。</p>
                <div className='mb-6'>
                  {options1.map((item: any) => {
                    return (
                      <div
                        className='form-check form-check-inline pe-3'
                        key={item.id}
                      >
                        <input
                          className='form-check-input'
                          type='radio'
                          value={item.id}
                          checked={formik.values.productDetailBottomShow === item.id}
                          onChange={() => {
                            formik.values.productDetailBottomShow = item.id
                            updateData(formik.values)
                          }}
                        />
                        <label className='form-check-label'>{item.label}</label>
                      </div>
                    )
                  })}
                </div>
                <div className='row'>
                  <label className='col-lg-3 col-form-label  fs-6'>
                    <span>レコメンド</span>
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <input
                      className='form-control'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('productBottomRecommend1')}
                      value={formik.values.productBottomRecommend1}
                    />
                    <span>商品IDを指定してレコメンドを表示させることができます。</span>
                    <br />
                    <span>(カンマ区切りで複数入力可)</span>
                    <input
                      className='form-control'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('productBottomRecommend2')}
                      value={formik.values.productBottomRecommend2}
                    />
                    <span>商品IDを指定してレコメンドを表示させることができます。</span>
                    <br />
                    <span>(カンマ区切りで複数入力可)</span>
                  </div>
                </div>
              </div>

              <div className='mb-6'>
                <div className='form-label fs-5 fw-bold mb-4'>お買い物かごの下段に出す広告枠</div>
                <div className='row'>
                  <label className='col-lg-3 col-form-label  fs-6'>
                    <span>レコメンド</span>
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <input
                      className='form-control'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('cartBottomRecommend1')}
                      value={formik.values.cartBottomRecommend1}
                    />
                    <span>商品IDを指定してレコメンドを表示させることができます。</span>
                    <br />
                    <span>(カンマ区切りで複数入力可)</span>
                    <input
                      className='form-control'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('cartBottomRecommend2')}
                      value={formik.values.cartBottomRecommend2}
                    />
                    <span>商品IDを指定してレコメンドを表示させることができます。</span>
                    <br />
                    <span>(カンマ区切りで複数入力可)</span>
                  </div>
                </div>
              </div>

              <div className='mb-6'>
                <div className='form-label fs-5 fw-bold mb-4'>注文完了画面に出す広告枠</div>
                <div className='row'>
                  <label className='col-lg-3 col-form-label  fs-6'>
                    <span>レコメンド</span>
                  </label>

                  <div className='col-lg-9 fv-row'>
                    <input
                      className='form-control'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('orderRecommend1')}
                      value={formik.values.orderRecommend1}
                    />
                    <span>商品IDを指定してレコメンドを表示させることができます。</span>
                    <br />
                    <span>(カンマ区切りで複数入力可)</span>
                    <input
                      className='form-control'
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('orderRecommend2')}
                      value={formik.values.orderRecommend2}
                    />
                    <span>商品IDを指定してレコメンドを表示させることができます。</span>
                    <br />
                    <span>(カンマ区切りで複数入力可)</span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
