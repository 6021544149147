import {AuthModel} from './_models'
// import { useNavigate } from 'react-router-dom'

// const navigate = useNavigate()
const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    // 获取当前时间
    const time = new Date().getTime()
    const auth = JSON.parse(lsValue) as any
    if (auth && time < auth.expire) {
      // You can easily check auth_token expiration also
      return auth
    } else {
      // console.log(666);
      localStorage.clear()
      window.location.href = window.location.origin + '/auth'
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const time = 24 * 60 * 60 * 1000 // 1小时之后失效
    // const time = 20 * 1000; // 20秒之后失效
    const expire = new Date().getTime() + time
    const lsValue = JSON.stringify({...auth, expire})
    // const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}
export function setupAxios(axios: any) {
  axios.interceptors.request.use((config: any) => {
    const auth = getAuth() as any
    if (auth && auth.api_token) {
      config.headers['x-api-key'] = auth.api_token
    }
    return config
  })
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
