import React, {useState, useEffect} from 'react'
import {useField, useFormikContext} from 'formik'
import {Modal} from 'react-bootstrap'
import {
  productInfoGet,
  productVariationInfoGet,
  productGroupGet,
  productCustomItemListGet,
  labelListGet,
  productCategoryListGet,
  parameterStoreGet,
} from 'src/app/modules/api'
import {useParams} from 'react-router-dom'
import {toAbsoluteUrl} from 'src/_metronic/helpers'
import {Skeleton} from 'antd'
import {useAuth} from '@/app/modules/auth'

// PreviewModalのプロパティインターフェース
interface PreviewModalProps {
  show: boolean
  product?: any
  variation?: any
  handleModalShow: () => void
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  show,
  product,
  variation,
  handleModalShow,
  ...props
}) => {
  const {auth} = useAuth()
  const formik = useFormikContext<any>()
  const {id} = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [statusShow, setStatusShow] = useState<boolean>(false)
  const [previewData, setPreviewData] = useState<any>({
    product: {},
    variation: [],
    group: [],
    options: [],
    recommendList: [],
  })
  const [masterData, setMasterData] = useState<any>({})
  const recommendationsToShow = 4
  const [s3Url, setS3Url] = useState('')
  const mediaUrl = s3Url + '/' + encodeURIComponent(auth.group_id.split('#Suppliers#')?.[1])

  const getParameterStore = async () => {
    try {
      const res: any = await parameterStoreGet({names: ['/Define/Common']}, {})
      setS3Url(JSON.parse(res['/Define/Common'])?.S3URL)
    } catch (error) {
      console.error('Failed to fetch parameter store', error)
    }
  }

  const handleStatusClose = () => {
    setStatusShow(false)
    handleModalShow()
  }

  const initdata = async () => {
    setLoading(true)

    if (product) {
      setPreviewData((prev: any) => ({...prev, product}))
      productVariationInfoGet({product_id: product.pk.split('#').pop()}).then((res: any) => {
        if (res) {
          setPreviewData((prev: any) => ({...prev, variation: res.rows}))
        }
      })
    }
    if (variation) {
      setPreviewData((prev: any) => ({...prev, variation: variation}))
      productInfoGet({pkVal: id}).then((res: any) => {
        if (res) {
          res.product_custom_field = JSON.parse(res.product_custom_field)
          res.categories = JSON.parse(res.categories)
          setPreviewData((prev: any) => ({...prev, product: {...res}}))
        }
      })
    }

    const groupData: any = await productGroupGet({})
    const customFieldData: any = await productCustomItemListGet({
      type: 'product',
      pageNum: 1,
      pageSize: 100,
    })

    setPreviewData((prev: any) => ({...prev, group: groupData.rows, options: customFieldData.rows}))
  }

  const initMaster = async () => {
    const labelOptions: any = await labelListGet({pageNum: 1, pageSize: 100})
    const categories: any = await productCategoryListGet({pageNum: 1, pageSize: 100})
    setMasterData((prev: any) => ({
      ...prev,
      labelOptions: labelOptions.rows,
      categories: categories.rows,
    }))
  }

  useEffect(() => {
    initMaster()
    initdata()
    getParameterStore()
  }, [])

  useEffect(() => {
    // console.log('previewData', previewData)
    if (previewData.product && previewData.variation && previewData.group && previewData.options) {
      setLoading(false)
      setStatusShow(show)
    }
  }, [previewData.product, previewData.variation, previewData.group, previewData.options])

  return (
    <>
      <Modal
        show={statusShow}
        onHide={handleStatusClose}
        dialogClassName='modal-dialog-centered mw-850px'
      >
        <div>
          <div className='container-xxl px-0'>
            <div className='card mb-12'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-12 col-md-12 col-lg-6 mb-6 mb-sm-6 mb-md-6'>
                    {loading ? (
                      <Skeleton.Image active style={{minHeight: '500px'}} className='w-100' />
                    ) : (
                      <img
                        className='w-100 mw-100'
                        // src={previewData.product.main_image || toAbsoluteUrl('/media/products/sample.png')}
                        src={
                          previewData.product.main_image
                            ? mediaUrl + previewData.product.main_image
                            : toAbsoluteUrl('/media/products/sample.png')
                        }
                      />
                    )}
                  </div>
                  <div className='col-md-12 col-lg-6 col-md-12'>
                    <div>
                      <div
                        className='mb-6'
                        style={{display: 'flex', justifyContent: 'space-between'}}
                      >
                        <div>
                          <h1 className='d-flex flex-column gap-2'>
                            {loading ? (
                              <>
                                <Skeleton.Button active style={{width: '100px'}} />
                                <Skeleton.Button active style={{width: '150px'}} />
                                <Skeleton.Button active style={{width: '180px'}} />
                              </>
                            ) : (
                              <>
                                <span className='fs-6'>{previewData.product.shop_name}</span>
                                <span className='fs-4'>{previewData.product.product_name}</span>
                                <span className='fs-4'>
                                  商品番号：{previewData.product.product_id}
                                </span>
                              </>
                            )}
                          </h1>
                          <div>
                            {loading && previewData.product.label ? <Skeleton.Button active /> : ''}
                            {!loading &&
                              masterData.labelOptions &&
                              Array.isArray(previewData.product.label) &&
                              previewData.product.label &&
                              previewData.product.label.map((value: any, idx: any) => (
                                <span
                                  className='badge badge-light-dark py-3 px-10 me-3 mb-3'
                                  key={idx}
                                >
                                  {
                                    masterData.labelOptions.find((item: any) => item.pk === value)
                                      ?.name
                                  }
                                </span>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className='mb-10'>
                        {loading ? <Skeleton.Button active /> : <h1 className='fs-4'>価格</h1>}
                        {loading ? (
                          <div className='row d-flex align-items-center my-4 p-2 border border-dashed'>
                            <div className='col col-4 d-flex align-middle flex-column'>
                              <Skeleton.Button
                                active
                                style={{width: '30px'}}
                                className='fw-semibold fs-6 mb-2'
                              />
                              <Skeleton.Button
                                active
                                style={{width: '60px'}}
                                className='fw-semibold fs-6 '
                              />
                            </div>
                            <div className='col col-6 flex-grow-1 d-flex align-items-center pe-0'>
                              <Skeleton.Input active style={{width: '60px'}} />
                              <Skeleton.Button active style={{width: '30px', marginLeft: '5px'}} />
                              <Skeleton.Button active style={{width: '30px', marginLeft: '5px'}} />
                              <span className='d-flex align-items-center'>
                                <em className='ki-duotone ki-plus-square fs-2x'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                </em>
                                <em className='ki-duotone ki-minus-square fs-2x'>
                                  <span className='path1' />
                                  <span className='path2' />
                                  <span className='path3' />
                                </em>
                              </span>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        {console.log(previewData.variation)}
                        {!loading &&
                          previewData.variation &&
                          previewData.variation.length !== 0 &&
                          Array.isArray(previewData.variation) &&
                          previewData.variation.map((item: any, index: number) => {
                            {
                              console.log(item)
                            }
                            return (
                              <div
                                className='row d-flex align-items-center my-4 p-2 border border-dashed'
                                key={index}
                              >
                                <div className='col col-6 d-flex align-middle flex-column'>
                                  {loading ? (
                                    <>
                                      <Skeleton.Button
                                        active
                                        style={{width: '30px'}}
                                        className='fw-semibold fs-6 mb-2'
                                      />
                                      <Skeleton.Button
                                        active
                                        style={{width: '60px'}}
                                        className='fw-semibold fs-6 '
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <span className='fw-semibold fs-6 '>
                                        {item.variation_product_name}
                                      </span>
                                      <span className='fw-semibold fs-6 '>
                                        {item.number_per_case}
                                        {item.unit}
                                      </span>
                                      <strong className='fs-6' style={{color: 'red'}}>
                                        {item.unit_price || '0'}円
                                      </strong>
                                    </>
                                  )}
                                </div>
                                <div className='col col-6 flex-grow-1 d-flex justify-content-end align-items-center pe-0'>
                                  {loading ? (
                                    <>
                                      <Skeleton.Input active style={{width: '60px'}} />
                                      <Skeleton.Button
                                        active
                                        style={{
                                          width: '30px',
                                          marginLeft: '5px',
                                        }}
                                      />
                                      <Skeleton.Button
                                        active
                                        style={{
                                          width: '30px',
                                          marginLeft: '5px',
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px my-5'
                                      >
                                        <i
                                          className='bi bi-dash-lg fs-4 text-black'
                                          data-kt-docs-datatable-subtable='expand_row'
                                        ></i>
                                      </button>
                                      <span style={{margin: '0px 10px'}}>1</span>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px my-5'
                                      >
                                        <i
                                          className='bi bi-plus-lg fs-4 text-black'
                                          data-kt-docs-datatable-subtable='expand_row'
                                        ></i>
                                      </button>

                                      <a
                                        className='btn btn-white btn-active-light'
                                        style={{
                                          float: 'left',
                                          width: 30,
                                          height: 40,
                                          border: '1px solid',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          marginLeft: '20px',
                                        }}
                                      >
                                        <i className='bi bi-bag p-0'></i>
                                      </a>
                                      <a
                                        className='btn btn-white '
                                        style={{
                                          float: 'left',
                                          width: 30,
                                          height: 40,
                                          border: '1px solid',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          marginLeft: '10px',
                                        }}
                                      >
                                        <i className='bi bi-heart p-0'></i>
                                      </a>
                                    </>
                                  )}

                                  <span className='d-flex align-items-center'>
                                    <em className='ki-duotone ki-plus-square fs-2x'>
                                      <span className='path1' />
                                      <span className='path2' />
                                      <span className='path3' />
                                    </em>
                                    <em className='ki-duotone ki-minus-square fs-2x'>
                                      <span className='path1' />
                                      <span className='path2' />
                                      <span className='path3' />
                                    </em>
                                  </span>
                                </div>
                              </div>
                            )
                          })}
                      </div>

                      <div className='mb-10'>
                        {loading ? (
                          <>
                            <Skeleton.Button active style={{width: '30px'}} className='mb-3' />
                            <Skeleton active />
                          </>
                        ) : (
                          <>
                            <h1 className='fs-4'>商品説明</h1>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  (previewData.product.upper_free_space || '') +
                                  (previewData.product.middle_free_space || '') +
                                  (previewData.product.lower_free_space || ''),
                              }}
                            />
                          </>
                        )}
                      </div>

                      <div className='mb-6'>
                        {loading ? (
                          <>
                            <Skeleton.Button active style={{width: '30px'}} className='mb-3' />
                            <Skeleton active />
                          </>
                        ) : (
                          <>
                            <h1 className='fs-4'>商品スペック</h1>
                            <div className='table-responsive'>
                              <table className='table'>
                                <thead>
                                  <tr className='fw-bold fs-6 text-center border-0 bg-light'>
                                    <th>項目</th>
                                    <th>値</th>
                                  </tr>
                                </thead>
                                <tbody className='border-bottom border-dashed'>
                                  {previewData.options &&
                                    previewData?.product?.product_custom_field &&
                                    previewData.options &&
                                    Array.isArray(previewData.product.product_custom_field) &&
                                    previewData.product?.product_custom_field.map(
                                      (item: any, index: number) => {
                                        const matchedOption = previewData.options.find(
                                          (option: {id: any}) => option.id === item.id
                                        )
                                        const name = matchedOption ? matchedOption.name : ''

                                        return (
                                          <tr className='fw-semibold fs-6 ' key={index}>
                                            <td className='min-w-60px text-center'>{name}</td>
                                            <td className='text-center'>{item.value}</td>
                                          </tr>
                                        )
                                      }
                                    )}
                                </tbody>
                              </table>
                              <table className='table'>
                                <tbody className='border-bottom border-dashed'>
                                  <tr className='fw-semibold fs-6 '>
                                    <td>商品カテゴリー:</td>
                                    <td>
                                      {masterData.categories &&
                                        Array.isArray(previewData.product.categories) &&
                                        previewData.product.categories?.length && (
                                          <p>
                                            {previewData.product.categories.map(
                                              (category: any, index: number) => {
                                                {
                                                  console.log(masterData.categories)
                                                }
                                                return (
                                                  <div key={index}>
                                                    {}
                                                    {category[0] &&
                                                      masterData.categories.find(
                                                        (item: any) => item.pk === category[0]
                                                      )?.name}
                                                    {category[1] &&
                                                      ' / ' +
                                                        masterData.categories.find(
                                                          (item: any) => item.pk === category[1]
                                                        )?.name}
                                                    {category[2] &&
                                                      ' / ' +
                                                        masterData.categories.find(
                                                          (item: any) => item.pk === category[2]
                                                        )?.name}
                                                  </div>
                                                )
                                              }
                                            )}
                                          </p>
                                        )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className='mb-10'>
                      {previewData.product.notes && previewData.product.notes !== '' && loading ? (
                        <>
                          <Skeleton.Button active style={{width: '30px'}} className='mb-3' />
                          <Skeleton active />
                        </>
                      ) : (
                        <>
                          <h1 className='fs-4'>注意事項</h1>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: previewData.product.notes || '',
                            }}
                          ></div>
                        </>
                      )}
                    </div>
                    <div className='mb-10'>
                      <h1 className='fs-4'>配送グループ</h1>{' '}
                      {previewData.group &&
                        previewData.group.filter(
                          (item: any) =>
                            item.delivery_master_id === previewData.variation.shipping_group
                        ).length > 0 &&
                        previewData.group.filter(
                          (item: any) =>
                            item.delivery_master_id === previewData.variation.shipping_group
                        )[0].delivery_master_name}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card mb-12'>
              <div className='card-header border-0 py-3 align-items-center bg-light'>
                <div className='col col-6'>
                  <h1 className='fs-4 mb-0'>こちらの商品もおすすめ</h1>
                </div>
                <div className='col col-6 d-flex justify-content-end gap-4 px-0'>
                  <button
                    className='btn btn-primary'
                    type='button'
                    data-bs-target='#offcanvas-1'
                    data-bs-toggle='offcanvas'
                    disabled
                  >
                    もっと見る
                  </button>
                </div>
              </div>
              <div className='card-body'>
                <div className='row'>
                  {previewData.product.recommendList && previewData.product.recommendList.length > 0
                    ? previewData.product.recommendList
                        .slice(0, recommendationsToShow)
                        .map((item: any, index: number | null | undefined) => {
                          return (
                            <div key={index} className='col-6 col-lg-3 col-xl-3 col-xxl-3 mb-4'>
                              <div className='mb-4 position-relative'>
                                <img
                                  className='w-100'
                                  src={toAbsoluteUrl('/media/products/sample.png')}
                                />
                              </div>
                              <div>
                                <div>
                                  <p className='fs-7 mb-0'>{item.shop_name}</p>
                                  <h1 className='fs-3'>{item.product_name}</h1>
                                  <p className='fs-6'>{item.catch_copy}</p>
                                  <strong>
                                    <span className='text-danger'>
                                      {' '}
                                      {item.minPrice || 0}円 ~ {item.maxPrice || 0}円{/* (税込) */}
                                    </span>
                                  </strong>
                                </div>
                              </div>
                            </div>
                          )
                        })
                    : null}
                </div>
              </div>
            </div>
          </div>
          <button
            type='button'
            className='btn btn-primary  px-6'
            style={{margin: '0 auto 20px', display: 'block'}}
            onClick={() => {
              handleStatusClose()
            }}
          >
            閉じる
          </button>
        </div>
      </Modal>
    </>
  )
}

export default PreviewModal
