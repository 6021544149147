import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from '../../../components/sweetAlert'
import {useLayout} from 'src/_metronic/layout/core'
import {
  getBuyerInfo,
  latterpaymentsGet,
  entrytran2,
  exectran2,
  entrytranvirtualaccount,
  exectranvirtualaccount,
  paymentStatusUpdate,
  getCreditInfo,
} from 'src/app/modules/api'
import {useIsWidthSize, formatNumber, getParameterStore} from 'src/app/modules/utils'
import BankCardLatterPayment from '@/app/components/bankCard/bankCardPayment/bankCardPaymentLatterPayment'
import {
  useFormik,
  FormikProvider,
  Form,
  useFormikContext,
  useField,
  FieldArray,
  FieldHookConfig,
} from 'formik'
import {use} from 'echarts'
import {di} from '@fullcalendar/core/internal-common'
import {Prev} from 'react-bootstrap/esm/PageItem'
import moment from 'moment'

let status: any = '0'
let payments: any = []

interface SupplierPayments {
  laterPayments: {
    [supplier_id: string]: {
      total: number
      company_name: string
      display_id: string
      list: {[payment_id: string]: PaymentData}
    }
  }
  waitingPayments: {
    [supplier_id: string]: {
      company_name: string
      display_id: string
      list: {[payment_id: string]: PaymentData}
    }
  }
}

interface PaymentData {
  order_id: string
  display_id: string
  order_date: string
  payment_amount: number
  payment_status: string
}

export default function LaterPayment() {
  const [latterPayments2, setlatterPayments2] = useState(0)
  const [laterPaymentAmount, setLaterPaymentAmount] = useState(0)
  const [isShow, setIsShow] = useState(1)
  const [isWidthLg, setIsWidthLg] = useState(true)
  const [pageGetData, setPageGetData] = useState<any>({card: null, text: null})
  const {isContentLoading, setContentLoading} = useLayout()
  const navigate = useNavigate()
  const [cardList, setCardList] = useState<any>({})
  const [supplierData, setSupplierData] = useState<any>({})
  const [initialValues, setInitialValues] = useState<any>({})
  const formik = useFormik<any>({
    initialValues: initialValues || {},
    onSubmit: (values) => {
      console.log('values', values)
      navigate('/later/checkout/confirm', {state: {...values.supplier_items}, replace: true})
    },
    enableReinitialize: true,
  })

  useEffect(() => {
    getBuyerInfo({}).then((res: any) => {
      // console.log(res.pk)
      latterpaymentsGet(res.pk, {}).then((response: any) => {
        let payments: SupplierPayments
        payments = {
          laterPayments: {},
          waitingPayments: {},
        }

        // 加盟店ごとに支払い金額を設定
        // 後払いと振り込み待ちで分ける
        // console.log('response', response)
        response.payments.forEach((payment: any) => {
          if (payment.payment_status === 'later_payment') {
            // [payment.supplier_id].totalがない場合は初期化

            if (!payments.laterPayments[payment.supplier_id]) {
              payments.laterPayments[payment.supplier_id] = {
                total: 0,
                company_name: payment.company_name,
                display_id: payment.supplier_display_id,
                list: {},
              }
            }

            // const supplierPayments = payments.laterPayments[payment.supplier_id][0]
            const supplierPayments = payments.laterPayments[payment.supplier_id]

            // totalにpayment_amountを追加
            supplierPayments.total += payment.payment_amount

            // laterPayments.listにpaymentを追加
            supplierPayments.list[payment.pk] = {
              order_id: payment.order_id,
              display_id: payment.display_id,
              order_date: payment.order_date,
              payment_amount: payment.payment_amount,
              payment_status: payment.payment_status,
            }
          }

          if (payment.payment_status === 'bank_virtual_account_wait') {
            if (!payments.waitingPayments[payment.supplier_id]) {
              payments.waitingPayments[payment.supplier_id] = {
                company_name: payment.company_name,
                display_id: payment.supplier_display_id,
                list: {},
              }
            }

            payments.waitingPayments[payment.supplier_id].list[payment.pk] = {
              order_id: payment.order_id,
              display_id: payment.display_id,
              order_date: payment.order_date,
              payment_amount: payment.payment_amount,
              payment_status: payment.payment_status,
            }
          }
        })

        setInitialValues((prev: any) => {
          return {
            ...prev,
            supplier_items: payments.laterPayments,
            supplier_waiting_items: payments.waitingPayments,
            _parameters: {},
            _cardList: {},
          }
        })
      })
    })
  }, [])

  useEffect(() => {
    console.log('initialValues', initialValues)
  }, [initialValues])

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          {formik.values.supplier_items &&
            Object.keys(formik.values.supplier_items).map((key: string, index: number) => (
              <SectionSuppliers
                key={key}
                supplier_data={formik.values.supplier_items[key]}
                dataKey={key}
                index={index}
              />
            ))}
          <div className='row'>
            <div className='mt-4 d-flex w-100 justify-content-between'>
              <button
                className='btn btn-white'
                style={{border: '1px solid'}}
                onClick={() => {
                  navigate('/dashboard', {replace: true})
                }}
              >
                ダッシュボードに戻る
              </button>

              <button
                type='submit'
                className='btn btn-dark d-flex align-items-center justify-content-center'
                style={{border: '1px solid'}}
                onClick={() => {
                  // goToOrderDetail()
                }}
              >
                お支払い内容確認
                <KTSVG
                  path='/media/icons/duotune/arrows/arr001.svg'
                  className='svg-icon-muted svg-icon-fs-2qx ms-2'
                />
              </button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </>
  )
}

interface SectionSuppliersProps {
  supplier_data: any
  dataKey: string
  index: number
}

const SectionSuppliers: React.FC<SectionSuppliersProps> = ({...props}) => {
  useEffect(() => {
    // console.log('props', props)
  })

  return (
    <>
      {useIsWidthSize('lg') ? (
        <PaymentSupplierAreaSP {...props} />
      ) : (
        <PaymentSupplierAreaSP {...props} />
      )}
    </>
  )
}

const PaymentSupplierArea: React.FC<SectionSuppliersProps> = ({...props}) => {
  return <></>
}

const PaymentSupplierAreaSP: React.FC<SectionSuppliersProps> = ({...props}) => {
  const [index, setIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showDetail, setShowDetail] = useState(false)
  const [supplier_data, setSupplierData] = useState<any>({})
  const formik: any = useFormikContext()
  const [cardList, setCardList] = useState<any>({})

  useEffect(() => {
    setLoading(true)
    // let key = Object.keys(formik.values['supplier_data'])[key]
    // console.log('key', props.dataKey)
    if (props.dataKey) {
      setSupplierData(props.supplier_data)
    }
  }, [])

  // useEffect(() => {
  //   console.log('key', key)

  //   if (key !== '0') {
  //     setSupplierData(formik.values['supplier_items'][key])
  //   }
  // }, [key])

  useEffect(() => {
    // console.log('supplier_data', supplier_data)
    if (Object.keys(supplier_data).length !== 0) {
      console.log('supplier_data', supplier_data)

      formik.setFieldValue(`supplier_items.${props.dataKey}.payment_method`, 'bank_transfer')

      getCreditInfo({
        type: 0,
        id: props.dataKey,
      }).then((res: any) => {
        let defaultCard = ''
        for (const v of res.data) {
          if (v.defaultFlag == 'true') {
            defaultCard = v.cardSeq
            break
          }
          if (!defaultCard) {
            defaultCard = res.data[0].cardSeq
          }
        }

        formik.setFieldValue(`_cardList.${props.dataKey}`, res.data)
        formik.setFieldValue(`supplier_items.${props.dataKey}.cardSeq`, defaultCard)
      })

      setLoading(false)
    }
  }, [supplier_data])

  // useEffect(() => {
  //   console.log('loading', loading)
  // }, [loading])

  return (
    <>
      {!loading && (
        <div className='card mb-4'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>{supplier_data.company_name}</h3>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
                <span className='fw-bold'>支払金額</span>
              </div>
              <div className='col-12 col-lg-8 d-flex align-items-center'>
                <span className='fw-bold' style={{color: 'red'}}>
                  {formatNumber(supplier_data.total)}円
                </span>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0 py-2'>
                <span className='fw-bold'>金額の内訳</span>
              </div>
              <div className='col-12 col-lg-8'>
                <div className='row'>
                  <div className='col-12 py-2'>
                    <button
                      type='button'
                      className='btn btn-sm btn-white btn-outline'
                      onClick={() => setShowDetail(!showDetail)}
                    >
                      {showDetail ? '閉じる' : '内訳を表示'}
                    </button>
                  </div>
                </div>

                {showDetail && (
                  <div className='row py-2'>
                    {Object.keys(supplier_data.list).length > 0 &&
                      Object.keys(supplier_data.list).map((item: any, idx: any) => (
                        <div key={idx} className='col-12 d-flex align-items-center'>
                          <div className='me-2'>
                            {moment(supplier_data.list[item].order_date).format('YYYY-MM-DD')}
                          </div>
                          <div className='me-2'>{supplier_data.list[item].display_id}</div>
                          <div className='me-2'>
                            {formatNumber(supplier_data.list[item].payment_amount)} 円
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0 py-2'>
                <span className='fw-bold'>支払方法</span>
              </div>
              <div className='col-12 col-lg-8 d-flex align-items-center py-2'>
                <CustomRadioGroup
                  label=''
                  name={`supplier_items.${props.dataKey}.payment_method`}
                  options={[
                    {id: 'bank_transfer', label: '銀行振込'},
                    {id: 'credit_card', label: 'クレジットカード'},
                  ]}
                />
              </div>
            </div>
            {formik.values['supplier_items'][props.dataKey]?.payment_method &&
              formik.values['supplier_items'][props.dataKey]?.payment_method === 'credit_card' && (
                <div className='row'>
                  <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
                    <span className='fw-bold'>クレジットカード</span>
                  </div>
                  <div className='col-12 col-lg-8 d-flex align-items-center'>
                    <select
                      className='form-select form-select-sm w-auto mw-100 mb-2'
                      value={formik.values['supplier_items'][props.dataKey].cardSeq}
                      onChange={(e) => {
                        // console.log('desired_delivery_date', e.target.value)
                        formik.setFieldValue(
                          `supplier_items.${props.dataKey}.cardSeq`,
                          e.target.value
                        )
                      }}
                    >
                      <option value=''>利用するカードを選択</option>
                      {formik.values._cardList[props.dataKey]?.map(
                        (cardItem: any, cardIdx: any) => (
                          <option key={cardIdx} value={cardItem.cardSeq}>
                            {cardItem.holderName}
                            {cardItem.cardNo}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  )
}

interface RadioGroupProps {
  label: string
  name: string
  options: {id: string; label: string}[]
}

const CustomRadioGroup: React.FC<RadioGroupProps> = ({label, name, options}) => {
  const [field, meta] = useField(name)
  const {setFieldValue} = useFormikContext()

  return (
    <div className='row'>
      <div className='col-12 align-self-center'>
        {options.map((option) => (
          <div className='form-check form-check-inline pe-3' key={option.id}>
            <input
              className='form-check-input'
              type='radio'
              {...field}
              value={option.id}
              checked={field.value === option.id}
              onChange={() => setFieldValue(name, option.id)}
            />
            <label className='form-check-label'>{option.label}</label>
          </div>
        ))}
        {meta.touched && meta.error ? (
          <div className='col-12 fv-plugins-message-container'>
            <div className='fv-help-block'>{meta.error}</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
