import {useEffect, useState} from 'react'
import ShippingAddressModal from './components/shipping-address-modal'
import {getAddress, parameterStoreGet, deleteAddress} from 'src/app/modules/api'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from 'src/app/components/sweetAlert'
import {Spin} from 'antd'

export function DeliveryInfo() {
  const [loading, setLoading] = useState<any>(false)
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState<any>([])
  const [pageData, setPageData] = useState<any>({})
  const [parameters, setParameters] = useState<any>([])

  useEffect(() => {
    initData()
    getParameter()
  }, [])

  const getParameter = async () => {
    const res: any = await parameterStoreGet({names: ['/Define/General/Prefecture']}, {})
    const parameters = JSON.parse(res['/Define/General/Prefecture'])
    console.log('parameters', res, parameters)
    setParameters(parameters)
  }
  const initData = () => {
    setLoading(true)
    getAddress({}).then((val: any) => {
      setData([...val?.rows])
    })
    setLoading(false)
  }
  const handleClose = (): void => {
    setShowModal(false)
  }
  const handleShow = (): void => {
    setShowModal(true)
  }
  const openModel = (item: any) => {
    setPageData(item)
    handleShow()
  }

  const deletePage = (item: any) => {
    showWarningMessage(
      '元に戻すことはできません。よろしいでしょうか？',
      () => {
        deleteAddress({pkVal: item.pk}).then((res: any) => {
          console.log('res', res)
          showSuccessMessage(res?.message)
          initData()
          // showSuccessMessage('削除しました')
        })
      },
      '削除',
      'キャンセル'
    )
  }

  return (
    <>
      {showModal && (
        <ShippingAddressModal
          showModal={showModal}
          handleClose={handleClose}
          apply={initData}
          pageData={pageData}
        />
      )}
      <Spin spinning={loading} tip='Processing' size='large'>
        <div className='card pt-4 mb-6 mb-xl-9'>
          <div className='card-header border-0'>
            <div className='card-title'>
              <h2 className='fw-bold mb-0'>配送先一覧</h2>
            </div>
            <div className='card-toolbar'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  openModel({})
                }}
              >
                追加
              </button>
            </div>
          </div>
          <div id='kt_customer_view_payment_method' className='card-body pt-0'>
            <div className='row gx-9 gy-6'>
              {data.length !== 0 &&
                data.map((item: any, index: number) => {
                  return (
                    <div className='col-xl-6' data-kt-billing-element='address' key={index}>
                      <div
                        className={'card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6'}
                      >
                        <div className='d-flex flex-column py-2'>
                          <div className='d-flex align-items-center fs-5 fw-bold mb-3'>
                            {item.default == 'true' ? (
                              <div className='badge badge-light-primary me-3'>規定の住所</div>
                            ) : (
                              <></>
                            )}
                            {/* #{item.id} */}
                          </div>
                          <div className='fs-6 fw-semibold '>
                            {item.lastname + item.firstname}
                            <br />
                            {item.address_postal_code}
                            <br />
                            {parameters?.find((k: any) => k.value == item.address_prefecture)
                              ?.label +
                              item.address_city +
                              item.address_street +
                              item.address_building_name}
                            <br />
                            {item.tel}
                            <br />
                          </div>
                        </div>
                        <div className='d-flex align-items-center py-2'>
                          <button
                            className='btn btn-sm btn-primary btn-active-light-primary me-3'
                            onClick={() => openModel(item)}
                          >
                            <span className='indicator-label'>編集</span>
                          </button>

                          <button
                            className='btn btn-sm btn-danger btn-active-light-primary me-3'
                            onClick={(e) => {
                              deletePage(item)
                            }}
                          >
                            <span className='indicator-label'>削除</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </Spin>
    </>
  )
}
