import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '@/_metronic/layout/core'
import {Link} from 'react-router-dom'

import {Settings} from './settings'
import {Invoice} from './invoice'
import {Slip} from './slip'
import {Receipt} from './receipt'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'ホーム',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: '設定',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TabContents: FC = () => {
  return (
    <>
      <div className='card mb-9'>
        <div className='card-body pt-1 pb-0'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link className={`nav-link text-active-primary active`} to='#settings' data-bs-toggle="tab">
                共通設定
              </Link>
            </li>
            <li className='nav-item'>
              <Link className={`nav-link text-active-primary`} to='#invoice' data-bs-toggle="tab">
                請求書
              </Link>
            </li>
            <li className='nav-item'>
              <Link className={`nav-link text-active-primary`} to='#slip' data-bs-toggle="tab">
                納品書
              </Link>
            </li>
            <li className='nav-item'>
              <Link className={`nav-link text-active-primary`} to='#receipt' data-bs-toggle="tab">
                領収書
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default function LedgerSetting() {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>帳票設定</PageTitle>
      <TabContents />
      <div className='tab-content'>
        <Settings />
        <Invoice />
        <Slip />
        <Receipt />
      </div>
    </>
  )
}
