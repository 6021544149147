// アカウント情報を提供するコンテキスト
import {use} from 'echarts'
import React, {createContext, useContext, useState, useEffect} from 'react'
import {useAuth} from '../auth'
import {
  getBuyerInfo,
  accountInfoGet,
  accountUserInfoGet,
  cartCountGet,
  supplierOrderReport,
} from 'src/app/modules/api'
import {cl} from '@fullcalendar/core/internal-common'

interface AccountProviderProps {
  children: React.ReactNode
}

interface AccountContextType {
  account: any
  setAccount: (account: any) => void
}

const AccountContext = createContext<AccountContextType | undefined>(undefined)

const AccountProvider = ({children}: AccountProviderProps) => {
  const [account, setAccount] = useState<any>(null)
  const {auth} = useAuth()
  const initialAccountState = {
    companyName: '' as string,
    userName: '' as string,
    companyImage: '' as string,
    role: [] as string[],
    cartCount: null as number | null,
    cartKey: '' as string,
    cartItem: [] as any[],
    menuAttributes: {} as any,
  }

  useEffect(() => {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token}`,
    }

    const updateAccount = async () => {
      let update = {...initialAccountState}

      if (auth.role.includes('buyer')) {
        try {
          const res = (await getBuyerInfo({}, headers)) as any
          const resAccount = (await accountUserInfoGet()) as any
          const cartCount = (await cartCountGet({})) as any
          let companyName = res.company_name ? res.company_name.trim() : ''
          let lastName = resAccount.lastname ? resAccount.lastname.trim() : ''
          let firstName = resAccount.firstname ? resAccount.firstname.trim() : ''

          update.companyName = companyName
          if (lastName || firstName) {
            update.userName = [lastName, firstName].filter(Boolean).join(' ')
          } else {
            update.userName = ''
          }
          update.companyImage = res.main_image
          update.role.push('buyer')
          update.cartCount = cartCount.count | 0
          update.cartKey = cartCount.cartkey
          update.cartItem = cartCount.itemList
        } catch (error) {
          console.error('Error fetching buyer info:', error)
        }
      }

      if (auth.role.includes('supplier')) {
        try {
          const res = (await accountInfoGet('', headers)) as any
          const resAccount = (await accountUserInfoGet()) as any
          const supplierOrder = (await supplierOrderReport({get: ['unReadOrders']})) as any
          let companyName = res.company_name ? res.company_name.trim() : ''
          let lastName = resAccount.lastname ? resAccount.lastname.trim() : ''
          let firstName = resAccount.firstname ? resAccount.firstname.trim() : ''
          let menuAttributes = {
            unread_order: supplierOrder.data.unReadOrders.toString(),
          }

          update.companyName = companyName
          if (lastName || firstName) {
            update.userName = [lastName, firstName].filter(Boolean).join(' ')
          } else {
            update.userName = ''
          }
          update.companyImage = res.main_image
          update.role.push('supplier')
          update.menuAttributes = menuAttributes
        } catch (error) {
          console.error('Error fetching supplier info:', error)
        }
      }

      setAccount(update)
    }

    updateAccount()
  }, [auth.token, auth.role])

  return (
    <AccountContext.Provider value={{account, setAccount}}>
      {account === null ? <div>Loading...</div> : children}
    </AccountContext.Provider>
  )
}

// カスタムフックを作成
const useAccount = () => {
  const context = useContext(AccountContext)
  if (context === undefined) {
    throw new Error('useAccount must be used within a AccountProvider')
  }
  return context
}

export {AccountProvider, useAccount}
