import {FC, useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {MenuInner} from './header-menus'

const HeaderLogo: FC = () => {
  const {config} = useLayout()

  return (
    <div className='header-logo px-6 d-none d-lg-flex justify-content-center align-items-center'>
      <a href='/'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/logo.png')}
          className='app-sidebar-logo-default theme-light-show mw-100'
        />
      </a>
    </div>
  )
}

export {HeaderLogo}
