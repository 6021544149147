import {getServer, postServer, deleteServer} from 'src/service/http'

// 加盟店
export function supplierInfoAdd(json: object) {
  let obj: any = {...json}
  return postServer('/supplier-info-add', obj)
}

export function supplierInfoUpdate(json: object) {
  let obj: any = {...json}
  return postServer('/supplier-info-update', obj)
}

// リージョン取得
export function supplierRegionListAll(param: object, headers: object = {}) {
  return getServer(`/supplier-region-list-all`, {...param}, {...headers})
}

// 新着通知登録
export function newNotificationRegister(param: object) {
  return postServer(`/new-notification-register`, {...param})
}

// 売上取得
export function supplierSalesReport(param: object) {
  return postServer(`/supplier-sales-report`, {...param})
}

// 受注速報取得
export function supplierOrderReport(param: object) {
  return postServer(`/supplier-order-report`, {...param})
}
