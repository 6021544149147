import { getServer, postServer, deleteServer } from 'src/service/http'

// カスタム項目 --------------------------------------------------

// カスタム項目の一覧を取得する
export function productCustomItemListGet(param: object, headers: object = {}) {
    return getServer(`/product-custom-item-list-get`, { ...param }, { ...headers })
}

// カスタム項目情報を新規追加する
export function productCustomItemAdd(param: object, headers: object = {}) {
    return postServer(`/product-custom-item-add`, { ...param }, { ...headers })
}

// カスタム項目情報をコピーする
export function productCustomItemCopy(param: object, headers: object = {}) {
    return postServer(`/product-custom-item-copy`, { ...param }, { ...headers })
}

// カスタム項目情報を更新する
export function productCustomItemUpdate(param: object, headers: object = {}) {
    return postServer(`/product-custom-item-update`, { ...param }, { ...headers })
}

// カスタム項目情報を削除する
export function productCustomItemDelete(param: object) {
    return deleteServer(`/product-custom-item-delete`, { ...param })
}