import {FC, useRef, useState, useEffect, Fragment} from 'react'
import {KTSVG, toAbsoluteUrl} from '@/_metronic/helpers'
import {Navigate, Route, Routes, Outlet, useNavigate} from 'react-router-dom'
import {
  buyerProductDataListGet,
  buyerProductVariationGet,
  cartDataUpdate,
  buyerProductSearchCategoriesGet,
  buyerProductSearchSuppliersGet,
  productFavoritesDelete,
  productFavoritesAdd,
  productFavoritesGet,
  parameterStoreGet,
} from 'src/app/modules/api'
import {Pagination} from 'src/app/components/pagination'
import {useLayout} from 'src/_metronic/layout/core'
import {useAuth} from 'src/app/modules/auth'
import {useAlert} from 'src/_metronic/layout/core'
import {calculatePrice} from './utils/index'
import CategoryTree from './components/categoryTree'
import {filterTree} from 'src/utils/filter'
import {useIsWidthSize} from 'src/app/modules/utils'
import {useAccount} from 'src/app/modules/accounts/AccountProvider'
import ProcessButton from 'src/app/components/part/ProcessButton'
import {formatNumber} from 'src/app/modules/utils'

const OrderingProductList: FC = () => {
  const {account, setAccount} = useAccount()
  const {setMessage} = useAlert()
  const navigate = useNavigate()
  const {isContentLoading, setContentLoading} = useLayout()
  const [queryParams, setQueryParams] = useState<any>({
    name: '',
    supplierParam: '',
    category: '',
    sortFlg: '1',
    buyer_id: '',
    buyer_pk: '',
    page: 1,
    pageSize: 40,
    total: 0,
  })
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams({...queryParams, name: event.target.value})
  }
  const [listData, setListData] = useState<any>([])
  const [supplierlistData, setSupplierlistData] = useState<any>([])
  const [categorylistData, setCategorylistData] = useState<any>([])
  const keysRef: any = useRef('null')
  const [listType, SetListType] = useState<string>('list')
  const {auth} = useAuth()
  const [s3Url, setS3Url] = useState('')

  const getParameterStore = async () => {
    try {
      const res: any = await parameterStoreGet({names: ['/Define/Common']}, {})
      setS3Url(JSON.parse(res['/Define/Common'])?.S3URL)
    } catch (error) {
      console.error('Failed to fetch parameter store', error)
    }
  }

  useEffect(() => {
    // queryParams.buyer_id = supplier.update_user
    queryParams.buyer_pk = auth.group_id
    setQueryParams({...queryParams, buyer_pk: auth.group_id})

    getAllTagList()
    initData()
    getParameterStore()
  }, [])

  const initData = async () => {
    getData(queryParams.page, queryParams.pageSize)
  }

  // 获取列表数据
  const getData = async (pageNum: any, pageSize: any) => {
    setContentLoading(true)

    // console.log(pageNum, pageSize,"传递过来的");
    queryParams.page = pageNum
    queryParams.pageSize = pageSize
    setQueryParams(queryParams)
    // console.log(queryParams,"设置过的值");
    try {
      const resp: any = await buyerProductDataListGet({
        pageNum,
        pageSize,
        name: queryParams.name,
        category: queryParams.category,
        supplierParam: queryParams.supplierParam,
        sortFlg: queryParams.sortFlg,
      })
      let rows: any = []
      for (const item of resp.rows) {
        // vs[]にquantityを追加
        item.vs?.forEach((variation: any, index: number) => {
          // item.quantity = 1

          // const obj = item.selectList.find((k:any)=> k.pk == e.target.value)
          if (variation.customer_rank_specific_pricing) {
            item.vs[index].customer_rank_specific_pricing = JSON.parse(
              variation.customer_rank_specific_pricing
            )
          }
          let itemId: any = ''
          if (variation.grade && variation.customer_rank_specific_pricing.length > 0) {
            itemId = variation.customer_rank_specific_pricing.findIndex(
              (e: any) => e.id == variation.grade
            )
          }
          if (
            itemId >= 0 &&
            variation.customer_rank_specific_pricing.length > 0 &&
            variation.customer_rank_specific_pricing[itemId]?.price
          ) {
            item.vs[index].unit_price = variation.customer_rank_specific_pricing[itemId]?.price
          }
          if (
            itemId >= 0 &&
            variation.customer_rank_specific_pricing.length > 0 &&
            variation.customer_rank_specific_pricing[itemId]?.price_range_min
          ) {
            item.vs[index].price_range_min =
              variation.customer_rank_specific_pricing[itemId]?.price_range_min
          }
          if (
            itemId >= 0 &&
            variation.customer_rank_specific_pricing.length > 0 &&
            variation.customer_rank_specific_pricing[itemId]?.price_range_max
          ) {
            item.vs[index].price_range_max =
              variation.customer_rank_specific_pricing[itemId]?.price_range_max
          }

          item.vs[index].default_price = variation.unit_price
          item.vs[index].minimum_order_quantity = variation.minimum_order_quantity
          item.vs[index].maximum_order_quantity = variation.maximum_order_quantity
          if (Number(variation.minimum_order_quantity) > 0) {
            item.vs[index].quantity = variation.minimum_order_quantity
          } else {
            item.vs[index].quantity = 1
          }
          item.vs[index].calc_price = variation.unit_price
          item.vs[index].calc_price_min = variation.price_range_min
          item.vs[index].calc_price_max = variation.price_range_max
          if (variation.quantity_discount)
            item.vs[index].quantity_discount = JSON.parse(variation.quantity_discount)
          calculatePrice(item)

          item.vs[index].price_range = variation.price_range
          item.vs[index].unit_price = variation.unit_price
          item.vs[index].price_range_min = variation.price_range_min
          item.vs[index].price_range_max = variation.price_range_max
        })
        rows.push({...item})
      }

      // console.log('rows', rows)
      setContentLoading(false)
      setQueryParams({...queryParams, total: resp.total})
      setListData(rows)
      setContentLoading(false)
    } catch (error) {
      setContentLoading(false)
      console.error('error', error)
    }
  }
  // getTag
  const getAllTagList = async () => {
    const resp1: any = await buyerProductSearchSuppliersGet({})
    const resp2: any = await buyerProductSearchCategoriesGet({})
    // console.log('getAllTagList', resp1, resp2)

    // データは [ title: item.supplier_name, value: item.pk ]の形式に整形
    let resp1Data = resp1.map((item: any) => {
      return {
        title: item.supplier_name,
        value: item.pk,
      }
    })

    setSupplierlistData(resp1Data)
    const arr = filterTree(resp2)
    setCategorylistData(arr)
  }

  const [selectedSupplierItems, setSelectedSupplierItems] = useState<any>([])
  const [selectedCategoryItems, setSelectedCategoryItems] = useState<string[]>([])

  // treeが変更された時に再検索を行う
  const handleCheckboxChange = (type: string, value: any) => {
    // console.log('handleCheckboxChange', type, value)
    if (type == 'supplier') {
      setQueryParams({...queryParams, supplierParam: value})
      initData()
      // console.log("supplierParam", queryParams)
    }
    if (type == 'category') {
      setSelectedCategoryItems(value)
    }
  }

  const search = () => {
    getData(queryParams.page, queryParams.pageSize)
  }

  // 製品詳細の表示
  const viewProductDetails = (item: {pk: any; grade: any; variationsNum: number; id: string}) => {
    if (item.variationsNum != 0) {
      navigate(`/buy/product/${item.pk}`, {
        state: {pk: item.pk, grade: item?.grade},
      })
    }
  }

  const onClose = (item: any) => {
    const idx = selectedSupplierItems.findIndex((k: any) => k.id == item.id)
    if (idx > -1) {
      selectedSupplierItems.splice(idx, 1)
      setSelectedSupplierItems([...selectedSupplierItems])
      handleButtonClick()
    }
    // console.log('idx',idx,item,selectedSupplierItems)
  }

  const onTreeClose = (item: any) => {
    const idx = selectedCategoryItems.findIndex((k: any) => k.id == item.id)
    if (idx > -1) {
      selectedCategoryItems.splice(idx, 1)
      setSelectedCategoryItems([...selectedCategoryItems])
      if (keysRef.current) {
        keysRef.current.setKeys()
      }
      handleButtonClick()
    }
    // console.log('idx',idx,item,selectedCategoryItems)
  }

  const handleButtonClick = async () => {
    let cs = selectedSupplierItems.map((k: any) => k.id).join()
    let cx = selectedCategoryItems.map((k: any) => k.id).join()
    queryParams.supplierParam = cs
    queryParams.category = cx
    setQueryParams(queryParams)
    getData(queryParams.page, queryParams.pageSize)
  }

  // データ減少
  const itemNumberReduce = (item: {quantity: number; minimum_order_quantity: number}) => {
    // console.log('--',item)
    if (
      item.quantity == 1 ||
      (item.minimum_order_quantity && Number(item.quantity) <= Number(item.minimum_order_quantity))
    ) {
      return
    }
    item.quantity--
    calculatePrice(item)
    let arr = JSON.parse(JSON.stringify(listData))

    setListData(arr)
  }
  // データ増加
  const itemNumberAdd = (item: {quantity: number; maximum_order_quantity: number}) => {
    console.log('++', item)
    if (
      item.maximum_order_quantity &&
      Number(item.quantity) >= Number(item.maximum_order_quantity)
    ) {
      return
    }
    item.quantity++
    calculatePrice(item)
    let arr = JSON.parse(JSON.stringify(listData))

    setListData(arr)
  }

  // カートの追加
  const addShoppingCart = async (item: any, item1 = '') => {
    if (item.pk) {
      try {
        const resp: any = await cartDataUpdate({
          product_pk: item.product_id,
          buyer_id: auth.group_id,
          variation_id: item.pk,
          product_id: item.product_id,
          quantity: item.quantity.toString(),
        })
        setMessage({type: 'success', message: 'カートに追加しました。'})
        setAccount({
          ...account,
          cartCount: resp.count,
          cartItem: resp.itemList,
          cartKey: resp.cartkey,
        })
      } catch (err: any) {
        setMessage({type: 'error', message: '在庫数が不足しています。'})
      }
    } else {
      setMessage({type: 'error', message: '未選択の項目があります'})
    }
  }

  const clickOnFavorites = async (item: any) => {
    // console.log('isCollect', item)
    if (!item.pk || !item.product_id) return
    if (item.is_favorite) {
      // ら削除
      let isCollect = (await productFavoritesDelete({
        product_id: item.product_id,
        variation_id: item.pk,
      })) as {message: string}
      // console.log(isCollect)

      if (isCollect.message == 'success') {
        setMessage({type: 'info', message: '「いつもの商品」を解除しました'})
        item.is_favorite = false
        // let resp = JSON.parse(JSON.stringify(listData))
        setListData([...listData])
      }
    } else {
      let isCollect = (await productFavoritesAdd(
        {
          product_id: item.product_id,
          variation_id: item.pk,
        },
        {}
      )) as {message: string}
      // console.log(isCollect)

      if (isCollect.message == 'success') {
        setMessage({type: 'info', message: '「いつもの商品」に追加しました'})
        item.is_favorite = true
        // let resp = JSON.parse(JSON.stringify(listData))
        setListData([...listData])
      }
    }
  }

  const handleChangeListType = (type: string) => {
    console.log('handleChangeListType', type)
    SetListType(type)
  }

  // useEffect(() => {
  //   console.log("listData", listData);
  // }, [listData])

  return (
    <div className='px-0'>
      <div className='card'>
        {/* 商品列表 */}
        <>
          {/* p-titleの高さを揃える */}
          <style>
            {`
            .p-title {
              height: 2.4em;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            .p-desc {
              height: 5.5em;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }

          `}
          </style>

          <div className='card-body border-bottom'>
            <div className='row border-bottom pb-6 mb-6'>
              <div className='col-12 col-lg-6'>
                <div className='input-group'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    value={queryParams.name}
                    onChange={handleInputChange}
                    placeholder='キーワードで検索'
                  />
                  {/* <span className="d-none d-lg-flex input-group-text" data-bs-toggle="offcanvas" data-bs-target="#offcanvas-search">
                    条件を指定して絞り込む
                  </span> */}
                  <span className='input-group-text' onClick={search}>
                    <i className='bi bi-search fs-4'></i>
                  </span>
                </div>
              </div>
            </div>

            <div className='row mb-6 border-bottom pb-6'>
              <div className='col-12 col-xl-6'>
                <div className='row mb-6'>
                  <div className='col d-flex align-items-center gap-2 flex-wrap flex-lg-nowrap'>
                    <span className='col-12 w-lg-200px'>カテゴリーで絞り込む</span>
                    <CategoryTree
                      ref={keysRef}
                      list={categorylistData}
                      items={selectedCategoryItems}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
                {/* </div> */}

                {/* <div className="col-12 col-xl-3"> */}
                <div className='row'>
                  <div className='col d-flex align-items-center gap-2 flex-wrap flex-lg-nowrap'>
                    <span className='col-12 w-lg-200px'>加盟店で絞り込む</span>
                    <CategoryTree
                      ref={keysRef}
                      list={supplierlistData}
                      items={selectedCategoryItems}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 col-xl-6 order-2 order-lg-1'>
                <div className='d-flex h-100'>
                  <div className='d-flex align-items-center'>
                    <span className='fw-bold'>
                      {queryParams.total || 0}件中{' '}
                      {listData.length == 0
                        ? listData.length
                        : listData.length * (queryParams.page - 1) + 1}
                      ～
                      {queryParams.total < queryParams.page * queryParams.pageSize
                        ? queryParams.total
                        : queryParams.page * queryParams.pageSize || 0}
                      件目
                    </span>
                  </div>
                </div>
              </div>
              <div className='d-none d-lg-flex justify-content-end col-12 col-xl-6 order-1 order-lg-2'>
                <div className='d-flex justify-content-end gap-4'>
                  <div className='d-none d-lg-flex align-items-center gap-3'>
                    <span>表示順 </span>
                    <select
                      className='col form-select form-select-sm'
                      style={{minWidth: '145px', maxWidth: '145px'}}
                      onChange={(e) => {
                        queryParams.sortFlg = e.target.value
                        setQueryParams({...queryParams})
                        getData(queryParams.page, queryParams.pageSize)
                      }}
                    >
                      <option value='1'>新着順</option>
                      <option value='2'>古い順</option>
                      <option value='3'>おすすめの昇順</option>
                      <option value='4'>おすすめの降順</option>
                      {/* <option value=''>価格が安い順</option>
                      <option value=''>価格が高い順</option> */}
                    </select>
                  </div>

                  <div className='d-flex align-items-center gap-3'>
                    <span>表示件数 </span>
                    <select
                      className='form-select form-select-sm w-100px'
                      data-control='select2'
                      value={queryParams.pageSize}
                      onChange={(e) => {
                        // changePageSize(e.target.value);
                      }}
                    >
                      <option value={40}>40件</option>
                      <option value={80}>80件</option>
                      <option value={100}>100件</option>
                    </select>
                  </div>

                  {useIsWidthSize('lg') ? (
                    <div className='d-inline-flex align-items-center gap-2'>
                      <button
                        type='button'
                        className={`btn btn-icon ${
                          listType === 'list' ? 'btn-primary' : 'btn-light'
                        }`}
                        onClick={() => {
                          handleChangeListType('list')
                        }}
                      >
                        <i className='bi bi-list-task'></i>
                      </button>
                      <button
                        type='button'
                        className={`btn btn-icon ${
                          listType === 'grid' ? 'btn-primary' : 'btn-light'
                        }`}
                        onClick={() => {
                          handleChangeListType('grid')
                        }}
                      >
                        <i className='bi bi-grid'></i>
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className='d-block d-lg-none col-12 order-1 order-lg-2'>
                <div className='mb-6 d-flex gap-2 justify-content-end'>
                  <div className='d-flex align-items-center'>
                    <select
                      className='form-select form-select-sm'
                      onChange={(e) => {
                        queryParams.sortFlg = e.target.value
                        setQueryParams({...queryParams})
                        getData(queryParams.page, queryParams.pageSize)
                      }}
                    >
                      <option value='1'>新着順</option>
                      <option value='2'>古い順</option>
                      <option value='3'>おすすめの昇順</option>
                      <option value='4'>おすすめの降順</option>
                      {/* <option value=''>価格が安い順</option>
                        <option value=''>価格が高い順</option> */}
                    </select>
                  </div>
                  <div className='d-flex align-items-center'>
                    <select
                      className='form-select form-select-sm w-100px'
                      data-control='select2'
                      value={queryParams.pageSize}
                      onChange={(e) => {
                        // changePageSize(e.target.value);
                      }}
                    >
                      <option value={40}>40件</option>
                      <option value={80}>80件</option>
                      <option value={100}>100件</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

        {/* 检索 */}
        <div className='offcanvas offcanvas-end' tabIndex={-1} id='offcanvas-search'>
          <div className='offcanvas-header'>
            <h5 className='offcanvas-title'>カテゴリーを選択</h5>
            <button
              className='btn-close'
              type='button'
              aria-label='Close'
              data-bs-dismiss='offcanvas'
            />
          </div>
          <div className='offcanvas-body'>
            <div className='row flex-column mb-4'>
              <div className='w-100px'>
                <h1 className='fs-4'>加盟店で絞り込む</h1>
              </div>
              <div className='col d-flex gap-2 flex-wrap flex-column'>
                <div>
                  {supplierlistData.map((item: any) => (
                    <div key={item.pk} className='form-check mb-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        // value={item.id}
                        checked={
                          selectedSupplierItems.findIndex((k: any) => k.id == item.supplier_id) !=
                          -1
                            ? true
                            : false
                        }
                        onChange={(e: any) => handleCheckboxChange(e, item)}
                      />
                      <label className='form-check-label text-dark'>{item.supplier_name}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className='row flex-column mb-4'>
              <div className='col'>
                <h1 className='fs-4'>カテゴリーで絞り込む</h1>
              </div>
              <div className='col d-flex gap-2 flex-wrap flex-column'>
                <CategoryTree
                  ref={keysRef}
                  list={categorylistData}
                  items={selectedCategoryItems}
                  onChange={(items: any) => setSelectedCategoryItems([...items])}
                />
              </div>
            </div>
            <button
              className='btn btn-primary w-100'
              type='button'
              data-bs-dismiss='offcanvas'
              onClick={() => {
                handleButtonClick()
              }}
            >
              指定した条件で検索
            </button>
          </div>
        </div>

        {!isContentLoading ? (
          listData.length > 0 ? (
            <ProductList
              props={{
                listData: listData,
                listType: listType,
                s3Url: s3Url,
                addShoppingCart: addShoppingCart,
                clickOnFavorites: clickOnFavorites,
                viewProductDetails: viewProductDetails,
                itemNumberAdd: itemNumberAdd,
                itemNumberReduce: itemNumberReduce,
              }}
            />
          ) : (
            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
              条件にあう商品が見つかりません
            </div>
          )
        ) : (
          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
            データの取得中
          </div>
        )}

        {queryParams.total != 0 && (
          <Pagination
            currentPage={getData}
            page={queryParams.page}
            pageSize={queryParams.pageSize}
            pageSizeList={[40, 80, 100]}
            total={queryParams.total}
          />
        )}
      </div>
    </div>
  )
}

const ProductList = (props: any) => {
  return (
    <>
      <div className='card-body'>
        {!useIsWidthSize('lg') ? <ProductListListMobile props={{...props.props}} /> : null}
        {useIsWidthSize('lg') ? (
          <>
            {props.props.listType == 'list' ? (
              <ProductListListPc props={{...props.props}} />
            ) : (
              <ProductListGrid props={{...props.props}} />
            )}
          </>
        ) : null}
      </div>
    </>
  )
}

const ProductListListPc = (props: any) => {
  return (
    <>
      {props.props.listData?.map((item: any, index: number) => {
        return (
          <div className='row border-bottom pb-4 mb-4' key={item.pk}>
            <div className='col-12 col-lg-1 ps-6'>
              <img
                className='mw-100 w-100 h-100'
                style={{objectFit: 'contain', objectPosition: 'center'}}
                src={
                  item.main_image
                    ? props.props.s3Url +
                      '/' +
                      encodeURIComponent(item.supplier_id.split('#Suppliers#')?.[1]) +
                      item.main_image
                    : toAbsoluteUrl('/media/products/sample.png')
                }
                onClick={() => {
                  props.props.viewProductDetails(item)
                }}
              />
            </div>
            <div className='col-12 col-lg-11 pe-6'>
              <div className='d-flex flex-column'>
                <p className='fs-7 mb-0'>{item.shop_name || '\u00A0'}</p>
                <h1 className='fs-3 link-underline-primary'>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                      props.props.viewProductDetails(item)
                    }}
                  >
                    {item.product_name || '\u00A0'}
                  </a>
                </h1>
                {item.vs && item.vs.length !== 0 && (
                  <div className='pt-2 mt-4 border-top'>
                    {item.vs.map((item1: any, index1: number) => {
                      return (
                        <>
                          <div
                            className='row align-items-center px-0 mx-0 mb-2 pb-2 border-bottom'
                            key={item1.pk}
                          >
                            <div className='col'>
                              <div className='row'>
                                <div className='col d-flex align-items-center'>
                                  {item1.variation_product_name}
                                </div>
                                <div className='col d-flex align-items-center'>単位</div>
                                <div className='col d-flex align-items-center'>
                                  <div className='row'>
                                    <button
                                      type='button'
                                      className='col btn btn-sm btn-icon btn-light btn-active-light-primary toggle py-4'
                                      onClick={() => {
                                        props.props.itemNumberReduce(item1)
                                      }}
                                    >
                                      <i
                                        className='bi bi-dash-lg fs-4 text-black'
                                        data-kt-docs-datatable-subtable='expand_row'
                                      ></i>
                                    </button>
                                    <div className='col d-flex align-items-center justify-content-center'>
                                      <span>{item1.quantity}</span>
                                    </div>
                                    <button
                                      type='button'
                                      className='col btn btn-sm btn-icon btn-light btn-active-light-primary toggle py-4'
                                      onClick={() => {
                                        props.props.itemNumberAdd(item1)
                                      }}
                                    >
                                      <i
                                        className='bi bi-plus-lg fs-4 text-black'
                                        data-kt-docs-datatable-subtable='expand_row'
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                                <div className='col d-flex align-items-center'>
                                  <strong className='fs-6' style={{color: 'red'}}>
                                    {item1.visible_price === 'false' && <span>価格非表示</span>}
                                    {item1.visible_price !== 'false' &&
                                      item1.price_range === 'unit_price' && (
                                        <span>{formatNumber(item1.unit_price)}円</span>
                                      )}
                                    {item1.visible_price !== 'false' &&
                                      item1.price_range === 'price_range' && (
                                        <span>
                                          {formatNumber(item1.price_range_min)}~
                                          {formatNumber(item1.price_range_max)}円
                                        </span>
                                      )}
                                  </strong>
                                </div>
                              </div>
                            </div>
                            <div className='w-200px'>
                              <ShoppingCartButton props={{...props.props, item: item1}} />
                            </div>
                            <div className='w-250px'>
                              <FavoriteButton props={{...props.props, item: item1}} />
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

const ProductListListMobile = (props: any) => {
  const [openVariations, setOpenVariations] = useState<string[]>([])

  // バリエーションを表示ボタン
  const handleVaiationButtonClick = async (item: any) => {
    let index = openVariations.findIndex((k: any) => k == item.pk)
    if (index > -1) {
      openVariations.splice(index, 1)
    } else {
      openVariations.push(item.pk)
    }
    setOpenVariations([...openVariations])
  }

  return (
    <>
      {props.props.listData?.map((item: any, index: number) => {
        return (
          <div
            className={`row pb-8 mb-8 ${
              index !== props.props.listData.length - 1 ? 'border-bottom' : ''
            }`}
            key={index}
          >
            <div className='col-4'>
              <img
                className='mw-100 w-100 h-100'
                style={{objectFit: 'contain', objectPosition: 'center'}}
                src={
                  item.main_image
                    ? props.props.s3Url +
                      '/' +
                      encodeURIComponent(item.supplier_id.split('#Suppliers#')?.[1]) +
                      item.main_image
                    : toAbsoluteUrl('/media/products/sample.png')
                }
                onClick={() => {
                  props.props.viewProductDetails(item)
                }}
              />
            </div>
            <div className='col-8'>
              <div className='d-flex flex-column'>
                <p className='fs-7 mb-0'>{item.shop_name || '\u00A0'}</p>
                <h1 className='fs-3 link-underline-primary'>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                      props.props.viewProductDetails(item)
                    }}
                  >
                    {item.product_name || '\u00A0'}
                  </a>
                </h1>

                <div className='mt-4'>
                  <button
                    className='btn btn-primary w-100'
                    onClick={() => {
                      handleVaiationButtonClick(item)
                    }}
                  >
                    バリエーションを表示
                  </button>
                </div>
              </div>
            </div>

            {openVariations.findIndex((k: any) => k == item.pk) > -1 && (
              <div className='col-12'>
                {item.vs && item.vs.length !== 0 && (
                  <div className={`pt-2 mt-4 border-top border-bottom`}>
                    {item.vs.map((item1: any, index1: number) => {
                      return (
                        <>
                          <div className='row align-items-center mb-2 pb-2' key={item1.pk}>
                            <div className='d-flex flex-column mb-3'>
                              <div className='p-1 fw-bold'>{item1.variation_product_name}</div>
                              <div className='p-1'>
                                <div className='row'>
                                  <button
                                    type='button'
                                    className='col btn btn-sm btn-icon btn-light btn-active-light-primary toggle py-4'
                                    onClick={() => {
                                      props.props.itemNumberReduce(item1)
                                    }}
                                  >
                                    <i
                                      className='bi bi-dash-lg fs-4 text-black'
                                      data-kt-docs-datatable-subtable='expand_row'
                                    ></i>
                                  </button>
                                  <div className='col d-flex align-items-center justify-content-center'>
                                    <span>{item1.quantity}</span>
                                  </div>
                                  <button
                                    type='button'
                                    className='col btn btn-sm btn-icon btn-light btn-active-light-primary toggle py-4'
                                    onClick={() => {
                                      props.props.itemNumberAdd(item1)
                                    }}
                                  >
                                    <i
                                      className='bi bi-plus-lg fs-4 text-black'
                                      data-kt-docs-datatable-subtable='expand_row'
                                    ></i>
                                  </button>
                                </div>
                              </div>
                              {item1.unit && (
                                <div className='p-1 text-end text-lg-start'>
                                  （単位:{item1.unit} ）
                                </div>
                              )}
                              <div className='p-1'>
                                <strong className='fs-6' style={{color: 'red'}}>
                                  {item1.visible_price === 'false' && <span>価格非表示</span>}
                                  {item1.visible_price !== 'false' &&
                                    item1.price_range === 'unit_price' && (
                                      <span>{formatNumber(item1.unit_price)}円</span>
                                    )}
                                  {item1.visible_price !== 'false' &&
                                    item1.price_range === 'price_range' && (
                                      <span>
                                        {formatNumber(item1.price_range_min)}~
                                        {formatNumber(item1.price_range_max)}円
                                      </span>
                                    )}
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`row mb-4 pb-4 ${
                              index1 !== item.vs.length - 1 ? 'border-bottom' : ''
                            }`}
                          >
                            <div className='w-50'>
                              <ShoppingCartButton props={{...props.props, item: item1}} />
                            </div>
                            <div className='w-50'>
                              <FavoriteButton props={{...props.props, item: item1}} />
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}

const ProductListGrid = (props: any) => {
  return (
    <div className='row row-cols-1 row-cols-lg-5 row-gap-4 px-5 mb-4'>
      {props.props.listData.length > 0
        ? props.props.listData?.map((item: any, index: number) => {
            return item.pk ? (
              <div key={index} className='border-bottom border-end pb-2 mb-4'>
                <div className='row'>
                  <div className='col-4 col-lg-12'>
                    <div className='mb-4 position-relative'>
                      <div
                        className='mb-4 position-relative'
                        style={{width: '100%', height: '30vw', maxHeight: '300px'}}
                        onClick={() => {
                          props.props.viewProductDetails(item)
                        }}
                      >
                        <img
                          className='w-100 h-100'
                          style={{objectFit: 'contain', objectPosition: 'center'}}
                          src={
                            item.main_image
                              ? props.props.s3Url +
                                '/' +
                                encodeURIComponent(item.supplier_id.split('#Suppliers#')?.[1]) +
                                item.main_image
                              : toAbsoluteUrl('/media/products/sample.png')
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-8 col-lg-12'>
                    <div className='mb-4'>
                      <div>
                        <p className='fs-7 mb-0'>{item.shop_name || '\u00A0'}</p>
                        <h1 className='p-title fs-3'>{item.product_name || '\u00A0'}</h1>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='d-flex align-items-center justify-content-center'>
                <span>(単位：{item.unit == '1' ? 'kg' : '個'})</span>
              </div> */}
                {/* { item.checked?<select className="form-select" data-control="select2"

                                        value={ }
                                    >
                                        <option value=''>未指定</option>
                                        }

                                    </select>:null} */}
                {/* {item.checked ? ( 
                <select
                  className='form-select'
                  data-control='select2'
                  value={item.variation_id}
                  onChange={(e) => {
                    item.variation_id = e.target.value
                    // item.checked = false
                    // setListData([...listData])
                  }}
                >
                  <option value=''></option>
                  {item.selectList &&
                    item.selectList.length !== 0 &&
                    item.selectList.map((item1: any, index1: number) => {
                      return (
                        <option value={item1.pk} key={item1.pk}>
                          {item1.variation_product_name}
                        </option>
                      )
                    })}
                </select>
              {/* ) : null} */}
              </div>
            ) : (
              ''
            )
          })
        : null}
    </div>
  )
}

const ShoppingCartButton = (props: any) => {
  const [loadingItems, setLoadingItems] = useState<{[key: string]: boolean}>({})
  const {account} = useAccount()

  useEffect(() => {
    setLoadingItems((prevState) => ({...prevState, [props.props.item.pk]: false}))
  }, [])

  const addShoppingCart = async (item: any) => {
    setLoadingItems((prevState) => ({...prevState, [item.pk]: true}))
    try {
      await props.props.addShoppingCart(item)
    } catch (error) {
      console.error('Error adding to cart:', error)
    } finally {
      setLoadingItems((prevState) => ({...prevState, [item.pk]: false}))
    }
  }

  return (
    <>
      {/* {props.props.items.map((item: any) => ( */}
      <ProcessButton
        onClick={() => addShoppingCart(props.props.item)}
        className='btn btn-primary w-100 px-0 me-5'
      >
        {(() => {
          const foundItem = account.cartItem.find((k: any) => k.id === props.props.item.pk)
          return foundItem ? (
            <span className='badge badge-circle badge-success me-2'>{foundItem.quantity}</span>
          ) : null
        })()}
        カートに追加
      </ProcessButton>
      {/* ))} */}
    </>
  )
}

const FavoriteButton = (props: any) => {
  const [loadingItems, setLoadingItems] = useState<{[key: string]: boolean}>({})

  useEffect(() => {
    setLoadingItems((prevState) => ({...prevState, [props.props.item.pk]: false}))
  }, [])

  const clickOnFavorites = async (item: any) => {
    setLoadingItems((prevState) => ({...prevState, [item.pk]: true}))
    try {
      await props.props.clickOnFavorites(item)
    } catch (error) {
      console.error('Error adding to favorite list:', error)
    } finally {
      setLoadingItems((prevState) => ({...prevState, [item.pk]: false}))
    }
  }

  return (
    <>
      {/* {props.props.items.map((item: any) => ( */}

      <ProcessButton
        onClick={() => clickOnFavorites(props.props.item)}
        className='btn-bg-light btn-color-primary w-100 px-0'
      >
        {props.props.item.is_favorite ? (
          <i className='bi bi-bookmark-check-fill fs-2'></i>
        ) : (
          <i className='bi bi-bookmark-plus fs-2'></i>
        )}
        いつもの商品
      </ProcessButton>
      {/* ))} */}
    </>
  )
}

export default OrderingProductList
