/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, FC, useState} from 'react'
import {useLocation, useParams, useSearchParams, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from 'src/_metronic/layout/core'
import {KTSVG, toAbsoluteUrl} from 'src/_metronic/helpers'
import {CustomerInfo} from './components/CustomerInfo'
import {CustomerEdit} from './components/CustomerEdit'
import {CustomerDelivery} from './components/CustomerDelivery'
import {CustomerReport} from './components/CustomerReport'
import {SupplierUniqueSettings} from './components/SupplierUniqueSettings'
import {showSuccessMessage, showErrorMessage} from 'src/app/components/sweetAlert'
import {
  getCommonDetails,
  customerRankListGet,
  customerDataEdit,
  customerStatusUpdate,
  customerUserStatusUpdate,
  parameterStoreGet,
} from 'src/app/modules/api'
import {useLayout} from 'src/_metronic/layout/core'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: '顧客情報',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
]

const Pageconts: FC = () => {
  const {id} = useParams()
  const currentLocation = useLocation()
  const [getSearchArr] = useSearchParams()
  const searchValue = getSearchArr.getAll('copy')[0]
  const [customerOptions, setCustomerOptions] = useState<any>([])
  const [userdata, setUserdata] = useState<any>({
    store_status: '',
    registration_date: '',
    customer_id: '',
    company_name: '',
    representative: '',
    contact_person: '',
    main_email_address: '',
    telephone_number: '',
    mobile_phone_number: '',
    fax_number: '',
    payment_method: '',
    establishment_date: '',
    actual_industry: '',
    website_url: '',
    physical_store: '',
    email_notifications: '',
  }) // 表单数据

  const [authentication, setAuthentication] = useState<any>('pending')
  const [groupId, setGroupId] = useState<any>('1')
  const {isContentLoading, setContentLoading} = useLayout()
  const [supplierApplicationStatusParameters, setSupplierApplicationStatusParameters] = useState<
    any[]
  >([])

  const getParameterStore = async () => {
    try {
      const shippingStatusRes: any = await parameterStoreGet(
        {names: ['/Define/Supplierapplications/Status']},
        {}
      )
      setSupplierApplicationStatusParameters(
        JSON.parse(shippingStatusRes['/Define/Supplierapplications/Status'])
      )
    } catch (error) {
      console.error('Failed to fetch parameter store', error)
    }
  }

  useEffect(() => {
    initData()
    getParameterStore()
  }, [])

  // 获取列表数据
  const initData = async () => {
    console.log('id', id, currentLocation)
    setContentLoading(true)
    const resp: any = await customerRankListGet({})
    setCustomerOptions(resp.rows)
    if (id) {
      const res: any = await getCommonDetails({pkVal: currentLocation.state.pk})
      console.log('res', res)
      setAuthentication(currentLocation.state.status)
      if (currentLocation.state.group_id) {
        setGroupId(currentLocation.state.group_id)
        res.group_id = currentLocation.state.group_id
      } else {
        res.group_id =
          resp.rows.find((e: any) => e.default == 'yes')?.group_id || resp.rows[0]?.group_id
        setGroupId(res.group_id)
      }
      setUserdata({...userdata, ...res})
      setContentLoading(false)
    } else {
      setContentLoading(false)
      setUserdata({
        ...userdata,
        group_id:
          resp.rows.find((e: any) => e.default == 'yes')?.group_id || resp.rows[0]?.group_id,
      })
    }
  }

  const handleUpdate = () => {
    setContentLoading(true)
    customerStatusUpdate({buyer_id: userdata.pk, status: authentication}, {})
      .then((res: any) => {
        setContentLoading(false)
        showSuccessMessage(res.message)
      })
      .catch((err: any) => {
        setContentLoading(false)
      })
  }
  const vipUpdate = () => {
    setContentLoading(true)
    customerUserStatusUpdate({buyer_id: userdata.pk, group_id: groupId}, {})
      .then((res: any) => {
        setContentLoading(false)
        showSuccessMessage(res.message)
      })
      .catch((err: any) => {
        setContentLoading(false)
      })
  }

  return (
    <>
      {currentLocation.state?.type && (
        <>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='me-7 mb-4'>
                  <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                    <img
                      src={
                        userdata.main_image && userdata.main_image !== ''
                          ? userdata.main_image
                          : toAbsoluteUrl('/media/avatars/blank.png')
                      }
                      alt={userdata.company_name}
                    />
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <span className='fs-2x fw-bold'>{userdata.company_name}</span>
                      </div>

                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                        アカウントID：{userdata?.display_id}
                      </div>
                    </div>

                    <div className='col-3 w-300px'>
                      <span className='fw-bold fs-6 mb-4'>ステータス</span>
                      <div className='row'>
                        <div className='col-8 col-sm-7'>
                          <select
                            className='form-select fw-bolder'
                            value={authentication}
                            onChange={(e) => {
                              setAuthentication(e.target.value)
                            }}
                          >
                            {supplierApplicationStatusParameters.map((status: any) => (
                              <option key={status.id} value={status.value}>
                                {status.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='col-4  col-sm-5'>
                          <button
                            onClick={() => handleUpdate()}
                            className='btn btn-sm fw-bold btn-primary'
                          >
                            更新
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                      <div className='d-flex flex-wrap'>
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>顧客ランク</div>
                          </div>
                          <div className='fw-bold fs-6 '>
                            {customerOptions?.find(
                              (e: any) => e.group_id == currentLocation.state.group_id
                            )?.group_name ||
                              currentLocation.state.group_id ||
                              customerOptions?.find((e: any) => e.default == 'yes')?.group_name}
                          </div>
                        </div>

                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-2 fw-bolder'>認証状況</div>
                          </div>
                          <div className='fw-bold fs-6 '>
                            {
                              supplierApplicationStatusParameters?.find(
                                (e: any) => e.value == currentLocation.state.status
                              )?.label
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-3 w-300px'>
                      <span className='fw-bold fs-6 mb-4'>顧客ランク</span>
                      <div className='row'>
                        <div className='col-8 col-sm-7'>
                          <select
                            className='form-select fw-bolder'
                            value={groupId}
                            onChange={(e) => {
                              setGroupId(e.target.value)
                            }}
                          >
                            {customerOptions.map((option: any) => (
                              <option key={option.pk} value={option.group_id}>
                                {option.group_name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='col-4  col-sm-5'>
                          <button
                            onClick={() => vipUpdate()}
                            className='btn btn-sm fw-bold btn-primary'
                          >
                            更新
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-toolbar'>
                <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bolder border-0'>
                  <li className='nav-item'>
                    <a
                      className={
                        currentLocation.state?.type == 'detail' ? 'nav-link active' : 'nav-link'
                      }
                      data-bs-toggle='tab'
                      href='#kt_tab_pane_1'
                    >
                      顧客情報表示
                    </a>
                  </li>

                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>
                      配送先情報
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_4'>
                      レポート
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
                      加盟店独自項目
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='tab-content' id='myTabContent'>
            <div
              className={
                currentLocation.state?.type == 'detail'
                  ? 'tab-pane fade show active card'
                  : 'tab-pane fade card'
              }
              id='kt_tab_pane_1'
              role='tabpanel'
            >
              {userdata && userdata.customer_id && (
                <>
                  <CustomerInfo customeItem={userdata} customerOptions={customerOptions} />
                </>
              )}
            </div>
            <div
              className={
                currentLocation.state?.type == 'edit'
                  ? 'tab-pane fade show active card'
                  : 'tab-pane fade card'
              }
              id='kt_tab_pane_2'
              role='tabpanel'
            >
              {userdata && userdata.customer_id && (
                <CustomerEdit customeItem={userdata} customerOptions={customerOptions} />
              )}
            </div>
            <div className='tab-pane fade card' id='kt_tab_pane_3' role='tabpanel'>
              <CustomerDelivery />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_4' role='tabpanel'>
              <CustomerReport />
            </div>
            <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
              <SupplierUniqueSettings />
            </div>
          </div>
        </>
      )}
      {!currentLocation.state?.type && customerOptions?.length > 0 && userdata?.group_id && (
        <CustomerEdit customeItem={userdata} customerOptions={customerOptions} />
      )}
    </>
  )
}

export default function CustomerDetail() {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>顧客情報表示</PageTitle>
      <Pageconts />
    </>
  )
}
