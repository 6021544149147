/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, Fragment} from 'react'
import {PageLink, PageTitle} from '@/_metronic/layout/core'
import {useAuth} from 'src/app/modules/auth'
import {useLayout, useAlert} from 'src/_metronic/layout/core'
import {useNavigate, useParams} from 'react-router-dom'
import {HelmetComponent} from 'src/app/modules/helmet'
import {
  supplierApplicationInfoGet,
  supplierApplication,
  supplierApplicationCancel,
} from 'src/app/modules/api'
import moment from 'moment'
import * as Yup from 'yup'
import {useFormik} from 'formik'

type SupplierDetailData = {
  application: SupplierDetailDataApplication
  legals: SupplierDetailDataLegals
  supplier: SupplierDetailDataSupplier
}

type SupplierDetailDataApplication = {
  application_date: string
  statuschange_date: string
  status: 'pending' | 'approval' | 'non_approval' | 'unapplied' | ''
}

type SupplierDetailDataLegals = {
  additional_charges: string
  application_deadline: string
  defective_products: string
  delivery_time: string
  order_method: string
  payment_method: string
  return_deadline: string
  return_shipping_fee: string
  sales_quantity: string
  transaction_details: string
}

type SupplierDetailDataSupplier = {
  supplier_id: string
  company_name: string
  corporate_number: string
  invoice_number: string
  website_url: string
  shop_introduction: string
  main_genre: string
}

const Pageconts: FC = () => {
  const [data, setData] = useState<SupplierDetailData>()
  const {id} = useParams()
  const {setContentLoading, isContentLoading} = useLayout()
  const navigate = useNavigate()
  const {auth} = useAuth()
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }

  let baseBreadCrumbs: Array<PageLink> = [
    {
      title: 'ダッシュボード',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '-',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '仕入先申請一覧',
      path: '/supplier',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '-',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  const getData = async () => {
    if (!id) return
    const response = (await supplierApplicationInfoGet(id, {}, headers)) as SupplierDetailData
    baseBreadCrumbs.push({
      title: response.supplier.company_name,
      path: '',
      isSeparator: false,
      isActive: true,
    })
    setData(response)
  }

  useEffect(() => {
    setContentLoading(true)
    getData()
      .then(() => setContentLoading(false))
      .catch(() => setContentLoading(false))
  }, [])

  const defaultSupplier: SupplierDetailDataSupplier = {
    supplier_id: '',
    company_name: '',
    corporate_number: '',
    invoice_number: '',
    website_url: '',
    shop_introduction: '',
    main_genre: '',
  }

  const defaultLegals: SupplierDetailDataLegals = {
    additional_charges: '',
    application_deadline: '',
    defective_products: '',
    delivery_time: '',
    order_method: '',
    payment_method: '',
    return_deadline: '',
    return_shipping_fee: '',
    sales_quantity: '',
    transaction_details: '',
  }

  const defaultApplication: SupplierDetailDataApplication = {
    application_date: '',
    statuschange_date: '',
    status: 'unapplied',
  }

  return (
    <>
      {/* <HelmetComponent title='仕入先詳細' options={{}} /> */}
      {!isContentLoading && data && (
        <>
          <PageTitle breadcrumbs={baseBreadCrumbs}>{data?.supplier.company_name}</PageTitle>
          <HelmetComponent title={data?.supplier.company_name} options={{}} />
          <SupplierDetailBlock {...(data?.supplier || defaultSupplier)} />
          <SpecialCommercialLawBlock {...(data?.legals || defaultLegals)} />
          <SupplierApplyBlock {...(data?.application || defaultApplication)} />
          <div className='row'>
            <div className='col-lg-12'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  navigate('/supplier')
                }}
              >
                一覧に戻る
              </button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default function SupplierDetail() {
  return (
    <>
      <PageTitle>仕入先詳細</PageTitle>
      <Pageconts />
    </>
  )
}

// 仕入れ先情報
const SupplierDetailBlock: React.FC<SupplierDetailDataSupplier> = (data) => {
  return (
    <div className='card mb-6'>
      <div className='card-header card-header-stretch'>
        <h3 className='card-title fs-2 fw-bold'>仕入先情報</h3>
      </div>
      <div className='card-body'>
        <div className='row mb-2'>
          <label className='col-lg-4 fw-bold'>仕入先名</label>
          <div className='col-lg-8'>
            <span className=''>{data && data.company_name}</span>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-4 fw-bold'>取り扱いジャンル</label>
          <div className='col-lg-8'>
            <span className=''>{data && data.main_genre}</span>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-4 fw-bold'>ホームページURL</label>
          <div className='col-lg-8'>
            <span className=''>{data && data.website_url}</span>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-4 fw-bold'>法人番号</label>
          <div className='col-lg-8'>
            <span className=''>{data && data.corporate_number}</span>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-4 fw-bold'>適格請求書発行事業者登録番号</label>
          <div className='col-lg-8'>
            <span className=''>{data && data.invoice_number}</span>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-4 fw-bold'>ショップ紹介</label>
          <div className='col-lg-8'>
            <span className=''>{data && data.shop_introduction}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

// 特商法表示
const SpecialCommercialLawBlock: React.FC<SupplierDetailDataLegals> = (data) => {
  const splitRow = (text: string) => {
    return text.split('\n').map((line: string, index: number) => (
      <Fragment key={index}>
        {line}
        <br />
      </Fragment>
    ))
  }

  return (
    <div className='card mb-6'>
      <div className='card-header card-header-stretch'>
        <h3 className='card-title fs-2 fw-bold'>利用規約</h3>
      </div>
      <div className='card-body'>
        <div className='row mb-4 pb-4 border-bottom border-gray-200'>
          <div className='col-lg-12 fw-bold'>お取引に関して</div>
          <div className='col-lg-12'>{data && splitRow(data.transaction_details)}</div>
        </div>

        <div className='row mb-4 pb-4 border-bottom border-gray-200'>
          <div className='col-lg-12 fw-bold'>ご注文方法</div>
          <div className='col-lg-12'>{data && splitRow(data.order_method)}</div>
        </div>

        <div className='row mb-4 pb-4 border-bottom border-gray-200'>
          <div className='col-lg-12 fw-bold'>商品代金以外の料金</div>
          <div className='col-lg-12'>{data && splitRow(data.additional_charges)}</div>
        </div>

        <div className='row mb-4 pb-4 border-bottom border-gray-200'>
          <div className='col-lg-12 fw-bold'>送料</div>
          <div className='col-lg-12'>
            送料設定に応じる
            {/* {data && splitRow(data.display_shipping)} */}
          </div>
        </div>

        <div className='row mb-4 pb-4 border-bottom border-gray-200'>
          <div className='col-lg-12 fw-bold'>申込有効期限</div>
          <div className='col-lg-12'>{data && splitRow(data.application_deadline)}</div>
        </div>

        <div className='row mb-4 pb-4 border-bottom border-gray-200'>
          <div className='col-lg-12 fw-bold'>不良品について</div>
          <div className='col-lg-12'>{data && splitRow(data.defective_products)}</div>
        </div>

        <div className='row mb-4 pb-4 border-bottom border-gray-200'>
          <div className='col-lg-12 fw-bold'>販売数量</div>
          <div className='col-lg-12'>{data && splitRow(data.sales_quantity)}</div>
        </div>

        <div className='row mb-4 pb-4 border-bottom border-gray-200'>
          <div className='col-lg-12 fw-bold'>引き渡し時期</div>
          <div className='col-lg-12'>{data && splitRow(data.delivery_time)}</div>
        </div>

        <div className='row mb-4 pb-4 border-bottom border-gray-200'>
          <div className='col-lg-12 fw-bold'>お支払方法</div>
          <div className='col-lg-12'>{data && splitRow(data.payment_method)}</div>
        </div>

        <div className='row mb-4 pb-4 border-bottom border-gray-200'>
          <div className='col-lg-12 fw-bold'>返品期限</div>
          <div className='col-lg-12'>{data && splitRow(data.return_deadline)}</div>
        </div>

        <div className='row mb-4'>
          <div className='col-lg-12 fw-bold'>返品送料</div>
          <div className='col-lg-12'>{data && splitRow(data.return_shipping_fee)}</div>
        </div>
      </div>
    </div>
  )
}

// 仕入れ先申請ブロック
const SupplierApplyBlock: React.FC<SupplierDetailDataApplication> = (data) => {
  const {id} = useParams()
  const {setContentLoading} = useLayout()
  const {setMessage} = useAlert()
  const {auth} = useAuth()
  const navigate = useNavigate()

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }

  let formikParams = {}

  if (data.status === 'unapplied') {
  }

  const formik = useFormik({
    initialValues: {
      apply: false,
    },
    // validationSchema: Yup.object().shape({
    //   apply: Yup.boolean().oneOf([true], '利用規約の同意は必須です')
    // }),
    onSubmit: (values) => {
      console.log(values)
      setContentLoading(true)

      if (data.status === 'pending') {
        supplierApplicationCancel({pk: id}, headers)
          .then(() => {
            setContentLoading(false)
            setMessage({type: 'success', message: '申請を取り消しました'})
            navigate('/supplier')
          })
          .catch(() => {
            setContentLoading(false)
            setMessage({type: 'error', message: '申請の処理中にエラーが発生しました'})
          })
      } else {
        supplierApplication({pk: id, apply: values.apply}, headers)
          .then(() => {
            setContentLoading(false)
            setMessage({type: 'success', message: '申請を送信しました'})
            navigate('/supplier')
          })
          .catch(() => {
            setContentLoading(false)
            setMessage({type: 'error', message: '申請の処理中にエラーが発生しました'})
          })
      }
    },
  })

  return (
    <div className='card mb-6'>
      <div className='card-header card-header-stretch'>
        <h3 className='card-title fs-2 fw-bold'>仕入先申請</h3>
      </div>
      <div className='card-body'>
        <div className='row mb-2'>
          <label className='col-lg-4 fw-bold'>申請ステータス</label>
          <div className='col-lg-8'>
            <span className=''>
              {data.status === 'unapplied'
                ? '未申請'
                : data.status === 'pending'
                ? '申請中'
                : data.status === 'approval'
                ? '承認済'
                : data.status === 'non_approval'
                ? '非承認'
                : ''}
            </span>
          </div>
        </div>
        <div className='row mb-2'>
          <label className='col-lg-4 fw-bold'>申請日時</label>
          <div className='col-lg-8'>
            <span className=''>
              {data.application_date && moment(data.application_date).format('YYYY-MM-DD HH:mm:ss')}
            </span>
          </div>
        </div>
        {data.status !== 'approval' && (
          <>
            <div className='row mb-2'>
              <form onSubmit={formik.handleSubmit} className='row'>
                {(data.status === 'unapplied' || data.status === '') && (
                  <>
                    <div className='row mb-2'>
                      <label className='col-lg-12 fw-bold text-center fs-4 form-check form-check-sm form-check-custom justify-content-center gap-2'>
                        <input
                          type='checkbox'
                          name='apply'
                          id=''
                          className='form-check-input'
                          onChange={() => formik.setFieldValue('apply', !formik.values.apply)}
                        />
                        <span>利用規約に同意して取引申請を行う</span>
                      </label>
                      {formik.touched.apply && formik.errors.apply ? (
                        <div className='col-lg-12 text-center text-danger mb-2'>
                          {formik.errors.apply}
                        </div>
                      ) : null}
                    </div>
                    <div className='row mb-2'>
                      <div className='col-lg-12 text-center'>
                        <button type='submit' className='btn btn-primary'>
                          申請する
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {data.status === 'pending' && (
                  <>
                    <div className='row mb-2'>
                      <div className='col-lg-12 text-center'>
                        <button type='submit' className='btn btn-primary'>
                          申請を取り消す
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
