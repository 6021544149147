/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useRef, useEffect, useMemo, Fragment} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useNavigate} from 'react-router-dom'
import {ChartsWidgetMonthly, ChartsWidgetDaily} from './components'
import {latterpaymentsGet} from 'src/app/modules/api'
import {formatNumber} from 'src/app/modules/utils'
import moment from 'moment'
import Chart from 'react-apexcharts'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {Spin} from 'antd'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'ホーム',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

function LaterPaymentTotal() {
  const navigate = useNavigate()
  // 決済可能額
  const [awaitAmount, setAwaitAmount] = useState(0)
  const [laterPaymentAmount, setLaterPaymentAmount] = useState(0)
  const [latterPayments2, setlatterPayments2] = useState(0)
  const bankList = useRef<any[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const response: any = await latterpaymentsGet('', {})
      setlatterPayments2(response.latterPayments.payment_limit)
      if (response.payments.length > 0) {
        bankList.current = response.payments
        let paymentAmount = response.payments
          .map((item: any) => {
            if (item.payment_status === 'later_payment') {
              return item.payment_amount
            }
            return 0
          })
          .filter(Number)
        if (paymentAmount.length > 0) {
          let total = paymentAmount.reduce((prev: any, next: any) => prev + next)
          setLaterPaymentAmount(total)
        }
        let awaitAmount = response.payments
          .map((item: any) => {
            if (item.payment_status === 'bank_virtual_account_wait') {
              return item.payment_amount
            }
            return 0
          })
          .filter(Number)
        if (awaitAmount.length > 0) {
          let awaitTotal = awaitAmount.reduce((prev: any, next: any) => prev + next)
          setAwaitAmount(awaitTotal)
        }
      }
    }

    fetchData()
  }, [])

  return (
    <>
      {!!latterPayments2 && (
        <div className='card mb-6'>
          <div className='card-body' style={{backgroundColor: '#f5f5f5'}}>
            <div className='mb-0'>
              <div className='row'>
                <div className='col-12'>
                  <span className='fw-bold'>後Payご利用状況</span>
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <span className=''>ご利用可能金額上限：</span>
                  <span className='fw-bold' style={{color: 'red'}}>
                    {formatNumber(latterPayments2)}円
                  </span>
                </div>
              </div>

              {!!laterPaymentAmount && (
                <div className='row'>
                  <div className='col-12'>
                    <span className='fw-bold'>後Pay内訳</span>
                  </div>
                  <div className='col-12'>
                    <span className=''>現時点利用金額：</span>
                    <span className='fw-bold'>{laterPaymentAmount.toLocaleString()}円</span>
                  </div>
                </div>
              )}
              {!!laterPaymentAmount && (
                <button
                  type='button'
                  className='btn btn-danger d-block mt-5 w-100 w-lg-auto'
                  onClick={() => {
                    navigate('/later/checkout')
                  }}
                >
                  お支払いへ進む
                </button>
              )}

              {!!awaitAmount && (
                <div className='mt-6'>
                  <LaterPaymentBanks data={bankList.current} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const Pageconts: FC = () => {
  const currentMonth = moment().format('YYYY年M月')
  const currentToday = moment().format('YYYY年M月D日')
  const currentTime = moment().format('HH時m分')
  const [initReportData, setInitReportData] = useState<boolean>(false)
  const [monthlyTotal, setMonthlyTotal] = useState<number>(0)
  const [reportData, setReportData] = useState<any>({})

  // let monthlyTotal: number = 1000000000
  // let dailyTotal: number = 1000000000

  useEffect(() => {
    // supplierSalesReport({}).then((res: any) => {
    //   // console.log('supplierSalesReport', res)
    setReportData({
      '2024-08-23': 100000,
      '2024-07-23': 100000,
    })
    // })
  }, [])

  useEffect(() => {
    if (reportData && Object.keys(reportData).length > 0) {
      setInitReportData(true)
    }
  }, [reportData])

  return (
    <>
      <div
        className='notice d-flex rounded p-6 col-xl-12 mb-6'
        style={{backgroundColor: '#F9F7FF'}}
      >
        <i className='ki-duotone ki-information-5 fs-2tx me-4' style={{color: '#A466EA'}}>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
        </i>
        <div className='d-flex flex-stack flex-grow-1 '>
          <div className=' fw-semibold'>
            <div className='fw-bold fs-4'>【重要】システムメンテナンスのお知らせ</div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <div className='col-12 col-lg-8'>
          <div className='row mb-6'>
            <div className='col-12'>
              <NavigateMenu />
            </div>
          </div>
          <div className='row mb-6'>
            <div className='col-12'>
              <LaterPaymentTotal />
            </div>
          </div>
          <div className='row mb-6'>
            <div className='col-12'>
              <div className='d-flex flex-column rom-sm gap-2'>
                <div className='alert alert-dismissible bg-white d-flex flex-sm-row align-items-center w-100 col-12 mb-0 p-5'>
                  <i className='ki-duotone ki-search-list fs-2hx text-primary '>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                  <div className='d-flex pe-0 pe-sm-10 fs-4 flex-grow-1'>
                    <span className='fw-bold'>支払待ちの注文</span>
                  </div>
                  <div className='d-flex pe-0 pe-sm-10 fs-4 flex-shrink-0 me-4'>
                    <span className='fw-bold'>◎件</span>
                  </div>
                  <div className='d-flex pe-0 pe-sm-10 fs-4 flex-shrink-0'>
                    <button type='button' className='btn btn-sm btn-primary'>
                      確認
                    </button>
                  </div>
                </div>

                <div className='alert alert-dismissible bg-white d-flex flex-sm-row align-items-center w-100 col-12 mb-0 p-5'>
                  <i className='ki-duotone ki-search-list fs-2hx text-primary '>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                  <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-grow-1'>
                    <span className='fw-bold'>出荷待ちの注文</span>
                  </div>
                  <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-shrink-0 me-4'>
                    <span className='fw-bold'>◎件</span>
                  </div>
                  <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-shrink-0'>
                    <button type='button' className='btn btn-sm btn-primary'>
                      確認
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='row mb-6'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='card-title'>
                    <span className='fw-bold'>注文レポート</span>
                  </div>
                </div>
                <div className='card-body d-flex justify-content-between flex-column pb-1 px-0'>
                  <div className='row'>
                    <div className='col-12'>
                      <Spin spinning={!initReportData}>
                        {<SalesChart salesData={reportData} />}
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className='col-12 col-lg-4'>
          <StoreNotificationsTable />
          <SystemNotifications />
        </div>
      </div>
    </>
  )
}

const DashboardBuyer: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>ダッシュボード</PageTitle>
      <Pageconts />
    </>
  )
}
export {DashboardBuyer}

const StoreNotificationsTable: FC = () => {
  type NotificationCategory = 'order' | 'customer' | 'all'

  type Notification = {
    date: string
    title: string
    content: string
    category: string
    link: string
  } | null

  const [notifications, setNotifications] = useState<Notification[]>([])
  const [category, setCategory] = useState<NotificationCategory>('all')

  const handleCategoryChange = (e: NotificationCategory) => {
    // console.log(e.target.value)
    setCategory(e)
  }

  useEffect(() => {
    setCategory('all')
    // setNotifications(testNotifications)
  }, [])

  useEffect(() => {
    if (category === 'all') {
      // setNotifications(testNotifications)
    } else {
      // setNotifications(
      //   testNotifications.filter((notification) => notification.category === category)
      // )
    }
  }, [category])

  return (
    <>
      <div className='card mb-6'>
        <div className='card-header'>
          <div className='card-title'>
            <span className='fw-bold'>注文・仕入に関する連絡事項</span>
          </div>
          <div className='card-toolbar'>
            {/* <div className="d-flex flex-stack flex-wrap gap-4">
            <div className="d-flex align-items-center fw-bold">
              <div className='fs-7 me-2'>カテゴリ</div>
              <select name="" id="" className='form-select form-select-transparent text-graY-800 fs-base lh-1 fw-bold py-0 ps-3 w-auto' data-control='select2' data-hide-search='true' data-dropdown-css-class='w-150px' data-placeholder='カテゴリーを選択'>
                <option></option>
                <option value="all" selected>全て</option>
                <option value="order">受注に関する連絡</option>
                <option value="customer">取引先からの連絡</option>
              </select>
            </div>
          </div> */}
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            {/* <div className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold mb-4'>
              <div className='nav-item mt-2 flex-grow-1'>
                <a
                  href='#'
                  className={`nav-link justify-content-center w-100 text-active-primary ms-0 py-2 ${
                    category === 'all' ? 'active' : ''
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    handleCategoryChange('all')
                  }}
                >
                  未読のお知らせ
                </a>
              </div>
              <div className='nav-item mt-2 flex-grow-1'>
                <a
                  href='#'
                  className={`nav-link justify-content-center w-100 text-active-primary ms-0 py-2 ${
                    category === 'order' ? 'active' : ''
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    handleCategoryChange('order')
                  }}
                >
                  全てのお知らせ
                </a>
              </div>
            </div> */}

            <div className=''>
              {notifications.length > 0 &&
                notifications.map((notification, index) => (
                  <div className='card mb-3' key={index}>
                    <div className='card-body py-4'>
                      <div className='d-flex gap-4 mb-2'>
                        <div className='flex-shrink-0'>{notification?.date}</div>
                        <div className='flex-grow-1'>
                          {notification?.category === 'order' && (
                            <span className='badge badge-light-primary me-2'>
                              {notification?.title}
                            </span>
                          )}
                          {notification?.category === 'customer' && (
                            <span className='badge badge-light-info me-2'>
                              {notification?.title}
                            </span>
                          )}
                        </div>
                      </div>
                      <p className='card-text'>
                        <a href='#'>{notification?.content}</a>
                      </p>
                    </div>
                  </div>
                ))}
              {notifications.length === 0 && (
                <div className='card mb-3'>
                  <div className='card-body py-4'>
                    <div className='d-flex gap-4'>
                      <div className='flex-grow-1'>連絡事項はありません</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

type SystemNotification = {
  date: string
  title: string
  discription: string
  content: string
  category: string
} | null

// props として受け取る型の定義
type SystemNotificationListProps = {
  notifications: SystemNotification[]
}

const SystemNotifications: FC = () => {
  const [notifications, setNotifications] = useState<SystemNotification[]>([])

  useEffect(() => {
    setNotifications([
      {
        date: '2024年7月1日',
        title: 'HatChuPayβ版をリリースしました',
        discription: '',
        content: ``,
        category: 'news',
      },
    ])
  }, [])

  return (
    <>
      <div className='card mb-10'>
        <div className='card-header'>
          <div className='card-title align-items-start flex-column'>
            <span className='fw-bold'>システムからのお知らせ</span>
          </div>
        </div>
        <div className='card-body'>
          {notifications.length > 0 && (
            <>
              <SystemNotificationList notifications={notifications} />
              <SystemNotificationModal notifications={notifications} />
            </>
          )}
        </div>
      </div>
    </>
  )
}

const SystemNotificationList: FC<SystemNotificationListProps> = (params) => {
  return (
    <div className=''>
      {params?.notifications?.map((notification, index) => (
        <div className='card mb-3' key={index}>
          <div className='card-body py-4'>
            <div className='d-flex gap-4 mb-2'>
              <div className='flex-shrink-0'>{notification?.date}</div>
              <div className='flex-grow-1'>
                {notification?.category === 'order' && (
                  <span className='badge badge-light-primary me-2'>{notification?.title}</span>
                )}
                {notification?.category === 'customer' && (
                  <span className='badge badge-light-info me-2'>{notification?.title}</span>
                )}
              </div>
            </div>
            <p className='card-text'>{notification?.title}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

const SystemNotificationModal: FC<SystemNotificationListProps> = (params) => {
  return (
    <>
      {params?.notifications &&
        params?.notifications.map((notification, index) => (
          <div
            className='modal fade'
            role='dialog'
            tabIndex={-3}
            id={`modal-${index}`}
            key={`modal-${index}`}
          >
            <div
              className='modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered'
              role='document'
            >
              <div className='modal-content'>
                <div className='modal-body fs-4'>
                  <div className='mb-3'>{notification?.title}</div>
                  <div className='mb-3'>
                    <span
                      className='badge me-2'
                      style={{color: '#fff', backgroundColor: '#70AD47'}}
                    >
                      バージョンアップ
                    </span>
                    {notification?.date}
                  </div>
                  <div className='mb-2'>{notification?.content}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  )
}

const SalesChart: React.FC<{salesData: any}> = ({salesData}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [monthlyTotal, setMonthlyTotal] = useState<number>(0)
  const [dailyTotal, setDailyTotal] = useState<number>(0)

  // 今日～
  useEffect(() => {
    console.log('salesData', salesData)

    // 売上データのサンプル
    const renderData = {
      thisMonth: [0, 0, 0, 0, 0, 0, 0],
      lastMonth: [0, 0, 0, 0, 0, 0, 0],
    }

    // 6日前～今日までのテキスト
    // 3日前を取得
    const currentTitle =
      moment().subtract(6, 'days').format('MM月DD日') + '～' + moment().format('MM月DD日')

    // 先月の同日を取得
    const lastTitle =
      moment().subtract(6, 'days').subtract(1, 'months').format('MM月DD日') +
      '～' +
      moment().subtract(1, 'months').format('MM月DD日')

    // 6日前～今日までのテキスト
    const xcategories = []
    for (let i = 6; i >= 0; i--) {
      xcategories.push(moment().subtract(i, 'days').format('MM月DD日'))
    }

    // 6日前～今日までの売上データをrenderDataにセット
    for (let i = 0; i < 7; i++) {
      const currentDay = moment()
        .subtract(6 - i, 'days')
        .format('YYYY-MM-DD')
      const lastDay = moment()
        .subtract(6 - i, 'days')
        .subtract(1, 'months')
        .format('YYYY-MM-DD')

      console.log('currentDay', currentDay)
      console.log('salesData', salesData[currentDay])

      renderData.thisMonth[i] = salesData[currentDay] || 0
      renderData.lastMonth[i] = salesData[lastDay] || 0
    }

    // ApexOptionsの設定
    const options: ApexOptions = {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false, // ツールバーを非表示にする
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '55%',
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        curve: 'smooth', // 線を滑らかにする
      },
      xaxis: {
        categories: xcategories,
      },
      fill: {
        opacity: 1,
      },
      // tooltip: {
      //   y: {
      //     formatter: (val) => `¥${val}`,
      //   },
      // },
      series: [
        {
          name: currentTitle,
          data: renderData.thisMonth,
        },
        {
          name: lastTitle,
          data: renderData.lastMonth,
        },
      ],
    }

    // ApexChartsのインスタンスを作成
    const chart = new ApexCharts(chartRef.current, options)
    chart.render()

    // クリーンアップ
    return () => {
      chart.destroy()
    }
  }, [])

  useEffect(() => {
    // res.dataから今月（yyyy-mm）が先方一致するkeyを探し、合計する
    let total = 0

    // 今日
    const currentDay = moment().format('YYYY-MM-DD')

    // 今月
    const currentMonth = moment().format('YYYY-MM')

    // 先月
    const lastMonth = moment().subtract(1, 'months').format('YYYY-MM')

    for (const key in salesData) {
      // keyの文字列にcurrentMonthを含むか
      if (key.includes(currentMonth)) {
        total += salesData[key]
      }
    }

    setDailyTotal(salesData[currentDay] || 0)
    setMonthlyTotal(total)
  }, [])

  return (
    <>
      <div className='row px-4 gap-y-2'>
        <div className='col-6 col-lg-3'>
          <div className='d-flex flex-column gap-1'>
            <span className='fw-bold'>■今日の注文</span>
            <span className='fw-bold fs-2'>◎件</span>
          </div>
        </div>
        <div className='col-6 col-lg-3'>
          <div className='d-flex flex-column gap-1'>
            <span className='fw-bold'>■今日の注文金金額</span>
            <span className='fw-bold fs-2'>{formatNumber(dailyTotal)}円</span>
          </div>
        </div>
        <div className='col-6 col-lg-3'>
          <div className='d-flex flex-column gap-1'>
            <span className='fw-bold'>■今月の注文</span>
            <span className='fw-bold fs-2'>◎件</span>
          </div>
        </div>
        <div className='col-6 col-lg-3'>
          <div className='d-flex flex-column gap-1'>
            <span className='fw-bold'>■今月の注文金額</span>
            <span className='fw-bold fs-2'>{formatNumber(monthlyTotal)}円</span>
          </div>
        </div>
      </div>
      <div ref={chartRef}></div>
    </>
  )
}

const NavigateMenu: FC = () => {
  const navigate = useNavigate()
  const goNavigator = (index: number) => {
    if (index == 1) {
      navigate('/product')
    } else if (index == 2) {
      navigate('/order')
    } else if (index == 3) {
      navigate('/customer')
    } else if (index == 4) {
      navigate('/account')
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>
            <span className='fw-bold'>ショートカット</span>
          </div>
        </div>
        <div className='card-body py-2'>
          <div className='d-flex justify-content-center gap-4 flex-wrap'>
            <a
              className='btn btn-white btn-outline '
              onClick={(e) => {
                e.preventDefault()
                navigate('/buy/product')
              }}
            >
              <i className='fa-solid fa-list fs-6 me-2 '></i>
              <span className='fs-6'>商品を探す</span>
            </a>
            <a
              className='btn btn-white btn-outline '
              onClick={(e) => {
                e.preventDefault()
                navigate('/buy/favorite')
              }}
            >
              <i className='fa-solid fa-list fs-6 me-2 '></i>
              <span className='fs-6'>いつもの商品</span>
            </a>
            <a
              className='btn btn-white btn-outline '
              onClick={(e) => {
                e.preventDefault()
                navigate('/buy/cart')
              }}
            >
              <i className='fa-solid fa-user fs-6 me-2 '></i>
              <span className='fs-6'>カート</span>
            </a>
            <a
              className='btn btn-white btn-outline '
              onClick={(e) => {
                e.preventDefault()
                navigate('/orderhistory')
              }}
            >
              <i className='fa-solid fa-user fs-6 me-2 '></i>
              <span className='fs-6'>注文履歴</span>
            </a>
            <a
              className='btn btn-white btn-outline '
              onClick={(e) => {
                e.preventDefault()
                navigate('/account/account')
              }}
            >
              <i className='fa-solid fa-circle-user fs-6 me-2 '></i>
              <span className='fs-6'>アカウント編集</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

interface LaterPaymentBanksProps {
  data: {
    pk: string
    company_name: string
    display_id: string
    order_date: string
    order_id: string
    payment_amount: number
    payment_method: string
    payment_status: 'bank_virtual_account_wait' | 'later_payment'
    supplier_display_id: string
    supplier_id: string
    payment_processor_bank_account: {
      Status: string
      ProcessDate: string
      Amount: string
      VaExpireDate: string
      VaTradeCode: string
      VaBankCode: string
      VaBankName: string
      VaBranchCode: string
      VaBranchName: string
      VaAccountType: string
      VaAccountNumber: string
    } | null
  }[]
}

const LaterPaymentBanks: FC<LaterPaymentBanksProps> = ({data}) => {
  const [show, setShow] = useState(false)
  const [scroll, setScroll] = useState(true)
  const memoBankList = useMemo(() => {
    let bankList: {[key: string]: any} = {}
    if (data.length > 0) {
      data.forEach((item) => {
        if (item.payment_processor_bank_account) {
          bankList[item.payment_processor_bank_account.VaTradeCode] = {
            ...item.payment_processor_bank_account,
            company_name: item.company_name,
          }
        }
      })
      return bankList
    } else {
      return {}
    }
  }, [data])

  useEffect(() => {
    // console.log('LaterPaymentBanks', data.length)
    if (data.length > 0) {
      setShow(true)
    }
  }, [memoBankList])

  return (
    <>
      {data.length > 0 && (
        <>
          <div className='row' id='bankList'>
            <div
              className='col-12'
              style={{
                height: scroll && Object.keys(memoBankList).length > 1 ? '40vh' : 'auto',
                maxHeight: scroll && Object.keys(memoBankList).length > 1 ? '250px' : 'none',
                overflow: scroll && Object.keys(memoBankList).length > 1 ? 'scroll' : 'visible',
              }}
            >
              {show &&
                Object.keys(memoBankList).map((key, index) => (
                  <>
                    <div className='row p-4 bg-white' key={key}>
                      <div className='col-12'>
                        <span className='fw-bold'>
                          支払情報 {index + 1}/{Object.keys(memoBankList).length}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-4 bg-white p-4 pt-0' key={index}>
                      <div className='col-12'>
                        取引先　<span className='fw-bold'>{memoBankList[key].company_name}</span>
                      </div>
                      <div className='col-12'>
                        振込金額　
                        <span className='fw-bold' style={{color: 'red'}}>
                          {formatNumber(memoBankList[key].VaRequestAmount)}円
                        </span>
                      </div>
                      <div className='col-12'>
                        <div>
                          銀行名　{memoBankList[key].VaBankName}({memoBankList[key].VaBankCode}){' '}
                          {memoBankList[key].VaBranchName}({memoBankList[key].VaBranchCode})
                        </div>
                        <div>
                          口座　
                          <span>
                            {memoBankList[key].VaAccountType === '1' && '普通 '}
                            {memoBankList[key].VaAccountType === '2' && '当座 '}
                          </span>
                          <span>{memoBankList[key].VaAccountNumber}</span>
                        </div>
                        <div>振込先名　SMBC-GP</div>
                        <div>
                          振込期限　
                          {moment(memoBankList[key].VaExpireDate).format('YYYY年MM月DD日')}迄
                        </div>
                        <div>
                          振込コード　{memoBankList[key].VaTradeCode}
                          <br />※ 振込の際は依頼人名の先頭にこの値を入力してください
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </div>
          {Object.keys(memoBankList).length > 1 && (
            <button
              type='button'
              className='btn btn-white btn-active-white btn-outline btn-active-outline d-block mt-5 w-100 w-lg-auto'
              onClick={() => {
                setScroll(!scroll)
                //bankList + 50pxまでスクロール
                const bankList = document.getElementById('bankList')
                if (bankList) {
                  bankList.scrollIntoView({behavior: 'smooth'})
                }
              }}
            >
              {scroll ? 'まとめて表示' : 'スクロールで表示'}
            </button>
          )}
        </>
      )}
    </>
  )
}
