import {Amplify} from '@aws-amplify/core'
import {Auth} from '@aws-amplify/auth'
import React, {createContext, useContext, useEffect, useState, useRef, ReactNode} from 'react'
import AwsConfigAuth from '../../../../service/auth'
import {postServer, getServer} from '../../../../service/http'
import {cognitoMessagesJP} from './AuthCognitoMessages'
import axios from 'axios'

Amplify.configure({Auth: AwsConfigAuth})

function getCognitoErrorMessageJP(error: {code?: string; message: string}): string {
  // エラーコードに対応するメッセージを取得
  const message = cognitoMessagesJP[error.code!]

  // メッセージがマッピングに存在すればそれを返す。存在しなければデフォルトのメッセージを返す。
  // return message || "不明なエラーが発生しました。";
  return message || error.message
}

interface UseAuth {
  auth: {
    isLoading: boolean
    isAuthenticated: boolean
    username: string
    token: string
    role: string[]
    caps: string[]
    data: any
    menu: MenuItem[] | null
    group_id: string
    signUp: (username: string, password: string, attributes: any) => Promise<Result>
    confirmSignUp: (verificationCode: string) => Promise<Result>
    signIn: (username: string, password: string) => Promise<Result>
    signOut: () => void
    forgotPassword: (username: string) => Promise<Result>
    forgotPasswordSubmit: (username: string, code: string, newPassword: string) => Promise<Result>
    changePassword: (username: string, oldPassword: string, newPassword: string) => Promise<Result>
    resendSignUp: (username: string) => Promise<Result>
    hasCapability: (capability: string) => boolean
    currentAuthenticatedUser: () => Promise<any>
    updateUserAttributes: (user: any, attributes: any) => Promise<any>
    verifyCurrentUserAttributeSubmit: (attributeName: string, code: string) => Promise<any>
  }

  saveAuth: (auth: any) => void
  currentUser: any
  setCurrentUser: (currentUser: any) => void
  logout: () => void
  completeNewPassword: (user: any, newPassword: string) => Promise<Result>
}

interface Result {
  success: boolean
  message: string
  code?: string
}

interface CognitoError extends Error {
  code?: string
}

// After
interface AuthProviderProps {
  children: ReactNode
}

type MenuItem = {
  title: string
  url?: string
  icon?: string
  submenu?: MenuItem[]
}

const authContext = createContext({} as UseAuth)

export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [username, setUsername] = useState('')
  const [group_id, setGroupId] = useState('')
  const [password, setPassword] = useState('')
  const [token, setToken] = useState('')
  const [data, setData] = useState({})
  const [role, setRole] = useState<string[]>([])
  const [caps, setCaps] = useState<string[]>([])
  const [menu, setMenu] = useState<MenuItem[]>([])

  const fetchUser = async () => {
    setIsLoading(true)
    try {
      const result = await Auth.currentAuthenticatedUser({bypassCache: true})
      // console.log('result', result)
      setUsername(result.username)
      setToken(result.signInUserSession.idToken.jwtToken)
      axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${result.signInUserSession.idToken.jwtToken}`
      // axios.defaults.headers.common['Accept-Encoding'] = `gzip`
      const res: any = await postServer(
        '/login-info',
        {},
        {
          headers: {'Content-Type': 'application/json'},
        }
      )

      setData(result)
      setRole(res.userType)
      setGroupId(res.group_id)
      setMenu(res.menus || [])
      setIsAuthenticated(true)
    } catch (error) {
      setData({})
      setUsername('')
      setGroupId('')
      setIsAuthenticated(false)
      setToken('')
      setRole([])
      setCaps([])
      setMenu([])
      console.log(error)
      // return false;
    } finally {
      setIsLoading(false)
    }
  }

  // トークンリフレッシュ関数
  const refreshToken = async () => {
    try {
      const session = await Auth.currentSession()
      const token = session.getIdToken().getJwtToken()
      // トークンをステートに保存するなどの処理
      console.log('トークンがリフレッシュされました', token)
    } catch (error) {
      console.error('トークンのリフレッシュ中にエラーが発生しました', error)
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    // コンポーネントがマウントされたらトークンリフレッシュをスケジュール
    const intervalId = setInterval(refreshToken, 900000) // 15分 = 900000ミリ秒

    // コンポーネントがアンマウントされるときにクリーンアップ
    return () => clearInterval(intervalId)
  }, [])

  // useEffect(() => {
  //   if (!username) return
  // }, [username])

  const signUp = async (username: string, password: string, attributes: any = {}) => {
    try {
      await Auth.signUp({username, password, attributes: {...attributes}})
      setUsername(username)
      setPassword(password)

      return {success: true, message: ''}
    } catch (error) {
      const typedError = error as CognitoError
      return {
        success: false,
        // message: '認証に失敗しました。',
        code: typedError?.code,
        message: getCognitoErrorMessageJP(typedError),
      }
    }
  }

  const confirmSignUp = async (verificationCode: string) => {
    try {
      await Auth.confirmSignUp(username, verificationCode)
      const result = await signIn(username, password)
      setPassword('')
      return result
    } catch (error) {
      const typedError = error as CognitoError
      return {
        success: false,
        // message: '認証に失敗しました。',
        code: typedError?.code,
        message: getCognitoErrorMessageJP(typedError),
      }
    }
  }

  const resendSignUp = async (username: string) => {
    try {
      await Auth.resendSignUp(username)
      return {success: true, message: 'Verification code resent successfully.'}
    } catch (error) {
      const typedError = error as CognitoError
      return {
        success: false,
        code: typedError?.code,
        message: getCognitoErrorMessageJP(typedError),
      }
    }
  }

  const signIn = async (username: string, password: string) => {
    try {
      setIsLoading(true)
      const result = await Auth.signIn(username, password)
      // console.log('result', result)

      // data.challengeNameをチェックする
      // MFA_REQUIRED or SMS_MFA ２段階認証が必要
      // NEW_PASSWORD_REQUIRED 強制パスワード変更
      // その他 ログイン成功！
      if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log('NEW_PASSWORD_REQUIRED', result)
        return {success: false, message: '', code: result.challengeName, user: result}
      } else if (result.challengeName === 'MFA_REQUIRED' || result.challengeName === 'SMS_MFA') {
        // ２段階認証処理
      } else {
        await fetchUser()
        setUsername(result.username)
        setIsAuthenticated(true)
        setIsLoading(false)
        return {success: true, message: '', code: result.challengeName}
      }

      setUsername(result.username)
      setIsAuthenticated(true)
      setIsLoading(false)
      return {success: false, message: '', code: ''}
    } catch (error) {
      setUsername(username)
      const typedError = error as CognitoError
      setIsLoading(false)
      return {
        success: false,
        // message: '認証に失敗しました。',
        code: typedError?.code,
        message: getCognitoErrorMessageJP(typedError),
      }
    }
  }

  const signOut = async () => {
    try {
      await Auth.signOut({global: true})
      setUsername('')
      setMenu([])
      setData({})
      setIsAuthenticated(false)
      // localStorage.clear()
      return {success: true, message: ''}
    } catch (error) {
      const typedError = error as CognitoError
      return {
        success: false,
        // message: 'ログアウトに失敗しました。',
        code: typedError?.code,
        message: getCognitoErrorMessageJP(typedError),
      }
    }
  }

  const forgotPassword = async (username: string) => {
    try {
      await Auth.forgotPassword(username)
      setUsername(username)
      return {success: true, message: ''}
    } catch (error) {
      const typedError = error as CognitoError
      return {
        success: false,
        code: typedError?.code,
        message: getCognitoErrorMessageJP(typedError),
      }
    }
  }

  const forgotPasswordSubmit = async (username: string, code: string, newPassword: string) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword)
      return {success: true, message: ''}
    } catch (error) {
      const typedError = error as CognitoError
      return {
        success: false,
        code: typedError?.code,
        message: getCognitoErrorMessageJP(typedError),
      }
    }
  }

  const changePassword = async (username: string, currentPassword: string, newPassword: string) => {
    try {
      // ログインする
      const user = await Auth.signIn(username, currentPassword).then(async (user) => {
        await Auth.currentAuthenticatedUser({bypassCache: true})
        // ログイン成功そたらパスワードを変更する
        return Auth.changePassword(user, currentPassword, newPassword)
      })

      console.log('changePassword', user)

      return {success: true, message: ''}
    } catch (error) {
      const typedError = error as CognitoError
      console.log('changePassword Error', typedError)
      return {
        success: false,
        code: typedError?.code,
        message: getCognitoErrorMessageJP(typedError),
      }
    }
  }

  const completeNewPassword = async (user: any, newPassword: string) => {
    try {
      const updatedUser = await Auth.completeNewPassword(user, newPassword, {
        // 必要に応じて他のユーザー属性を渡す
      })
      console.log('New password set successfully:', updatedUser)
      return {success: true, message: ''}
    } catch (error) {
      const typedError = error as CognitoError
      return {
        success: false,
        code: typedError?.code,
        message: getCognitoErrorMessageJP(typedError),
      }
    }
  }

  const hasCapability = (capability: string) => {
    let hasCapability = false

    if (caps.includes(capability)) {
      hasCapability = true
    }

    if (role) {
      role.forEach((role) => {
        if (hasCapabilityInRole(role, capability)) {
          hasCapability = true
        }
      })
    }
    // console.log('hasCapability', hasCapability)
    return caps.includes(capability)
  }

  const hasCapabilityInRole = (role: string, capability: string) => {
    return false
  }

  const currentAuthenticatedUser = async () => {
    return Auth.currentAuthenticatedUser()
  }

  const updateUserAttributes = async (user: any, attributes: any) => {
    return Auth.updateUserAttributes(user, attributes)
  }

  const verifyCurrentUserAttributeSubmit = async (attributeName: string, code: string) => {
    return Auth.verifyCurrentUserAttributeSubmit(attributeName, code)
  }

  return {
    auth: {
      isLoading,
      isAuthenticated,
      username,
      token,
      role,
      caps,
      data,
      menu,
      group_id,
      signUp,
      confirmSignUp,
      signIn,
      signOut,
      forgotPassword,
      forgotPasswordSubmit,
      changePassword,
      resendSignUp,
      hasCapability,
      currentAuthenticatedUser,
      updateUserAttributes,
      verifyCurrentUserAttributeSubmit,
    },
    saveAuth: () => {},
    currentUser: username,
    setCurrentUser: () => {},
    logout: signOut,
    completeNewPassword: completeNewPassword,
  }
}

export const AuthInit = () => {}
