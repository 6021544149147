/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const profileBreadCrumbs: Array<PageLink> = [
	{
		title: 'ホーム',
		path: '/dashboard',
		isSeparator: false,
		isActive: false,
	  },
	  {
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	  },
]

const Pageconts: FC = () => {
    return (
      <>
	  <div className='card  p-9'>
		<h2 className="fs-2x w-bolder mb-6">よくあるご質問</h2>
		<div className="row mb-12">
			<div className="col-md-6 pe-md-10 mb-10 mb-md-0">
				<h2 className="fw-bold mb-4">使い方ついて</h2>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle  mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_4_1">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">会員登録の手順を知りたい</h4>
					</div>
					<div id="kt_job_4_1" className="collapse show fs-6 ms-1">
						<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_4_2">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">契約を終了したい</h4>
					</div>
					<div id="kt_job_4_2" className="collapse fs-6 ms-1">
					<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_4_3">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">審査時に必要な情報</h4>
					</div>
					<div id="kt_job_4_3" className="collapse fs-6 ms-1">
					<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
			</div>
			<div className="col-md-6 pe-md-10">
				<h2 className="fw-bold mb-4">その他</h2>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_5_1">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">困った時のお問い合せ先</h4>
					</div>
					<div id="kt_job_5_1" className="collapse fs-6 ms-1">
					<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_5_2">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">安全性について</h4>
					</div>
					<div id="kt_job_5_2" className="collapse fs-6 ms-1">
					<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-md-6 pe-md-10 mb-10 mb-md-0">
				<h2 className="fw-bold mb-4">契約について</h2>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_6_1">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">どのような契約がありますか？</h4>
					</div>
					<div id="kt_job_6_1" className="collapse fs-6 ms-1">
						<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_6_2">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">使える決済方法</h4>
					</div>
					<div id="kt_job_6_2" className="collapse fs-6 ms-1">
					<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_6_3">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">インボイスに対応していますか？</h4>
					</div>
					<div id="kt_job_6_3" className="collapse fs-6 ms-1">
					<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_6_4">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">契約プランの変更について</h4>
					</div>
					<div id="kt_job_6_4" className="collapse fs-6 ms-1">
					<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_6_5">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">個人での契約は可能か？</h4>
					</div>
					<div id="kt_job_6_5" className="collapse fs-6 ms-1">
					<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_6_6">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">配送方法について</h4>
					</div>
					<div id="kt_job_6_6" className="collapse fs-6 ms-1">
					<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
				<div className="m-0">
					<div className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0" data-bs-toggle="collapse" data-bs-target="#kt_job_6_7">
						<div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
							<i className="fas fa-minus-square toggle-on text-primary fs-1"></i>
                            <i className="fas fa-plus-square toggle-off fs-1"></i>
						</div>
						<h4 className="fw-bold cursor-pointer mb-0">休止はできますか？</h4>
					</div>
					<div id="kt_job_6_7" className="collapse fs-6 ms-1">
					<div className="mb-4 fw-semibold fs-6 ps-10">
						<a href="#">会員登録ページ</a>よりご登録ください。<br></br>
						審査後、●日前後で審査結果をご連絡させていただきます。<br></br>
						通貨された場合は、すぐにご利用いただけます。</div>
					</div>                
					<div className="separator separator-dashed"></div>
				</div>
			</div>
		</div>
		</div>
    </>
    )
}

export default function FaqPage() {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>よくあるご質問</PageTitle>
      <Pageconts />
    </>
  )
}
