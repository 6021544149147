// import { formatNumber } from 'src/app/modules/utils'
import React, {useState, useEffect} from 'react'
import {parameterStoreGet} from 'src/app/modules/api'

interface FormatNumberOptions {
  decimals?: number
  method?: 'round' | 'floor' | 'ceil'
  locale?: string
}

export const formatNumber = (value: string | number, options: FormatNumberOptions = {}): string => {
  // valueがundefinedの場合は空文字を返す
  if (value === undefined) {
    return ''
  }

  const {decimals = 0, method = 'round', locale = 'ja-JP'} = options

  // 数値に変換
  let number: number = typeof value === 'string' ? parseFloat(value) : value

  if (isNaN(number)) {
    throw new Error('Invalid number')
  }

  // 小数点以下の処理
  const factor = Math.pow(10, decimals)

  switch (method) {
    case 'floor':
      number = Math.floor(number * factor) / factor
      break
    case 'ceil':
      number = Math.ceil(number * factor) / factor
      break
    case 'round':
    default:
      number = Math.round(number * factor) / factor
      break
  }

  // フォーマット
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })

  return formatter.format(number)
}

// \nを<br>に変換する
export function nl2br(str: string) {
  return str.split('\n').map((line: any, index: number) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))
}

// 画面幅を監視するフック
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}

// 画面サイズ満たすかどうかを判定するフック
export const useIsWidthSize = (size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): boolean => {
  const {width} = useWindowSize()
  const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  }

  return width >= breakpoints[size]
}

// aws パラメータストアから情報を取得する

type ParameterStoreGetOptions = {
  names: string[]
}

export const getParameterStore = async (options: ParameterStoreGetOptions) => {
  let response: any = await parameterStoreGet(options, {})
  let result: any = {}

  Object.keys(response).map((item: any, index: number) => {
    try {
      result[item] = JSON.parse(response[item])
    } catch (error) {
      result[item] = response[item]
    }
  })

  return result
}
