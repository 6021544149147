import { getServer, postServer, deleteServer } from 'src/service/http'

// 取引先承認一覧
export function customerSupplierApplicationsSearch(param: object, headers: object = {}) {
    return getServer(`/customer-supplier-applications-search`, { ...param }, { ...headers })
}

export function supplierApplicationInfoGet(id: string, param: object,headers:object={}) {
    return getServer(`/supplier-application-info-get/${id}`, { ...param},{...headers})
}

// 顧客-加盟店独自項目
export function customerSupplierUniqueGet(param: object, headers: object = {}) {
    return getServer(`/customer-supplier-unique-get`, { ...param},{...headers})
}

// 顧客-加盟店独自項目
export function customerSupplierUniqueUpdate(param: object, headers: object = {}) {
    return postServer(`/customer-supplier-unique-update`, { ...param },{...headers})
}