import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
//import { useNavigate } from 'react-router-dom'
import paramOrder from './settings/order.json'
import paramOrderLineItem from './settings/orderLineItem.json'
import paramLogistics from './settings/logistics.json'
import paramPayment from './settings/payment.json'
import { Sortable } from '@shopify/draggable';
import { templateDdd, templateDataGet, templateUpdate } from 'src/app/modules/api'
import { useAuth } from "src/app/modules/auth"
import { showSuccessMessage, showErrorMessage } from 'src/app/components/sweetAlert'
import { useLocation, useParams, useSearchParams, useNavigate } from 'react-router-dom'
type params = paramGroup[]

interface paramGroup {
    id: string
    title: string
    params: paramGroupItem[]
}

interface paramGroupItem {

    _uniqueId?: string
    id: string
    label: string

    isStatic?: boolean

}

type templeteSettings = templateSettingItem[]

interface templateSettingItem extends paramGroupItem {
    title: string 
    value: string
}

type uploadParams = uploadParamItem[]

interface uploadParamItem {
    id: string
    title: string
    value?: string
}

// JSONのparams.idをid-params.idに変換
paramOrder.params.forEach((item: any) => {
    item.id = `${paramOrder.id}-${item.id}`
})
paramOrderLineItem.params.forEach((item: any) => {
    item.id = `${paramOrderLineItem.id}-${item.id}`
})
paramLogistics.params.forEach((item: any) => {
    item.id = `${paramLogistics.id}-${item.id}`
})
paramPayment.params.forEach((item: any) => {
    item.id = `${paramPayment.id}-${item.id}`
})


export default function CsvTemplateDetail() {
    const { auth } = useAuth();
    let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.token}`
    }
    const currentLocation = useLocation()

    const navigate = useNavigate()

    const [formData, setFormData] = useState<any>({})
    const [initialValues, setInitialValues] = useState<any>({})
    const [param, setParams] = useState<params>([])
    const [templeteSettingParams, setTempleteSettingParams] = useState<templeteSettings>([])
    const [uploadParams, setUploadParams] = useState<uploadParams>([])
    const [queryParams, setQueryParams] = useState<any>({
        name: "",
        column: `[ { id: "2", title: "title", value: "value" } ]`
    }) // 保存接口 添加参数

    const [list1, setList1] = useState<any>([]) // 选项列表1
    const [list2, setList2] = useState<any>([]) // 选项列表2

    const handleSave = (values: templeteSettings) => {

        console.log(values, "保存的数据");

        // valuesをupddateParamsに変換
        const updateParams = values.map((item) => {
            const { id, label, value, title } = item;
            return { id, label, value, title };
        })
        const jsonString = JSON.stringify(updateParams);
        let params = {
            name: queryParams.name,
            column: `${jsonString}`
        }

        if (currentLocation.state) {
            // 更新
            var obj ={
                name:queryParams.name,
                column:`${jsonString}`,
                pk:currentLocation.state.pk
            }
            templateUpdate(obj, { headers }).then((res: any) => {
                showSuccessMessage(res.message)
            })

        } else {
            templateDdd(params, { headers }).then((res: any) => {
                showSuccessMessage(res.message)

            })

        }
        // setContentLoading(true)
    }

    // 获取发货详情
    const getAllDetail = (page: any, pageSize: any, obj?: any) => {
        console.log(obj)

        let pkVal = currentLocation.state.pk
        templateDataGet(pkVal).then((res: any) => {
            console.log(res, "详情接口返回的数据")
             setQueryParams({ ...queryParams, name: res.name  })
             var arrData=JSON.parse(res.column)
             const newData = arrData
             setTempleteSettingParams([...templeteSettingParams, ...newData])
        })
    }

    useEffect(() => {
        setParams([]);
        setParams(param => [...param, paramOrder, paramOrderLineItem, paramLogistics, paramPayment]);

        const list1: {}[] = []
        setList1(list1);

        const list2: {}[] = [];
        setList2(list2);
        if (currentLocation.state) {
            getAllDetail(1, 1)//获取详情
        } else {
            console.log("新增进来的");
        }
    }, [])


    useEffect(() => {

        templeteSettingParams.forEach(item => {
            if (!item._uniqueId) {
                item._uniqueId = crypto.randomUUID()
            }
        });

        const sortable = new Sortable(document.querySelectorAll('tbody.templete-param-settings'), {
          draggable: 'tr',
          handle: 'a',
          mirror: {
            appendTo: 'tbody.templete-param-settings',
            constrainDimensions: true, // ミラー要素の寸法をドラッグされた要素と同じに保つ
            xAxis: false, // ミラー要素をY軸のみに沿ってドラッグ可能にする
            yAxis: true  // ミラー要素をX軸のみに沿ってドラッグ可能にする（上記xAxisと矛盾するため、実際には一方のみをtrueに設定）
          }
        });
    
        sortable.on('sortable:stop', (evt) => {
            const {oldIndex, newIndex} = evt;

            if (oldIndex !== newIndex) {
              // 並び替えのロジック
              const newList = [...templeteSettingParams];
              const [movedItem] = newList.splice(oldIndex, 1);
              newList.splice(newIndex, 0, movedItem);
              setTempleteSettingParams(newList);
            }
          });
    
        // クリーンアップ関数
        return () => {
          sortable.destroy();
        };
      }, [templeteSettingParams, setTempleteSettingParams]);

    // 改变选中列表1
    const changeSelectList = (obj: any, param?: any, title?: String) => {
        let list1: any = []
        if (obj.id === 'all') {
          param.forEach((element: any) => {
            if (element.title == title) {
              element.params.forEach((item: any) => {
                item.title = item.label
                item.value = ''
              })
            list1 = element.params
        }
      })
            // list1.forEach((item: any) => {
            //     arr.push(item.id)
            // });
            setTempleteSettingParams([...templeteSettingParams, ...list1])
        } else {
            console.log(obj,"999999");
            obj.title=obj.label
            setTempleteSettingParams([...templeteSettingParams, obj])
        }
    }

    const handleRemoveRow = (indexToRemove: number) => {
        let list: any = []
        templeteSettingParams.map((item: any, k: number) => {
          if (k != indexToRemove) {
            list.push(item)
          }
        })
    setTempleteSettingParams(list)
    }
    const updateName = (id: any, newName: any) => {
        const updatedData = templeteSettingParams.map((item: any, index: any) => {
            if (index === id) {
                return { ...item, title: newName };
            }
            return item;
        });

        setTempleteSettingParams(updatedData);
    };
    const updateName2 = (id: any, value: any) => {
      console.log(value)
      const updatedData = templeteSettingParams.map((item: any, index: any) => {
        if (index === id) {
          return {...item,value:value}
        }
        return item
      })
      setTempleteSettingParams(updatedData)
    }
    return (
        <div>
            <div className="container-fluid">
                <form>
                    <div className="card mb-6">
                        <div className="d-flex align-items-center card-header">
                            <span className="card-title fw-bold m-0">受注CSVテンプレート設定</span>
                        </div>
                        <div className="card-body" style={{ paddingTop: '0.6rem', paddingBottom: '0.6rem' }}>
                            <div className="row">
                                <div className="col">
                                    <div className="row mb-6">
                                        <div className="col-lg-3 col-form-label">
                                            <label className="col-form-label">CSVテンプレート名</label>
                                        </div>
                                        <div className="col-lg-5 align-self-center">
                                            <input className="form-control" type="text" placeholder=""
                                                value={queryParams.name}
                                                onChange={(e) => {
                                                    // queryParams.name = e.target.value
                                                    setQueryParams({ ...queryParams, name: e.target.value })
                                                    console.log(queryParams, "参数");

                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-6">
                        <div className="d-flex align-items-center card-header">
                            <span className="card-title fw-bold m-0">ダウンロード項目指定</span>
                        </div>
                        <div className="card-body">
                            <div className="row mb-6">
                                <div>
                                    <div>CSVダウンロードする受注データ項目を設定</div>
                                    <div>出力する項目を選択してください。</div>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    param.map((item: paramGroup, index: Number) => (
                                        <div className="card mb-4" key={item.id}>
                                            <div className='col-lg-12'>
                                                <h2 className='fs-6 fw-bold mb-4'>{item.title}</h2>
                                            </div>
                                            <div className='card-body p-0 d-flex flex-wrap gap-3'>
                                                <button className='btn btn-sm btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary' onClick={(e) => {
                                                        e.preventDefault()
                                                        let obj = {
                                                            id: 'all',
                                                            label: '全て追加',
                                                            value: ""
                                                        }
                                                        changeSelectList(obj, param, item.title)
                                                    }}>全て追加</button>
                                                { item.params.map((item: paramGroupItem) => (
                                                    <button className='btn btn-sm btn-outline btn-outline-dashed btn-outline-dark btn-active-light-primary' key={item.id} onClick={(e) => {
                                                        e.preventDefault()
                                                        let obj = {
                                                            id: item.id,
                                                            label: item.label,
                                                            value: ""
                                                        }
                                                        changeSelectList(obj)
                                                    }}>{item.label}</button>
                                                )) }

                                            </div>
                                        </div>

                                    ))
                                }
                                <div className="card mb-4">
                                    <div className='col-lg-12'>
                                        <h2 className='fs-6 fw-bold mb-4'>独自項目</h2>
                                    </div>
                                    <div className='card-body p-0 d-flex gap-3'>
                                        <button className='btn btn-sm btn-outline btn-outline-dashed btn-outline-dark btn-active-light-primary' onClick={(e) => {
                                                e.preventDefault()
                                                let obj = {
                                                    id: 'static_field',
                                                    label: '独自項目',
                                                    value: "",
                                                    isStatic: true
                                                }
                                                changeSelectList(obj)
                                            }}>独自項目</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="d-flex align-items-center card-header">
                            <span className="card-title fw-bold m-0">ダウンロード項目詳細設定</span>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className='table table-row-bordered gy-5 gs-7'>
                                    <thead>
                                        <tr>
                                            <th>列</th>
                                            <th>項目名</th>
                                            <th>CSVヘッダー名</th>
                                            <th>固定値</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className='templete-param-settings'>
                                        
                                        {templeteSettingParams.length > 0 && templeteSettingParams.map((item, index) => {                                                                                 

                                            return (
                                                <tr key={item._uniqueId}>
                                                    <td className='align-middle'>
                                                        <a href="#" className="border border-gray-300 btn btn-sm btn-icon btn-white me-6" onClick={(e) => e.preventDefault()}>
                                                            <i className="bi bi-chevron-expand"></i>
                                                        </a>
                                                        {index + 1}
                                                    </td>
                                                    <td className='align-middle' style={{width: '10rem'}}>{item.label}</td>
                                                    <td className='align-middle'>
                                                        <input type='text' className='form-control form-control-sm' defaultValue={item.title}
                                                            value={queryParams.value}
                                                            onChange={(e) => {
                                                                updateName(index, e.target.value)
                                                            }} />
                                                    </td>
                                                    <td className='align-middle'>
                                                        {item.isStatic||item.value  ? (<input type='text' className='form-control form-control-sm' value={item.value} onChange={(e) => {updateName2(index, e.target.value)}} />) : ('')}
                                                    </td>
                                                    <td className='align-middle'><div onClick={() => handleRemoveRow(index)} className="btn btn-sm btn-danger">行を削除</div></td>
                                                </tr>
                                            )
                                        })}
                                        {templeteSettingParams.length === 0 && (
                                            <tr>
                                                <td colSpan={4}>ダウンロード項目を指定してください</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button className="btn btn-light btn-active-light-primary me-2" onClick={(e) => { e.preventDefault(); navigate(-1) }}  >キャンセル</button>
                            <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); handleSave(templeteSettingParams) }}>保存</button>
                        </div>
                    </div>

                </form>

            </div>
        </div>
    )
}
