import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {Link} from 'react-router-dom'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {useAccount} from 'src/app/modules/accounts/AccountProvider'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  hasBadge?: string | false
  disabled?: boolean
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  disabled = false,
  hasBadge = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const {account} = useAccount()

  return (
    <div
      // className={clsx('menu-item without-sub', {'here': isActive}, 'menu-accordion')}
      // data-kt-menu-trigger='click'
      className={clsx('menu-item', {here: isActive}, '')}
      style={disabled ? {pointerEvents: 'none'} : {}}
    >
      {to !== '' ? (
        <Link className={clsx('menu-link', {active: isActive})} to={to}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span
                className='bullet bullet-dot'
                style={disabled ? {backgroundColor: 'gray'} : {}}
              ></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon' style={disabled ? {color: 'gray'} : {}}>
              <KTSVG path={icon} className={`svg-icon-2 ${disabled ? 'text-gray-600' : ''}`} />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className='menu-title' style={disabled ? {color: 'gray'} : {}}>
            {title}{' '}
            {hasBadge && account.menuAttributes[hasBadge] && (
              <span className='badge badge-circle badge-danger ms-2'>
                {account.menuAttributes[hasBadge]}
              </span>
            )}
          </span>
          <span className='menu-arrow'></span>
        </Link>
      ) : (
        <span className='menu-link'>
          {hasBullet && (
            <span className='menu-bullet'>
              <span
                className='bullet bullet-dot'
                style={disabled ? {backgroundColor: 'gray'} : {}}
              ></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon' style={disabled ? {color: 'gray'} : {}}>
              <KTSVG path={icon} className={`svg-icon-2 ${disabled ? 'text-gray-600' : ''}`} />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span className='menu-title' style={disabled ? {color: 'gray'} : {}}>
            {title} {hasBadge && <span className='badge badge-circle badge-danger ms-2'>1</span>}
          </span>
        </span>
      )}
      <div className={clsx('menu-item menu-sub', {'menu-active-bg': isActive})}>{children}</div>
    </div>
  )
}

export {SidebarMenuItemWithSub}
