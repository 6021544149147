import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '@/_metronic/helpers'
import moment from 'moment'
import {Loading} from 'src/app/components/loading'
import {billingInfoListGet,parameterStoreGet} from 'src/app/modules/api'
import {useAuth} from 'src/app/modules/auth'

const class1 = 'page-item previous'
const class2 = 'page-item previous active'
const class3 = 'page-item previous disabled'

const statusObj: any = {
  actived: 'アクティブ ',
  pause: '一時停止',
  deactived: '終了',
}

export function InfoList() {
  const {auth} = useAuth()
  const [nowPage, setNowPage] = useState<any>(1) // 当前页
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
  let [page, setPage] = useState<any>([]) // 页码列表
  const [pageSize, setPageSize] = useState<any>(10) // 每页多少条
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    pageSize: 10,
  }) // 分页参数
  const [selectedList, setSelectList] = useState<any>([]) // 选中的项
  const [arr, setArr] = useState<any>([
    {
      id: '1313460',
      issueDate: '2023/11/1',
      content: 'HatChuPay基本料金1',
      requestAmount: '3,300円',
      requestLetter: 'PDF',
      status: '決済前',
    },
    {
      id: '1313459',
      issueDate: '2023/11/2',
      content: 'HatChuPay基本料金2',
      requestAmount: '6,300円',
      requestLetter: 'CSV',
      status: '決済済み',
    },
  ]) //总数据
  const [data, setData] = useState<any>([]) // 数据列表

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`
  }

  //处理页码
  const changePage = (sumPage: any, size?: number | string) => {
    page = []
    let num = Math.ceil(sumPage / Number(size ? size : pageSize))
    for (let i = 1; i < num + 1; i++) {
      page.push(i)
    }
    let newPage = JSON.parse(JSON.stringify([...page]))
    setPage(newPage)
    console.log(page, newPage, ']]]]', num)
  }
  //获取当前页码的列表
  const getPageList = async (page: any) => {
    setIsLoading(true)
    // let newArr = arr.slice((page - 1) * pageSize, page * pageSize)
    // setData(newArr)
    setNowPage(page)
    queryParams.page = page
    getAllList()
    setIsLoading(false)
  }
  // 改变当前显示数量
  const changePageSize = (size: string | number) => {
    queryParams.pageSize = size
    queryParams.page = 1
    setPageSize(size)
    setNowPage(1)
    changePage(data.length, size)
    let newArr = data.slice((1 - 1) * Number(size), 1 * Number(size))
    setData(newArr)
    getAllList()
  }
  // 改变选中列表
  const changeSelectList = (obj: any) => {
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = []
        data.forEach((item: any) => {
          arr.push(item.id)
        })
        setSelectList(arr)
      } else {
        selectedList.push(obj.id)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([])
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id
        })
        selectedList.splice(idx, 1)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    }
  }
  // 获取列表数据
  const getAllList = async () => {
    let params = {
      pageNum: queryParams.page,
      pageSize: queryParams.pageSize,
      user_id: auth.username,
    }


    let res: any = await billingInfoListGet(params,headers)
    setPage(1)
    changePage(res.total, queryParams.pageSize)
    //let newArr = arr.slice((nowPage - 1) * pageSize, nowPage * pageSize)
    res.rows.forEach((e:any)=> {
      if (e.plan_info) e.plan_info = JSON.parse(e.plan_info)
    })
    setData(res.rows)
  }
  const Style: React.CSSProperties = {
    maxWidth: '80px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

  const [parameters, setParameters] = useState<any>([])
  const getParameter = async () => {
    const res: any = await parameterStoreGet({ names: ['/Define/SubscriptionsPayment/Status'] }, {})
    for (const key in res) {
      // JSON.parseしてエラーだったらそのまま
      try {
        res[key] = JSON.parse(res[key])
      } catch (error) {
        res[key] = res[key]
      }
    }
    console.log('getParameter', res)
    setParameters(res)
  }
  useEffect(() => {
    getParameter()
    getAllList()
  }, [])

  return (
    <div className='card mb-6 shadow-sm'>
      <div className='d-flex align-items-center card-header'>
        <span className='card-title fw-bold m-0'>請求情報</span>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='table-responsive pb-10'>
            <table className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer'>
              <thead>
                <tr className='text-start fs-6 text-uppercase gs-0'>
                  {/* <th style={{minWidth: '60px'}}>ID</th> */}
                  <th style={{minWidth: '70px'}}>発行日</th>
                  <th style={{minWidth: '70px'}}>内容</th>
                  <th style={{minWidth: '80px'}}>請求金額</th>
                  {/* <th style={{minWidth: '70px'}}>請求書</th> */}
                  <th style={{minWidth: '100px'}}>ステータス</th>
                  <th style={{minWidth: '60px'}}>帳票</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        {/* <td>{item.create_at}</td> */}
                        <td>{moment(item.create_at).format('YYYY-MM-DD')}</td>
                        {/* <td>
                          {item.create_at.slice(0, 10) + '   ' + item.create_at.slice(11, 19)}
                        </td> */}
                        <td>{item.plan_info.title}</td>
                        <td>{item.amount}円</td>
                        {/* <td>{item.planName}</td> */}
                        <td>{parameters['/Define/SubscriptionsPayment/Status']?.find((k:any)=> k.value == item.status)?.label}</td>
                        <td>
                          <div className='d-inline-flex w-100 gap-2'>
                            <button className='btn btn-sm btn-light-primary'>
                              請求書
                            </button>
                            <button className='btn btn-sm btn-light-primary'>
                              領収書
                            </button>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={3}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <select
              className='form-select '
              data-control='select2'
              style={{width: 80, position: 'absolute', left: 20, bottom: 10}}
              value={pageSize}
              onChange={(e) => {
                changePageSize(e.target.value)
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            {/* page */}
            <ul className='pagination' style={{position: 'absolute', right: 20, bottom: 10}}>
              <li className={nowPage === page[0] ? class3 : class1}>
                <a
                  href='#'
                  className='page-link'
                  onClick={(e) => {
                    getPageList(nowPage - 1)
                    e.preventDefault()
                  }}
                >
                  <i className='previous'></i>
                </a>
              </li>
              {page.map((item: any, index: number) => {
                return (
                  <li className={nowPage === item ? class2 : class1} key={index}>
                    <a
                      href='#'
                      className='page-link'
                      onClick={(e) => {
                        getPageList(item)
                        e.preventDefault()
                      }}
                    >
                      {item}
                    </a>
                  </li>
                )
              })}
              <li className={nowPage === page[page.length - 1] ? class3 : class1}>
                <a
                  href='#'
                  className='page-link'
                  onClick={(e) => {
                    getPageList(nowPage + 1)
                    e.preventDefault()
                  }}
                >
                  <i className='next'></i>
                </a>
              </li>
            </ul>
            {isLoading && <Loading></Loading>}
          </div>
        </div>
      </div>
    </div>
  )
}
