/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, Fragment} from 'react'
import {useLocation} from 'react-router-dom'
import {PageLink, PageTitle} from '@/_metronic/layout/core'
import {useLayout} from 'src/_metronic/layout/core'
import {accountUsersListGet, accountUserDelete} from 'src/app/modules/api'
import {useNavigate} from 'react-router-dom'
import {useAlert} from 'src/_metronic/layout/core'
import {useIsWidthSize, formatNumber, getParameterStore} from 'src/app/modules/utils'
import moment from 'moment'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'ダッシュボード',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const statusObj: any = {
  unapplied: '未申请',
  pending: '承認待ち',
  non_approval: '非承認',
  approval: '承認済',
}

export default function OrganizationUserDetail() {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>組織ユーザー詳細</PageTitle>
      <Pageconts />
    </>
  )
}

const Pageconts: FC = () => {
  const navigate = useNavigate()
  const {setContentLoading} = useLayout()
  const [data, setData] = useState<any>([])
  const location = useLocation()
  const {setMessage} = useAlert()

  const deleteUser = (id: string) => {
    setContentLoading(true)
    accountUserDelete({id: id})
      .then((res: any) => {
        setMessage({type: 'success', message: 'アカウントを削除しました。'})
        navigate('/account/organization/userlist')
        setContentLoading(false)
      })
      .catch((err: any) => {
        setMessage({type: 'error', message: 'アカウント削除に失敗しました。再度お試しください。'})
        setContentLoading(false)
      })
  }

  useEffect(() => {
    console.log('location.state', location.state)
    if (!location.state) {
      navigate('/account/organization/userlist')
    }

    setContentLoading(true)
    accountUsersListGet({id: location.state}).then((res: any) => {
      setContentLoading(false)
      setData(res.rows[0])
      // setData(res)
    })
  }, [])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>アカウント情報</h3>
          </div>
          <div className='card-toolbar'></div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>メールアドレス</label>

            <div className='col-lg-8'>
              <span className='fs-6 text-dark'>{data.email}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>姓</label>

            <div className='col-lg-8'>
              <span className='fs-6 text-dark'>{data.lastname}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>名</label>

            <div className='col-lg-8'>
              <span className='fs-6 text-dark'>{data.firstname}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>姓（フリガナ）</label>

            <div className='col-lg-8'>
              <span className='fs-6 text-dark'>{data.lastname_kana}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>名（フリガナ）</label>

            <div className='col-lg-8'>
              <span className='fs-6 text-dark'>{data.firstname_kana}</span>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button
            type='submit'
            data-kt-users-modal-action='submit'
            className='btn btn-danger me-4'
            onClick={() => {
              window.confirm('アカウントを削除しますか？') && deleteUser(location.state)
            }}
          >
            アカウント削除
          </button>

          {/* <button type='submit' data-kt-users-modal-action='submit' className='btn btn-primary'>
            {!loading && '保存'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                お待ちください...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button> */}
        </div>
      </div>
    </>
  )
}
