/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {
  receipt, receiptInit
} from 'src/app/modules/api'
import * as Yup from "yup";
import {useFormik} from "formik";
import { showSuccessMessage} from '@/app/components/sweetAlert';
import {message} from "antd";
import { useAuth } from "src/app/modules/auth"

const Receipt: FC = () => {
  useEffect(() => {
    apply()
  },[])
  const [loading, setLoading] = useState<boolean>(false)
  const [pkVal,setPkVal] = useState<string>('')
  const {auth} = useAuth();
    let headers = {
      "Content-Type":"application/json",
      Authorization:`Bearer ${auth.token}`
    }
  const apply = () => {
    receiptInit({},headers).then((res: any) => {
      if(res?.rows.length){
        setPkVal(res.rows[0].pk)
        formik.resetForm({
          values:{
            attension:res.rows[0].attension,
            header_message:res.rows[0].header_message,
            proviso:res.rows[0].proviso,
            title:res.rows[0].title,
          }
        })
      }
    }).catch((err: any) => {
        message.error("初始化失敗")
    })
  }
  const validationSchema = Yup.object({
    header_message: Yup.string()
        .required('ヘッダーの挨拶文は必須です。')
        .min(1, '1文字以上で入力してください。')
        .max(255, '255文字以下で入力してください。'),
    proviso: Yup.string()
        .required('但し書きは必須です。')
        .min(1, '1文字以上で入力してください。')
        .max(255, '255文字以下で入力してください。'),
    title: Yup.string()
        .required('書類タイトルは必須です。')
        .min(1, '1文字以上で入力してください。')
        .max(255, '255文字以下で入力してください。'),
  })
  const formik:any = useFormik<any>({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      /**
       * 領収書 宛先
       * 1        会社名（御中）    （默认）
       * 2        会社名＋部署名（御中）
       * 3        会社名＋部署名＋担当者名（様）
       */
      attension: '1',
      /**
       * 領収書 ヘッダーの挨拶文
       */
      header_message: '',
      /**
       * 領収書 但し書き
       */
      proviso: '',
      /**
       * 領収書 書類タイトル
       */
      title: ''
    },
    validationSchema: validationSchema,
    onSubmit:async (values) => {
      receipt({...values,pkVal}, {headers}).then((res: any) => {
        setLoading(false)
        apply()
        showSuccessMessage(res.message)
      }) .catch((err: any) => {
        setLoading(false)
      })
    },
  });

  const  clearForm = ()=> {
    formik.resetForm({
      values:{
        attension: '1',
        header_message: '',
        proviso: '',
        title:''
      }
    })
  }

  return (
    <div id='receipt' className='card tab-pane'>
      <form onSubmit={formik.handleSubmit}>
        <div className='card-body p-9'>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3  required'>書類タイトル</div>
            </div>
            <div className='col-lg-8'>
              <input
                type='text'
                className='form-control form-control-sm'
                placeholder='領収書'
                {...formik.getFieldProps('title')}
              />
              {formik.errors.title && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.title}</div>
                  </div>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3'>宛先</div>
            </div>
            <div className='col-lg-8'>
              <select
                className='form-select fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='role'
                data-hide-search='true'
                {...formik.getFieldProps('attension')}
              >
                <option value='1'>会社名(御中)</option>
                <option value='2'>会社名＋部署名(御中)</option>
                <option value='3'>会社名＋部署名＋担当者名(様)</option>
              </select>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3  required'>ヘッダーの挨拶文</div>
            </div>
            <div className='col-lg-8'>
              <input
                type='text'
                placeholder='ヘッダーの挨拶文'
                className='form-control form-control-sm'
                {...formik.getFieldProps('header_message')}
              />
              {formik.errors.header_message && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.header_message}</div>
                  </div>
              )}
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-xl-3'>
              <div className='fs-6 fw-semibold mt-2 mb-3  required'>但し書き</div>
            </div>
            <div className='col-lg-8'>
              <input
                type='text'
                placeholder='但し書き'
                className='form-control form-control-sm'
                {...formik.getFieldProps('proviso')}
              />
              {formik.errors.proviso && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.proviso}</div>
                  </div>
              )}
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button className='btn btn-lightbtn-active-light-primary me-2' onClick={clearForm}>キャンセル</button>
          <button type='submit' disabled={loading}  className='btn btn-primary' data-kt-indicator={loading?'on':'off'}>
               <span className="indicator-label">
                 保存
              </span>
            <span className="indicator-progress"><span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
          </button>
        </div>
      </form>
    </div>
  )
}
export {Receipt}
