import { getServer, postServer, deleteServer } from 'src/service/http'

// 获取列表
export function getCreditInfo(json: object) {
    return getServer('/credit-info-get', json);
  }
  // 加盟添加卡
  export function creditInfoRegister(url:string, json: object) {
    // let url =  auth.role.includes('buyer' ? '/credit-info-register' : '/credit-info-add');
    return postServer('/credit-info-add', json);
  }
  // 加盟删除卡
  export function creditInfoDelete(json: object) {
    return deleteServer('/credit-info-delete', json);
  }
  
  // 获取银行卡列表数据
export function getCardList() {
    return postServer('/user-card-information-list', {});
  }

// 获取列表
export function getInformationList(json: object) {
  return postServer('/user-card-information-list', json);
}