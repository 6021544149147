/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from 'src/app/modules/auth'
import {specialCommerceLawInfoGet} from 'src/app/modules/api'
import {Spin} from 'antd'
export function BusinessLaw({pk}: any) {
  const {auth} = useAuth()
  const [userdata, setUserdata] = useState<any>({
    store_status: '',
    registration_date: '',
    affiliate_id: '',
    referrer: '',
    ecompany_name: '',
    corporation_or_individual: '',
    shop_name: '',
    shop_name_kana: '',
    representative_name: '',
    establishment_date: '',
    fiscal_year_end: '',
  }) // 表单数据

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }
  useEffect(() => {
    initData()
  }, [])
  const [loading, setLoading] = useState<any>(false)
  // 获取列表数据
  const initData = () => {
    setLoading(true)

    specialCommerceLawInfoGet({}, headers)
      .then((res: any) => {
        console.log('res', res)
        if (res && res.rows.length > 0) {
          setUserdata(res.rows[0])
          setLoading(false)
        } else {
          setLoading(false)
        }
      })
      .catch((_) => {
        setLoading(false)
      })
  }

  return (
    <>
      <Spin spinning={loading} tip='Processing' size='large'>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>お取引に関する同意事項</h3>
            </div>
            <div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
              <Link to='/account/law/edit' className='btn btn-primary align-self-center'>
                編集
              </Link>
            </div>
          </div>
          <div className='card-body p-9'>
            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>お取引に関して</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.transaction_details &&
                    userdata?.transaction_details.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='card mb-5 mb-xl-10'>
          <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>特定商取引法に基づく記載</h3>
            </div>
            <div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
              <Link to='/account/law/edit' className='btn btn-primary align-self-center'>
                編集
              </Link>
            </div>
          </div>

          <div className='card-body p-9'>
            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>販売業者</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.seller &&
                    userdata?.seller.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>代表責任者</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.representative &&
                    userdata?.representative.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>所在地</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.address &&
                    userdata?.address.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>電話番号</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.phone_number &&
                    userdata?.phone_number.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>電話受付時間</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.phone_hour &&
                    userdata?.phone_hour.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>メールアドレス</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.email &&
                    userdata?.email.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>サイトURL</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.site_url &&
                    userdata?.site_url.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>販売価格</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.price &&
                    userdata?.price.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>商品代金以外の料金</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.additional_charges &&
                    userdata?.additional_charges.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>引き渡し時期</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.delivery_time &&
                    userdata?.delivery_time.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>お支払方法</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.payment_method &&
                    userdata?.payment_method.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>お支払方法時期</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.payment_time &&
                    userdata?.payment_time.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>ご注文方法</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.order_method &&
                    userdata?.order_method.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>返品・交換・キャンセルについて</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.defective_products &&
                    userdata?.defective_products.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>返品期限</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.return_deadline &&
                    userdata?.return_deadline.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>
            <div className='row mb-7'>
              <label className='col-lg-4 fw-bold text-dark'>返品送料</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.return_shipping_fee &&
                    userdata?.return_shipping_fee.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>申込有効期限</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.application_deadline &&
                    userdata?.application_deadline
                      .split('\n')
                      .map((line: string, index: number) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                </span>
              </div>
            </div>

            <div className='row mb-7 pb-7 border-bottom border-gray-200'>
              <label className='col-lg-4 fw-bold text-dark'>販売数量</label>

              <div className='col-lg-8'>
                <span className=''>
                  {userdata?.sales_quantity &&
                    userdata?.sales_quantity.split('\n').map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  )
}
