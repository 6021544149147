import React, {useEffect, useState} from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '@/_metronic/layout/core'
import {AccountInfo} from '../../common/account/AccountInfo'
import OrganizationUserList from '../../common/account/OrganizationUserList'
import OrganizationUserDetail from '../../common/account/OrganizationUserDetail'
import OrganizationUserInvitation from '../../common/account/OrganizationUserInvitation'
import {BusinessLaw} from './components/settings/BusinessLaw'
import {BusinessLawSet} from './components/settings/BusinessLawSet'
import {AccountUserInfo} from 'src/app/pages/common/account/AccountUserInfo'
import {AccountInfoSetting} from '../../common/account/AccountInfoSetting'
import PaymentManagement from './components/settings/PaymentManagement'
import PaymentProvider from './components/settings/PaymentProvider'
import DataTransferIndex from './components/settings/DataTransferIndex'
import {PaymentLogin} from './components/settings/PaymentLogin'
import Header from '@/app/pages/common/account/Header'
import {accountInfoGet} from 'src/app/modules/api'
import {useAuth} from 'src/app/modules/auth'
import {HelmetComponent} from 'src/app/modules/helmet'
import {useAccount} from 'src/app/modules/accounts/AccountProvider'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'アカウント',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

// const auth = getAuth();
// async function getUser() {
//   const user = await accountById(auth?.data.login_id) as any;
//   return user.data
// }

export default function SupplierAccountRoute() {
  const {auth} = useAuth()
  const {account} = useAccount()
  const [resData, setResData] = useState<any>({})

  useEffect(() => {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token}`,
    }
    accountInfoGet('auth.username', headers).then((res: any) => {
      setResData(res)
    })

    console.log('account.role', account.role)
  }, [])

  return (
    <Routes>
      <Route>
        <Route
          path='/account'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='アカウント情報' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>アカウント情報</PageTitle>
              <AccountUserInfo type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
            </>
          }
        />
        <Route
          path='/account/edit'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='アカウント情報' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>アカウント情報</PageTitle>
              <AccountUserInfo type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
            </>
          }
        />
        <Route
          path='/organization'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='組織情報' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>組織情報</PageTitle>
              <AccountInfo type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
            </>
          }
        />
        <Route
          path='/organization/userlist'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='組織ユーザー一覧' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>組織ユーザー一覧</PageTitle>
              <OrganizationUserList />
            </>
          }
        />
        <Route
          path='/organization/userlist/detail'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='組織ユーザー詳細' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>組織ユーザー詳細</PageTitle>
              <OrganizationUserDetail />
            </>
          }
        />
        <Route
          path='/organization/invitation'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='新規ユーザー追加' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>新規ユーザー追加</PageTitle>
              <OrganizationUserInvitation />
            </>
          }
        />
        <Route
          path='/organization/edit'
          element={
            <>
              <HelmetComponent title='組織情報設定' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>組織情報設定</PageTitle>
              <AccountInfoSetting />
            </>
          }
        />
        <Route
          path='/law'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='特定商取引法に基づく記載' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>特定商取引法に基づく記載</PageTitle>
              <BusinessLaw pk={resData.pk} />
            </>
          }
        />
        <Route
          path='/law/edit'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='特定商取引法に基づく記載設定' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>特定商取引法に基づく記載設定</PageTitle>
              <BusinessLawSet pk={resData.pk} />
            </>
          }
        />
        <Route
          path='/payment'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='利用料お支払管理' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>利用料お支払管理</PageTitle>
              <PaymentManagement />
            </>
          }
        />
        <Route
          path='/paymentprovider'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='決済連携設定' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>決済連携設定</PageTitle>
              <PaymentProvider />
            </>
          }
        />
        <Route
          path='/general'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='その他共通設定' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>その他共通設定</PageTitle>
              <PaymentLogin resData={resData} />
            </>
          }
        />
        <Route
          path='/datatransfer'
          element={
            <>
              <Header type={account.role.includes('supplier') ? 'supplier' : 'buyer'} />
              <HelmetComponent title='自動連携設定' options={{}} />
              <PageTitle breadcrumbs={accountBreadCrumbs}>自動連携設定</PageTitle>
              <DataTransferIndex />
            </>
          }
        />
        <Route index element={<Navigate to='/account/account' />} />
      </Route>
    </Routes>
  )
}
