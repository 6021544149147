import { getServer, postServer, deleteServer } from 'src/service/http'

export function buyerProductDataListGet(param: object,headers:object = {}) {
    return getServer(`/buyer-product-data-list-get`, { ...param },{...headers})
}

// 商品仕様
export function buyerProductVariationGet(param: object, headers: object = {}) {
    return getServer(`/buyer-product-variation-get`, { ...param }, { ...headers })
}

// 商品の詳細
export function buyerProductDataGet(param: object) {
    return getServer(`/buyer-product-data-get`, { ...param })
}

