const roleGroupFilter = (role_group: any, list: any) => {
  const newList = JSON.parse(JSON.stringify(list))
  let arr = newList.filter((item:any) => {
    item.roles = item && item?.roles && JSON.parse(item?.roles);
    return role_group.includes(item.role_group_id)
  })
  // console.log('arr',arr);
  let obj = JSON.parse(JSON.stringify(arr[0].roles)) as any;
  arr.reverse().forEach((item:any,idx:number)=>{
    for (let i in item.roles) {
      if(obj[i] === undefined){
        obj[i] = [];
      }
      obj[i] = obj[i].concat(item.roles[i].filter((v:any) => !obj[i].includes(v)));
    }
  })
  return obj;
}

const filterTree = (items:any)=> {
  const rootItems = items.filter((item:any) => !item.parent);
  const childrenOfItem = new Map();

  for (const item of items) {
      const parentId = item.parent;
      if (!childrenOfItem.has(parentId)) {
          childrenOfItem.set(parentId, []);
      }
      childrenOfItem.get(parentId).push(item);
  }

  function addChildren(items:any) {
      for (const item of items) {
          item.children = childrenOfItem.get(item.pk) || [];
          addChildren(item.children);
      }
  }

  addChildren(rootItems);
  return rootItems;
}

export {
  roleGroupFilter,
  filterTree
}