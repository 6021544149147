/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useLocation, useParams, useSearchParams, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {showSuccessMessage, showErrorMessage} from 'src/app/components/sweetAlert'
import {useAuth} from 'src/app/modules/auth'
import {getCommonDetails} from 'src/app/modules/api'
import {customerRankListGet} from 'src/app/modules/api'
import { customerDataEdit, customerAdd } from 'src/app/modules/api'

const validationSchema = Yup.object().shape({
  // customer_id: Yup.string().required('顧客ID必須です。'),
  company_name: Yup.string().required('会社名は必須です。'),
  representative: Yup.string().required('代表者名は必須です。'),
  establishment_date: Yup.string().required('設立年月は必須です。'),
  // registration_date: Yup.string().required('登録年月日必須です。'),
  telephone_number: Yup.string().required('電話番号は必須です。'),
  main_email_address: Yup.string().required('メールアドレスは必須です。'),
  mobile_phone_number: Yup.string().required('携帯番号は必須です。'),
  fax_number: Yup.string().required('FAX番号は必須です。'),
  actual_industry: Yup.string().required('実態/業種は必須です。'),
  payment_method: Yup.string().required('決済方法は必須です。'),
  contact_person: Yup.string().required('担当者は必須です。'),
  website_url: Yup.string().required('ホームページURLは必須です。'),
  physical_store: Yup.string().required('実店舗は必須です。'),
  email_notifications: Yup.string().required('メール案内は必須です。'),
})
export function CustomerEdit({customeItem, customerOptions}: any) {
  const navigate = useNavigate()
  const {id} = useParams()
  const currentLocation = useLocation()
  const [getSearchArr] = useSearchParams()
  const searchValue = getSearchArr.getAll('copy')[0]
  const {auth} = useAuth()
  // const [customerOptions, setCustomerOptions] = useState<any>([])
  const [userdata, setUserdata] = useState<any>({
    // store_status: '公開',
    // registration_date: '2023/10',
    user_id: auth.username,
    customer_id: '',
    company_name: '',
    representative: '',
    department_name: '',
    contact_person: '',
    main_email_address: '',
    address_postal_code: '',
    address_prefecture: '',
    address_city: '',
    address_street: '',
    address_building_name: '',
    telephone_number: '',
    mobile_phone_number: '',
    fax_number: '',
    group_id: '',
    payment_method: '',
    establishment_date: '',
    actual_industry: '',
    website_url: '',
    physical_store: '',
    email_notifications: '',
  }) // 表单数据

  const [loading, setLoading] = useState(false)

  const updateData = (fieldsToUpdate: Partial<any>) => {
    const updatedData = {...formik.values, ...fieldsToUpdate}
    formik.setValues(updatedData)
  }

  useEffect(() => {
    initData()
  }, [customeItem])

  // 获取列表数据
  const initData = () => {
    console.log('edit',id, customeItem)
    if (customeItem && customeItem.customer_id) {
      updateData({...userdata, ...customeItem, pkVal: customeItem.pk})
    } else if (customeItem && customeItem.group_id) {
      updateData({
        ...userdata,
        group_id:
          customerOptions.find((e: any) => e.default == 'yes')?.group_id ||
          customerOptions[0].group_id,
      })
    }
  }

  const formik: any = useFormik<any>({
    initialValues: userdata as any,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('values', values)
      setSubmitting(true)
      setLoading(true)
      if (id) {
        customerDataEdit(values)
          .then((res: any) => {
            setLoading(false)
            showSuccessMessage(res.message)
            navigate(`/customer`)
          })
          .catch((err: any) => {
            setLoading(false)
          })
      } else {
        customerAdd(values)
          .then((res: any) => {
            setLoading(false)
            showSuccessMessage(res.message)
            navigate(`/customer`)
          })
          .catch((err: any) => {
            setLoading(false)
          })
      }
    },
  })

  console.log('formik', formik)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>顧客情報設定</h3>
        </div>
      </div>

      <form
        id='kt_modal_add_user_form'
        className='container-fluid'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='card-body'>
          {/* <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-dark required'>登録年月日</label>
                        <div className='col-lg-8 fv-row'>
                            <input className='form-control form-control-lg' type="text" placeholder="登録年月日"
                                disabled
                                {...formik.getFieldProps('registration_date')}
                            />
                            {formik.touched.registration_date && formik.errors.registration_date && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.registration_date}</div>
                                </div>
                            )}
                        </div>
                    </div> */}
          {/* <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-dark'>紹介者</label>
                        <div className='col-lg-8 fv-row'>
                            <span className='fw-bold fs-6'>株式会社◎◎</span>
                        </div>
                    </div> */}
          {/* <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-dark'>顧客ID</label>
                        <div className='col-lg-8 fv-row'>
                            <input className='form-control form-control-lg'
                                disabled
                                type="text" placeholder="顧客ID"
                                {...formik.getFieldProps('customer_id')}
                            />
                            {formik.errors.customer_id && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.customer_id}</div>
                                </div>
                            )}
                        </div>
                    </div> */}
          {/* <div className='row mb-7'>
                        <label className='col-lg-4 fw-bold text-dark'>貴社独自顧客ID</label>
                        <div className='col-lg-8 fv-row'>
                            <span className='fw-bold fs-6'>ABC5678</span>
                        </div>
                    </div> */}
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>会社名</label>
            <div className='col-lg-8 fv-row'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='会社名'
                {...formik.getFieldProps('company_name')}
              />
              {formik.touched.company_name && formik.errors.company_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.company_name}</div>
                </div>
              )}
              {/* <span className='fw-bold fs-6'>{userdata?.company_name}</span> */}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-dark'>所在地 郵便番号</label>
            <div className='col-lg-8 fv-row'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='所在地 郵便番号'
                {...formik.getFieldProps('address_postal_code')}
              />
              {formik.touched.address_postal_code && formik.errors.address_postal_code && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address_postal_code}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-dark'>所在地 都道府県</label>
            <div className='col-lg-8 fv-row'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='所在地 都道府県'
                {...formik.getFieldProps('address_prefecture')}
              />
              {formik.touched.address_prefecture && formik.errors.address_prefecture && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address_prefecture}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-dark'>所在地 市区町村</label>
            <div className='col-lg-8 fv-row'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='所在地 市区町村'
                {...formik.getFieldProps('address_city')}
              />
              {formik.touched.address_city && formik.errors.address_city && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address_city}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-dark'>所在地 丁目番地</label>
            <div className='col-lg-8 fv-row'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='所在地 丁目番地'
                {...formik.getFieldProps('address_street')}
              />
              {formik.touched.address_street && formik.errors.address_street && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address_street}</div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 fw-bold text-dark'>所在地 建物名</label>
            <div className='col-lg-8 fv-row'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='所在地 建物名'
                {...formik.getFieldProps('address_building_name')}
              />
              {formik.touched.address_building_name && formik.errors.address_building_name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address_building_name}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>代表者</label>
            <div className='col-lg-8 fv-row'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='代表者'
                {...formik.getFieldProps('representative')}
              />
              {formik.touched.representative && formik.errors.representative && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.representative}</div>
                </div>
              )}
              {/* <span className='fw-bold fs-6'>{userdata?.representative}</span> */}
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>部署名</label>
            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                <input
                  className='form-control form-control-lg'
                  type='text'
                  placeholder='部署名'
                  {...formik.getFieldProps('department_name')}
                />
                {formik.touched.department_name && formik.errors.department_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.department_name}</div>
                  </div>
                )}
              </span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>担当者</label>
            <div className='col-lg-8 fv-row'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='担当者'
                {...formik.getFieldProps('contact_person')}
              />
              {formik.touched.contact_person && formik.errors.contact_person && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.contact_person}</div>
                </div>
              )}
              {/* <span className='fw-bold fs-6'>{userdata?.contact_person}</span> */}
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>担当者 フリガナ</label>
            <div className='col-lg-8 fv-row'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='担当者'
                {...formik.getFieldProps('contact_person_kana')}
              />
              {formik.touched.contact_person_kana && formik.errors.contact_person_kana && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.contact_person_kana}</div>
                </div>
              )}
              {/* <span className='fw-bold fs-6'>{userdata?.contact_person}</span> */}
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>メールアドレス</label>
            <div className='col-lg-8 fv-row'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='メールアドレス'
                {...formik.getFieldProps('main_email_address')}
              />
              {formik.touched.main_email_address && formik.errors.main_email_address && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.main_email_address}</div>
                </div>
              )}
              {/* <a href='#' className='fw-bold fs-6'>{userdata?.main_email_address}</a> */}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>電話番号</label>
            <div className='col-lg-8'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='電話番号'
                {...formik.getFieldProps('telephone_number')}
              />
              {formik.touched.telephone_number && formik.errors.telephone_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.telephone_number}</div>
                </div>
              )}
              {/* <a href='tel:0454786870' className='fw-bold fs-6 text-dark text-hover-primary'>{userdata?.telephone_number}</a> */}
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>携帯番号</label>
            <div className='col-lg-8'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='携帯番号'
                {...formik.getFieldProps('mobile_phone_number')}
              />
              {formik.touched.mobile_phone_number && formik.errors.mobile_phone_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.mobile_phone_number}</div>
                </div>
              )}
              {/* <span className='fw-bolder fs-6 text-dark'>{userdata?.mobile_phone_number}</span> */}
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>FAX番号</label>
            <div className='col-lg-8'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='FAX番号'
                {...formik.getFieldProps('fax_number')}
              />
              {formik.touched.fax_number && formik.errors.fax_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.fax_number}</div>
                </div>
              )}
              {/* <span className='fw-bolder fs-6 text-dark'>{userdata?.fax_number}</span> */}
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-dark'>顧客ランク</label>
            <div className='col-lg-8'>
              <select
                className='form-select'
                data-control='select2'
                disabled
                {...formik.getFieldProps('group_id')}
              >
                {customerOptions.map((option: any) => (
                  <option key={option.pk} value={option.group_id}>
                    {option.group_name}
                  </option>
                ))}
              </select>
              {formik.touched.group_id && formik.errors.group_id && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.group_id}</div>
                </div>
              )}
            </div>
          </div>
          {/* <div className='row mb-10'>
                        <label className='col-lg-4 fw-bold text-dark'>表示グループ</label>
                        <div className='col-lg-8'>
                            <span className='fw-bold fs-6'></span>
                        </div>
                    </div> */}
          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-dark'>決済方法</label>
            <div className='col-lg-8'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='決済方法'
                {...formik.getFieldProps('payment_method')}
              />
              {formik.touched.payment_method && formik.errors.payment_method && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.payment_method}</div>
                </div>
              )}
              {/* <span className='fw-bold fs-6'>クレジット{userdata?.payment_method}</span> */}
            </div>
          </div>
          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-dark'>設立年月</label>
            <div className='col-lg-8'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='設立年月'
                {...formik.getFieldProps('establishment_date')}
              />
              {formik.touched.establishment_date && formik.errors.establishment_date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.establishment_date}</div>
                </div>
              )}
              {/* <span className='fw-bold fs-6'>{userdata?.establishment_date}</span> */}
            </div>
          </div>
          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-dark'>実態/業種</label>
            <div className='col-lg-8'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='実態/業種'
                {...formik.getFieldProps('actual_industry')}
              />
              {formik.touched.actual_industry && formik.errors.actual_industry && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.actual_industry}</div>
                </div>
              )}
              {/* <span className='fw-bold fs-6'>{userdata?.actual_industry}</span> */}
            </div>
          </div>
          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-dark'>ホームページURL</label>
            <div className='col-lg-8'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='ホームページURL'
                {...formik.getFieldProps('website_url')}
              />
              {formik.touched.website_url && formik.errors.website_url && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.website_url}</div>
                </div>
              )}
              {/* <a href='#' className='fw-bold fs-6'>{userdata?.website_url}</a> */}
            </div>
          </div>
          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-dark'>実店舗</label>
            <div className='col-lg-8'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='実店舗'
                {...formik.getFieldProps('physical_store')}
              />
              {formik.touched.physical_store && formik.errors.physical_store && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.physical_store}</div>
                </div>
              )}
              {/* <span className='fw-bold fs-6'>{userdata?.physical_store}</span> */}
            </div>
          </div>
          <div className='row mb-10'>
            <label className='col-lg-4 fw-bold text-dark'>メール案内</label>
            <div className='col-lg-8'>
              <input
                className='form-control form-control-lg'
                type='text'
                placeholder='メール案内'
                {...formik.getFieldProps('email_notifications')}
              />
              {formik.touched.email_notifications && formik.errors.email_notifications && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email_notifications}</div>
                </div>
              )}
              {/* <span className='fw-bold fs-6'>{userdata?.email_notifications}</span> */}
            </div>
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' data-kt-users-modal-action='submit' className='btn btn-primary'>
            {!loading && '保存'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                お待ちください...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
