import { getServer, postServer, deleteServer } from 'src/service/http'

// 顧客追加
export function customerAdd(param: object) {
    return postServer(`/customer-add`, { ...param })
}

// 顧客検索
export function customerSearch(param: object,headers: object={}) {
    return getServer(`/customer-search`, { ...param},{...headers})
}

// 顧客データ削除
export function customerDataDelete(param: object, headers: object = {}) {
    return deleteServer(`/customer-data-delete`, { ...param }, { ...headers })
}

// 顧客データ一括削除
export function customerDataBulkDelete(param: object, headers: object = {}) {
    return deleteServer(`/customer-data-bulk-delete`, { ...param }, { ...headers })
}

// 顧客データダウンロード
export function customerDataDownload(param: object, headers: object = {}) {
    return getServer(`/customer-data-download`, { ...param },{...headers})
}

// 顧客データ編集
export function customerDataEdit(param: object) {
    return postServer(`/customer-data-edit`, { ...param })
}

// 顧客ステータス更新
export function customerStatusUpdate(param: object, headers: object = {}) {
    return postServer(`/customer-status-update`, { ...param },{...headers})
}

// 顧客ランク更新
export function customerUserStatusUpdate(param: object, headers: object = {}) {
    return postServer(`/customer-user-rank-update`, { ...param },{...headers})
}