/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, Fragment, useRef} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from 'src/_metronic/layout/core'
import {ChartsWidgetMonthly, ChartsWidgetDaily} from './components'
import {useNavigate} from 'react-router-dom'
// import { use } from 'echarts'
import {supplierSalesReport, supplierOrderReport, SystemNotificationGet} from 'src/app/modules/api'
import moment from 'moment'
import Chart from 'react-apexcharts'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {Spin} from 'antd'
import {formatNumber} from '@/app/modules/utils'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'ホーム',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Pageconts: FC = () => {
  const currentMonth = moment().format('YYYY年M月')
  const currentToday = moment().format('YYYY年M月D日')
  const currentTime = moment().format('HH時m分')
  const [initReportData, setInitReportData] = useState<boolean>(false)
  const [reportData, setReportData] = useState<any>({})
  const [initOrderReportData, setInitOrderReportData] = useState<boolean>(false)
  const [orderReportData, setOrderReportData] = useState<any>({})

  // let monthlyTotal: number = 1000000000
  // let dailyTotal: number = 1000000000

  useEffect(() => {
    supplierSalesReport({}).then((res: any) => {
      setReportData(res.data)
    })

    supplierOrderReport({}).then((res: any) => {
      setOrderReportData(res.data)
    })
  }, [])

  useEffect(() => {
    if (reportData && Object.keys(reportData).length > 0) {
      setInitReportData(true)
    }
  }, [reportData])

  useEffect(() => {
    if (orderReportData && Object.keys(orderReportData).length > 0) {
      setInitOrderReportData(true)
    }
  }, [orderReportData])

  return (
    <>
      <div
        className='notice d-flex rounded p-6 col-xl-12 mb-6'
        style={{backgroundColor: '#F9F7FF'}}
      >
        <i className='ki-duotone ki-information-5 fs-2tx me-4' style={{color: '#A466EA'}}>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
        </i>
        <div className='d-flex flex-stack flex-grow-1 '>
          <div className=' fw-semibold'>
            <div className='fw-bold fs-4'>【重要】システムメンテナンスのお知らせ</div>
          </div>
        </div>
      </div>

      <div className='row mb-6'>
        <div className='col-12 col-lg-8'>
          <div className='row mb-6'>
            <div className='col-12'>
              <NavigateMenu />
            </div>
          </div>
          <div className='row mb-6'>
            <div className='col-12'>
              <div className='card'>
                <div className='card-header'>
                  <div className='card-title'>
                    <span className='fw-bold'>売上レポート</span>
                  </div>
                </div>
                <div className='card-body d-flex justify-content-between flex-column pb-1 px-0'>
                  <div className='row'>
                    <div className='col-12'>
                      <Spin spinning={!initReportData}>
                        {<SalesChart salesData={reportData} />}
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-6'>
            <div className='col-12'>
              <Spin spinning={!initOrderReportData}>
                {<OrderReport reportData={orderReportData} />}
              </Spin>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-4'>
          <StoreNotificationsTable />
          <SystemNotifications />
        </div>
      </div>
    </>
  )
}

const DashboardSupplier: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>ダッシュボード</PageTitle>
      <Pageconts />
    </>
  )
}
export {DashboardSupplier}

const StoreNotificationsTable: FC = () => {
  type NotificationCategory = 'order_created' | 'customer' | 'all'

  type Notification = {
    create_at: string
    title: string
    message: string
    type: string
    meta: any
  } | null

  const navigate = useNavigate()
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [initData, setInitData] = useState<boolean>(false)
  const [category, setCategory] = useState<NotificationCategory>('all')

  const handleCategoryChange = (e: NotificationCategory) => {
    // console.log(e.target.value)
    setCategory(e)
  }

  useEffect(() => {
    SystemNotificationGet().then((res: any) => {
      setNotifications(res.data.notifications)
      setInitData(true)
    })
  }, [])

  return (
    <>
      <Spin spinning={!initData}>
        <div className='card mb-6'>
          <div className='card-header'>
            <div className='card-title'>
              <span className='fw-bold'>注文・仕入に関する連絡事項</span>
            </div>
            <div className='card-toolbar'>
              {/* <div className="d-flex flex-stack flex-wrap gap-4">
            <div className="d-flex align-items-center fw-bold">
              <div className='fs-7 me-2'>カテゴリ</div>
              <select name="" id="" className='form-select form-select-transparent text-graY-800 fs-base lh-1 fw-bold py-0 ps-3 w-auto' data-control='select2' data-hide-search='true' data-dropdown-css-class='w-150px' data-placeholder='カテゴリーを選択'>
                <option></option>
                <option value="all" selected>全て</option>
                <option value="order">受注に関する連絡</option>
                <option value="customer">取引先からの連絡</option>
              </select>
            </div>
          </div> */}
            </div>
          </div>
          <div className='card-body'>
            <div className='table-responsive'>
              <div className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold mb-4 d-none'>
                <div className='nav-item mt-2 flex-grow-1'>
                  <a
                    href='#'
                    className={`nav-link justify-content-center w-100 text-active-primary ms-0 py-2 ${
                      category === 'all' ? 'active' : ''
                    }`}
                    onClick={(e) => {
                      e.preventDefault()
                      handleCategoryChange('all')
                    }}
                  >
                    未読のお知らせ
                  </a>
                </div>
                <div className='nav-item mt-2 flex-grow-1'>
                  <a
                    href='#'
                    className={`nav-link justify-content-center w-100 text-active-primary ms-0 py-2 ${
                      category === 'order_created' ? 'active' : ''
                    }`}
                    onClick={(e) => {
                      e.preventDefault()
                      handleCategoryChange('order_created')
                    }}
                  >
                    全てのお知らせ
                  </a>
                </div>
                {/* <div className='nav-item mt-2'>
                <a
                  href='#'
                  className={`nav-link text-active-primary ms-0 py-5 ${
                    category === 'customer' ? 'active' : ''
                  }`}
                  onClick={(e) => {
                    e.preventDefault()
                    handleCategoryChange('customer')
                  }}
                  style={{color: 'gray', pointerEvents: 'none'}}
                >
                  取引先からの連絡
                </a>
              </div> */}
              </div>

              <div className=''>
                {notifications.length > 0 &&
                  notifications.map((notification, index) => (
                    <div className='card mb-3' key={index}>
                      <div className='card-body py-4'>
                        <div className='d-flex gap-4 mb-2'>
                          <div className='flex-shrink-0'>
                            {moment(notification?.create_at).format('YYYY年MM月DD日 HH:mm')}
                          </div>
                          <div className='flex-grow-1'>
                            {notification?.type === 'order_created' && (
                              <span className='badge badge-light-primary me-2'>
                                注文に関する連絡
                              </span>
                            )}
                            {notification?.type === 'customer' && (
                              <span className='badge badge-light-info me-2'>
                                {notification?.title}
                              </span>
                            )}
                          </div>
                        </div>
                        <p className='card-text'>
                          <a
                            href='#'
                            onClick={(e) => {
                              e.preventDefault()

                              let url = ''

                              // typeに応じてリンク差変更
                              if (notification?.type === 'order_created') {
                                url = '/order/' + notification?.meta.orderId.split('#').pop()
                              } else {
                                // navigate('/')
                              }

                              navigate(url)
                            }}
                          >
                            {notification?.title}
                          </a>
                        </p>
                      </div>
                    </div>
                  ))}
                {notifications.length === 0 && (
                  <div className='card mb-3'>
                    <div className='card-body py-4'>
                      <div className='d-flex gap-4'>
                        <div className='flex-grow-1'>連絡事項はありません</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </>
  )
}

type SystemNotification = {
  create_at: string
  title: string
  discription: string
  content: string
  type: string
} | null

// props として受け取る型の定義
type SystemNotificationListProps = {
  notifications: SystemNotification[]
}

const SystemNotifications: FC = () => {
  const [notifications, setNotifications] = useState<SystemNotification[]>([])

  useEffect(() => {
    setNotifications([
      {
        create_at: '2024年7月1日',
        title: 'HatChuPayβ版をリリースしました',
        discription: '',
        content: ``,
        type: 'news',
      },
    ])
  }, [])

  return (
    <>
      <div className='card mb-10'>
        <div className='card-header'>
          <div className='card-title align-items-start flex-column'>
            <span className='fw-bold'>システムからのお知らせ</span>
          </div>
        </div>
        <div className='card-body'>
          {notifications.length > 0 && (
            <>
              <SystemNotificationList notifications={notifications} />
              <SystemNotificationModal notifications={notifications} />
            </>
          )}
        </div>
      </div>
    </>
  )
}

const SystemNotificationList: FC<SystemNotificationListProps> = (params) => {
  return (
    <div className=''>
      {params?.notifications?.map((notification, index) => (
        <div className='card mb-3' key={index}>
          <div className='card-body py-4'>
            <div className='d-flex gap-4 mb-2'>
              <div className='flex-shrink-0'>{notification?.create_at}</div>
              <div className='flex-grow-1'>
                {notification?.type === 'order' && (
                  <span className='badge badge-light-primary me-2'>{notification?.title}</span>
                )}
                {notification?.type === 'customer' && (
                  <span className='badge badge-light-info me-2'>{notification?.title}</span>
                )}
              </div>
            </div>
            <p className='card-text'>{notification?.title}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

const SystemNotificationModal: FC<SystemNotificationListProps> = (params) => {
  return (
    <>
      {params?.notifications &&
        params?.notifications.map((notification, index) => (
          <div
            className='modal fade'
            role='dialog'
            tabIndex={-3}
            id={`modal-${index}`}
            key={`modal-${index}`}
          >
            <div
              className='modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered'
              role='document'
            >
              <div className='modal-content'>
                <div className='modal-body fs-4'>
                  <div className='mb-3'>{notification?.title}</div>
                  <div className='mb-3'>
                    <span
                      className='badge me-2'
                      style={{color: '#fff', backgroundColor: '#70AD47'}}
                    >
                      バージョンアップ
                    </span>
                    {notification?.create_at}
                  </div>
                  <div className='mb-2'>{notification?.content}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  )
}

const OrderReport: React.FC<{reportData: any}> = ({reportData}) => {
  const [unReadOrders, setUnReadOrders] = useState<number>(0)
  const [processingOrders, setProcessingOrders] = useState<number>(0)
  const [laterPaymentTotal, setAfterPayTotal] = useState<number>(0)
  const [bankTransferTotal, setBankTransferTotal] = useState<number>(0)

  useEffect(() => {
    if (Object.keys(reportData).length !== 0) {
      if (reportData.unReadOrders) {
        setUnReadOrders(reportData.unReadOrders)
      }
      if (reportData.processingOrders) {
        setProcessingOrders(reportData.processingOrders)
      }
      if (reportData.laterPaymentTotal) {
        setAfterPayTotal(reportData.laterPaymentTotal)
      }
      if (reportData.bankTransferTotal) {
        setBankTransferTotal(reportData.bankTransferTotal)
      }
    }
  }, [reportData])

  return (
    <>
      <div className='d-flex flex-column rom-sm gap-2'>
        <div className='alert alert-dismissible bg-white d-flex flex-sm-row align-items-center w-100 col-12 mb-0 p-5'>
          <i className='ki-duotone ki-search-list fs-2hx text-primary '>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
          <div className='d-flex pe-0 pe-sm-10 fs-4 flex-grow-1'>
            <span className='fw-bold'>未確認の注文</span>
          </div>
          <div className='d-flex pe-0 pe-sm-10 fs-4 flex-shrink-0'>
            <span className='fw-bold'>{formatNumber(unReadOrders)}件</span>
          </div>
          {/* <div className='d-flex pe-0 pe-sm-10 fs-4 flex-shrink-0'>
            <button type='button' className='btn btn-sm btn-primary'>
              確認
            </button>
          </div> */}
        </div>

        <div className='alert alert-dismissible bg-white d-flex flex-sm-row align-items-center w-100 col-12 mb-0 p-5'>
          <i className='ki-duotone ki-search-list fs-2hx text-primary '>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
          <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-grow-1'>
            <span className='fw-bold'>処理中の注文</span>
          </div>
          <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-shrink-0'>
            <span className='fw-bold'>{formatNumber(processingOrders)}件</span>
          </div>
          {/* <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-shrink-0'>
            <button type='button' className='btn btn-sm btn-primary'>
              確認
            </button>
          </div> */}
        </div>

        <div className='alert alert-dismissible bg-gray-400 d-flex flex-sm-row align-items-center w-100 col-12 mb-0 p-5'>
          <i className='ki-duotone ki-search-list fs-2hx text-primary '>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
          <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-grow-1'>
            <span className='fw-bold'>後Pay中の合計金額</span>
          </div>
          <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-shrink-0'>
            <span className='fw-bold'>---円</span>
          </div>
          {/* <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-shrink-0'>
            <button type='button' className='btn btn-sm btn-primary' disabled>
              確認
            </button>
          </div> */}
        </div>

        <div className='alert alert-dismissible bg-gray-400 d-flex flex-sm-row align-items-center w-100 col-12 mb-0 p-5'>
          <i className='ki-duotone ki-search-list fs-2hx text-primary '>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
          <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-grow-1'>
            <span className='fw-bold'>銀行振込待ちの合計金額</span>
          </div>
          <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-shrink-0'>
            <span className='fw-bold'>---円</span>
          </div>
          {/* <div className='d-flex flex-column pe-0 pe-sm-10 fs-4 flex-shrink-0'>
            <button type='button' className='btn btn-sm btn-primary'>
              確認
            </button>
          </div> */}
        </div>
      </div>
    </>
  )
}

const SalesChart: React.FC<{salesData: any}> = ({salesData}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [dailyOrders, setDailyOrders] = useState<number>(0)
  const [monthlyOrders, setMonthlyOrders] = useState<number>(0)
  const [monthlyTotal, setMonthlyTotal] = useState<number>(0)
  const [dailyTotal, setDailyTotal] = useState<number>(0)
  const [initFlag, setInitFlag] = useState<boolean>(false)

  // 今日～
  useEffect(() => {
    console.log('salesData', salesData)

    // 売上データのサンプル
    const renderData = {
      thisMonth: [0, 0, 0, 0, 0, 0, 0],
      lastMonth: [0, 0, 0, 0, 0, 0, 0],
    }

    // 6日前～今日までのテキスト
    // 3日前を取得
    const currentTitle =
      moment().subtract(6, 'days').format('MM月DD日') + '～' + moment().format('MM月DD日')

    // 先月の同日を取得
    const lastTitle =
      moment().subtract(6, 'days').subtract(1, 'months').format('MM月DD日') +
      '～' +
      moment().subtract(1, 'months').format('MM月DD日')

    // 6日前～今日までのテキスト
    const xcategories = []
    for (let i = 6; i >= 0; i--) {
      xcategories.push(moment().subtract(i, 'days').format('MM月DD日'))
    }

    // 6日前～今日までの売上データをrenderDataにセット
    for (let i = 0; i < 7; i++) {
      const currentDay = moment()
        .subtract(6 - i, 'days')
        .format('YYYY-MM-DD')
      const lastDay = moment()
        .subtract(6 - i, 'days')
        .subtract(1, 'months')
        .format('YYYY-MM-DD')

      // console.log('currentDay', currentDay)
      // console.log('salesData', salesData[currentDay])

      if (Object.keys(salesData).length === 0) {
        renderData.thisMonth[i] = 0
        renderData.lastMonth[i] = 0
      } else {
        renderData.thisMonth[i] = salesData[currentDay].amount || 0
        renderData.lastMonth[i] = salesData[lastDay].amount || 0
      }
    }

    // ApexOptionsの設定
    const options: ApexOptions = {
      chart: {
        type: 'line',
        height: 350,
        toolbar: {
          show: false, // ツールバーを非表示にする
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '55%',
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        curve: 'smooth', // 線を滑らかにする
      },
      xaxis: {
        categories: xcategories,
      },
      fill: {
        opacity: 1,
      },
      // tooltip: {
      //   y: {
      //     formatter: (val) => `¥${val}`,
      //   },
      // },
      series: [
        {
          name: currentTitle,
          data: renderData.thisMonth,
        },
        {
          name: lastTitle,
          data: renderData.lastMonth,
        },
      ],
    }

    // ApexChartsのインスタンスを作成
    const chart = new ApexCharts(chartRef.current, options)
    chart.render()

    // クリーンアップ
    return () => {
      chart.destroy()
    }
  }, [salesData])

  useEffect(() => {
    // res.dataから今月（yyyy-mm）が先方一致するkeyを探し、合計する
    let totalOrders = 0
    let totalAmount = 0

    // 今日
    const currentDay = moment().format('YYYY-MM-DD')

    // 今月
    const currentMonth = moment().format('YYYY-MM')

    // 先月
    const lastMonth = moment().subtract(1, 'months').format('YYYY-MM')

    if (Object.keys(salesData).length !== 0) {
      for (const key in salesData) {
        // keyの文字列にcurrentMonthを含むか
        if (key.includes(currentMonth)) {
          totalOrders += salesData[key].orders
          totalAmount += salesData[key].amount
        }
      }

      setDailyTotal(salesData[currentDay].amount || 0)
      setMonthlyTotal(totalAmount)
      setMonthlyOrders(totalOrders)
      setDailyOrders(salesData[currentDay].orders || 0)
    }
  }, [salesData])

  return (
    <>
      <div className='row px-4 gap-y-2'>
        <div className='col-6 col-lg-3'>
          <div className='d-flex flex-column gap-1'>
            <span className='fw-bold'>■今日の受注</span>
            <span className='fw-bold fs-2'>{formatNumber(dailyOrders)}件</span>
          </div>
        </div>
        <div className='col-6 col-lg-3'>
          <div className='d-flex flex-column gap-1'>
            <span className='fw-bold'>■今日の売上</span>
            <span className='fw-bold fs-2'>{formatNumber(dailyTotal)}円</span>
          </div>
        </div>
        <div className='col-6 col-lg-3'>
          <div className='d-flex flex-column gap-1'>
            <span className='fw-bold'>■今月の受注</span>
            <span className='fw-bold fs-2'>{formatNumber(monthlyOrders)}件</span>
          </div>
        </div>
        <div className='col-6 col-lg-3'>
          <div className='d-flex flex-column gap-1'>
            <span className='fw-bold'>■今月の売上</span>
            <span className='fw-bold fs-2'>{formatNumber(monthlyTotal)}円</span>
          </div>
        </div>
      </div>
      <div ref={chartRef}></div>
    </>
  )
}

const NavigateMenu: FC = () => {
  const navigate = useNavigate()
  const goNavigator = (index: number) => {
    if (index == 1) {
      navigate('/product')
    } else if (index == 2) {
      navigate('/order')
    } else if (index == 3) {
      navigate('/customer')
    } else if (index == 4) {
      navigate('/account')
    }
  }

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>
            <span className='fw-bold'>ショートカット</span>
          </div>
        </div>
        <div className='card-body py-2'>
          <div className='d-flex justify-content-center gap-4 flex-wrap'>
            <a className='btn btn-white btn-outline ' onClick={() => goNavigator(1)}>
              <i className='fa-solid fa-list fs-6 me-2 '></i>
              <span className='fs-6'>注文管理</span>
            </a>
            <a className='btn btn-white btn-outline ' onClick={() => goNavigator(2)}>
              <i className='fa-solid fa-list fs-6 me-2 '></i>
              <span className='fs-6'>商品管理</span>
            </a>
            <a className='btn btn-white btn-outline ' onClick={() => goNavigator(3)}>
              <i className='fa-solid fa-user fs-6 me-2 '></i>
              <span className='fs-6'>顧客管理</span>
            </a>
            <a className='btn btn-white btn-outline ' onClick={() => goNavigator(4)}>
              <i className='fa-solid fa-circle-user fs-6 me-2 '></i>
              <span className='fs-6'>アカウント編集</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
