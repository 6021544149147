import React, { useState, useEffect, SetStateAction, ChangeEvent, Key } from 'react'
import * as Yup from 'yup'
import { useFormik, FormikProvider, Form, useFormikContext, useField, FieldArray, FieldHookConfig } from 'formik';
import { useParams, useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import { productInfoAdd, productInfoCopy, productInfoUpdate } from 'src/app/modules/api'
import { customerRankListGet, labelListGet, productInfoGet, productCustomItemListGet, productCategoryListGet, getListMedia, parameterStoreGet } from 'src/app/modules/api'
import { useLayout } from 'src/_metronic/layout/core'
import { useAlert } from 'src/_metronic/layout/core'
import { filterTree } from 'src/utils/filter'
import {
  LabelSelector, OptionSelector, ProductCategorySelector, ProductCustomFieldSelector,
  FormRow, CustomInput, CustomSelect, CustomTextArea, CustomRadioGroup, CustomEditor
} from './Form'
import PreviewModal from './PreviewModal'
import { useAuth } from '@/app/modules/auth'
import { Breadcrumb, Modal, Table } from 'antd'
import moment from 'moment';

const validationSchema = Yup.object().shape({
  product_name: Yup.string().required('商品名は必須です。'),
  // product_id: Yup.string().required('商品管理番号は必須です。'),
  main_image: Yup.string().matches(/^\/.*(.jpg|.jpeg|.png)$/, '入力形式が間違っています（jpg, jpeg, png のみ入力できます）').matches(/^[a-zA-Z0-9!-/:-@[-`{-~_]+$/, '半角英数字で入力してください'),
})
const conditionOptions: any = [
  { id: 'publish', label: '表示' },
  { id: 'hidden', label: '非表示' },
]

export const ProductInfo = ({ props }: any) => {
  const { auth } = useAuth()
  const group_id = auth?.group_id

  const { setContentLoading } = useLayout()
  const { setMessage } = useAlert()
  const navigate = useNavigate()
  const { id } = useParams()
  const [productData, setProductData] = useState<any>([])
  const [formData, setFormData] = useState<any>({
    url: '',
    product_name: '',
    product_id: '',
    catch_copy: '',
    status: '',
    black_market_setting: 'no',
    weight_setting_usage: 'no',
    unit: '0',
    weight: '',
    notes: '',
    label: [''],
    product_custom_field: [{ id: '', value: '' }],
    recommend: '',
    condition: 'publish',
    product_hidden_customer_rank: '',
    exception_display_member_id: '',
    display_order: '',
    upper_free_space: '',
    middle_free_space: '',
    lower_free_space: '',
    meta_title: '',
    meta_keywords: '',
    meta_description: '',
    categories: [[]],
    options: [''],
    // main_image: { id: '', url: '', alt: '' },
    main_image: '',
    sub_images: [''],
    create_at: '',
    update_at: '',
  })
  const [modalShow, setModalShow] = useState(false)
  const [s3Url, setS3Url] = useState('')
  const mediaUrl = s3Url + '/' + encodeURIComponent(auth.group_id.split('#Suppliers#')?.[1])

  const getParameterStore = async () => {
    try {
      const res: any = await parameterStoreGet({ names: ['/Define/Common'] }, {})
      setS3Url(JSON.parse(res['/Define/Common'])?.S3URL)
    } catch (error) {
      console.error('Failed to fetch parameter store', error)
    }
  }

  const submitData = (data: any) => {
    // const leftData = leftRef.current && leftRef.current.submitData()
    let obj = { ...data }
    // console.log('leftData', data, leftData, obj)
    if (id !== "edit") {
      delete obj.update_at
      productInfoUpdate(obj).then((res: any) => {
        setMessage({ type: 'success', message: '更新しました' })
        initData()
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }).catch((err: any) => {
        setMessage({ type: 'error', message: '更新に失敗しました' })
        console.log('err', err)
      })

    } else if (id === 'edit') {
      productInfoAdd(obj).then((res: any) => {
        if (res.message === '商品管理番号が重複しています') {
          setMessage({ type: 'error', message: '商品管理番号が重複しています' })
        } else {
          setMessage({ type: 'success', message: '登録しました' })
          navigate(`/product/${(res.id).split('#').pop()}`)
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
          initData()
        }
      }).catch((err: any) => {
        console.log('err', err)
      })
    }

  }

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      // console.log(values)
      delete values._form

      setSubmitting(true)
      setContentLoading(true)
      let obj = JSON.parse(JSON.stringify(values))
      obj.label = obj.label?.join(',')
      obj.options = obj.options?.join(',')
      obj.product_custom_field = JSON.stringify(obj.product_custom_field)
      obj.sub_images = obj.sub_images?.join(',') || ''

      if (!!obj.categories) {
        obj.categories = JSON.stringify(obj.categories)
      }
      obj.product_hidden_customer_rank = obj.product_hidden_customer_rank
        ? obj.product_hidden_customer_rank.join(',')
        : ''
      obj.display_order = Number(obj.display_order)
      // console.log('values', obj)
      submitData(obj)

      setContentLoading(false)
      // console.log(values);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    // checkId().then(() => {
    //   initSelect()
    //   initData()
    // });
    checkId()
    // initSelect()
    // initData()
  }, [])

  useEffect(() => {
    initSelect()
    initData()
    getParameterStore()
  }, [productData])

  const checkId = () => {
    if (id !== 'edit') {
      productInfoGet({ pkVal: id }).then((res: any) => {
        setProductData(res)
      }).catch(() => {
        navigate('/404', { replace: true })
      })
    }
  }

  const initSelect = async () => {
    setContentLoading(true)

    const customerRankOptions: any = await customerRankListGet({})
    const labelOptions: any = await labelListGet({ pageNum: 1, pageSize: 100 })
    const productCustomItemList: any = await productCustomItemListGet({
      type: 'product',
      pageNum: 1,
      pageSize: 100,
    })
    const productCategoryList: any = await productCategoryListGet({ pageNum: 1, pageSize: 100 })

    setFormData((prev: any) => ({
      ...prev,
      _form: {
        ...prev._form,
        product_options: [{}],
        customer_rank_options: customerRankOptions.rows,
        label_options: labelOptions.rows,
        product_custom_field_options: productCustomItemList.rows,
        product_category_options: filterTree(productCategoryList.rows),
      }
    }));

  }

  useEffect(() => {
    // console.log('formData', formData)
  }, [formData])

  const initData = async () => {
    setContentLoading(true)

    // if (id !== 'id' && (id || searchValue)) {
    if (id !== 'edit') {

      setContentLoading(true)
      // PKで詳細情報を取得
      let res: any = await productInfoGet({
        pkVal: id,
      })

      // let res = productData;
      // console.log("res", res)

      res.product_custom_field = JSON.parse(res?.product_custom_field || '[]')
      res.label = res?.label?.split(',')
      res.options = res?.options?.split(',') || ['']
      res.sub_images = res?.sub_images?.split(',') || ['']
      if (!!res.categories) {
        const categories = JSON.parse(res.categories || '[]')
        res.categories = categories
      }

      // setSelectedItems(res?.product_hidden_customer_rank?.split(','))
      // setProductOptions(res?.productOptions)

      res.product_hidden_customer_rank = res?.product_hidden_customer_rank?.split(',')
      // if (rows?.rows.length == res?.product_hidden_customer_rank.length) {
      // setSelectedAll(true)
      // }

      setFormData((prev: any) => ({
        ...prev,
        url: `${window.location.protocol}//${window.location.hostname}/buy/product/${id}`,
        pkVal: res.pk,
        status: res?.status,
        product_name: res?.product_name,
        product_id: res?.product_id,
        catch_copy: res?.catch_copy,
        black_market_setting: res?.black_market_setting,
        weight_setting_usage: res?.weight_setting_usage,
        unit: res?.unit,
        weight: res?.weight,
        notes: res?.notes,
        label: res?.label,
        product_custom_field: res?.product_custom_field,
        recommend: res?.recommend,
        condition: res?.condition,
        product_hidden_customer_rank: res?.product_hidden_customer_rank,
        exception_display_member_id: res?.exception_display_member_id,
        display_order: res?.display_order,
        upper_free_space: res?.upper_free_space,
        middle_free_space: res?.middle_free_space,
        lower_free_space: res?.lower_free_space,
        meta_title: res?.meta_title,
        meta_keywords: res?.meta_keywords,
        meta_description: res?.meta_description,
        options: res?.options,
        categories: res?.categories,
        main_image: res?.main_image,
        sub_images: res?.sub_images,
        create_at: res?.create_at,
        update_at: res?.update_at,
      }));
    }

    setContentLoading(false)

  }

  return (
    <>

      <div className='container-fluid p-0'>
        <FormikProvider value={formik}>
          <Form>
            <SectionInfo />
            <SectionData />
            <SectionMedia mediaUrl={mediaUrl} group_id={group_id} setContentLoading={setContentLoading} formik={formik} />
            <SectionDisplay />
            <SectionText />
            <SectionMetaData />
            <ControllArea
              handleModalShow={setModalShow}
            />
          </Form>
          {modalShow && <PreviewModal show={modalShow} handleModalShow={() => setModalShow(false)} product={{ ...formik.values }} />}
        </FormikProvider>
      </div>

    </>
  )

};
const ControllArea: React.FC<any> = ({ handleModalShow }) => {

  const { id } = useParams()
  const navigate = useNavigate()
  const { handleSubmit, isSubmitting, isValid } = useFormikContext<any>();

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleSubmit();
  };

  const handleButtonModalClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleModalShow(true)
  }

  return (
    <div className='app-wrapper fixed-bottom' style={{ zIndex: 10 }}>
      <div className='col-12 col-md-auto w-100'>
        <div
          className='btn-group d-flex flex-wrap'
          role='group'
          aria-label='Button group'
        >
          <button
            type='button'
            className='btn btn-primary rounded mx-2 my-2'
            onClick={() => navigate(`/product`)}
          >
            一覧へ戻る
          </button>

          {id != 'edit' && (
            <button
              type='button'
              className='btn btn-success rounded mx-2 my-2'
              onClick={() => {
                navigate(`/product/${id}/variation`)
              }}
            >
              バリエーション編集
            </button>
          )}

          <button
            type='button'
            className='btn btn-info rounded mx-2 my-2'
            onClick={(event) => {
              handleButtonModalClick(event)
            }}
          >
            プレビューを表示
          </button>

          {id == 'edit' && (
            <button
              className='btn btn-primary rounded mx-2 my-2'
              type='submit'
              onClick={handleButtonClick}
              disabled={isSubmitting || !isValid}
            >
              新規登録
            </button>
          )}
          {id != 'edit' && (
            <button
              className='btn btn-primary rounded mx-2 my-2'
              type='submit'
              onClick={handleButtonClick}
              disabled={isSubmitting || !isValid}
            >
              更新
            </button>
          )}
        </div>
      </div>
    </div>
  )

}

const SectionInfo: React.FC = () => {

  const { values } = useFormikContext<any>();
  const statusOption = [
    { value: 'publish', label: '公開中' },
    { value: 'draft', label: '下書き' },
    { value: 'hidden', label: '非公開' },
    { value: 'reserve', label: '公開予約' },
  ]

  return (
    <div className='card mb-5 fs-7'>
      <div className='card-header'>
        <h4 className='card-title fs-6 fw-bold'>商品情報</h4>
      </div>
      <div className='card-body'>
        <div className='row mb-2'>
          <label className='col-lg-2 col-form-label fs-7'>
            <span>商品URL</span>
          </label>
          <div className='col-lg-10 align-self-center'>
            {values.url}
          </div>
        </div>

        <FormRow label='ステータス' required>
          <CustomSelect label="ステータス" name="status" options={statusOption} props={
            {
              onChange: (e: any) => {
                console.log('e', e.target.value)
              }
            }
          } />
        </FormRow>

        {values.ststus && values.status === 'reserve' && (
          <>
            <FormRow label='公開開始日時' required>
              <CustomInput
                label='公開開始日時'
                name='publication_reserve_date'
                type='datetime'
                placeholder='公開開始日時'
              />
            </FormRow>

            <FormRow label='公開終了日時' required>
              <CustomInput
                label='公開終了日時'
                name='publication_end_date'
                type='datetime'
                placeholder='公開終了日時'
              />
            </FormRow>
          </>
        )}

        <div className='row mb-2'>
          <label className='col-lg-2 col-form-label fs-7'>
            <span>作成日</span>
          </label>
          <div className='col-lg-10 align-self-center'>
            {values.create_at}
          </div>
        </div>

        <div className='row mb-2'>
          <label className='col-lg-2 col-form-label fs-7'>
            <span>最終更新日</span>
          </label>
          <div className='col-lg-10 align-self-center'>
            {values.update_at}
          </div>
        </div>

      </div>
    </div>
  )

}

const SectionData: React.FC = () => {

  const { values } = useFormikContext<any>();

  return (
    <div className='card mb-5 fs-7'>
      <div className='card-header'>
        <h4 className='card-title fs-6 fw-bold'>基本情報設定</h4>
      </div>
      <div className='card-body'>
        <FormRow label='商品名' required>
          <CustomInput
            label='商品名'
            name='product_name'
            type='text'
            placeholder='商品名'
          />
        </FormRow>

        <FormRow label='商品ID' required>
          <CustomInput
            label='商品ID'
            name='product_id'
            type='text'
            placeholder='商品ID'
          />
        </FormRow>

        <FormRow label='キャッチコピー'>
          <CustomInput
            label='キャッチコピー'
            name='catch_copy'
            type='text'
            placeholder='キャッチコピー'
          />
        </FormRow>
        <LabelSelector
          options={values?._form?.label_options}
          updateData={(values: any) => console.log(values)}
        />
        <OptionSelector
          options={values?._form?.product_options}
          updateData={(values: any) => console.log(values)}
        />
        <ProductCustomFieldSelector
          options={values?._form?.product_custom_field_options}
          updateData={(values: any) => console.log(values)}
        />
        <ProductCategorySelector
          options={values?._form?.product_category_options}
          updateData={(values: any) => console.log(values)}
        />

        <FormRow label='レコメンド'>
          <CustomInput
            label='レコメンド'
            name='recommend'
            type='text'
            placeholder='レコメンド'
          />
        </FormRow>
      </div>
    </div>
  )

};

const renderModalContent = (data: any, values: any, setContentLoading: any, formik: any, breadcrumb: any[], setBreadcrumb: any) => {
  // フォルダを上にソート
  data = data.sort((a: any, b: any) => b.is_folder - a.is_folder);

  const columns = [
    {
      title: '名前',
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => (
        <span
          style={{ columnGap: '5px' }}
          className="d-flex cursor-pointer text-primary"
          onClick={async () => {
            if (record.is_folder === 1) {
              setContentLoading(true);
              const folderResponse: any = await getListMedia({ parent: record.pk });
              setContentLoading(false);

              if (folderResponse) {
                setBreadcrumb([...breadcrumb, { name: record.name, pk: record.pk }]);
                Modal.destroyAll();
                renderModalContent(folderResponse.items, values, setContentLoading, formik, [...breadcrumb, { name: record.name, pk: record.pk }], setBreadcrumb);
              }
            } else {
              formik.setFieldValue('main_image', record.path);
              Modal.destroyAll();
            }
          }}
        >
          {record.is_folder === 1 ? (
            <>
              <i className='bi bi-folder-fill text-primary fs-2'></i> {text}
            </>
          ) : (
            <>
              <i className='bi bi-file-image-fill text-primary fs-2'></i> {text}
            </>
          )}
        </span>
      ),
    },
    {
      title: 'サイズ',
      dataIndex: 'size',
      key: 'size',
      render: (text: any) => `${(text / 1024).toFixed(2)} KB`,
    },
    {
      title: '更新日時',
      dataIndex: 'update_at',
      key: 'update_at',
      render: (text: any) => moment(text).format('YYYY-MM-DD HH:mm'),
    },
  ];

  Modal.info({
    title: '画像選択',
    content: (
      <div style={{ width: '100%', overflow: 'auto', maxHeight: '400px' }}>
        <Breadcrumb
          className='my-4'
          items={[
            {
              title: 'TOP',
              onClick: async () => {
                if (breadcrumb.length === 0) return;
                setContentLoading(true);
                const folderResponse: any = await getListMedia({});
                setContentLoading(false);
                if (folderResponse) {
                  setBreadcrumb([]);
                  Modal.destroyAll();
                  renderModalContent(folderResponse.items, values, setContentLoading, formik, [], setBreadcrumb);
                }
              },
              className: 'cursor-pointer'
            },
            ...breadcrumb.map((bc, index) => ({
              title: bc.name,
              onClick: async () => {
                if (breadcrumb.length === index + 1) return;
                setContentLoading(true);
                const folderResponse: any = await getListMedia({ parent: bc.pk });
                setContentLoading(false);
                if (folderResponse) {
                  setBreadcrumb(breadcrumb.slice(0, index + 1));
                  Modal.destroyAll();
                  renderModalContent(folderResponse.items, values, setContentLoading, formik, breadcrumb.slice(0, index + 1), setBreadcrumb);
                }
              },
              className: 'cursor-pointer'
            }))
          ]}
        />
        <div className='overflow-auto min-w-600px'>
          <Table
            dataSource={data}
            columns={columns}
            rowKey="pk"
            pagination={{ pageSize: 10 }}
            // scroll={{ y: 400 }}
            loading={setContentLoading}
            className='border'
          />
        </div>
      </div>
    ),
    width: 'min(85%, 1000px)',
    style: { zIndex: 9999 },
    maskClosable: true,
    onOk() { },
    okText: '閉じる',
  });
};


const SectionMedia: React.FC<{ mediaUrl: string, group_id: string, setContentLoading: any, formik: any }> = ({ mediaUrl, group_id, setContentLoading, formik }) => {

  const { values, handleChange, handleBlur } = useFormikContext<any>();
  const [breadcrumb, setBreadcrumb] = useState([]);

  return (
    <div className='card mb-5 fs-7'>
      <div className='card-header'>
        <h4 className='card-title fs-6 fw-bold'>画像登録</h4>
      </div>
      <div className='card-body'>
        <div className='row mb-6'>
          <label className='col-lg-2 col-form-label fs-7'>
            メイン画像
          </label>

          <div className='col-lg-10 align-self-center fs-7'>
            <div className="row mb-4">
              <CustomInput
                label='メイン画像'
                name='main_image'
                type='text'
                placeholder='メイン画像'
              />
              <button
                className='btn btn-primary rounded my-4 mx-4 w-200px'
                type='button'
                onClick={async (e) => {
                  e.preventDefault();
                  setContentLoading(true);
                  let response: any = await getListMedia({});
                  setContentLoading(false);
                  if (response) {
                    setBreadcrumb([])
                    renderModalContent(response.items, values, setContentLoading, formik, [], setBreadcrumb);
                  }
                }}
              >
                画像選択
              </button>

            </div>
            <div>
              <img
                src={
                  values?.main_image
                    ? mediaUrl + values?.main_image
                    : ''
                }
                alt=""
                className='mw-200px'
              />
            </div>
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-2 col-form-label fs-7'>
            サブ画像
          </label>

          <div className='col-lg-10 align-self-center fs-7'>
            <div className="row">
              <FieldArray
                name="sub_images"
                render={(arrayHelpers) => (
                  <>
                    {values?.sub_images && values?.sub_images.map((item: any, index: number) => (
                      <div className='col-lg-12 ps-0' key={index}>
                        <input
                          className='form-control'
                          type='text'
                          value={item}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => arrayHelpers.replace(index, e.target.value)}
                          onBlur={handleBlur}
                        />
                      </div>
                    ))}
                  </>
                )}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  )

};

const SectionDisplay: React.FC = () => {

  const { values } = useFormikContext<any>();
  const [selectedAll, setSelectedAll] = useState(false)
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  useEffect(() => {
    if (values.product_hidden_customer_rank) {
      setSelectedItems(values.product_hidden_customer_rank)
    }
  }, [values.product_hidden_customer_rank])

  const handleSelectAll = (e: any) => {
    // console.log('e.target.checked', e.target.checked)
    let arr = []

    if (e.target.checked) {
      // 如果之前没有全选，将所有项添加到选中项数组中
      arr = values.product_hidden_customer_rank.map((item: any) => item.group_id)
      setSelectedItems(arr)
    } else {
      // 如果之前已经全选，清空选中项数组
      setSelectedItems([])
    }

    setSelectedAll(e.target.checked)
    values.product_hidden_customer_rank = arr
    // updateData(values)
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, itemId: string) => {
    const checked = e.target.checked
    let newSelectedItems: string[] = []

    if (checked) {
      console.log("selectedItems-before", selectedItems)
      newSelectedItems = [...selectedItems, itemId]
      console.log("selectedItems-after", newSelectedItems)
    } else {
      newSelectedItems = selectedItems.filter((id) => id !== itemId)
    }

    // console.log(newSelectedItems.length, _form.customer_rank_options.length)
    const allItemsSelected = newSelectedItems.length === values._form.customer_rank_options.length
    setSelectedItems(JSON.parse(JSON.stringify(newSelectedItems)))
    setSelectedAll(allItemsSelected)

    values.product_hidden_customer_rank = newSelectedItems
    // updateData(values)
  }

  return (
    <div className='card mb-5 fs-7'>
      <div className='card-header'>
        <h4 className='card-title fs-6 fw-bold'>表示設定</h4>
      </div>
      <div className='card-body'>
        <FormRow label='基本表示設定' >
          <CustomRadioGroup
            label="基本表示設定"
            name="condition"
            options={conditionOptions}
          />
        </FormRow>

        <div className='row mb-2'>
          <div className='col-2 align-items-center align-self-center'>
            <label className=''>非表示顧客ランク</label>
          </div>
          <div className='col-10 align-self-center'>
            <div>下記でチェックがついている「顧客ランク」の顧客には、この商品は表示されません</div>
            <table
              style={{ borderCollapse: 'collapse', border: '1px solid #c7c7c7' }}
              className='table table-bordered'
            >
              <thead style={{ backgroundColor: '#7f7f7f' }}>
                <tr>
                  <th scope='col' style={{ textAlign: 'center' }}>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={selectedAll}
                      onChange={(e) => handleSelectAll(e)}
                    />
                  </th>
                  <th scope='col' style={{ color: '#fff' }}>
                    ID
                  </th>
                  <th scope='col' style={{ color: '#fff' }}>
                    顧客ランク名
                  </th>
                </tr>
              </thead>
              <tbody
                style={{
                  borderCollapse: 'separate',
                  borderSpacing: '1px',
                  border: '1px solid #c7c7c7',
                }}
              >
                {values?._form?.customer_rank_options && values?._form?.customer_rank_options?.map((item: any, index: number) => (
                  <tr key={index}>
                    <td style={{ textAlign: 'center' }}>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={values.product_hidden_customer_rank.includes(item.group_id)}
                        onChange={(e) => handleCheckboxChange(e, item.group_id)}
                      />
                    </td>
                    <td>{item.group_id}</td>
                    <td>{item.group_name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <FormRow label='例外表示顧客' >
          <CustomInput
            label='例外表示顧客'
            name='exception_display_member_id'
            type='text'
            placeholder='例外表示顧客'
          // description='例外表示顧客のIDをカンマ区切りで入力してください。'
          />
        </FormRow>

        <FormRow label='表示順' >
          <CustomInput
            label='表示順'
            name='display_order'
            type='text'
            placeholder='表示順'
          />
        </FormRow>

      </div>
    </div>
  )

};

const SectionText: React.FC = () => {

  return (
    <div className='card mb-5 fs-7'>
      <div className='card-header'>
        <h4 className='card-title fs-6 fw-bold'>商品説明</h4>
      </div>
      <div className='card-body'>
        <FormRow label='上部 商品説明欄' >
          <CustomEditor label="上部 商品説明欄" name="upper_free_space" />
        </FormRow>
        <FormRow label='中部 商品説明欄' >
          <CustomEditor label="中部 商品説明欄" name="middle_free_space" />
        </FormRow>
        <FormRow label='下部 商品説明欄' >
          <CustomEditor label="下部 商品説明欄" name="lower_free_space" />
        </FormRow>
        <FormRow label='注意事項' >
          <CustomTextArea label="注意事項" name="notes" />
        </FormRow>
      </div>
    </div>
  )

};

const SectionMetaData: React.FC = () => {

  return (
    <div className='card mb-5 fs-7'>
      <div className='card-header'>
        <h4 className='card-title fs-6 fw-bold'>メタ情報</h4>
      </div>
      <div className='card-body'>
        <FormRow label='ページタイトル' >
          <CustomInput
            label='ページタイトル'
            name='meta_title'
            type='text'
            placeholder='ページタイトル'
          />
        </FormRow>

        <FormRow label='外部検索キーワード' >
          <CustomInput
            label='外部検索キーワード'
            name='meta_keywords'
            type='text'
            placeholder='外部検索キーワード'
          />
        </FormRow>

        <FormRow label='ページの概要'>
          <CustomInput
            label='ページの概要'
            name='meta_description'
            type='text'
            placeholder='ページの概要'
          />
        </FormRow>

      </div>
    </div>
  )

};