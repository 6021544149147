/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {showSuccessMessage, showErrorMessage, showWarningMessage} from '@/app/components/sweetAlert'
import {productStatusBulkUpdate, productInfoDelete} from 'src/app/modules/api'

const options = [
  // {id: 'copy', name: 'コピーを作成する'},
  {id: 'delete', name: '削除'},
  {id: 'publish', name: '公開'},
  {id: 'hidden', name: '非公開'},
  {id: 'draft', name: '下書き'},
]
const CustomFieldActionsCell = (props: any) => {
  const navigate = useNavigate()
  const [id, setId] = useState<any>('')
  const [selectType, setSelectType] = useState<any>('')

  useEffect(() => {
    setId(props.item.pk.split('#').pop())
  }, [])

  const editStatus = (item: any, val: any) => {
    console.log('item.controls', val)
    // 复制
    if (val == 'copy') {
      navigate(`/product/${id}?copy=1`, {state: item.pk})
    } else if (val == 'delete') {
      showWarningMessage(
        '元に戻すことはできません。よろしいでしょうか？',
        () => {
          productInfoDelete({pkVal: item.pk}).then((res: any) => {
            console.log('res', res)
            props.getAllList()
            showSuccessMessage('削除しました')
          })
        },
        '削除',
        'キャンセル'
      )
    } else if (val == 'publish' || val == 'hidden') {
      showWarningMessage(
        '商品ステータスを更新します。よろしいでしょうか？',
        () => {
          productStatusBulkUpdate({pkVal: JSON.stringify([item.pk]), status: val}).then(
            (res: any) => {
              props.getAllList()
              showSuccessMessage('更新しました')
            }
          )
        },
        '更新',
        'キャンセル'
      )
    } else if (val == 'draft') {
      showWarningMessage(
        '商品ステータスを「下書き」にします。よろしいでしょうか？',
        () => {
          productStatusBulkUpdate({pkVal: JSON.stringify([item.pk]), status: val}).then(
            (res: any) => {
              props.getAllList()
              showSuccessMessage('下書きに変更しました')
            }
          )
        },
        '更新',
        'キャンセル'
      )
    }
  }

  return (
    <>
      <div className='btn-group'>
        <button
          type='button'
          className='btn btn-sm btn-white btn-outline'
          onClick={() => {
            navigate(`/product/${id}`, {state: props.item.pk})
          }}
        >
          編集
        </button>
        <div className='btn-group'>
          <button
            type='button'
            className='btn btn-sm btn-white btn-outline dropdown-toggle dropdown-toggle-split'
            data-bs-toggle='dropdown'
          ></button>
          <div className='dropdown-menu dropdown-menu-sm dropdown-menu-end'>
            {options.length !== 0 &&
              options.map((item: any, index: number) => {
                // props.status が item.id と一致する場合は表示しない
                if (props.status === item.id) {
                  return null
                }

                return (
                  <a
                    className='dropdown-item'
                    key={item.id}
                    onClick={() => {
                      editStatus(props.item, item.id)
                    }}
                  >
                    {item.name}
                  </a>
                )
              })}
          </div>
        </div>
      </div>
      {/* <select
        className='form-select w-auto'
        data-control='select2'
        value={props.controls}
        onChange={(e) => {
          // setSelectType(e.target.value)
          editStatus(props.item, e.target.value)
          // props.operationModification(props.index, e.target.value)
        }}
      >
        <option value={''}></option>
        {options.length !== 0 &&
          options.map((item: any, index: number) => {
            return (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            )
          })}
      </select> */}
    </>
  )
}

export {CustomFieldActionsCell}
