/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const profileBreadCrumbs: Array<PageLink> = [
	{
		title: 'ホーム',
		path: '/dashboard',
		isSeparator: false,
		isActive: false,
	  },
	  {
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	  },
]

const Pageconts: FC = () => {
    return (
      <>
	  <div className='card p-9 fs-6'>
	  	<h2 className="fs-2x w-bolder mb-6">個人情報保護方針</h2>
	  	<figure className="text-end">
	  		<blockquote className="blockquote">
	  			<p className='fs-6'>(JIS Q 15001:2017)<br></br> PF-101<br></br> 制定日　2020年11月11日<br></br> 最終改正日　2020年11月11日</p>
	  		</blockquote>
	  	</figure>
	  	<div className='mb-10'>株式会社アップシェア（以下、「当社」という。）は、インターネット関連事業を行っており、お客様、お取引先関係者、従業者、および関係者の個人情報保護が重大な責務であると認識しております。<br></br> そこで個人情報保護理念と自ら定めた行動規範に基づき、社会的使命を十分に認識し、本人の権利の保護、個人情報に関する法規制等を遵守致します。<br></br> また、以下に示す方針を具現化するための個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的改善に、全社を挙げて取り組むことをここに宣言致します。</div>
	  	<ol>
	  		<li className='mb-3'>当社は、適切な個人情報の取得・利用及び提供を行い、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱いを行ないません。また、そのための措置を講じます。</li>
	  		<li className='mb-3'>当社は個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守致します。</li>
	  		<li className='mb-3'>当社は個人情報の漏えい、滅失、き損などのリスクに対しては、合理的な安全対策を講じて防止すべく、事業の実情に合致した経営資源を注入し、個人情報のセキュリティ体制を継続的に向上させていきます。<br></br>また、万一の際には速やかに是正措置を講じます。</li>
	  		<li className='mb-3'>当社は個人情報取扱いに関する苦情及び相談に対しては、迅速かつ誠実に対応致します。</li>
	  		<li>個人情報保護マネジメントシステムは、当社を取り巻く環境の変化と実情を踏まえ、適時・適切に見直して継続的に改善をはかります。</li>
	  	</ol>
	  	<div className='mb-3'>株式会社アップシェア　　代表取締役　稲井田　顕章</div>
	  	<div className='card m-8 p-6 border border-secondary border-2'>
	  		<div className='fs-5 fw-bold mb-3'>【個人情報保護方針に関するお問い合わせ先 兼 個人情報に関する苦情・相談窓口】</div>
	  		<div>〒150-0002 東京都渋谷区渋谷1-12-8ILA渋谷美竹ビル3階<br></br> 株式会社アップシェア　個人情報に関するお問い合わせ窓口<br></br> <a href="m&#97;i&#108;t&#111;:p&#109;s@&#117;&#112;sh&#97;&#114;&#101;&#46;&#99;&#111;&#46;&#106;&#112;">pms＠(アットマーク)upshare.co.jp</a><br></br> TEL：<a href="tel:05054975109">050-5497-5109</a> （受付時間　11:00～17:00）<br></br> FAX：03-3546-9774 </div>
	  	</div>
	  	<div className="separator my-10"></div>
	  	<h2 className="fs-2x w-bolder mb-6">個人情報保護に対する基本的な考え方</h2>
	  	<div className='mb-10'>株式会社アップシェア（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。</div>
	  	<div className='fs-3 mb-1'>第1条（個人情報）</div>
	  	<div className='mb-10'>「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。</div>
	  	<div className='fs-3 mb-1'>第2条（個人情報の収集方法）</div>
	  	<div className='mb-10'>当社は，ユーザーが利用登録をする際に氏名，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。</div>
	  	<div className='fs-3 mb-1'>第3条（個人情報を収集・利用する目的）</div>
	  	<div className='mb-3'>当社が個人情報を収集・利用する目的は，以下のとおりです。</div>
	  	<ol className='mb-10'>
	  		<li className='mb-3'>当社サービスの提供・運営のため</li>
	  		<li className='mb-3'>ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</li>
	  		<li className='mb-3'>ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため</li>
	  		<li className='mb-3'>メンテナンス，重要なお知らせなど必要に応じたご連絡のため</li>
	  		<li className='mb-3'>利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため</li>
	  		<li className='mb-3'>ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため</li>
	  		<li className='mb-3'>有料サービスにおいて，ユーザーに利用料金を請求するため</li>
	  		<li>上記の利用目的に付随する目的</li>
	  	</ol>
	  	<div className='fs-3 mb-1'>第4条（利用目的の変更）</div>
	  	<ol className='mb-10'>
	  		<li className='mb-3'>当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。</li>
	  		<li>利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。</li>
	  	</ol>
	  	<div className='fs-3 mb-1'>第5条（個人情報の第三者提供）</div>
	  	<ol>
	  		<li className='mb-3'>当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合及び以下を除きます。 <ol className='mt-3'>
	  				<li className='mb-3'>人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき</li>
	  				<li className='mb-3'>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき</li>
	  				<li className='mb-3'>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
	  				<li className='mb-3'>アメリカン・エキスプレスが、(1)お客様への本サービスの提供、(2)本サービスの利用動向の統計分析、および(3)お客様への的確な商品・サービスの案内を行うため、弊社が個人情報の一部（氏名、住所、電話番号、電子メールアドレス、購入情報など）をアメリカン・エキスプレスに提供する場合</li>
	  				<li className='mb-3'>予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき 
						<ol className='mt-3'>
	  						<li className='mb-3'>利用目的に第三者への提供を含むこと</li>
	  						<li className='mb-3'>第三者に提供されるデータの項目</li>
	  						<li className='mb-3'>第三者への提供の手段または方法</li>
	  						<li className='mb-3'>本人の求めに応じて個人情報の第三者への提供を停止すること</li>
	  						<li className='mb-3'>本人の求めを受け付ける方法</li>
	  					</ol>
	  				</li>
	  			</ol>
	  		</li>
	  		<li className='mb-3'>前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。 
				<ol className='mt-3'>
	  				<li className='mb-3'>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>
	  				<li className='mb-3'>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
	  				<li className='mb-3'>個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合</li>
	  			</ol>
	  		</li>
	  	</ol>
	  	<div className='fs-3 mb-1'>第6条（個人情報の開示）</div>
	  	<ol>
	  		<li className='mb-3'>当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。 
				<ol className='mt-3'>
	  				<li className='mb-3'>本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合</li>
	  				<li className='mb-3'>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
	  				<li className='mb-3'>その他法令に違反することとなる場合</li>
	  			</ol>
	  		</li>
	  		<li className='mb-3'>前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。</li>
	  	</ol>
	  	<div className='fs-3 mb-1'>第7条（個人情報の訂正および削除）</div>
	  	<ol>
	  		<li className='mb-3'>ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。</li>
	  		<li className='mb-3'>当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。</li>
	  		<li className='mb-3'>当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。</li>
	  	</ol>
	  	<div className='fs-3 mb-1'>第8条（個人情報の利用停止等）</div>
	  	<ol>
	  		<li className='mb-3'>当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。</li>
	  		<li className='mb-3'>前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。</li>
	  		<li className='mb-3'>当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。</li>
	  		<li className='mb-3'>前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。</li>
	  	</ol>
	  	<div className='fs-3 mb-1'>第9条（プライバシーポリシーの変更）</div>
	  	<ol>
	  		<li className='mb-3'>本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。</li>
	  		<li className='mb-3'>.当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。</li>
	  	</ol>
	  	<div className='fs-3 mb-1'>第10条（お問い合わせ窓口）</div>
	  	<div className='mb-10'>本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。</div>
	  	<div className='mb-10'>【個人情報保護方針に関するお問い合わせ先 兼 個人情報に関する苦情・相談窓口】</div>
	  	<div className='mb-10'>〒150-0002<br></br> 東京都渋谷区渋谷1-12-8ILA渋谷美竹ビル3階<br></br> 株式会社アップシェア　情報システム部<br></br> (日本データ通信協会　準会員番号 11921)<br></br> メールアドレス：<a href="m&#97;i&#108;t&#111;:p&#109;s@&#117;&#112;sh&#97;&#114;&#101;&#46;&#99;&#111;&#46;&#106;&#112;">p&#109;s@&#117;&#112;sh&#97;&#114;&#101;&#46;&#99;&#111;&#46;&#106;&#112;</a>
<br></br> TEL：<a href="tel:05054975109">050-5497-5109</a> （受付時間　11:00～17:00）<br></br> FAX：03-3546-9774<br></br> 個人情報保護規程　JIS Q 15001:2017</div>
	  	<div className="separator my-10"></div>
	  	<h2 className="fs-2x w-bolder mb-6">個人情報の利用目的の公表</h2>
	  	<figure className="text-end">
	  		<blockquote className="blockquote">
			  <p className='fs-6'>(JIS Q 15001:2017)<br></br>PF-102-1</p>
	  		</blockquote>
	  	</figure>
	  	<div className='mb-10'>株式会社アップシェア（以下、「当社」という。）は、個人情報を次の利用目的の範囲内で利用することを、個人情報の保護に関する法律（個人情報保護法）第18条第１項に基づき公表します。</div>
	  	<div className='fs-3 mb-1'>個人情報の利用目的</div>
	  	<div className='mt-10'>(1)ご本人より直接書面等（ホームページや電子メール等によるものを含む。以下「書面」という）に記載された個人情報を取得する場合の利用目的。</div>
	  	<ul className='mt-10'>
	  		<li>お客様の個人情報は、インターネット関連事業におけるサービスの提供、新たなサービスのご案内のため</li>
	  		<li>当社が運営するECサイトのお客様情報は、商品の発送、商品情報提供のため</li>
	  		<li>当社の各事業に関するお問い合わせの方の個人情報は、お問い合わせにお答えするため</li>
	  		<li>当社従業者の個人情報は、人事労務管理、業務管理、健康管理、セキュリティ管理等のため</li>
	  		<li>当社への直接応募者情報は、採用業務のため</li>
	  	</ul>
	  	<div className='mt-10'>(2)ご本人より直接書面以外の方法で個人情報を取得する際の利用目的</div>
	  	<ul className='mt-10'>
	  		<li>当社が出店するECモールのお客様情報は、商品の発送、商品情報提供のため</li>
	  		<li>当社が受託するECサイト運営業務を実施のため</li>
	  		<li>協力会社の要員情報は、スキルの確認のため、委託元に提供するため</li>
	  		<li>ハローワークや民間就職斡旋サイトからの応募者情報は、採用業務のため</li>
	  	</ul>
	  	<div className='mt-10'>株式会社アップシェア　　代表取締役　稲井田　顕章</div>
		<div className='card mt-8 p-6 border border-secondary border-2'>
	  		<div className='fs-5 fw-bold mb-3'>【個人情報保護方針に関するお問い合わせ先 兼 個人情報に関する苦情・相談窓口】</div>
	  		<div>〒150-0002 東京都渋谷区渋谷1-12-8ILA渋谷美竹ビル3階<br></br> 株式会社アップシェア　個人情報に関するお問い合わせ窓口<br></br> <a href="m&#97;i&#108;t&#111;:p&#109;s@&#117;&#112;sh&#97;&#114;&#101;&#46;&#99;&#111;&#46;&#106;&#112;">pms＠(アットマーク)upshare.co.jp</a><br></br> TEL：<a href="tel:05054975109">050-5497-5109</a> （受付時間　11:00～17:00）<br></br> FAX：03-3546-9774 </div>
	  	</div>
	  	<div className="separator my-10"></div>
	  	<h2 className="fs-2x w-bolder mb-6">保有個人データに関する事項の周知について</h2>
	  	<figure className="text-end">
	  		<blockquote className="blockquote">
			  <p className='fs-6'>(JIS Q 15001:2017)<br></br>PF-102-2<br></br>制定日　2020年11月11日<br></br>最終改正日　2020年11月11日</p>
	  		</blockquote>
	  	</figure>
	  	<div className='mb-10'>株式会社アップシェア（以下、「当社」という。）の保有個人データ（当社が、利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止を行う権限を有するもの）は、以下の通りです。</div>
	  	<div>(1)個人情報に関する管理者の氏名、所属及び連絡先</div>
	  	<div className='mb-10'>管理者名：個人情報保護管理者<br></br>役職名：株式会社アップシェア　情報システム部部長<br></br>連絡先：電話<a href="tel:05054975109">050-5497-5109</a>
	  	</div>
	  	<div className='mb-10'>(2)保有個人データの利用目的</div>
	  	<ul className='mb-10'>
	  		<li>お客様の個人情報は、インターネット関連事業におけるサービスの提供、新たなサービスのご案内のため</li>
	  		<li>当社の各事業に関するお問い合わせの方の個人情報は、お問い合わせにお答えするため</li>
	  		<li>当社従業者の個人情報は、人事労務管理、業務管理、健康管理、セキュリティ管理等のため</li>
	  		<li>当社への直接応募者情報は、採用業務のため</li>
	  	</ul>
	  	<div className='mb-10'>(3)保有個人データに関する苦情の申し出先</div>
	  	{/* <div className='card p-9 mb-10'> */}
		  <div className='card mb-9 p-6 border border-secondary border-2'>
	  		<div className='fs-5 fw-bold mb-3'>【個人情報保護方針に関するお問い合わせ先 兼 個人情報に関する苦情・相談窓口】</div>
	  		<div>〒150-0002 東京都渋谷区渋谷一丁目12番8号　ILA渋谷美竹ビル3F<br></br> 株式会社アップシェア　個人情報に関するお問い合わせ窓口<br></br> <a href="m&#97;i&#108;t&#111;:p&#109;s@&#117;&#112;sh&#97;&#114;&#101;&#46;&#99;&#111;&#46;&#106;&#112;">pms＠(アットマーク)upshare.co.jp</a><br></br> TEL：<a href="tel:05054975109">050-5497-5109</a> （受付時間　11:00～17:00）<br></br> FAX：03-3546-9774 </div>
	  	</div>
	  	<div>(4)当社の加入する認定個人情報保護団体について</div>
	  	<div className='mt-10'>当社の加入する認定個人情報保護団体はありません。</div>
	  	<div className="separator my-10"></div>
	  	<h2 className="fs-2x w-bolder mb-6">保有個人データの開示等に関する手続き</h2>
	  	<figure className="text-end">
	  		<blockquote className="blockquote">
			  <p className='fs-6'>(JIS Q 15001:2017)<br></br>PF-102-3<br></br>制定日　2020年11月11日<br></br>最終改正日　2020年11月11日</p>
	  		</blockquote>
	  	</figure>
	  	<div className='mb-10'>保有個人データについて、「利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止」（開示等）のご請求が出来ます。当社では、次の手順で個人情報の開示等の求めに対応いたします。</div>
	  	<div className='fs-3 mb-1'>1．開示等の求めの受付</div>
	  	<div className='mb-10'>（1）下記の宛先までご連絡ください。<br></br>当社より「所定の用紙」を郵送いたしますので、到着後、当社の「個人情報の取扱いについて」に同意後、以下の宛先まで郵送またはご持参ください。 <div className='mt-10'>〒150-0002 東京都渋谷区渋谷一丁目12番8号　ILA渋谷美竹ビル3F<br></br>株式会社アップシェア　個人情報に関するお問い合わせ窓口<br></br>メールアドレス：<a href="m&#97;i&#108;t&#111;:p&#109;s@&#117;&#112;sh&#97;&#114;&#101;&#46;&#99;&#111;&#46;&#106;&#112;">p&#109;s@&#117;&#112;sh&#97;&#114;&#101;&#46;&#99;&#111;&#46;&#106;&#112;</a>
<br></br>TEL：<a href="tel:05054975109">050-5497-5109</a> （受付時間　11:00～17:00）</div>
	  	</div>
	  	<div className='mb-10'>（2）本人確認のため、以下の書類いずれか1通を(1)「所定の用紙」とともに郵送またはご持参ください。 
			<ul className='mb-10'>
	  			<li>運転免許証、パスポート等の公的証明書で本人確認ができるものの写し（開示等の求めをする本人の名前および住所が記載されているもの）</li>
	  			<li>住民票の写し（開示等の求めをする日前30日以内に作成されたもの）</li>
	  		</ul>
	  	</div>
	  	<div className='mb-10'>(3)代理人の方が手続きをされる場合は、（1）「所定の用紙」、（2）「本人確認書類」に加え、以下のア）のいずれか一つおよびイ）の書類も郵送またはご持参ください。</div>
	  	<div className='mb-10'>ア）代理人の本人確認書類 
			<ul className='mb-10'>
	  			<li>代理人を証明する書類、代理人の運転免許証、パスポート等の公的証明書で代理人確認ができるものの写し（開示等の求めをする代理人の名前および住所が記載されているもの）</li>
	  			<li>代理人の住民票の写し（開示等の求めをする日前30日以内に作成されたもの）</li>
	  			<li>代理人が弁護士の場合は、登録番号のわかる書類</li>
	  		</ul>
	  	</div>
	  	<div>イ）代理権を証する書面（委任状など） 
			<ul className='mb-10'>
	  			<li>本人または代理人を証明する書類に、本籍地が明示されている場合は、消去していただいて結構です。</li>
	  			<li>本人または代理人を証明する書類は、当社が入手してから2か月以内に責任を持って廃棄いたします。</li>
	  			<li>お客様の個人情報は、インターネット関連事業におけるサービスの提供、新たなサービスのご案内のため</li>
	  			<li>当社の各事業に関するお問い合わせの方の個人情報は、お問い合わせにお答えするため</li>
	  			<li>当社従業者の個人情報は、人事労務管理、業務管理、健康管理、セキュリティ管理等のため</li>
	  			<li>当社への直接応募者情報は、採用業務のため</li>
	  		</ul>
	  	</div>
	  	<div className='fs-3 mb-1'>2．「開示等の請求」の手数料及びその徴収方法</div>
	  	<div className='mb-10'>利用目的の通知又は開示の請求の場合にのみ、１回の請求につき、以下の金額（当社からの返信費を含む）を申し受けます。下記金額分の郵便定額小為替を請求書類の郵送時に同封してください。<br></br>・手数料金額：1,000円（税込）</div>
	  	<div className='fs-3 mb-1'>3．検討結果連絡</div>
	  	<ul className='mb-10'>
	  		<li>個人情報の利用目的の通知、開示の場合は、書面の郵送または手渡し</li>
	  		<li>個人情報の内容の訂正、通知または削除、利用の停止、消去および第三者への提供の停止の場合は、電話またはメール</li>
	  	</ul>
	  	<div className='mb-10'>書面の送付先は、以下のいずれかの適切な方法を選択し行います。</div>
	  	<ul>
	  		<li>登録されている本人住所に回答文面を郵送する。</li>
	  		<li>登録されている本人のFAX番号に回答文面をFAXする。</li>
	  		<li>登録されている本人のＥメールアドレスに回答文面をメールする。</li>
	  		<li>登録されている本人の電話番号に電話をかけ、口頭にて回答する。</li>
	  	</ul>
	  	<div className='d-flex justify-content-end'>以上</div>
	 </div>
    </>
    )
}

export default function Privacy() {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>プライバシーポリシー</PageTitle>
      <Pageconts />
    </>
  )
}
