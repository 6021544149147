import {FC} from 'react'
import {Link, useNavigate} from 'react-router-dom'

const Error500: FC = () => {

  const navigate = useNavigate()

  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx mb-6'>エラーが発生しました</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 mb-3'>処理中にエラーが発生しました</div>
      <div className='fw-semibold fs-6 mb-7'>しばらくしてからもう一度お試しください。それでも解決しない場合は、サポートまでお問い合わせください。</div>
      {/* end::Text */}

      {/* begin::Link */}
      <div className='mb-0'>

        <button
          className='btn btn-sm btn-primary me-3'
          onClick={() => {
            navigate(-2)
          }}
        >
          戻る
        </button>

        <Link to='/dashboard' className='btn btn-sm btn-primary'>
          ダッシュボードへ戻る
        </Link>
      </div>
      {/* end::Link */}
    </>
  )
}

export {Error500}
