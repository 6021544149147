import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from '@/app/components/sweetAlert'
import {useNavigate} from 'react-router-dom'
import {message} from 'antd'
import {getAction, getBuyerInfo} from 'src/app/modules/api'
import { useAuth } from 'src/app/modules/auth'

export default function PlanInput() {
  const navigate = useNavigate()
  const paymentMethodDictionary = {
    credit_card: 'クレジットカード',
    bank_transfer: '銀行振込',
    bill_payment: '請求書払い',
  }
  const physicalStoreDictionary = {
    '1': 'あり',
    '2': 'なし',
  }
  const emailNotificationsDictionary = {
    '1': '希望する',
    '2': '希望しない',
  }
  const addressDictionary = {
    'JP-01': '北海道',
    'JP-02': '青森県',
    'JP-03': '岩手県',
    'JP-04': '宮城県',
    'JP-05': '秋田県',
    'JP-06': '山形県',
    'JP-07': '福島県',
    'JP-08': '茨城県',
    'JP-09': '栃木県',
    'JP-10': '群馬県',
    'JP-11': '埼玉県',
    'JP-12': '千葉県',
    'JP-13': '東京都',
    'JP-14': '神奈川県',
    'JP-15': '新潟県',
    'JP-16': '富山県',
    'JP-17': '石川県',
    'JP-18': '福井県',
    'JP-19': '山梨県',
    'JP-20': '長野県',
    'JP-21': '岐阜県',
    'JP-22': '静岡県',
    'JP-23': '愛知県',
    'JP-24': '三重県',
    'JP-25': '滋賀県',
    'JP-26': '京都府',
    'JP-27': '大阪府',
    'JP-28': '兵庫県',
    'JP-29': '奈良県',
    'JP-30': '和歌山県',
    'JP-31': '鳥取県',
    'JP-32': '島根県',
    'JP-33': '岡山県',
    'JP-34': '広島県',
    'JP-35': '山口県',
    'JP-36': '徳島県',
    'JP-37': '香川県',
    'JP-38': '愛媛県',
    'JP-39': '高知県',
    'JP-40': '福岡県',
    'JP-41': '佐賀県',
    'JP-42': '長崎県',
    'JP-43': '熊本県',
    'JP-44': '大分県',
    'JP-45': '宮崎県',
    'JP-46': '鹿児島県',
    'JP-47': '沖縄県',
  }

  const [initialValues, setInitialValues] = useState<any>({
    corporation_or_individual: '',
    company_name: '',
    shop_name: '',
    shop_name_kana: '',
    representative_name: '',
    headoffice_address_postal_code: '',
    headoffice_address_prefecture: '',
    headoffice_address_city: '',
    headoffice_address_street: '',
    headoffice_address_building_name: '',
    corporate_number: '',
    invoice_number: '',
    establishment_date: '',
    fiscal_year_end: '',
    annual_sales: '',
    regular_holiday: '',
    business_hours: '',

    main_genre: '',
    secondary_genre: '',
    business_description: '',
    shop_introduction: '',
    primary_contact_email: '',
    person_in_charge: '',
    person_in_charge_kana: '',
    person_in_charge_email: '',
    person_in_charge_phone: '',
    person_in_charge_phone_secondary: '',
    contact_person: '',
    contact_person_kana: '',
    contact_person_email: '',
    contact_person_phone: '',
    contact_person_phone_secondary: '',
    referrer: '',
    initCost: '1',
    termsOfApplication: '0',
    privacyPolicy: '0',
  })
  let arr: any = JSON.parse(
    JSON.stringify({
      // "customer_id": "",
      // "company_name": "",
      // "representative": "",
      // "department_name": "",
      // "contact_person": "",
      // "contact_person_kana": "",
      // "main_email_address": "",
      // "address_postal_code": "",
      // "address_prefecture": "",
      // "address_city": "",
      // "address_street": "",
      // "address_building_name": "",
      // "telephone_number": "",
      // "mobile_phone_number": "",
      // "fax_number": "",
      // "payment_method": "",
      // "establishment_date": "",
      // "actual_industry": "",
      // "website_url": "",
      // "physical_store": "",
      // "email_notifications": "",
      // "user_id": "",
      // "referrer": "",
      // "user_image": ""
      customer_id: '',
      company_name: '',
      representative: '',
      department_name: '',
      contact_person: '',
      contact_person_kana: '',
      main_email_address: '',
      address_postal_code: '',
      address_prefecture: '',
      address_city: '',
      address_street: '',
      address_building_name: '',
      telephone_number: '',
      mobile_phone_number: '',
      fax_number: '',
      payment_method: '',
      establishment_date: '',
      actual_industry: '',
      website_url: '',
      physical_store: '',
      email_notifications: '',
    })
  )
  const regionSettingTimeOptions: any = [
    {name: '北海道', value: 'JP-01'},
    {name: '青森県', value: 'JP-02'},
    {name: '岩手県', value: 'JP-03'},
    {name: '宮城県', value: 'JP-04'},
    {name: '秋田県', value: 'JP-05'},
    {name: '山形県', value: 'JP-06'},
    {name: '福島県', value: 'JP-07'},
    {name: '茨城県', value: 'JP-08'},
    {name: '栃木県', value: 'JP-09'},
    {name: '群馬県', value: 'JP-10'},
    {name: '埼玉県', value: 'JP-11'},
    {name: '千葉県', value: 'JP-12'},
    {name: '東京都', value: 'JP-13'},
    {name: '神奈川県', value: 'JP-14'},
    {name: '新潟県', value: 'JP-15'},
    {name: '富山県', value: 'JP-16'},
    {name: '石川県', value: 'JP-17'},
    {name: '福井県', value: 'JP-18'},
    {name: '山梨県', value: 'JP-19'},
    {name: '長野県', value: 'JP-20'},
    {name: '岐阜県', value: 'JP-21'},
    {name: '静岡県', value: 'JP-22'},
    {name: '愛知県', value: 'JP-23'},
    {name: '三重県', value: 'JP-24'},
    {name: '滋賀県', value: 'JP-25'},
    {name: '京都府', value: 'JP-26'},
    {name: '大阪府', value: 'JP-27'},
    {name: '兵庫県', value: 'JP-28'},
    {name: '奈良県', value: 'JP-29'},
    {name: '和歌山県', value: 'JP-30'},
    {name: '鳥取県', value: 'JP-31'},
    {name: '島根県', value: 'JP-32'},
    {name: '岡山県', value: 'JP-33'},
    {name: '広島県', value: 'JP-34'},
    {name: '山口県', value: 'JP-35'},
    {name: '徳島県', value: 'JP-36'},
    {name: '香川県', value: 'JP-37'},
    {name: '愛媛県', value: 'JP-38'},
    {name: '高知県', value: 'JP-39'},
    {name: '福岡県', value: 'JP-40'},
    {name: '佐賀県', value: 'JP-41'},
    {name: '長崎県', value: 'JP-42'},
    {name: '熊本県', value: 'JP-43'},
    {name: '大分県', value: 'JP-44'},
    {name: '宮崎県', value: 'JP-45'},
    {name: '鹿児島県', value: 'JP-46'},
    {name: '沖縄県', value: 'JP-47'},
  ]

  const {auth} = useAuth()

  const validationSchema: any = Yup.object({
    // company_name: Yup.string().required('会社名は必須です。'),
  })
  const formik: any = useFormik({
    initialValues: initialValues,
    // validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      if (formik.values.termsOfApplication == '0' || formik.values.privacyPolicy == '0') {
        showErrorMessage('HatChuPay規約にご同意の上、お進みください。', '閉じる')
        return
      }
      setSubmitting(true)
      try {
        localStorage.setItem('accountFormStorageInformation', JSON.stringify(formik.values))
        localStorage.setItem('setMeal', JSON.stringify(chooseASetMeal))
        navigate('/plan/payment', {state: {...values}})
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })
  const updateData = (fieldsToUpdate: Partial<any>) => {
    const updatedData = {...initialValues, ...fieldsToUpdate}
    setInitialValues(updatedData)
    formik.setValues(updatedData)
  }

  const [chooseASetMeal, setChooseASetMeal] = useState<any>({})
  const [fullSetMeal, setFullSetMeal] = useState<any>([])

  const setMealReset = () => {
    // localStorage.removeItem('setMeal')
    // localStorage.removeItem('allMealList')
    // localStorage.removeItem('accountFormStorageInformation')
    console.log(formik.values)
    localStorage.setItem('accountFormStorageInformation', JSON.stringify(formik.values))
    navigate('/plan')
  }

  const init = () => {
    console.log('init------------')
    let setMeal = localStorage.getItem('setMeal')
    let allMeal = localStorage.getItem('allMealList')

    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token}`
    }


    async function getTheBuyerInfo() {
      let objGet: any = {}
      if (auth.role.includes('buyer')) {
        objGet = await getBuyerInfo({}, headers)
      }else {
        objGet = await getAction("auth.username", headers)
      }
      // setproductCustomFieldOptions(ListGet.rows)
      console.log('objGet', objGet)
      // setUserdata(objGet)

      let accountFormStorageInformation = localStorage.getItem('accountFormStorageInformation')

      if (accountFormStorageInformation) {
        updateData(JSON.parse(accountFormStorageInformation))
      } else {
        updateData(objGet)
      }
    }
    getTheBuyerInfo()

    if (setMeal) {
      console.log(JSON.parse(setMeal ? setMeal : ''))
      console.log(JSON.parse(allMeal ? allMeal : ''))

      setChooseASetMeal(JSON.parse(setMeal))

      if (allMeal) {
        setFullSetMeal(JSON.parse(allMeal))
      }
    } else {
      showErrorMessage('コース未選択', () => {
        localStorage.removeItem('setMeal')
        localStorage.removeItem('allMealList')
        localStorage.removeItem('accountFormStorageInformation')
        navigate(`/plan`)
      })
    }
  }

  useEffect(() => {
    if (auth.role.includes('buyer')) {
      setInitialValues({...initialValues, ...arr})
    }
    init()
  }, [])

  return (
      <div>
        <div className='container-fluid'>
          <div className='card mb-6 py-6 shadow-sm'>
            <div className='text-center'>
              <h1 className='fs-2hx fw-bold mb-5'>アカウントお申込みフォーム</h1>
              <div className='fw-semibold fs-5'>必要事項をご入力ください。</div>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            {auth.role.includes('buyer')?(
                <>
                <div className='card mb-6 shadow-sm'>
                  <div className='d-flex align-items-center card-header'>
                    <span className='card-title fw-bold m-0'>基本情報</span>
                  </div>
                  <div className='card-body'>
                {/* <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 '>アカウントID</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        placeholder='顧客ID'
                        {...formik.getFieldProps('customer_id')}
                      />
                      {formik.errors.customer_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.customer_id}</div>
                        </div>
                    )}
                  </div>
                </div> */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 '>会社名</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        placeholder='会社名'
                        {...formik.getFieldProps('company_name')}
                      />
                      {formik.errors.company_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.company_name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>代表者</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='代表者'
                        {...formik.getFieldProps('representative')}
                      />
                      {formik.errors.representative && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.representative}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>担当部署名</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当部署名'
                        {...formik.getFieldProps('department_name')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>担当者</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者'
                        {...formik.getFieldProps('contact_person')}
                      />
                      {formik.errors.contact_person && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.contact_person}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>
                      担当者　フリガナ
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者　フリガナ'
                        {...formik.getFieldProps('contact_person_kana')}
                      />
                      {formik.errors.contact_person_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.contact_person_kana}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>
                      担当者　メールアドレス
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者　メールアドレス'
                        {...formik.getFieldProps('main_email_address')}
                      />
                      {formik.errors.main_email_address && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.main_email_address}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>所在地 郵便番号</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='住所郵便番号'
                        {...formik.getFieldProps('address_postal_code')}
                      />
                      {formik.errors.address_postal_code && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.address_postal_code}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6 '>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>所在地 都道府県</label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select form-select-lg form-control bg-light'
                        placeholder='住所都道府県'
                        {...formik.getFieldProps('address_prefecture')}
                      >
                        {Object.entries(addressDictionary).map(([key, value]) => (
                          <option key={key} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                      {formik.errors.address_prefecture && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.address_prefecture}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>所在地 市区町村</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='住所市区町村'
                        {...formik.getFieldProps('address_city')}
                      />
                      {formik.errors.address_city && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.address_city}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>所在地 丁目番地</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='住所丁目番地'
                        {...formik.getFieldProps('address_street')}
                      />
                      {formik.errors.address_street && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.address_street}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>所在地 建物名</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='住所建物名'
                        {...formik.getFieldProps('address_building_name')}
                      />
                      {formik.errors.address_building_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.address_building_name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>電話番号</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='電話番号'
                        {...formik.getFieldProps('telephone_number')}
                      />
                      {formik.errors.telephone_number && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.telephone_number}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>携帯番号</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='携帯番号'
                        {...formik.getFieldProps('mobile_phone_number')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>FAX番号</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='FAX番号'
                        {...formik.getFieldProps('fax_number')}
                    />
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 '>決済方法</label>
                  <div className='col-lg-8 fv-row'>
                    <select
                        className='form-select form-select-lg form-control-solid form-control bg-light'
                        placeholder='決済方法'
                        {...formik.getFieldProps('payment_method')}
                    >
                      {Object.entries(paymentMethodDictionary).map(([key, value]) => (
                          <option key={key} value={value}>
                            {value}
                          </option>
                      ))}
                    </select>

                    {formik.errors.payment_method && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.payment_method}</div>
                        </div>
                    )}
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 '>設立年月</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                        className='form-control form-control-lg form-control-solid'
                        type='text'
                        placeholder='設立年月'
                        {...formik.getFieldProps('establishment_date')}
                      />
                      {formik.errors.establishment_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.establishment_date}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>実態/業種</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='実態/業種'
                        {...formik.getFieldProps('actual_industry')}
                      />
                      {formik.errors.actual_industry && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.actual_industry}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>ホームページURL</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='ホームページURL'
                        {...formik.getFieldProps('website_url')}
                      />
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>実店舗</label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select form-select-lg form-control bg-light'
                        placeholder='実店舗'
                        {...formik.getFieldProps('physical_store')}
                      >
                        {Object.entries(physicalStoreDictionary).map(([key, value]) => (
                          <option key={key} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>

                      {formik.errors.physical_store && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.physical_store}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 '>メール案内</label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select form-select-lg form-control bg-light'
                        placeholder='メール案内'
                        {...formik.getFieldProps('email_notifications')}
                      >
                        {Object.entries(emailNotificationsDictionary).map(([key, value]) => (
                          <option key={key} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>

                      {formik.errors.email_notifications && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.email_notifications}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='card mb-6 shadow-sm'>
                <div className='d-flex align-items-center card-header'>
                  <span className='card-title fw-bold m-0'>基本情報</span>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col'>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>法人・個人</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='法人・個人'
                            {...formik.getFieldProps('corporation_or_individual')}
                            value={formik.values.corporation_or_individual}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label '>
                            <span>会社名</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='会社名'
                            {...formik.getFieldProps('company_name')}
                            value={formik.values.company_name}
                          />
                          {formik.touched.company_name && formik.errors.company_name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.company_name}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>お店屋号</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='お店屋号'
                            {...formik.getFieldProps('shop_name')}
                            value={formik.values.shop_name}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>お店屋号（カナ）</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='お店屋号（カナ）'
                            {...formik.getFieldProps('shop_name_kana')}
                            value={formik.values.shop_name_kana}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>代表者名</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='代表者名'
                            {...formik.getFieldProps('representative_name')}
                            value={formik.values.representative_name}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>本社所在地 郵便番号</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='本社所在地 郵便番号'
                            {...formik.getFieldProps('headoffice_address_postal_code')}
                            value={formik.values.headoffice_address_postal_code}
                          />
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>本社所在地 都道府県</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <select
                            className='form-select'
                            data-control='selectAdress'
                            value={formik.values.headoffice_address_prefecture}
                            // style={{width: 180}}
                            onChange={(e) => {
                              formik.values.headoffice_address_prefecture = e.target.value
                              updateData(formik.values)
                            }}
                          >
                            <option value=''> </option>
                            {regionSettingTimeOptions.map((i: any) => {
                              return (
                                <option value={i.name} key={i.value}>
                                  {i.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>本社所在地 市区町村</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='本社所在地 市区町村'
                            {...formik.getFieldProps('headoffice_address_city')}
                            value={formik.values.headoffice_address_city}
                          />
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>本社所在地 丁目番地</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='本社所在地 丁目番地'
                            {...formik.getFieldProps('headoffice_address_street')}
                            value={formik.values.headoffice_address_street}
                          />
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>本社所在地 建物名</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='本社所在地 建物名'
                            {...formik.getFieldProps('headoffice_address_building_name')}
                            value={formik.values.headoffice_address_building_name}
                          />
                        </div>
                      </div>

                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>法人番号</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='法人番号'
                            {...formik.getFieldProps('corporate_number')}
                            value={formik.values.corporate_number}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>適格請求書発行事業者登録番号</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='適格請求書発行事業者登録番号'
                            {...formik.getFieldProps('invoice_number')}
                            value={formik.values.invoice_number}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>設立</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='設立'
                            {...formik.getFieldProps('establishment_date')}
                            value={formik.values.establishment_date}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>決算期</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='決算期'
                            {...formik.getFieldProps('fiscal_year_end')}
                            value={formik.values.fiscal_year_end}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>年商</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='年商'
                            {...formik.getFieldProps('annual_sales')}
                            value={formik.values.annual_sales}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>定休日</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='定休日'
                            {...formik.getFieldProps('regular_holiday')}
                            value={formik.values.regular_holiday}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3 col-xxl-3 align-items-center align-self-center'>
                          <label className='col-form-label'>
                            <span>営業時間</span>
                          </label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='営業時間'
                            {...formik.getFieldProps('business_hours')}
                            value={formik.values.business_hours}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card mb-6 shadow-sm'>
                <div className='d-flex align-items-center card-header'>
                  <span className='card-title fw-bold m-0'>その他情報</span>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col'>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>メインの取り扱いジャンル</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='メインの取り扱いジャンル'
                            {...formik.getFieldProps('main_genre')}
                            value={formik.values.main_genre}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>その他の取り扱いジャンル</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='その他の取り扱いジャンル'
                            {...formik.getFieldProps('secondary_genre')}
                            value={formik.values.secondary_genre}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>事業内容</label>
                        </div>
                        <div className='col align-self-center'>
                          <textarea
                            className='form-control'
                            rows={5}
                            {...formik.getFieldProps('business_description')}
                            value={formik.values.business_description}
                          ></textarea>
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>会社紹介文</label>
                        </div>
                        <div className='col align-self-center'>
                          <textarea
                            className='form-control'
                            rows={5}
                            {...formik.getFieldProps('shop_introduction')}
                            value={formik.values.shop_introduction}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card mb-6 shadow-sm'>
                <div className='d-flex align-items-center card-header'>
                  <span className='card-title fw-bold m-0'>連絡先情報</span>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col'>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>メインの連絡先メールアドレス</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='メインの連絡先メールアドレス'
                            {...formik.getFieldProps('primary_contact_email')}
                            value={formik.values.primary_contact_email}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>責任者</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='責任者'
                            {...formik.getFieldProps('person_in_charge')}
                            value={formik.values.person_in_charge}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>責任者フリガナ</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='責任者フリガナ'
                            {...formik.getFieldProps('person_in_charge_kana')}
                            value={formik.values.person_in_charge_kana}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>責任者　メールアドレス</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='責任者　メールアドレス'
                            {...formik.getFieldProps('person_in_charge_email')}
                            value={formik.values.person_in_charge_email}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>責任者　電話番号</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='責任者　電話番号'
                            {...formik.getFieldProps('person_in_charge_phone')}
                            value={formik.values.person_in_charge_phone}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>責任者　電話番号２</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='責任者　電話番号２'
                            {...formik.getFieldProps('person_in_charge_phone_secondary')}
                            value={formik.values.person_in_charge_phone_secondary}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>担当者</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='担当者'
                            {...formik.getFieldProps('contact_person')}
                            value={formik.values.contact_person}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>担当者フリガナ</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='担当者フリガナ'
                            {...formik.getFieldProps('contact_person_kana')}
                            value={formik.values.contact_person_kana}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>担当者　メールアドレス</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='担当者　メールアドレス'
                            {...formik.getFieldProps('contact_person_email')}
                            value={formik.values.contact_person_email}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>担当者　電話番号</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='担当者　電話番号'
                            {...formik.getFieldProps('contact_person_phone')}
                            value={formik.values.contact_person_phone}
                          />
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>担当者　電話番号２</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='担当者　電話番号２'
                            {...formik.getFieldProps('contact_person_phone_secondary')}
                            value={formik.values.contact_person_phone_secondary}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card mb-6 shadow-sm'>
                <div className='d-flex align-items-center card-header'>
                  <span className='card-title fw-bold m-0'>その他</span>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col'>
                      <div className='row mb-6'>
                        <div className='col-3'>
                          <label className='col-form-label'>紹介者</label>
                        </div>
                        <div className='col align-self-center'>
                          <input
                            className='form-control'
                            type='text'
                            placeholder='紹介者'
                            {...formik.getFieldProps('referrer')}
                            value={formik.values.referrer}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className='card mb-6 shadow-sm'>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>ご契約プラン</span>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <div className='row mb-6'>
                    <div className='col-12'>
                      <label className='col-form-label fs-3 fw-bold'>初期費用</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row' style={{maxWidth: 480}}>
                <div data-kt-buttons='true'>
                  <label className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5 active'>
                    <div className='d-flex align-items-center me-2'>
                      <div className='form-check form-check-custom form-check-primary me-6'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='plan'
                          value={formik.values.initCost}
                          checked={formik.values.initCost === '1'}
                          {...formik.getFieldProps('initCost')}
                        />
                      </div>
                      <div className='flex-grow-1'>
                        <h2 className='d-flex align-items-center fs-4 fw-bold flex-wrap'>
                          初期費用
                        </h2>
                        <div className='fw-semibold opacity-50'>初期にかかる費用です。</div>
                      </div>
                    </div>
                    <div className='ms-5'>
                      <span className='fs-2x fw-bold'>{chooseASetMeal.initial_fee}円</span>
                      <span className='fs-7 opacity-50'>
                        /<span data-kt-element='period'>初回</span>
                      </span>
                    </div>
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='row mb-6'>
                    <div className='col-12'>
                      <label className='col-form-label fs-3 fw-bold'>ご選択プラン</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div data-kt-buttons='true' className='d-flex flex-wrap'>
                  {fullSetMeal.length !== 0 &&
                    fullSetMeal.map((item: any, index: number) => {
                      return (
                        <label
                          key={index}
                          className={
                            chooseASetMeal.pk == item.pk
                              ? 'btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5 mx-3 active'
                              : 'btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5 mx-3'
                          }
                        >
                          <div className='d-flex align-items-center me-2'>
                            <div className='form-check form-check-custom form-check-primary me-6'>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='plan1'
                                value={chooseASetMeal.pk}
                                checked={chooseASetMeal.pk == item.pk ? true : false}
                                onChange={(e) => {
                                  setChooseASetMeal(item)
                                }}
                              />
                            </div>
                            <div className='flex-grow-1'>
                              <h2 className='d-flex align-items-center fs-4 fw-bold flex-wrap'>
                                {item.name}
                              </h2>
                              <div className='fw-semibold opacity-50'>{item.name}</div>
                            </div>
                          </div>
                          <div className='ms-5'>
                            <span className='fs-2x fw-bold'>
                              {' '}
                              {item.fee * (Number(item.tax_rate) / 100) + Number(item.fee)}
                            </span>
                            <span className='mb-2'>円</span>
                            <span className='fs-7 opacity-50'>
                              /
                              <span data-kt-element='period'>
                                {item.duration == '毎月' ? '月' : '年'}
                              </span>
                            </span>
                          </div>
                        </label>
                      )
                    })}
                </div>
              </div>
            </div>
            <div className='row text-end mt-6 mb-0 me-10'>
              <p>
                ※プラン内容のご確認は
                <a
                  style={{textDecoration: 'underline'}}
                  onClick={() => {
                    setMealReset()
                  }}
                  className='menu-link'
                >
                  こちら
                </a>
              </p>
            </div>
          </div>

          <div className='card mb-6 shadow-sm'>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>HatChuPay規約</span>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <div className='row mb-6'>
                    <div>
                      <label className='col-form-label fs-3 fw-bold'>契約に関する規約</label>
                      <p>お申込みに関する規約の同意が必要です。ご利用規約をご確認ください。</p>                     
                      
                    </div>
                    <div>
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => {
                            // navigate('/static/ec/terms')
                            window.open('/terms', '_blank')
                        }}
                      >
                        ご利用規約
                      </button>
                    </div>
                    <div className='form-check ms-5 mt-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value={formik.values.termsOfApplication}
                        checked={formik.values.termsOfApplication === '1' ? true : false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            formik.values.termsOfApplication = '1'
                            updateData(formik.values)
                          } else {
                            formik.values.termsOfApplication = '0'
                            updateData(formik.values)
                          }
                        }}
                      />
                      <label className='form-check-label'>同意する</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='row mb-6'>
                    <div>
                      <label className='col-form-label fs-3 fw-bold'>
                        個人情報保護方針について
                      </label>
                      <p>個人情報保護方針についての同意が必要です。プライバシーポリシーをご確認ください。</p>
                    </div>
                    <div>
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => {
                            // navigate('/static/ec/privacy')
                            window.open('/privacy', '_blank')
                        }}
                      >
                        プライバシーポリシー
                      </button>
                    </div>
                    <div className='form-check ms-5 mt-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value={formik.values.privacyPolicy}
                        checked={formik.values.privacyPolicy === '1' ? true : false}
                        onChange={(e) => {
                          if (e.target.checked) {
                            formik.values.privacyPolicy = '1'
                            updateData(formik.values)
                          } else {
                            formik.values.privacyPolicy = '0'
                            updateData(formik.values)
                          }
                        }}
                      />
                      <label className='form-check-label'>同意する</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{display: 'flex', justifyContent: 'center'}}>
            <button className='btn btn-primary' type='submit'>
              次のページへ進む
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
