import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {getBuyerInfo} from 'src/app/modules/api'
import {toAbsoluteUrl} from '@/_metronic/helpers'
import {formatNumber} from 'src/app/modules/utils'

export default function LaterPaymentComplete() {
  // 決済可能額
  const [laterPaymentAmount, setLaterPaymentAmount] = useState(0)
  const [latterPayments2, setlatterPayments2] = useState(0)
  const [displayid, setDisplayid] = useState(0)
  const [displayidErr, setDisplayidErr] = useState(0)

  useEffect(() => {
    getBuyerInfo({}).then((res: any) => {
      const payments: any = JSON.parse(localStorage.getItem('payments') as any)
      const msgError: any = JSON.parse(localStorage.getItem('msgError') as any)
      if (payments.length > 0) {
        let displayid = payments.map((item: any) => item.display_id).join()
        setDisplayid(displayid)
      }
      if (msgError.length > 0) {
        setDisplayidErr(msgError.join())
      }
      if (payments.length > 0) {
        let paymentAmount = payments
          .map((item: any) => {
            if (item.payment_status == 'later_payment') {
              return item.payment_amount
            }
          })
          .filter(Number)
        console.log('paymentAmount', paymentAmount)
        if (paymentAmount.length > 0) {
          let total = paymentAmount.reduce((prev: any, next: any) => prev + next)
          setLaterPaymentAmount(total)
        }
      }
    })
  }, [])
  const navigate = useNavigate()

  return (
    <>
      <div>
        <div className='card' style={{textAlign: 'center', padding: 30, boxSizing: 'border-box'}}>
          <h1 className='fw-bolder fs-6 '>
            いつもお仕事お疲れ様です
            <br />
            <span className='mt-3 d-inline-block'>決済処理が完了致しました。</span>
          </h1>
          <div className='w-250px my-6 mx-auto'>
            <img
              alt=''
              src={toAbsoluteUrl('/media/cart/super_thank_you.svg')}
              className='h-100 w-100'
            />
          </div>

          {/* <h1 className='fw-bolder fs-6 mb-6'>注文番号：{displayid}</h1>
                {displayidErr != 0 &&
                    <h1 className='fw-bolder fs-6 mb-6'>注文番号：{displayidErr}</h1>
                } */}
          {!!laterPaymentAmount && (
            <h1 className='fw-bolder fs-6 mb-6'>決済金額：{formatNumber(laterPaymentAmount)}円</h1>
          )}

          <button
            className='btn btn-primary w-200px'
            style={{margin: 'auto'}}
            onClick={() => {
              navigate('/orderhistory')
            }}
          >
            ご注文履歴を見る
          </button>
        </div>
      </div>
    </>
  )
}
