import {MouseEventHandler, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from '../../auth'
import {ForgotPasswordVerificationAndNewPassword} from './ForgotPasswordVerificationAndNewPassword'
import {Spin} from 'antd'

const local_URL = process.env.REACT_APP_LOCAL_URL

const initialValues = {
  //   email: 'admin@demo.com',
  username: '',
}

const forgotPasswordSchema = Yup.object().shape({
  username: Yup.string()
    .email('メールアドレスのフォーマットが正しくありません')
    .required('メールアドレスは必須です'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const {auth} = useAuth()
  const [phase, setPhase] = useState('initial')
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const location = useLocation()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const result = await auth.forgotPassword(values.username)
        if (result.success) {
          console.log(result)
          // navigate({ pathname: '/dashboard' });
          setPhase('inputVerificationCode')
        } else {
          // alert(result.message);
          throw new Error(result.message)
        }

        setLoading(false)
      } catch (error) {
        const typedError = error as Error
        console.error(typedError)
        setStatus(typedError.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      {phase === 'initial' && (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px'>
            <div className='d-flex flex-stack py-2'>
              <div className='w-100 separator separator-content my-6'>
                <span className='fw-bold fs-4' style={{width: '30em'}}>
                  パスワードリセット
                </span>
              </div>
            </div>
            <div className='pb-0'>
              {location?.state?.message && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                  <div className='text-info'>{location.state.message}</div>
                </div>
              )}

              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}

              <div className='fv-row mb-5'>
                <label className='mb-1'>メールアドレス</label>
                <input
                  type='email'
                  placeholder=''
                  autoComplete='off'
                  {...formik.getFieldProps('username')}
                  className={clsx(
                    'form-control bg-transparent',
                    {'is-invalid': formik.touched.username && formik.errors.username},
                    {
                      'is-valid': formik.touched.username && !formik.errors.username,
                    }
                  )}
                />
                {formik.touched.username && formik.errors.username && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.username}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='d-flex flex-stack mb-4'>
                <div className='col-12 col-lg-6 offset-lg-3'>
                  <Spin spinning={loading}>
                    <button
                      id='kt_sign_in_submit'
                      className='btn btn-primary w-100 me-2 flex-shrink-0'
                      // onClick={() => loginWithRedirect()}
                      // onClick={() => signUp()}
                    >
                      <span className='indicator-label'>再設定用メールを送る</span>
                    </button>
                  </Spin>
                </div>
              </div>

              <div className='row'>
                <div className='col-12 text-center'>
                  <Link to='/auth' className='fs-6' data-kt-translate='sign-in-head-link'>
                    ログインへ戻る
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}

      {phase === 'inputVerificationCode' && <ForgotPasswordVerificationAndNewPassword />}
    </>
  )
}
