/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState} from 'react'
import {Button, Modal} from 'antd'
import { KTSVG,} from 'src/_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {MenuComponent} from 'src/_metronic/assets/ts/components'
import {useAuth} from 'src/app/modules/auth'
// import {getMenuListDev} from 'src/app/modules/api'
import {
  showSuccessMessage,
  showWarningMessage,
} from 'src/app/components/sweetAlert'
import {productOptionItemDelete } from 'src/app/modules/api'

const CustomFieldActionsCell = (props: any) => {
  const {auth, saveAuth} = useAuth() as any
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  // 删除某一项
  const deleteList = (item:any) => {
    showWarningMessage(
      '元に戻すことはできません。よろしいでしょうか？', () => {
        productOptionItemDelete({pkVal: item.pk}).then((res:any)=> {
          console.log('res',res)
          props.getAllList()
          showSuccessMessage('削除しました')
        })
      },
      '削除',
      'キャンセル'
    )
  }

  const goCopyDetail = async (item:any) => {
    navigate(`/product/option/${item.id}?copy=1`,{state: item.pk})
  }

  const doModalOK = async () => {
    setBtnLoading(true)

    setBtnLoading(false)
    setIsModalOpen(false)
  }

  const doModalCancel = () => {
    setIsModalOpen(false)
  }

  // const updataMenus = async () => {
  //   const menu: any = await getMenuListDev({
  //     userId: auth?.data.login_id,
  //     roleGroup: auth?.data.role_group,
  //   })
  //   if (menu?.data) {
  //     saveAuth({...auth, menus: menu.data})
  //   }
  // }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        操作
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        {/* <div className='menu-item px-3'> */}
        <div className='menu-item px-3'>
          <a
            style={{fontSize: '13px'}}
            onClick={() => goCopyDetail(props.item)}
            className={'menu-link px-3 py-2 btn'}
            data-kt-docs-table-filter='edit_row'
          >
            コピーを作成する
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            style={{fontSize: '13px'}}
            onClick={() => deleteList(props.item)}
            className={'menu-link px-3 py-2 btn'}
            data-kt-docs-table-filter='delete_row'
          >
            削除
          </a>
        </div>
      </div>

      <div className='menu-item px-3'>
        <Modal
          title='削除しますか?'
          open={isModalOpen}
          onOk={doModalOK}
          onCancel={doModalCancel}
          footer={[
            <Button key='cancel' onClick={doModalCancel}>
              Cancel
            </Button>,
            <Button
              key='submit'
              type='primary'
              loading={btnLoading}
              onClick={doModalOK}
              disabled={btnLoading}
            >
              OK
            </Button>,
          ]}
        >
          <p>元に戻すことはできません。よろしいでしょうか？</p>
        </Modal>
      </div>
      {/* </div> */}
    </>
  )
}

export {CustomFieldActionsCell}
