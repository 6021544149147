/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {KTSVG} from '@/_metronic/helpers'
import {MenuComponent} from '@/_metronic/assets/ts/components'

type Props = {
  setMediaEditName?: () => void
  onDeleteMedia?: () => void
  onDownload?: () => void
  onCopy?: () => void
  isFolder?: boolean
}

export default function CustomFieldActionsCell({
  setMediaEditName,
  onDeleteMedia,
  onDownload,
  onCopy,
  isFolder,
}: Props) {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <div>
      <button
        className='btn btn-light btn-active-light-primary btn-sm d-flex align-items-center gap-1'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        操作
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </button>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a
            onClick={setMediaEditName}
            style={{fontSize: '13px'}}
            className={'menu-link px-3 py-2  btn'}
            data-kt-docs-table-filter='rename_row'
          >
            名前を変更
          </a>
        </div>
        {isFolder ? null : (
          <div className='menu-item px-3' onClick={onCopy}>
            <a
              style={{fontSize: '13px'}}
              className={'menu-link px-3 py-2  btn'}
              data-kt-docs-table-filter='copy_row'
            >
              リンクをコピー
            </a>
          </div>
        )}
        <div className='menu-item px-3'>
          <a
            onClick={onDownload}
            style={{fontSize: '13px'}}
            className={'menu-link px-3 py-2  btn'}
            data-kt-docs-table-filter='edit_row'
          >
            ダウンロード
          </a>
        </div>
        <div className='menu-item px-3'>
          <a
            onClick={onDeleteMedia}
            style={{fontSize: '13px'}}
            className={'menu-link px-3 py-2  btn'}
            data-kt-docs-table-filter='delete_row'
          >
            削除
          </a>
        </div>
      </div>
    </div>
  )
}
