import { Fragment, useEffect, useState } from 'react'
import { KTCard, KTSVG, toAbsoluteUrl } from '@/_metronic/helpers'
import { useNavigate } from 'react-router-dom'

const DocumentReceipt = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<any>({}) // 領収書详情
    const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态


    // 获取列表数据
    const getAlldata = () => {
        let newObj = {
            id: 'A00011',
            goodsName: '商品A',
            goodsType: '食品',
            isChange: '無',
            status: '公開',
            show: false,
            children: [
                {
                    id: '001',
                    img: '',
                    name: 'まぐろ　なかおち',
                    price: 3300,
                    count: 2,
                    unit: 'kg',
                    total: 6600,
                    status: '出荷準備完了'
                },
                {
                    id: '002',
                    img: '',
                    name: 'まぐろ　なかおち',
                    price: 3300,
                    count: 2,
                    unit: 'kg',
                    total: 6600,
                    status: '出荷準備完了'
                }
            ]
        };
        setData(newObj);
    }

    useEffect(() => {
        getAlldata()
    }, [])

    return (
        <div className='card'>
            {/* begin body */}
            <div className='card-body'>
                {/* begin wrapper */}
                <div className='mw-lg-950px mx-auto w-100'>
                    <div className='d-flex justify-content-between flex-column flex-sm-row mb-10'>
                        <div className='text-dark fs-5'>
                            <div className='fs-6'>#REC-000414</div>
                            <h4 className='fw-bolder fs-2qx pe-5 pb-7'>領収書</h4>
                            <div className="mb-10">
                                <div>〇〇〇　 御中</div>
                                <div>◎◎◎　様</div>
                            </div>
                            <div className="mb-10">
                                <div>この度はご注文頂きありがとうございます。</div>
                                <div>下記の通り、正に領収いたしました。</div>
                            </div>
                            <div>
                                <div className='d-flex'>
                                    <div className='text-sm-center' style={{ width: '80px' }}>注文番号</div>
                                    <div className='ms-3'>#14534</div>
                                </div>
                                <div className='d-flex'>
                                    <div className='text-sm-center' style={{ width: '80px' }}>注文日</div>
                                    <div className='ms-3'>2023 年 10 月 25 日</div>
                                </div>
                                <div className='d-flex'>
                                    <div className='text-sm-center' style={{ width: '80px' }}>配送日</div>
                                    <div className='ms-3'>2023 年 10 月 25 日</div>
                                </div>
                            </div>
                        </div>

                        <div className='text-sm-end'>
                            <a href='#' className='d-block mw-150px ms-sm-auto'>
                                <img
                                    alt='Logo'
                                    src={toAbsoluteUrl('/media/svg/brand-logos/lloyds-of-london-logo.svg')}
                                    className='w-100'
                                />
                            </a>

                            <div className='text-sm-start fw-semibold fs-5 text-dark mt-7'>
                                <div className='mb-10'>株式会社アップシェア</div>

                                <div className='mb-10'>
                                    <div>神奈川県横浜市港北区新横浜２丁目３−８</div>
                                    <div>KDX新横浜ビル</div>
                                    <div>電話：045-〇〇〇-〇〇〇</div>
                                </div>
                                <div className="fs-14 text-black">
                                    登録番号：T1111111111111
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-column gap-7 gap-md-10'>
                        <div className='separator'></div>

                        {/* begin order details */}
                        <div className='d-flex'>
                            <div className='w-50 d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold pe-20'>
                                <div className='flex-root d-flex flex-column'>
                                    <span className='text-dark'>小計</span>
                                    <span className='fs-5'>3,900円</span>
                                </div>
                                <div className='flex-root d-flex flex-column'>
                                    <span className='text-dark'>消費税</span>
                                    <span className='fs-5'>330円</span>
                                </div>
                                <div className='flex-root d-flex flex-column'>
                                    <span className='text-dark'>請求金額</span>
                                    <span className='fs-5'>4,270円</span>
                                </div>
                            </div>
                            <div className='w-50 d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold'>
                                <div className='flex-root d-flex flex-column'>
                                    <span className='text-dark'>請求先住所</span>
                                    <span className='fs-5'>
                                        〒220-0011
                                        <br />
                                        神奈川県横浜市西区高島２
                                        <br />
                                        丁目１－１
                                        <br />
                                        築豊ビル5F
                                    </span>
                                </div>
                                <div className='flex-root d-flex flex-column'>
                                    <span className='text-dark'>お届け先の住所</span>
                                    <span className='fs-5'>
                                        〒220-0011
                                        <br />
                                        神奈川県横浜市西区高島２
                                        <br />
                                        丁目１－１
                                        <br />
                                        築豊ビル5F
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* end order details */}

                        {/* begin order-summary */}
                        <div className='d-flex justify-content-between flex-column'>
                            <div className='table-responsive border-bottom mb-9'>
                                <table className='table align-middle table-row-bordered fs-6 gy-4 mb-0'>
                                    <thead>
                                        <tr className='border-bottom fs-6 fw-bold text-dark'>
                                            {/* <th className='min-w-175px pb-2'>ID</th> */}
                                            <th className='min-w-70px pb-2'>ID</th>
                                            <th className='min-w-80px text-end pb-2'>商品</th>
                                            <th className='min-w-100px text-end pb-2'>数量</th>
                                            <th className='min-w-100px text-end pb-2'>単位</th>
                                            <th className='min-w-100px text-end pb-2'>単価（税込）</th>
                                            <th className='min-w-100px text-end pb-2'>合計（税込）</th>
                                        </tr>
                                    </thead>
                                    <tbody className='fw-semibold '>
                                        {
                                            data?.children?.length > 0 ? data?.children?.map((item: any, index: number) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.id}</td>
                                                        <td className='text-end'>{item.name}</td>
                                                        <td className='text-end'>{item.count}</td>
                                                        <td className='text-end'>{item.unit}</td>
                                                        <td className='text-end'>{item.status}</td>
                                                        <td className='text-end'>{item.price}</td>
                                                    </tr>
                                                )
                                            }) : (
                                                <tr>
                                                    <td colSpan={17}>
                                                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                            {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        <tr>
                                            <td className='text-end' colSpan={5}>
                                                小計
                                            </td>
                                            <td className='text-end'>3,900円</td>
                                        </tr>
                                        <tr>
                                            <td className='text-end' colSpan={5}>
                                                消費税
                                            </td>
                                            <td className='text-end'>330円</td>
                                        </tr>
                                        <tr>
                                            <td className='text-end' colSpan={5}>
                                                対象(10%)
                                            </td>
                                            <td className='text-end'>900円</td>
                                        </tr>
                                        <tr>
                                            <td className='text-end' colSpan={5}>
                                                消費税
                                            </td>
                                            <td className='text-end'>90円</td>
                                        </tr>
                                        <tr>
                                            <td className='text-end' colSpan={5}>
                                                8% (軽減税率)対象
                                            </td>
                                            <td className='text-end'>3,000円</td>
                                        </tr>
                                        <tr>
                                            <td className='text-end' colSpan={5}>
                                                消費税
                                            </td>
                                            <td className='text-end'>240円</td>
                                        </tr>
                                        <tr>
                                            <td className='fs-3 text-dark fw-bold text-end' colSpan={5}>
                                                総計（税込）
                                            </td>
                                            <td className='text-dark fs-3 fw-bolder text-end'>4,270円</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* end order-summary */}
                        <div className='w-100 d-flex align-items-center p-2 border border-dark rounded' style={{ height: '120px' }}>
                            備考
                        </div>
                    </div>
                    <div className='d-flex flex-stack flex-wrap mt-lg-20 pt-13'>
                        <div className='my-1 me-5'>
                            <button className='btn btn-success my-1 me-12' onClick={() => window.print()}>
                                Print Invoice
                            </button>
                            <button className='btn btn-light-success my-1'>Download</button>
                        </div>
                        <a href='#' className='btn btn-primary'>
                            Create Invoice
                        </a>
                    </div>
                    {/* end wrapper */}
                </div>
            </div>
            {/* end body */}
        </div>
    )
}

export default DocumentReceipt;
