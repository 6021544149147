import { useEffect, useState, useRef } from 'react'
import { KTCard } from '@/_metronic/helpers'
import { CustomFieldActionsCell } from './tabel/endPointActionsCell'
import { useNavigate } from 'react-router-dom'
import { shippingMasterGet,shippingMasterDelete,shippingMasterInfoGet,shippingMasterCopy,shippingDataBulkOperation } from 'src/app/modules/api'
import { useLayout } from 'src/_metronic/layout/core'
import { useAuth } from "src/app/modules/auth"

const class1 = 'page-item previous'
const class2 = 'page-item previous active'
const class3 = 'page-item previous disabled'

const options = [
  //{ id: 2, name: 'コピーを作成する' },
  { id: 1, name: '削除' },

  // { id: '0', name: 'CSVエクスポート' },
  // { id: '1', name: 'ステータスを処理中に変更' },
  // { id: '2', name: '目方修正済みに変更' },
  // { id: '3', name: '出荷準備完了（伝票番号付与）に変更' },
  // { id: '4', name: 'ステータスを完了に変更 ' },
  // { id: '5', name: 'ステータスをキャンセルに変更 ' },
  // { id: '6', name: 'ステータスを返金処理に変更 ' },
  // { id: '7', name: 'ステータスを保留に変更 ' },
  // { id: '8', name: 'ステータスを決済エラーに変更' },
]

export default function DeliveryAddress() {
  const {auth} = useAuth();
    let headers = {
      "Content-Type":"application/json",
      Authorization:`Bearer ${auth.token}`
    }
  const {setContentLoading} = useLayout()
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState<any>({
    pageNum: 1,
    pageSize: 10,
    //total: 0
  }) // 分页参数
  const [data, setData] = useState<any>([]) // 数据列表
  const [selectType, setSelectType] = useState<string>('') // 左上角选择框
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
  const [selectedList, setSelectList] = useState<any>([]) // 选中的项
  const [nowPage, setNowPage] = useState<any>(1) // 当前页
  const [pageSize, setPageSize] = useState<any>(10) // 每页多少条
  const [id, setId] = useState<any>('') //删除的id
  let [page, setPage] = useState<any>([]) // 页码列表

  // 適用
  const apply = async () => {
    let params = {
      "operation": 1,
      "pkVals": selectedList
    }
    await shippingDataBulkOperation(params)
    getAllList()
  }
  //处理页码
  const changePage = (sumPage: any, size?: number | string) => {
    page = []
    let num = Math.ceil(sumPage / queryParams.pageSize)
    for (let i = 1; i < num + 1; i++) {
      page.push(i)
    }
    let newPage = JSON.parse(JSON.stringify([...page]))
    setPage(newPage)
  }
  
  //获取当前页码的列表
  const getPageList = async (page: any) => {
    setIsLoading(true);
    // let newArr = arr.slice((page - 1) * pageSize, page * pageSize);
    // setData(newArr);
    setNowPage(page);
    queryParams.pageNum = page
    getAllList()
    setIsLoading(false);
  }
  // 改变当前显示数量
  const changePageSize = (size: string | number) => {
    setPageSize(size);
    queryParams.pageSize = size
    getAllList()
    // changePage(arr.length, size);
    // let newArr = arr.slice((nowPage - 1) * Number(size), nowPage * Number(size));
    // setData(newArr);
  }
  // 改变选中列表
  const changeSelectList = (obj: any) => {
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = []
        data.forEach((item: any) => {
          arr.push(item.id)
        });
        setSelectList(arr)
      } else {
        selectedList.push(obj.id)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([])
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id
        })
        selectedList.splice(idx, 1)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    }
  }
  //复制并新增某项
  const bindAddItem = async (id: any) => {
    navigate(`/store/shipping/${id}?copy=1`,{state: id})
  }
  // 删除某项
  const deleteList = (id: any) => {
    bindDeleteItem(id)
    // let idx = data.findIndex((item: any) => {
    //   return item.id === id;
    // })
    // data.splice(idx, 1)
    // let nowArr = JSON.parse(JSON.stringify(data))
    // let idx1 = arr.findIndex((item: any) => {
    //   return item.id === id;
    // })
    // arr.splice(idx1, 1)
    // let nowArr1 = JSON.parse(JSON.stringify(arr))
    // setData(nowArr);
    // setArr(nowArr1);
  }
 // 删除操作
 const bindDeleteItem = async (id: any) => {
  let res: any = await shippingMasterDelete({pkVal: id})
  queryParams.pageNum = 1
  getAllList()
}
const goDetail = async (item: any) => {
  navigate(`/store/shipping/${item.delivery_master_id}`, { state: item.pk })
}
  // 获取列表数据
  const getAllList = () => {
    setContentLoading(true);
    shippingMasterGet(queryParams,headers).then((res: any) => {
      console.log(res)
      changePage(res.total);
    //let newArr = arr.slice((nowPage - 1) * pageSize, nowPage * pageSize);
    let arr = res.rows.map((item: any) => {
      item.id = item.pk
      return item
    })
    setData(arr);
    setContentLoading(false);
    }).catch(()=>{
      setContentLoading(false);
    })
    
  }
  useEffect(() => {
    getAllList()
  }, [])

  return (
    <div style={{ position: 'relative' }}>
        <div className='d-flex justify-content-end mb-6'>
          {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <select className="form-select " data-control="select2"
              style={{ width: 200 }}
              value={selectType}
              placeholder='一括操作'
              onChange={(e) => { setSelectType(e.target.value) }}
            >
              <option value={''}></option>
              {
                options.length !== 0 && options.map((item: any, index: number) => {
                  return (
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                })
              }
            </select>
            <button type='button' className='btn btn-primary'
              style={{ marginLeft: 10 }}
              onClick={() => { apply() }}>
              適用
            </button>
          </div> */}
          <div>
            <button type='button' className='btn btn-primary'
              onClick={() => navigate('/store/shipping/id')}>
              配送マスタの追加
            </button>
          </div>
        </div>
      <KTCard resetSidePaddings={true}>
        <div className="table-responsive " style={{ margin: '0px 30px 50px 30px ' }}>
          <table className="table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer">
            <thead className='table-light'>
              <tr className="text-start fs-6 text-uppercase gs-0">
                {/* <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      data-kt-check-target='#kt_table_users .form-check-input'
                      checked={selectedList.length === data.length && selectedList.length !== 0}
                      onChange={(e) => {
                        let obj = {
                          id: 'all',
                          value: e.target.checked
                        }
                        changeSelectList(obj)
                      }}
                    />
                  </div>
                </th> */}
                <th id='id'>配送ID</th>
                <th id='name'>配送マスタ名</th>
                <th id='career'>配送キャリア</th>
                <th id='setting'>送料設定</th>
                <th id='settime'>配送日時設定</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody className=''>
              {
                data.length > 0 ? data.map((item: any, index: number) => {
                  return (
                    <tr key={item.id}>
                      {/* <td>
                        <div className='form-check form-check-sm form-check-custom me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check-target='#kt_table_users .form-check-input'
                            checked={selectedList.includes(item.id)}
                            onChange={(e) => {
                              let obj = {
                                id: item.id,
                                value: e.target.checked
                              }
                              changeSelectList(obj)
                            }}
                          />
                        </div>
                      </td> */}
                      <td>{item.management_id}</td>
                      <td><a href="#" onClick={(event) => {event.preventDefault(); goDetail(item)}}>{item.delivery_master_name}</a></td>
                      <td>{item.delivery_setting_carrierName}</td>
                      <td>{item.shipping_setting_status == '1' ? '無効' : '有効'}</td>
                      <td>{item.delivery_setting_status == 'enabled' ? '有効' : '無効'}</td>
                      <td style={{ minWidth: 90 }}><CustomFieldActionsCell bindAddItem={bindAddItem} deleteList={deleteList} id={item.id} nowPage={nowPage}></CustomFieldActionsCell></td>
                    </tr>
                  )
                }) : (
                  <tr>
                    <td colSpan={17}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                      </div>
                    </td>
                  </tr>
                )
              }

            </tbody>
          </table>
          <select className="form-select " data-control="select2"
            style={{ width: 80, position: 'absolute', left: 20, bottom: 10 }}
            value={pageSize}
            onChange={(e) => {
              changePageSize(e.target.value);
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>

          {/* page */}
          <ul className="pagination" style={{ position: 'absolute', right: 20, bottom: 10 }}>
            <li className={nowPage === page[0] ? class3 : class1}>
              <a href="#" className="page-link" onClick={(e) => {
                getPageList(nowPage - 1)
                e.preventDefault()
              }}>
                <i className="previous"></i>
              </a>
            </li>
            {
              page.map((item: any, index: number) => {
                return <li className={nowPage === item ? class2 : class1} key={index} >
                  <a href="#" className="page-link" onClick={(e) => {
                    getPageList(item)
                    e.preventDefault()
                  }}>
                    {item}
                  </a>
                </li>
              })
            }
            <li className={nowPage === page[page.length - 1] ? class3 : class1}>
              <a href="#" className="page-link" onClick={(e) => {
                getPageList(nowPage + 1)
                e.preventDefault()
              }}>
                <i className="next"></i>
              </a>
            </li>
          </ul>
        </div>
      </KTCard>
    </div>
  )
}

