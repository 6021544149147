/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import exp from 'constants'

const profileBreadCrumbs: Array<PageLink> = [
	{
		title: 'ホーム',
		path: '/dashboard',
		isSeparator: false,
		isActive: false,
	  },
	  {
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	  },
]

const Pageconts: FC = () => {
    return (
      <>
	<div className='card p-9'>
		<table id="kt_datatable_zero_configuration" className="table table-row-bordered gy-4">
			<tbody>
				<tr>
					<th className='fw-bold'>アプリ名</th>
					<td>HatChuPay</td>
				</tr>
				<tr>
					<th className='fw-bold'>運営会社／販売元</th>
					<td>株式会社アップシェア</td>
				</tr>
				<tr>
					<th className='fw-bold'>運営統括責任者</th>
					<td>稲井田  顕章</td>
				</tr>
				<tr>
					<th className='fw-bold'>運営社所在地</th>
					<td>〒150-0002<br></br>東京都渋谷区渋谷1-12-8ILA渋谷美竹ビル3階</td>
				</tr>
				<tr>
					<th className='fw-bold'>お問い合わせ番号</th>
					<td>050-0000-0000</td>
				</tr>
				<tr>
					<th className='fw-bold'>製造元</th>
					<td>掲載店舗が直接お客様へ商品の提供を行いますので各商品毎に異なります。<br></br>店舗詳細は商品ページをご覧ください。</td>
				</tr>
				<tr>
					<th className='fw-bold'>販売価格</th>
					<td>ご利用のサービスにより異なります。各サービスの購入画面でご確認いただけます。</td>
				</tr>
				<tr>
					<th className='fw-bold'>商品代金以外の必要料金</th>
					<td>通信時に発生するパケット通信料</td>
				</tr>
				<tr>
					<th className='fw-bold'>申込有効期限</th>
					<td>ご注文時のみとなります。</td>
				</tr>
				<tr>
					<th className='fw-bold'>返品について</th>
					<td>デジタルコンテンツの特性上、購入確定後の返品・交換には応じられません。</td>
				</tr>
				<tr>
					<th className='fw-bold'>お支払い方法・時期</th>
					<td>ご利用のプラットフォームが定めるお支払い方法に基づきます。</td>
				</tr>
				<tr>
					<th className='fw-bold'>お支払期限</th>
					<td>クレジット決済の為、即時決済されます。</td>
				</tr>
				<tr>
					<th className='fw-bold'>対応機種</th>
					<td>対象のサービスが動作する端末（スマートフォン、タブレットなど）が必要です。<br></br>動作環境は、各アプリの公式サイトやダウンロードしたストア等のページにてご確認ください。</td>
				</tr>
			</tbody>
		</table>
	</div>
    </>
    )
}

export default function Low() {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>特定商取引法に基づく表記</PageTitle>
      <Pageconts />
    </>
  )
}
