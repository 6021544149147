import { toAbsoluteUrl } from 'src/_metronic/helpers'
import React, { useEffect, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { ledgerDataGet, ledgerDownload, parameterStoreGet } from 'src/app/modules/api';
import moment from 'moment'
import { formatNumber } from 'src/app/modules/utils'
import { useAlert } from 'src/_metronic/layout/core'
export const Invoice = forwardRef((props: any, ref) => {
    console.log('props', props)
    const {setMessage} = useAlert()
    const invoiceRef = useRef<any>(null)
    const [dataItem, setDataItem] = useState<any>({})
    const [prefectureParametersMap, setPrefectureParametersMap] = useState<Map<any, any>>()
    const [productUnitParametersMap, setProductUnitParametersMap] = useState<Map<any, any>>()

    const initData = ()=> { 
        setDataItem(props.invoiceInitData)
        let unitParametersMap: any = new Map(props.modalParameters?.units?.map((unit: any) => [unit.id, unit]));
        let prefectureParametersMap: any = new Map(props.modalParameters?.prefecture?.map((prefecture: any) => [prefecture.value, prefecture]));
        setProductUnitParametersMap(unitParametersMap)
        setPrefectureParametersMap(prefectureParametersMap)
    }

    useEffect( () => {
        initData()
    }, [])

    const submitData = (callback:any) => {
        // console.log('invoiceRef', invoiceRef.current, invoiceRef.current.innerHTML)
        const htmlbody = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Document</title>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css" rel="stylesheet"
                    integrity="sha384-GLhlTQ8iRABdZLl6O3oVMWSktQOp6b7In1Zl3/Jr59b6EGGoI1aFkw7cmDA6j6gD" crossorigin="anonymous">

                <link href="https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,200,300,regular,500,600,700,800,900" rel="stylesheet" />
            
            </head>
            <style>
            body{
                font-family: "Noto Sans JP", sans-serif;
                font-optical-sizing: auto;
                font-style: normal;
            }
                @media print {
                    @page {
                        size: A4;
                        margin: 0;
                    }
            
                    #kt_app_header {
                        display: none;
                    }
            
                    #kt_app_footer {
                        display: none;
                    }
            
                    body {
                        margin: 0;
                    }
                }
            </style>
            </head>
            <body>
                ${invoiceRef.current.innerHTML}
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-/mhDoLbDldZc3qpsJHpLogda//BVZbgYuw6kof4u2FrCedxOtgRZDTHgHUhOCVim"
                    crossorigin="anonymous"></script>
            </body>
            </html>
        `
        console.log("htmlbody", htmlbody)
        
        ledgerDownload({
            ledgerKbn: props.title == '領収書' ? 2 : 1,
            title:props.title,
            displayId:dataItem?.suppliers?.display_id,
            pdfHtml: htmlbody
        }).then((res: any) => {
            console.log('res', res)
            const url = res.clientUrl
            const fileName = res.fileName
            const link = document.createElement('a')
            link.href = url
            link.download = `${fileName}.csv`
            document.body.appendChild(link)
            link.click()
        
            // 作成したURL対象を削除
            URL.revokeObjectURL(url)
            if(callback){
                callback()
            }
            setMessage({ type: 'success', message: 'PDFファイルのダウンロードを成功しました。' })
        }).catch((res:any)=>{
            if(callback){
                callback()
            }
            setMessage({ type: 'error', message: 'PDFファイルのダウンロードを失敗しました。' })
        })
    }
    useImperativeHandle(ref, () => ({
        submitData: submitData,
    }))
    return (
        <>
            <style>
                {`
                    @media print {
                        @page {
                            size: A4;
                            margin: 0;
                        }a
                        #kt_app_header {
                            display: none;
                        }
                        #kt_app_footer {
                            display: none;
                        }
                        body {
                            margin: 0;
                        }
                    }
                `}
            </style>
            <div ref={invoiceRef} className="bg-white mw-lg-950px mx-auto w-100">
                    <div className="p-5">
                        <div className="">
                            {/* ヘッダ */}
                            <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
                                <div className="">
                                    <h1 className="fw-bolder fs-2qx pe-5 pb-7">{props.title}</h1>
                                    <div className="text-sm-start fw-semibold fs-6 mt-7">
                                        <div>{dataItem?.order?.billing_zip_code}</div>
                                        <div>
                                            {prefectureParametersMap?.get(dataItem?.order?.billing_prefecture)?.label}
                                            {dataItem?.order?.billing_city}
                                            {dataItem?.order?.billing_street_address}
                                        </div>
                                        <div>{dataItem?.order?.billing_building_name}</div>
                                        <div><span className="fw-bold">{dataItem?.order?.billing_store_name}</span></div>
                                        <div><span className="fs-6">(顧客コード: xxxxxxxxx)</span></div>
                                    </div>
                                </div>
                                <div className="text-sm-end">
                                    <div className="ms-sm-auto fs-8 mb-4">
                                        発注番号：{dataItem?.order?.order_management_id}
                                    </div>
                                    <div className="mw-150px ms-sm-auto">
                                        {/* <img
                                        alt='Logo'
                                        src={toAbsoluteUrl('/media/svg/brand-logos/lloyds-of-london-logo.svg')}
                                        className='w-100'
                                        /> */}
                                        <img
                                        alt='Logo'
                                        src={toAbsoluteUrl(dataItem?.documentSettings?.logo)}
                                        className='w-100'
                                        />
                                    </div>
                                    <div className="text-sm-start fw-semibold fs-6 mt-7">
                                        <div className='mb-4'>
                                            <div><span className="fw-bold">{dataItem?.documentSettings?.sender_info}</span></div>
                                            {/* <div>〒236-0012</div>
                                            <div>神奈川県横浜市港北区新横浜2-3-8</div>
                                            <div>KDX新横浜ビル8F</div> */}
                                        </div>
                                        {/* <div className=''>
                                            <div>TEL: 045-478-6870</div>
                                            <div>FAX: 045-478-6871</div>
                                            <div>登録番号: XXXXXXXXXXXXXXXXXXXXXXXXXX</div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            {/* テキスト */}
                            <div className="pb-12">
                                <div className="fw-bold fs-4 mb-6">
                                    {/* 下記の通り御請求申し上げます。 */}
                                    {dataItem?.messageData?.header_message}
                                </div>

                                {/* 金額 */}
                                <div className="row">
                                    <div className="col-8">
                                        <div className="row border border-dark fs-6">
                                            <div className="col-3 py-1">
                                                <span className="fw-bold">小計</span>
                                            </div>
                                            <div className="col-3 py-1 border-start border-dark">
                                                <span className="fw-bold">消費税</span>
                                            </div>
                                            <div className="col-6 py-1 border-start border-dark">
                                                <span className="fw-bold">総合計</span>
                                            </div>
                                        </div>
                                        <div className="row border border-dark border-top-0">
                                            <div className="col-3 py-1">
                                                <span className="">{dataItem.total?formatNumber(dataItem.total.amount):0}</span>
                                            </div>
                                            <div className="col-3 py-1 border-start border-dark">
                                                <span className="">{dataItem.total? formatNumber(dataItem.total.totalTaxRate): 0}</span>
                                            </div>
                                            <div className="col-6 py-1 border-start border-dark">
                                                <span className="fw-bold">{dataItem.total?formatNumber(dataItem.total.sumAmount):0}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* 注文情報 */}
                            {/* <div className="mb-12">
                                <div className="row border border-dark">
                                    <div className="col-3 py-1">
                                        <span className="fw-bold"></span>
                                    </div>
                                    <div className="col-3 py-1 border-start border-dark">
                                        <span className="fw-bold">発注番号</span>
                                    </div>
                                    <div className="col-3 py-1 border-start border-dark">
                                        <span className="fw-bold"></span>
                                    </div>
                                </div>
                                <div className="row border border-dark border-top-0">
                                    <div className="col-3 py-1">
                                        <span className="">xxxxxxxxxxxxxxx</span>
                                    </div>
                                    <div className="col-3 py-1 border-start border-dark">
                                        <span className=""></span>
                                    </div>
                                    <div className="col-3 py-1 border-start border-dark">
                                        <span className="">2024/03/19</span>
                                    </div>
                                </div>
                            </div> */}

                            {/* 注文明細テーブル */}
                            <div className="fw-bold fs-2 mb-6">
                                商品明細
                            </div>
                            <div className="row border border-dark">
                                <div className="col py-1 text-center mw-50px">
                                    <span className='fs-8'>No</span>
                                </div>
                                <div className="col-1 py-1 text-center border-start border-dark">
                                    <span className='fs-8'>発注日</span>
                                </div>
                                <div className="col-4 py-1 text-center border-start border-dark">
                                    <span className='fs-8'>品名</span>
                                </div>
                                <div className="col-1 py-1 text-center text-center border-start border-dark">
                                    <span className='fs-8'>税率</span>
                                </div>
                                <div className="col py-1 text-center border-start border-dark">
                                    <span className='fs-8'>単価</span>
                                </div>
                                <div className="col-1 py-1 text-center border-start border-dark">
                                    <span className='fs-8'>数量</span>
                                </div>
                                <div className="col-1 py-1 text-center border-start border-dark">
                                    <span className='fs-8'>単位</span>
                                </div>
                                <div className="col py-1 text-center border-start border-dark">
                                    <span className='fs-8'>金額</span>
                                </div>
                            </div>
                            {dataItem?.orderItems?.map((item:any, index: number)=> (
                                <div key={index} className="row border border-dark border-top-0">
                                    <div className="col py-1 text-center mw-50px">
                                        <span className='fs-8'>{index+1}</span>
                                    </div>
                                    <div className="col-1 py-1 border-start border-dark">
                                        <span className='fs-8'>{moment(dataItem?.order?.order_date).format('YYYY-MM-DD')}</span>
                                    </div>
                                    <div className="col-4 py-1 border-start border-dark">
                                        <div><span className='fs-8'>{item.product_name}</span></div>
                                        <div><span className='fs-8'>{item.product_variation_name}</span></div>
                                    </div>
                                    <div className="col-1 py-1 border-start border-dark text-end">
                                        <span className='fs-8'>{item.tax_rate}%</span>
                                    </div>
                                    <div className="col py-1 border-start border-dark text-end">
                                        <span className='fs-8'>{formatNumber(item.unit_price)}</span>
                                    </div>
                                    <div className="col-1 py-1 border-start border-dark text-end">
                                        <span className='fs-8'>{item.quantity}</span>
                                    </div>
                                    <div className="col-1 py-1 border-start border-dark">
                                        <span className='fs-8'>
                                            {productUnitParametersMap?.get(item.unit)?.label}
                                        </span>
                                    </div>
                                    <div className="col py-1 border-start border-dark text-end">
                                        <span className='fs-8'>{formatNumber(item.total_amount)}</span>
                                    </div>
                                </div>
                            ))}
                            <div className="row border border-dark border-top-0">
                                <div className="col py-4 mw-50px"></div>
                                <div className="col-1 py-4 border-start border-dark"></div>
                                <div className="col-4 py-4 border-start border-dark"></div>
                                <div className="col-1 py-4 border-start border-dark"></div>
                                <div className="col py-4 border-start border-dark"></div>
                                <div className="col-1 py-4 border-start border-dark"></div>
                                <div className="col-1 py-4 border-start border-dark"></div>
                                <div className="col py-4 border-start border-dark"></div>
                            </div>

                            {/* 小計・総合計 */}
                            <div className="row border border-dark border-top-0">
                                <div className="col-6 offset-6">
                                    <div className="row border border-dark border-top-0 border-end-0">
                                        <div className="col-6 py-1 border-end border-dark">
                                            <span className="fs-8">商品計</span>
                                        </div>
                                        <div className="col-6 py-1 text-end">
                                            <span className="fs-8">{dataItem.total?formatNumber(dataItem?.total?.amount):0}</span>
                                        </div>
                                    </div>
                                    <div className="row border border-dark border-top-0 border-end-0">
                                        <div className="col-6 py-1 border-end border-dark">
                                            <span className="fs-8">消費税10％対象</span>
                                        </div>
                                        <div className="col-6 py-1 text-end">
                                            <span className="fs-8">{dataItem.total?formatNumber(dataItem.total.taxRate10):0}</span>
                                        </div>
                                    </div>
                                    <div className="row border border-dark border-top-0 border-end-0">
                                        <div className="col-6 py-1 border-end border-dark">
                                            <span className="fs-8">消費税8％対象</span>
                                        </div>
                                        <div className="col-6 py-1 text-end">
                                            <span className="fs-8">{dataItem.total?formatNumber(dataItem.total.taxRate8):0}</span>
                                        </div>
                                    </div>
                                    <div className="row border border-dark border-top-0 border-end-0 border-bottom-0">
                                        <div className="col-6 py-1 border-end border-dark">
                                            <span className="fw-bold fs-6">総合計</span>
                                        </div>
                                        <div className="col-6 py-1 text-end">
                                            <span className="fw-bold fs-6">{dataItem.total?formatNumber(dataItem.total.sumAmount):0}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </>
    )
})
