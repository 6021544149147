import { getServer, postServer, deleteServer } from 'src/service/http'

// 出荷一览
export function shippingDataListGet(param: object,headers: object={}) {
    return getServer(`/shipping-data-list-get`, { ...param},{...headers})
}

// 出荷情報取得
export function shippingDataDetailGet(param: any) {
    return getServer(`/shipping-data-get`, {pkVal: param })
}

// 详细页面数据更新
export function shippingUpDateGet(param: any,headers: object={}) {
    return postServer(`/shipping-data-update`, {...param }, {...headers})
}

// 一览更新状态
export function shipbulkUpDatePost(param: any,headers: object={}) {
    return postServer(`/shipping-data-bulk-update`, {...param },{...headers})
}

// 配送データ一括操作
export function shippingDataBulkOperation(param: object) {
    return postServer(`/shipping-data-bulk-operation`, { ...param })
}

export function productGroupGet(param: object,headers: object={}) {
    return getServer(`/shipping-group-get`, { ...param},{...headers})
}

// 配送マスタ情報取得
export function shippingMasterGet(param: object,headers:object={}) {
    return getServer(`/shipping-master-get`, { ...param },{...headers})
}

// 配送マスタ情報登録
export function shippingMasterInfoAdd(param: object,headers:object={}) {
    return postServer(`/shipping-master-info-add`, { ...param }, { ...headers })
}

// 配送マスタ削除
export function shippingMasterDelete(param: object) {
    return deleteServer(`/shipping-master-delete`, { ...param })
}
// 配送マスタ情報取得
export function shippingMasterInfoGet(param: object) {
    return getServer(`/shipping-master-info-get`, { ...param })
}

// 配送マスタ複写(参考新增)
export function shippingMasterCopy(param: object,headers:object={}) {
    return postServer(`/shipping-master-copy`, { ...param },{...headers})
}

// 配送マスタ情報更新
export function shippingMasterInfoUpdate(param: object,headers:object={}) {
    return postServer(`/shipping-master-info-update`, { ...param },{...headers})
}