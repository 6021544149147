import {useEffect, useState} from 'react'
import {NavLink, useNavigate} from 'react-router-dom'
import {KTCard} from 'src/_metronic/helpers'
import {showSuccessMessage} from 'src/app/components/sweetAlert'
import {Pagination} from 'src/app/components/pagination'
import {UsersListSearchComponent} from './components/header/UsersListSearchComponent'
import {CustomFieldActionsCell} from './tabel/endPointActionsCell'
import {customerSearch} from 'src/app/modules/api'
import {customerRankListGet} from 'src/app/modules/api'
import moment from 'moment'
import { useLayout } from 'src/_metronic/layout/core'

export default function Customer() {
  const navigate = useNavigate()

  const {isContentLoading, setContentLoading} = useLayout()
  const [customerOptions, setCustomerOptions] = useState<any>([])
  const [datas, setDatas] = useState<any>([])

  const [selectedList, setSelectList] = useState<any>([])
  //检索的字段
  let [role, setRole] = useState<any>('')
  const [queryParams, setQueryParams] = useState<any>({
    company_name: '',
    queryTimestart: '',
    queryTimeend: '',
    page: 1,
    pageSize: 10,
    total: 0,
  }) // 分页参数

  //asc  desc
  const [sort, setSort] = useState('')
  const [sortId, setSortId] = useState('')

  // 排序
  const changeSortType = async (id: any) => {}

  // 根据时间检索
  const searchTime = (s: any, e: any) => {
    console.log('searchTime', s, e)
    let stime = s ? moment(s).format('YYYY-MM-DD') + ' 00:00:00' : ''
    let etime = e ? moment(e).format('YYYY-MM-DD') + ' 23:59:59' : ''
    queryParams.queryTimestart = stime
    queryParams.queryTimeend = etime
    setQueryParams({...queryParams, queryTimestart: stime, queryTimeend: etime})
    changeTenantDeleteList()
  }

  //検索
  const searchValues = async (value: any) => {
    console.log('searchValues', value)
    queryParams.company_name = value
    setQueryParams({...queryParams, company_name: value})
    changeTenantDeleteList()
  }

  // 筛选之后初始化
  const changeTenantDeleteList = async () => {
    setSelectList([])
    initData()
  }

  //重置检索信息
  const resizeTenantValue = async (val: any) => {
    setRole('')
  }

  useEffect(() => {
    initData()
  }, [])

  // 获取列表数据
  const initData = async () => {
    setContentLoading(true)
    const resp: any = await customerRankListGet({})
    setCustomerOptions(resp.rows)
    await getData(queryParams.page, queryParams.pageSize)
    setContentLoading(false)
  }

  // 获取列表数据
  const getData = async (pageNum: any, pageSize: any) => {
    // console.log('pageNum', pageNum, pageSize)
    setContentLoading(true)
    try {
      const res: any = await customerSearch({
        pageNum,
        pageSize,
        company_name: queryParams.company_name,
        queryTimestart: queryParams.queryTimestart,
        queryTimeend: queryParams.queryTimeend,
      })
      console.log('res', res)

      setQueryParams({...queryParams, total: res.total})
      setDatas(res.rows)
      setContentLoading(false)
    } catch (error) {
      setContentLoading(false)
      console.error('error', error)
    }
  }

  const goDetail = async (item: any) => {
    navigate(`/customer/${item.customer_id}`, {
      state: {pk: item.pk, type: 'detail', group_id: item.group_id, status: item.status},
    })
  }

  return (
    <div style={{position: 'relative'}}>

      <KTCard resetSidePaddings={true}>

        <div className='row border-bottom py-4 px-6 m-0'>
          <div className='col-md-6 ps-0 ms-0'>
            <UsersListSearchComponent
              searchValues={searchValues}
              searchTime={searchTime}
              resizeTenantValue={resizeTenantValue}
            />
          </div>
          <div className='col-md-6 text-end'>
            {/* <NavLink to='/customer/create' className='btn btn-primary'>
              顧客新規登録
            </NavLink> */}
          </div>
        </div>

        <div className='table-responsive'>
          <table className='table align-middle table-row-bordered fs-6 gy-2 gx-6 dataTable no-footer' >
            <thead className='table-light'>
              <tr className='text-start fs-6 text-uppercase gs-0'>
                <th
                  id='customer_id'
                  onClick={() => {
                    changeSortType('customer_id')
                  }}
                >
                  顧客ID
                  {sortId === 'customer_id' && sort === 'asc' && (
                    <svg
                      style={{display: 'inline-block', width: 10, height: 10, marginLeft: 5}}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 6 6'
                      fill='var(--kt-text-dark)'
                    >
                      <path d='M3.23571 2.72011L4.97917 4.46358C5.15176 4.63618 5.43158 4.63617 5.60417 4.46358C5.77676 4.29099 5.77676 4.01118 5.60417 3.83861L3.29463 1.52904C3.13192 1.36629 2.86809 1.36629 2.70538 1.52904L0.395812 3.83861C0.22325 4.01117 0.22325 4.29099 0.395812 4.46358C0.568437 4.63617 0.84825 4.63617 1.02081 4.46358L2.76429 2.72011C2.89446 2.58994 3.10554 2.58994 3.23571 2.72011Z' />
                    </svg>
                  )}
                  {sortId === 'customer_id' && sort === 'desc' && (
                    <svg
                      style={{display: 'inline-block', width: 10, height: 10, marginLeft: 5}}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 6 6'
                      fill='var(--kt-text-dark)'
                    >
                      <path d='M2.76429 3.27989L1.02083 1.53642C0.848244 1.36382 0.568419 1.36383 0.395831 1.53642C0.223244 1.70901 0.223244 1.98882 0.395831 2.16139L2.70537 4.47096C2.86808 4.63371 3.13191 4.63371 3.29462 4.47096L5.60419 2.16139C5.77675 1.98883 5.77675 1.70901 5.60419 1.53642C5.43156 1.36383 5.15175 1.36383 4.97919 1.53642L3.23571 3.27989C3.10554 3.41006 2.89446 3.41006 2.76429 3.27989Z' />
                    </svg>
                  )}
                </th>
                <th
                  id='orderName'
                  onClick={() => {
                    changeSortType('orderName')
                  }}
                >
                  顧客名
                  {sortId === 'orderName' && sort === 'asc' && (
                    <svg
                      style={{display: 'inline-block', width: 10, height: 10, marginLeft: 5}}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 6 6'
                      fill='var(--kt-text-dark)'
                    >
                      <path d='M3.23571 2.72011L4.97917 4.46358C5.15176 4.63618 5.43158 4.63617 5.60417 4.46358C5.77676 4.29099 5.77676 4.01118 5.60417 3.83861L3.29463 1.52904C3.13192 1.36629 2.86809 1.36629 2.70538 1.52904L0.395812 3.83861C0.22325 4.01117 0.22325 4.29099 0.395812 4.46358C0.568437 4.63617 0.84825 4.63617 1.02081 4.46358L2.76429 2.72011C2.89446 2.58994 3.10554 2.58994 3.23571 2.72011Z' />
                    </svg>
                  )}
                  {sortId === 'orderName' && sort === 'desc' && (
                    <svg
                      style={{display: 'inline-block', width: 10, height: 10, marginLeft: 5}}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 6 6'
                      fill='var(--kt-text-dark)'
                    >
                      <path d='M2.76429 3.27989L1.02083 1.53642C0.848244 1.36382 0.568419 1.36383 0.395831 1.53642C0.223244 1.70901 0.223244 1.98882 0.395831 2.16139L2.70537 4.47096C2.86808 4.63371 3.13191 4.63371 3.29462 4.47096L5.60419 2.16139C5.77675 1.98883 5.77675 1.70901 5.60419 1.53642C5.43156 1.36383 5.15175 1.36383 4.97919 1.53642L3.23571 3.27989C3.10554 3.41006 2.89446 3.41006 2.76429 3.27989Z' />
                    </svg>
                  )}
                </th>

                <th
                  id='orderLevel'
                  onClick={() => {
                    changeSortType('orderLevel')
                  }}
                >
                  顧客ランク
                  {sortId === 'orderLevel' && sort === 'asc' && (
                    <svg
                      style={{display: 'inline-block', width: 10, height: 10, marginLeft: 5}}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 6 6'
                      fill='var(--kt-text-dark)'
                    >
                      <path d='M3.23571 2.72011L4.97917 4.46358C5.15176 4.63618 5.43158 4.63617 5.60417 4.46358C5.77676 4.29099 5.77676 4.01118 5.60417 3.83861L3.29463 1.52904C3.13192 1.36629 2.86809 1.36629 2.70538 1.52904L0.395812 3.83861C0.22325 4.01117 0.22325 4.29099 0.395812 4.46358C0.568437 4.63617 0.84825 4.63617 1.02081 4.46358L2.76429 2.72011C2.89446 2.58994 3.10554 2.58994 3.23571 2.72011Z' />
                    </svg>
                  )}
                  {sortId === 'orderLevel' && sort === 'desc' && (
                    <svg
                      style={{display: 'inline-block', width: 10, height: 10, marginLeft: 5}}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 6 6'
                      fill='var(--kt-text-dark)'
                    >
                      <path d='M2.76429 3.27989L1.02083 1.53642C0.848244 1.36382 0.568419 1.36383 0.395831 1.53642C0.223244 1.70901 0.223244 1.98882 0.395831 2.16139L2.70537 4.47096C2.86808 4.63371 3.13191 4.63371 3.29462 4.47096L5.60419 2.16139C5.77675 1.98883 5.77675 1.70901 5.60419 1.53642C5.43156 1.36383 5.15175 1.36383 4.97919 1.53642L3.23571 3.27989C3.10554 3.41006 2.89446 3.41006 2.76429 3.27989Z' />
                    </svg>
                  )}
                </th>

                <th
                  id='contact_person'
                  onClick={() => {
                    changeSortType('contact_person')
                  }}
                >
                  担当者
                  {sortId === 'contact_person' && sort === 'asc' && (
                    <svg
                      style={{display: 'inline-block', width: 10, height: 10, marginLeft: 5}}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 6 6'
                      fill='var(--kt-text-dark)'
                    >
                      <path d='M3.23571 2.72011L4.97917 4.46358C5.15176 4.63618 5.43158 4.63617 5.60417 4.46358C5.77676 4.29099 5.77676 4.01118 5.60417 3.83861L3.29463 1.52904C3.13192 1.36629 2.86809 1.36629 2.70538 1.52904L0.395812 3.83861C0.22325 4.01117 0.22325 4.29099 0.395812 4.46358C0.568437 4.63617 0.84825 4.63617 1.02081 4.46358L2.76429 2.72011C2.89446 2.58994 3.10554 2.58994 3.23571 2.72011Z' />
                    </svg>
                  )}
                  {sortId === 'contact_person' && sort === 'desc' && (
                    <svg
                      style={{display: 'inline-block', width: 10, height: 10, marginLeft: 5}}
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 6 6'
                      fill='var(--kt-text-dark)'
                    >
                      <path d='M2.76429 3.27989L1.02083 1.53642C0.848244 1.36382 0.568419 1.36383 0.395831 1.53642C0.223244 1.70901 0.223244 1.98882 0.395831 2.16139L2.70537 4.47096C2.86808 4.63371 3.13191 4.63371 3.29462 4.47096L5.60419 2.16139C5.77675 1.98883 5.77675 1.70901 5.60419 1.53642C5.43156 1.36383 5.15175 1.36383 4.97919 1.53642L3.23571 3.27989C3.10554 3.41006 2.89446 3.41006 2.76429 3.27989Z' />
                    </svg>
                  )}
                </th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {datas.length > 0 ? (
                datas.map((item: any, index: number) => {
                  return (
                    <tr key={item.pk}>
                      <td>
                        <a
                          onClick={(event) => {
                            event.preventDefault()
                            goDetail(item)
                          }}
                          href="#"
                        >
                          {item.display_id}
                        </a>
                      </td>
                      <td>{item.company_name}</td>
                      <td>
                        {customerOptions?.find((e: any) => e.group_id == item.group_id)
                          ?.group_name ||
                          customerOptions?.find((e: any) => e.default == 'yes')?.group_name}
                      </td>
                      <td>{item.contact_person}</td>
                      <td style={{minWidth: 90}}>
                        <CustomFieldActionsCell
                          getAllList={initData}
                          item={item}
                          nowPage={queryParams.page}
                        ></CustomFieldActionsCell>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={17}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {isContentLoading ? 'データの取得中' : '該当するレコードがありません。'}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
          {queryParams.total != 0 && (
            <Pagination
              currentPage={getData}
              page={queryParams.page}
              pageSize={queryParams.pageSize}
              total={queryParams.total}
            />
          )}
      </KTCard>
    </div>
  )
}
