import React, {useState, useEffect} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import './style.css'
import {useLayout} from 'src/_metronic/layout/core'
import {orderDataAdd, entrytran} from 'src/app/modules/api'
import {useAlert} from 'src/_metronic/layout/core'

type Success = {
  entrytran: {[key: string]: any}[]
  orderDataAdd: {[key: string]: any}[]
}

type Errors = {
  entrytran: {[key: string]: any}[]
  orderDataAdd: {[key: string]: any}[]
}

export default function CartCheckoutOrdering() {
  const {setContentLoading} = useLayout()
  const navigate = useNavigate()
  const [orderData, setOrderData] = useState<any>([])
  const {setMessage} = useAlert()

  let location = useLocation()
  const qramesObj: any = location.state

  useEffect(() => {
    setOrderData([])
  }, [])

  useEffect(() => {
    console.log('qramesObj', qramesObj)
    if (orderData.length === 0 && qramesObj && qramesObj.supplier_items.length > 0) {
      // この形にする
      //   "orderDataList": [
      //     {
      //       "supplier_id": "supplier_id_example",
      //       "supplier_order_id": "supplier_order_id_example",
      //       "status": "in_progress",
      //       "billing_zip_code": "123-4567",
      //       "billing_prefecture": "Tokyo",
      //       "billing_city": "Shibuya",
      //       "billing_street_address": "1-2-3",
      //       "billing_building_name": "Building Name",
      //       "billing_store_name": "Store Name",
      //       "billing_name": "Billing Name",
      //       "billing_phone_number": "090-1234-5678",
      //       "billing_email_address": "billing@example.com",
      //       "product_option": "Option 1",
      //       "member_specific_setting": "Specific Setting",
      //       "customer_inquiry": "Inquiry",
      //       "internal_memo": "Memo",
      //       "orderItems": [
      //         {
      //           "product_id": "product_id_example",
      //           "product_variation_id": "variation_id_example",
      //           "product_name": "Product Name",
      //           "product_variation_name": "Variation Name",
      //           "sku": "SKU123",
      //           "quantity": 2,
      //           "unit": "pcs",
      //           "unit_price": 1000,
      //           "shippingPrice": 500,
      //           "total_amount": 2500,
      //           "tax_rate": 10,
      //           "taxPrice": 250
      //         }
      //       ],
      //       "shipping": [
      //         {
      //           "id": "shipping_id_example",
      //           "fee": 500,
      //           "name": "Standard Shipping",
      //           "tax": 50,
      //           "desired_delivery_date": "2024-01-01",
      //           "desired_delivery_time": "Morning"
      //         }
      //       ],
      //       "paymentItem": {
      //         "payment_method": "credit_card",
      //         "payment_amount": 2500,
      //         "payment_status": "pending"
      //       }
      //     }
      //   ],
      //   "buyerShippingPk": "buyer_shipping_pk_example",
      //   "notes": "Special instructions",
      //   "OrderShippings": [],
      //   "OrderDiscounts": [],
      //   "Payment": [],
      //   "Refunds": [],
      //   "PaymentChanges": []
      // }

      // 注文登録
      qramesObj.supplier_items.forEach((supplier_data: any, index: number) => {
        console.log('supplier_data', supplier_data)

        let orderDataList: any = []
        let orderDataListItems: any = {}
        let orderDataListBilling: any = {}
        let orderDataListShipping: any = []
        let orderDataListOrderItems: any = []
        let orderDataListPayment: any = {}

        let tmpData = supplier_data

        // 請求先
        const address = qramesObj._addressList.find((k: any) => k.pk === tmpData.address)
        orderDataListBilling = {
          billing_store_name: '',
          billing_name: '',
          billing_email_address: '',
          billing_street_address: address.address_street,
          billing_zip_code: address.address_postal_code,
          billing_building_name: address.address_building_name,
          billing_city: address.address_city,
          billing_prefecture: address.address_prefecture,
          billing_phone_number: address.tel,
          internal_memo: '',
          member_specific_setting: '',
          customer_inquiry: '',
          product_option: '',
          order_management_id: '',
          payment_id: '',
          supplier_order_id: '',
          supplier_id: tmpData.id,
          status: 'in_progress',
        }

        // 配送先
        orderDataListShipping = supplier_data.shipping.map((k: any) => {
          return {
            id: k.id,
            fee: k.shippingPrice,
            name: k.name,
            tax: k.shippingTaxPrice,
            desired_delivery_date: k.desired_delivery_date,
            desired_delivery_time: k.desired_delivery_time,
          }
        })

        // 支払い情報
        orderDataListPayment = {
          payment_amount: supplier_data.supplierPrice.toString(),
          payment_method: supplier_data.payment_method,
          payment_status:
            supplier_data.payment_method === 'later_payment' ? 'later_payment' : 'in_progress',
        }

        // 商品情報

        orderDataListOrderItems = supplier_data.list.map((item1: any) => {
          if (!item1.tax_rate) {
            let reg = /(\d+)%/g
            let tax_rate = reg.exec(item1.tax_category)
            if (tax_rate) {
              item1.tax_rate = tax_rate[1]
            } else {
              item1.tax_rate = 0
            }
          }
          item1.shipping_group = item1.shipping_group
          item1.total_amount = item1.calcPrice
          item1.product_variation_id = item1.variation_id
          item1.product_variation_name = item1.variation_name
          item1.shippingPrice = item1.shippingPrice || 0
          item1.shippingTaxPrice = item1.shippingTaxPrice || 0
          return item1
        })

        // delete tmpData.supplier_items.list

        // const obj = {
        //   buyerShippingPk: qramesObj.address,
        //   notes: qramesObj.note,
        // }

        setOrderData((prevOrderData: any) => [
          ...prevOrderData,
          {
            ...orderDataListBilling,
            orderItems: orderDataListOrderItems,
            shipping: orderDataListShipping,
            paymentItem: {...orderDataListPayment},
            notes: supplier_data.note,
            buyerShippingPk: supplier_data.address,
          },
        ])
      })
    }
  }, [orderData, qramesObj])

  useEffect(() => {
    let success: Success = {
      entrytran: [],
      orderDataAdd: [],
    }
    let errors: Errors = {
      entrytran: [],
      orderDataAdd: [],
    }

    if (orderData.length > 0) {
      let entrytranPromises = orderData.map((order: any) => {
        if (order.paymentItem.payment_method === 'credit_card') {
          return entrytran({
            amount: order.paymentItem.payment_amount,
            provider: 'smbc',
            id: order.supplier_id,
          })
            .then((res) => {
              success.entrytran.push({[order.supplier_id]: res})
              // errors.entrytran.push({[order.supplier_id]: res})
            })
            .catch((err) => {
              errors.entrytran.push({[order.supplier_id]: err})
            })
        }
        return Promise.resolve()
      })

      Promise.all(entrytranPromises).then(() => {
        console.log('errors.entrytran', errors.entrytran)

        let orderDataAddPromises = orderData.map((order: any) => {
          if (!errors.entrytran.find((k) => k[order.supplier_id])) {
            //  success.entrytranに存在している場合、data.orderCdを取得

            return orderDataAdd([order])
              .then((res) => {
                success.orderDataAdd.push({[order.supplier_id]: res})
              })
              .catch((err) => {
                errors.orderDataAdd.push({[order.supplier_id]: err})
              })
          }
          return Promise.resolve()
        })

        Promise.all(orderDataAddPromises).then(() => {
          // errorsがどちらも空の場合、完了ページへ遷移
          if (errors.entrytran.length === 0 && errors.orderDataAdd.length === 0) {
            navigate('/buy/checkout/complete', {replace: true})
          } else {
            // エラーがあった場合は、成功したほうのデータをorderdataから削除して、checkoutに戻る
            let successSupplierId: any = []
            errors.entrytran.forEach((k) => {
              successSupplierId.push(Object.keys(k)[0])
            })
            errors.orderDataAdd.forEach((k) => {
              successSupplierId.push(Object.keys(k)[0])
            })

            const errorData = orderData.filter(
              (k: any) => !successSupplierId.includes(k.supplier_id)
            )
            qramesObj.supplier_items = errorData

            setMessage({
              type: 'error',
              message:
                '一部商品の注文に失敗しました。注文に失敗した商品はカートに残っています。別の支払い方法をお試しください。',
            })
            navigate('/buy/cart', {state: {...qramesObj}, replace: true})
          }
        })
      })
    }
  }, [orderData, navigate, qramesObj, setMessage])

  if (!qramesObj) {
    navigate('/buy/cart', {replace: true})
    return null
  }

  return (
    <>
      <div className='row'>
        <div className='mt-4 d-flex w-100 justify-content-between'>
          注文処理中です。しばらくお待ちください。
        </div>
      </div>
    </>
  )
}
