/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {useAuth} from '@/app/modules/auth'

export default function Header() {

  const { auth } = useAuth()
  // const userdata = auth?.data
  // const role_group = JSON.parse(auth?.data?.role_group);
  const role_group = auth?.role;
  const location = useLocation()

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-0 pb-0'>
        {/* delete 86 - 178   */ }


        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/product/category' && 'active')
                }
                to='/product/category'
              >
                商品カテゴリ管理
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/product/label' && 'active')
                }
                to='/product/label'
              >
                商品ラベル設定
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )

}
