/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, Fragment} from 'react'
import React from 'react'
import {Loading} from '@/app/components/loading'
import {PageLink, PageTitle} from '@/_metronic/layout/core'
import {toAbsoluteUrl} from '@/_metronic/helpers'
import {useLayout} from 'src/_metronic/layout/core'
import {Pagination} from '@/app/components/pagination'
import {useQueryResponseLoading} from '@/app/modules/accounts/users-list/core/QueryResponseProvider'
import {useQueryRequest} from '@/app/modules/accounts/users-list/core/QueryRequestProvider'
import {
  supplierApplicationListGet,
  supplierInfoGet,
  supplierApplication,
  supplierApplicationCancel,
  newNotificationRegister,
} from 'src/app/modules/api'
import {useAuth} from 'src/app/modules/auth'
import {useNavigate} from 'react-router-dom'
import {useIsWidthSize, formatNumber, getParameterStore} from 'src/app/modules/utils'
import moment from 'moment'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'ダッシュボード',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const statusObj: any = {
  unapplied: '未申请',
  pending: '承認待ち',
  non_approval: '非承認',
  approval: '承認済',
}

export default function SupplierList() {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>仕入先申請一覧</PageTitle>
      <Pageconts />
    </>
  )
}

const Pageconts: FC = () => {
  const [parameters, setParameters] = useState<any>({})
  const navigate = useNavigate()
  const isLoading = useQueryResponseLoading()
  const {setContentLoading} = useLayout()
  const [isCanLoading, setIsCanLoading] = useState<boolean>(false) // 加载状态
  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()
  const [keyWords, setKeyWords] = useState<string>('')
  const [notify, setNotify] = useState<any>('')
  const [data, setData] = useState<any>([]) // 数据列表
  const [queryParams, setQueryParams] = useState<any>({
    pageNum: 1,
    pageSize: 10,
    total: 0,
  }) // 分页参数
  const {auth} = useAuth()
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }
  const filterData = () => {
    // updateState({
    //   filter: {role, last_login: lastLogin},
    // })
  }
  const resetData = () => {
    //updateState({filter: undefined})
    getAllList(role)
  }
  const {updateState} = useQueryRequest()
  // 申请/取消
  const bindClickApply = async (data: any, isCancel?: any) => {
    let params = {
      supplier_ids: data.pk,
      user_id: auth.username,
      // buyer_pk: auth.group_id,
    }
    if (isCancel) {
      await supplierApplicationCancel(params, {headers})
    } else {
      console.log('pppsupplier', data, params)

      await supplierApplication(params, {headers})
    }

    getAllList()
  }
  const bindChangeNotify = async (item: any) => {
    let params = {
      status: item.notificationInfo_status === 'enabled' ? 'disabled' : 'enabled',
      update_user: auth.username,
      buyer_id: item.buyer_id,
      supplier_id: item.supplier_id,
    }
    await newNotificationRegister(params)
    getAllList()
  }
  const currentPage = (pageNum: any, pageSize: any) => {
    queryParams.pageNum = pageNum
    queryParams.pageSize = pageSize
    getAllList()
  }
  // 获取列表数据
  const getAllList = async (status?: any) => {
    // setIsCanLoading(true)
    setContentLoading(true)
    try {
      let res: any
      res = await supplierApplicationListGet(
        {
          status: status ? status : role,
          ...queryParams,
        },
        headers
      )

      setData(res.rows || [])
      setQueryParams({...queryParams, total: res.total})
      setContentLoading(false)
    } catch (error) {
      console.error(error)
      setContentLoading(false)
    }
  }

  const initParameters = async () => {
    let res: any = await getParameterStore({
      names: ['/Define/Supplierapplications/Status'],
    })

    setParameters(res)
  }

  useEffect(() => {
    setContentLoading(true)
    initParameters()
  }, [])

  useEffect(() => {
    if (Object.keys(parameters).length > 0) {
      getAllList()
    }
  }, [parameters])

  return (
    <>
      {useIsWidthSize('lg') ? (
        <>
          <div
            className='d-flex flex-stack flex-column flex-sm-row align-items-start mb-6'
            style={{gap: '10px 0'}}
          >
            <div>
              <div className='position-relative w-md-400px me-md-2 d-flex align-items-center'>
                <i className='fa-solid fa-magnifying-glass s-1 position-absolute ms-6'></i>
                <input
                  type='text'
                  className='form-control ps-12'
                  name='search'
                  placeholder='仕入先を検索'
                  onChange={(e) => setKeyWords(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.keyCode === 13) {
                      queryParams.customer_info = keyWords
                      getAllList()
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <button
                disabled={isLoading}
                type='button'
                className='btn btn-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
              >
                <i className='fa-solid fa-filter fs-5 me-3'></i>
                フィルター
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                data-kt-menu='true'
              >
                <div className='separator border-gray-200'></div>

                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                  <div className='mb-10'>
                    <label className='form-label fs-6 fw-bold'>承認状況:</label>
                    <select
                      className='form-select fw-bolder'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      data-kt-user-table-filter='role'
                      data-hide-search='true'
                      onChange={(e) => setRole(e.target.value)}
                      value={role}
                    >
                      <option value=''>全て</option>
                      {Object.keys(statusObj).map((item: any, index: any) => {
                        return (
                          <option key={index} value={item}>
                            {statusObj[item]}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button
                      type='button'
                      disabled={isLoading}
                      onClick={filterData}
                      className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='reset'
                    >
                      キャンセル
                    </button>
                    <button
                      disabled={isLoading}
                      type='button'
                      onClick={resetData}
                      className='btn btn-primary fw-bold px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='filter'
                    >
                      適用
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-lg-5 mb-9'>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3  text-center'>
                  <thead>
                    <tr className='fw-bold text-dark'>
                      <th className='min-w-150px'>仕入先名</th>
                      <th className='min-w-150px'>仕入先ID</th>
                      <th className='min-w-150px'>ジャンル</th>
                      <th className='min-w-150px'>申請日時</th>
                      <th className='min-w-150px'>承認状況</th>
                      <th className='min-w-150px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item: any, index: any) => {
                        return (
                          <Fragment key={index}>
                            <tr>
                              <td className='text-dark  '>{item.company_name}</td>
                              {/* <td className='text-dark  '>{item.website_url}</td> */}
                              <td className=''>{item.display_id}</td>
                              <td className=''>{item.main_genre}</td>
                              <td>
                                {item.application_date &&
                                  moment(item.application_date).format('YYYY-MM-DD HH:mm:ss')}
                              </td>
                              <td>
                                {parameters['/Define/Supplierapplications/Status']?.map(
                                  (v: any) => {
                                    if (v.value === item.status) {
                                      return (
                                        <span
                                          className={`badge badge-lg badge-${v.color} justify-content-center py-3 px-4  me-2`}
                                        >
                                          {v.label}
                                        </span>
                                      )
                                    }
                                  }
                                )}
                              </td>
                              <td>
                                <a
                                  href='#'
                                  onClick={(e) => {
                                    e.preventDefault()
                                    let pk = item.pk.split('#').pop()
                                    navigate(`/supplier/${pk}`)
                                  }}
                                  className='btn btn-white btn-outline'
                                >
                                  詳細情報・取引申請
                                </a>
                              </td>
                            </tr>
                          </Fragment>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={17}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            {isCanLoading ? 'データの取得中' : '該当するレコードがありません。'}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {queryParams.total != 0 && (
                  <Pagination
                    currentPage={currentPage}
                    page={queryParams.pageNum}
                    pageSize={queryParams.pageSize}
                    total={queryParams.total}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <SupplierListSP data={data} parameters={parameters} />
      )}
    </>
  )
}

const SupplierListSP: React.FC<any> = ({data, parameters}) => {
  const navigate = useNavigate()

  return (
    <>
      {data.length > 0 &&
        data.map((item: any, index: number) => {
          return (
            <div className='row mb-6'>
              <div className='col-12 fs-7 mb-2'>
                {/* 注文日 {moment(item.order_date).format('YYYY-MM-DD HH:mm:ss')} */}
              </div>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row mb-2 border-bottom mb-2 pb-2'>
                      <div className='col-12 fs-4 fw-bold d-flex align-items-center'>
                        {parameters['/Define/Supplierapplications/Status']?.map((v: any) => {
                          if (v.value === item.status) {
                            return (
                              <span
                                className={`badge badge-${v.color} justify-content-center py-3 me-2`}
                              >
                                {v.label}
                              </span>
                            )
                          }
                        })}
                        {/* {item.status_name ? (
                          <span
                            className={`badge badge-${item.status_color} justify-content-center py-3 me-2`}
                          >
                            {item.status_name}
                          </span>
                        ) : (
                          <span className={`badge badge-light justify-content-center py-3 me-2`}>
                            {'未申請'}
                          </span>
                        )} */}
                        {item.company_name}
                      </div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-4 fs-7 '>仕入先ID</div>
                      <div className='col-8 fs-7 '>{item.display_id}</div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-4 fs-7 '>取扱ジャンル</div>
                      <div className='col-8 fs-7 '>{item.main_genre}</div>
                    </div>
                    <div className='row mb-2'>
                      <div className='col-4 fs-7 '>申請日時</div>
                      <div className='col-8 fs-7 '>
                        {item.application_date &&
                          moment(item.application_date).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12 d-flex justify-content-center'>
                        <a
                          href='#'
                          className='btn btn-white btn-outline w-75'
                          onClick={(e) => {
                            e.preventDefault()
                            let pk = item.pk.split('#').pop()
                            navigate(`/supplier/${pk}`)
                          }}
                        >
                          詳細情報・取引申請
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
    </>
  )
}
