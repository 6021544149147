import { getServer, postServer, deleteServer } from 'src/service/http'

// 条件に応じて商品カテゴリの一覧を取得する
export function labelListGet(param: object) {
  return getServer(`/label-list-get`, { ...param })
}

// ラベル登録
export function labelAdd(param: object, headers: object = {}) {
  return postServer(`/label-add`, { ...param }, { ...headers})
}

// 商品カテゴリの詳細表示する
export function labelInfoGet(param: object) {
  return postServer(`/label-info-get`, { ...param })
}

// 商品カテゴリーを更新する
export function labelUpdate(param: object, headers: object = {}) {
  return postServer(`/label-update`, { ...param }, { ...headers})
}

// 商品カテゴリーを削除する
export function labelDelete(param: object) {
  return deleteServer(`/label-delete`, { ...param })
}