import React, {useEffect, useState} from 'react'
import ReactEcharts from 'echarts-for-react'
import Reports from './components/echarts'
import {getMonthlyReportist} from 'src/app/modules/api'
// import { Pagination } from 'antd';
import {Pagination} from 'src/app/components/pagination'
import './Report.scss'
import {useAuth} from 'src/app/modules/auth'

const options = [
  {id: '0', name: '1 Hours'},
  {id: '1', name: '2 Hours'},
  {id: '2', name: '3 Hours'},
  {id: '3', name: '4 Hours'},
  {id: '4', name: '5 Hours'},
]

const Report: React.FC = () => {
  const {auth} = useAuth()
  let headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }
  const [selectType, setSelectType] = useState<string>('') // 左上角选择框
  const [data, setData] = useState<any>([]) // 总数据
  const [searchParams, setSearchParams] = useState<any>({
    pageNum: 1,
    pageSize: 10,
  })
  const [tableTotal, setTableTotal] = useState<number>(0)
  const [thisMonthData, setThisMonthData] = useState<any>({})

  const getData = (pageNum: any, pageSize: any) => {
    getMonthlyReportist(
      {
        pageNum,
        pageSize,
      },
      headers
    ).then((res: any) => {
      // 检索当月数据
      // let findData = res.rows.find((item: any) => {
      //   let date = new Date()
      //   let year = date.getFullYear()
      //   let month = date.getMonth() + 1
      //   let nowDate = `${year}-${month}`
      //   return item.last_payment_date.indexOf(nowDate) !== -1
      // })

      // if (findData) {
      //   setThisMonthData(findData)
      // }

      setData(res.rows || [])
      setTableTotal(res.total || 0)
    })
  }

  useEffect(() => {
    getData(1, 10)
  }, [searchParams])

  return (
    <div className='container-fluid'>
      <form>
        {/* <div className='card mb-6 shadow-sm'>
          <div className='d-flex align-items-center card-header'>
            <span className='card-title fw-bold m-0'>今月レポートサマリー</span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <div
                  className='d-flex flex-wrap  mb-3 mt-5'
                  style={{display: 'flex', justifyContent: 'space-between'}}
                >
                  <div className='border border-gray-300 border-dashed rounded py-3 px-20 me-6 mb-3 '>
                    <div style={{textAlign: 'center'}}>
                      <div className='fs-6' style={{color: 'lightgreen'}}>
                        利用金額
                      </div>
                      <div className='fw-bold fs-4'>154600円</div>
                    </div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded py-3 px-20 me-6 mb-3'>
                    <div style={{textAlign: 'center'}}>
                      <div className='fs-6' style={{color: '#009ef7'}}>
                        注文回数
                      </div>
                      <div className='fw-bold fs-4'>{thisMonthData.len || 0}回</div>
                    </div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded py-3 px-20 me-6 mb-3'>
                    <div style={{textAlign: 'center'}}>
                      <div className='fs-6' style={{color: 'red'}}>
                        業務効率時間
                      </div>
                      <div className='fw-bold fs-4'>14.5時間</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>利用金額</span>
            </div>
            <div>
              <Reports />
              <Reports className='mb-5 mb-xxl-8' />
            </div>
          </div>
        </div> */}
        <div className='card mb-6 shadow-sm'>
          <div className='d-flex align-items-center card-header d-flex flex-wrap'>
            <span className='card-title fw-bold m-0'>レポート</span>
            {/* <div className="card-title fw-bold m-0 d-flex flex-wrap"> */}
            {/*  <select className="form-select form-select-sm w-250px me-4" data-control="select2" */}
            {/*    style={{ width: 200 }} */}
            {/*    value={selectType} */}
            {/*    onChange={(e) => { */}
            {/*      setSelectType(e.target.value); */}
            {/*    }} */}
            {/*  > */}
            {/*    <option value={''}></option> */}
            {/*    { */}
            {/*      options.length !== 0 && options.map((item: any, index: number) => { */}
            {/*        return ( */}
            {/*          <option value={item.id} key={item.id}>{item.name}</option> */}
            {/*        ); */}
            {/*      }) */}
            {/*    } */}
            {/*  </select> */}

            {/*  <button */}
            {/*    className="btn btn-icon btn-primary w-80px btn-sm" */}
            {/*    type="button" */}
            {/*    onClick={() => { */}

            {/*    }} */}
            {/*  > */}
            {/*    View All */}
            {/*  </button> */}
            {/* </div> */}
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <table className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer'>
                  <thead>
                    <tr className='text-start fs-6 text-uppercase gs-0'>
                      <th>年月日</th>
                      <th>注文回数</th>
                      <th>利用金額（税込）</th>
                      <th>支払い済み合計（税込）</th>
                      <th>支払い待ち合計（税込）</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{item.monthStr}</td>
                            <td>{item.order.count}回</td>
                            <td>{item.payment.total}円</td>
                            <td>{item.payment.total_complete}円</td>
                            <td>{item.payment.total_before}円</td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={3}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'></div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div style={{marginTop: '20px'}}>
              <Pagination
                currentPage={getData}
                page={searchParams.pageNum}
                pageSize={searchParams.pageSize}
                total={tableTotal}
              />
            </div> */}
          </div>
        </div>
      </form>
    </div>
  )
}

export {Report}
