import React, {useEffect, useState} from 'react'
import DisplayCards from '../module/displayCards';

export default function BankCardPaymentPlan(props: any) {
  // console.log('bankCardPaymentPlan', props)

  useEffect(() => {}, [])

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header card-header-stretch pb-0 d-flex flex-wrap'>
          <div className='card-title'>
            <h3 className='m-0'>決済方法の選択</h3>
          </div>
        </div>
        <div id='kt_billing_payment_tab_content' className='card-body tab-content'>
          <div
            id='kt_billing_creditcard'
            className='tab-pane fade show active'
            role='tabpanel'
            aria-labelledby='kt_billing_creditcard_tab'
          >
            <h3 className='mb-5'>カード情報</h3>
            <DisplayCards getCardData={props.getCardData} type={'GMOPG'} isEdit={false} />
          </div>
        </div>
      </div>
    </>
  )
}
