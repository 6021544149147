/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, FC, useState} from 'react'
import {useLocation, useParams, useSearchParams, useNavigate} from 'react-router-dom'
import moment from 'moment'

export const CustomerInfo = ({customeItem, customerOptions}: any) => {
  const {id} = useParams()
  const currentLocation = useLocation()
  const [getSearchArr] = useSearchParams()
  const searchValue = getSearchArr.getAll('copy')[0]
  const [userdata, setUserdata] = useState<any>({
    store_status: '',
    registration_date: '',
    customer_id: '',
    company_name: '',
    representative: '',
    contact_person: '',
    main_email_address: '',
    telephone_number: '',
    mobile_phone_number: '',
    fax_number: '',
    payment_method: '',
    establishment_date: '',
    actual_industry: '',
    website_url: '',
    physical_store: '',
    email_notifications: '',
    display_id:''
  }) // 表单数据

  useEffect(() => {
    initData()
  }, [customeItem])

  // 获取列表数据
  const initData = () => {
    console.log('customeItem', customeItem)
    if (customeItem && customeItem.customer_id) {
      let obj: any = {}
      if (customerOptions.length > 0) {
        obj = customerOptions?.find((e: any) => e.group_id == userdata?.group_id)
      }
      console.log('obj', obj)
      setUserdata({...userdata, ...customeItem, group_name: obj?.group_name})
    }
  }

  return (
    <>
      <div className='card-header card-header-stretch'>
        <h3 className='card-title fs-2 fw-bold'>顧客情報詳細</h3>
      </div>

      <div className='card-body'>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>登録年月日</label>
          <div className='col-lg-8'>
            <span className='fs-6 text-dark'>
              {userdata?.create_at && moment(userdata?.create_at).format('YYYY-MM-DD')}
            </span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>顧客ID</label>
          <div className='col-lg-8 fv-row'>
            <span className='fs-6'>{userdata?.display_id}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>会社名</label>
          <div className='col-lg-8 fv-row'>
            <span className='fs-6'>{userdata?.company_name}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>代表者</label>
          <div className='col-lg-8 fv-row'>
            <span className='fs-6'>{userdata?.representative}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>部署名</label>
          <div className='col-lg-8 fv-row'>
            <span className='fs-6'>{userdata?.department_name}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>担当者</label>
          <div className='col-lg-8 fv-row'>
            <span className='fs-6'>{userdata?.contact_person}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>担当者 フリガナ</label>
          <div className='col-lg-8 fv-row'>
            <span className='fs-6'>{userdata?.contact_person_kana}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>メールアドレス</label>
          <div className='col-lg-8 fv-row'>
            {userdata?.main_email_address}
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>住所</label>
          <div className='col-lg-8 d-flex align-items-center'>
            <span className='fs-6 me-2'>
              {userdata?.address_postal_code}
              {userdata?.address_prefecture}
              {userdata?.address_city}
              {userdata?.address_street}
              {userdata?.address_building_name}
            </span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>電話番号</label>
          <div className='col-lg-8'>
            <a href={`tel:${userdata?.telephone_number}`} className='fs-6 text-dark text-hover-primary'>
              {userdata?.telephone_number}
            </a>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>携帯番号</label>
          <div className='col-lg-8'>
            <span className='fs-6 text-dark'>{userdata?.mobile_phone_number}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>FAX番号</label>
          <div className='col-lg-8'>
            <span className='fs-6 text-dark'>{userdata?.fax_number}</span>
          </div>
        </div>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-dark'>顧客ランク</label>
          <div className='col-lg-8'>
            <span className='fs-6 text-dark'>
              {customerOptions.find((e: any) => e.group_id == userdata.group_id)?.group_name ||
                userdata.group_id}
            </span>
          </div>
        </div>
        {/*<div className='row mb-10'>*/}
        {/*  <label className='col-lg-4 fw-bold text-dark'>表示グループ</label>*/}
        {/*  <div className='col-lg-8'>*/}
        {/*    <span className='fs-6'></span>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* <div className='row mb-10'>
          <label className='col-lg-4 fw-bold text-dark'>決済方法</label>
          <div className='col-lg-8'>
            <span className='fs-6'>{userdata?.payment_method}</span>
          </div>
        </div> */}
        <div className='row mb-10'>
          <label className='col-lg-4 fw-bold text-dark'>設立年月</label>
          <div className='col-lg-8'>
            <span className='fs-6'>{userdata?.establishment_date}</span>
          </div>
        </div>
        <div className='row mb-10'>
          <label className='col-lg-4 fw-bold text-dark'>実態/業種</label>
          <div className='col-lg-8'>
            <span className='fs-6'>{userdata?.actual_industry}</span>
          </div>
        </div>
        <div className='row mb-10'>
          <label className='col-lg-4 fw-bold text-dark'>ホームページURL</label>
          <div className='col-lg-8'>
            <a href='#' className='fs-6'>
              {userdata?.website_url}
            </a>
          </div>
        </div>
        <div className='row mb-10'>
          <label className='col-lg-4 fw-bold text-dark'>実店舗</label>
          <div className='col-lg-8'>
            <span className='fs-6'>{userdata?.physical_store}</span>
          </div>
        </div>
        <div className='row mb-10'>
          <label className='col-lg-4 fw-bold text-dark'>メール案内</label>
          <div className='col-lg-8'>
            <span className='fs-6'>{userdata?.email_notifications}</span>
          </div>
        </div>
      </div>
    </>
  )
}
