import { useEffect, useState } from 'react'
import { KTCard } from '@/_metronic/helpers'
import { useNavigate } from 'react-router-dom'
import { showSuccessMessage, showErrorMessage, showWarningMessage } from '@/app/components/sweetAlert';
import { Pagination } from '@/app/components/pagination'
import { Loading } from '@/app/components/loading'
import { CustomFieldActionsCell } from './tabel/endPointActionsCell'
import { customerRankListGet } from 'src/app/modules/api'
import { useAuth } from 'src/app/modules/auth'

const options = [
  { id: '0', name: 'コピーを作成する' },
  { id: '1', name: '削除' },
]

export default function TaxSetting() {
  const navigate = useNavigate();
  const [datas, setDatas] = useState<any>([]) // 数据列表
  const [selectType, setSelectType] = useState<string>('') // 左上角选择框
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
  const [selectedList, setSelectList] = useState<any>([]) // 选中的项
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    pageSize: 10,
    total: 0
  }) // 分页参数

  const {auth} = useAuth();
  let headers = {
    "Content-Type":"application/json",
    Authorization:`Bearer ${auth.token}`
  }

  useEffect(() => {
    initData()
  }, [])

  // 获取列表数据
  const initData = () => {
    getData(queryParams.page, queryParams.pageSize)
  }

  // 获取列表数据
  const getData = async (pageNum: any, pageSize: any) => {
    // console.log('pageNum', pageNum, pageSize)
    setIsLoading(true)
    try {
      const res: any = await customerRankListGet({ pageNum, pageSize },headers)
      console.log('res', res)
      setQueryParams({ ...queryParams, total: res.total })
      setDatas(res.rows);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error("error", error);
    }
  }

  // 適用
  const apply = () => {
    if (selectType === '1') {
      showWarningMessage('元に戻すことはできません。よろしいでしょうか？', () => {
        // for(let i = 0; i < selectedList.length; i++){
        //   let idx = arr.findIndex((item:any)=>{
        //     return item.id === selectedList[i]
        //   })
        //   if(idx !== -1){
        //     arr.splice(idx,1)
        //   }
        // }
        // getAllList()
        showSuccessMessage('削除しました')
      }, "削除", "キャンセル")
    }
  }
  // 改变选中列表
  const changeSelectList = (obj: any) => {
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = []
        datas.forEach((item: any) => {
          arr.push(item.pk)
        });
        setSelectList(arr)
      } else {
        selectedList.push(obj.id)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([])
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id
        })
        selectedList.splice(idx, 1)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    }
  }

  const goDetail = async (item: any) => {
    navigate(`/customer/rank/${item.group_id}`, { state: item.pk })
  }


  const [formData, setformData] = useState<any>([
    {
      title: '税表示',
      type: 'drop-downBox',
      edit: true,
      value: '0',
      hidden: false,
      dropDownOption: [
        '税抜表示（外税）',
        '税込表示（内税）',
      ],
    },
    {
      title: '端数の計算方法',
      type: 'drop-downBox',
      edit: true,
      value: '0',
      hidden: false,
      dropDownOption: [
        '切り捨て',
        '切り上げ',
        '四捨五入',
      ],
    },
    {
      title: 'インボイス対応',
      type: 'input',
      edit: true,
      value: '33538',
      hidden: true,
      rules: ['[a-zA-Z]', '[0-9]'],
      formError: false,
    },
    {
      title: '消費税の算出方法',
      type: 'input',
      edit: true,
      value: '33538',
      hidden: true,
      rules: ['[a-zA-Z]', '[0-9]'],
      formError: false,
    }
  ])

  return (
    <div style={{ position: 'relative' }}>
      <KTCard resetSidePaddings={true}>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <select className="form-select " data-control="select2"
              style={{ width: 200 }}
              value={selectType}
              placeholder='一括操作'
              onChange={(e) => { setSelectType(e.target.value) }}
            >
              <option value={''}></option>
              {
                options.length !== 0 && options.map((item: any, index: number) => {
                  return (
                    <option value={item.id} key={item.id}>{item.name}</option>
                  )
                })
              }
            </select>
            <button type='button' className='btn btn-primary'
              style={{ marginLeft: 10 }}
              onClick={() => { apply() }}>
              適用
            </button>
          </div>
          <button type='button' className='btn btn-primary'
            onClick={() => navigate('/customer/rank/id')}>
            新規グループ登録
          </button>
        </div>
        <div className="table-responsive " style={{ margin: '0px 30px 50px 30px ' }}>
          <table className="table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer">
            <thead className='table-light'>
              <tr className="text-start fs-6 text-uppercase gs-0">
                <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      data-kt-check-target='#kt_table_users .form-check-input'
                      checked={selectedList.length === datas.length && selectedList.length !== 0}
                      onChange={(e) => {
                        let obj = {
                          id: 'all',
                          value: e.target.checked
                        }
                        changeSelectList(obj)
                      }}
                    />
                  </div>
                </th>
                <th>名前</th>
                <th>税率</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {
                datas.length > 0 ? datas.map((item: any, index: number) => {
                  return (
                    <tr key={item.pk}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check-target='#kt_table_users .form-check-input'
                            checked={selectedList.includes(item.pk)}
                            onChange={(e) => {
                              let obj = {
                                id: item.pk,
                                value: e.target.checked
                              }
                              changeSelectList(obj)
                            }}
                          />
                        </div>
                      </td>
                      <td>{item.group_id}</td>
                      <td><a href="#" onClick={() => goDetail(item)}>{item.group_name}</a></td>
                      <td>{item.default}</td>
                      <td style={{ minWidth: 90 }}><CustomFieldActionsCell getAllList={initData} item={item} nowPage={queryParams.page}></CustomFieldActionsCell></td>
                    </tr>
                  )
                }) : (
                  <tr>
                    <td colSpan={17}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                      </div>
                    </td>
                  </tr>
                )
              }

            </tbody>
          </table>
          {queryParams.total != 0 &&
            <Pagination currentPage={getData} page={queryParams.page} pageSize={queryParams.pageSize} total={queryParams.total} />
          }
        </div>
        {
          isLoading && <Loading></Loading>
        }
      </KTCard>

      <div className='card card-custom my-6'>
        <div className='card-body'>
          <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
            <div className='mb-5' style={{width: '100%'}}>
              <div
                className='accordion-header py-3 d-flex'
                data-bs-toggle='collapse'
                data-bs-target={'#kt_accordion_2_item'}
                style={{background: '#f2f2f2'}}
              >
                <h3 className='card-title fw-bolder fs-3 '>税設定</h3>
              </div>

              <div
                id={'kt_accordion_2_item'}
                className='fs-6 collapse show ps-10 my-5'
                data-bs-parent='#kt_accordion_2'
              >
                {formData.map((item: any, idx: number) => {
                  return (
                    <div className='mb-6' key={idx}>
                      <div className='row'>
                        <label className='col-lg-4 col-form-label  fs-6'>
                          <span>{item.title}</span>
                        </label>

                        <div
                          className='col-lg-8 fv-row flex-wrap'
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          {item.type === 'input' ? (
                            <div className='col-lg-10 fv-row'>
                              <input
                                className='form-control form-control-lg'
                                style={{minWidth: '263px'}}
                                type='text'
                                readOnly={item.edit}
                                value={item.value}
                                // onChange={(e) => {
                                //   let data = [...formData]
                                //   data[idx].value = e.target.value
                                //   const regStr = funcRules(item.rules)
                                //   const reg = new RegExp(regStr)
                                //   data[idx].formError = !reg.test(e.target.value)
                                //   setformData(data)
                                // }}
                              />
                              {item.formError && (
                                <div className='text-danger' style={{paddingLeft: '13px'}}>
                                  {' '}
                                  書式が間違っています{' '}
                                </div>
                              )}
                            </div>
                          ) : item.type === 'drop-downBox' ? (
                            <div className='col-lg-10 fv-row ' style={{minWidth: '263px'}}>
                              <select
                                className='form-select form-select-lg'
                                value={item.value}
                                onChange={(e) => {
                                  formData[idx].value = e.target.value
                                  let newformData = [...formData]
                                  setformData(newformData)
                                }}
                                disabled={item.edit}
                              >
                                {item.dropDownOption.map((it: any, ind: number) => {
                                  return (
                                    <option value={ind} key={ind}>
                                      {it}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                          ) : (
                            <span>{item.value}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}

                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
