import React, {useEffect, useState, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import {formatNumber} from 'src/app/modules/utils'

/*
 * 決済画面へのリダイレクトを行うモーダル
 */

interface SectionSuppliersRedirectModalProps {
  show: boolean
  handleShow: () => void
  handleHide: () => void
  handleSubmit: () => void
  redirectUrl: string
  order_id: string
  loading: boolean
  payment_method: string
  supplier_id: string
  company_name: string
  amount: string
  index: number
  max: number
  status: string
}

export const SupplierCheckoutModal: React.FC<SectionSuppliersRedirectModalProps> = ({...props}) => {
  console.log('props', props)

  const [proccesing, setProccesing] = useState(false)

  const statusList = useRef<any>({
    pending: {
      text: '認証待ち',
      class: '',
      style: {},
    },
    ordering: {
      text: '注文作成中',
      class: 'text-primary',
      style: {},
    },
    payment: {
      text: '支払中',
      class: 'text-primary',
      style: {},
    },
    in_progress: {
      text: '処理中',
      class: 'text-primary',
      style: {},
    },
    order_success: {
      text: '注文完了',
      class: 'text-success',
      style: {},
    },
    payment_success: {
      text: '支払完了',
      class: 'text-success',
      style: {},
    },
    error: {
      text: '失敗',
      class: '',
      style: {color: 'red'},
    },
  })

  return (
    <>
      <Modal
        centered={true}
        show={props.show}
        onHide={props.handleHide}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            注文処理 {props.index}/{props.max}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-12 mb-6'>
              クレジットカードでの決済中にセキュリティのため認証が必要になる場合があります。認証ボタンが表示された際は認証画面へお進みください。
            </div>
            <div className='col-12'>
              支払先：<span className='fw-bold'>{props.company_name}</span>
            </div>
            <div className='col-12'>
              支払い方法：
              <span className='fw-bold'>
                {props.payment_method === 'credit_card' && 'クレジットカード'}
                {props.payment_method === 'later_payment' && '後Pay'}
              </span>
            </div>
            <div className='col-12'>
              お支払い金額：
              <span className='fw-bold' style={{color: 'red'}}>
                ￥{formatNumber(props.amount)}
              </span>
            </div>
            <div className='col-12'>
              ステータス：
              {statusList.current[props.status] && (
                <span
                  className={`fw-bold ${statusList.current[props.status].class}`}
                  style={statusList.current[props.status].style}
                >
                  {statusList.current[props.status].text}
                </span>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {props.status === 'ordering' && (
            <button type='button' className='btn btn-light' disabled>
              注文中
            </button>
          )}
          {props.status === 'payment' && (
            <button type='button' className='btn btn-light' disabled>
              支払中
            </button>
          )}
          {props.status === 'in_progress' && (
            <button type='button' className='btn btn-light' disabled>
              処理中
            </button>
          )}
          {props.status === 'pending' && (
            <>
              {/* <button type='button' className='btn btn-light' onClick={props.handleHide}>
                キャンセル
              </button> */}

              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  setProccesing(true)
                  props.handleSubmit()
                }}
              >
                認証画面を開く
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}
