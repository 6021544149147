import React, {useState, useEffect} from 'react'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from '@/app/components/sweetAlert'
import {useNavigate} from 'react-router-dom'
import {KTSVG} from '@/_metronic/helpers'
import {subscriptionInfoGet} from 'src/app/modules/api'
import {Spin} from 'antd'

export default function Plan() {
  const navigate = useNavigate()
  const [showType, setShowType] = useState<any>(false)
  const [selectItem, setSelectItem] = useState<any>(1)
  const [data, setData] = useState<any>([])
  const [monthList, setMonthList] = useState<any>([])
  const [yearList, setYearList] = useState<any>([])
  const [loading, setLoading] = useState<any>(false)

  const init = async () => {
    console.log(5555)
    setLoading(true)
    try {
      let usinfo = localStorage.getItem('supplier')
      if (usinfo) {
        usinfo = JSON.parse(usinfo)
      }
      console.log('--------------', usinfo)

      const yueYueResponse: any = await subscriptionInfoGet({
        //　毎月、年間
        duration: '毎月',
      })
      const duringTheYearResponse: any = await subscriptionInfoGet({
        //　毎月、年間
        duration: '年間',
      })

      console.log('毎月', yueYueResponse)
      console.log('年間', duringTheYearResponse)

      const formattingYueYueResponse = yueYueResponse.rows.map((item: any) => {
        return {
          ...item,
          NEWdata_constraints: JSON.parse(item.data_constraints),
          NEWpay_per_use: JSON.parse(item.pay_per_use),
          NEWfeatures: JSON.parse(item.features),
        }
      })
      const formattingDuringTheYearResponse = duringTheYearResponse.rows.map((item: any) => {
        return {
          ...item,
          NEWdata_constraints: JSON.parse(item.data_constraints),
          NEWpay_per_use: JSON.parse(item.pay_per_use),
          NEWfeatures: JSON.parse(item.features),
        }
      })
      setMonthList(formattingYueYueResponse)
      setYearList(formattingDuringTheYearResponse)
      setData(formattingYueYueResponse)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      //   setData(monthList)
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      <style>
        {`
        @keyframes fadeInAnime{
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
        `}
      </style>

      <Spin spinning={loading} tip='Processing' size='large'>
        <div className='card' id='kt_pricing'>
          <div className='card-body p-lg-17'>
            <div className='d-flex flex-column'>
              <div className='bg-light nav-group nav-group-outline mx-auto mb-15'>
                <button
                  className={
                    showType === false
                      ? 'btn btn-dark fw-bold py-3 px-5 m-1 rounded-pill'
                      : 'btn btn-light fw-bold py-3 px-5 m-1 rounded-pill'
                  }
                  onClick={() => {
                    setShowType(false)
                    setData(monthList)
                  }}
                >
                  毎月
                </button>
                <button
                  className={
                    showType === true
                      ? 'btn btn-dark fw-bold py-3 px-5 m-1 rounded-pill'
                      : 'btn btn-light fw-bold py-3 px-5 m-1 rounded-pill'
                  }
                  onClick={() => {
                    setShowType(true)
                    setData(yearList)
                  }}
                >
                  年間
                </button>
              </div>

              <div className='row g-10'>
                {data.length !== 0 &&
                  data.map((item: any, index: number) => {
                    return (
                      <>
                        <div className='col-xl-4'>
                          <div
                            className='d-flex h-100 align-items-center'
                            style={
                              selectItem === index
                              ? {animation: 'fadeInAnime 1s ease'}
                              : {}
                            }
                            onClick={() => {
                              setSelectItem(index)
                            }}
                          >
                            <div
                              className={
                                selectItem === index
                                  ? 'w-100 d-flex flex-column flex-center rounded-3 bg-primary card-rounded bg-opacity-75 py-15 px-10'
                                  : 'w-100 d-flex flex-column flex-center rounded-3 bg-light bg-opacity-75 py-15 px-10'
                              }
                            >
                              <div
                                className={
                                  selectItem === index
                                    ? 'text-white fs-3 fw-bold mb-7 text-center'
                                    : 'text-primary fs-3 fw-bold mb-7 text-center'
                                }
                              >
                                {item.name}
                                <div className='fs-5x text-white d-flex justify-content-center align-items-start'>
                                  <div
                                    className={
                                      selectItem === index
                                        ? 'lh-sm fw-semibold px-5'
                                        : 'fs-3x fw-bold text-primary text-danger px-5'
                                    }
                                  >
                                    {item.fee * (Number(item.tax_rate) / 100) + Number(item.fee)}円
                                  </div>
                                </div>
                                <div
                                  className={
                                    selectItem === index
                                      ? 'text-white fw-bold mb-7'
                                      : 'text-dark fw-bold mb-7'
                                  }
                                >
                                  {showType ? '（税込）/ 年' : '（税込）/ 月'}
                                </div>
                              </div>

                              <div className='w-100 mb-10'>
                                <div className='d-flex align-items-center  mb-5'>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'fw-bold fs-3 pe-3 text-white'
                                        : 'fw-bold fs-3 pe-3'
                                    }
                                  >
                                    初期費用
                                  </span>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'flex-root d-flex justify-content-end text-white'
                                        : 'flex-root d-flex justify-content-end'
                                    }
                                  >
                                    {item.initial_fee}
                                  </span>
                                </div>

                                <div className='d-flex align-items-center mb-5'>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'fw-bold fs-3 pe-3 text-white'
                                        : 'fw-bold fs-3 pe-3'
                                    }
                                  >
                                    {data.length !== 0
                                      ? data[0].NEWdata_constraints[0].title
                                      : 'ログインユーザー数'}
                                  </span>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'flex-root d-flex justify-content-end text-white'
                                        : 'flex-root d-flex justify-content-end'
                                    }
                                  >
                                    {item.NEWdata_constraints[0].limit}
                                    {item.NEWdata_constraints[0].after_text}
                                  </span>
                                </div>

                                <div className='d-flex align-items-center mb-5'>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'fw-bold fs-3 pe-3 text-white'
                                        : 'fw-bold fs-3 pe-3'
                                    }
                                  >
                                    {data.length !== 0
                                      ? data[0].NEWdata_constraints[1].title
                                      : '取引先数'}
                                  </span>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'flex-root d-flex justify-content-end text-white'
                                        : 'flex-root d-flex justify-content-end'
                                    }
                                  >
                                    {item.NEWdata_constraints[1].limit}
                                    {item.NEWdata_constraints[1].after_text}
                                  </span>
                                </div>

                                <div className='d-flex align-items-center mb-5'>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'fw-bold fs-3 pe-3 text-white'
                                        : 'fw-bold fs-3 pe-3'
                                    }
                                  ></span>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'flex-root d-flex justify-content-end text-white'
                                        : 'flex-root d-flex justify-content-end'
                                    }
                                  >
                                    {item.description}
                                  </span>
                                </div>

                                <div className='d-flex align-items-center mb-5'>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'fw-bold fs-3 pe-3 text-white'
                                        : 'fw-bold fs-3 pe-3'
                                    }
                                  >
                                    {item.NEWpay_per_use[0].title}
                                  </span>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'flex-root d-flex justify-content-end text-white'
                                        : 'flex-root d-flex justify-content-end'
                                    }
                                  >
                                    {item.NEWpay_per_use[0].base}
                                    件ごとに
                                    {item.NEWpay_per_use[0].fee}円
                                  </span>
                                </div>

                                <div className='d-flex align-items-center mb-5'>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'fw-bold fs-3 pe-3 text-white'
                                        : 'fw-bold fs-3 pe-3'
                                    }
                                  >
                                    支払い方法
                                  </span>
                                  <span
                                    className={
                                      selectItem === index
                                        ? 'flex-root d-flex justify-content-end text-white'
                                        : 'flex-root d-flex justify-content-end'
                                    }
                                  >
                                    {item.payment_method === 'credit_card'
                                      ? 'クレジットカード'
                                      : item.payment_method === 'bank_transfer'
                                      ? '銀行振込'
                                      : item.payment_method === 'bill_payment'
                                      ? '請求書払い'
                                      : ''}
                                  </span>
                                </div>

                                {item.NEWfeatures.map((it: any, ind: number) => {
                                  return (
                                    <div className='d-flex align-items-center mb-5'>
                                      <span
                                        className={
                                          selectItem === index
                                            ? 'fw-bold fs-3 pe-3 text-white'
                                            : 'fw-bold fs-3 pe-3'
                                        }
                                      >
                                        {it.title}
                                      </span>

                                      <span className='flex-root d-flex justify-content-end'>
                                        {item.NEWfeatures[ind].icon == 'disabled' && (
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen040.svg'
                                            className={
                                              selectItem === index
                                                ? 'svg-icon-danger svg-icon-2x text-white'
                                                : 'svg-icon-danger svg-icon-2x'
                                            }
                                          />
                                        )}
                                        {item.NEWfeatures[ind].icon == 'enabled' && (
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen043.svg'
                                            className={
                                              selectItem === index
                                                ? 'svg-icon-2x svg-icon-success text-white'
                                                : 'svg-icon-2x svg-icon-success'
                                            }
                                          />
                                        )}
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>

                              <button
                                className={
                                  selectItem === index
                                    ? 'btn bg-white bg-opacity-20 bg-hover-white text-hover-primary text-white fw-bold mx-auto'
                                    : 'btn btn-light-primary fw-bold mx-auto'
                                }
                                onClick={() => {
                                  if (showType) {
                                    let obj = yearList[index]
                                    localStorage.setItem('setMeal', JSON.stringify(obj))
                                    localStorage.setItem('allMealList', JSON.stringify(yearList))
                                    navigate(
                                      '/plan/input'
                                    )
                                  } else {
                                    let obj = monthList[index]
                                    localStorage.setItem('setMeal', JSON.stringify(obj))
                                    localStorage.setItem('allMealList', JSON.stringify(monthList))
                                    navigate(
                                      '/plan/input'
                                    )
                                  }
                                }}
                              >
                                申し込む
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  })}
              </div>
            </div>
            <p className='pt-5'>※ご契約後のプラン変更は、上位プランへの移行の場合に限り変更が可能です。</p>
          </div>
        </div>
      </Spin>

      <div className='card py-13 mt-6'>
        <div className='text-center'>
          <h1 className='fs-2hx fw-bold mb-5'>エンタープライズ版</h1>
          <div className='fw-semibold fs-5'>
            HatChuPayのプラットフォームをベースに、貴社オリジナル機能の実装が可能となります。
          </div>
          <div className='fw-semibold fs-5'>別途お問い合わせ下さい。</div>
          <button
            className='btn btn-primary mt-13'
            onClick={() => {
              // navigate('/static/ec/store/set/userUsageFee')
              window.open('https://www.upshare.co.jp/contact/')
            }}
          >
            お問い合わせ
          </button>
        </div>
      </div>
    </>
  )
}
