import React, {useEffect, useState, useRef} from 'react'

export default function DataTransferHistory() {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='table-responsive'>
          <table className='table table-row-bordered align-middle gy-4 gs-9'>
            <thead className='border-bottom border-gray-200 fs-6 fw-bold bg-light bg-opacity-75'>
              <tr>
                <td>接続名</td>
                <td>処理状態</td>
                <td>開始時間</td>
                <td>終了時間</td>
                <td>結果</td>
              </tr>
            </thead>
            <tbody className=''>
              <tr>
                <td>受注データ出力</td>
                <td>成功</td>
                <td>2024/08/08 10:00:00</td>
                <td>2024/08/08 10:00:03</td>
                <td>
                  <span className='text-success'>出力完了しました</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
