import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Modal } from 'react-bootstrap';
import { InfoList } from './components/InfoList'
import { BankCard } from './components/BankCard'
import {showSuccessMessage} from 'src/app/components/sweetAlert'
import { useAlert } from 'src/_metronic/layout/core'
import {supplierInfoUpdatePost,accountInfoGet} from 'src/app/modules/api'
import { useAuth } from 'src/app/modules/auth'
import { useLayout } from 'src/_metronic/layout/core'
const options1:any = [
  { id: 'disabled', label: "自動承認しない" },
  { id: 'enabled', label: "自動承認する" },
]
const options2:any = [
  { id: 0, label: "承認フローを使う" },
  { id: 1, label: "承認フローを使わない" },
]
const options3:any = [
  { id: 0, label: "目方設定利用あり" },
  { id: 1, label: "目方設定利用なし" },
  { id: 2, label: "両方あり" },
]
const options4:any = [
  { id: 0, label: "表示しない" },
  { id: 1, label: "表示する" },
]

const PaymentLogin = ({ resData }: any) => {
  
  const {setContentLoading} = useLayout()
  const { auth } = useAuth()
  const {setMessage} = useAlert()
  const [userdata, setUserdata] = useState<any>({
    // user_supplier_approval_setting: 0,
    price_change_approval_request_setting: 'disabled',
    product_weight_setting_use: 'disabled',
    out_of_stock_display_setting: 'disabled',
  }); // 表单数据
  useEffect(() => {
    //setUserdata({...resData})
    init()
  }, [])
  const [loading, setLoading] = useState(false)
  //const [data, setData] = useState(resData)
  const updateData = (fieldsToUpdate: any) => {
    const updatedData = {...formik.values, ...fieldsToUpdate}
    setUserdata(updatedData)
    formik.setValues(updatedData)
  }
  const init = async ()=>{
    setContentLoading(true)

    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token}`
    }

    try {
    const response: any = await accountInfoGet("auth.username",headers)
    userdata.price_change_approval_request_setting= response.price_change_approval_request_setting
    userdata.product_weight_setting_use= response.product_weight_setting_use
    userdata.out_of_stock_display_setting= response.out_of_stock_display_setting
    let newData = JSON.parse(JSON.stringify(userdata))
    console.log('pp----',newData);
    
    setUserdata(newData)
    setContentLoading(false)
    } catch(err) {
      setContentLoading(false)
    }
  }
  const formik: any = useFormik<any>({
    initialValues: userdata as any,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      setLoading(true)
      // accountEdit({
      let obj = values
      let data = {
        pk: resData.pk,
        update_user: resData.user_id,
        price_change_approval_request_setting: obj.price_change_approval_request_setting,
        product_weight_setting_use: obj.product_weight_setting_use,
        out_of_stock_display_setting: obj.out_of_stock_display_setting,
      }

      let headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.token}`
      }
      supplierInfoUpdatePost(data, {headers})
        .then((res: any) => {
          setLoading(false)
          setMessage({type: 'success', message: '更新しました'})
        })
        .catch((err: any) => {
          setLoading(false)
          setMessage({type: 'error', message: '更新に失敗しました'})
        })
    },
  })


  return (
    <div className="container-fluid">
      <div className="card mb-6 shadow-sm">
        <div className="d-flex align-items-center card-header">
          <span className="card-title fw-bold m-0">共通設定</span>
        </div>
        <div className="collapse show">
          <form
            id='kt_modal_add_user_form'
            className='container-fluid'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className='card-body p-9'>
              {/* <div className='mb-5'>
                <div className='form-label fs-5 fw-bold mb-4'>利用者の仕入先承認について</div>
                <div>
                  {options1.map((item:any) => {
                    return (
                      <div
                        className='form-check form-check-inline pe-3'
                        key={item.id}
                      >
                        <input
                          className='form-check-input'
                          type='radio'
                          value={item.id}
                          checked={userdata.user_supplier_approval_setting === item.id}
                          onChange={() => {
                            formik.values.user_supplier_approval_setting = item.id
                            updateData(formik.values)
                          }}
                        />
                        <label className='form-check-label'>{item.label}</label>
                      </div>
                    )
                  })}
                </div>
              </div> */}
              <div className='mb-5'>
              </div>
              <div className='mb-5'>
                <div className='form-label mb-4'>
                  <span className='fs-5 fw-bold'>商品目方設定利用　</span>
                  <span className='fs-6 text-dark'>※目方を量って販売する商品がある場合、チェックしてください</span>
                </div>
                <div>
                  {options1.map((item:any) => {
                    return (
                      <div
                        className='form-check form-check-inline pe-3'
                        key={item.id}
                      >
                        <input
                          className='form-check-input'
                          type='radio'
                          value={item.id}
                          checked={userdata.product_weight_setting_use === item.id}
                          onChange={() => {
                            formik.values.product_weight_setting_use = item.id
                            updateData(formik.values)
                          }}
                        />
                        <label className='form-check-label'>{item.label}</label>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className='mb-5'>
                <div className='form-label fs-5 fw-bold mb-4'>在庫がなくなった場合の表示</div>
                <div>
                  {options1.map((item:any) => {
                    return (
                      <div
                        className='form-check form-check-inline pe-3'
                        key={item.id}
                      >
                        <input
                          className='form-check-input'
                          type='radio'
                          value={item.id}
                          checked={userdata.out_of_stock_display_setting === item.id}
                          onChange={() => {
                            formik.values.out_of_stock_display_setting = item.id
                            updateData(formik.values)
                          }}
                        />
                        <label className='form-check-label'>{item.label}</label>
                      </div>
                    )
                  })}
                </div>
              </div>
              {/* <div className='d-flex align-items-center card-footer justify-content-end gap-5'>
                <a className='btn btn-primary'>更新</a>
              </div> */}
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' data-kt-users-modal-action='submit' className='btn btn-primary'>
                {!loading && '更新'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    更新...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            </div>
          </form>
        </div>
      </div>
      {/* <BankCard headTitle={'ご利用料金に対してのお支払方法'} />
      <InfoList /> */}
    </div>
  )
}
export { PaymentLogin }