import React from 'react';
import { FieldArray, useFormikContext } from 'formik';

interface OptionSelectorProps {
  options: { pk: string; name: string }[];
  updateData: (values: any) => void;
}

const OptionSelector: React.FC<OptionSelectorProps> = ({ options, updateData }) => {
  const formik = useFormikContext<any>();

  return (
    <div className='row mb-6'>
      <label className='col-lg-2 col-form-label fs-7'>
        <span>オプション項目</span>
      </label>
      <div className='col-lg-10 align-self-center'>
        <FieldArray
          name="options"
          render={(arrayHelpers) => (
            <>
              {formik.values.options && formik.values.options.map((item: any, index: number) => (
                <div className='row gx-0 pb-3' key={index}>
                  <div
                    className='col-lg-3'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#dfe2e9',
                      borderTopLeftRadius: '0.475rem',
                      borderBottomLeftRadius: '0.475rem',
                      borderRight: 'none',
                      padding: '5px 0',
                    }}
                  >
                    オプションを選択
                  </div>
                  <div className='col-lg-7'>
                    <select
                      className='form-select'
                      data-control='select2'
                      placeholder='選択してください'
                      value={item}
                      onChange={(e) => {
                        arrayHelpers.replace(index, e.target.value);
                        // updateData({
                        //   ...formik.values,
                        //   options: formik.values.options.map((v: string, i: number) =>
                        //     i === index ? e.target.value : v
                        //   ),
                        // });
                      }}
                      style={{
                        borderTopRightRadius: '0.475rem',
                        borderBottomRightRadius: '0.475rem',
                        borderTopLeftRadius: '0',
                        borderBottomLeftRadius: '0',
                      }}
                    >
                      <option value=''></option>
                      {options && options.map((option, optIndex) => (
                        <option key={optIndex} value={option.pk}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-lg-2 d-flex justify-content-end'>
                    <div
                      onClick={() => {
                        if (formik.values.options.length > 1) {
                          arrayHelpers.remove(index);
                          // updateData(formik.values);
                        }
                      }}
                      className='btn btn-danger'
                    >
                      削除
                    </div>
                  </div>
                </div>
              ))}
              <div style={{ textAlign: 'right' }}>
                <button
                  type='button'
                  className='btn btn-primary w-100px'
                  onClick={() => {
                    arrayHelpers.push('');
                    // updateData(formik.values);
                  }}
                >
                  ＋追加
                </button>
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default OptionSelector;
