import {FC, useState, useEffect, Fragment} from 'react'
import {useNavigate} from 'react-router-dom'
import {KTCard, KTSVG, toAbsoluteUrl} from '@/_metronic/helpers'
import {productFavoritesGet, productFavoritesDelete, cartDataUpdate} from 'src/app/modules/api'
import {parameterStoreGet} from 'src/app/modules/api'
import {Pagination} from 'src/app/components/pagination'
import {useLayout, useAlert} from 'src/_metronic/layout/core'
import {useAuth} from 'src/app/modules/auth'
import {calculatePrice} from '@/app/pages/buyer/product-list/utils'
import {useIsWidthSize, formatNumber} from 'src/app/modules/utils'
import {useAccount} from 'src/app/modules/accounts/AccountProvider'
import ProcessButton from 'src/app/components/part/ProcessButton'

export default function FavoriteProductList() {
  const {account, setAccount} = useAccount()
  const {isContentLoading, setContentLoading} = useLayout()
  const {setMessage} = useAlert()
  const {auth} = useAuth()
  const navigate = useNavigate()
  const [queryParams, setQueryParams] = useState<any>({
    name: '',
    category: '',
    buyer_id: auth.group_id,
    pageNum: 1,
    pageSize: 10,
    total: 0,
  })

  const [listData, setListData] = useState<any>([])

  const deledeFavorite = async (item: any) => {
    setContentLoading(true)

    let resp: any = await productFavoritesDelete({
      variation_id: item.pk,
      product_id: item.product_id,
    })
    if (resp.message == 'success') {
      setMessage({type: 'info', message: 'キャンセルしました'})

      let idx = listData.findIndex((e: any) => e.pk == item.pk)
      let arr = JSON.parse(JSON.stringify(listData))
      arr.splice(idx, 1)
      setListData(arr)
    }
    setContentLoading(false)
  }

  // 获取列表数据
  const getData = async (pageNum: any, pageSize: any) => {
    setContentLoading(true)
    try {
      const res: any = await productFavoritesGet({
        pageNum,
        pageSize,
        buyer_id: queryParams.buyer_id,
      })
      setQueryParams({...queryParams, total: res.total})
      res.rows.forEach((element: {quantity: number}) => {
        element.quantity = 1
      })
      setListData(res.rows)
      console.log('getData', listData)
      setContentLoading(false)
    } catch (error) {
      setContentLoading(false)
      console.error('error', error)
    }
  }

  const initData = async () => {
    setContentLoading(true)
    try {
      const res: any = await parameterStoreGet({names: ['/Define/Product/Units']}, {})
      const parameters = JSON.parse(res['/Define/Product/Units'])
      console.log('parameters', res, parameters)

      const resp: any = await productFavoritesGet({
        pageNum: queryParams.pageNum,
        pageSize: queryParams.pageSize,
      })

      console.log('resp', resp)

      setQueryParams({...queryParams, total: resp.total})
      resp.rows.forEach((item: any) => {
        item.isCollect = true
        if (parameters && parameters.length > 0) {
          item.unit = parameters.find((e: any) => item.unit == e.id)?.label
        }
        if (item.customer_rank_specific_pricing) {
          item.customer_rank_specific_pricing = JSON.parse(item.customer_rank_specific_pricing)
        }
        let itemId: any = ''
        if (item.grade && item.customer_rank_specific_pricing.length > 0) {
          itemId = item.customer_rank_specific_pricing.findIndex((e: any) => e.id == item.grade)
        }
        if (
          itemId >= 0 &&
          item.customer_rank_specific_pricing.length > 0 &&
          item.customer_rank_specific_pricing[itemId]?.price
        ) {
          item.unit_price = item.customer_rank_specific_pricing[itemId]?.price
        }
        if (
          itemId >= 0 &&
          item.customer_rank_specific_pricing.length > 0 &&
          item.customer_rank_specific_pricing[itemId]?.price_range_min
        ) {
          item.price_range_min = item.customer_rank_specific_pricing[itemId]?.price_range_min
        }
        if (
          itemId >= 0 &&
          item.customer_rank_specific_pricing.length > 0 &&
          item.customer_rank_specific_pricing[itemId]?.price_range_max
        ) {
          item.price_range_max = item.customer_rank_specific_pricing[itemId]?.price_range_max
        }
        item.default_price = item.unit_price
        item.minimum_order_quantity = item.minimum_order_quantity
        item.maximum_order_quantity = item.maximum_order_quantity
        if (item.quantity) {
          item.quantity = Number(item.quantity)
        } else {
          item.quantity = 1
        }
        if (
          Number(item.minimum_order_quantity) > 0 &&
          Number(item.minimum_order_quantity) > item.quantity
        ) {
          item.quantity = item.minimum_order_quantity
        }
        item.calc_price = item.unit_price
        item.calc_price_min = item.price_range_min
        item.calc_price_max = item.price_range_max
        if (item.quantity_discount) item.quantity_discount = JSON.parse(item.quantity_discount)
        calculatePrice(item)

        item.price_range = item.price_range
        item.unit_price = item.unit_price
        item.price_range_min = item.price_range_min
        item.price_range_max = item.price_range_max
      })
      setListData(resp.rows)
      // console.log('getData', listData)
      setContentLoading(false)
    } catch (error) {
      setContentLoading(false)
    }
  }

  //选择列表
  const [selectedList, setSelectList] = useState<any>([])
  // カートの追加
  const bulkAddShoppingCart = async () => {
    let obj: any = {}
    let items: any = []
    listData.forEach((item: any) => {
      if (selectedList.includes(item.pk)) {
        items.push({
          id: item.pk,
          product_id: item.product_id,
          quantity: item.quantity.toString(),
          variation_id: item.pk,
          value: true,
        })
      }
    })
    console.log('items', items)
    if (items.length == 0) {
      // return showWarningMessage('商品をお選びください!')
      setMessage({type: 'warning', message: '商品をお選びください'})
      return
    }
    if (items.length == 1) {
      obj = {
        type: 0,
        product_id: items[0].product_id,
        variation_id: items[0].variation_id,
        quantity: items[0].quantity,
      }
    } else {
      obj = {
        type: 1,
        list: items,
      }
    }
    console.log('obj', obj)

    let resp: any = await cartDataUpdate(obj)
    // console.log('resp', resp)
    if (resp.message) {
      console.log(resp.message)

      if (resp.message == 'success') {
        setMessage({type: 'info', message: '商品をカートに追加しました'})
        setAccount({
          ...account,
          cartCount: resp.count,
          cartItem: resp.itemList,
          cartKey: resp.cartkey,
        })
      }

      if (resp.message == 'out of stock') {
        setMessage({type: 'error', message: '購入可能な数を超えています'})
      }
    }

    if (resp.processLists) {
      let messages = ''
      let success: number = 0
      let outOfStock: number = 0
      resp.processLists.forEach((item: any) => {
        if (item.message === 'success') {
          success++
        }
        if (item.message === 'out of stock') {
          outOfStock++
        }
      })

      if (success > 0) {
        setMessage({type: 'success', message: `${success}件の商品をカートに追加しました`})
        setAccount({
          ...account,
          cartCount: resp.count,
          cartItem: resp.itemList,
          cartKey: resp.cartkey,
        })
      }

      if (outOfStock > 0) {
        setMessage({type: 'error', message: `${outOfStock}件の商品が購入可能な数を超えています`})
        setAccount({
          ...account,
          cartCount: resp.count,
          cartItem: resp.itemList,
          cartKey: resp.cartkey,
        })
      }

      // selectedListからsuccessの商品を削除
      let arr = JSON.parse(JSON.stringify(selectedList))
      resp.processLists.forEach((item: any) => {
        if (item.message === 'success') {
          let idx = arr.findIndex((e: any) => e == item.variation_id)
          arr.splice(idx, 1)
        }
      })

      setSelectList(arr)
    }
  }
  // カートの追加
  const addShoppingCart = async (item: any) => {
    // console.log(item)
    try {
      const resp: any = await cartDataUpdate({
        product_pk: item.product_id,
        buyer_id: auth.group_id,
        variation_id: item.pk,
        product_id: item.product_id,
        update_user: auth.group_id,
        quantity: item.quantity.toString(),
      })
      // console.log('resp', resp)
      if (resp.message == 'success') {
        setMessage({type: 'success', message: '商品をカートに追加しました'})
        // showSuccessMessage('カートに追加しました。')
        setAccount({
          ...account,
          cartCount: resp.count,
          cartItem: resp.itemList,
          cartKey: resp.cartkey,
        })
      }

      if (resp.message == 'out of stock') {
        setMessage({type: 'error', message: '購入可能な数を超えています'})
        // showSuccessMessage('カートに追加しました。')
        setAccount({
          ...account,
          cartCount: resp.count,
          cartItem: resp.itemList,
          cartKey: resp.cartkey,
        })
      }
    } catch (err: any) {
      setMessage({
        type: 'error',
        message: 'カートに追加中にエラーが発生しました。のちほどお試しください',
      })
      // showErrorMessage('在庫が不足しています。')
    }
  }
  // データ減少
  const itemNumberReduce = (item: {quantity: number; minimum_order_quantity: number}) => {
    console.log('--', item)
    if (
      item.quantity == 1 ||
      (item.minimum_order_quantity && Number(item.quantity) <= Number(item.minimum_order_quantity))
    ) {
      return
    }
    item.quantity--
    calculatePrice(item)
    let arr = JSON.parse(JSON.stringify(listData))

    setListData(arr)
  }
  // データ増加
  const itemNumberAdd = (item: {quantity: number; maximum_order_quantity: number}) => {
    console.log('++', item)
    if (
      item.maximum_order_quantity &&
      Number(item.quantity) >= Number(item.maximum_order_quantity)
    ) {
      return
    }
    item.quantity++
    calculatePrice(item)
    let arr = JSON.parse(JSON.stringify(listData))

    setListData(arr)
  }

  //选中列表
  const changeSelectList = (obj: any) => {
    console.log('changeSelectList', obj)
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = []
        listData.forEach((item: any) => {
          arr.push(item.pk)
        })
        setSelectList(arr)
      } else {
        selectedList.push(obj.id)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([])
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id
        })
        selectedList.splice(idx, 1)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    }
  }
  useEffect(() => {
    queryParams.buyer_id = auth.group_id
    setQueryParams({...queryParams, buyer_id: auth.group_id})
    initData()
  }, [])

  return (
    <>
      <style>
        {`
      @media (max-width: 991.98px) {
        .table-responsive table {
          width: 100%;
          min-width: auto !important;
        }
        .table-responsive thead {
          display: none;
        }
        .table-responsive tbody tr {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 0 20px;
        }
        .table-responsive tbody tr:not(:last-child) {
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 20px;
          margin-bottom: 30px;
        }
        .table-responsive tbody tr td {
          display: block;
          padding: 0 !important;
          margin-bottom: 10px;
          border: none;
        }
        .table-responsive tbody tr td:nth-child(2),
        .table-responsive tbody tr td:nth-child(3),
        .table-responsive tbody tr td:nth-child(6) {
          width: 100%;
        }
      }
      `}
      </style>

      {!useIsWidthSize('lg') && (
        <div className='d-flex justify-content-between py-6 gap-4'>
          <button
            className='col btn btn-sm btn-primary'
            type='button'
            aria-pressed={selectedList.length === listData.length && selectedList.length !== 0}
            onClick={() => {
              let isChecked = selectedList.length === listData.length && selectedList.length !== 0
              let obj: {id: string; value: boolean} = {
                id: 'all',
                value: !isChecked,
              }
              changeSelectList(obj)
            }}
          >
            まとめてチェック
          </button>

          <button
            className={`col btn btn-sm btn-primary`}
            type='button'
            onClick={() => {
              if (selectedList.length === 0) {
                return // Disable the button if selectedList is empty
              }
              bulkAddShoppingCart()
            }}
            disabled={selectedList.length === 0}
          >
            まとめてカートに追加
          </button>
        </div>
      )}

      {useIsWidthSize('lg') && (
        <div className='d-flex justify-content-end py-6'>
          <button
            className='btn btn-sm btn-primary'
            type='button'
            onClick={() => {
              if (selectedList.length === 0) {
                return // Disable the button if selectedList is empty
              }
              bulkAddShoppingCart()
            }}
            disabled={selectedList.length === 0}
          >
            まとめてカートに追加
          </button>
        </div>
      )}

      <div className='container-xxl px-0'>
        {useIsWidthSize('lg') && (
          <KTCard resetSidePaddings={true}>
            <div className='table-responsive'>
              <table className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer '>
                <thead className='table-light'>
                  <tr className='text-start fs-6 text-uppercase gs-0'>
                    <th className='w-10px pe-2'>
                      <div className='form-check form-check-sm form-check-custom me-3'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={
                            selectedList.length === listData.length && selectedList.length !== 0
                          }
                          onChange={(e) => {
                            let obj: any = {
                              id: 'all',
                              value: e.target.checked,
                            }
                            changeSelectList(obj)
                          }}
                        />
                      </div>
                    </th>
                    <th id='name'>商品名</th>
                    <th id='company'>加盟店</th>
                    <th id='total'>数量</th>
                    <th id='unit'>単位</th>
                    <th id='price'>価格</th>
                    <th id='status'>在庫状況</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody className=''>
                  {listData.length > 0 ? (
                    listData.map((item: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          <tr>
                            <td>
                              <div className='form-check form-check-sm form-check-custom me-3'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={selectedList.includes(item.pk)}
                                  onChange={(e) => {
                                    let obj = {
                                      id: item.pk,
                                      value: e.target.checked,
                                    }
                                    changeSelectList(obj)
                                  }}
                                />
                              </div>
                            </td>

                            <td>
                              {item.product_name}
                              <br />
                              {item.variation_product_name}
                            </td>
                            <td>{item.shopName}</td>
                            <td>
                              <button
                                type='button'
                                className='btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px my-5'
                              >
                                <i
                                  className='bi bi-dash-lg fs-4 text-black'
                                  data-kt-docs-datatable-subtable='expand_row'
                                  onClick={() => {
                                    itemNumberReduce(item)
                                  }}
                                ></i>
                              </button>
                              <span style={{margin: '0px 10px'}}>{item.quantity}</span>
                              <button
                                type='button'
                                className='btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px my-5'
                              >
                                <i
                                  className='bi bi-plus-lg fs-4 text-black'
                                  data-kt-docs-datatable-subtable='expand_row'
                                  onClick={() => {
                                    itemNumberAdd(item)
                                  }}
                                ></i>
                              </button>
                            </td>
                            <td>
                              {/* {item.unit == '1' ? 'kg' : '個'} */}
                              {item.unit}
                            </td>
                            <td>
                              <span className=' fs-4 fw-bold' style={{color: 'red'}}>
                                {item.price_range === 'unit_price' && (
                                  <>{formatNumber(item.unit_price)}円</>
                                )}
                                {item.price_range === 'price_range' && (
                                  <>
                                    {formatNumber(item.price_range_min)}~
                                    {formatNumber(item.price_range_max)}円
                                  </>
                                )}
                              </span>
                            </td>
                            <td>
                              {item.stock_quantity == '0' && (
                                <span className='badge badge-light-danger'>{'在庫無'}</span>
                              )}
                              {item.stock_quantity != '0' && (
                                <span className='badge badge-light-primary'>{'在庫有'}</span>
                              )}
                            </td>
                            <td style={{minWidth: 90}}>
                              <a
                                href='#'
                                className='btn btn-white btn-active-primary'
                                style={{
                                  float: 'left',
                                  width: 30,
                                  height: 30,
                                  border: '1px solid',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  addShoppingCart(item)
                                }}
                              >
                                <i className='bi bi-bag p-0'></i>
                              </a>
                              <a
                                className='btn btn-white btn-active-success mx-2'
                                href='#'
                                style={{
                                  float: 'left',
                                  width: 30,
                                  height: 30,
                                  border: '1px solid',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  navigate(`/buy/product/${item.product_id}`, {
                                    state: {pk: item.product_id},
                                  })
                                }}
                              >
                                <i className='bi bi-eye p-0'></i>
                              </a>

                              <a
                                href='#'
                                className={
                                  item.isCollect
                                    ? 'btn btn-white btn-warning'
                                    : 'btn btn-white btn-active-warning'
                                }
                                style={{
                                  float: 'left',
                                  width: 30,
                                  height: 30,
                                  border: '1px solid',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                onClick={(e) => {
                                  e.preventDefault()
                                  deledeFavorite(item)
                                }}
                              >
                                <i className='bi bi-heart p-0'></i>
                              </a>
                              <br />
                            </td>
                          </tr>
                        </Fragment>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={17}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {isContentLoading ? 'データの取得中' : '商品が追加されていません'}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </KTCard>
        )}

        {!useIsWidthSize('lg') && (
          <FavoriteProductListSP
            items={listData}
            selectedList={selectedList}
            handleNumberReduce={itemNumberReduce}
            handleNumberAdd={itemNumberAdd}
            addShoppingCart={addShoppingCart}
            deledeFavorite={deledeFavorite}
            changeSelectList={changeSelectList}
          />
        )}

        {queryParams.total != 0 && (
          <Pagination
            currentPage={getData}
            page={queryParams.pageNum}
            pageSize={queryParams.pageSize}
            total={queryParams.total}
          />
        )}
      </div>
      <div />
    </>
  )
}

interface FavoriteProductListSPProps {
  items: any[]
  selectedList: any[]
  handleNumberReduce: (item: any) => void
  handleNumberAdd: (item: any) => void
  addShoppingCart: (item: any) => void
  deledeFavorite: (item: any) => void
  changeSelectList: (obj: any) => void
}

const FavoriteProductListSP: FC<FavoriteProductListSPProps> = ({
  items,
  selectedList,
  handleNumberAdd,
  handleNumberReduce,
  addShoppingCart,
  deledeFavorite,
  changeSelectList,
}) => {
  const {account} = useAccount()

  return (
    <>
      {items.length > 0 ? (
        items.map((item: any, index: number) => {
          return (
            <KTCard className={`mb-4`} resetSidePaddings={true} key={index}>
              <div className='row bg-light py-2'>
                <div className='col-1 d-flex align-items-center'>
                  <div className='form-check form-check-sm form-check-custom me-3'>
                    <input
                      id={`${item.pk}`}
                      className='form-check-input'
                      type='checkbox'
                      checked={selectedList.includes(item.pk)}
                      onChange={(e) => {
                        let obj: any = {
                          id: item.pk,
                          value: e.target.checked,
                        }
                        changeSelectList(obj)
                      }}
                    />
                  </div>
                </div>
                <div className='col d-flex align-items-center pb-2'>
                  <label
                    className='form-check form-check-sm form-check-custom w-100 me-3 fs-6 fw-bold'
                    htmlFor={`${item.pk}`}
                  >
                    {item.product_name}
                    <br />
                    {item.variation_product_name}
                  </label>

                  <div className='d-flex h-100 align-items-start'>
                    {item.stock_quantity == '0' && (
                      <span className='badge badge-light-danger'>{'在庫無'}</span>
                    )}
                    {item.stock_quantity != '0' && (
                      <span className='badge badge-light-primary'>{'在庫有'}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className='row bg-white py-2'>
                <div className='col-12'>
                  <span className=' fs-6'>販売: {item.shopName}</span>
                </div>
                <div className='col-6'>
                  {/* <label className='form-check form-check-sm form-check-custom me-3' htmlFor={`${item.pk}`}>
                  数量: {item.quantity}
                </label> */}
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px my-5'
                  >
                    <i
                      className='bi bi-dash-lg fs-4 text-black'
                      data-kt-docs-datatable-subtable='expand_row'
                      onClick={() => {
                        handleNumberReduce(item)
                      }}
                    ></i>
                  </button>
                  <span style={{margin: '0px 10px'}}>{formatNumber(item.quantity)}</span>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px my-5'
                  >
                    <i
                      className='bi bi-plus-lg fs-4 text-black'
                      data-kt-docs-datatable-subtable='expand_row'
                      onClick={() => {
                        handleNumberAdd(item)
                      }}
                    ></i>
                  </button>
                </div>
                <div className='col-6 text-end'>
                  <span className=' fs-6'>
                    {/* 単位: {item.unit} */}
                    単位：本
                  </span>
                </div>

                <div className='col-12 text-end'>
                  <span className=' fs-4 fw-bold' style={{color: 'red'}}>
                    {item.price_range === 'unit_price' && <>{formatNumber(item.unit_price)}円</>}
                    {item.price_range === 'price_range' && (
                      <>
                        {formatNumber(item.price_range_min)}~{formatNumber(item.price_range_max)}円
                      </>
                    )}
                  </span>
                </div>
              </div>

              <div className='row bg-white pt-2 pb-4'>
                <div className='col-12 text-end'>
                  <div className='row'>
                    <div className='col-6'>
                      <ProcessButton
                        className='btn-sm btn-primary w-100 fs-6'
                        onClick={async () => {
                          await addShoppingCart(item)
                        }}
                      >
                        {(() => {
                          const foundItem = account.cartItem.find((k: any) => k.id === item.pk)
                          return foundItem ? (
                            <span className='badge badge-circle badge-success me-2'>
                              {foundItem.quantity}
                            </span>
                          ) : null
                        })()}
                        カートに入れる
                      </ProcessButton>
                    </div>
                    <div className='col-6'>
                      <ProcessButton
                        className='btn-sm btn-danger w-100 fs-6'
                        onClick={async () => {
                          await deledeFavorite(item)
                        }}
                      >
                        一覧から削除
                      </ProcessButton>
                    </div>
                  </div>
                </div>
              </div>
            </KTCard>
          )
        })
      ) : (
        <div className='row'>
          <div className='col-12'>
            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
              商品が追加されていません
            </div>
          </div>
        </div>
      )}
    </>
  )
}
