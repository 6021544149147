/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, FC, useState } from 'react'
import { useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useAlert } from 'src/_metronic/layout/core'
import { customerSupplierUniqueGet, customerSupplierUniqueUpdate } from 'src/app/modules/api'
import { useLayout } from 'src/_metronic/layout/core'

const validationSchema = Yup.object().shape({
  // name: Yup.string().required('郵便番号必須です。'),
})

export const SupplierUniqueSettings: FC = () => {
  const { setMessage } = useAlert()
  const location = useLocation()
  const { isContentLoading, setContentLoading } = useLayout()
  const [userdata, setUserdata] = useState<any>({
    buyer_pk: location.state.pk,
    unique_params: [
      {
        label: '',
        value: ''
      },
      {
        label: '',
        value: ''
      },
      {
        label: '',
        value: ''
      },
    ],
  })  // 表单数据

  const updateData = (fieldsToUpdate: Partial<any>) => {
    const updatedData = { ...formik.values, ...fieldsToUpdate }
    formik.setValues(updatedData)
  }

  useEffect(() => {
    initData()
  }, [])

  // 获取列表数据
  const initData = () => {
    customerSupplierUniqueGet({ buyer_pk: location.state.pk }).then((res: any) => {
      console.log('res', res)
      if (res?.unique_params) {
        res.unique_params = JSON.parse(res.unique_params)
        if (res.unique_params.length > 0) {
          formik.values.unique_params = res.unique_params
          updateData(formik.values)
        }
      }
    })
  }

  const formik: any = useFormik<any>({
    initialValues: userdata as any,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log('values', values)
      values.unique_params = JSON.stringify(values.unique_params)
      console.log('values2222', values)
      setSubmitting(true)
      setContentLoading(true)
      customerSupplierUniqueUpdate(values)
        .then((res: any) => {
          setContentLoading(false)
          setMessage({ type: 'success', message: '更新しました' })
        })
        .catch((err: any) => {
          setContentLoading(false)
          setMessage({ type: 'error', message: '保存に失敗しました' })
        })
    },
  })

  console.log('formik', formik)

  return (

    <div className='card pt-4 mt-6 mb-6 mb-xl-9'>
      <div className='card-header border-0'>
        <div className='card-title'>
          <h2 className='fw-bold mb-0'>顧客独自項目</h2>
        </div>
      </div>
      <form
        id='kt_modal_add_user_form'
        className='container-fluid'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <div className='card-body p-9'>
          {Array.isArray(userdata?.unique_params) && userdata?.unique_params.map((item: any, index: number) => (
            <div key={index} className='row mb-7'>
              <label className='col-lg-1 fw-bold text-dark'>項目{index + 1}</label>
              <div className='col-lg-11'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='row mb-2'>
                      <label className='col-lg-1 fw-bold text-dark'>項目名</label>
                      <div className='col-lg-11'>
                        <input
                          className='form-control form-control-sm'
                          type='text'
                          value={item.label}
                          onChange={(e) => {
                            console.log('onchange', e)
                            formik.values.unique_params[index].label = e.target.value
                            updateData(formik.values)
                          }}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <label className='col-lg-1 fw-bold text-dark'>値</label>
                      <div className='col-lg-11'>
                        <input
                          className='form-control form-control-sm'
                          type='text'
                          value={item.value}
                          onChange={(e) => {
                            console.log('onchange', e)
                            formik.values.unique_params[index].value = e.target.value
                            updateData(formik.values)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className='row mb-7'>
            <div className='col-2 offset-10'>
              <button type='submit' data-kt-users-modal-action='submit' className='btn btn-primary'>
                {!isContentLoading && '更新'}
                {isContentLoading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    お待ちください...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>

        </div>
      </form>
    </div>

  )
}
