import { Fragment, useEffect, useState } from 'react'
import { KTCard, KTSVG } from '@/_metronic/helpers'
import { TableLoading } from './tabel/tableLoading'
import { useNavigate, Link } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import image from '@/utils/img/SAMPLE.png';
import { showErrorMessage, showSuccessMessage } from '@/app/components/sweetAlert'

function CsvUpdate() {
  const navigate = useNavigate()
  const [arr, setArr] = useState<any>([
    {
      id: 'A00011',
      isChange: '鶏もも肉',
      status: 1.5,
      controls: 6.53,
      unit: 'Kg'
    },
    {
      id: 'A00012',
      isChange: '肉にく本舗',
      status: 2,
      controls: 2.1,
      unit: 'Kg'
    },
    {
      id: 'A00013',
      isChange: '牛うし屋',
      status: 5,
      controls: 1.5,
      unit: 'g'
    }
  ]) //总数据
  const [data, setData] = useState<any>([]) // 数据列表
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
  const [keyWords, setKeywords] = useState<any>('')

  // 获取列表数据
  const getAllList = () => {
    setData(arr)
  }

  useEffect(() => {
    getAllList()
  }, [])

  return (

    <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
      <div className="card-header">
        <div>
          <h2>目方更新詳細</h2>
          <p>受注ID#123456</p>
        </div>
        <div className='d-flex align-items-center position-relative my-1'>
          <input
            type='text'
            data-kt-user-table-filter='search'
            style={{ marginRight: 10 }}
            className='form-control w-250px ps-14'
            placeholder='検索'
            value={keyWords}
            onChange={(e) => {
              setKeywords(e.target.value);
              if (e.target.value === '') {
                setData(arr)
              } else {
                let ar = JSON.parse(JSON.stringify(arr))
                let newArr = ar.filter((item: any) => {
                  return item.isChange.includes(e.target.value) || item.id.includes(e.target.value)
                })
                setData(newArr)
              }
            }}
          />
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="table-responsive">
          <table className="table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer min-w-1000px">
            <thead>
              <tr className="text-start fs-6 text-uppercase gs-0">
                <th>商品</th>
                <th className="text-end">ご注文目方</th>
                <th className="text-end">単位</th>
                <th className="text-end">目方修正</th>
                <th className="text-end">ステータス</th>
              </tr>
            </thead>
            <tbody>
              {
                data.length !== 0 && data.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className='symbol symbol-50px'>
                            <img className='symbol-label' src={image} alt="" />
                          </div>
                          <div className="ms-5">
                            <a href="/metronic8/demo1/../demo1/apps/ecommerce/catalog/edit-product.html" className="fw-bold text-hover-primary">{item.isChange}</a>
                          </div>
                        </div>
                      </td>
                      <td className="text-end">
                        {item.status}
                      </td>
                      <td className="text-end">
                        {item.unit}
                      </td>
                      <td className="text-end" style={{ maxWidth: 100 }}>
                        <input type="text" className='form-control mb-2' value={item.controls} onChange={(e) => {
                          data[index].controls = e.target.value;
                          let newArr = JSON.parse(JSON.stringify(arr))
                          setData(newArr)
                        }} />
                        <select className="form-select " data-control="select2"
                          style={{ width: 80 }}
                        >
                          <option value={1}>g</option>
                          <option value={2}>kg</option>
                          <option value={3}>mL</option>
                          <option value={4}>L</option>
                          <option value={5}>個</option>
                          <option value={6}>本</option>
                          <option value={7}>箱</option>
                          <option value={8}>ダース</option>
                          <option value={9}>匹</option>
                          <option value={10}>羽</option>
                        </select>
                      </td>
                      <td className="text-end">
                        <button className="btn btn-outline btn-sm btn-outline-primary btn-active-light-primary me-3" onClick={() => {
                          if (arr[index].controls !== data[index].controls) {
                            arr[index].controls = data[index].controls;
                            let newArr = JSON.parse(JSON.stringify(arr))
                            setArr(newArr)
                            showSuccessMessage('更新しました')
                          }
                        }}>更新する</button>
                        <button className="btn btn-sm btn-bg-danger btn-color-white" onClick={() => {

                        }}>決定</button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CsvUpdate
