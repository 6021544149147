import React from 'react';
import { useFormikContext } from 'formik';
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

interface CustomEditorProps {
    label: string;
    name: string;
  }
  
  const CustomEditor: React.FC<CustomEditorProps> = ({ label, name }) => {
    const { values, setFieldValue } = useFormikContext<any>();
  
    return (
      <div className='row px-0'>
        <div className='col-12 align-self-center'>
          <CKEditor
            editor={ClassicEditor}
            data={values[name]}
            config={{
              toolbar: [
                'undo',
                'redo',
                '|',
                'heading',
                '|',
                'bold',
                'italic',
                '|',
                'link',
                'insertTable',
                'blockQuote',
                'mediaEmbed',
                '|',
                'bulletedList',
                'numberedList',
                'outdent',
                'indent',
              ],
            }}
            onChange={(event: any, editor: any) => {
              const data = editor.getData();
              setFieldValue(name, data);
            }}
          />
        </div>
      </div>
    );
  };

  export default CustomEditor;