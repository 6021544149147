import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from 'src/app/modules/auth'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAccount} from 'src/app/modules/accounts/AccountProvider'

const itemClass = ''
const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {config} = useLayout()
  const {auth} = useAuth()
  // const [name, setname] = useState<any>([])
  const navigate = useNavigate()
  const {account} = useAccount()

  useEffect(() => {
    console.log('account', account)
  }, [])

  return (
    <div className='app-navbar flex-shrink-0 gap-4'>
      <div className={clsx('app-navbar-item d-none d-md-flex', itemClass)}>
        {account?.companyName}
        <br />
        {account?.userName}
      </div>

      {/* buyer判定 */}
      {account?.role.includes('buyer') && (
        <>
          <div className={clsx('app-navbar-item', itemClass)}>
            <button
              className='fs-8 btn btn-icon btn-primary position-relative'
              onClick={() => {
                navigate(`/buy/cart`)
              }}
            >
              <i className='bi bi-basket p-0 text-white fs-4'></i>
              <span className='position-absolute top-0 start-100 translate-middle badge badge-circle badge-success'>
                {account?.cartCount}
              </span>
            </button>
          </div>

          {/* <div className={clsx('app-navbar-item', itemClass)}>
          <button className={clsx('fs-8 btn btn-info position-relative', btnClass)}
            data-kt-menu-target='#kt_header_notifications_menu'
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <i className='bi bi-gear fs-4 text-white'></i>
          </button>
          <HeaderUserMenu />
        </div> */}
        </>
      )}

      {/* supplier判定 */}
      {/* {account?.role.includes('supplier') && (
        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('btn btn-success position-relative', btnClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <i className='bi bi-gear fs-4 text-white'></i>
          </div>
          <HeaderUserMenu />
        </div>
      )} */}
    </div>
  )
}

export {Navbar}
