import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


interface CustomInputProps {
  label: string;
  name: string;
  type: string;
  placeholder?: string;
}

const CustomInput: React.FC<CustomInputProps> = ({ label, type, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;
  const { value } = field;

  return (
    <>
    <div className='row'>
      <div className='col-12'>
        {type === 'datetime' ? (
          <DatePicker
            className='form-control'
            selected={(value && new Date(value)) || null}
            onChange={(val) => setValue(val)}
            showTimeSelect
            dateFormat="Pp"
          />
        ) : (
          <input
            className='form-control'
            type={type}
            {...field}
            {...props}
          />
        )}
      </div>
      {meta.touched && meta.error ? (
        <div className='col-12 fv-plugins-message-container mb-4'>
          <div className='fv-help-block'>{meta.error}</div>
        </div>
      ) : null}
    </div>
    </>
  );
};

export default CustomInput;
