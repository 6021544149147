import {MouseEventHandler, useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from '..'
import {ForgotPasswordVerificationAndNewPassword} from './ForgotPasswordVerificationAndNewPassword'
import {Spin} from 'antd'
import Password from 'antd/es/input/Password'

const local_URL = process.env.REACT_APP_LOCAL_URL

const initialValues = {
  password: '',
  password_confirmation: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string().required('入力は必須です'),
  password_confirmation: Yup.string()
    .required('入力は必須です')
    .oneOf([Yup.ref('password'), null], '新しいパスワードと一致しません。'),
})

export function ResetPassword({
  user,
  handleClose,
}: {
  user: any
  handleClose: (isClose: boolean) => void
}) {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const {auth, completeNewPassword} = useAuth()
  const [alert, setAlert] = useState('')
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const location = useLocation()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const result = await completeNewPassword(user, values.password)
        console.log('result', result)
        if (result.success) {
          console.log(result)
          await auth.signIn(user.username, values.password)
          // await auth.currentAuthenticatedUser()
          // navigate({ pathname: '/dashboard' });
          // setPhase('inputVerificationCode')
        } else {
          setAlert(result.message)
          // alert(result.message);
          // throw new Error(result.message)
        }

        setLoading(false)
      } catch (error) {
        const typedError = error as Error
        console.error(typedError)
        setStatus(typedError.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    console.log('location.state.user', user)
    // userが存在しない場合は、/authにリダイレクト

    // if (!location.state) {
    //   navigate('/auth')
    // }
  }, [])

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px'>
          <div className='d-flex flex-stack py-2'>
            <div className='w-100 separator separator-content my-6'>
              <span className='fw-bold fs-4' style={{width: '30em'}}>
                パスワードリセット
              </span>
            </div>
          </div>
          <div className='pb-0'>
            <div className='alert alert-primary d-flex align-items-center p-5'>
              <div className='d-flex flex-column fs-6 '>
                <span>
                  仮パスワードが設定されています。以下のフォームから新しくパスワードを設定するまで、アカウントは有効化されません。
                </span>
              </div>
            </div>

            {alert && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{alert}</div>
              </div>
            )}

            {location?.state?.message && (
              <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>{location.state.message}</div>
              </div>
            )}

            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            <div className='fv-row mb-5'>
              <label className='mb-1'>パスワード</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-5'>
              <label className='mb-1'>パスワード（確認）</label>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('password_confirmation')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.password_confirmation && formik.errors.password_confirmation,
                  },
                  {
                    'is-valid':
                      formik.touched.password_confirmation && !formik.errors.password_confirmation,
                  }
                )}
              />
              {formik.touched.password_confirmation && formik.errors.password_confirmation && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password_confirmation}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex flex-stack mb-4'>
              <div className='col-12 col-lg-6 offset-lg-3'>
                <Spin spinning={loading}>
                  <button
                    id='kt_sign_in_submit'
                    className='btn btn-primary w-100 me-2 flex-shrink-0'
                    // onClick={() => loginWithRedirect()}
                    // onClick={() => signUp()}
                  >
                    <span className='indicator-label'>パスワードを設定</span>
                  </button>
                </Spin>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 text-center'>
                <a
                  href='#'
                  onClick={(e) => {
                    e.preventDefault()
                    handleClose(false)
                  }}
                >
                  ログインへ戻る
                </a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
