/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'

import {useAuth} from '../../modules/auth'
import {DashboardBuyer} from './DashboardBuyer'
import {DashboardAdmin} from './DashboardAdmin'
import {DashboardSupplier} from './DashboardSupplier'
import {HelmetComponent} from 'src/app/modules/helmet'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10 h-100'>
        <CardsWidget20
          className='h-md-50 mb-5 mb-xl-10'
          description='Active Projects'
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10 h-100'>
        <CardsWidget7
          className='h-md-50 mb-5 mb-xl-10'
          description='Professionals'
          icon={false}
          stats={357}
          labelColor='dark'
          textColor='gray-300'
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10 h-100'>
        <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10 h-100'>
        <ListsWidget26 className='h-lg-50' />
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gx-5 gx-xl-10'>
      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {auth} = useAuth()

  // auth.rolesの値で分岐
  return (
    <>
      <HelmetComponent title={intl.formatMessage({id: 'MENU.DASHBOARD'})} options={{}} />
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {/* // ordererを含む */}
      {auth.role.includes('supplier') && <DashboardSupplier />}
      {/* // purchaserを含む */}
      {auth.role.includes('buyer') && <DashboardBuyer />}
      {/* // adminを含む */}
      {auth.role.includes('administrator') && <DashboardAdmin />}
      {/* // adminを含む */}
      {auth.role.includes('administrator') && (
        <>
          ↓　ここから利用者のダッシュボード
          <DashboardBuyer />
        </>
      )}
      {/* // adminを含む */}
      {auth.role.includes('administrator') && (
        <>
          ↓　ここから加盟店のダッシュボード
          <DashboardSupplier />
        </>
      )}
    </>
  )
}

export {DashboardWrapper}
