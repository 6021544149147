/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '@/_metronic/helpers'
import {useLocation} from 'react-router-dom'
import {getCommonDetails} from 'src/app/modules/api'
import {customerDataEdit} from 'src/app/modules/api'

export const CustomerDelivery: FC = () => {
  const location = useLocation()
  const [userdata, setUserdata] = useState<any>({
    store_status: '公開',
    registration_date: '2023/10',
    customer_id: '#123476',
    company_name: 'アップシェア商会',
    representative: 'zhang',
    contact_person: '担当　太郎',
    main_email_address: 'UPS@gmail.com',
    telephone_number: '045-478-6870',
    mobile_phone_number: '1232111111',
    fax_number: '',
    payment_method: '',
    establishment_date: '',
    actual_industry: '',
    website_url: '',
    physical_store: '有り',
    email_notifications: '可能',
  }) // 表单数据

  useEffect(() => {
    initData()
  }, [])

  // 获取列表数据
  const initData = () => {
    // getCommonDetails({}).then((res: any) => {
    //     console.log('res', res)
    //     if (res && res.rows.length > 0) {
    //         setUserdata(res.rows[0]);
    //     }
    // })
  }

  return (
    <>
      <div className='card-header card-header-stretch'>
        <h3 className='card-title fs-2 fw-bold'>配送先一覧</h3>
      </div>
      <div className='card-body accordion accordion-icon-toggle' id='kt_accordion_2'>
        {/* <div className='mb-5'>
          <div
            className='accordion-header py-3 d-flex'
            data-bs-toggle='collapse'
            data-bs-target='#kt_accordion_2_item_1'
          >
            <span className='accordion-icon'>
              <KTSVG
                className='svg-icon svg-icon-4'
                path='/media/icons/duotune/arrows/arr064.svg'
              />
            </span>
            <h3 className='fs-4 fw-bold mb-0 ms-4'>
              配送先&#9312;<span className='badge badge-light-primary m-3 fs-6'>規定の住所</span>
            </h3>
          </div>
          <div
            id='kt_accordion_2_item_1'
            className='fs-6 collapse show ps-10'
            data-bs-parent='#kt_accordion_2'
          >
            <div className='mb-2'>配送先IDD123</div>
            <div className='mb-2'>
              〒222-0033 <br />
              神奈川県横浜市港北区新横浜2丁目3−8
            </div>
            <div className='mb-2'>
              <a href='tel:0454786870' className='fw-bold fs-6 text-hover-primary'>
                045-478-6870
              </a>
            </div>
            <div className=''>配送次郎</div>
          </div>
        </div> */}
        {/* <div className='mb-5'>
          <div
            className='accordion-header py-3 d-flex collapsed'
            data-bs-toggle='collapse'
            data-bs-target='#kt_accordion_2_item_2'
          >
            <span className='accordion-icon'>
              <KTSVG
                className='svg-icon svg-icon-4'
                path='/media/icons/duotune/arrows/arr064.svg'
              />
            </span>
            <h3 className='fs-4 fw-bold mb-0 ms-4'>配送先&#9313;</h3>
          </div>
          <div
            id='kt_accordion_2_item_2'
            className='collapse fs-6 ps-10'
            data-bs-parent='#kt_accordion_2'
          >
            <div className='mb-2'>配送先IDD123</div>
            <div className='mb-2'>
              〒222-0033 <br />
              神奈川県横浜市港北区新横浜2丁目3−8
            </div>
            <div className='mb-2'>
              <a href='tel:0454786870' className='fw-bold fs-6 text-hover-primary'>
                045-478-6870
              </a>
            </div>
            <div className=''>配送次郎</div>
          </div>
        </div> */}
        {/* <div className='mb-5'>
          <div
            className='accordion-header py-3 d-flex collapsed'
            data-bs-toggle='collapse'
            data-bs-target='#kt_accordion_2_item_3'
          >
            <span className='accordion-icon'>
              <KTSVG
                className='svg-icon svg-icon-4'
                path='/media/icons/duotune/arrows/arr064.svg'
              />
            </span>
            <h3 className='fs-4 fw-bold mb-0 ms-4'>配送先&#9314;</h3>
          </div>
          <div
            id='kt_accordion_2_item_3'
            className='collapse fs-6 ps-10'
            data-bs-parent='#kt_accordion_2'
          >
            <div className='mb-2'>配送先IDD123</div>
            <div className='mb-2'>
              〒222-0033 <br />
              神奈川県横浜市港北区新横浜2丁目3−8
            </div>
            <div className='mb-2'>
              <a href='tel:0454786870' className='fw-bold fs-6 text-hover-primary'>
                045-478-6870
              </a>
            </div>
            <div className=''>配送次郎</div>
          </div> */}
      </div>
      {/* </div> */}
    </>
  )
}
