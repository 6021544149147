import axios from 'axios'
import {getServer, postServer} from './Axios'
import {updateEmailModel, UserModel} from './_models'
import {API_URL, host} from '../../../../service/api'

// Server should return AuthModel
// 登录接口
export function login(obj: any) {
  // console.log(111, username)
  return postServer('/login', obj)
}

// Server should return AuthModel
// 注册接口
export function register(obj: any) {
  return postServer('/register', obj)
}

// 忘记密码设置新密码
export function setNewPassword(obj: any) {
  return postServer('/new-password', obj)
}

// 忘记密码发邮件接口
export function requestPassword(email: string, url: string) {
  return postServer(`/reset-password`, {
    email,
    url
  })
}

// 用户列表
export function accountList(obj: any) {
  return postServer(`/account-list`, obj)
}

// 用户列表
export function accountById(id: any) {
  return getServer(`/get-account-by-id`,{
    id
  })
}

// 用户列表
export function accountEdit(obj: any) {
  return postServer(`/account-edit`, obj)
}

// 停用用户
export function deleteDev(obj: any) {
  return postServer(`/account-delete`, obj)
}

/**
 * 修改邮箱
 * @param email 邮箱
 * @param login_id 用户id
 * @param password 用户密码
 * @returns
 */
export function changeEmail(email: string, login_id: string, password: string): Promise<updateEmailModel> {
  return postServer('/change-email', {
    email,
    login_id,
    password,
  }) as Promise<updateEmailModel>
}


/**
 * 二段密码验证
 * @param twoAuthEnable 确认二次
 * @param login_id 用户id
 * @returns
 */
export function twoPassword(tfAuthTel: string, login_id: string): Promise<updateEmailModel> {
  return postServer('/two-factor-auth-setting', {
    tfAuthTel,
    twoAuthEnable: true,
    login_id,
  }) as Promise<updateEmailModel>
}


// 忘记密码发邮件接口
// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
//     email,
//   })
// }
/**
 * 获取stateValue
 * @param id 输入值
 * @returns
 */
export function setState(id: string) {
  return getServer(`/set-state`,{
    id
  })
}

// Connected Accounts
export function updateConnectedAccounts(obj: any) {
  return postServer(`/update-connected-accounts`, obj)
}

// Email Preferences
export function getMailPre(obj: any) {
  return getServer(`/get-mail-pre`, obj)
}

// Email Preferences
export function saveEmailPre(obj: any) {
  return postServer(`/save-email-pre`, obj)
}

// Notifications
export function getPushNotices(obj: any) {
  return getServer(`/get-push-notices`, obj)
}

// Notifications
export function savePushNotices(obj: any) {
  return postServer(`/save-push-notices`, obj)
}

// 权限组列表
export function getRoleGroupList(obj: any) {
  return getServer(`/get-rolegroup-list`, obj)
}

// 权限列表
export function getRoleList(obj: any) {
  return getServer(`/get-role-list`, obj)
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(`${API_URL}/verify_token`, {

    api_token: token,
  })
}

// 获取验证码
export function getSmsCode(obj: any) {
  return postServer(`/two-factor-auth`, obj)
  // return postServer(`/save-push-notices`, obj)
}

// 验证码是否正确
export function checkSmsCode(obj: any) {
  return postServer(`/sms-code-verify`, obj)
}

// 获取权限组ID列表
export function getRoleGroupIds(obj: any) {
  return getServer(`/get-rolegroup-ids`, obj)
}