interface cognitoMessagesJP {
  [key: string]: string; 
  "PasswordResetRequiredException": string,
  "InternalErrorException": string,
  "InvalidLambdaResponseException": string,
  "UnexpectedLambdaException": string,
  "ResourceNotFoundException": string,
  "TooManyRequestsException": string,
  "CodeDeliveryFailureException": string,
  "InvalidEmailRoleAccessPolicyException": string,
  "InvalidSmsRoleAccessPolicyException": string,
  "InvalidSmsRoleTrustRelationException": string,
  "InvalidParameterException": string,
  "InvalidPasswordException": string,
  "NotAuthorizedException": string,
  "UserLambdaValidationException": string,
  "UsernameExistsException": string,
  "UserNotConfirmedException": string,
  "PasswordResetException": string,
  "CodeMismatchException": string,
  "LimitExceededException": string,
  "ExpiredCodeException": string,
  "UserNotFoundException": string,
}

export const cognitoMessagesJP: cognitoMessagesJP = {
  "PasswordResetRequiredException": "パスワードのリセットが必要です。",
  "InternalErrorException": "",
  "InvalidLambdaResponseException": "",
  "UnexpectedLambdaException": "",
  "ResourceNotFoundException": "",
  "TooManyRequestsException": "",
  "CodeDeliveryFailureException": "",
  "InvalidEmailRoleAccessPolicyException": "",
  "InvalidSmsRoleAccessPolicyException": "",
  "InvalidSmsRoleTrustRelationException": "",
  "InvalidParameterException": "",
  "InvalidPasswordException": "",
  "NotAuthorizedException": "認証されていません。",
  "UserLambdaValidationException": "",
  "UsernameExistsException": "",
  "UserNotConfirmedException": "ユーザーはまだ確認されていません。",
  "PasswordResetException": "",
  "CodeMismatchException": "検証コードが一致していません。",
  "LimitExceededException": "",
  "ExpiredCodeException": "",
  "UserNotFoundException": "ユーザーが見つかりません。",
};

