/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation, Navigate} from 'react-router-dom'
import {useAuth} from 'src/app/modules/auth'
import {accountUserInfoGet, accountUserInfoUpdate} from 'src/app/modules/api'
import {useFormik, Form, Field, FormikProvider} from 'formik'
import * as Yup from 'yup'
import {ChangeEmail} from '@/app/pages/common/account/ChangeEmail'
import {Spin} from 'antd'

export function AccountUserInfo({type}: {type: 'buyer' | 'supplier'}) {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname

  const {auth} = useAuth()
  const [loading, setLoading] = useState<any>(false)
  const [userdata, setUserdata] = useState<any>({
    lastname: '',
    lastname_kana: '',
    firstname: '',
    firstname_kana: '',
  })

  const typeOptions: any = {
    viewPath: {
      buyer: '/account/buyer-account',
      supplier: '/account/account',
    },
    editPath: {
      buyer: '/account/buyer-account/edit',
      supplier: '/account/account/edit',
    },
  }

  useEffect(() => {
    const res = accountUserInfoGet().then((res: any) => {
      setUserdata((prev: any) => ({...prev, ...res}))
      setLoading(false)
    })
  }, [])

  return (
    <>
      {currentPath === `${typeOptions.editPath[type]}` && (
        <AccountUserInfoEdit username={auth.username} email={auth.data.attributes.email} />
      )}
      {currentPath === `${typeOptions.viewPath[type]}` && (
        <Spin spinning={loading} tip='Processing' size='large'>
          {/* <div style={{minHeight: '400px'}} className='card card-body mb-6'> */}
          {loading || (
            <>
              <div className='card mb-5 mb-xl-10'>
                <div className='card-header cursor-pointer'>
                  <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>アカウント情報</h3>
                  </div>
                  <div className='card-toolbar'>
                    <button
                      onClick={() => {
                        navigate(typeOptions.editPath[type], {state: {data: {...userdata}}})
                      }}
                      className='btn btn-primary align-self-center'
                    >
                      編集
                    </button>
                  </div>
                </div>

                <div className='card-body p-9'>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-dark'>メールアドレス</label>

                    <div className='col-lg-8'>
                      <span className='fs-6 text-dark'>{auth.data.attributes.email}</span>
                    </div>
                  </div>
                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-dark'>姓</label>

                    <div className='col-lg-8'>
                      <span className='fs-6 text-dark'>{userdata?.lastname}</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-dark'>名</label>

                    <div className='col-lg-8'>
                      <span className='fs-6 text-dark'>{userdata?.firstname}</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-dark'>姓（フリガナ）</label>

                    <div className='col-lg-8'>
                      <span className='fs-6 text-dark'>{userdata?.lastname_kana}</span>
                    </div>
                  </div>

                  <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-dark'>名（フリガナ）</label>

                    <div className='col-lg-8'>
                      <span className='fs-6 text-dark'>{userdata?.firstname_kana}</span>
                    </div>
                  </div>
                </div>
              </div>
              <ChangeEmail />
            </>
          )}
          {/* </div> */}
        </Spin>
      )}
    </>
  )
}

interface AccountUserInfoEditProps {
  username: string
  email: string
}

const AccountUserInfoEdit: React.FC<AccountUserInfoEditProps> = ({username, email}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {data} = location.state || {}

  const formik = useFormik({
    initialValues: {
      lastname: '',
      lastname_kana: '',
      firstname: '',
      firstname_kana: '',
    },
    validationSchema: Yup.object().shape({
      lastname: Yup.string(),
      lastname_kana: Yup.string(),
      firstname: Yup.string(),
      firstname_kana: Yup.string(),
    }),
    onSubmit: async (values) => {
      const res = await accountUserInfoUpdate(values)
    },
  })

  useEffect(() => {
    console.log('data', data)
    if (!data) {
      navigate('/account/buyer-account')
      return
    }
    formik.setValues(data)
  }, [data])

  return (
    <>
      <FormikProvider value={formik}>
        <Form>
          <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>アカウント情報</h3>
              </div>
            </div>

            <div className='card-body p-9'>
              {/* <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>システムID</label>

                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{username}</span>
                </div>
              </div> */}
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>メールアドレス</label>

                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>{email}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>姓</label>

                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    <Field type='text' className='form-control' name='lastname' />
                  </span>
                  {formik.touched.lastname && formik.errors.lastname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.lastname}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>名</label>

                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    <Field type='text' className='form-control' name='firstname' />
                  </span>

                  {formik.touched.firstname && formik.errors.firstname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.firstname}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>姓（フリガナ）</label>

                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    <Field type='text' className='form-control' name='lastname_kana' />
                  </span>

                  {formik.touched.firstname && formik.errors.firstname && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.firstname}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-dark'>名（フリガナ）</label>

                <div className='col-lg-8'>
                  <span className='fs-6 text-dark'>
                    <Field type='text' className='form-control' name='firstname_kana' />
                  </span>

                  {formik.touched.firstname_kana && formik.errors.firstname_kana && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.firstname_kana}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='card-footer'>
              <div className='d-flex justify-content-end'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  保存
                </button>
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </>
  )
}
