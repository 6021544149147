import {FC, ReactNode, createContext, useContext, useState, useEffect} from 'react'
import {WithChildren} from '../../helpers'
import { notification  } from 'antd'

interface AlertMessage {
    type: 'success' | 'info' | 'warning' | 'error'
    message: string
    description?: string
    placement?: NotificationPlacement
    onClose?: () => void
}
type NotificationPlacement = 'top' |  'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'

// const AlertContext = createContext<(message: AlertMessage) => void>(() => {});
const AlertContext = createContext<{ 
                        setMessage: (message: AlertMessage) => void
                     } | undefined>(undefined);

const AlertProvider: FC<WithChildren> = ({children}) => {
    const [api, contextHolder] = notification.useNotification();

    const setMessage = (message: AlertMessage) => {
      const { type, ...args } = message;

      // placementが指定されていない場合はtopに設定
        if (!args.placement) {
            args.placement = 'top'
        }
      if (api[type]) {
        api[type](args);
      }
    };

    return (
      <AlertContext.Provider value={{ setMessage }}>
        {contextHolder}
        {children}
      </AlertContext.Provider>
    )
}

export {AlertProvider}

export function useAlert() {
  const context = useContext(AlertContext);
  // TypeScriptの型アサーションを使用して、undefinedではないことを保証
  return context as { setMessage: (message: AlertMessage) => void };
}
  