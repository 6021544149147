import { getServer, postServer, deleteServer } from 'src/service/http'

// お気に入り商品を取得
export function productFavoritesGet(param: object, headers: object = {}) {
    return getServer(`/product-favorites-get`, { ...param }, { ...headers })
}

// お気に入り商品を取得
export function productFavoritesCheckGet(param: object, headers: object = {}) {
    return getServer(`/product-favorites-check-get`, { ...param }, { ...headers })
}

//  商品をお気に入りに追加
export function productFavoritesAdd(param: object, headers: object = {}) {
    return postServer(`/product-favorites-add`, { ...param }, {...headers})
}

//  商品をお気に入りから削除
export function productFavoritesDelete(param: object, headers:object = {}) {
    return deleteServer(`/product-favorites-delete`, { ...param }, { ...headers })
}