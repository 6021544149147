import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
// import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {ThemeModeProvider} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'
import {useLayout, AlertProvider} from './core'
import {Spin, ConfigProvider} from 'antd'
import {AccountProvider} from 'src/app/modules/accounts/AccountProvider'
import ErrorBoundary from 'src/app/modules/errors/ErrorBoundary'

const MasterLayout = () => {
  const [loading] = useState(false)
  const {isContentLoading} = useLayout()

  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  // useEffect(() => {
  //   setLoading(isContentLoading)
  // }, [])

  // useEffect(() => {
  //   setLoading(isContentLoading)
  // }, [isContentLoading])

  return (
    <AccountProvider>
      <PageDataProvider>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "'Noto Sans JP', sans-serif",
              fontSize: undefined,
            },
            hashed: false,
          }}
        >
          <ThemeModeProvider>
            <Spin tip='Loading' spinning={loading} size='large'>
              <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
                <div className='app-page flex-column flex-column-fluid min-vh-100' id='kt_app_page'>
                  <HeaderWrapper />
                  <div
                    className='app-wrapper d-flex flex-row flex-column-fluid fs-6'
                    id='kt_app_wrapper'
                  >
                    <Sidebar />
                    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                      <div className='d-flex flex-column flex-column-fluid'>
                        <ToolbarWrapper />
                        <Content>
                          <ErrorBoundary>
                            <Spin tip='読み込み中' spinning={isContentLoading} size='large'>
                              <AlertProvider>
                                <Outlet />
                              </AlertProvider>
                            </Spin>
                          </ErrorBoundary>
                        </Content>
                      </div>
                      <FooterWrapper />
                    </div>
                  </div>
                </div>
              </div>

              {/* begin:: Drawers */}
              {/* <ActivityDrawer /> */}
              {/* <RightToolbar /> */}
              {/* <DrawerMessenger /> */}
              {/* end:: Drawers */}

              {/* begin:: Modals */}
              {/* <InviteUsers /> */}
              {/* <UpgradePlan /> */}
              {/* end:: Modals */}
              <ScrollTop />
            </Spin>
          </ThemeModeProvider>
        </ConfigProvider>
      </PageDataProvider>
    </AccountProvider>
  )
}

export {MasterLayout}
