/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Link, useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router'
import {useAuth} from '@/app/modules/auth'
import {accountInfoGet, accountInfoUpdate, productCategoryListGet} from 'src/app/modules/api'
import {message, Spin, Upload} from 'antd'
import {InboxOutlined, LoadingOutlined} from '@ant-design/icons'
import {generateMixed} from 'src/utils/index'
import {getSignedUrl, parameterStoreGet} from 'src/app/modules/api'
import {useAlert} from 'src/_metronic/layout/core'
import {filterTree} from 'src/utils/filter'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import {format, parse} from 'date-fns'
// import {
//   imageAdd,
// } from '../product/components/core/api'

const status = [
  {name: '公開', id: 'publish'},
  {name: '非公開', id: 'hidden'},
]

const entityType = [
  {name: '法人', id: 'corporation'},
  {name: '個人', id: 'individual'},
]

const categoryOptions = [
  {name: '飲食', id: '1'},
  {name: '美容', id: '2'},
  {name: '健康', id: '3'},
  {name: '住宅', id: '4'},
  {name: 'ファッション', id: '5'},
  {name: '趣味', id: '6'},
  {name: 'その他', id: '7'},
]

const month = [
  {name: '1月', id: '1'},
  {name: '2月', id: '2'},
  {name: '3月', id: '3'},
  {name: '4月', id: '4'},
  {name: '5月', id: '5'},
  {name: '6月', id: '6'},
  {name: '7月', id: '7'},
  {name: '8月', id: '8'},
  {name: '9月', id: '9'},
  {name: '10月', id: '10'},
  {name: '11月', id: '11'},
  {name: '12月', id: '12'},
]

// const productCategoryOptions = [
//   {name: '飲食', id: '1'},
//   {name: '美容', id: '2'},
//   {name: '健康', id: '3'},
//   {name: '住宅', id: '4'},
//   {name: 'ファッション', id: '5'},
//   {name: '趣味', id: '6'},
//   {name: 'その他', id: '7'},
// ]

const mailGuidance = [
  {name: '受け取る', id: '1'},
  {name: '受け取らない', id: '2'},
]

const validation: any = [
  {
    store_status: Yup.string()
      .required('選択は必須です')
      .oneOf(
        status.map((s) => s.id),
        '無効な値が選択されています'
      ),
    // registration_date: Yup.string().required('登録年月日必須です。'),
    // supplier_id: Yup.string().required('加盟店ID必須です。'),
    // referrer: Yup.string().required('紹介者必須です。'),
    company_name: Yup.string().required('入力は必須です'),
    corporation_or_individual: Yup.string()
      .required('選択は必須です')
      .oneOf(
        entityType.map((s) => s.id),
        '無効な値が選択されています'
      ),
    shop_name: Yup.string().required('入力は必須です'),
    shop_name_kana: Yup.string()
      .required('入力は必須です')
      .matches(/^[ァ-ヶー　]+$/, '全角カタカナで入力してください'),
    representative_name: Yup.string().required('入力は必須です'),
    // establishment_date: Yup.string().required('代表者名必須です。'),
    main_genre: Yup.string()
      .required('選択は必須です')
      .oneOf(
        categoryOptions.map((s) => s.id),
        '無効な値が選択されています'
      ),
    // primary_contact_email: Yup.string()
    //   .required('入力は必須です')
    //   .email('メールアドレスの形式が正しくありません'),
    person_in_charge: Yup.string().required('入力は必須です'),
    person_in_charge_kana: Yup.string()
      .required('入力は必須です')
      .matches(/^[ァ-ヶー　]+$/, '全角カタカナで入力してください'),
    person_in_charge_email: Yup.string()
      .required('入力は必須です')
      .email('メールアドレスの形式が正しくありません'),
    person_in_charge_phone: Yup.string().required('入力は必須です'),
  },
  {
    headoffice_address_postal_code: Yup.string().required('入力は必須です'),
    headoffice_address_prefecture: Yup.string().required('選択は必須です'),
    headoffice_address_city: Yup.string().required('入力は必須です'),
    headoffice_address_street: Yup.string().required('入力は必須です'),
    headoffice_tel: Yup.string().required('入力は必須です'),
    headoffice_manager_lastname: Yup.string().required('入力は必須です'),
    headoffice_manager_firstname: Yup.string().required('入力は必須です'),
    headoffice_manager_lastname_kana: Yup.string()
      .required('入力は必須です')
      .matches(/^[ァ-ヶー　]+$/, '全角カタカナで入力してください'),
    headoffice_manager_firstname_kana: Yup.string()
      .required('入力は必須です')
      .matches(/^[ァ-ヶー　]+$/, '全角カタカナで入力してください'),
  },
  {
    billing_address_postal_code: Yup.string().required('入力は必須です'),
    billing_address_prefecture: Yup.string().required('選択は必須です'),
    billing_address_city: Yup.string().required('入力は必須です'),
    billing_address_street: Yup.string().required('入力は必須です'),
    billing_tel: Yup.string().required('入力は必須です'),
    billing_manager_lastname: Yup.string().required('入力は必須です'),
    billing_manager_firstname: Yup.string().required('入力は必須です'),
    billing_manager_lastname_kana: Yup.string()
      .required('入力は必須です')
      .matches(/^[ァ-ヶー　]+$/, '全角カタカナで入力してください'),
    billing_manager_firstname_kana: Yup.string()
      .required('入力は必須です')
      .matches(/^[ァ-ヶー　]+$/, '全角カタカナで入力してください'),
  },
  {
    slip_address_postal_code: Yup.string().required('入力は必須です'),
    slip_address_prefecture: Yup.string().required('選択は必須です'),
    slip_address_city: Yup.string().required('入力は必須です'),
    slip_address_street: Yup.string().required('入力は必須です'),
    slip_tel: Yup.string().required('入力は必須です'),
    slip_manager_lastname: Yup.string().required('入力は必須です'),
    slip_manager_firstname: Yup.string().required('入力は必須です'),
    slip_manager_lastname_kana: Yup.string()
      .required('担当者 姓（フリガナ）必須です。')
      .matches(/^[ァ-ヶー　]+$/, '全角カタカナで入力してください'),
    slip_manager_firstname_kana: Yup.string()
      .required('担当者 名（フリガナ）必須です。')
      .matches(/^[ァ-ヶー　]+$/, '全角カタカナで入力してください'),
  },
]

export function AccountInfoSetting() {
  const {auth} = useAuth()
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }
  const [userdata, setUserdata] = useState<any>({}) // 表单数据
  const {setMessage} = useAlert()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const validationSchema = Yup.object().shape(validation[Number(location.state) - 1])
  const [resData, setResData] = useState<any>({})
  const [avatarData, setAvatarData] = useState<any>('') //avatar  头像url
  const [goodsImageUrl, setGoodsImageUrl] = useState<any>(null)
  const [goodsLoading, setGoodsLoading] = useState<boolean>(false)
  const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

  // const [categoryOptions, setCategoryOptions] = useState<any>([])
  const [parameters, setParameters] = useState<any>([])

  const parseDateString = (dateString: string) => {
    try {
      console.log('parseDateString', dateString)
      return parse(dateString, 'yyyy年MM月', new Date(), {locale: ja})
    } catch (error) {
      return null
    }
  }

  const updateData = (fieldsToUpdate: Partial<any>) => {
    const updatedData = {...formik.values, ...fieldsToUpdate}
    // undefinedなら空文字にする
    console.log('updatedData.establishment_date', updatedData.establishment_date)
    console.log(
      'updatedData.establishment_date',
      updatedData.establishment_date === undefined || updatedData.establishment_date === ''
    )

    // establishment_dateの処理
    if (updatedData.establishment_date === undefined || updatedData.establishment_date === '') {
      updatedData.establishment_date = null
    } else {
      const parsedDate = parseDateString(updatedData.establishment_date)
      updatedData.establishment_date = parsedDate ? parsedDate : null
    }

    // fiscal_year_endの処理
    // if (updatedData.fiscal_year_end === undefined || updatedData.fiscal_year_end === '') {
    //   updatedData.fiscal_year_end = null
    // } else {
    //   const parsedDate = parseMonthString(updatedData.fiscal_year_end)
    //   updatedData.fiscal_year_end = parsedDate ? parsedDate : null
    // }

    formik.setValues(updatedData)
  }

  useEffect(() => {
    initData()
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token}`,
    }
    accountInfoGet('auth.username', headers).then((res: any) => {
      console.log('basic account header res', res)
      setResData(res)
    })
    getParameterStore()
  }, [])

  // 获取数据
  const initData = () => {
    console.log('location', location)
    setLoading(true)
    accountInfoGet('auth.username', headers)
      .then((res: any) => {
        console.log('res', res)
        if (res) {
          updateData(res)
          setLoading(false)
        }
      })
      .catch((_) => {
        setLoading(false)
      })
    // productCategoryListGet({pageNum: 1, pageSize: 100}).then((res: any) => {
    //   const arr = filterTree(res.rows)
    //   setCategoryOptions(arr)
    // })
  }

  const getParameterStore = async () => {
    let res: any = await parameterStoreGet(
      {names: ['/Define/user/EmailNotifications', '/Define/General/Prefecture']},
      {headers}
    )
    console.log('parameters', res)
    const data = {
      emailNotifications: JSON.parse(res['/Define/user/EmailNotifications']),
      prefecture: JSON.parse(res['/Define/General/Prefecture']),
    }
    setParameters(data)
  }

  const formik: any = useFormik<any>({
    initialValues: userdata as any,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('values', values)
      console.log('点击保存')
      values.main_image = goodsImageUrl ? goodsImageUrl : resData.main_image
      console.log('values2222', values)
      setSubmitting(true)
      setLoading(true)
      accountInfoUpdate(values)
        .then((res: any) => {
          setLoading(false)
          setMessage({type: 'success', message: '更新しました'})
          // navigate('/account/account')
        })
        .catch((err: any) => {
          setLoading(false)
          setMessage({type: 'error', message: '保存に失敗しました'})
        })
    },
  })

  console.log('formik', formik)

  return (
    <>
      <Spin spinning={loading} tip='Processing' size='large'>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>
                {location.state == '1' && '組織情報設定'}
                {location.state == '2' && '本社所在地設定'}
                {location.state == '3' && 'ご請求先住所設定'}
                {location.state == '4' && '伝票記載住所設定'}
              </h3>
            </div>
          </div>

          <div className='collapse show'>
            <form
              id='kt_modal_add_user_form'
              className='container-fluid'
              onSubmit={formik.handleSubmit}
              noValidate
            >
              {location.state == '1' && (
                <div className='card-body p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      店舗状態
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select w-auto'
                        data-control='select1'
                        {...formik.getFieldProps('store_status')}
                      >
                        <option value=''></option>
                        {status.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                      {formik.errors.store_status && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.store_status}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      登録年月日
                    </label>
                    <div className='col-lg-8 fv-row'>
                      {moment(resData.create_at).format('YYYY年MM月DD日')}
                    </div>
                  </div>
                  {/* <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>加盟店ID</label>
                    <div className='col-lg-8 fv-row'>
                      <input className='form-control form-control-lg' type="text" placeholder="加盟店ID"

                        {...formik.getFieldProps('supplier_id')}
                      />
                      {formik.errors.supplier_id && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.supplier_id}</div>
                        </div>
                      )}
                    </div>
                  </div> */}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>紹介者</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='紹介者'
                        {...formik.getFieldProps('referrer')}
                      />
                      {formik.errors.referrer && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.referrer}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>会社名</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='会社名'
                        {...formik.getFieldProps('company_name')}
                      />
                      {formik.errors.company_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.company_name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      法人／個人
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select w-auto'
                        data-control='select1'
                        {...formik.getFieldProps('corporation_or_individual')}
                      >
                        <option value=''></option>
                        {entityType.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>

                      {formik.errors.corporation_or_individual && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.corporation_or_individual}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>お店屋号</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='お店屋号'
                        {...formik.getFieldProps('shop_name')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      お店屋号（カナ）
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='お店屋号（カナ）'
                        {...formik.getFieldProps('shop_name_kana')}
                      />
                      {formik.errors.shop_name_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.shop_name_kana}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      代表者名
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='代表者名'
                        {...formik.getFieldProps('representative_name')}
                      />
                      {formik.errors.representative_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.representative_name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>設立</label>
                    <div className='col-lg-8 fv-row'>
                      <DatePicker
                        className='form-control w-auto'
                        selected={formik.values.establishment_date}
                        onChange={(date) => {
                          formik.setFieldValue('establishment_date', date)
                        }}
                        dateFormat='yyyy年MM月'
                        locale={ja}
                        showMonthYearPicker
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>決算期</label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select w-auto'
                        data-control='select1'
                        {...formik.getFieldProps('fiscal_year_end')}
                      >
                        <option value=''></option>
                        {month.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>年商</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='年商'
                        {...formik.getFieldProps('annual_sales')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>営業時間</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='営業時間'
                        {...formik.getFieldProps('business_hours')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>定休日</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='定休日'
                        {...formik.getFieldProps('regular_holiday')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>事業内容</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='事業内容'
                        {...formik.getFieldProps('business_description')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      メインの取り扱いジャンル
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select w-auto'
                        data-control='select1'
                        {...formik.getFieldProps('main_genre')}
                      >
                        <option value=''></option>
                        {categoryOptions?.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                      {formik.errors.main_genre && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.main_genre}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      メインの取り扱いジャンル２
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select w-auto'
                        data-control='select1'
                        {...formik.getFieldProps('secondary_genre')}
                      >
                        <option value=''></option>
                        {categoryOptions?.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>ホームページURL</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='ホームページURL'
                        {...formik.getFieldProps('website_url')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>法人番号</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='法人番号'
                        {...formik.getFieldProps('corporate_number')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      適格請求書発行事業者登録番号
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='適格請求書発行事業者登録番号'
                        {...formik.getFieldProps('invoice_number')}
                      />
                    </div>
                  </div>
                  {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                メインのメールアドレス
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    className='form-control form-control-lg'
                    type='text'
                    placeholder='メインのメールアドレス'
                    {...formik.getFieldProps('primary_contact_email')}
                  />
                  {formik.errors.primary_contact_email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.primary_contact_email}</div>
                    </div>
                  )}
                </div>
              </div> */}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>責任者</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='責任者'
                        {...formik.getFieldProps('person_in_charge')}
                      />
                      {formik.errors.person_in_charge && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.person_in_charge}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      責任者　フリガナ
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='責任者　フリガナ'
                        {...formik.getFieldProps('person_in_charge_kana')}
                      />
                      {formik.errors.person_in_charge_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.person_in_charge_kana}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      責任者　メールアドレス
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='責任者　メールアドレス'
                        {...formik.getFieldProps('person_in_charge_email')}
                      />
                      {formik.errors.person_in_charge_email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.person_in_charge_email}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      責任者　電話番号
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='責任者　電話番号'
                        {...formik.getFieldProps('person_in_charge_phone')}
                      />
                      {formik.errors.person_in_charge_phone && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.person_in_charge_phone}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      責任者　電話番号２
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='責任者　電話番号２'
                        {...formik.getFieldProps('person_in_charge_phone_secondary')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>担当者</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者'
                        {...formik.getFieldProps('contact_person')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>担当者　フリガナ</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者　フリガナ'
                        {...formik.getFieldProps('contact_person_kana')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      担当者　メールアドレス
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者　メールアドレス'
                        {...formik.getFieldProps('contact_person_email')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>担当者　電話番号</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者　電話番号'
                        {...formik.getFieldProps('contact_person_phone')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      担当者　電話番号２
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者　電話番号２'
                        {...formik.getFieldProps('contact_person_phone_secondary')}
                      />
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>メール案内</label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select w-auto'
                        data-control='select1'
                        {...formik.getFieldProps('email_guidance')}
                      >
                        <option value=''></option>
                        {mailGuidance.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                      {formik.errors.email_guidance && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.email_guidance}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>決済方法</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    className='form-control form-control-lg'
                    type='text'
                    placeholder='決済方法'
                    {...formik.getFieldProps('payment_method')}
                  />
                </div>
              </div> */}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>ショップ紹介文</label>
                    <div className='col-lg-8 fv-row'>
                      {/* <input className='form-control form-control-lg' type="text" placeholder="ショップ紹介文"
                  {...formik.getFieldProps('shop_introduction')}
                /> */}
                      <textarea
                        className='form-control form-control-lg'
                        rows={3}
                        data-kt-element='input'
                        placeholder='ショップ紹介文'
                        {...formik.getFieldProps('shop_introduction')}
                      ></textarea>
                    </div>
                  </div>
                </div>
              )}
              {location.state == '2' && (
                <div className='card-body p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>会社名・店名</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='会社名・店名'
                        {...formik.getFieldProps('headoffice_company_name')}
                      />
                      {formik.errors.headoffice_company_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.headoffice_company_name}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      会社名・店名（フリガナ）
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='会社名・店名（フリガナ）'
                        {...formik.getFieldProps('headoffice_company_name_kana')}
                      />
                      {formik.errors.headoffice_company_name_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.headoffice_company_name_kana}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      郵便番号
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='郵便番号'
                        {...formik.getFieldProps('headoffice_address_postal_code')}
                      />
                      {formik.errors.headoffice_address_postal_code && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.headoffice_address_postal_code}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      都道府県
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select w-auto'
                        data-control='select1'
                        {...formik.getFieldProps('headoffice_address_prefecture')}
                      >
                        <option value=''></option>
                        {parameters?.prefecture?.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.label}
                            </option>
                          )
                        })}
                      </select>
                      {formik.errors.headoffice_address_prefecture && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.headoffice_address_prefecture}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      市区町村
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='市区町村'
                        {...formik.getFieldProps('headoffice_address_city')}
                      />
                      {formik.errors.headoffice_address_city && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.headoffice_address_city}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      丁目番地
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='丁目番地'
                        {...formik.getFieldProps('headoffice_address_street')}
                      />
                      {formik.errors.headoffice_address_street && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.headoffice_address_street}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>建物名</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='建物名'
                        {...formik.getFieldProps('headoffice_address_building_name')}
                      />
                      {formik.errors.headoffice_address_building_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.headoffice_address_building_name}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      電話番号
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='電話番号'
                        {...formik.getFieldProps('headoffice_tel')}
                      />
                      {formik.errors.headoffice_tel && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.headoffice_tel}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>FAX番号</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='FAX番号'
                        {...formik.getFieldProps('headoffice_fax')}
                      />
                      {formik.errors.headoffice_fax && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.headoffice_fax}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 姓
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 姓'
                        {...formik.getFieldProps('headoffice_manager_lastname')}
                      />
                      {formik.errors.headoffice_manager_lastname && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.headoffice_manager_lastname}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 名
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 名'
                        {...formik.getFieldProps('headoffice_manager_firstname')}
                      />
                      {formik.errors.headoffice_manager_firstname && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.headoffice_manager_firstname}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 姓（フリガナ）
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 姓（フリガナ）'
                        {...formik.getFieldProps('headoffice_manager_lastname_kana')}
                      />
                      {formik.errors.headoffice_manager_lastname_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.headoffice_manager_lastname_kana}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 名（フリガナ）
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 名（フリガナ）'
                        {...formik.getFieldProps('headoffice_manager_firstname_kana')}
                      />
                      {formik.errors.headoffice_manager_firstname_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.headoffice_manager_firstname_kana}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {location.state == '3' && (
                <div className='card-body p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>会社名・店名</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='会社名・店名'
                        {...formik.getFieldProps('billing_company_name')}
                      />
                      {formik.errors.billing_company_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.billing_company_name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      会社名・店名（フリガナ）
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='会社名・店名（フリガナ）'
                        {...formik.getFieldProps('billing_company_name_kana')}
                      />
                      {formik.errors.billing_company_name_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.billing_company_name_kana}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      郵便番号
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='郵便番号'
                        {...formik.getFieldProps('billing_address_postal_code')}
                      />
                      {formik.errors.billing_address_postal_code && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.billing_address_postal_code}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      都道府県
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select w-auto'
                        data-control='select1'
                        {...formik.getFieldProps('billing_address_prefecture')}
                      >
                        <option value=''></option>
                        {parameters?.prefecture?.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          )
                        })}
                      </select>
                      {formik.errors.billing_address_prefecture && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.billing_address_prefecture}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      市区町村
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='市区町村'
                        {...formik.getFieldProps('billing_address_city')}
                      />
                      {formik.errors.billing_address_city && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.billing_address_city}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      丁目番地
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='丁目番地'
                        {...formik.getFieldProps('billing_address_street')}
                      />
                      {formik.errors.billing_address_street && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.billing_address_street}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>建物名</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='建物名'
                        {...formik.getFieldProps('billing_address_building_name')}
                      />
                      {formik.errors.billing_address_building_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.billing_address_building_name}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      電話番号
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='電話番号'
                        {...formik.getFieldProps('billing_tel')}
                      />
                      {formik.errors.billing_tel && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.billing_tel}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>FAX番号</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='FAX番号'
                        {...formik.getFieldProps('billing_fax')}
                      />
                      {formik.errors.billing_fax && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.billing_fax}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 姓
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 姓'
                        {...formik.getFieldProps('billing_manager_lastname')}
                      />
                      {formik.errors.billing_manager_lastname && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.billing_manager_lastname}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 名
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 名'
                        {...formik.getFieldProps('billing_manager_firstname')}
                      />
                      {formik.errors.billing_manager_firstname && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.billing_manager_firstname}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 姓（フリガナ）
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 姓（フリガナ）'
                        {...formik.getFieldProps('billing_manager_lastname_kana')}
                      />
                      {formik.errors.billing_manager_lastname_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.billing_manager_lastname_kana}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 名（フリガナ）
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 名（フリガナ）'
                        {...formik.getFieldProps('billing_manager_firstname_kana')}
                      />
                      {formik.errors.billing_manager_firstname_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.billing_manager_firstname_kana}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {location.state == '4' && (
                <div className='card-body p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>会社名・店名</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='会社名・店名'
                        {...formik.getFieldProps('slip_company_name')}
                      />
                      {formik.errors.slip_company_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.slip_company_name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      会社名・店名（フリガナ）
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='会社名・店名（フリガナ）'
                        {...formik.getFieldProps('slip_company_name_kana')}
                      />
                      {formik.errors.slip_company_name_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.slip_company_name_kana}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      郵便番号
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='郵便番号'
                        {...formik.getFieldProps('slip_address_postal_code')}
                      />
                      {formik.errors.slip_address_postal_code && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.slip_address_postal_code}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      都道府県
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <select
                        className='form-select w-auto'
                        data-control='select1'
                        {...formik.getFieldProps('slip_address_prefecture')}
                      >
                        <option value=''></option>
                        {parameters?.prefecture?.map((item: any, index: number) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.label}
                            </option>
                          )
                        })}
                      </select>
                      {formik.errors.slip_address_prefecture && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.slip_address_prefecture}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      市区町村
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='市区町村'
                        {...formik.getFieldProps('slip_address_city')}
                      />
                      {formik.errors.slip_address_city && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.slip_address_city}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      丁目番地
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='丁目番地'
                        {...formik.getFieldProps('slip_address_street')}
                      />
                      {formik.errors.slip_address_street && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.slip_address_street}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>建物名</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='建物名'
                        {...formik.getFieldProps('slip_address_building_name')}
                      />
                      {formik.errors.slip_address_building_name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.slip_address_building_name}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      電話番号
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='電話番号'
                        {...formik.getFieldProps('slip_tel')}
                      />
                      {formik.errors.slip_tel && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.slip_tel}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>FAX番号</label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='FAX番号'
                        {...formik.getFieldProps('slip_fax')}
                      />
                      {formik.errors.slip_fax && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.slip_fax}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 姓
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 姓'
                        {...formik.getFieldProps('slip_manager_lastname')}
                      />
                      {formik.errors.slip_manager_lastname && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.slip_manager_lastname}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 名
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 名'
                        {...formik.getFieldProps('slip_manager_firstname')}
                      />
                      {formik.errors.slip_manager_firstname && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.slip_manager_firstname}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 姓（フリガナ）
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 姓（フリガナ）'
                        {...formik.getFieldProps('slip_manager_lastname_kana')}
                      />
                      {formik.errors.slip_manager_lastname_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.slip_manager_lastname_kana}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6 required'>
                      担当者 名（フリガナ）
                    </label>
                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg'
                        type='text'
                        placeholder='担当者 名（フリガナ）'
                        {...formik.getFieldProps('slip_manager_firstname_kana')}
                      />
                      {formik.errors.slip_manager_firstname_kana && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {formik.errors.slip_manager_firstname_kana}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button
                  type='submit'
                  data-kt-users-modal-action='submit'
                  className='btn btn-danger me-4'
                  onClick={() => {
                    navigate('/account/account')
                  }}
                >
                  戻る
                </button>

                <button
                  type='submit'
                  data-kt-users-modal-action='submit'
                  className='btn btn-primary'
                >
                  {!loading && '保存'}
                  {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      お待ちください...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {/* <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit'
            >
              {
                !loading && <span className='indicator-label'>保存</span>
              }
              {
                loading && <span>
                お待ちください...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
              }
            </button> */}
            </form>
          </div>
        </div>
      </Spin>
    </>
  )
}
