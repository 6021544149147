import {getServer, postServer, deleteServer} from 'src/service/http'

// 通知イベント設定一覧
export function notificationMethodGet(param: object) {
  return getServer(`/notification-method-get`, {...param})
}

//  通知イベント設定更新
export function notificationMethodUpdate(param: object) {
  return postServer(`/notification-method-update`, {...param})
}

// メールシステム設定
export function mailSettingGet(param: object) {
  return getServer(`/mail-setting-get`, {...param})
}

//  メールシステム設定更新
export function mailSettingUpdate(param: object) {
  return postServer(`/mail-setting-update`, {...param})
}

// メールシステム設定
export function SystemNotificationGet(param: object = {}) {
  return postServer(`/system-notification-get`, {...param})
}
