import {TMedia} from '../types'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {createMedia, getListMedia, updateMedia} from 'src/app/modules/api'
import {useLayout} from '@/_metronic/layout/core/_LayoutProvider'
import {getSignedUrl} from 'src/app/modules/api'
import {useAuth} from '@/app/modules/auth'
import {replaceSlashInFolderName} from '@/utils'
import { use } from 'echarts'

export const useMediaManagement = () => {
  const {isContentLoading, setContentLoading} = useLayout()
  const [selectedFolder, setSelectedFolder] = useState<TMedia[]>([])
  const [mediaEditName, setMediaEditName] = useState<TMedia>()
  const [filePreview, setFilePreview] = useState<TMedia>()
  const [{listMedia, totalMedias}, setMedias] = useState<{
    listMedia: TMedia[]
    totalMedias: {files: 0; size: 0}
  }>({listMedia: [], totalMedias: {files: 0, size: 0}})

  const parentSearch = useMemo(() => selectedFolder[selectedFolder.length - 1], [selectedFolder])

  const {auth} = useAuth()

  const related_id = useMemo(() => {
    return auth.group_id || auth.username
  }, [auth.group_id, auth.username])

  const supplierId = useMemo(() => {
    return related_id?.split('#Suppliers#')?.[1]
  }, [related_id])

  const fetchData = useCallback(async () => {
    try {
      setContentLoading(true)
      setMediaEditName(undefined)
      const response: any = await getListMedia({parent: parentSearch?.pk})
      setMedias({listMedia: response.items, totalMedias: response.totalMedias})
    } catch (e) {
      setMedias({listMedia: [], totalMedias: {files: 0, size: 0}})
    } finally {
      setContentLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentSearch?.pk])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const createFolderMedia = useCallback(
    async (name: string) => {
      let path = `/${name.trim()}`
      if (parentSearch?.path) {
        path = `${parentSearch.path}/${name.trim()}`
      }
      setContentLoading(true)
      await createMedia({
        name: name.trim(),
        isFolder: 1,
        path,
        parent: parentSearch?.pk,
      })
        .then(fetchData)
        .catch((e: any) => {
          setContentLoading(false)
          throw e
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parentSearch?.path, parentSearch?.pk, fetchData]
  )

  const createFileMedia = useCallback(
    async (file: File, path: string, parent?: string) => {
      await createMedia({
        name: file.name.trim(),
        path,
        size: file.size,
        isFolder: 0,
        parent,
      }).then(fetchData)
    },
    [fetchData]
  )

  const uploadFile = useCallback(
    async (file: File): Promise<void> => {
      const name = replaceSlashInFolderName(file.name.trim())
      let path = `/${name}`
      if (parentSearch?.path) {
        path = `${parentSearch.path}/${name}`
      }
      const res: any = await getSignedUrl({
        key: `${supplierId}${path}`,
        action: 'upload',
      })
        .then((res: any) => res.data)
        .catch()
      if (!res) return setContentLoading(false)
      await fetch(res.url, {method: 'PUT', body: file})
      await createFileMedia(file, path, parentSearch?.pk)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [parentSearch?.path, parentSearch?.pk, supplierId, createFileMedia]
  )

  const onSelectFolder = useCallback(
    (folder: TMedia) => {
      if (!folder.is_folder) {
        setContentLoading(true)
        return getSignedUrl({
          key: `${supplierId}${folder.path}`,
          action: 'get',
        })
          .then(({data}: any) => {
            setFilePreview({...folder, path: data.url})
          })
          .finally(() => setContentLoading(false))
      }
      setSelectedFolder((pre) => {
        if (pre.find((e) => e.pk === folder.pk)) return pre
        return [...pre, folder]
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [supplierId]
  )

  const onSaveMediaEditName = useCallback(
    async (name?: string) => {
      if (!mediaEditName) return
      const nameEdit = name || mediaEditName.name
      if (nameEdit === listMedia?.find((e) => e.pk === mediaEditName.pk)?.name) {
        return setMediaEditName(undefined)
      }
      setContentLoading(true)
      return updateMedia({pk: mediaEditName.pk, name: nameEdit.trim()})
        .then(() => {
          setMediaEditName(undefined)
          fetchData()
        })
        .catch((e) => {
          setContentLoading(false)
          throw e
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchData, listMedia, mediaEditName]
  )

  return {
    listMedia,
    isContentLoading,
    onSelectFolder,
    uploadFile,
    createFolderMedia,
    selectedFolder,
    setSelectedFolder,
    refetch: fetchData,
    mediaEditName,
    setMediaEditName,
    onSaveMediaEditName,
    filePreview,
    setFilePreview,
    totalMedias,
  }
}

export type Props = ReturnType<typeof useMediaManagement>
