import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react';
import { Tree, TreeSelect } from 'antd';
import type { TreeDataNode, TreeProps } from 'antd';

// const treeData: TreeDataNode[] = [
//   {
//     title: '0-0',
//     key: '0-0',
//     children: [
//       {
//         title: '0-0-0',
//         key: '0-0-0',
//         children: [
//           { title: '0-0-0-0', key: '0-0-0-0' },
//           { title: '0-0-0-1', key: '0-0-0-1' },
//           { title: '0-0-0-2', key: '0-0-0-2' },
//         ],
//       },
//       {
//         title: '0-0-1',
//         key: '0-0-1',
//         children: [
//           { title: '0-0-1-0', key: '0-0-1-0' },
//           { title: '0-0-1-1', key: '0-0-1-1' },
//           { title: '0-0-1-2', key: '0-0-1-2' },
//         ],
//       },
//       {
//         title: '0-0-2',
//         key: '0-0-2',
//       },
//     ],
//   },
//   {
//     title: '0-1',
//     key: '0-1',
//     children: [
//       { title: '0-1-0-0', key: '0-1-0-0' },
//       { title: '0-1-0-1', key: '0-1-0-1' },
//       { title: '0-1-0-2', key: '0-1-0-2' },
//     ],
//   },
//   {
//     title: '0-2',
//     key: '0-2',
//   },
// ];

const treeData = [
  {
    title: 'Node1',
    value: '0-0',
    children: [
      {
        title: 'Child Node1',
        value: '0-0-1',
      },
      {
        title: 'Child Node2',
        value: '0-0-2',
      },
    ],
  },
  {
    title: 'Node2',
    value: '0-1',
  },
];

const CategoryTree: any = forwardRef((props:any, ref) => {
    // console.log('props',props)
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(['0-0-0', '0-0-1']);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);

  const onExpand: TreeProps['onExpand'] = (expandedKeysValue) => {
    console.log('onExpand', expandedKeysValue);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck: TreeProps['onCheck'] = (checkedKeysValue, e) => {
    console.log('onCheck', checkedKeysValue, e);
    setCheckedKeys(checkedKeysValue as React.Key[]);
    let keysItem:any = []
    e.checkedNodes.forEach((k:any)=> {
        if (!(k?.children?.length>0)) {
            keysItem.push({
                id: k.pk,
                name: k.name
            })
        }
    })
    console.log('keysItem',keysItem)
    props.onChange(keysItem)
  };

  const onSelect: TreeProps['onSelect'] = (selectedKeysValue, info) => {
    console.log('onSelect', info,selectedKeysValue);
    setSelectedKeys(selectedKeysValue);
  };

  const setKeys = ()=> {
    const keys = props.items.map((e:any)=> e.id)
    console.log('keys',keys)
    setCheckedKeys(keys)
  }

  useImperativeHandle(ref, () => ({
    setKeys: setKeys,
  }))

  const [value, setValue] = useState<string[]>([]);
  const [previousValue, setPreviousValue] = useState<string[]>([]);
  const [selectedValue, setSelectedValue] = useState<string[]>([]);

  const onChange = (newValue: string[]) => {
    console.log(newValue);
    if (newValue.length < previousValue.length) {
      console.log('Item removed:', previousValue.find(item => !newValue.includes(item)));
      // 必要な処理をここに追加
    }
    setPreviousValue(newValue);
    setValue(newValue);
  };

  const onBlur = () => {
    if (selectedValue.length !== value.length) {
      props.onChange('supplier', selectedValue);
    }
    setSelectedValue(value);
  };


  useEffect(() => {
    setPreviousValue(value);
    setSelectedValue(value);
  }, []);

  return (
    <>
      {/* <style>
        {
          `
          .ant-select-selector {
            display: block;
            width: 100%;
            font-size: 0.925rem !important;
            font-weight: 500;
            line-height: 1.5;
            appearance: none !important;
            box-shadow: none !important;
            background-color: var(--kt-form-select-bg) !important;
            background-image: var(--kt-form-select-indicator) !important;
            background-repeat: no-repeat;
            background-position: right 1rem center;
            background-size: 16px 12px;
            border: 1px solid var(--kt-form-select-border-color) !important;
            border-radius: 0.425rem !important;
            color: var(--kt-form-select-color) !important;
            padding-top: 0.55rem !important;
            padding-bottom: 0.55rem !important;
            padding-left: 0.75rem !important;
          }

          `
        }
      </style> */}

      <TreeSelect
        showSearch
        style={{ width: '100%' }}
        value={value}
        fieldNames={{
          label: 'name', value: 'pk', children: 'children'
        }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="全て"
        allowClear
        multiple
        treeDefaultExpandAll
        treeData={props.list}
        onBlur={onBlur}
        onChange={onChange}
      />
    </>
  );
});

export default CategoryTree;