/* eslint-disable react/jsx-no-target-blank */
import {KTSVG} from '../../../helpers'

import {useAuth} from '@/app/modules/auth'

const SidebarFooter = () => {
  const {logout} = useAuth()
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <div className='d-flex flex-column flex-center' style={{gap: '20px'}}>
        <a
          href='/plan'
          className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        >
          <span className='menu-icon'>
            <KTSVG path={'/media/icons/duotune/abstract/abs029.svg'} className='svg-icon-2' />
          </span>
          <span className='menu-title'>プラン選択</span>
        </a>

        <a
          onClick={() => logout()}
          className='btn btn-info btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        >
          <span className='menu-icon'>
            <KTSVG path={'/media/icons/duotune/abstract/abs029.svg'} className='svg-icon-2' />
          </span>
          <span className='menu-title'>ログアウト</span>
        </a>
      </div>
    </div>
  )
}

export {SidebarFooter}
