import React, { useRef, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ja } from 'date-fns/locale'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import dayjs from 'dayjs'
// import customParseFormat from 'dayjs/plugin/customParseFormat';
import { getUpdate, getAlUpdate, getcalendarInfo, getdataLoad } from 'src/app/modules/api'
import {showSuccessMessage} from "src/app/components/sweetAlert";
import { useLayout } from 'src/_metronic/layout/core'


const Options1: SubscribeType[] = [
  { id: '1', name: '設定なし' },
  { id: '2', name: '設定' },
]

const Options2: any = [
  { id: 0, name: '日' },
  { id: 1, name: '月' },
  { id: 2, name: '火' },
  { id: 3, name: '水' },
  { id: 4, name: '木' },
  { id: 5, name: '金' },
  { id: 6, name: '土' },
]
function getAllSundays(year: any, month: any, weekArr: any) {
  let sundays = []
  // 获取给定月份第一天的日期对象
  let date = new Date(year, month, 1)
  // 获取给定月份最后一天的日期对象
  let lastDate = new Date(year, month + 1, 0)
  // 循环遍历每一天，如果是星期日则保存下来
  while (date <= lastDate) {
    if (weekArr.includes(date.getDay())) {
      let num: any = date.getDate()
      if (num < 10) {
        num = '0' + num
      }
      sundays.push({
        title: '定休日',
        date: dayjs(`${year}-${month + 1}-${num}`).format('YYYY-MM-DD'),
      })
    }
    date.setDate(date.getDate() + 1)
  }
  return sundays
}

export default function SalesDays() {
  const {setContentLoading} = useLayout()
  const [selectedDate, setSelectedDate] = useState<Date>()
  const [events, setEvents] = useState<any>([]) // 表单数据
  const [singleEvents, setSingleEvents] = useState<any>([]) // 表单数据
  const [formData, setFormData] = useState<any>({
    timeSetting: '0',
    closedDayStatus: [],
    dateArr: [],
  }) // 表单数据
  const calendarRef: { current: any } = useRef()

  const updateEvents = (eventsToUpdate: any) => {
    const updatedData = [...singleEvents, ...eventsToUpdate]
    console.log('updatedData8888', updatedData)
    setEvents(updatedData)
  }

  const handleDate = async () => {
    const calendarApi = calendarRef.current.getApi()
    // console.log('calendarApi.currentData',calendarApi.currentData)
    const currentMonth = calendarApi.currentData.viewTitle
    // console.log('currentMonth',currentMonth);
    let arr = currentMonth.split('年')
    var year = arr[0]
    var month = arr[1].split('月')[0]
    const dateArr = getAllSundays(year, month - 1, formData.closedDayStatus)
    formData.dateArr = dateArr
    let newData = JSON.parse(JSON.stringify(formData))
    setFormData(newData)
    let proms = {
      sunday: '',
      saturday: '',
      notes: '',

      wednesday: '',
      //1 営業日(默认) 2 休業日 3 受注のみ許可 4 出荷のみ許可
      thursday: '',
      friday: '',
      Tuesday: '',

      is_biweekly: '',
      monday: '',
    }
    if (formData.closedDayStatus) {
      proms.sunday = formData.closedDayStatus.includes(0) ? '2' : '1'
      proms.saturday = formData.closedDayStatus.includes(6) ? '2' : '1'

      proms.wednesday = formData.closedDayStatus.includes(3) ? '2' : '1'
      //1 営業日(默认) 2 休業日 3 受注のみ許可 4 出荷のみ許可
      proms.thursday = formData.closedDayStatus.includes(2) ? '2' : '1'
      proms.friday = formData.closedDayStatus.includes(5) ? '2' : '1'
      proms.Tuesday = formData.closedDayStatus.includes(4) ? '2' : '1'

      proms.is_biweekly = formData.timeSetting
      proms.monday = arr.includes(1) ? '2' : '1'
    }

    if (formData.timeSetting == '1') {
      setEvents([...singleEvents, ...dateArr])
    } else if (formData.timeSetting == '2') {
      // setEvents([])
      // setSingleEvents([])
    }
    try {
      const response: any = await getUpdate(proms)


    } catch (error) {

      console.error("There was a problem with the fetch operation:", error);
    }
  }
  // カレンダー情報更新

  // 適用
  const apply = () => {
    const date = dayjs(selectedDate).format('YYYY-MM-DD')
    console.log('date', date, 'selectedDate', selectedDate)
    if (selectedDate) {
      setSingleEvents([
        {
          title: '臨時休業',

          date: date,
        },
      ])
      setEvents([
        ...events,
        {
          title: '臨時休業',
          date: date,
        },
      ])

    }
    formData.dateArr.push({ is_open: '2', date: date, title: '臨時休業', })
    let newData = JSON.parse(JSON.stringify(formData))
    setFormData(newData)
  }
  //更新
  const getAllUpdate = async () => {
    console.log('dateArr', formData.dateArr)
    let arr: any = []
    formData.dateArr.map((v: any) => {
      arr.push({ is_open: '2', date: v.date })
    })
     // 获取当前时间
     let today = new Date();
     // 获取当前时间(today)的年份
     let year = today.getFullYear();
     // 获取月份
     let month = String(today.getMonth() + 1).padStart(2, '0');
    let params = { notes: '', dates: JSON.stringify(arr),month:`${year}-${month}`  }
    try {
      const response: any = await getAlUpdate(params)
      setEvents([])
      setSingleEvents([])
      init()
      showSuccessMessage(response.message)
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }

  }
  useEffect(() => {
    setContentLoading(true)
    setEvents([])
    setSingleEvents([])
    init()
      .then(() => {
        setContentLoading(false)
      })
      .catch((error) => {
        setContentLoading(false)
        console.error("There was a problem with the fetch operation:", error);
      })
  }, [])
  //初始化数据
  const init = async () => {
    const response: any = await getcalendarInfo({})
    let arry = []
    if (response.Tuesday == 2) {
      arry.push(4)
    }

    if (response.sunday == 2) {
      arry.push(0)
    }
    if (response.saturday == 2) {
      arry.push(6)
    }
    if (response.wednesday == 2) {
      arry.push(3)
    }
    if (response.thursday == 2) {
      arry.push(2)
    }

    if (response.friday == 2) {
      arry.push(5)
    }

    if (response.monday == 2) {
      arry.push(1)
    }
    formData.closedDayStatus = arry
    let newData = JSON.parse(JSON.stringify(formData))
    setFormData(newData)
    formData.timeSetting = response.is_biweekly
    let newDataA = JSON.parse(JSON.stringify(formData))
    setFormData(newDataA)
    //
    // 获取当前时间
    let today = new Date();
    // 获取当前时间(today)的年份
    let year = today.getFullYear();
    // 获取月份
    let month = String(today.getMonth() + 1).padStart(2, '0');
    console.log(`${year}-${month}`)
    const responseA: any = await getdataLoad({ Date: `${year}-${month}` })
    let array: any = []
    console.log( responseA);

    responseA.map((v: any) => {
      if(v.is_open==2){
        array.push({
          title: '臨時休業',
          date: v.date
        })
      }

    })
    setEvents([...singleEvents, ...array])


  }


  const renderEventContent = (eventContent: any) => {
    console.log('eventContent', eventContent)

    return (
      <>
        <b>{eventContent.timeText}</b>
        <i>{eventContent.event.title}</i>
      </>
    )
  }

  return (
    <div>
      <div className='container-fluid'>
        <form>
          {/* 定休日設定 */}
          <div className='card mb-6 shadow-sm'>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>定休日設定</span>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  {/* 定期的に休業する曜日を選択 */}
                  <div className='row mb-6'>
                    <p>定期的に休業する曜日を選択</p>
                    <div
                      className='col align-self-center'
                      style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
                    >
                      {Options2.map((item1: any) => {
                        return (
                          <div
                            className='form-check form-check-inline pe-3 mb-3'
                            key={item1.id}
                          >
                            <input
                              className='form-check-input'
                              type='checkbox'
                              name='closedDayStatus'
                              value={item1.id}
                              checked={formData.closedDayStatus.includes(item1.id) ? true : false}
                              onChange={(e) => {
                                let newData = ''
                                if (
                                  e.target.checked &&
                                  !formData.closedDayStatus.includes(item1.id)
                                ) {
                                  formData.closedDayStatus.push(item1.id)
                                  newData = JSON.parse(JSON.stringify(formData))
                                  setFormData(newData)
                                } else {
                                  let idx = formData.closedDayStatus.findIndex((i: any) => {
                                    return i === item1.id
                                  })
                                  if (idx !== -1) {
                                    formData.closedDayStatus.splice(idx, 1)
                                  }
                                  newData = JSON.parse(JSON.stringify(formData))
                                  setFormData(newData)
                                }
                                handleDate()
                              }}
                            />
                            <label className='form-check-label'>{item1.name}</label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  {/* 隔週 */}
                  <div className='row my-6'>
                    <div style={{ width: 100, marginLeft: 20 }}>
                      <label className='col-form-label'>隔週</label>
                    </div>

                    <div className='col-lg-9 align-self-center'>
                      {Options1.map((item2: any) => {
                        return (
                          <div
                            className='form-check form-check-inline pe-3'
                            key={item2.id}
                          >
                            <input
                              className='form-check-input'
                              type='radio'
                              name='timeSetting'
                              value={item2.id}
                              checked={formData.timeSetting === item2.id}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  formData.timeSetting = item2.id
                                  let newData = JSON.parse(JSON.stringify(formData))
                                  setFormData(newData)
                                }
                                handleDate()
                              }}
                            />
                            <label className='form-check-label'>{item2.name}</label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>臨時休業設定</span>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  {/* 日別の休日を個別に設定 */}
                  <div className='row mb-6'>
                    <p>日別の休日を個別に設定</p>
                    <div
                      className='col align-self-center flex-wrap'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <DatePicker
                        className='custom-datepicker form-control w-250px ps-12 py-3'
                        showIcon
                        icon='fa-solid fa-calendar-days position-absolute top-50 translate-middle ms-8 fs-5'
                        dateFormat='yyyy/MM/dd'
                        locale={ja}
                        isClearable
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date!)}
                        placeholderText='日付を選択'
                      />
                      <button
                        type='button'
                        className='btn btn-primary'
                        style={{ marginLeft: 10 }}
                        onClick={(e) => {
                          apply()
                        }}
                      >
                        追加
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* カレンダー */}
          <div className='card mb-6 shadow-sm'>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>カレンダー</span>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <FullCalendar
                    ref={calendarRef}
                    headerToolbar={{
                      left: 'prev,next',
                      center: 'title',
                      right: '',
                    }}
                    plugins={[dayGridPlugin]}
                    initialView='dayGridMonth'
                    locale='ja'
                    eventContent={renderEventContent}
                    events={events}
                    customButtons={{
                      prevMonthCustom: {
                        text: '上月',
                        click: function () {
                          console.log(11)
                        }
                      },
                      nextMonthCustom: {
                        text: '下月',
                        click: function () {
                          console.log(441)
                        }
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}>
            <button
              className='btn btn-primary'
              type='button'
              onClick={(e) => {
                getAllUpdate()
              }}
            >
              更新
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
