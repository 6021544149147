import React, {useEffect, useState, useRef, useMemo} from 'react'
import {Spin} from 'antd'
import {
  useFormik,
  FormikProvider,
  Form,
  useFormikContext,
  useField,
  FieldArray,
  FieldHookConfig,
} from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import {connect} from 'http2'
import {dir} from 'console'
import {remoteConnectConfirm} from 'src/app/modules/api'

interface FormValues {
  enable: string
  type: string
  format: string
  name: string
  templete_id: string
  connection_type: string
  header_exists: string
  host: string
  port: string
  user: string
  password: string
  pathkey: string
  directory: string
  filename: string
  charactor_code: string
  start_time_hour: string
  start_time_minute: string
  timing: string
  daily_timing: string
  monthly_timing: string
  weekranges: string[]
  weekdays: string[]
  days: string[]
  conditions_order_daterange: string
  conditions_order_status: string
  conditions_payment_daterange: string
  conditions_payment_status: string
  conditions_delivery_daterange: string
  conditions_delivery_status: string
}

export default function DataTransferSetting() {
  const [isConnecting, setIsConnecting] = useState(false)
  const [connectionResult, setConnectionResult] = useState('')
  const initialValues: FormValues = {
    enable: 'false',
    type: 'import',
    format: 'csv',
    name: '',
    templete_id: '',
    connection_type: 'ftp',
    header_exists: 'true',
    host: '',
    port: '',
    user: '',
    password: '',
    pathkey: '',
    directory: '/',
    filename: '',
    charactor_code: 'utf-8',
    start_time_hour: '',
    start_time_minute: '',
    timing: 'daily',
    daily_timing: '',
    monthly_timing: 'date',
    days: [],
    weekranges: [],
    weekdays: [],
    conditions_order_daterange: 'today',
    conditions_order_status: 'all',
    conditions_payment_daterange: 'today',
    conditions_payment_status: 'all',
    conditions_delivery_daterange: 'today',
    conditions_delivery_status: 'all',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('接続名は必須です'),
    type: Yup.string().required('接続タイプは必須です'),
    enable: Yup.string().required('ステータスは必須です'),
    templete_id: Yup.string().required('CSVテンプレート名は必須です'),
    format: Yup.string().required('ファイル形式は必須です'),
    header_exists: Yup.string().required('ヘッダーは必須です'),
    charactor_code: Yup.string().required('文字コードは必須です'),
    connection_type: Yup.string().required('接続タイプは必須です'),
    host: Yup.string().required('ホストは必須です'),
    port: Yup.string().required('ポートは必須です'),
    user: Yup.string().required('ユーザー名は必須です'),
    password: Yup.string().required('パスワードは必須です'),
    directory: Yup.string().required('ディレクトリは必須です'),
    filename: Yup.string().required('ファイル名は必須です'),
    timing: Yup.string().required('実行頻度を選択してください。'),
    start_time_hour: Yup.string()
      .required('時間の入力は必須です')
      .matches(/^[0-9]{1,2}$/, '時間は半角数字で入力してください')
      .test('is-valid-hour', '時間は0～23の範囲で入力してください', (value) => {
        if (value === undefined) return false
        const hour = parseInt(value, 10)
        return hour >= 0 && hour <= 23
      }),
    start_time_minute: Yup.string()
      .required('分の入力は必須です')
      .matches(/^[0-9]{1,2}$/, '分は半角数字で入力してください')
      .test('is-valid-minute', '分は0～59の範囲で入力してください', (value) => {
        if (value === undefined) return false
        const minute = parseInt(value, 10)
        return minute >= 0 && minute <= 59
      }),
    pathkey: Yup.string().when('connection_type', {
      is: 'sftp',
      then: Yup.string().required('秘密鍵は必須です'),
    }),
    daily_timing: Yup.string().when('timing', {
      is: 'daily',
      then: Yup.string().required('繰り返し（１日）は必須です'),
    }),
    weekdays: Yup.array().when(['timing', 'monthly_timing'], {
      is: (timing: string, monthly_timing: string) =>
        timing === 'weekly' || (timing === 'monthly' && monthly_timing === 'weekdays'),
      then: Yup.array().min(1, '曜日は最低1つ選択してください'),
    }),
    weekranges: Yup.array().when('timing', {
      is: (timing: string, monthly_timing: string) =>
        timing === 'monthly' && monthly_timing === 'weekdays',
      then: Yup.array().min(1, '期間は最低1つ選択してください'),
    }),
    days: Yup.array().when(['timing', 'monthly_timing'], {
      is: (timing: string, monthly_timing: string) =>
        timing === 'monthly' && monthly_timing === 'date',
      then: Yup.array().min(1, '日付は最低1つ選択してください'),
    }),
  })

  // const validationSchema = useMemo(() => getValidationSchema(initialValues), [initialValues])
  const formik: any = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      console.log('values', values)
    },
    validationSchema: validationSchema,
    validateOnChange: true,
  })

  const enables = useRef([
    {label: '有効', value: 'true'},
    {label: '無効', value: 'false'},
  ])

  const template_ids = useRef([
    {label: 'テンプレート1', value: 'template1'},
    {label: 'テンプレート2', value: 'template2'},
  ])

  const formats = useRef([
    {label: 'カンマ区切りテキスト（.csv)', value: 'csv'},
    {label: 'タブ区切りテキスト(.tsv)', value: 'tsv'},
    {label: 'JSON形式(.json)', value: 'json'},
  ])

  const headerExists = useRef([
    {label: 'あり', value: 'true'},
    {label: 'なし', value: 'false'},
  ])

  const charactor_codes = useRef([
    {label: 'UTF-8', value: 'utf-8'},
    {label: 'Shift_JIS', value: 'shift_jis'},
    {label: 'EUC-JP', value: 'euc-jp'},
  ])

  const connectionTypes = useRef([
    {label: 'FTP', value: 'ftp'},
    {label: 'SFTP', value: 'sftp'},
  ])

  const types = useRef([
    {label: 'インポート', value: 'import'},
    {label: 'エクスポート', value: 'export'},
  ])

  const timings = useRef([
    {label: '毎日', value: 'daily'},
    {label: '毎週', value: 'weekly'},
    {label: '毎月', value: 'monthly'},
  ])

  const dailyTimings = useRef([
    {label: '繰り替えさない', value: ''},
    {label: '1時間毎', value: 'hourly'},
    {label: '3時間毎', value: '3hourly'},
    {label: '6時間毎', value: '6hourly'},
    {label: '12時間毎', value: '12hourly'},
  ])

  const monthlyTimings = useRef([
    {label: '日付で指定', value: 'date'},
    {label: '曜日で指定', value: 'weekdays'},
  ])

  const weekranges = useRef([
    {label: '第1', value: '1'},
    {label: '第2', value: '2'},
    {label: '第3', value: '3'},
    {label: '第4', value: '4'},
    {label: '最終', value: '5'},
  ])

  const weekdays = useRef([
    {label: '日曜日', value: '0'},
    {label: '月曜日', value: '1'},
    {label: '火曜日', value: '2'},
    {label: '水曜日', value: '3'},
    {label: '木曜日', value: '4'},
    {label: '金曜日', value: '5'},
    {label: '土曜日', value: '6'},
  ])

  const days = useRef([
    {label: '1日', value: '1'},
    {label: '2日', value: '2'},
    {label: '3日', value: '3'},
    {label: '4日', value: '4'},
    {label: '5日', value: '5'},
    {label: '6日', value: '6'},
    {label: '7日', value: '7'},
    {label: '8日', value: '8'},
    {label: '9日', value: '9'},
    {label: '10日', value: '10'},
    {label: '11日', value: '11'},
    {label: '12日', value: '12'},
    {label: '13日', value: '13'},
    {label: '14日', value: '14'},
    {label: '15日', value: '15'},
    {label: '16日', value: '16'},
    {label: '17日', value: '17'},
    {label: '18日', value: '18'},
    {label: '19日', value: '19'},
    {label: '20日', value: '20'},
    {label: '21日', value: '21'},
    {label: '22日', value: '22'},
    {label: '23日', value: '23'},
    {label: '24日', value: '24'},
    {label: '25日', value: '25'},
    {label: '26日', value: '26'},
    {label: '27日', value: '27'},
    {label: '28日', value: '28'},
    {label: '29日', value: '29'},
    {label: '30日', value: '30'},
    {label: '末日', value: 'last'},
  ])

  const conditionsDateRange = useRef([
    {label: '当日', value: 'today'},
    {label: '前日', value: 'yesterday'},
    {label: '今週', value: 'thisweek'},
    {label: '今月', value: 'thismonth'},
  ])

  const conditionsOrderStatus = useRef([
    {label: '全て', value: 'order'},
    {label: '処理中', value: 'in_progress'},
    {label: '完了', value: 'completed'},
    {label: 'キャンセル', value: 'cancel'},
  ])

  const conditionsPaymentStatus = useRef([
    {label: '全て', value: 'payment'},
    {label: '未入金', value: 'unpaid'},
    {label: '入金済', value: 'paid'},
    {label: '返金', value: 'refund'},
    {label: '後Pay中', value: 'later_payment'},
  ])

  const conditionsDeliveryStatus = useRef([
    {label: '全て', value: 'delivery'},
    {label: '未発送', value: 'unshipped'},
    {label: '発送済', value: 'shipped'},
    {label: '配達済', value: 'delivered'},
    {label: 'キャンセル', value: 'cancel'},
  ])

  const handleFtpConnect = async () => {
    setIsConnecting(true)
    setConnectionResult('')
    console.log('formik.values', formik.values)
    const connection_type = formik.values.connection_type
    const host = formik.values.host
    const port = formik.values.port
    const user = formik.values.user
    const password = formik.values.password
    const pathkey = formik.values.pathkey
    let parameters:any = {
      connection_type: connection_type,
      host: host,
      user_name: user,
      port: port,
      password: password
    }
    if(connection_type == 'sftp'){
      parameters.private_key = pathkey
    }
    try {
      await remoteConnectConfirm(parameters).then((res:any)=>{
        setIsConnecting(false)
        setConnectionResult(res.result)
        console.log("remoteConnectConfirm res", res)
      })
    } catch(err){
      setIsConnecting(false)
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className='card'>
          <div className='card-header'>
            <div className='card-title fw-bold'>接続設定</div>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-primary'>
                新しい接続を作成
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='row mb-8'>
              <div className='col-12 mb-4 align-items-center'>
                <span className='fw-bold'>基本設定</span>
              </div>
              <div className='col-12'>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>接続名</div>
                  <div className='col-9'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='name'
                      name='name'
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <div className='text-danger'>{formik.errors.name}</div>
                    )}
                  </div>
                </div>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>接続タイプ</div>
                  <div className='col-9'>
                    {types.current.map((type, index) => (
                      <div key={index} className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='type'
                          id={type.value}
                          value={type.value}
                          checked={formik.values.type === type.value}
                          onChange={formik.handleChange}
                        />
                        <label className='form-check-label' htmlFor={type.value}>
                          {type.label}
                        </label>
                      </div>
                    ))}
                    {formik.errors.type && formik.touched.type && (
                      <div className='text-danger'>{formik.errors.type}</div>
                    )}
                  </div>
                </div>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>ステータス</div>
                  <div className='col-9'>
                    {enables.current.map((enable, index) => (
                      <div key={index} className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='enable'
                          id={enable.value}
                          value={enable.value}
                          checked={formik.values.enable === enable.value}
                          onChange={formik.handleChange}
                        />
                        <label className='form-check-label' htmlFor={enable.value}>
                          {enable.label}
                        </label>
                      </div>
                    ))}
                    {formik.errors.enable && formik.touched.enable && (
                      <div className='text-danger'>{formik.errors.enable}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-12 mb-4'>
                <span className='fw-bold'>出力/取り込みするCSVテンプレート名</span>
              </div>
              <div className='col-12'>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>CSVテンプレート名</div>
                  <div className='col-9'>
                    <select
                      className='form-select form-select-sm w-auto'
                      id='templete_id'
                      name='templete_id'
                      value={formik.values.templete_id}
                      onChange={formik.handleChange}
                    >
                      {template_ids.current.map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                    {formik.errors.templete_id && formik.touched.templete_id && (
                      <div className='text-danger'>{formik.errors.templete_id}</div>
                    )}
                  </div>
                </div>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>ファイル形式</div>
                  <div className='col-9'>
                    <select
                      className='form-select form-select-sm w-auto'
                      id='format'
                      name='format'
                      value={formik.values.format}
                      onChange={formik.handleChange}
                    >
                      {formats.current.map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                    {formik.errors.format && formik.touched.format && (
                      <div className='text-danger'>{formik.errors.format}</div>
                    )}
                  </div>
                </div>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>ヘッダー</div>
                  <div className='col-9'>
                    {headerExists.current.map((type, index) => (
                      <div key={index} className='form-check form-check-inline'>
                        <input
                          className='form-check-input'
                          type='radio'
                          name='header_exists'
                          id={type.value}
                          value={type.value}
                          checked={formik.values.header_exists === type.value}
                          onChange={formik.handleChange}
                        />
                        <label className='form-check-label' htmlFor={type.value}>
                          {type.label}
                        </label>
                      </div>
                    ))}
                    {formik.errors.header_exists && formik.touched.header_exists && (
                      <div className='text-danger'>{formik.errors.header_exists}</div>
                    )}
                  </div>
                </div>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>文字コード</div>
                  <div className='col-9'>
                    <select
                      className='form-select form-select-sm w-auto'
                      id='charactor_code'
                      name='charactor_code'
                      value={formik.values.charactor_code}
                      onChange={formik.handleChange}
                    >
                      {charactor_codes.current.map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                    {formik.errors.charactor_code && formik.touched.charactor_code && (
                      <div className='text-danger'>{formik.errors.charactor_code}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-12 mb-4'>
                <span className='fw-bold'>接続先アカウント</span>
              </div>
              <div className='col-12'>
                <div className='row mb-4 align-items-center'>
                  <div className='col-3'>接続タイプ</div>
                  <div className='col-9'>
                    <select
                      className='form-select form-select-sm w-auto'
                      id='connection_type'
                      name='connection_type'
                      value={formik.values.connection_type}
                      onChange={formik.handleChange}
                    >
                      {connectionTypes.current.map((connection_type, index) => (
                        <option key={index} value={connection_type.value}>
                          {connection_type.label}
                        </option>
                      ))}
                    </select>
                    {formik.errors.connection_type && formik.touched.connection_type && (
                      <div className='text-danger'>{formik.errors.connection_type}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-4 align-items-center'>
                  <div className='col-3'>ホスト</div>
                  <div className='col-9'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='host'
                      name='host'
                      value={formik.values.host}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.host && formik.touched.host && (
                      <div className='text-danger'>{formik.errors.host}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-4 align-items-center'>
                  <div className='col-3'>ポート</div>
                  <div className='col-9'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='port'
                      name='port'
                      value={formik.values.port}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.port && formik.touched.port && (
                      <div className='text-danger'>{formik.errors.port}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-4 align-items-center'>
                  <div className='col-3'>ユーザー名</div>
                  <div className='col-9'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='user'
                      name='user'
                      value={formik.values.user}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.user && formik.touched.user && (
                      <div className='text-danger'>{formik.errors.user}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-4 align-items-center'>
                  <div className='col-3'>パスワード</div>
                  <div className='col-9'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='password'
                      name='password'
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <div className='text-danger'>{formik.errors.password}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-4 align-items-center'>
                  <div className='col-3'>秘密鍵</div>
                  <div className='col-9'>
                    <textarea
                      className='form-control text-start text-dark'
                      style={{ color: '#A1A5B7' }}
                      id='pathkey'
                      name='pathkey' rows={8}
                      value={formik.values.pathkey}
                      onChange={formik.handleChange}
                      disabled={formik.values.connection_type === 'ftp'}
                    ></textarea>
                    {formik.errors.pathkey && formik.touched.pathkey && (
                      <div className='text-danger'>{formik.errors.pathkey}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 d-flex align-items-center'>
                  <button type='button' className='btn btn-info' onClick={handleFtpConnect}>
                    <Spin spinning={isConnecting}>接続を確認する</Spin>
                  </button>
                  {isConnecting && <span className='ms-3'>接続中...</span>}
                  {!isConnecting && connectionResult === 'error' && (
                    <span className='ms-3 text-danger'>接続失敗</span>
                  )}
                  {!isConnecting && connectionResult === 'success' && (
                    <span className='ms-3 text-success'>接続成功</span>
                  )}
                </div>
              </div>
            </div>
            <div className='row mb-8'>
              <div className='col-12 mb-4'>
                <span className='fw-bold'>ファイル名・ディレクトリ名</span>
              </div>
              <div className='col-12'>
                <div className='row mb-4 align-items-center'>
                  <div className='col-3'>ディレクトリ</div>
                  <div className='col-9'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      id='directory'
                      name='directory'
                      value={formik.values.directory}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.directory && formik.touched.directory && (
                      <div className='text-danger'>{formik.errors.directory}</div>
                    )}
                  </div>
                </div>
                <div className='row mb-4 align-items-center'>
                  <div className='col-3'>ファイル名</div>
                  <div className='col-9'>
                    {formik.values.type === 'import' && (
                      <>
                        <input
                          type='text'
                          className='form-control form-control-sm'
                          id='filename'
                          name='filename'
                          value={formik.values.filename}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.filename && formik.touched.filename && (
                          <div className='text-danger'>{formik.errors.filename}</div>
                        )}
                      </>
                    )}
                    {formik.values.type === 'export' && (
                      <span>
                        export
                        {formik.values.format === 'csv' && '.csv'}
                        {formik.values.format === 'tsv' && '.tsv'}
                        {formik.values.format === 'json' && '.json'}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-12 mb-4'>
                <span className='fw-bold'>取得条件</span>
              </div>
              <div className='col-12'>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>受注　受注日時</div>
                  <div className='col-9'>
                    <div className='d-flex align-items-center'>
                      <select
                        className='form-select form-select-sm w-auto'
                        id='conditions_order_date_range'
                        name='conditions_order_date_range'
                        value={formik.values.conditions_order_date_range}
                        onChange={formik.handleChange}
                      >
                        {conditionsDateRange.current.map((dataRange, index) => (
                          <option key={index} value={dataRange.value}>
                            {dataRange.label}
                          </option>
                        ))}
                      </select>
                      {formik.errors.conditions_order_date_range &&
                        formik.touched.conditions_order_date_range && (
                          <div className='text-danger'>
                            {formik.errors.conditions_order_date_range}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>受注　ステータス</div>
                  <div className='col-9'>
                    <div className='d-flex align-items-center'>
                      <select
                        className='form-select form-select-sm w-auto'
                        id='conditions_order_status'
                        name='conditions_order_status'
                        value={formik.values.conditions_order_status}
                        onChange={formik.handleChange}
                      >
                        {conditionsOrderStatus.current.map((status, index) => (
                          <option key={index} value={status.value}>
                            {status.label}
                          </option>
                        ))}
                      </select>
                      {formik.errors.conditions_order_status &&
                        formik.touched.conditions_order_status && (
                          <div className='text-danger'>{formik.errors.conditions_order_status}</div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>決済　決済日時</div>
                  <div className='col-9'>
                    <div className='d-flex align-items-center'>
                      <select
                        className='form-select form-select-sm w-auto'
                        id='conditions_payment_date_range'
                        name='conditions_payment_date_range'
                        value={formik.values.conditions_payment_date_range}
                        onChange={formik.handleChange}
                      >
                        {conditionsDateRange.current.map((dataRange, index) => (
                          <option key={index} value={dataRange.value}>
                            {dataRange.label}
                          </option>
                        ))}
                      </select>
                      {formik.errors.conditions_payment_date_range &&
                        formik.touched.conditions_payment_date_range && (
                          <div className='text-danger'>
                            {formik.errors.conditions_payment_date_range}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>決済　決済ステータス</div>
                  <div className='col-9'>
                    <div className='d-flex align-items-center'>
                      <select
                        className='form-select form-select-sm w-auto'
                        id='conditions_payment_status'
                        name='conditions_payment_status'
                        value={formik.values.conditions_payment_status}
                        onChange={formik.handleChange}
                      >
                        {conditionsPaymentStatus.current.map((status, index) => (
                          <option key={index} value={status.value}>
                            {status.label}
                          </option>
                        ))}
                      </select>
                      {formik.errors.conditions_payment_status &&
                        formik.touched.conditions_payment_status && (
                          <div className='text-danger'>
                            {formik.errors.conditions_payment_status}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>出荷　出荷日時</div>
                  <div className='col-9'>
                    <div className='d-flex align-items-center'>
                      <select
                        className='form-select form-select-sm w-auto'
                        id='conditions_delivery_date_range'
                        name='conditions_delivery_date_range'
                        value={formik.values.conditions_delivery_date_range}
                        onChange={formik.handleChange}
                      >
                        {conditionsDateRange.current.map((dataRange, index) => (
                          <option key={index} value={dataRange.value}>
                            {dataRange.label}
                          </option>
                        ))}
                      </select>
                      {formik.errors.conditions_delivery_date_range &&
                        formik.touched.conditions_delivery_date_range && (
                          <div className='text-danger'>
                            {formik.errors.conditions_delivery_date_range}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>出荷　出荷ステータス</div>
                  <div className='col-9'>
                    <div className='d-flex align-items-center'>
                      <select
                        className='form-select form-select-sm w-auto'
                        id='conditions_delivery_status'
                        name='conditions_delivery_status'
                        value={formik.values.conditions_delivery_status}
                        onChange={formik.handleChange}
                      >
                        {conditionsDeliveryStatus.current.map((status, index) => (
                          <option key={index} value={status.value}>
                            {status.label}
                          </option>
                        ))}
                      </select>
                      {formik.errors.conditions_delivery_status &&
                        formik.touched.conditions_delivery_status && (
                          <div className='text-danger'>
                            {formik.errors.conditions_delivery_status}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 mb-4 alert alert-primary'>
                <span>条件はテンプレートにそれぞれのデータが含まれる時に適用されます。</span>
              </div>
            </div>

            <div className='row mb-8'>
              <div className='col-12 mb-4'>
                <span className='fw-bold'>接続頻度</span>
              </div>
              <div className='col-12'>
                <div className='row align-items-center mb-4'>
                  <div className='col-3'>タイミング</div>
                  <div className='col-9'>
                    <div className='d-flex align-items-center'>
                      <select
                        className='form-select form-select-sm w-auto'
                        id='timing'
                        name='timing'
                        value={formik.values.timing}
                        onChange={formik.handleChange}
                      >
                        {timings.current.map((timing, index) => (
                          <option key={index} value={timing.value}>
                            {timing.label}
                          </option>
                        ))}
                      </select>
                      {formik.errors.timing && formik.touched.timing && (
                        <div className='text-danger'>{formik.errors.timing}</div>
                      )}
                    </div>
                  </div>
                </div>

                {formik.values.timing === 'monthly' && (
                  <div className='row align-items-center mb-4'>
                    <div className='col-3'>実行タイミング</div>
                    <div className='col-9'>
                      {monthlyTimings.current.map((type, index) => (
                        <div key={index} className='form-check form-check-inline'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='monthly_timing'
                            id={type.value}
                            value={type.value}
                            checked={formik.values.monthly_timing === type.value}
                            onChange={formik.handleChange}
                          />
                          <label className='form-check-label' htmlFor={type.value}>
                            {type.label}
                          </label>
                        </div>
                      ))}
                      {formik.errors.monthly_timing && formik.touched.monthly_timing && (
                        <div className='text-danger'>{formik.errors.monthly_timing}</div>
                      )}
                    </div>
                  </div>
                )}

                {formik.values.timing === 'monthly' && formik.values.monthly_timing === 'weekdays' && (
                  <>
                    <div className='row align-items-center mb-4'>
                      <div className='col-3'>期間</div>
                      <div className='col-9'>
                        {weekranges.current.map((weekrange, index) => (
                          <div key={`weekrange_${index}`} className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              name='weekranges'
                              id={weekrange.value}
                              value={weekrange.value}
                              checked={(formik.values.weekranges as string[]).includes(
                                weekrange.value
                              )}
                              onChange={formik.handleChange}
                            />
                            <label className='form-check-label' htmlFor={weekrange.value}>
                              {weekrange.label}
                            </label>
                          </div>
                        ))}
                        {formik.errors.weekranges && formik.touched.weekranges && (
                          <div className='text-danger'>{formik.errors.weekranges}</div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {(formik.values.timing === 'weekly' ||
                  (formik.values.timing === 'monthly' &&
                    formik.values.monthly_timing === 'weekdays')) && (
                  <>
                    <div className='row align-items-center mb-4'>
                      <div className='col-3'>曜日指定</div>
                      <div className='col-9'>
                        {weekdays.current.map((weekday, index) => (
                          <div key={`weekday_${index}`} className='form-check form-check-inline'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              name='weekdays'
                              id={weekday.value}
                              value={weekday.value}
                              checked={(formik.values.weekdays as string[]).includes(weekday.value)}
                              onChange={formik.handleChange}
                            />
                            <label className='form-check-label' htmlFor={weekday.value}>
                              {weekday.label}
                            </label>
                          </div>
                        ))}
                        {formik.errors.weekdays && formik.touched.weekdays && (
                          <div className='text-danger'>{formik.errors.weekdays}</div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {formik.values.timing === 'monthly' && formik.values.monthly_timing === 'date' && (
                  <div className='row align-items-center mb-4'>
                    <div className='col-3'>日付指定</div>
                    <div className='col-9'>
                      {days.current.map((day, index) => (
                        <div key={`day_${index}`} className='form-check form-check-inline'>
                          <input
                            className='form-check-input mb-3'
                            type='checkbox'
                            name='days'
                            id={day.value}
                            value={day.value}
                            checked={(formik.values.days as string[]).includes(day.value)}
                            onChange={formik.handleChange}
                          />
                          <label className='form-check-label' htmlFor={day.value}>
                            {day.label}
                          </label>
                        </div>
                      ))}
                      {formik.errors.days && formik.touched.days && (
                        <div className='text-danger'>{formik.errors.days}</div>
                      )}
                    </div>
                  </div>
                )}

                <div className='row align-items-center mb-4'>
                  <div className='col-3'>時間</div>
                  <div className='col-9'>
                    <div className='d-flex align-items-center'>
                      <div className='me-3'>
                        <input
                          type='text'
                          className='form-control form-control-sm '
                          id='start_time_hour'
                          name='start_time_hour'
                          value={formik.values.start_time_hour}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <span className='me-3'>時</span>

                      <div className='me-3'>
                        <input
                          type='text'
                          className='form-control form-control-sm'
                          id='start_time_minute'
                          name='start_time_minute'
                          value={formik.values.start_time_minute}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <span className=''>分</span>
                    </div>
                    {formik.errors.start_time_hour && formik.touched.start_time_hour && (
                      <div className='text-danger'>{formik.errors.start_time_hour}</div>
                    )}
                    {formik.errors.start_time_minute && formik.touched.start_time_minute && (
                      <div className='text-danger'>{formik.errors.start_time_minute}</div>
                    )}
                  </div>
                </div>

                <div className='row align-items-center mb-4'>
                  <div className='col-3'>繰り返し（１日）</div>
                  <div className='col-9'>
                    <select
                      className='form-select form-select-sm w-auto'
                      id='daily_timing'
                      name='daily_timing'
                      value={formik.values.daily_timing}
                      onChange={formik.handleChange}
                    >
                      {dailyTimings.current.map((daily_timing, index) => (
                        <option key={`daily_timing_${index}`} value={daily_timing.value}>
                          {daily_timing.label}
                        </option>
                      ))}
                    </select>
                    {formik.errors.daily_timing && formik.touched.daily_timing && (
                      <div className='text-danger'>{formik.errors.daily_timing}</div>
                    )}
                  </div>
                </div>

                <div className='row align-items-center mb-4'>
                  <div className='col-3'>次回実行予定時間</div>
                  <div className='col-9'>{moment().add(1, 'days').format('YYYY/MM/DD HH:mm')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer'>
            <button type='submit' className='btn btn-primary'>
              保存
            </button>
          </div>
        </div>
      </Form>
    </FormikProvider>
  )
}
