/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

const data= [15, 10, 5];
const color= ['#F8285A', '#1B84FF', '#E4E6EF'];

type Props = {
  className: string
}

const ChartsWidget11: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`${className}`}>
      {/* begin::Body */}
        {/* begin::Chart */}
		<div className="card-body d-flex align-items-center row">
			<div ref={chartRef} id='kt_card_widget_4_chart' style={{height: '100px'}} className='col-md-5'></div>
			<div className='col-md-5'>
				<div className="d-flex flex-column content-justify-center w-100">
					<div className="d-flex fs-6 fw-semibold align-items-center">
						<div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
						<div className="flex-grow-1 me-4">ミニマム</div>
						<div className="fw-bolder text-xxl-end">{ data[0] }社</div>
					</div>
					<div className="d-flex fs-6 fw-semibold align-items-center my-3">
						<div className="bullet w-8px h-6px rounded-2 bg-primary me-3"></div>
						<div className="flex-grow-1 me-4">basic</div>
						<div className="fw-bolder text-xxl-end">{ data[1] }社</div>
					</div>
					<div className="d-flex fs-6 fw-semibold align-items-center">
						<div className="bullet w-8px h-6px rounded-2 me-3" style={{backgroundColor: "#E4E6EF"}}></div>
						<div className="flex-grow-1 me-4">プロプラン</div>
						<div className=" fw-bolder text-xxl-end">{ data[2] }社</div>
					</div>
				</div>
			</div>
		</div>
        {/* end::Chart */}
      {/* end::Body */}
    </div>
  )
}

export {ChartsWidget11}

function getChartOptions(height: number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = ('#1AC054')
  const lightColor = ('#ECFAF1')

  return {
    series: data,
	colors: color,
	labels: ['ミニマム', 'basic', 'プロプラン'],
	legend: {
		show: false,
	  },
	dataLabels: {
		enabled: false,
	  },
    chart: {
	  type: 'donut',
    }
  }
}
