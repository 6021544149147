import axios from 'axios'
import { getServer, postServer, deleteServer } from 'src/service/http'

// AWS ---------------------------------------

// パラメーター取得
export function parameterStoreGet(param: object,headers: object={}) {
    return postServer(`/parameterstore-get`, { ...param },{...headers})
}

export function getS3SignedUrl(value: any) {
    return getServer(`/get-s3SignedUrl`, value)
}

// 获取预签名URL
export function getSignedUrl(param: object) {
    return deleteServer('/getSignedUrl', { ...param });
}

//获取图片存储桶url
export async function getUploadFile(value: any,file:any) {
    const resp:any =  await getS3SignedUrl(value)
    console.log('resp',resp)
    const uploadResp = await axios.put(resp.data, file, {
      headers: {
        'Accept': '*',
        'Content-Type': file.type
      }
    })
    console.log('uploadResp',uploadResp)
    const getResp:any =  await getS3SignedUrl({key: value.key, action: 'get'})
    console.log('getResp',getResp)
    // const getUploadResp = await axios.get(getResp.data)
    console.log('getUploadResp',getResp.data)
    return getResp.data
  }