/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../../auth'
import {useNavigate} from 'react-router-dom'
import {Button, Modal} from 'react-bootstrap'
// import {specialCharToUnderline} from '../../../../format'
import VerificationModal from './VerificationModal'
import {Spin} from 'antd'

// const initialValues = {
//   first_name: '',
//   last_name: '',
//   login_id: '',
//   email: '',
//   password: '',
//   password_confirmation: '',
//   acceptTerms: false,
// }

const initialValues = {
  first_name: 'hathu',
  last_name: 'tarou',
  username: '',
  user_type: '',
  password: 'Upshare0617@',
  password_confirmation: 'Upshare0617@',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  // first_name: Yup.string()
  //   // .min(3, '最小3文字')
  //   .max(50, '最大50文字')
  //   .required('名字は必須です'),
  // last_name: Yup.string()
  //   // .min(3, '最小3文字')
  //   .max(50, '最大50文字')
  //   .required('名前は必須です'),
  username: Yup.string()
    .email('メールのフォーマットが正しくありません')
    .min(3, '最小3文字')
    // .matches(/^[a-zA-Z0-9\_\-\+\.]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/,"メールのフォーマットが正しくありません")
    .max(50, '最大50文字')
    .required('メールは必須です'),
  // login_id: Yup.string()
  //   .min(3, '最小3文字')
  //   .max(50, '最大50文字')
  //   .matches(/^[0-9A-Za-z-]{2,50}$/,"ユーザー 名のフォーマットが正しくありません")
  //   .required('ユーザー 名は必須です'),
  password: Yup.string()
    .min(8, '最小8文字')
    // .max(16, '最大16文字')
    // .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,"パスワードのフォーマットが正しくありません")
    .required('パスワードは必須です'),
  password_confirmation: Yup.string()
    .required('確認パスワードは必須です')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'パスワードと確認パスワードが一致しません'),
    }),
  acceptTerms: Yup.bool().required('契約条件を受け入れる必要があります'),
})

export function RegisterMember() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [pswIsvisit, setpswIsvisit] = useState(false)
  const {auth} = useAuth()
  // const {saveAuth, setCurrentUser} = useAuth()
  const [inputValue, inputChange] = useState('')

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (
      values: {
        first_name: string
        last_name: string
        username: string
        user_type: string
        // login_id: string
        password: string
        password_confirmation: string
      },
      {setStatus, setSubmitting}
    ) => {
      console.log('values', values)
      try {
        const result = await auth.signUp(values.username, values.password, {
          'custom:user_type': values.user_type,
        })
        if (result.success) {
          // navigate({ pathname: '/dashboard' });
          setShowModal(true)
        } else {
          throw new Error(result.message)
        }
      } catch (error) {
        const typedError = error as Error
        console.error(typedError)
        setStatus(typedError.message)
      }
      // const obj = {
      //   isProfileLogin: 0,
      //   userInfo: {
      //     first_name: values.first_name,
      //     last_name: values.last_name,
      //     email: values.email,
      //     login_id: values.login_id,
      //     password: values.password,
      //     password_confirmation: values.password_confirmation,
      //   },
      //   host: window.location.host
      // }
      // register(obj)
      //   .then((res: any) => {
      //     console.log('register',res);
      //     if (res) {
      //       navigate('/auth/SuccessfulAuthentication', {
      //         replace: true,
      //         state: {username: values.login_id, values: values.password, flag: false},
      //       })
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err)
      //     saveAuth(undefined)
      //     setStatus('The registration details is incorrect')
      //     setSubmitting(false)
      //     setLoading(false)
      //   })
    },
  })

  // ラジオボタンの onChange ハンドラー
  const handleUserTypeChange = (event: any) => {
    const {value} = event.target
    formik.setFieldValue('user_type', value)
  }

  const formatInput = (value: string): void => {
    inputChange(value)
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px'>
          <div className='d-flex flex-stack py-2'>
            {/* <div className='me-2'>
              <Link to='/auth' className='btn btn-icon bg-light rounded-circle'>
                <span className='svg-icon svg-icon-2 svg-icon-gray-800'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z'
                      fill='currentColor'
                    ></path>
                    <path
                      opacity='0.3'
                      d='M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z'
                      fill='currentColor'
                    ></path>
                  </svg>
                </span>
              </Link>
            </div> */}

            {/* <div className='m-0'> */}
            {/* <span className='fw-bold fs-5 me-2' data-kt-translate='sign-in-head-desc'>
                もう会員ですか？
              </span> */}

            {/* <Link
                to='/auth'
                className='fw-bold fs-5'
                data-kt-translate='sign-in-head-link'
              >
                アカウントをお持ちの方はこちら
              </Link> */}
            {/* </div> */}
            <div className='w-100 separator separator-content my-6'>
              <span className='fw-bold fs-4' style={{width: '10em'}}>
                会員登録
              </span>
            </div>
          </div>
          <div className='pb-0'>
            {/* <div className='card-body'>
              <div className='text-start mb-10'>
                <h1 className='text-dark mb-3 fs-3x' data-kt-translate='sign-in-title'>
                  サインアップ
                </h1>

                <div className='fw-semibold fs-6' data-kt-translate='general-desc'>
                  無制限のアクセスを取得してお金を稼ぐ
                </div>
              </div>
            </div> */}

            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            <div className='fv-row mb-3'>
              <label className='mb-1'>アカウント種別</label>
              <div className='d-flex flex-column gap-2'>
                <label className='d-flex align-item-center gap-2' htmlFor=''>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='user_type'
                    value='supplier'
                    onChange={handleUserTypeChange}
                    checked={formik.values.user_type === 'supplier'}
                  />
                  <span>加盟店</span>
                </label>

                <label className='d-flex align-item-center gap-2' htmlFor=''>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='user_type'
                    value='buyer'
                    onChange={handleUserTypeChange}
                    checked={formik.values.user_type === 'buyer'}
                  />
                  <span>利用者</span>
                </label>
              </div>
            </div>

            <div className='fv-row mb-3'>
              <label className='mb-1'>メールアドレス</label>
              <input
                placeholder=''
                {...formik.getFieldProps('username')}
                className={clsx(
                  'form-control bg-transparent',
                  {'is-invalid': formik.touched.username && formik.errors.username},
                  {
                    'is-valid': formik.touched.username && !formik.errors.username,
                  }
                )}
                //type='email'
                name='username'
                autoComplete='off'
                // onChange={formik.handleChange}
                // value={formik.values.email}
              />
              {formik.touched.username && formik.errors.username && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.username}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-3' data-kt-password-meter='true'>
              <label className='mb-1'>パスワード</label>
              <div className='mb-1'>
                <div className='position-relative mb-3'>
                  <input
                    type={pswIsvisit ? '' : 'password'}
                    placeholder='パスワード'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control bg-transparent onIcon',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  <span
                    className='btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                    data-kt-password-meter-control='visibility'
                  >
                    <i
                      onClick={() => setpswIsvisit(true)}
                      className={
                        pswIsvisit ? 'bi bi-eye-slash fs-2 d-none' : 'bi bi-eye-slash fs-2'
                      }
                    ></i>
                    <i
                      onClick={() => setpswIsvisit(false)}
                      className={pswIsvisit ? 'bi bi-eye fs-2' : 'bi bi-eye fs-2 d-none'}
                    ></i>
                  </span>
                </div>

                <div
                  className='d-flex align-items-center mb-3'
                  data-kt-password-meter-control='highlight'
                >
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
              </div>
              <div className='text-dark'>
                文字、数字、記号を組み合わせた8文字以上を使用してください。
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='fv-row mb-5'>
              <label className='mb-1'>パスワード（確認）</label>
              <input
                type='password'
                placeholder='パスワード'
                autoComplete='off'
                {...formik.getFieldProps('password_confirmation')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid':
                      formik.touched.password_confirmation && formik.errors.password_confirmation,
                  },
                  {
                    'is-valid':
                      formik.touched.password_confirmation && !formik.errors.password_confirmation,
                  }
                )}
              />
              {formik.touched.password_confirmation && formik.errors.password_confirmation && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password_confirmation}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex flex-stack mb-4'>
              <div className='col-12 col-lg-6 offset-lg-3'>
                <Spin spinning={loading}>
                  <button
                    id='kt_sign_in_submit'
                    className='btn btn-primary w-100 me-2 flex-shrink-0'
                  >
                    {!auth.isLoading && <span className='indicator-label'>サインアップ</span>}
                    {auth.isLoading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        お待ちください...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </Spin>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 text-center'>
                <Link to='/auth' className='fs-6' data-kt-translate='sign-in-head-link'>
                  既に会員の場合はこちら
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
      <VerificationModal showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}
