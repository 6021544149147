import React from 'react';
import { useField } from 'formik';

interface CustomSelectOption {
    value: string;
    label: string;
  }
  
  interface CustomSelectProps {
    label: string;
    name: string;
    options: CustomSelectOption[];
    [key: string]: any;
  }
  
  const CustomSelect: React.FC<CustomSelectProps> = ({ label, options, ...props }) => {
    const [field, meta] = useField(props);
  
    return (
      <div className='row'>
        <div className='col-12 align-self-center'>
          <select className='form-select' {...field} {...props}>
            <option value=''>選択してください</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        {meta.touched && meta.error ? (
        <div className='col-12 fv-plugins-message-container'>
            <div className='fv-help-block'>{meta.error}</div>
        </div>
        ) : null}
      </div>
    );
  };
  

  export default CustomSelect;