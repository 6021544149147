import React, {CSSProperties, useCallback} from 'react'

const SortIcon = React.memo(({direction}: any) => {
  return direction === 'asc' ? (
    <i className='bi bi-sort-down-alt fs-3 ms-2'></i>
  ) : (
    <i className='bi bi-sort-down fs-3 ms-2'></i>
  )
})

type TableHeaderProps = {
  onSortColumn: (sort: {id: string; type: 'asc' | 'desc'}) => void
  id: string
  title: string
  sort?: {id?: string; type?: 'asc' | 'desc'}
  textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent'
  style?: CSSProperties
}
export default function TableHeader(props: TableHeaderProps) {
  const {id, title, sort, onSortColumn, textAlign, style} = props

  const handleSort = useCallback(() => {
    const typeSort = sort?.type === 'asc' ? 'desc' : 'asc'
    onSortColumn({id, type: typeSort})
  }, [id, onSortColumn, sort?.type])

  return (
    <th id={id} style={{cursor: 'pointer', textAlign, ...style}} onClick={handleSort}>
      {title}
      {sort?.id === id && <SortIcon direction={sort.type} />}
    </th>
  )
}
