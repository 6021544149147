import React, { useState } from 'react';
import { useAuth } from "src/app/modules/auth";
import {Spin} from 'antd'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// バリデーションスキーマ
const emailSchema = Yup.object().shape({
  newEmail: Yup.string().email('無効なメールアドレス形式です').required('メールアドレスは必須です'),
});

const verificationCodeSchema = Yup.object().shape({
  verificationCode: Yup.string().required('検証コードは必須です').matches(/^[a-zA-Z0-9]*$/, '検証コードは半角英数字のみです'),
});

export function ChangeEmail(props: any) {
  const { auth } = useAuth();

  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>メールアドレスの変更</h3>
        </div>
      </div>
      <div className='card-body p-9'>
        <Formik
          initialValues={{ newEmail: '', verificationCode: '' }}
          validationSchema={emailSubmitted ? verificationCodeSchema : emailSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setLoading(true);
            if (!emailSubmitted) {
              try {
                const user = await auth.currentAuthenticatedUser();
                await auth.updateUserAttributes(user, {
                  email: values.newEmail,
                });
                setEmailSubmitted(true);
                alert('入力されたメールアドレスに検証コードを送信しました。');
              } catch (error) {
                console.error('Error updating email:', error);
                alert('メールアドレスの更新処理に失敗しました。時間をおいて再度お試しください。');
              }
            } else {
              try {
                await auth.verifyCurrentUserAttributeSubmit('email', values.verificationCode);
                alert('メールアドレスの変更が完了しました。');
                setEmailSubmitted(false); // プロセス完了後にリセット
              } catch (error) {
                console.error('Error verifying new email:', error);
                alert('検証コードの処理中にエラーが発生しました。検証コードを再入力するか、時間をおいて再度お試しください。');
              }
            }
            setLoading(false);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form>
              {/* メールアドレス入力フィールド */}
              <div className='row mb-7'>
                <label className='col-lg-4 col-form-label fw-bold fs-6 required'>新しいメールアドレス</label>
                <div className='col-lg-8 fv-row'>
                  <Field type='email' name='newEmail' className='form-control me-3 flex-grow-1' />
                  <ErrorMessage name='newEmail' component='div' className='text-danger' />
                </div>
              </div>
              
              {/* 検証コード入力フィールド */}
              {emailSubmitted && (
                <div className='row mb-7'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6 required'>検証コード</label>
                  <div className='col-lg-8 fv-row'>
                    <Field type='text' name='verificationCode' className='form-control me-3 flex-grow-1' />
                    <ErrorMessage name='verificationCode' component='div' className='text-danger' />
                  </div>
                </div>
              )}
              
              {/* 送信ボタン */}
              <div className='d-flex justify-content-end'>
                <Spin spinning={isSubmitting || loading}>
                  <button className="btn btn-primary fw-bold" type="submit" disabled={isSubmitting || loading}>
                    {loading ? "検証コードを送信" : "メールアドレスを検証する"}
                  </button>
                </Spin>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}