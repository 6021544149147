import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import type { UploadProps } from 'antd'
import { Button, Form, message, Upload, Progress } from 'antd'
import type { UploadFile } from 'antd/es/upload/interface'
import { imageAdd, imageUpload, imageDelete, imageGet, getSignedUrl } from 'src/app/modules/api'
import axios from 'axios'
import { useAuth } from 'src/app/modules/auth'
import { generateMixed } from '../../../utils'
//import https from "https";
//import * as https from 'https'
//import crypto from "crypto";
//import crypto from 'crypto-js';
//import * as CryptoJS from 'crypto-browserify';
// import { API_URL } from 'src/service/api'

const UploadFileCompont = forwardRef(({ value, onChange, setIsLoading }: any, ref) => {
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [progress, setProgress] = useState(0)
    const [uploadUrl, setUploadUrl] = useState('')
    const { auth } = useAuth();
    useEffect(() => {
        initData()
        console.log('fileList', fileList)
    }, [])
    useImperativeHandle(ref, () => ({
        init: initData
      }))
    
    let headers = {
    "Content-Type":"application/json",
    Authorization:`Bearer ${auth.token}`
    }
    // 编辑页面图片回显
    const initData = async (pk?:any) => {
        // let uuid = Date.now()
        // const res: any = await getSignedUrl({
        //     fileName: uuid,
        //     type: 1,
        //     // expiresIn: '',
        //     // bucket: ''
        // })
        // setUploadUrl(res.data.url)
        // const res2: any = await getSignedUrl({
        //     fileName: uuid,
        //     type: 0,
        //     // expiresIn: '',
        //     // bucket: ''
        // })
        // console.log(res2.data.url)
        console.log(pk)
        if (pk) {
            const resp: any = await imageGet({ pk })
            let obj:any = {
                uid: Date.now(), // uid一定不能少，否则上传失敗
                name: resp.item.name,
                status: 'done',
                url: resp.item.path, // url 是展示在页面上的绝对链接
            }
            setFileList([obj])
        }else{
            setFileList([])
        }
    }
    // 上传图片之前的校验（文件大小）
    const beforeUpload: any = async (file: any) => {
        console.log('file', file)
    }
    // 监听上传好的文件并转给父组件
    const handleChange: UploadProps['onChange'] = ({ fileList }) => {
        console.log('Aliyun OSS:', fileList)
        //onChange?.([fileList.map((k: any) => k.name)])
    }
    // 图片删除
    const onRemove = (file: UploadFile) => {
        const files = (fileList || []).filter((v: any) => v.url !== file.url)
        if (onChange) {
            setFileList([...files])
            onChange('')
        }
    }
    // file 文件流通过 formData 转base64
    const formDataToBase64 = (formData: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                resolve(event.target.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            const file = formData.get('file');
            reader.readAsDataURL(file);
        });
    }
    // 自定义上传图片
    // const uploadImage = async (data: any) => {
    //     console.log('uploadImage', data)
    //     const { action, onSuccess, file, onError, onProgress } = data
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     const base64data = await formDataToBase64(formData)
    //     try {
    //         const uploadResp: any = await imageAdd({
    //             fileName: file.name,
    //             data: base64data
    //         })
    //         console.log('uploadResp', uploadResp)
    //         const getResp: any = await imageGet({ pkVal: uploadResp.item.pk })
    //         console.log('getResp', getResp)
    //         const imgItem: any = {
    //             uid: Date.now(), // uid一定不能少，否则上传失敗
    //             name: getResp.item.name,
    //             status: 'done',
    //             url: getResp.item.path, // url 是展示在页面上的绝对链接
    //         }
    //         fileList.push(imgItem)
    //         setFileList([...fileList])
    //         onSuccess([...fileList])
    //         onChange?.(fileList.map((k: any) => k.name))
    //     } catch (err) {
    //         console.log('Eroor: ', err)
    //         onError({ err })
    //     }
    // }
    // function put(url:any, data:any) {
    //     return new Promise((resolve, reject) => {
    //         const req = https.request(
    //             url,
    //             { method: "PUT", headers: { "Content-Length": new Blob([data]).size } },
    //             (res) => {
    //                 let responseBody = "";
    //                 res.on("data", (chunk) => {
    //                     responseBody += chunk;
    //                 });
    //                 res.on("end", () => {
    //                     resolve(responseBody);
    //                 });
    //             },
    //         );
    //         req.on("error", (err) => {
    //             reject(err);
    //         });
    //         req.write(data);
    //         req.end();
    //     });
    // }
    const uploadImage = async (data: any) => {
        console.log('uploadImage', data)
        const {action, onSuccess, file, onError, onProgress} = data
        try {
            setIsLoading(true)
            // var aa = await put(action, "hello");
            // console.log(aa);
          const uploadResp:any = await axios.put(action.url, file, {
            //method: "PUT",
            // headers: {
            //   Accept: '*',
            //   'Content-Type': file.type,
            // },
            headers: { "Content-Length": file.size },
            onUploadProgress: (event: any) => {
              const percent = Math.floor((event.loaded / event.total) * 100)
              setProgress(percent)
              if (percent === 100) {
                setTimeout(() => setProgress(0), 1000)
              }
              onProgress({percent: (event.loaded / event.total) * 100})
            },
          })

          const getResp: any = await getSignedUrl({key: file.key, action: 'get'})
          console.log('getResp', getResp)
          let res:any = await imageAdd({
            name: file.key,
            path: getResp.data.url,
            status: 'publish'
        }, {headers})
          const imgItem: any = {
            uid: Date.now(), // uid一定不能少，否则上传失敗
            name: file.key,
            status: 'done',
            url: getResp.data.url, // url 是展示在页面上的绝对链接
          }
          //fileList.push(imgItem)
          setFileList([imgItem])
          onSuccess([imgItem])
          onChange?.(res.item.pk)
          setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
          console.log('Eroor: ', err)
          onError({err})
        }
      }
    // 文件下载
    const onDownload = (file: any) => {
        console.log('onDownload', file)
    }
    // 文件下载预览
    const onPreview = (file: any) => {
        console.log('onPreview', file)
        fetch(file.url).then((res) =>
            res.blob().then((blob) => {
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = file.name; // 下载名称
                a.click();
                window.URL.revokeObjectURL(url);
            })
        );
    }

    return (
        <div>
            <Upload
                name='file'
                fileList={fileList}
                maxCount={1}
                onChange={handleChange}
                action={(file: any) => {
                    return new Promise(async (resolve) => {
                      console.log('action',file)
                      file.key = generateMixed(32) + '.' + file.name.split('.')[1]
                      const resp: any = await getSignedUrl({key: file.key, action: 'upload'})
                      if (resp?.data) {
                        resolve(resp?.data)
                      }
                    })
                  }}
                customRequest={uploadImage}
                beforeUpload={beforeUpload}
                onDownload={onDownload}
                onPreview={onPreview}
                onRemove={onRemove}
            >
                <Button icon={<UploadOutlined rev={undefined} />}>Click to Upload</Button>
            </Upload>
        </div>
    )
})

export default UploadFileCompont
