import React, {useEffect, useState, useRef} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useNavigate, useLocation} from 'react-router-dom'
import {useAuth} from 'src/app/modules/auth'
import {getBuyerInfo, latterpaymentsGet} from 'src/app/modules/api'
import {getAddress, cartDataGet, cartShippingGroup, getCreditInfo} from 'src/app/modules/api'
import {parameterStoreGet} from 'src/app/modules/api'
import {Steps} from './components/Steps'
import './style.css'
import {formatNumber} from 'src/app/modules/utils'
import {useLayout} from 'src/_metronic/layout/core'
import {useFormik, FormikProvider, Form, useFormikContext, useField} from 'formik'

interface supplierCardList {
  [key: string]: any
}

export default function CartCheckout() {
  const {auth} = useAuth()
  const {setContentLoading} = useLayout()
  const [paymentAllow, setPaymentAllow] = useState(1)
  const paymentMethods = useRef<any>([])
  const [parameters, setParameters] = useState<any>([])
  const [addressList, setAddressList] = useState<any>([])
  const [falgIndex, setFalgIndex] = useState<any>(0)
  const navigate = useNavigate()

  const [pageGetData, setPageGetData] = useState<any>({card: '', text: ''})
  const [address, setAddress] = useState<any>({address: ''})
  const [cartList, setCartList] = useState<any>([])
  const [totalData, setTotalData] = useState()
  const [cardList, setCardList] = useState<supplierCardList>({})
  let location = useLocation()
  const qramesObj: any = location.state ? location.state : null

  console.log('checkout qramesObj', qramesObj)

  const formik = useFormik({
    initialValues: qramesObj || {
      supplier_items: [],
      _parameters: {},
      _addressList: [],
      _cartList: [],
      _totalData: {},
      _cardList: {},
    },
    onSubmit: (values) => {
      console.log('values', values)
      navigate('/buy/checkout/confirm', {state: {...values}, replace: true})
    },
    enableReinitialize: true,
  })

  const getParameter = async () => {
    const res: any = await parameterStoreGet(
      {names: ['/Define/General/Prefecture', '/Define/Product/Units']},
      {}
    )
    for (const key in res) {
      // JSON.parseしてエラーだったらそのまま
      try {
        res[key] = JSON.parse(res[key])
      } catch (error) {
        res[key] = res[key]
      }
    }
    // console.log('getParameter', res)
    setParameters(res)
    // formik.setValues({...formik.values, _parameters: res})
  }

  const getAddressData = async () => {
    const res: any = await getAddress({})
    console.log('getAddressData', res)
    setAddressList(() => res?.rows)
    initCartData(res?.rows, 0)
    if (res?.rows && res?.rows.length) {
      const idx = res.rows.findIndex((k: any) => k.default == 'true')
      const falgIdx = Number(sessionStorage.getItem('falgIndex'))
      if (falgIdx >= 0) {
        setAddress({address: res.rows[falgIdx]})
        setFalgIndex(falgIdx)
      } else if (idx > -1) {
        setAddress({address: res.rows[idx]})
        setFalgIndex(idx)
      } else {
        setAddress({address: res.rows[0]})
      }
    }
    if (sessionStorage.getItem('productListMessage')) {
      let str: any = sessionStorage.getItem('productListMessage')
      let obj: any = JSON.parse(str)
      setPageGetData({...pageGetData, text: obj.text})
    }
  }

  const initCartData = (list: any, index: number) => {
    cartDataGet({shippingPk: list[index]?.pk}).then(async (res: any) => {
      const cartDataJson = sessionStorage.getItem('cartData')
      let list = []
      if (cartDataJson) {
        list = JSON.parse(cartDataJson)?.list
      }
      // console.log('list', list)
      let index = 0
      for (let e of res.rows) {
        let index1 = 0
        // console.log('list[index]?.shipping', list[index]?.shipping, index)
        for (const k of e?.shipping) {
          if (list[index]?.shipping[index1]?.desired_delivery_date) {
            k.desired_delivery_date = list[index]?.shipping[index1]?.desired_delivery_date
          } else {
            k.desired_delivery_date = ''
          }
          if (list[index]?.shipping[index1]?.desired_delivery_time) {
            k.desired_delivery_time = list[index]?.shipping[index1]?.desired_delivery_time
          } else {
            k.desired_delivery_time = ''
          }
          if (k.delivery_setting_time_specification == 'enabled') {
            k.delivery_setting_time_option = JSON.parse(k.delivery_setting_time_option)
          }
          const options = await cartShippingGroup({
            delivery_master_id: k.id,
            address_prefecture: list[index]?.address_prefecture,
          })
          // console.log('options', options)
          k.shippingGroup = options
          index1++
        }
        index++
      }
      // console.log('cartDataGet', res.rows)
      setCartList(res.rows)
      setTotalData(res.total)
    })
  }

  useEffect(() => {
    initData()
  }, [])

  useEffect(() => {
    if (paymentAllow) {
      if (paymentAllow == 1) {
        paymentMethods.current = [
          {id: 'later_payment', label: '後Pay'},
          {id: 'credit_card', label: 'クレジットカード'},
        ]
      } else {
        paymentMethods.current = [{id: 'credit_card', label: 'クレジットカード'}]
      }
    }
  }, [paymentAllow])

  const initData = async () => {
    setContentLoading(true)

    if (!qramesObj) {
      await getAddressData()
      await getParameter()
      await getBuyerInfo({}).then((res: any) => {
        // console.log(res.pk)
        latterpaymentsGet('', {}).then((response: any) => {
          if (response.latterPayments) {
            setPaymentAllow(response.latterPayments.payment_allow)
          }
        })
      })
    }

    setContentLoading(false)
  }

  useEffect(() => {
    // console.log('supplierItems', supplierItems)
    if (addressList && parameters && cartList && totalData && cardList) {
      // supplier_itemsのaddressを_addressList: default === trueを初期値にする。ない場合は最初の値を初期値にする
      let address = addressList[0]
      for (const k of addressList) {
        if (k.default == 'true') {
          address = k
          break
        }
      }

      // cardSeqはdefaultFlagがtrueのものを初期値にする。ない場合は最初の値を初期値にする
      let cardSeqList: any = {}
      for (const k in cardList) {
        for (const v of cardList[k]) {
          if (v.defaultFlag == 'true') {
            cardSeqList[k] = v.cardSeq
            break
          }
        }
        if (!cardSeqList[k]) {
          cardSeqList[k] = cardList[k][0].cardSeq
        }
      }

      const newCartList = cartList.map((item: any) => {
        let shipping = item.shipping.map((shippingItem: any) => {
          return {
            ...shippingItem,
            desired_delivery_date: '',
            desired_delivery_time: '',
          }
        })

        return {
          ...item,
          address: address.pk,
          shipping,
          payment_method: 'later_payment',
          cardSeq: cardSeqList[item.id],
          note: '',
        }
      })

      formik.setValues({
        supplier_items: newCartList,
        _addressList: addressList,
        _cartList: cartList,
        _totalData: totalData,
        _parameters: parameters,
        _cardList: cardList,
      })
    }
  }, [addressList, cartList, totalData, parameters, cardList])

  useEffect(() => {
    console.log('formik.values', formik.values)
  }, [formik.values])

  // cartListの[].idを使ってカードを取得
  // 取得したカード情報を{ item.id: res }の形で格納
  useEffect(() => {
    if (cartList && cartList.length > 0) {
      cartList.map((item: any) => {
        getCreditInfo({
          type: 0,
          id: item.id,
        }).then((res: any) => {
          setCardList((prev: any) => {
            return {...prev, [item.id]: res.data}
          })
        })
      })
    }
  }, [cartList])

  return (
    <FormikProvider value={formik}>
      <Form>
        <Steps StepNo={2} />
        {/* {supplierItems &&
          formik.values &&
          formik.values.supplier_items &&
          Object.keys(formik.values.supplier_items).map((supplier_id: string, index: number) => (
            <SectionSuppliers key={index} supplier_id={supplier_id} />
          ))} */}
        {formik.values &&
          formik.values.supplier_items.length > 0 &&
          formik.values.supplier_items.map((supplier_data: any, index: number) => (
            <SectionSuppliers
              key={index}
              supplier_data={supplier_data}
              index={index}
              payment_methods={paymentMethods.current}
            />
          ))}
        <div className='row'>
          <div className='mt-4 d-flex w-100 justify-content-between'>
            <button
              className='btn btn-white'
              style={{border: '1px solid'}}
              onClick={() => {
                // navigate('/buy/cart', {replace: true})
              }}
            >
              カートに戻る
            </button>

            <button
              className='btn btn-dark d-flex align-items-center justify-content-center'
              style={{border: '1px solid'}}
              onClick={() => {
                // goToOrderDetail()
              }}
            >
              お支払いへ進む
              <KTSVG
                path='/media/icons/duotune/arrows/arr001.svg'
                className='svg-icon-muted svg-icon-fs-2qx ms-2'
              />
            </button>
          </div>
        </div>
      </Form>
    </FormikProvider>
  )
}

// 加盟店ごとの商品・決済方法選択・配送方法選択を表示するコンポーネント
interface SectionSuppliersProps {
  supplier_data: any
  index: number
  payment_methods: {id: string; label: string}[]
}

const SectionSuppliers: React.FC<SectionSuppliersProps> = ({
  supplier_data,
  index,
  payment_methods,
}) => {
  const formik: any = useFormikContext()

  useEffect(() => {
    console.log('SectionSuppliers', formik.values)
    // console.log('SectionSuppliers.supplier_id', supplier_id)
    // console.log('SectionSuppliers.supplier_id', formik.values[supplier_id])
  }, [])

  return (
    <>
      <div className='card border mb-6'>
        <div className='card-header bg-light'>
          <div className='card-title'>
            <h3 className='fs-6'>{supplier_data.name}</h3>
          </div>
        </div>
        <div className='card-body'>
          <div className='row mb-4'>
            <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
              <span className='fw-bold'>配送先</span>
            </div>
            <div className='col-12 col-lg-8 d-flex align-items-center'>
              <select
                className='form-select form-select-sm w-auto mb-2 mw-100'
                // value={shippingItem.desired_delivery_date}
                value={formik.values.supplier_items[index].address}
                onChange={(e) => {
                  console.log('desired_delivery_date', e.target.value)
                  formik.setFieldValue(`supplier_items.${index}.address`, e.target.value)
                  // shippingItem.desired_delivery_date = e.target.value
                  // setCartList([...cartList])
                }}
              >
                {/* <option value=''>指定しない</option> */}
                {/* {shippingItem.shippingGroup?.map((time: any, timeIdx: any) => ( */}
                {formik.values._addressList &&
                  formik.values._addressList?.map((address: any, addressIdx: any) => (
                    <option key={addressIdx} value={address.pk}>
                      {address.lastname + address.firstname}
                      {address.address_postal_code}
                      {formik.values._parameters['/Define/General/Prefecture']?.find(
                        (k: any) => k.value == address.address_prefecture
                      )?.label +
                        address.address_city +
                        address.address_street +
                        address.address_building_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {formik.values['supplier_items'][index]['shipping'] &&
            // Object.keys(formik.values['supplier_items'][index]['shipping']).map(
            formik.values['supplier_items'][index]['shipping'].map(
              (shipping_data: any, listIdx: number) => (
                <div className='row mb-2'>
                  <div
                    className='col-12 bg-light px-4 mb-4'
                    data-bs-toggle='collapse'
                    data-bs-target='#collapseExample'
                    aria-expanded='false'
                    aria-controls='collapseExample'
                  >
                    <span className='fw-bold'>
                      商品内訳（配送方法：
                      {shipping_data.name}）
                    </span>
                  </div>
                  <div className='col-12 px-4'>
                    {shipping_data.list.map((listItem: any, listIdx: number) => (
                      <div key={listIdx} className='row mb-2 border-bottom border-bottom-lg-0'>
                        <div className='col-12 col-lg-5 border-bottom-lg'>
                          {listItem.product_name} - {listItem.variation_name}
                        </div>
                        <div className='col-12 col-lg-5 border-bottom-lg'>
                          単位：
                          {
                            formik.values._parameters['/Define/Product/Units']?.find(
                              (k: any) => k.id == listItem.unit
                            )?.label
                          }{' '}
                          x {listItem.quantity}
                        </div>
                        <div className='col-12 col-lg-2 border-bottom-lg text-end'>
                          ￥{formatNumber(listItem.calcPrice)}
                        </div>
                      </div>
                    ))}
                    <div className='row mb-2'>
                      <div className='col-12 col-lg-5 border-bottom-lg'>送料</div>
                      <div className='col-12 col-lg-5 border-bottom-lg'></div>
                      <div className='col-12 col-lg-2 border-bottom-lg text-end'>
                        ￥ {formatNumber(shipping_data.shippingPrice)}
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
                        <span className='fw-bold'>お届け希望日</span>
                      </div>
                      <div className='col-12 col-lg-8 d-flex align-items-center'>
                        <select
                          className='form-select form-select-sm w-auto mb-2'
                          value={shipping_data.desired_delivery_date}
                          onChange={(e) => {
                            // console.log('desired_delivery_date', e.target.value)
                            // shippingItem.desired_delivery_date = e.target.value
                            // setCartList([...cartList])
                            formik.setFieldValue(
                              `supplier_items.${index}.shipping.${listIdx}.desired_delivery_date`,
                              e.target.value
                            )
                          }}
                        >
                          <option value=''>指定しない</option>
                          {shipping_data.shippingGroup?.map((time: any, timeIdx: any) => (
                            <option key={timeIdx} value={time}>
                              {time}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
                        <span className='fw-bold'>お届け希望時間</span>
                      </div>
                      <div className='col-12 col-lg-8 d-flex align-items-center'>
                        <select
                          className='form-select form-select-sm w-auto mb-2'
                          value={shipping_data.desired_delivery_time}
                          onChange={(e) => {
                            // console.log('desired_delivery_time', e.target.value)
                            // shipping_data.desired_delivery_time = e.target.value
                            // setCartList([...cartList])
                            formik.setFieldValue(
                              `supplier_items.${index}.shipping.${listIdx}.desired_delivery_time`,
                              e.target.value
                            )
                          }}
                        >
                          {shipping_data.delivery_setting_time_specification == 'enabled' ? (
                            shipping_data.delivery_setting_time_option?.map((optionItem: any) => (
                              <option key={optionItem.id} value={optionItem.option}>
                                {optionItem.option}
                              </option>
                            ))
                          ) : (
                            <option value=''>指定しない</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                // ))}
              )
            )}

          <div className='row mb-2 border-bottom border-bottom-lg-0 mt-4'>
            <div className='col-12 col-lg-5 border-bottom-lg'>商品計</div>
            <div className='col-12 col-lg-5 border-bottom-lg'></div>
            <div className='col-12 col-lg-2 border-bottom-lg text-end fs-6 fw-bold'>
              ￥{formatNumber(formik.values.supplier_items[index].supplierPrice)}
            </div>
          </div>

          <div className='row mb-2 border-bottom border-bottom-lg-0 mt-4'>
            <div className='col-12 col-lg-5 border-bottom-lg'>送料合計</div>
            <div className='col-12 col-lg-5 border-bottom-lg'></div>
            <div className='col-12 col-lg-2 border-bottom-lg text-end fs-6 fw-bold'>
              ￥{formatNumber(formik.values.supplier_items[index].shippingPrice)}
            </div>
          </div>

          <div className='row mb-2 border-bottom border-bottom-lg-0 mt-4'>
            <div className='col-12 col-lg-5 border-bottom-lg'>総合計</div>
            <div className='col-12 col-lg-5 border-bottom-lg'></div>
            <div className='col-12 col-lg-2 border-bottom-lg text-end fs-6 fw-bold'>
              ￥
              {formatNumber(
                formik.values.supplier_items[index].supplierPrice +
                  formik.values.supplier_items[index].shippingPrice
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
              <span className='fw-bold'>支払方法</span>
            </div>
            <div className='col-12 col-lg-8 d-flex align-items-center'>
              <CustomRadioGroup
                label=''
                name={`supplier_items.${index}.payment_method`}
                options={payment_methods}
              />
            </div>
          </div>
          {formik.values['supplier_items'][index].payment_method &&
            formik.values['supplier_items'][index].payment_method === 'credit_card' && (
              <div className='row'>
                <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
                  <span className='fw-bold'>クレジットカード</span>
                </div>
                <div className='col-12 col-lg-8 d-flex align-items-center'>
                  <select
                    className='form-select form-select-sm w-auto mw-100 mb-2'
                    value={formik.values['supplier_items'][index].cardSeq}
                    onChange={(e) => {
                      // console.log('desired_delivery_date', e.target.value)
                      formik.setFieldValue(`supplier_items.${index}.cardSeq`, e.target.value)
                    }}
                  >
                    <option value=''>利用するカードを選択</option>
                    {formik.values._cardList[supplier_data.id].map(
                      (cardItem: any, cardIdx: any) => (
                        <option key={cardIdx} value={cardItem.cardSeq}>
                          {cardItem.holderName}
                          {cardItem.cardNo}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            )}
          <div className='row'>
            <div className='col-12 col-lg-4 bg-light p-2 d-flex align-items-center mb-2 mb-lg-0'>
              <span className='fw-bold'>配送に関する備考</span>
            </div>
            <div className='col align-self-center'>
              <textarea
                className='form-control'
                rows={5}
                value={formik.values['supplier_items'][index].note}
                onChange={(e) => {
                  formik.setFieldValue(`supplier_items.${index}.note`, e.target.value)
                }}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

interface RadioGroupProps {
  label: string
  name: string
  options: {id: string; label: string}[]
}

const CustomRadioGroup: React.FC<RadioGroupProps> = ({label, name, options}) => {
  const [field, meta] = useField(name)
  const {setFieldValue} = useFormikContext()

  return (
    <div className='row'>
      <div className='col-12 align-self-center'>
        {options.map((option) => (
          <div className='form-check form-check-inline pe-3' key={option.id}>
            <input
              className='form-check-input'
              type='radio'
              {...field}
              value={option.id}
              checked={field.value === option.id}
              onChange={() => setFieldValue(name, option.id)}
            />
            <label className='form-check-label'>{option.label}</label>
          </div>
        ))}
        {meta.touched && meta.error ? (
          <div className='col-12 fv-plugins-message-container'>
            <div className='fv-help-block'>{meta.error}</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

interface CustomSelectOption {
  value: string
  label: string
}

interface CustomSelectProps {
  label: string
  name: string
  options: CustomSelectOption[]
  [key: string]: any
}

const CustomSelect: React.FC<CustomSelectProps> = ({label, options, ...props}) => {
  const [field, meta] = useField(props)

  return (
    <div className='row'>
      <div className='col-12 align-self-center'>
        <select className='form-select' {...field} {...props}>
          <option value=''>選択してください</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {meta.touched && meta.error ? (
        <div className='col-12 fv-plugins-message-container'>
          <div className='fv-help-block'>{meta.error}</div>
        </div>
      ) : null}
    </div>
  )
}
