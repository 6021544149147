import axios from 'axios'
import JSZip from 'jszip'
import {saveAs} from 'file-saver'
import {getSignedUrl} from 'src/app/modules/api'

// 校验endpoint 转换属性
const getValidationflg = (convertBeforeStr: any, convertAfterStr: any, convertStr: any) => {
  if (!convertBeforeStr.includes('{/') && !convertBeforeStr.includes('}')) {
    if (convertBeforeStr === convertStr) {
      return true
    } else {
      return false
    }
  }
  let reg

  if (convertBeforeStr.includes('{/d')) {
    reg = new RegExp(
      convertBeforeStr.replace(new RegExp('{/d', 'g'), '(\\d').replace(new RegExp('/}', 'g'), ')')
    )
  } else {
    reg = new RegExp(
      convertBeforeStr.replace(new RegExp('{/', 'g'), '(').replace(new RegExp('/}', 'g'), ')')
    )
  }
  if (!reg.test(convertStr)) {
    return false
  }
  return true
}

// 正则拼接
const funcRules = (arr: any) => {
  if (arr.length === 0) {
    return ''
  }
  let res = ''
  res = '^(' + arr.join('|') + ')*$'
  return res
}

// 获取字节长度
const wordLength = (val: any) => {
  var totalLength = 0
  var i
  var charCode
  for (i = 0; i < val.length; i++) {
    charCode = val.charCodeAt(i)
    if (charCode < 0x007f) {
      totalLength = totalLength + 1
    } else if (0x0080 <= charCode && charCode <= 0x07ff) {
      totalLength += 2
    } else if (0x0800 <= charCode && charCode <= 0xffff) {
      totalLength += 3
    }
  }
  return totalLength
}

// 生成32位随机数
const generateMixed = (n: any) => {
  var chars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ]
  let num = ''
  for (var i = 0; i < n; i++) {
    var id = Math.ceil(Math.random() * 61)
    num += chars[id]
  }
  return num
}

const downloadFiles = async (obj: any) => {
  try {
    const decodedData = atob(obj.data)

    // 将解码后的数据转为 JSON 格式
    //const jsonData = JSON.parse(decodedData)
    const jsonData = JSON.parse(decodeURIComponent(escape(decodedData)))
    // 创建 CSV 文件格式的字符串
    let csv = ''
    const headerTranslation = obj.tableHeader
    // {
    //   customer_id: '顧客',
    //   company_name: '顧客名',
    //   group_id: '会員ランク',
    //   contact_person: '担当者',
    //   telephone_number: '電話番号',
    //   main_email_address: 'メールアドレス',
    //   status: '承認状況',
    // }
    const keys = Object.keys(headerTranslation)
    const header = Object.values(headerTranslation).join(',') + '\n'
    csv += header
    for (let data of jsonData) {
      let arr = keys.map((item) => {
        return data[item]
      })
      const row =
        // [
        //   data.customer_id,
        //   data.company_name,
        //   data.group_id,
        //   data.contact_person,
        //   data.telephone_number,
        //   data.main_email_address,
        //   data.status,
        // ]
        arr.join(',') + '\n'
      csv += row
    }

    // 创建 <a> 标签，进行文件下载
    const link = document.createElement('a')
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv))
    link.setAttribute('download', obj.name + '.csv')
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    //setIsLoading(false)
    console.error('error', error)
  }
}
// 防抖
// const debounce = (fn:any, delay:any) => {
//   let time:any = null;//time用来控制事件的触发
//   return function () {
//       if (time !== null) {
//           clearTimeout(time);
//       }
//       time = setTimeout(() => {
//           fn.call(this);
//           //利用call(),让this的指针从指向window 转成指向input
//       }, delay)
//   }
// }

export function formatFileSize(fileSizeBytes: number) {
  if (fileSizeBytes === 0) return '--'
  if (fileSizeBytes < 1024) {
    return fileSizeBytes + ' bytes'
  } else if (fileSizeBytes < 1024 * 1024) {
    return (fileSizeBytes / 1024).toFixed(2) + ' KB'
  } else if (fileSizeBytes < 1024 * 1024 * 1024) {
    return (fileSizeBytes / (1024 * 1024)).toFixed(2) + ' MB'
  }
  return (fileSizeBytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB'
}

export const isValidFileSize = (file?: File, maxSize = 2) => {
  if (!file || file.size / (1024 * 1024) > maxSize) {
    return false
  }
  return true
}

export const isValidFileType = (file?: File, accept: string = '') => {
  const types = accept.split(',')
  if (accept && !types.find((e) => file?.type.includes(e.split('.')[1] || e.split('.')[0]))) {
    return false
  }
  return true
}

export const isValidFileName = (file?: File) => {
  if (!file || file.name.length > 50) {
    return false
  }
  return true
}

const fetchFileFromS3 = async (url: string) => {
  try {
    const response = await axios.get(url, {responseType: 'blob', headers: {Authorization: ''}})
    return response.data
  } catch (error) {
    throw error
  }
}

export const replaceSlashInFolderName = (name: string) => {
  return name.replace(/\//g, '')
}

type Structure = {[key: string]: string | Structure}

const mapUrlsToFolderStructure = (urls: string[]) => {
  const structure: Structure = {}
  urls.forEach((url) => {
    const parts = url.split('/')
    let current = structure
    parts.shift()
    while (parts.length > 0) {
      const part = parts.shift() as string
      if (parts.length > 0) {
        current[part] = current[part] || {}
        current = current[part] as Structure
      } else {
        current[part] = current[part] || {}
        current[part] = url
      }
    }
  })
  return structure
}

export const downloadFile = async (url: string, filename: string) => {
  const fileContent = await fetchFileFromS3(url)
  saveAs(fileContent, filename)
}

export const createZipWithFolderStructure = async (
  folders: string[],
  name: string,
  userId: string
) => {
  const struc = mapUrlsToFolderStructure(folders)
  const zip = new JSZip()
  const addFilesToZip = async (node: Structure, currentPath: string) => {
    for (const key in node) {
      const newPath = currentPath ? `${currentPath}/${key}` : key
      if (typeof node[key] === 'object') {
        zip.folder(newPath)
        await addFilesToZip(node[key] as Structure, newPath)
      } else {
        const url = await getSignedUrl({key: `${userId}/${newPath}`, action: 'get'}).then(
          ({data}: any) => data.url.split('?')[0]
        )
        const fileContent = await fetchFileFromS3(url)
        zip.file(newPath, fileContent)
      }
    }
  }
  await addFilesToZip(struc, '')
  const zipContent = await zip.generateAsync({type: 'blob'})
  saveAs(zipContent, `${name}.zip`)
}

export {
  getValidationflg,
  funcRules,
  wordLength,
  generateMixed,
  downloadFiles,
  // debounce
}
