import React from 'react';
import { FieldArray, useFormikContext } from 'formik';

interface Option {
  pk: string;
  name: string;
  children?: Option[];
}

interface ProductCustomFieldSelectorProps {
  options: Option[];
  updateData: (values: any) => void;
}

const ProductCustomFieldSelector: React.FC<ProductCustomFieldSelectorProps> = ({ options, updateData }) => {
  const formik = useFormikContext<any>();

  const handleSelectChange = (index: number, level: number, value: string, arrayHelpers: any) => {
    const newCategories = [...formik.values.categories];
    newCategories[index][level] = value;
    
    // Clear subsequent levels
    if (level < 2) newCategories[index].fill('', level + 1);

    formik.setFieldValue('categories', newCategories);
    // updateData(formik.values);
  };

  return (
    <div className='row mb-6'>
      <label className='col-lg-2 col-form-label fs-7'>
        <span>商品カテゴリ</span>
      </label>
      <div className='col-lg-10 align-self-center'>
        <FieldArray
          name="categories"
          render={(arrayHelpers) => (
            <>
              {formik.values.categories && formik.values.categories.map((item: any, index: number) => (
                <div className='row gx-0 pb-3' key={index}>
                  <div className='col-lg-10'>
                    <div className='d-flex gap-2'>
            
                      <select
                        className='form-select'
                        data-control='select2'
                        placeholder='選択してください'
                        value={item[0] || ''}
                        onChange={(e) => handleSelectChange(index, 0, e.target.value, arrayHelpers)}
                      >
                        <option value=''></option>
                        {options && options.map((opt) => (
                          <option key={opt.pk} value={opt.pk}>
                            {opt.name}
                          </option>
                        ))}
                      </select>

                      <select
                        className='form-select'
                        data-control='select2'
                        placeholder='選択してください'
                        value={item[1] || ''}
                        onChange={(e) => handleSelectChange(index, 1, e.target.value, arrayHelpers)}
                      >
                        <option value=''></option>
                        {options && options.find(opt => opt.pk === item[0])?.children?.map((child) => (
                          <option key={child.pk} value={child.pk}>
                            {child.name}
                          </option>
                        ))}
                      </select>

                      <select
                        className='form-select'
                        data-control='select2'
                        placeholder='選択してください'
                        value={item[2] || ''}
                        onChange={(e) => handleSelectChange(index, 2, e.target.value, arrayHelpers)}
                      >
                        <option value=''></option>
                        {options && options.find(opt => opt.pk === item[0])?.children
                          ?.find(child => child.pk === item[1])?.children?.map((grandchild) => (
                            <option key={grandchild.pk} value={grandchild.pk}>
                              {grandchild.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className='col-lg-2 d-flex justify-content-end'>
                    <div
                      onClick={() => {
                        if (formik.values.categories.length > 1) {
                          arrayHelpers.remove(index);
                          // updateData(formik.values);
                        }
                      }}
                      className='btn btn-danger'
                    >
                      削除
                    </div>
                  </div>
                </div>
              ))}
              <div style={{ textAlign: 'right' }}>
                <button
                  type='button'
                  className='btn btn-primary w-100px'
                  onClick={() => {
                    arrayHelpers.push(['', '', '']);
                    // updateData(formik.values);
                  }}
                >
                  ＋追加
                </button>
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default ProductCustomFieldSelector;
