/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '@/_metronic/helpers'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {
  getAddressList,
  getBuyerInfo,
  latterpaymentsGet,
  getUserSubscriptionInfo,
  accountInfoGet,
  productCategoryListGet,
  userSubscriptionInfoGet,
} from 'src/app/modules/api'
import {useAuth} from 'src/app/modules/auth'
import {formatNumber} from 'src/app/modules/utils'

let latterPayments: any = {}

export default function Header({type}: {type: 'buyer' | 'supplier'}) {
  const {auth} = useAuth()
  // const userdata = auth?.data
  // const role_group = JSON.parse(auth?.data?.role_group);
  const role_group = auth?.role
  const location = useLocation()
  const [loading, setLoading] = useState<any>(false)
  const [userdata, setUserdata] = useState<any>({
    application_date: '',
    referrer: '',
    customer_id: '',
    company_name: '',
    representative: '',
    department_name: '',
    contact_person: '',
    contact_person_kana: '',
    main_email_address: '',
    address_postal_code: '',
    telephone_number: '',
    mobile_phone_number: '',
    fax_number: '',
    payment_method: '',
    establishment_date: '',
    actual_industry: '',
    website_url: '',
    physical_store: '',
    email_notifications: '',
  }) // 表单数据

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }

  const initData = async () => {
    setLoading(true)
    const userSubscriptionInfo: any = await getUserSubscriptionInfo({}, headers)
    const objGet: any = await getBuyerInfo({}, headers)
    objGet.plan_name = userSubscriptionInfo.planName
    console.log('objGet', objGet)
    const response: any = await latterpaymentsGet(objGet.pk, headers)
    console.log(response, response)
    latterPayments = response.latterPayments

    setUserdata(objGet)
    setLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-0 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4 mt-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img
                src={
                  userdata.main_image
                    ? userdata.main_image
                    : toAbsoluteUrl('/media/avatars/blank.png')
                }
                alt={userdata.company_name}
              />
            </div>
          </div>

          <div className=''>
            <div className='justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex my-4' style={{display: 'flex', alignItems: 'baseline'}}>
                <div className='fs-2 fw-bolder'>
                  {userdata.company_name}(アカウントID: {userdata.display_id})
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-4 fw-bolder'>契約プラン</div>
                    </div>

                    <div className='fw-bold fs-6 '>{userdata.plan_name || 'フリープラン'}</div>
                  </div>

                  {type === 'buyer' && (
                    <>
                      {latterPayments?.payment_limit && latterPayments?.payment_allow == 1 && (
                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                          <div className='d-flex align-items-center'>
                            <div className='fs-4 fw-bolder'>後Pay利用枠</div>
                          </div>

                          <div className='fw-bold fs-6 ' style={{color: 'red'}}>
                            {formatNumber(latterPayments?.payment_limit)}円
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {type === 'buyer' && (
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-4'>店舗状態</div>
                      </div>

                      <div className='fw-bold fs-6 '>
                        {/* {resData.store_status == 'publish' ? '公開' : '非公開'} */}
                      </div>
                    </div>
                  )}
                  {type === 'buyer' && (
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-4'>取り扱いジャンル</div>
                      </div>

                      <div className='fw-bold fs-6 '>
                        {/* {categoryOptions?.find((k: any) => k.id == resData.main_genre)?.name} */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {type === 'buyer' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    'nav-link text-active-primary me-6 ' +
                    (location.pathname === '/account/buyer-account' && 'active')
                  }
                  to='/account/account'
                >
                  アカウント管理
                </Link>
              </li>
            )}
            {type === 'buyer' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    'nav-link text-active-primary me-6 ' +
                    (location.pathname === '/account/buyer-organization' && 'active')
                  }
                  to='/account/buyer-organization'
                >
                  組織情報管理
                </Link>
              </li>
            )}
            {type === 'supplier' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/account/account' && 'active')
                  }
                  to='/account/account'
                >
                  アカウント情報
                </Link>
              </li>
            )}
            {type === 'supplier' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/account/organization' && 'active')
                  }
                  to='/account/organization'
                >
                  組織情報管理
                </Link>
              </li>
            )}
            <li className='nav-item flex-shrink-0 px-2'>
              <Link
                className={
                  'nav-link text-active-primary me-6 ' +
                  (location.pathname === '/account/organization/userlist' && 'active')
                }
                to='/account/organization/userlist'
              >
                ユーザー一覧
              </Link>
            </li>
            <li className='nav-item flex-shrink-0 px-2'>
              <Link
                className={
                  'nav-link text-active-primary me-6 ' +
                  (location.pathname === '/account/organization/invitation' && 'active')
                }
                to='/account/organization/invitation'
              >
                新規ユーザー登録
              </Link>
            </li>
            {type === 'buyer' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    'nav-link text-active-primary me-6 ' +
                    (location.pathname === '/account/buyer-shipping' && 'active')
                  }
                  to='/account/buyer-shipping'
                >
                  配送先情報
                </Link>
              </li>
            )}
            {type === 'buyer' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    'nav-link text-active-primary me-6 ' +
                    (location.pathname === '/account/buyer-paymentPurchase' && 'active')
                  }
                  to='/account/buyer-paymentPurchase'
                >
                  仕入のお支払管理
                </Link>
              </li>
            )}
            {type === 'buyer' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    'nav-link text-active-primary me-6 ' +
                    (location.pathname === '/account/buyer-paymentUsage' && 'active')
                  }
                  to='/account/buyer-paymentUsage'
                >
                  利用料のお支払方法登録
                </Link>
              </li>
            )}
            {type === 'buyer' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/account/buyer-paymentLoginSet' && 'active')
                  }
                  to='/account/buyer-paymentLoginSet'
                >
                  その他共通設定
                </Link>
              </li>
            )}
            {type === 'buyer' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    'nav-link text-active-primary me-6 ' +
                    (location.pathname === '/account/buyer-report' && 'active')
                  }
                  to='/account/buyer-report'
                >
                  レポート
                </Link>
              </li>
            )}
            {type === 'supplier' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/account/law' && 'active')
                  }
                  to='/account/law'
                >
                  特定商取引法に基づく記載
                </Link>
              </li>
            )}
            {type === 'supplier' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/account/payment' && 'active')
                  }
                  to='/account/payment'
                >
                  利用料お支払管理
                </Link>
              </li>
            )}
            {type === 'supplier' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/account/paymentprovider' && 'active')
                  }
                  to='/account/paymentprovider'
                >
                  決済連携設定
                </Link>
              </li>
            )}
            {type === 'supplier' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/account/datatransfer' && 'active')
                  }
                  to='/account/datatransfer'
                >
                  外部自動連携設定
                </Link>
              </li>
            )}
            {type === 'supplier' && (
              <li className='nav-item flex-shrink-0 px-2'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/account/general' && 'active')
                  }
                  to='/account/general'
                >
                  その他共通設定
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}
