import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { KTCard, KTSVG } from 'src/_metronic/helpers'
import { showSuccessMessage } from 'src/app/components/sweetAlert'
import { Pagination } from 'src/app/components/pagination'
import { customerSearch, customerRankListGet, shippingDataListGet, shipbulkUpDatePost, parameterStoreGet } from 'src/app/modules/api'
import { useLayout } from 'src/_metronic/layout/core'

export default function Logistics() {
  const { isContentLoading, setContentLoading } = useLayout()
  const [queryParams, setQueryParams] = useState<any>({
    pageNum: 1,
    pageSize: 10,
    total: 0,
    name: "",
    status: ""
  }) // 分页参数
  const [tor, setTor] = useState<any>({}) // 条数
  const [oneKEy, setoneKEy] = useState<string>("awaiting") // 批量操作的值
  const [statusData, setstatusData] = useState<string>("") // 批量操作的值
  const [selectedList, setSelectList] = useState<any>([]) // 选中的项
  const [data, setData] = useState<any>([]) // 数据列表
  const [shippingStatusParameters, setShippingStatusParameters] = useState<any[]>([])

  const getParameterStore = async () => {
    try {
      const shippingStatusRes: any = await parameterStoreGet({ names: ['/Define/Shippingdetails/Status'] }, {})
      setShippingStatusParameters(JSON.parse(shippingStatusRes['/Define/Shippingdetails/Status']))
    } catch (error) {
      console.error('Failed to fetch parameter store', error)
    }
  }

  // 获取发货列表
  const getAllList = (page: any, pageSize: any, value?: any) => {
    console.log(value)
    let params = {
      pageNum: page,
      pageSize: pageSize,
      name: queryParams.name,
      status: value ? value : ''
    }

    setContentLoading(true)
    shippingDataListGet(params).then((res: any) => {
      console.log(res, "接口返回的数据")
      setQueryParams({ ...queryParams, total: res.total })
      let arr = res.rows.map((item: any) => {
        item.children = []
        item.id = item.pk
        shippingStatusParameters.map((v) => {
          console.log(v, "v");
          console.log(item.status, "item.status");
          if (item.status == v.value) {
            item.statusName = v.label
          }
        })

        return item
      })
      setTor(res.calcNum)
      console.log(arr, "获取列表数据1");
      console.log(res.calcNum, "获取条数总览");


      setContentLoading(false)
      setData(arr)
    })
  }

  // ステータス変更イベント
  const bulkUpDate = async (data1: any, pk: any) => {
    console.log(data1, "bulkUpDate");
    var pkListData: any = []
    pkListData.push(pk)

    const obj = {
      status: data1,
      pkList: pkListData
    }

    console.log(obj, "obj");

    let res: any = await shipbulkUpDatePost(obj, {})
    console.log(res, "shipbulkUpDatePost");
    showSuccessMessage(res.message)
    getAllList(queryParams.pageNum, queryParams.pageSize)
  }

  // 改变选中列表
  const changeSelectList = (obj: any) => {
    console.log(obj, "选中的数据");
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = []
        data.forEach((item: any) => {
          arr.push(item.id)
        })
        setSelectList(arr)
      } else {
        selectedList.push(obj.id)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([])
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id
        })
        selectedList.splice(idx, 1)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    }
    console.log(selectedList, "选中的项");
  }

  //一键操作按钮改变 事件
  const OnekeyFun = async (data2: any,) => {
    console.log(data2, "状态改变事件");
    setoneKEy(data2)
  }

  //批量操作
  const OnekeyCli = async () => {
    const obj = {
      status: oneKEy,
      pkList: selectedList
    }
    console.log(obj, "要传的参数");
    let res: any = await shipbulkUpDatePost(obj, {})
    console.log(res, "单个更新返回的数据");
    showSuccessMessage(res.message)
    getAllList(queryParams.pageNum, queryParams.pageSize)
  }
  const navigate = useNavigate()

  //検索
  const searchValues = async (value: any) => {
    console.log('searchValues99999999', value)
    queryParams.name = value.target.value
    getAllList(queryParams.pageNum, queryParams.pageSize)
  }

  //status検索
  const statussearchValues = async (value: string) => {
    // queryParams.status = value
    console.log(value, "点击传递过来的值");
    // setQueryParams( queryParams.status= value )
    setstatusData(value)
    console.log(statusData, "复制后得知");

    await getAllList(queryParams.pageNum, queryParams.pageSize, value)

  }

  useEffect(() => {
    const calcNum = { cacelNum: 0, overNum: 0, waitNum: 0 }
    setTor(calcNum);
    getAllList(queryParams.pageNum, queryParams.pageSize)
    getParameterStore()
  }, [])

  // 跳转到
  const goDetail = async (item: any) => {
    navigate(`/logistics/${item.display_id}`, {
      state: { pk: item.pk, type: 'detail', order_no: item.order_no, status: item.status },
    })
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className='d-flex justify-content-between align-item-center mb-6' style={{ alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <select className="form-select" data-control="select2"
            style={{ width: 250 }}
            placeholder='一括操作'
            onChange={(e) => {
              OnekeyFun(e.target.value)
            }}
          >
            {
              shippingStatusParameters.length !== 0 && shippingStatusParameters.map((item: any, index: number) => {
                return (
                  <option value={item.value} key={item.id}>{item.label}</option>
                )
              })
            }
          </select>
          <button type='button' className='btn btn-primary'
            style={{ marginLeft: 10 }}
            onClick={OnekeyCli}
          >
            適用
          </button>
        </div>

        <div className='d-flex align-items-center position-relative'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            style={{ marginRight: 10 }}
            className='form-control w-250px ps-14'
            placeholder='検索'
            value={queryParams.product_name}
            onChange={(e) => {
              queryParams.company_name = e.target.value
              setQueryParams({ ...queryParams, company_name: e.target.value })
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                console.log(e, "搜索框的值");
                searchValues(e)
              }
            }}
          />
        </div>
      </div>
      <KTCard resetSidePaddings={true}>
        <div className='table-responsive '>
          <p className='fw-normal'>
            <span
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                statussearchValues('awaiting')
              }}
            >{`出荷待ち（${tor.waitNum}件）`}</span>
            ｜
            <span
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                statussearchValues('shipped')
                console.log("点击的第二个");
              }}
            >{`出荷済み（${tor.overNum}件）`}</span>
            ｜
            <span
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                statussearchValues('cancelled')
              }}
            >{`キャンセル（${tor.cacelNum}件）`}</span>
          </p>
          <table
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead className='table-light'>
              <tr className='text-start fs-6 text-uppercase gs-0'>
                <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      data-kt-check-target='#kt_table_users .form-check-input'
                      checked={selectedList.length === data.length && selectedList.length !== 0}
                      onChange={(e) => {
                        let obj = {
                          id: 'all',
                          value: e.target.checked,
                        }
                        changeSelectList(obj)
                      }}
                    />
                  </div>
                </th>
                <th
                  id='orderName'
                  style={{ cursor: 'pointer', width: '140px' }}
                >
                  出荷管理番号
                </th>
                <th
                  id='customer_id'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                  }}
                >
                  送り状番号
                </th>

                <th
                  id='orderLevel'
                  style={{ cursor: 'pointer' }}
                >
                  配送希望日
                </th>

                <th
                  id='contact_person'
                  style={{ cursor: 'pointer', width: '150px' }}
                >
                  配送方法
                </th>
                <th
                  id='telephone_number'
                  style={{ cursor: 'pointer', width: '220px' }}
                >
                  配送先住所
                </th>
                <th
                  id='main_email_address'
                  style={{ cursor: 'pointer', width: '220px' }}
                  onClick={() => {
                  }}
                >
                  メールアドレス

                </th>
                <th
                  id='orderStatus'
                  style={{ cursor: 'pointer' }}

                >
                  受注番号
                </th>
                <th
                  id='orderStatus'
                  style={{ cursor: 'pointer', width: '140px' }}
                >
                  配送状況
                </th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data?.map((item: any, index: number) => {
                  return (
                    <tr key={item.pk}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check-target='#kt_table_users .form-check-input'
                            checked={selectedList.includes(item.id)}
                            onChange={(e) => {
                              let obj = {
                                id: item.id,
                                value: e.target.checked,
                              }
                              changeSelectList(obj)
                            }}
                          />
                        </div>
                      </td>
                      <td onClick={() => goDetail(item)} style={{ maxWidth: 160 }}>
                        <a style={{
                          textDecorationLine: 'underline', color: '#009ef7', cursor: 'pointer',
                        }}>{item.display_id || ''}</a></td>
                      <td>{item.tracking_id || ''}</td>
                      <td style={{ maxWidth: 120 }}>{item.desired_delivery_time || ''}</td>
                      <td>{item.delivery_method || ''}</td>
                      <td>{item.city || ''}</td>
                      <td>{item.company_name || ''}</td>
                      <td>{item.display_id2 || ''}</td>
                      <td>{item.statusName}</td>
                      <td style={{ minWidth: 120 }}>
                        <>
                          <select
                            className='form-select form-select-solid w-md-125px'
                            value={item.status}
                            onChange={(e) => {
                              bulkUpDate(e.target.value, item.pk)
                            }}
                          >
                            {shippingStatusParameters.map((v) => {
                              return (
                                <option key={v.value} value={v.value}>{v.label}</option>
                              )
                            })}
                          </select>
                        </>
                      </td>

                      <td style={{ width: 50 }}>

                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={17}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {isContentLoading ? 'データの取得中' : '該当するレコードがありません。'}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {/* 分页 */}
          {queryParams.total != 0 && (
            <Pagination
              currentPage={getAllList}
              page={queryParams.pageNum}
              pageSize={queryParams.pageSize}
              total={queryParams.total}
            />
          )}
        </div>
      </KTCard>
    </div>
  )
}


