import { useFormik } from 'formik'
import { Key, ReactChild, ReactFragment, ReactPortal, useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useLayout } from 'src/_metronic/layout/core'
import * as Yup from 'yup'
import {
  showErrorMessage,
  showWarningMessage,
  showSuccessMessage,
} from '@/app/components/sweetAlert'
import { notificationMethodGet, notificationMethodUpdate, parameterStoreGet } from 'src/app/modules/api';

const statusOptions = [
  { id: '0', label: '無効' },
  { id: '1', label: '有効' },
]
const validationSchema = Yup.object().shape({
  subject: Yup.string().required('件名必須です。'),
})

export default function NotificationDetail() {
  const { isContentLoading, setContentLoading } = useLayout()
  const navigate = useNavigate()
  const location = useLocation()
  const item = location.state?.item

  const [mailDefaultSupplierData, setMailDefaultSupplierData] = useState<any>()
  const [formData, setFormData] = useState<any>({
    status: '0',
    subject: '',
    message: '',
    method: '',
    target: '',
    id: '',
    ...item
  })
  
  const formik: any = useFormik<any>({
    initialValues: formData as any,
    validationSchema: validationSchema,
    onSubmit: async (values: any, { setSubmitting }: any) => {
      setContentLoading(true)
      console.log('values', values)
      setSubmitting(true)
      notificationMethodUpdate(values).then((res: any) => {
        showSuccessMessage(res.message)
        navigate('/notification')
        setSubmitting(false)
        setContentLoading(false)
      }).catch((err: any) => {
        console.log('err', err)
        setSubmitting(false)
        setContentLoading(false)
      })
    },
  })

  const updateData = (fieldsToUpdate: Partial<userFormDataType>) => {
    const updatedData = { ...formData, ...fieldsToUpdate }
    switch (updatedData?.id) {
      case 1:
        if( !updatedData?.subject ) updatedData.subject = mailDefaultSupplierData?.ORDER_COMPLETE.SUBJECT;
        if( !updatedData?.message ) updatedData.message = mailDefaultSupplierData?.ORDER_COMPLETE.MESSAGE;
        break
      case 2:
        if( !updatedData?.subject ) updatedData.subject = mailDefaultSupplierData?.ORDER_CANCEL.SUBJECT;
        if( !updatedData?.message ) updatedData.message = mailDefaultSupplierData?.ORDER_CANCEL.MESSAGE;
        break
      case 3:
        if( !updatedData?.subject ) updatedData.subject = mailDefaultSupplierData?.DELIVERY_READY.SUBJECT;
        if( !updatedData?.message ) updatedData.message = mailDefaultSupplierData?.DELIVERY_READY.MESSAGE;
        break
      case 4:
        if( !updatedData?.subject ) updatedData.subject = mailDefaultSupplierData?.DELIVERY_COMPLETE.SUBJECT;
        if( !updatedData?.message ) updatedData.message = mailDefaultSupplierData?.DELIVERY_COMPLETE.MESSAGE;
        break
      case 5:
        if( !updatedData?.subject ) updatedData.subject = mailDefaultSupplierData?.PRICE_UPDATE.SUBJECT;
        if( !updatedData?.message ) updatedData.message = mailDefaultSupplierData?.PRICE_UPDATE.MESSAGE;
        break
      case 6:
        if( !updatedData?.subject ) updatedData.subject = mailDefaultSupplierData?.SUPPLIER_APPROVAL_RESULT.SUBJECT;
        if( !updatedData?.message ) updatedData.message = mailDefaultSupplierData?.SUPPLIER_APPROVAL_RESULT.MESSAGE;
        break
    }
    formik.setValues(updatedData)
  }

  const getParameterStore = async () => {
    try {
      let data: any = await parameterStoreGet({ names: ['/Define/Mail/Supplier'] }, {})
      setMailDefaultSupplierData(JSON.parse(data['/Define/Mail/Supplier']))
    } catch (error) {
      console.error('Failed to fetch parameter store', error)
    }
  }

  const getNotificationMethod = async () => {
    const resp:any = await notificationMethodGet({})
    const obj = resp.find((k:any)=> k.id == item.id)
    console.log('obj', obj)
    updateData(obj)
  }

  useEffect(() => {
    if (item) {
      getNotificationMethod()
    }
  }, [mailDefaultSupplierData])

  useEffect(() => {
    if (item) {
      console.log('item', item)
      getParameterStore()
    }
  }, [])

  return (
    <div>
      <div className='container-fluid'>
        <form onSubmit={formik.handleSubmit}>
          {/* メールシステム設定 */}
          <div className='card mb-6 shadow-sm'>
            <div className='d-flex align-items-center card-header'>
              <span className='card-title fw-bold m-0'>メールシステム設定</span>
            </div>
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  {/* 自動返信設定 */}
                  <div className='row mb-2'>
                    <div className='col-lg-3 col-form-label'>
                      <label className='col-form-label'>自動返信設定</label>
                    </div>
                    <div className='col-lg-9 align-self-center'>
                      {statusOptions.map((item: any, index: number) => {
                        return (
                          <div
                            className='form-check form-check-inline pe-3'
                            key={index}
                          >
                            <input
                              className='form-check-input'
                              type='radio'
                              value={item.id}
                              checked={formik.values.status == item.id}
                              onChange={(e: any) => {
                                formik.values.status = e.target.value
                                updateData(formik.values)
                              }}
                            />
                            <label className='form-check-label'>{item.label}</label>
                          </div>
                        )
                      })}
                    </div>
                  </div>

                  {/* 送信元メールアドレス */}
                  <div className='row mb-6'>
                    <div className='col-lg-3 col-form-label'>
                      <label className='col-form-label required'>件名</label>
                    </div>
                    <div className='col-lg-9 align-self-center'>
                      <input
                        className='form-control'
                        type='text'
                        {...formik.getFieldProps('subject')}
                        value={formik.values.subject}
                      />
                    </div>
                    {formik.touched.subject && formik.errors.subject && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.subject}</div>
                      </div>
                    )}
                  </div>

                  {/* 備考（管理用メモ欄） */}
                  <div className='row mb-6'>
                    <div className='col-lg-3 col-form-label'>
                      <label className='col-form-label'>メール本文</label>
                    </div>
                    <div className='col-lg-9 align-self-center'>
                      <textarea
                        className='form-control'
                        rows={5}
                        {...formik.getFieldProps('message')}
                        value={formik.values.message}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center' }}>
          <button
              className='rounded mx-2 my-2 btn btn-primary'
              type='button'
              onClick={() => {
                navigate('/notification')
              }}
            >
              戻る
            </button>

            <button
              className={`rounded mx-2 my-2 btn ${formik.isSubmitting ? 'btn-light-primary' : 'btn-primary'}`}
              type='submit'
              data-kt-users-modal-action='submit'
              disabled={formik.isSubmitting}
            >
              更新
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
