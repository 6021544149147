import React, {DragEvent, Fragment, useCallback, useState} from 'react'
import {Button, Modal, Spin} from 'antd'
import {InboxOutlined} from '@ant-design/icons'
import {
  formatFileSize,
  isValidFileSize,
  isValidFileType,
  isValidFileName,
} from '@/utils'
import {DeleteOutlined, LoadingOutlined, CheckOutlined} from '@ant-design/icons'
import {TMedia} from '../types'

type Props = {
  onUpload?: (file: File) => Promise<void>
  open?: boolean
  onClose?: () => void
  accept?: string
  medias: TMedia[]
}

interface TFile {
  statusFile?: 'done' | 'uploading' | 'error'
  file: File
}

export default function UploafFile({onUpload, open, onClose, accept, medias}: Props) {
  const refInputFile = React.useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<TFile[]>([])
  const [loadingUpload, setLoadingUpload] = useState(false)

  const onRemove = (index: number) => {
    setFiles((pre) => pre.filter((_, i) => i !== index))
  }

  const onSubmit = async () => {
    setLoadingUpload(true)
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      if (
        !isValidFileSize(file.file) ||
        !isValidFileType(file.file) ||
        !isValidFileName(file.file) ||
        !file.file?.name.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~_]+$/)
      ) {
        continue
      }
      setFiles((pre) => pre.map((_, _i) => (_i === i ? {...file, statusFile: 'uploading'} : _)))
      await onUpload?.(file.file)
        .then(() => {
          setFiles((pre) => pre.map((_, _i) => (_i === i ? {...file, statusFile: 'done'} : _)))
        })
        .catch((e) => {
          setFiles((pre) => pre.map((_, _i) => (_i === i ? {...file, statusFile: 'error'} : _)))
        })
    }
    onCloseClick()
    setLoadingUpload(false)
  }

  const onCloseClick = () => {
    if (loadingUpload) return
    setFiles([])
    onClose?.()
    setLoadingUpload(false)
  }

  const onChangeFile = useCallback(
    async (fileList: FileList | null) => {
      if (!fileList) return
      if (fileList.length >= 10) {
        Modal.error({
          title: '最大同時アップロード数は10ファイルです',
          centered: true,
        })
        return
      }
      let list = []
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i]
        if (files.find((e) => e.file.name === file.name)) continue
        if (medias.find((e) => e.name === file.name && !e.is_folder)) {
          await new Promise((res) => {
            const nameshow = file.name.split('.') || []
            nameshow.pop()
            Modal.confirm({
              title: `${nameshow.join('')}は既存なので、上書きしても宜しいでしょうか。`,
              centered: true,
              onOk: () => {
                list.push({file})
                res(null)
              },
              onCancel: () => res(null),
            })
          })
        } else list.push({file: file})
      }
      setFiles([...files, ...list])
    },
    [files, medias]
  )

  const onPreventDefault = useCallback((event: DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  const onDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault()
      event.stopPropagation()
      onChangeFile(event.dataTransfer.files)
    },
    [onChangeFile]
  )

  return (
    <Modal
      centered
      title='ファイルアップロード'
      onCancel={onCloseClick}
      open={open}
      closable={!loadingUpload}
      closeIcon={!loadingUpload}
      footer={[
        <Button key='cancel' disabled={loadingUpload} onClick={onCloseClick}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={onSubmit}
          disabled={!files.length || loadingUpload || files.length >= 10}
        >
          OK
        </Button>,
      ]}
    >
      <div className='pt-4 card card-custom my-3 media-table-upload' style={{border: 'none'}}>
        <div className='card card-custom mb-3' style={{border: 'none'}}>
          <div
            className='pt-4 card card-custom my-3 media-table-upload'
            style={{
              width: '100%',
              border: '1px dashed #cacaca',
              borderRadius: 8,
              textAlign: 'center',
            }}
            onDrag={onPreventDefault}
            onDragOver={onPreventDefault}
            onDragEnter={onPreventDefault}
            onDrop={onDrop}
          >
            <div className='card card-custom mb-3' style={{width: '100%', border: 'none'}}>
              <span className='ant-upload-wrapper css-dev-only-do-not-override-5dw222'>
                <div
                  className='ant-upload ant-upload-drag css-dev-only-do-not-override-5dw222'
                  style={{width: '100%'}}
                  onClick={() => refInputFile.current?.click()}
                >
                  <span className='ant-upload ant-upload-btn' role='button'>
                    <input
                      onChange={async (e) => {
                        await onChangeFile(e.target.files)
                        e.target.value = ''
                      }}
                      ref={refInputFile}
                      type='file'
                      accept='.jpg,.jpeg,.png'
                      multiple
                      style={{display: 'none'}}
                    />
                    <div className='ant-upload-drag-container'>
                      <p className='ant-upload-drag-icon'>
                        <InboxOutlined style={{fontSize: 40, color: '#0061d5'}} rev={undefined} />
                      </p>
                      <p
                        className='ant-upload-text'
                        style={{paddingLeft: 8, paddingRight: 8, fontSize: 14, marginBottom: 8}}
                      >
                        ここにファイルをドロップするか、クリックしてアップロードします。
                      </p>
                      <p className='ant-upload-hint' style={{paddingLeft: 8, paddingRight: 8, marginBottom: 0, fontSize: 12, textAlign: 'left', color: '#666'}}>
                        ※ファイル形式：JPG、JPEG、PNG
                        <br />
                        ※ファイルサイズ：2MB以下
                        <br />
                        ※ファイル名：50文字以下、半角英数字記号のみ
                        <br />
                        ※最大同時アップロード数：10ファイル
                      </p>
                    </div>
                  </span>
                </div>
              </span>
            </div>
          </div>
          {files.map((e, i) => {
            const isValidSize = isValidFileSize(e.file)
            const isValidType = isValidFileType(e.file, accept)
            const isValidName = isValidFileName(e.file)
            const isValidName2 = !!e.file?.name.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~_]+$/)
            
            const isValid = isValidSize && isValidType && isValidName && isValidName2 && e.statusFile !== 'error'
            return (
              <Fragment key={`${e.file.name}_${i}`}>
                <div
                  style={{color: isValid ? '' : '#FF0000'}}
                  className='mt-4 d-flex align-items-center justify-content-between'
                >
                  <div className='d-flex align-items-center'>
                    <div
                      className=''
                      style={{
                        width: 340,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        paddingRight: 20,
                      }}
                    >
                      {e.statusFile === 'uploading' ? (
                        <Spin
                          className='me-3'
                          indicator={
                            <LoadingOutlined style={{fontSize: 16}} spin rev={undefined} />
                          }
                        />
                      ) : !e.statusFile ? (
                        <i
                          className='bi bi-file-earmark me-3'
                          style={{fontSize: 16, color: 'currentColor'}}
                        ></i>
                      ) : (
                        <CheckOutlined className='text-primary me-3' rev={undefined} />
                      )}
                      {e.file.name}
                    </div>
                    <div>{formatFileSize(e.file.size || 0)}</div>
                  </div>
                  {e.statusFile === 'uploading' ? null : (
                    <DeleteOutlined
                      onClick={() => onRemove(i)}
                      style={{cursor: 'pointer'}}
                      rev={undefined}
                    />
                  )}
                </div>
                {!isValidType || !isValidSize || !isValidName || !isValidName2 ? (
                  <div style={{fontSize: 12, color: '#ff0000'}}>
                    {!isValidSize
                      ? '2MB以下のファイルをアップロードしてください。'
                      : !isValidType
                      ? '有効なフォーマットでアップロードしてください。'
                      : !isValidName
                      ? '50文字以下のファイル名でアップロードしてください。'
                      : '半角英数字記号のみでアップロードしてください。'}
                  </div>
                ) : null}
              </Fragment>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
