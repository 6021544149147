import { useEffect, useState } from 'react';
import { KTSVG } from '@/_metronic/helpers';
import {useLocation, useParams, useSearchParams, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {showSuccessMessage} from '@/app/components/sweetAlert'
import { 
    shippingMasterInfoAdd,
    shippingMasterInfoGet,
    shippingMasterInfoUpdate,
    shippingMasterCopy,
    supplierRegionListAll,
    taxSettingDetailsListAll
 } from 'src/app/modules/api'

import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/en_US'
import { TimePicker } from 'antd';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import { useAuth } from "src/app/modules/auth"
import { parameterStoreGet, taxSettingGet} from 'src/app/modules/api'
import { useLayout } from 'src/_metronic/layout/core'
import { json } from 'stream/consumers';

const format = 'HH:mm';

const validationSchema = Yup.object().shape({
    shippingMasterName: Yup.string().required('配送マスタ名は必須です。'),
    region: Yup.string().required('リージョンは必須です。'),
    memo: Yup.string().required('社内メモは必須です。'),
    temperature_control_zones: Yup.string().required('温度帯は必須です。'),
    delivery_type: Yup.string().required('配送種別は必須です。'),
  })
const LeadTimeSettings: SubscribeType[] = [
    { id: 'disabled', name: '無効' },
    { id: 'enabled', name: '有効' },
]

export default function DeliveryAddressDetail({ onSubmit }: any) {

    const navigate = useNavigate()
    const { id, copy } = useParams()
    const [flg,setflg] = useState<boolean>(true);
    const {setContentLoading} = useLayout()

    const currentLocation = useLocation()
    const [getSearchArr] = useSearchParams()
    const [isEdit, setIsEdit] = useState<any>('')
    const [isCopy, setIsCopy] = useState<any>('')
    const [areaList, setAreaList] = useState<any>([])
    // const [taxation, setTaxation] = useState<any>([])
    const [parameters, setParameters] = useState<any>([])
    const [taxOptions, setTaxOptions] = useState<any>([])
    let searchValue = getSearchArr.getAll('copy')[0]

    const [formData, setFormData] = useState<any>({
        shippingMasterName: '',
        region: '',
        memo: '',
        temperature_control_zones: '1',
        delivery_type: '1',
        career: 'kuronekoyamato',
        serviceName: '1',

        delivery_setting_status: 'disabled',
        deliveryDay: '',
        getOrderTime: '',
        holidayJudgment: '1',

        regionSettingTime: [
            { id: '1', region: '', leadTime: '' },
            { id: '2', region: '', leadTime: '' }
        ],
        dateSelectionSettings: '',
        timeSetting: '1',
        selectTimeOptions: [
            { id: '1', value: '指定なし' },
            { id: '2', value: '午前中（8時～12時）' },
            { id: '3', value: '12時～14時' },
            { id: '4', value: '14時～16時' },
            { id: '5', value: '16時～18時' },
            { id: '6', value: '18時～20時' },
            { id: '7', value: '' },
            { id: '8', value: '' },
        ],

        shoppingSettings: '1',
        shoppingFee: '',
        freeShoppingStatus: '',
        freeShoppingType: '1',
        freeShoppingValue: '',
        freeShippingExcludedAreas: '',
        freeShippingExcludedAreasValue: [{prefecture_id: ''}],
        shippingTypeSetting: '1',
        individualShippingSettings: '',
        islands: [
            // { name: '北海道', value: '' },
            // { name: '石川県', value: '' },
            // { name: '岡山県', value: '' },
            // { name: '青森県', value: '' },
            // { name: '福井県', value: '' },
            // { name: '広島県', value: '' },
            // { name: '岩手県', value: '' },
            // { name: '山梨県', value: '' },
            // { name: '山口県', value: '' },
            // { name: '宮城県', value: '' },
            // { name: '長野県', value: '' },
            // { name: '徳島県', value: '' },
            // { name: '秋田県', value: '' },
            // { name: '岐阜県', value: '' },
            // { name: '香川県', value: '' },
            // { name: '山形県', value: '' },
            // { name: '静岡県', value: '' },
            // { name: '愛媛県', value: '' },
            // { name: '福島県', value: '' },
            // { name: '愛知県', value: '' },
            // { name: '高知県', value: '' },
            // { name: '茨城県', value: '' },
            // { name: '三重県', value: '' },
            // { name: '福岡県', value: '' },
            // { name: '栃木県', value: '' },
            // { name: '滋賀県', value: '' },
            // { name: '佐賀県', value: '' },
            // { name: '群馬県', value: '' },
            // { name: '京都府', value: '' },
            // { name: '長崎県', value: '' },
            // { name: '埼玉県', value: '' },
            // { name: '大阪府', value: '' },
            // { name: '熊本県', value: '' },
            // { name: '千葉県', value: '' },
            // { name: '兵庫県', value: '' },
            // { name: '大分県', value: '' },
            // { name: '東京県', value: '' },
            // { name: '奈良県', value: '' },
            // { name: '宮崎県', value: '' },
            // { name: '神奈川県', value: '' },
            // { name: '和歌山県', value: '' },
            // { name: '鹿児島県', value: '' },
            // { name: '新潟県', value: '' },
            // { name: '鳥取県', value: '' },
            // { name: '沖縄県', value: '' },
            // { name: '富山県', value: '' },
            // { name: '島根県', value: '' },
            // { name: '離島', value: '' },
        ],
        totalAmount: [
            { totalAmountMin: '', totalAmountMax: '', money: '' },
        ],
        weight: [
            { weightMin: '', weightMax: '', money: '' },
        ],

        cashSeliverySetting: '1',
        taxFee: [
            { amount: '', fees: '' },
        ]
    }); // 表单数据
    //将接口返回的数据转化为页面数据
    const dataChangeReverse = (values: any)=>{
        let obj = JSON.parse(JSON.stringify(values))

        let regionSettingTime = obj.delivery_setting_regional_lead_time && JSON.parse(obj.delivery_setting_regional_lead_time)?.map((item: any) => {
          return {
            region: item.prefecture,
            leadTime: item.lead_time,
          }
        })
        let selectTimeOptions = obj.delivery_setting_time_option && JSON.parse(obj.delivery_setting_time_option)?.map((item: any) => {
          return {
              id: item.id,
              value: item.option,
          }
        })
        let totalAmount = obj.shipping_setting_amount_variation && JSON.parse(obj.shipping_setting_amount_variation)?.map((item: any) => {
          return {
            totalAmountMin: item.minimum,
            totalAmountMax: item.maximum,
            money: item.shipping_fee,
          }
        })
        let weight = obj.shipping_setting_size_variation && JSON.parse(obj.shipping_setting_size_variation)?.map((item: any) => {
          return {
            weightMin : item.minimum,
            weightMax : item.maximum,
            money : item.shipping_fee,
          }
        })
       
        let taxFee = obj.cash_on_delivery_setting_fee && JSON.parse(obj.cash_on_delivery_setting_fee)?.map((item: any) => {
          return {
            amount : item.order_amount,
            fees : item.cash_on_delivery_fee,
          }
        })

        // let islands = obj.shipping_setting_individual_shipping && JSON.parse(obj.shipping_setting_individual_shipping)?.map((item: any) => {
        //     return {
        //         name : item.prefecture_id,
        //         value : item.shipping_fee,
        //         title: item.title
        //     }
        //   })
        let islands = parameters['/Define/General/Prefecture']?.map((item: any) => {
            return {
                name: item.value,
                value: '',
                title: item.name
            }
        })
      
          return {
              pk: obj.pk,
              //配送マスタ登録
              shippingMasterName : searchValue ? obj.delivery_master_name + 'コピー' : obj.delivery_master_name,
              region : obj.region,
              memo : obj.internal_memo,
              temperature_control_zones: obj.temperature_control_zones,
              delivery_type: obj.delivery_type,
              //配送日時設定（受注〆時間）
              delivery_setting_status: obj.delivery_setting_status,
              deliveryDay : obj.delivery_setting_minimum_delivery_date, //最短配送日
              getOrderTime : obj.delivery_setting_order_deadline,
              holidayJudgment : obj.delivery_setting_holiday_judgment,
              career : obj.delivery_setting_carrier,
              serviceName : obj.delivery_setting_service_name,
              regionSettingTime,
              
              dateSelectionSettings : obj.delivery_setting_date_selection,
              timeSetting : obj.delivery_setting_time_specification,
              selectTimeOptions,
              //送料設定
              shoppingSettings : obj.shipping_setting_status,
              shoppingFee : obj.shipping_setting_notes,
              freeShoppingStatus : obj.shipping_setting_free_shipping_flag,
              freeShoppingType : obj.shipping_setting_free_shipping_tax_category,
              freeShoppingValue : obj.shipping_setting_free_shipping_line,
              freeShippingExcludedAreas : obj.shipping_setting_free_shipping_exclusion_flag,
              freeShippingExcludedAreasValue : JSON.parse(obj.shipping_setting_free_shipping_exclusion_area),

              shippingTypeSetting : obj.shipping_setting_shipping_type, //送料タイプ設定

              islands, //送料個別設定
              totalAmount, //合計金額より変動
              weight, //荷物サイズにより変動

              //代金引換設定
              cashSeliverySetting : obj.cash_on_delivery_setting_status,
              taxFee,

          }
         
      }

    const execGetParameters = async () => {
        const names = [
            '/Define/SupplierShippingSettings/Temperature',
            '/Define/SupplierShippingSettings/DeliveryType',
            '/Define/SupplierShippingSettings/Holiday',
            '/Define/SupplierShippingSettings/Carries',
            '/Define/SupplierShippingSettings/CarrieServices',
            '/Define/General/Prefecture',
            '/Define/SupplierShippingSettings/TimeSpecification',
            '/Define/SupplierShippingSettings/FreeShippingStatus',
            '/Define/SupplierShippingSettings/FeeType',
            '/Define/SupplierShippingSettings/CodStatus'
        ]
        const res: any = await parameterStoreGet({names: names}, {headers})

        console.log('parameterStore111', res)
        const obj = {...res}

        // resはobject
        // 内部をループしてJSON形式ならパースする
        for (const key in obj) {
            // JSON.parseしてエラーだったらそのまま
            try {
                obj[key] = JSON.parse(obj[key])
            } catch (error) {
                obj[key] = obj[key]
            }
        }

        console.log(res)

        setParameters(obj)
    }

    const getTaxOptions = async ()=> {
        const resp: any = await taxSettingGet({pk:auth.group_id},headers)
        console.log('resp',resp)
        if (resp && resp.length>0) {
            setTaxOptions(resp[0].taxSettingDetailList)
        }
    }

    const {auth} = useAuth();
    let headers = {
      "Content-Type":"application/json",
      Authorization:`Bearer ${auth.token}`
    }
    const init = async () => {

        setContentLoading(true)

        const str = currentLocation.hash;
        const regex = /copy=(\d+)/;
        const match = str.match(regex);
        if (match) {
            searchValue = match[1];
        }

        await execGetParameters()
        await getTaxOptions()

        let res2: any = await supplierRegionListAll({}, headers)
        setAreaList(res2)
        // let res3: any = await taxSettingDetailsListAll({},headers)
        // setTaxation(res3)

        if(id != undefined && searchValue != undefined){
            setflg(false);
        }

        if (id || searchValue) { //编辑
            

            setIsEdit(true)
           if (searchValue) {
                setIsCopy(true)
            }
            let res: any = await shippingMasterInfoGet({
                pkVal: currentLocation.state,
            })
            console.log(res)
           let data = dataChangeReverse(res)
            formik.setValues(data)
        } else { //新增
            setflg(false);
            let arr = parameters['/Define/General/Prefecture']?.map((item: any) => {
                return {
                    name: item.value,
                    value: '',
                    title: item.name
                }
            })
            formData.islands = arr
            let newData = JSON.parse(JSON.stringify(formData))
            setFormData(newData)
            formik.setValues(newData)
          }

        setContentLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    const formik: any = useFormik({
        initialValues: formData,
        validationSchema: validationSchema,
        onSubmit: async (values, {setSubmitting}) => {
          setSubmitting(true)
          bindSubmit(values)
        },
      })

      //新增或修改
      const bindSubmit = async (values: any) => {
        let params:any = dataChange(values)
        console.log(values, params)
        
        let res: any
        if(params.pk){
            params.pkVal = params.pk
            if (isCopy) {//复制
                res = await shippingMasterCopy(params, {headers})
            } else {//编辑
                res = await shippingMasterInfoUpdate(params, {headers})
            }
        } else { //新增
            res = await shippingMasterInfoAdd(params, {headers})
        }

        showSuccessMessage(res.message)
        navigate(`/store/shipping`)
        
      }
      //将表单数据转化为接口入参需要的数据格式
      const dataChange = (values: any)=>{
        let obj = JSON.parse(JSON.stringify(values))

        let delivery_setting_regional_lead_time = obj.regionSettingTime?.map((item: any) => {
          return {
              prefecture: item.region,
              lead_time: item.leadTime,
          }
        })
        let delivery_setting_time_option = obj.selectTimeOptions?.map((item: any) => {
          return {
              id: item.id,
              option: item.value,
          }
        })
        let shipping_setting_amount_variation = obj.totalAmount?.map((item: any) => {
          return {
              minimum: item.totalAmountMin,
              maximum: item.totalAmountMax,
              shipping_fee: item.money,
          }
        })
        let shipping_setting_size_variation = obj.weight?.map((item: any) => {
          return {
              minimum: item.weightMin,
              maximum: item.weightMax,
              shipping_fee: item.money,
          }
        })
       
        let cash_on_delivery_setting_fee = obj.taxFee?.map((item: any) => {
          return {
              order_amount: item.amount,
              cash_on_delivery_fee: item.fees,
          }
        })

        let shipping_setting_individual_shipping = obj.islands?.map((item: any) => {
            return {
                prefecture_id: item.name,
                shipping_fee: item.value,
                title: item.title
            }
          })
      
          return {
              pk: obj.pk || '',
              //配送マスタ登録
              delivery_master_name: obj.shippingMasterName,
              region: obj.region,
              internal_memo: obj.memo,
              temperature_control_zones: obj.temperature_control_zones,
              delivery_type: obj.delivery_type,
              //配送日時設定（受注〆時間）
              delivery_setting_status: obj.delivery_setting_status,
              delivery_setting_minimum_delivery_date: obj.deliveryDay + '', //最短配送日
              delivery_setting_order_deadline: obj.getOrderTime,
              delivery_setting_holiday_judgment: obj.holidayJudgment,
              delivery_setting_carrier: obj.career,
              delivery_setting_service_name: obj.serviceName,
              delivery_setting_regional_lead_time: JSON.stringify(delivery_setting_regional_lead_time),
              
              delivery_setting_date_selection: obj.dateSelectionSettings + '',
              delivery_setting_time_specification: obj.timeSetting,
              delivery_setting_time_option: JSON.stringify(delivery_setting_time_option),
              //送料設定
              shipping_setting_status: obj.shoppingSettings,
              shipping_setting_notes: obj.shoppingFee,
              shipping_setting_free_shipping_flag: obj.freeShoppingStatus,
              shipping_setting_free_shipping_tax_category: obj.freeShoppingType,
              shipping_setting_free_shipping_line: obj.freeShoppingValue,
              shipping_setting_free_shipping_exclusion_flag: obj.freeShippingExcludedAreas,
              shipping_setting_free_shipping_exclusion_area: JSON.stringify(obj.freeShippingExcludedAreasValue),

              shipping_setting_shipping_type: obj.shippingTypeSetting, //送料タイプ設定

              shipping_setting_individual_shipping: JSON.stringify(shipping_setting_individual_shipping), //送料個別設定
              shipping_setting_amount_variation: JSON.stringify(shipping_setting_amount_variation), //合計金額より変動
              shipping_setting_size_variation: JSON.stringify(shipping_setting_size_variation), //荷物サイズにより変動

              //代金引換設定
              cash_on_delivery_setting_status: obj.cashSeliverySetting,
              cash_on_delivery_setting_fee: JSON.stringify(cash_on_delivery_setting_fee),

          }
         
      }
      const updateData = (fieldsToUpdate: Partial<any>) => {
        const updatedData = {...formik.values, ...fieldsToUpdate}
        formik.setValues(updatedData)
      }

    return (
        <div>
            <div className="container-fluid">
                <form onSubmit={formik.handleSubmit} noValidate>
                    {/* 配送設定 */}
                    <div className="card mb-6 shadow-sm">
                        <div className="d-flex align-items-center card-header">
                            <span className="card-title fw-bold m-0">配送設定</span>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    {/* 配送マスタ名 */}
                                    <div className="row mb-6">
                                        <div className="col-lg-3 col-form-label">
                                            <label className="col-form-label required">配送マスタ名</label>
                                        </div>
                                        <div className="col-lg-9 align-self-center">
                                            <input className="form-control form-control-lg" type="text" placeholder="配送マスタ名"
                                               
                                                value={formik.values.shippingMasterName}
                                                {...formik.getFieldProps('shippingMasterName')}
                                            />
                                            {formik.touched.shippingMasterName && formik.errors.shippingMasterName && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.shippingMasterName}</div>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* リージョン */}
                                    <div className="row mb-6">
                                        <div className="col-lg-3 col-form-label align-items-center align-self-center">
                                            <label className="col-form-label required">
                                                <span>リージョン</span>
                                            </label>
                                        </div>
                                        <div className="col-lg-9 align-self-center">
                                            <input type="text" className='form-control' value={formik.values.region} {...formik.getFieldProps('region')} />
                                            {formik.touched.region && formik.errors.region && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.region}</div>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* 社内メモ */}
                                    <div className="row">
                                        <div className="col">
                                            <div className="row mb-6">
                                                <div className="col-lg-3 col-form-label">
                                                    <label className="col-form-label required">社内メモ</label>
                                                </div>
                                                <div className="col-lg-9 align-self-center">
                                                    <textarea className="form-control" rows={5}
                                                        // value={formData.memo}
                                                        // onChange={(e) => {
                                                        //     formData.memo = e.target.value
                                                        //     let newData = JSON.parse(JSON.stringify(formData))
                                                        //     setFormData(newData)
                                                        // }}
                                                        value={formik.values.memo}
                                                        {...formik.getFieldProps('memo')}
                                                    ></textarea>
                                                    {formik.touched.memo && formik.errors.memo && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.memo}</div>
                                                    </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {温度帯} */}
                                    <div className="row mb-6">
                                        <div className="col-lg-3 col-form-label align-items-center align-self-center">
                                            <label className="col-form-label required">
                                                <span>温度帯</span>
                                            </label>
                                        </div>
                                        <div className="col-lg-9 align-self-center">
                                            <select className="form-select" data-control="select2"
                                                value={formik.values.temperature_control_zones}
                                                {...formik.getFieldProps('temperature_control_zones')}
                                            >
                                                <option value=''>未指定</option>
                                                {/* <option value='1'>通常</option>
                                                <option value='2'>冷蔵</option>
                                                <option value='3'>冷凍</option> */}
                                                {parameters['/Define/SupplierShippingSettings/Temperature']?.map((item: any, index: number) => {
                                                    return (
                                                    <option key={index} value={item.id}>
                                                        {item.label}
                                                    </option>
                                                    )
                                                })}
                                                
                                            </select>
                                            {formik.touched.temperature_control_zones && formik.errors.temperature_control_zones && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.temperature_control_zones}</div>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                    {/* {配送種別} */}
                                    <div className="row mb-6">
                                        <div className="col-lg-3 col-form-label align-items-center align-self-center">
                                            <label className="col-form-label required">
                                                <span>配送種別</span>
                                            </label>
                                        </div>
                                        <div className="col-lg-9 align-self-center">
                                            <select className="form-select" data-control="select2"
                                                value={formik.values.delivery_type}
                                                {...formik.getFieldProps('delivery_type')}
                                            >
                                                <option value=''>未指定</option>
                                                {/* <option value='1'>配送</option>
                                                <option value='2'>店頭渡し</option> */}
                                                {parameters['/Define/SupplierShippingSettings/DeliveryType']?.map((item: any, index: number) => {
                                                    return (
                                                    <option key={index} value={item.id}>
                                                        {item.label}
                                                    </option>
                                                    )
                                                })}
                                            </select>
                                            {formik.touched.delivery_type && formik.errors.delivery_type && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.delivery_type}</div>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                    
                                    {/* 配送キャリア */}
                                    {/* <div className="row mb-6">
                                        <div className="col-lg-3 col-form-label align-items-center align-self-center">
                                            <label className="col-form-label">配送キャリア</label>
                                        </div>
                                        <div className="col-lg-9 align-self-center">
                                            <select className="form-select" data-control="select2"
                                                value={formData.career}
                                                onChange={(e) => {
                                                    formData.career = e.target.value
                                                    let newData = JSON.parse(JSON.stringify(formData))
                                                    setFormData(newData)
                                                }}
                                            >
                                                <option value='0'>未指定</option>
                                                <option value='1'>ヤマト運輸</option>
                                                <option value='2'>佐川急便</option>
                                                <option value='3'>日本郵便</option>
                                                <option value='4'>西濃運輸</option>
                                                <option value='5'>福山通運</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    {/* 配送サービス名 */}
                                    {/* <div className="row mb-6">
                                        <div className="col-lg-3 col-form-label align-items-center align-self-center">
                                            <label className="col-form-label">配送サービス名</label>
                                        </div>
                                        <div className="col-lg-9 align-self-center">
                                            <select className="form-select" data-control="select2"
                                                value={formData.serviceName}
                                                onChange={(e) => {
                                                    formData.serviceName = e.target.value
                                                    let newData = JSON.parse(JSON.stringify(formData))
                                                    setFormData(newData)
                                                }}
                                            >
                                                <option value='0'>未指定</option>
                                                <option value='1'>宅配便</option>
                                                <option value='2'>宅配便コンパクト</option>
                                                <option value='3'>クール宅配便</option>
                                            </select>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 配送日時設定（受注〆時間） */}
                    <div className="card mb-6 shadow-sm">
                        <div className="d-flex align-items-center card-header">
                            <span className="card-title fw-bold m-0">配送日時設定（受注〆時間）</span>
                        </div>
                        <div className="card-body">

                            {/* 時間指定設定 */}
                            <div className="row my-6">
                                <div className="col-2 col-xxl-2 align-items-center align-elf-center">
                                    <label className="col-form-label">配送日時設定</label>
                                </div>
                                <div className="col-4 align-self-center">
                                    {LeadTimeSettings?.map((item2: any) => {
                                        return (
                                            <div
                                                className='form-check form-check-inline pe-3'
                                                key={item2.id}
                                            >
                                                <input
                                                    className='form-check-input'
                                                    type='radio'
                                                    name='delivery_setting_status'
                                                    value={item2.id}
                                                    checked={formik.values.delivery_setting_status == item2.id}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            formik.values.delivery_setting_status = item2.id;
                                                            updateData(formik.values)
                                                        }
                                                    }}
                                                />
                                                <label>{item2.name}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {/* 最短配送日 */}
                                    <div className="row mb-6">
                                        <div className="col-lg-3 col-form-label">
                                            <label className="col-form-label">最短配送日</label>
                                        </div>
                                        <div className="col-lg-9 align-self-center" style={{ display: 'flex', alignItems: 'baseline' }}>
                                            <input className="form-control" type="number" placeholder=""
                                                // value={formData.deliveryDay}
                                                {...formik.getFieldProps('deliveryDay')}
                                                style={{ width: 100, marginRight: 10 }}
                                                // onChange={(e) => {
                                                //     formData.deliveryDay = e.target.value
                                                //     let newData = JSON.parse(JSON.stringify(formData))
                                                //     setFormData(newData)
                                                // }}
                                            />
                                            <span>日後</span>
                                        </div>
                                    </div>
                                    {/* 受注〆時間 */}
                                    <div className="row mb-6">
                                        <div className="col-lg-3 col-form-label align-items-center align-self-center">
                                            <label className="col-form-label">
                                                <span>受注〆時間</span>
                                            </label>
                                        </div>
                                        <div className="col-lg-9 align-self-center" style={{ display: 'flex', alignItems: 'baseline' }}>
                                            {/* <select className="form-select" data-control="select2"
                                                value={formData.getOrderTime}
                                                style={{ width: 100, marginRight: 10 }}
                                                onChange={(e) => {
                                                    formData.getOrderTime = e.target.value
                                                    let newData = JSON.parse(JSON.stringify(formData))
                                                    setFormData(newData)
                                                }}
                                            >
                                                <option value='0'>選択してください</option>
                                                <option value='11:00'>11:00</option>
                                                <option value='12:00'>12:00</option>
                                                <option value='13:00'>13:00</option>
                                                <option value='14:00'>14:00</option>
                                            </select> */}
                                            <TimePicker
                                            format={format} 
                                            locale={locale}
                                            value={dayjs(formik.values.getOrderTime || '00:00', format)}
                                            // {...formik.getFieldProps('getOrderTime')}
                                            onChange={(
                                                value: DatePickerProps['value'] | RangePickerProps['value'],
                                                dateString: [string, string] | string,
                                            ) => {
                                                console.log(dateString)
                                                // formData.getOrderTime = dateString
                                                // let newData = JSON.parse(JSON.stringify(formData))
                                                // setFormData(newData)
                                                // console.log(formData)
                                                // formik.setValues(formData)
                                                formik.values.getOrderTime = dateString
                                                updateData(formik.values)
                                            }}
                                            />
                                            <span>時以降は翌日配送</span>
                                        </div>
                                    </div>
                                    {/* 休日判定 */}
                                    <div className="row">
                                        <div className="col">
                                            <div className="row mb-6">
                                                <div className="col-lg-3 col-form-label"><label className="col-form-label">休日判定</label></div>
                                                <div className="col-lg-9 align-self-center">
                                                    {parameters['/Define/SupplierShippingSettings/Holiday']?.map((item1: any) => {
                                                        return (
                                                            <div
                                                                className='form-check form-check-inline pe-3 mb-3'
                                                                key={item1.id}
                                                            >
                                                                <input
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='holidayJudgment'
                                                                    value={item1.id}
                                                                    checked={formik.values.holidayJudgment == item1.id}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            // formData.holidayJudgment = item1.id;
                                                                            // let newData = JSON.parse(JSON.stringify(formData))
                                                                            // setFormData(newData)
                                                                            updateData({holidayJudgment: item1.id})
                                                                        }
                                                                    }}
                                                                />
                                                                <label className='form-check-label'>{item1.label}</label>
                                                            </div>
                                                        )
                                                    })}
                                                    <div>※営業日は<a href=''>営業日設定</a>からご確認いただけます。</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 配送キャリア */}
                                    <div className="row mb-6">
                                        <div className="col-lg-3 col-form-label align-items-center align-self-center">
                                            <label className="col-form-label">配送キャリア</label>
                                        </div>
                                        <div className="col-lg-9 align-self-center">
                                            <select className="form-select" data-control="select2"
                                                // value={formData.career}
                                                // onChange={(e) => {
                                                //     formData.career = e.target.value
                                                //     let newData = JSON.parse(JSON.stringify(formData))
                                                //     setFormData(newData)
                                                // }}
                                                {...formik.getFieldProps('career')}
                                            >
                                                <option value=''>未指定</option>
                                                {/* <option value='kuronekoyamato'>ヤマト運輸</option>
                                                <option value='sagawa'>佐川急便</option>
                                                <option value='japanpost'>日本郵便</option>
                                                <option value='seino'>西濃運輸</option>
                                                <option value='fukutsu'>福山通運</option>
                                                <option value='private_delivery'>自社配送</option>
                                                <option value='byhand'>店頭渡し</option>
                                                <option value='other'>その他</option> */}
                                                {parameters['/Define/SupplierShippingSettings/Carries']?.map((item: any, index: number) => {
                                                    return (
                                                    <option key={index} value={item.id}>
                                                        {item.label}
                                                    </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* 配送サービス名 */}
                                    <div className="row mb-6">
                                        <div className="col-lg-3 col-form-label align-items-center align-self-center">
                                            <label className="col-form-label">配送サービス名</label>
                                        </div>
                                        <div className="col-lg-9 align-self-center">
                                            <select className="form-select" data-control="select2"
                                                // value={formData.serviceName}
                                                // onChange={(e) => {
                                                //     formData.serviceName = e.target.value
                                                //     let newData = JSON.parse(JSON.stringify(formData))
                                                //     setFormData(newData)
                                                // }}
                                                {...formik.getFieldProps('serviceName')}
                                            >
                                                <option value=''>未指定</option>
                                                {/* <option value='1'>宅配便</option>
                                                <option value='2'>宅配便コンパクト</option>
                                                <option value='3'>クール宅配便</option> */}
                                                {parameters['/Define/SupplierShippingSettings/CarrieServices']?.map((item: any, index: number) => {
                                                    return (
                                                    <option key={index} value={item.id}>
                                                        {item.label}
                                                    </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="border"></div>
                                    {/* 地域別リードタイム設定 */}
                                    <div className="row mb-6">
                                        <div className='col-lg-3 col-form-label'>
                                            <label className="col-form-label">地域別リードタイム設定</label>
                                        </div>
                                        <p className="">最短配送日に地域別のリードタイムを追加</p>
                                        <div>
                                            {
                                                formik.values?.regionSettingTime?.length !== 0 && formik.values.regionSettingTime?.map((item: any, index: number) => {
                                                    return (
                                                        <div className="row mb-6" key={index}>
                                                            <div className="col-lg-2 col-form-label align-self-center">
                                                                <label className="col-form-label">都道府県</label>
                                                            </div>
                                                            <div className="col-3 align-self-center">
                                                                <select className="form-select" data-control="select2"
                                                                    value={item.region}
                                                                    style={{ width: 180 }}
                                                                    onChange={(e) => {
                                                                        item.region = e.target.value;
                                                                        updateData({})
                                                                    }}
                                                                >
                                                                    <option value=''>選択してください</option>
                                                                    {
                                                                        parameters['/Define/General/Prefecture']?.map((i: any) => {
                                                                            return <option value={i.id} key={i.id}>{i.label}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            <div className="col-lg-2 col-form-label align-self-center">
                                                                <label className="col-form-label">リードタイム</label>
                                                            </div>
                                                            <div className="col-3 align-self-center" style={{ display: 'flex', alignItems: 'center' }}>
                                                                <input className="form-control" type="number"
                                                                    value={item.leadTime}
                                                                    style={{ width: 100, marginRight: 10 }}
                                                                    onChange={(e) => {
                                                                        item.leadTime = e.target.value;
                                                                        updateData({})
                                                                    }}
                                                                />
                                                                <span>日</span>
                                                                <button
                                                                    style={{ margin: '0px 20px', display: formik.values.regionSettingTime.length <= 1 ? 'none' : 'block' }}
                                                                    className='btn btn-icon btn-light-danger btn-sm'
                                                                    type='button'
                                                                    disabled={formik.values.regionSettingTime.length <= 1}
                                                                    onClick={() => {
                                                                        formik.values.regionSettingTime.splice(index, 1);
                                                                        updateData({})
                                                                    }}
                                                                >
                                                                    <KTSVG path="/media/icons/duotune/arrows/arr011.svg" className="svg-icon-muted svg-icon-2x" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <button
                                            className='btn btn-icon btn-light-primary btn-sm'
                                            style={{ width: 90 }}
                                            type='button'
                                            onClick={() => {
                                                formik.values.regionSettingTime.push({ region: '', leadTime: '' });
                                                updateData({})
                                            }}
                                        >
                                            <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-5" />追加する
                                        </button>
                                    </div>
                                    <div className="border"></div>
                                    {/* 日付選択設定 */}
                                    <div className="row my-6">
                                        <div className="col-lg-3 col-form-label align-self-center">
                                            <label className="col-form-label">日付選択設定</label>
                                        </div>
                                        <div className="col-lg-9 align-self-center">
                                            <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                                <span>選択可能な日数</span>
                                                <input className="form-control" type="number"
                                                    //value={formData.dateSelectionSettings}
                                                    style={{ width: 100, margin: '0px 10px' }}
                                                    {...formik.getFieldProps('dateSelectionSettings')}
                                                    // onChange={(e) => {
                                                    //     formData.dateSelectionSettings = e.target.value;
                                                    //     let newData = JSON.parse(JSON.stringify(formData))
                                                    //     setFormData(newData)
                                                    // }}
                                                />
                                                <span>日間</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border"></div>
                                    {/* 時間指定設定 */}
                                    <div className="row my-6">
                                        <div className="col-lg-3 col-form-label align-self-center">
                                            <label className="col-form-label">時間指定設定</label>
                                        </div>
                                        <div className="col-lg-9 align-self-center">
                                            {parameters['/Define/SupplierShippingSettings/TimeSpecification']?.map((item2: any) => {
                                                return (
                                                    <div
                                                        className='form-check form-check-inline pe-3'
                                                        key={item2.id}
                                                    >
                                                        <input
                                                            className='form-check-input'
                                                            type='radio'
                                                            name='timeSetting'
                                                            value={item2.id}
                                                            checked={formik.values.timeSetting == item2.id}
                                                            // {...formik.getFieldProps('timeSetting')}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    updateData({timeSetting: item2.id})
                                                                    // formData.timeSetting = item2.id;
                                                                    // let newData = JSON.parse(JSON.stringify(formData))
                                                                    // setFormData(newData)
                                                                }
                                                            }}
                                                        />
                                                        <label className='form-check-label'>{item2.label}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <p className="">下記の時間帯を選択肢としてプルダウンメニューに表示</p>
                                    <div>
                                        {
                                            formik.values.selectTimeOptions.length !== 0 && formik.values.selectTimeOptions?.map((item: any, index: number) => {
                                                return (
                                                    <div style={{ display: 'flex', alignItems: 'center' }} key={item.id}>
                                                        <label>{item.id}</label>
                                                        <input className="form-control" type="text" placeholder={`選択肢${item.id}`}
                                                            value={item.value}
                                                            style={{ width: 300, margin: '5px 20px' }}
                                                            onChange={(e) => {
                                                                item.value = e.target.value;
                                                                // let newData = JSON.parse(JSON.stringify(formData))
                                                                // setFormData(newData)
                                                                updateData({})
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 送料設定 */}
                    <div className="card mb-6 shadow-sm">
                        <div className="d-flex align-items-center card-header">
                            <span className="card-title fw-bold m-0">送料設定</span>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    {/* 送料設定 */}
                                    <div className="row">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col align-self-center">
                                                    {parameters['/Define/SupplierShippingSettings/FreeShippingStatus']?.map((item1: any) => {
                                                        return (
                                                            <div
                                                                className='form-check form-check-inline pe-3 mb-3'
                                                                key={item1.id}
                                                            >
                                                                <input
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='shoppingSettings'
                                                                    value={item1.id}
                                                                    checked={formik.values.shoppingSettings == item1.id}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            updateData({shoppingSettings: item1.id})
                                                                            // formData.shoppingSettings = item1.id;
                                                                            // let newData = JSON.parse(JSON.stringify(formData))
                                                                            // setFormData(newData)
                                                                        }
                                                                    }}
                                                                />
                                                                <label className='form-check-label'>{item1.label}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 備考欄（送料について） */}
                                    <div className="row mb-6">
                                        <p>備考欄（送料について）</p>
                                        <div className="col align-self-center">
                                            <textarea className="form-control" rows={5}
                                                // value={formData.shoppingFee}
                                                // onChange={(e) => {
                                                //     formData.shoppingFee = e.target.value;
                                                //     let newData = JSON.parse(JSON.stringify(formData))
                                                //     setFormData(newData)
                                                // }}
                                                {...formik.getFieldProps('shoppingFee')}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="border mb-6"></div>
                                    {/* 送料無料設定 */}
                                    <div className="row mb-6">
                                        <p>送料無料設定</p>
                                        <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className='col-lg-3 align-self-center form-check form-check-inline pe-3 mb-3 ms-9'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    name='freeShoppingStatus'
                                                    //value={formik.values.freeShoppingStatus}
                                                    checked={formik.values.freeShoppingStatus === 'enabled'}
                                                    onChange={(e) => {
                                                        updateData({
                                                            freeShoppingStatus: e.target.checked? 'enabled': 'disabled'
                                                        })
                                                    }}
                                                />
                                                <label className='form-check-label'>利用する</label>
                                            </div>
                                            <div className='col-lg-3 align-self-center form-check form-check-inline pe-3 mb-3'>
                                                <select className="form-select" data-control="select2"
                                                    //value={formData.freeShoppingType}
                                                    style={{ width: 180, marginRight: 10 }}
                                                    // onChange={(e) => {
                                                    //     formData.freeShoppingType = e.target.value;
                                                    //     let newData = JSON.parse(JSON.stringify(formData))
                                                    //     setFormData(newData)
                                                    // }}
                                                    {...formik.getFieldProps('freeShoppingType')}
                                                >
                                                    {/* <option value='0'>税込</option>
                                                    <option value='1'>税抜</option> */}
                                                    {
                                                        taxOptions?.map((item:any, index:any) => {
                                                            return <option key={index} value={item.pk}>{item.name}（{item.taxrate}%）</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className='col-lg-5 align-self-center form-check form-check-inline pe-3 mb-3' style={{ display: 'flex', alignItems: 'center' }}>
                                                <input className="form-control" type="text" placeholder=""
                                                    // value={formData.freeShoppingValue}
                                                    // onChange={(e) => {
                                                    //     formData.freeShoppingValue = e.target.value;
                                                    //     let newData = JSON.parse(JSON.stringify(formData))
                                                    //     setFormData(newData)
                                                    // }}
                                                    {...formik.getFieldProps('freeShoppingValue')}
                                                />
                                                <span style={{ width: 200, marginLeft: 10 }}>円 以上で送料無料</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 送料無料除外地域 */}
                                    <div className="row mb-6">
                                        <p>送料無料除外地域</p>
                                        <div className="col align-self-center">
                                            <div className='form-check form-check-inline pe-3 mb-3'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    name='freeShippingExcludedAreas'
                                                    //value={formData.freeShippingExcludedAreas}
                                                    checked={formik.values.freeShippingExcludedAreas === 'enabled'}
                                                    onChange={(e) => {
                                                        updateData({
                                                            freeShippingExcludedAreas: e.target.checked? 'enabled': 'disabled'
                                                        })
                                                        // if (e.target.checked) {
                                                        //     formData.freeShippingExcludedAreas = '1';
                                                        //     let newData = JSON.parse(JSON.stringify(formData))
                                                        //     setFormData(newData)
                                                        // } else {
                                                        //     formData.freeShippingExcludedAreas = '0';
                                                        //     let newData = JSON.parse(JSON.stringify(formData))
                                                        //     setFormData(newData)
                                                        // }
                                                    }}
                                                />
                                                <label className='form-check-label'>利用する</label>
                                            </div>
                                            <div className="row mb-6">
                                                <div className="col-lg-2">
                                                    <label className="col-form-label">都道府県</label>
                                                </div>
                                                <div className="col-lg-3 align-self-center">
                                                    {
                                                        formik.values.freeShippingExcludedAreasValue.length !== 0 && formik.values.freeShippingExcludedAreasValue?.map((item: any, index: number) => {
                                                            return (
                                                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                                                    {/* <input className="form-control" type="text"

                                                                        value={formData.freeShippingExcludedAreasValue[index]}
                                                                        onChange={(e) => {
                                                                            formData.freeShippingExcludedAreasValue[index] = e.target.value;
                                                                            let newData = JSON.parse(JSON.stringify(formData))
                                                                            setFormData(newData)
                                                                        }}
                                                                    /> */}
                                                                    <select className="form-select" data-control="select2"
                                                                    value={item.prefecture_id}
                                                                    style={{ width: 180 }}
                                                                    onChange={(e) => {
                                                                        item.prefecture_id = e.target.value;
                                                                        updateData({})
                                                                    }}
                                                                >
                                                                    <option value=''>選択してください</option>
                                                                    {
                                                                        parameters['/Define/General/Prefecture']?.map((i: any) => {
                                                                            return <option value={i.id} key={i.id}>{i.label}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                                    <button
                                                                        style={{ width: 50, margin: '0px 20px', display: formik.values.freeShippingExcludedAreasValue.length <= 1 ? 'none' : 'block' }}
                                                                        className='btn btn-icon btn-light-danger btn-sm'
                                                                        type='button'
                                                                        disabled={formik.values.freeShippingExcludedAreasValue.length <= 1}
                                                                        onClick={() => {
                                                                            formik.values.freeShippingExcludedAreasValue.splice(index, 1);
                                                                            updateData({})
                                                                        }}
                                                                    >
                                                                        <KTSVG path="/media/icons/duotune/arrows/arr011.svg" className="svg-icon-muted svg-icon-2x" />
                                                                    </button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        className='btn btn-icon btn-light-primary btn-sm'
                                        style={{ width: 90 }}
                                        type='button'
                                        onClick={() => {
                                            formik.values.freeShippingExcludedAreasValue.push({prefecture_id: ''});
                                            updateData({})
                                        }}
                                    >
                                        <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-5" />追加する
                                    </button>
                                    <div className="border my-6"></div>
                                    {/* 送料タイプ設定 */}
                                    <div className="row">
                                        <div className="col">
                                            <p>送料タイプ設定</p>
                                            <div className="row">
                                                <div className="col align-self-center">
                                                    {parameters['/Define/SupplierShippingSettings/FeeType']?.map((item1: any) => {
                                                        return (
                                                            <div
                                                                className='form-check form-check-inline pe-3 mb-3'
                                                                key={item1.id}
                                                            >
                                                                <input
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='shippingTypeSetting'
                                                                    //value={item1.id}
                                                                    checked={formik.values.shippingTypeSetting == item1.id}
                                                                    //{...formik.getFieldProps('shippingTypeSetting')}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            formik.values.shippingTypeSetting = item1.id
                                                                            updateData(formik.values)
                                                                            // formData.shippingTypeSetting = item1.id;
                                                                            // let newData = JSON.parse(JSON.stringify(formData))
                                                                            // setFormData(newData)
                                                                        }
                                                                    }}
                                                                />
                                                                <label className='form-check-label'>{item1.label}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border my-6"></div>
                                    {/* １．送料個別設定 */}
                                    <div className="row" style={{display: formik.values.shippingTypeSetting == 1?'block':'none'}}>
                                        <div className="col">
                                            <p>１．送料個別設定</p>
                                            <p>送料一括で変更　※一括で全ての都道府県に反映するには、下記フォームに金額を入力し「一括変更」を押してください。</p>
                                            <div className="d-flex flex-wrap">
                                                <div className="align-self-center" style={{ display: 'flex', alignItems: 'center' }}>
                                                    <input className="form-control" type="text"
                                                        value={formData.individualShippingSettings}
                                                        style={{ width: 200 }}
                                                        onChange={(e) => {
                                                            formData.individualShippingSettings = e.target.value;
                                                            let newData = JSON.parse(JSON.stringify(formData))
                                                            setFormData(newData)
                                                        }}
                                                    />
                                                    <span style={{ margin: '0px 10px' }}>円</span>
                                                </div>
                                                <div className='mt-3'>
                                                    <button
                                                        className='btn btn-icon btn-primary btn-sm'
                                                        style={{ width: 90 }}
                                                        type='button'
                                                        onClick={() => {
                                                            for (let i = 0; i < formik.values.islands.length; i++) {
                                                                formik.values.islands[i].value = formData.individualShippingSettings;
                                                            }
                                                            updateData({})
                                                            // let newData = JSON.parse(JSON.stringify(formData))
                                                            // setFormData(newData)
                                                        }}
                                                    >
                                                        一括変更
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row" style={{ display: 'flex', justifyContent: 'left', marginTop: 10 }}>
                                                {
                                                    formik.values.islands?.map((item: any, index: number) => {
                                                        return (
                                                            <div className="col" key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                                                <span style={{ width: 50 }}>{item.title}</span>
                                                                <input className="form-control" type="text"
                                                                    value={item.value}
                                                                    // value={formik.values.islands[index].value}
                                                                    // {...formik.getFieldProps('islands')}
                                                                    style={{ width: 150, margin: '0px 10px' }}
                                                                    onChange={(e) => {
                                                                        item.value = e.target.value
                                                                        updateData({})
                                                                        // let newData = JSON.parse(JSON.stringify(formData))
                                                                        // console.log(e.target.value, newData)
                                                                        // setFormData(newData)
                                                                        // formik.setValues(newData)
                                                                    }}
                                                                    
                                                                />
                                                                <span>円</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="border my-6" style={{ borderStyle: 'dashed' }}></div> */}
                                    {/* ２．合計金額より変動 */}
                                    <div className="row" style={{display: formik.values.shippingTypeSetting == 2?'block':'none'}}>
                                        <div className="col">
                                            <p>２．合計金額より変動</p>
                                            <div className="row">
                                                {
                                                    formik.values.totalAmount.length !== 0 && formik.values.totalAmount?.map((item: any, index: number) => {
                                                        return (
                                                            <div className="d-flex flex-wrap align-self-center" style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }} key={index}>
                                                                <span>合計金額が</span>
                                                                <input className="form-control mt-3" type="number"
                                                                    value={item.totalAmountMin}
                                                                    style={{ width: 200, margin: '0px 10px' }}
                                                                    onChange={(e) => {
                                                                        item.totalAmountMin = e.target.value;
                                                                        updateData({})
                                                                        // let newData = JSON.parse(JSON.stringify(formData))
                                                                        // setFormData(newData)
                                                                    }}
                                                                />
                                                                <span style={{ margin: '0px 10px' }}>~</span>
                                                                <input className="form-control mt-3" type="number"
                                                                    value={item.totalAmountMax}
                                                                    style={{ width: 200 }}
                                                                    onChange={(e) => {
                                                                        item.totalAmountMax = e.target.value;
                                                                        updateData({})
                                                                        // let newData = JSON.parse(JSON.stringify(formData))
                                                                        // setFormData(newData)
                                                                    }}
                                                                />
                                                                <div>
                                                                    <span style={{ margin: '0px 10px' }}>の場合、送料は一律</span>
                                                                </div>
                                                                <input className="form-control mt-3" type="number"
                                                                    value={item.money}
                                                                    style={{ width: 200, marginRight: 10 }}
                                                                    onChange={(e) => {
                                                                        item.money = e.target.value;
                                                                        updateData({})
                                                                        // let newData = JSON.parse(JSON.stringify(formData))
                                                                        // setFormData(newData)
                                                                    }}
                                                                />
                                                                <span>円</span>
                                                                <button
                                                                    style={{ margin: '0px 20px', display: formik.values.totalAmount.length <= 1 ? 'none' : 'block' }}
                                                                    className='btn btn-icon btn-light-danger btn-sm'
                                                                    type='button'
                                                                    disabled={formik.values.totalAmount.length <= 1}
                                                                    onClick={() => {
                                                                        formik.values.totalAmount.splice(index, 1);
                                                                        updateData({})
                                                                        // let newData = JSON.parse(JSON.stringify(formData));
                                                                        // setFormData(newData);
                                                                    }}
                                                                >
                                                                    <KTSVG path="/media/icons/duotune/arrows/arr011.svg" className="svg-icon-muted svg-icon-2x" />
                                                                </button>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <button
                                                className='btn btn-icon btn-light-primary btn-sm'
                                                style={{ width: 90, margin: '10px 0px 0px 0px' }}
                                                type='button'
                                                onClick={() => {
                                                    formik.values.totalAmount.push({ totalAmountMin: '', totalAmountMax: '', money: '' })
                                                    updateData({})
                                                    // let newData = JSON.parse(JSON.stringify(formData))
                                                    // setFormData(newData)
                                                }}
                                            >
                                                <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-5" />追加する
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className="border my-6" style={{ borderStyle: 'dashed' }}></div> */}
                                    {/* ３．荷物サイズにより変動 */}
                                    <div className="row" style={{display: formik.values.shippingTypeSetting == 3?'block':'none'}}>
                                        <div className="col">
                                            <p>３．荷物サイズにより変動</p>
                                            <div className="row">
                                                {
                                                    formik.values.weight.length !== 0 && formik.values.weight?.map((item: any, index: number) => {
                                                        return (
                                                            <div className="d-flex flex-wrap align-self-center" style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }} key={index}>
                                                                <span>重量が</span>
                                                                <input className="form-control mt-3" type="number"
                                                                    value={item.weightMin}
                                                                    style={{ width: 200, margin: '0px 10px' }}
                                                                    onChange={(e) => {
                                                                        item.weightMin = e.target.value;
                                                                        // let newData = JSON.parse(JSON.stringify(formData))
                                                                        // setFormData(newData)
                                                                        updateData({})
                                                                    }}
                                                                />
                                                                <span style={{ margin: '0px 10px' }}>~</span>
                                                                <input className="form-control mt-3" type="number"
                                                                    value={item.weightMax}
                                                                    style={{ width: 200 }}
                                                                    onChange={(e) => {
                                                                        item.weightMax = e.target.value;
                                                                        // let newData = JSON.parse(JSON.stringify(formData))
                                                                        // setFormData(newData)
                                                                        updateData({})
                                                                    }}
                                                                />
                                                                <span style={{ margin: '0px 10px' }}>の場合、送料は一律</span>
                                                                <input className="form-control mt-3" type="number"
                                                                    value={item.money}
                                                                    style={{ width: 200, marginRight: 10 }}
                                                                    onChange={(e) => {
                                                                        item.money = e.target.value;
                                                                        // let newData = JSON.parse(JSON.stringify(formData))
                                                                        // setFormData(newData)
                                                                        updateData({})
                                                                    }}
                                                                />
                                                                <span>円</span>
                                                                <button
                                                                    style={{ margin: '0px 20px', display: formik.values.weight.length <= 1 ? 'none' : 'block' }}
                                                                    className='btn btn-icon btn-light-danger btn-sm'
                                                                    type='button'
                                                                    disabled={formik.values.weight.length <= 1}
                                                                    onClick={() => {
                                                                        formik.values.weight.splice(index, 1);
                                                                        // let newData = JSON.parse(JSON.stringify(formData));
                                                                        // setFormData(newData);
                                                                        updateData({})
                                                                    }}
                                                                >
                                                                    <KTSVG path="/media/icons/duotune/arrows/arr011.svg" className="svg-icon-muted svg-icon-2x" />
                                                                </button>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <button
                                                className='btn btn-icon btn-light-primary btn-sm'
                                                style={{ width: 90, margin: '10px 0px 0px 0px' }}
                                                type='button'
                                                onClick={() => {
                                                    formik.values.weight.push({ weightMin: '', weightMax: '', money: '' })
                                                    // let newData = JSON.parse(JSON.stringify(formData))
                                                    // setFormData(newData)
                                                    updateData({})
                                                }}
                                            >
                                                <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-5" />追加する
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 代金引換設定 */}
                    <div className="card mb-6 shadow-sm">
                        <div className="d-flex align-items-center card-header">
                            <span className="card-title fw-bold m-0">代金引換設定</span>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    {/* 代金引換設定 */}
                                    <div className="row">
                                        <div className="col">
                                            <div className="row">
                                                <div className="col align-self-center">
                                                    {parameters['/Define/SupplierShippingSettings/CodStatus']?.map((item1: any) => {
                                                        return (
                                                            <div
                                                                className='form-check form-check-inline pe-3 mb-3'
                                                                key={item1.id}
                                                            >
                                                                <input
                                                                    className='form-check-input'
                                                                    type='radio'
                                                                    name='cashSeliverySetting'
                                                                    value={item1.id}
                                                                    checked={formik.values.cashSeliverySetting == item1.id}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            updateData({cashSeliverySetting: item1.id})
                                                                            // formData.cashSeliverySetting = item1.id;
                                                                            // let newData = JSON.parse(JSON.stringify(formData))
                                                                            // setFormData(newData)
                                                                        }
                                                                    }}
                                                                />
                                                                <label className='form-check-label'>{item1.label}</label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* 代引手数料 */}
                                    <div className="row mb-6">
                                        <div>
                                            <label className="col-form-label">代引手数料</label>
                                        </div>
                                        <div>
                                            {
                                                formik.values?.taxFee?.length !== 0 && formik.values.taxFee?.map((item: any, index: number) => {
                                                    return (
                                                        <div className="d-flex flex-wrap align-items-center mb-6" key={index}>
                                                            <div style={{ width: 100 }}>
                                                                <label className="col-form-label">注文金額</label>
                                                            </div>
                                                            <div className="d-flex flex-wrap align-items-center">
                                                                <input className="form-control mt-3" type="text" placeholder=""
                                                                    style={{ width: 200, marginRight: 10 }}
                                                                    value={item.amount}
                                                                    onChange={(e) => {
                                                                        item.amount = e.target.value;
                                                                        // let newData = JSON.parse(JSON.stringify(formData))
                                                                        // setFormData(newData)
                                                                        updateData({})
                                                                    }}
                                                                />
                                                                <span>円以下</span>
                                                            </div>
                                                            <div style={{ width: 100 }}>
                                                                <label className="col-form-label">代引手数料</label>
                                                            </div>
                                                            <div className="d-flex flex-wrap align-items-center">
                                                                <input className="form-control mt-3" type="number"
                                                                    value={item.fees}
                                                                    style={{ width: 100, marginRight: 10 }}
                                                                    onChange={(e) => {
                                                                        item.fees = e.target.value;
                                                                        // let newData = JSON.parse(JSON.stringify(formData))
                                                                        // setFormData(newData)
                                                                        updateData({})
                                                                    }}
                                                                />
                                                                <span>円</span>
                                                                <button
                                                                    style={{ margin: '0px 20px', display: formik.values.taxFee.length <= 1 ? 'none' : 'block' }}
                                                                    className='btn btn-icon btn-light-danger btn-sm'
                                                                    type='button'
                                                                    disabled={formik.values.taxFee.length <= 1}
                                                                    onClick={() => {
                                                                        formik.values.taxFee.splice(index, 1);
                                                                        updateData({})
                                                                        // let newData = JSON.parse(JSON.stringify(formData));
                                                                        // setFormData(newData);
                                                                    }}
                                                                >
                                                                    <KTSVG path="/media/icons/duotune/arrows/arr011.svg" className="svg-icon-muted svg-icon-2x" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <button
                                            className='btn btn-icon btn-light-primary btn-sm'
                                            style={{ width: 90 }}
                                            type='button'
                                            onClick={() => {
                                                formik.values.taxFee.push({ amount: '', fees: '' });
                                                // let newData = JSON.parse(JSON.stringify(formData));
                                                // setFormData(newData);
                                                updateData({})
                                            }}
                                        >
                                            <KTSVG path="/media/icons/duotune/arrows/arr009.svg" className="svg-icon-muted svg-icon-5" />追加する
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{margin: 'auto', display: 'flex', justifyContent: 'center'}}>
                    <button className='btn btn-primary' style={{marginRight: 20}} type='button' onClick={() => navigate(-1)}>
                        戻る
                    </button>
                        <button
                            className='btn btn-primary'
                            type='submit' 
                        data-kt-users-modal-action='submit'
                        >
                            {flg ? '更新' : '登録'}
                        </button>
                        
                    </div>
                </form>
            </div>
        </div>
    )
}
