import {getServer, postServer, deleteServer} from 'src/service/http'

// 受注一览取得
export function orderDataFilter(param: object) {
  return postServer(`/order-data-filter`, {...param})
}

// 受注データ取得
export function orderDataGet(param: object) {
  return getServer(`/order-data-get`, {...param})
}

// 受注データ更新
export function orderDataUpdate(param: object, headers: object = {}) {
  return postServer(`/order-data-update`, {...param}, {...headers})
}

// 受注一览下载
export function orderDataDownload(param: object, headers: object = {}) {
  return getServer(`/order-data-download`, {...param}, {...headers})
}

// 注文一覧取得
export function orderListGet(param: object, headers: object = {}) {
  return getServer(`/order-list-get`, {...param}, {...headers})
}

// 注文詳細取得
export function orderDetailGet(param: object) {
  return getServer(`/order-detail-get`, {...param})
}

export function orderStatusUpdate(param: object, headers: object = {}) {
  return postServer(
    '/order-status-update',
    {
      ...param,
    },
    {...headers}
  )
}

export function orderDataAdd(orderDataList: any[], headers: object = {}) {
  return postServer(
    '/order-data-add',
    {
      orderDataList,
    },
    {...headers}
  )
}

// 受注決済データ取得
export function orderPaymentGet(param: object) {
  return postServer(`/order-payment-get`, {...param})
}
