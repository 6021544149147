/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {Button, Modal, message} from 'antd'
import {ID, KTSVG, QUERIES} from '@/_metronic/helpers'
import {useNavigate} from 'react-router-dom'
// import { deleteSubscriptionRegister } from '../../core/_requests';
import {MenuComponent} from '@/_metronic/assets/ts/components'
import {useAuth} from '@/app/modules/auth'
// import {getMenuListDev} from 'src/app/modules/api'
import {  deleteTemplate } from 'src/app/modules/api'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from '@/app/components/sweetAlert'
import {popper} from '@popperjs/core'

const options = [
  {id: '0', name: '編集する'},
  {id: '1', name: '削除'},
]

const CustomFieldActionsCell = (props: any) => {
  const {auth, saveAuth} = useAuth() as any
  const role = auth?.role
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [selectType, setSelectType] = useState<any>('')
  const [optionValue, setoptionValue] = useState<any>('')

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])


    // 删除某一项
    const showModal = (item:any) => {
      // console.log(item,"删除传递值");
      
      console.log('deleteList',item);
      if (item.pk ) {
        showWarningMessage(
          '元に戻すことはできません。よろしいでしょうか？', () => {
            deleteTemplate({pkVal: item.pk}).then((res:any)=> {
              // console.log('删除成功',res)
              props.deleteList(props.id)
              showSuccessMessage('削除しました')
            })
          },
          '削除',
          'キャンセル'
        )
      } else {
        showWarningMessage('非承認状態のため、削除できません。');
      }
    }
  

  const doModalOK = async () => {
    setBtnLoading(true)

    setBtnLoading(false)
    setIsModalOpen(false)
  }

  const doModalCancel = () => {
    setIsModalOpen(false)
  }

  // const updataMenus = async () => {
  //   const menu: any = await getMenuListDev({
  //     userId: auth?.data.login_id,
  //     roleGroup: auth?.data.role_group,
  //   })
  //   if (menu?.data) {
  //     saveAuth({...auth, menus: menu.data})
  //   }
  // }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        操作
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        <div className="menu-item px-3">
          <a style={{ fontSize: '13px' }}
            onClick={() => {
              navigate(`/order/csv/${props.id}`, {
                state: props.item,
            })
            }}
            className={"menu-link px-3 py-2 btn"} data-kt-docs-table-filter="edit_row">
            編集
          </a>
        </div>
        <div className="menu-item px-3">
          <a style={{ fontSize: '13px' }}
            onClick={() => {
              showModal(props.item)
            }}
            className={"menu-link px-3 py-2 btn"} data-kt-docs-table-filter="delete_row">
            削除
          </a>
        </div>
        
      </div>
      {/* <select
        className='form-select w-250px'
        data-control='select2'
        value={props.controls}
        onChange={(e) => {
          setSelectType(e.target.value)
          props.operationModification(props.index, e.target.value)
        }}
      >
        <option value={''}></option>
        {options.length !== 0 &&
          options.map((item: any, index: number) => {
            return (
              <option value={item.name} key={item.id}>
                {item.name}
              </option>
            )
          })}
      </select> */}

      <div className='menu-item px-3'>
        <Modal
          title='削除しますか?'
          open={isModalOpen}
          onOk={doModalOK}
          onCancel={doModalCancel}
          footer={[
            <Button key='cancel' onClick={doModalCancel}>
              Cancel
            </Button>,
            <Button
              key='submit'
              type='primary'
              loading={btnLoading}
              onClick={doModalOK}
              disabled={btnLoading}
            >
              OK
            </Button>,
          ]}
        >
          <p>元に戻すことはできません。よろしいでしょうか？</p>
        </Modal>
      </div>
      {/* </div> */}
    </>
  )
}

export {CustomFieldActionsCell}
