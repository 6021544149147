import React from 'react';
import { useField } from 'formik';
import { ex } from '@fullcalendar/core/internal-common';

interface CustomTextAreaProps {
    label: string;
    name: string;
  }
  
  const CustomTextArea: React.FC<CustomTextAreaProps> = ({ label, ...props }) => {
  
    const [field, meta] = useField(props);
    return (
      <div className='row'>
        <div className='col-12 align-self-center'>
          <textarea
            className='form-control'
            rows={5}
            {...field}
            {...props}
          ></textarea>
        </div>
        {meta.touched && meta.error ? (
          <div className='col-12 fv-plugins-message-container'>
            <div className='fv-help-block'>{meta.error}</div>
          </div>
        ) : null}
      </div>
    );
  };

  export default CustomTextArea;