import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from 'src/app/modules/auth'
import {Spin} from 'antd'
import {useFormik} from 'formik'
import * as yup from 'yup'
import ProcessButton from 'src/app/components/part/ProcessButton'
import {paymentProviderSettingGet, paymentProviderSettingUpdate} from 'src/app/modules/api'
import {useAlert} from 'src/_metronic/layout/core'

interface PaymentProviderProps {
  [provider: string]: {
    [mode: string]: {
      [key: string]: string
    }
  }
}

export default function PaymentProvider() {
  const navigate = useNavigate()
  const {auth} = useAuth()
  const {setMessage} = useAlert()
  const [loading, setLoading] = useState<any>(false)
  const [initialValues, setInitialValues] = useState<any>(null)
  const [currentProvider, setCurrentProvider] = useState('smbc')
  const [mode, setMode] = useState('production')
  const [userdata, setUserdata] = useState<any>({})
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }

  useEffect(() => {
    initData()
  }, [])

  useEffect(() => {
    if (initialValues) {
      // console.log('initialValues', initialValues)
      setLoading(false)
    }
  }, [initialValues])

  // 获取列表数据
  const initData = async () => {
    setLoading(true)
    let res: any = await paymentProviderSettingGet({})

    if (res) {
      // const provider: any = Object.keys(res.data[0].provider_settings)
      // const mode = Object.keys(res.data[provider])
      const provider = 'smbc'
      const mode = res.data[0].provider_settings[provider].mode

      console.log('provider', provider)

      let tempValues: any = {}
      Object.keys(res.data[0].provider_settings[provider]).map((key: any) => {
        if (key !== 'mode') {
          tempValues[key] = {
            siteId: res.data[0].provider_settings[provider][key].siteId || '',
            sitePass: res.data[0].provider_settings[provider][key].sitePass || '',
            shopId: res.data[0].provider_settings[provider][key].shopId || '',
            shopPass: res.data[0].provider_settings[provider][key].shopPass || '',
          }
          // return {
          //   [key]: {
          //     [res.data[0].provider_settings[provider].mode]: {
          //       siteId: res.data[0].provider_settings[provider][key].siteId || '',
          //       sitePass: res.data[0].provider_settings[provider][key].sitePass || '',
          //       shopId: res.data[0].provider_settings[provider][key].shopId || '',
          //       shopPass: res.data[0].provider_settings[provider][key].shopPass || '',
          //     },
          //   },
          // }
        }
      })

      console.log('tempValues', tempValues)

      // setInitialValues({
      //   [provider]: {
      //     mode: mode,
      //     [mode]: {
      //       siteId: res.data[0].provider_settings[provider][mode].siteId || '',
      //       sitePass: res.data[0].provider_settings[provider][mode].sitePass || '',
      //       shopId: res.data[0].provider_settings[provider][mode].shopId || '',
      //       shopPass: res.data[0].provider_settings[provider][mode].shopPass || '',
      //     },
      //   },
      // })
      setInitialValues({[provider]: {...tempValues, mode: mode}})

      // setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const schema = yup.object({
    siteId: yup.string().required(),
    sitePass: yup.string().required(),
    shopId: yup.string().required(),
    shopPass: yup.string().required(),
    provider: yup.string().required(),
  })

  const formik: any = useFormik({
    initialValues: initialValues || {
      smbc: {
        mode: 'disabled',
        production: {
          siteId: '',
          sitePass: '',
          shopId: '',
          shopPass: '',
        },
        sandbox: {
          siteId: '',
          sitePass: '',
          shopId: '',
          shopPass: '',
        },
      },
    },
    onSubmit: async (values: any) => {
      setLoading(true)

      let senddata = {
        mode: mode,
        provider: currentProvider,
        siteId: values[currentProvider][mode]?.siteId || '',
        sitePass: values[currentProvider][mode]?.sitePass || '',
        shopId: values[currentProvider][mode]?.shopId || '',
        shopPass: values[currentProvider][mode]?.shopPass || '',
      }

      let res: any = await paymentProviderSettingUpdate(senddata, headers)

      if (res) {
        initData()
        setMessage({type: 'success', message: '更新しました'})
      } else {
        setMessage({type: 'error', message: '更新に失敗しました'})
      }

      setLoading(false)
    },
    enableReinitialize: true,
    // validationSchema: schema,
  })

  const handleSubmit = async () => {
    formik.handleSubmit()
  }

  useEffect(() => {
    console.log('formik.values', formik.values)
  }, [formik.values])

  useEffect(() => {
    console.log('formik.values', formik.values)
  }, [initialValues])

  // const showFormikValues = () => {
  //   console.log(formik.values);
  // }

  return (
    <Spin spinning={loading} tip=' ' size='large'>
      <div className='container-fluid px-0'>
        <div className='card mb-6 shadow-sm'>
          <div className='d-flex align-items-center card-header'>
            <span className='card-title fw-bold m-0'>決済連携設定</span>
          </div>
          {/* <div className='card-body'> */}
          <form className='card-body' onSubmit={formik.handleSubmit}>
            <div className='row mb-4'>
              <div className='fs-4 fw-bold'>SMBC PG設定</div>
            </div>
            <div className='row mb-4'>
              <div className='col-3'>決済モード</div>
              <div className='col-9'>
                <select
                  className='form-select form-select-sm'
                  id={`${currentProvider}.mode`}
                  name={`${currentProvider}.mode`}
                  value={formik.values[currentProvider].mode}
                  onChange={(e) => {
                    console.log(e.target.value)
                    setMode(e.target.value)
                    formik.values[currentProvider]['mode'] = e.target.value
                  }}
                >
                  <option value='disabled'>未選択</option>
                  <option value='production'>本番</option>
                  <option value='sandbox'>テスト</option>
                </select>
              </div>
            </div>
            {['siteId', 'sitePass', 'shopId', 'shopPass'].map((field: any, index: number) => (
              <div className='row mb-4' key={index}>
                <div className='col-3'>
                  {field === 'siteId' && 'サイトID'}
                  {field === 'sitePass' && 'サイトパスワード'}
                  {field === 'shopId' && 'ショップID'}
                  {field === 'shopPass' && 'ショップパスワード'}
                </div>
                <div className='col-9'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id={`${currentProvider}.${formik.values[currentProvider].mode}.${field}`}
                    name={`${currentProvider}.${formik.values[currentProvider].mode}.${field}`}
                    value={
                      formik.values[currentProvider][formik.values[currentProvider].mode]
                        ? formik.values[currentProvider][formik.values[currentProvider].mode][field]
                        : ''
                    }
                    onChange={formik.handleChange}
                    disabled={formik.values[currentProvider].mode === 'disabled'}
                  />
                  {formik.errors[currentProvider] && formik.errors[currentProvider][field] && (
                    <div>{formik.errors[currentProvider][field]}</div>
                  )}
                </div>
              </div>
            ))}
            <input type='hidden' id='provider' name='provider' value={formik.values.provider} />
            <div className='row'>
              <div className='col-12 justify-content-end'>
                <ProcessButton type='submit' className='btn btn-primary' onClick={handleSubmit}>
                  保存
                </ProcessButton>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Spin>
  )
}
