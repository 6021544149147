import React, { useState } from 'react';
import DisplayCards from './module/displayCards';

const options = [
  {id: '0', name: '1 Hours'},
  {id: '1', name: '2 Hours'},
  {id: '2', name: '3 Hours'},
  {id: '3', name: '4 Hours'},
  {id: '4', name: '5 Hours'},
]
export default function BankCardRegister(props:any) {
  console.log('bankCardRegister',props)

  const [selectType, setSelectType] = useState<string>('') // 左上角选择框

  return (
    <div className='card mb-5 mb-xl-10'>
        <div className='card-header card-header-stretch pb-0 d-flex flex-wrap'>
          <div className='card-title'>
            <h3 className='m-0'>{props.title}</h3>
          </div>
          {/* <div className='card-toolbar m-0 d-flex flex-wrap'>
            {props.closeRightTitle !== true && (
              <div className='card-title fw-bold m-0 d-flex flex-wrap'>
                <select
                  className='form-select form-select-sm w-250px me-4'
                  data-control='select2'
                  style={{width: 200}}
                  value={selectType}
                  onChange={(e) => {
                    setSelectType(e.target.value)
                  }}
                >
                  <option value={''}></option>
                  {options.length !== 0 &&
                    options.map((item: any, index: number) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                </select>

                <button
                  className='btn btn-icon btn-primary w-80px btn-sm'
                  type='button'
                  onClick={() => {}}
                >
                  View All
                </button>
              </div>
            )}
          </div> */}
        </div>
        <div id='kt_billing_payment_tab_content' className='card-body tab-content'>
          <div
            id='kt_billing_creditcard'
            className='tab-pane fade show active'
            role='tabpanel'
            aria-labelledby='kt_billing_creditcard_tab'
          >
            <h3 className='mb-5'>カード情報</h3>
            <DisplayCards type={props.type} isEdit={true} />
          </div>
        </div>
      </div>
  );
}
