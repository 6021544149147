// Axios
import axios, {AxiosRequestConfig} from 'axios'
import {Logout} from '../app/modules/auth'
import {API_URL, host} from './api'
import {showErrorMessage} from '../app/components/sweetAlert'

const baseURL = API_URL

export const getServer = (url: string, params: any, headers: any = {}) => {
  return new Promise((resolve, reject) => {
    let urlAxios = url
    if (!urlAxios.startsWith('http')) {
      urlAxios = baseURL + url
    }
    axios
      .get(urlAxios, {params, headers})
      .then((resp: any) => {
        // console.log('resp', resp)
        if (resp.status === 200) {
          resolve(resp.data as any)
        } else if (resp.status === 403) {
          // Logout()
        } else {
          resolve(null)
          showErrorMessage(resp.data.message)
        }
      })
      .catch((error) => {
        console.log('error', error)
        reject(error)
      })
  })
}

export const postServer = (url: string, data: any, config?: AxiosRequestConfig) => {
  let urlAxios = url
  if (!urlAxios.startsWith('http')) {
    urlAxios = baseURL + url
  }
  return new Promise((resolve, reject) => {
    axios
      .post(urlAxios, data, config)
      .then((resp: any) => {
        if (resp.status === 200) {
          resolve(resp.data)
        } else if (resp.status === 403) {
          // Logout()
        } else {
          resolve(resp.data)
          showErrorMessage(resp.data.message)
        }
      })
      .catch((error) => {
        if (error.response) {
          reject(error.response.data)
          console.log(error.response.data.message)
        } else {
          reject(error.message)
          console.log(error.message)
        }
      })
  })
}

export const putServer = (url: string, data: any, config = {} as any) => {
  // console.log('post', data)
  data = JSON.stringify(data)
  let urlAxios = url
  if (!urlAxios.startsWith('http')) {
    urlAxios = baseURL + url
  }
  // console.log('post',data)
  return new Promise((resolve, reject) => {
    axios
      .post(urlAxios, data, config as any)
      .then((resp: any) => {
        // console.log('resp', resp)
        // resp.data.code = resp.status;
        if (resp.status === 200) {
          resolve(resp.data)
        } else if (resp.status === 403) {
          // Logout()
        } else {
          resolve(null)
          showErrorMessage(resp.data.message)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const deleteServer = (url: string, params: any, headers: any = {}) => {
  // console.log("params",params);
  params = {...params}
  return new Promise((resolve, reject) => {
    let urlAxios = url
    if (!urlAxios.startsWith('http')) {
      urlAxios = baseURL + url
    }
    axios
      .delete(urlAxios, {params: params, headers: headers})
      .then((resp: any) => {
        // console.log('resp', resp)
        if (resp.status === 200) {
          resolve(resp.data as any)
        } else if (resp.status === 403) {
          // Logout()
        } else if (resp.data && resp.data.message) {
          resolve(resp.data as any)
        } else {
          // throw new Error(resp.data.message)
          showErrorMessage(resp.data.message)
          resolve(null)
        }
      })
      .catch((error) => {
        console.log('error', error)
        reject(error)
      })
  })
}
