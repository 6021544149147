import {getServer, postServer, deleteServer} from 'src/service/http'
import {object} from 'yup'

// 取引取得
export function entrytran(json: object) {
  // return postServer('/gmopg-entrytran', {...json, target_type:supplier.userInfo_type});
  return postServer('/gmopg-entrytran', {...json})
}
export function entrytran2(json: object) {
  return postServer('/gmopg-entrytran', {...json, target_type: 'smbc'})
}

// 決済実行
export function exectran(json: object) {
  // return postServer('/gmopg-exectran', {target_type:supplier.userInfo_type,user_id:supplier.update_user,...json});
  return postServer('/gmopg-exectran', {...json})
}
export function exectran2(json: object) {
  // return postServer('/gmopg-exectran', {target_type:'smbc',user_id:supplier.update_user,...json});
  return postServer('/gmopg-exectran', {target_type: 'smbc', ...json})
}
export function execsecuretran(json: object) {
  return postServer('/gmopg-securetran', {...json})
}
export function transauth(json: object) {
  return postServer('/gmopg-transauth', {...json})
}

// 支付 取引登録
// EntryTranVirtualaccount
export function entrytranvirtualaccount(json: object) {
  return postServer('/smbc-entrytranvirtualaccount', {...json})
}
//支付 -決済実行
export function exectranvirtualaccount(json: object) {
  return postServer('/smbc-exectranvirtualaccount', {...json})
}
//支付 -取引状態参照
export function searchtrademulti(json: object) {
  return postServer('/smbc-searchtrademulti', {...json})
}
//支付 -修改订单状态
export function paymentStatusUpdate(json: object, headers: object = {}) {
  return postServer('/payment-status-update', {...json}, {...headers})
}
