import React, {Fragment, useEffect, useRef, useState} from 'react'
import {useNavigate, useLocation, useParams, useSearchParams} from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import {useLayout} from 'src/_metronic/layout/core'
import {orderDataGet, orderDataUpdate, parameterStoreGet} from 'src/app/modules/api'
import {useAlert} from 'src/_metronic/layout/core'
import {useAccount} from 'src/app/modules/accounts/AccountProvider'
import {
  OrderLineItems,
  Payments,
  ShippingDetails,
  OrderData,
  BillingData,
  OrderInfo,
  OrderTimeLine,
  Note,
} from 'src/app/components/orderdetail2'

export default function OrderDetail() {
  const {isContentLoading, setContentLoading} = useLayout()
  const [parameters, setParameters] = useState<{[key: string]: {value: []}}>({})
  const [orderData, setOrderData] = useState<any>({})
  const [dataReady, setDataReady] = useState<boolean>(false)
  const {account, setAccount} = useAccount()
  const {id} = useParams()
  const {setMessage} = useAlert()
  const navigate = useNavigate()
  const role = 'supplier'

  const setOrderDataFunc = (data: any) => {
    setOrderData((prev: any) => {
      return {...prev, ...data}
    })
  }

  const getOrderData = async () => {
    let res: any = await orderDataGet({
      id: id,
    })
    setOrderData(res)
  }

  const getParameterStore = async () => {
    try {
      const parametersRes: any = await parameterStoreGet(
        {
          names: [
            '/Define/General/Prefecture',
            '/Define/Order/Status',
            '/Define/Payment/Status',
            '/Define/Product/Units',
            '/Define/Payment/Method',
          ],
        },
        {}
      )

      setParameters({
        prefecture: JSON.parse(parametersRes['/Define/General/Prefecture']),
        orderStatus: JSON.parse(parametersRes['/Define/Order/Status']),
        paymentStatus: JSON.parse(parametersRes['/Define/Payment/Status']),
        productUnit: JSON.parse(parametersRes['/Define/Product/Units']),
        paymentMethod: JSON.parse(parametersRes['/Define/Payment/Method']),
      })
    } catch (error) {
      console.error('Failed to fetch parameter store', error)
    }
  }

  const initialData = () => {
    const promises = [getOrderData(), getParameterStore()]
    Promise.all(promises)
      .then(() => {
        // setContentLoading(false) // データ取得が成功した場合にローディング状態を解除
      })
      .catch((error) => {
        console.error('Failed to fetch order data', error)
        setMessage({type: 'error', message: 'データが取得できませんでした'})
        navigate('/order')
        setContentLoading(false) // データ取得が失敗した場合にローディング状態を解除
      })
  }

  // account.menuAttributes.unread_orderを更新する
  const updateAccountMenuAttributes = (count: number) => {
    const newMenuAttributes = {
      unread_order: count.toString(),
    }
    const newAccount = {...account}
    newAccount.menuAttributes = newMenuAttributes
    setAccount(newAccount)
  }

  useEffect(() => {
    setContentLoading(true)
    // 既読フラグをつける
    orderDataUpdate({order_ids: [id], type: 'read_flag'}).then((res: any) => {
      if (res.success === true) {
        updateAccountMenuAttributes(res.data.unread_order)
      }
    })
    initialData()
  }, [])

  useEffect(() => {
    // console.log('orderData', orderData)
    // console.log('parameters', parameters)

    if (
      orderData &&
      Object.keys(orderData).length > 0 &&
      parameters &&
      Object.keys(parameters).length > 0
    ) {
      setContentLoading(false) // データが揃った場合にローディング状態を解除
      setDataReady(true)
    }
  }, [orderData, parameters])

  if (!dataReady) {
    return null
  }

  return (
    <>
      <div className='container-lg'>
        <div className='row mb-4'>
          <div className='col-12 col-lg-8 px-0 pe-lg-4'>
            <div className='d-flex flex-column gap-4 mb-4'>
              <OrderLineItems
                orderData={orderData}
                setOrderData={setOrderDataFunc}
                handleInit={initialData}
                role={role}
              />
              <Payments
                orderData={orderData}
                handleInit={initialData}
                parameters={parameters}
                role={role}
              />
              <ShippingDetails orderData={orderData} handleInit={initialData} role={role} />
            </div>
          </div>
          <div className='col-12 col-lg-4 px-0'>
            <div className='d-flex flex-column gap-4'>
              <OrderData
                orderData={orderData}
                handleInit={initialData}
                parameters={parameters}
                role={role}
              />
              <BillingData
                orderData={orderData}
                handleInit={initialData}
                parameters={parameters}
                role={role}
              />
              <OrderInfo orderData={orderData} handleInit={initialData} role={role} />
            </div>
          </div>
        </div>

        <div className='row mb-4'>
          <div className='col-12 px-0'>
            <OrderTimeLine orderData={orderData} role={role} />
          </div>
        </div>

        <div className='row'>
          <div className='col-12 px-0'>
            <Note orderData={orderData} handleInit={initialData} role={role} />
          </div>
        </div>
      </div>
    </>
  )
}
