import {FC, useEffect, useState} from 'react'
import {KTCard, KTSVG} from 'src/_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from 'src/_metronic/layout/core'
import {MenuComponent} from 'src/_metronic/assets/ts/components'
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from 'src/app/components/sweetAlert'
import {Pagination} from 'src/app/components/pagination'
import {Loading} from 'src/app/components/loading'
import moment from 'moment'
import {customerSupplierApplicationsSearch} from 'src/app/modules/api'
import {useQueryRequest} from '@/app/modules/accounts/users-list/core/QueryRequestProvider'
import {useLayout} from 'src/_metronic/layout/core'
import {useAuth} from 'src/app/modules/auth'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'ホーム',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const options: any = [
  {id: 'pending', label: '承認待ち'},
  {id: 'non_approval', label: '非承認'},
  {id: 'approval', label: '承認済'},
]

const Pageconts: FC = () => {
  const {auth} = useAuth()
  const navigate = useNavigate()
  const {setContentLoading} = useLayout()
  const [datas, setDatas] = useState<any>([]) // 数据列表
  const [selectType, setSelectType] = useState<string>('') // 左上角选择框
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
  const [selectedList, setSelectList] = useState<any>([]) // 选中的项
  const [queryParams, setQueryParams] = useState<any>({
    company_name: '',
    status: '',
    page: 1,
    pageSize: 10,
    total: 0,
  }) // 分页参数

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }

  const [role, setRole] = useState<string | undefined>()
  const [lastLogin, setLastLogin] = useState<string | undefined>()
  const filterData = () => {
    updateState({
      filter: {role, last_login: lastLogin},
    })
  }
  const resetData = () => {
    updateState({filter: undefined})
  }
  const {updateState} = useQueryRequest()

  useEffect(() => {
    MenuComponent.reinitialization()
    initData()
  }, [])

  // 获取列表数据
  const initData = () => {
    setContentLoading(true)
    getData(queryParams.page, queryParams.pageSize)
      .then(() => {
        setContentLoading(false)
      })
      .catch(() => {
        setContentLoading(false)
      })
  }

  // 获取列表数据
  const getData = async (pageNum: any, pageSize: any) => {
    // console.log('pageNum', pageNum, pageSize)
    setIsLoading(true)
    try {
      const res: any = await customerSupplierApplicationsSearch(
        {
          pageNum,
          pageSize,
          company_name: queryParams.company_name,
          status: queryParams.status,
        },
        headers
      )
      console.log('res', res)
      setQueryParams({...queryParams, total: res.total})
      setDatas(res.rows)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('error', error)
    }
  }

  // 改变选中列表
  const changeSelectList = (obj: any) => {
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = []
        datas.forEach((item: any) => {
          arr.push(item.pk)
        })
        setSelectList(arr)
      } else {
        selectedList.push(obj.id)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([])
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id
        })
        selectedList.splice(idx, 1)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    }
  }

  return (
    <div style={{position: 'relative'}}>
      <div
        className='mb-6'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div className='d-flex align-items-center position-relative'>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-1 position-absolute ms-6'
          />
          <input
            type='text'
            data-kt-user-table-filter='search'
            style={{marginRight: 10}}
            className='form-control w-250px ps-14'
            placeholder='検索'
            value={queryParams.product_name}
            onChange={(e) => {
              queryParams.company_name = e.target.value
              setQueryParams({...queryParams, company_name: e.target.value})
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.keyCode === 13) {
                initData()
              }
            }}
          />
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
          <button
            disabled={isLoading}
            type='button'
            className='btn btn-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            {/* <KTIcon iconName='filter' className='fs-2' /> */}
            <i className='fa-solid fa-filter fs-5 me-3'></i>
            フィルター
          </button>
          <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
            <div className='separator border-gray-200'></div>
            <div className='px-7 py-5' data-kt-user-table-filter='form'>
              <div className='mb-10'>
                <label className='form-label fs-6 fw-bold'>承認状況:</label>
                <select
                  className='form-select fw-bolder'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='role'
                  data-hide-search='true'
                  onChange={(e) => {
                    setRole(e.target.value)
                    setQueryParams({...queryParams, status: e.target.value})
                  }}
                  value={role}
                >
                  {/* <option value=''>▼ソート</option>
                                        <option value='未承認でソートする'>未承認でソートする</option>
                                        <option value='承認でソートする'>承認でソートする</option> */}
                  <option value=''>全て</option>
                  {options.length !== 0 &&
                    options.map((item: any, index: number) => {
                      return (
                        <option value={item.id} key={item.id}>
                          {item.label}
                        </option>
                      )
                    })}
                </select>
              </div>

              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  disabled={isLoading}
                  onClick={filterData}
                  className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='reset'
                >
                  キャンセル
                </button>
                <button
                  disabled={isLoading}
                  type='button'
                  onClick={() => {
                    resetData()
                    initData()
                  }}
                  className='btn btn-primary fw-bold px-6'
                  data-kt-menu-dismiss='true'
                  data-kt-user-table-filter='filter'
                >
                  適用
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <KTCard resetSidePaddings={true}>
        <div className='table-responsive '>
          <table
            className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer'
            style={{minWidth: 1000}}
          >
            <thead className='table-light'>
              <tr className='text-start fs-6 text-uppercase gs-0'>
                {/* <th className='w-10px pe-2'>
                  <div className='form-check form-check-sm form-check-custom me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      data-kt-check-target='#kt_table_users .form-check-input'
                      checked={selectedList.length === datas.length && selectedList.length !== 0}
                      onChange={(e) => {
                        let obj = {
                          id: 'all',
                          value: e.target.checked,
                        }
                        changeSelectList(obj)
                      }}
                    />
                  </div>
                </th> */}
                <th className='min-w-120px'>顧客ID</th>
                <th className='min-w-150px'>顧客名</th>
                <th className='min-w-150px'>ジャンル</th>
                <th className='min-w-150px'>承認状況</th>
                <th className='min-w-150px'>申請日時</th>
                <th className='min-w-150px'>更新日時</th>
              </tr>
            </thead>
            <tbody>
              {datas.length > 0 ? (
                datas.map((item: any, index: number) => {
                  return (
                    <tr key={item.pk}>
                      {/* <td>
                        <div className='form-check form-check-sm form-check-custom me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check-target='#kt_table_users .form-check-input'
                            checked={selectedList.includes(item.pk)}
                            onChange={(e) => {
                              let obj = {
                                id: item.pk,
                                value: e.target.checked,
                              }
                              changeSelectList(obj)
                            }}
                          />
                        </div>
                      </td> */}
                      <td>
                        <a
                          onClick={(event) => {
                            event.preventDefault()
                            navigate(`/customer/${item.customer_id}`, {
                              state: {
                                pk: item.buyer_id,
                                type: 'detail',
                                group_id: item.group_id,
                                status: item.status,
                              },
                            })
                          }}
                          href='#'
                        >
                          {item.display_id}
                        </a>
                      </td>
                      <td>{item.company_name}</td>
                      <td>{item.actual_industry}</td>
                      <td>
                        {item.status == 'non_approval' && (
                          <a href='#' className='badge badge-light-danger'>
                            非承認
                          </a>
                        )}
                        {(item.status == 'pending' || !item.status) && (
                          <a href='#' className='badge badge-light-warning'>
                            承認待ち
                          </a>
                        )}
                        {item.status == 'approval' && (
                          <a href='#' className='badge badge-light-primary'>
                            承認済
                          </a>
                        )}
                      </td>
                      <td>
                        {item.application_date ? (
                          <>
                            {moment.utc(item.application_date).format('YYYY-MM-DD')}
                            <br />
                            {moment.utc(item.application_date).format('HH:mm:ss')}
                          </>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        {item.update_at ? (
                          <>
                            {moment.utc(item.update_at).format('YYYY-MM-DD')}
                            <br />
                            {moment.utc(item.update_at).format('HH:mm:ss')}
                          </>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={16}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {queryParams.total != 0 && (
            <Pagination
              currentPage={getData}
              page={queryParams.page}
              pageSize={queryParams.pageSize}
              total={queryParams.total}
            />
          )}
        </div>
      </KTCard>
    </div>
  )
}

export default function CustomerApproval() {
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>取引先承認一覧</PageTitle>
      <Pageconts />
    </>
  )
}
