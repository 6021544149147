import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {User, UsersQueryResponse} from './_models'
import {API_URL, host} from '../../../../../service/api'

const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  
//   const response =  fetch(`${API_URL}/account-list?${query}`, {
//     method: "GET",
//     mode: "cors",
//     headers: {
//      "x-api-key": "SQRsdJQbdV9HxMMIrjsA8alrkTpQTLGT6SZD0Bnf",
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify((d: AxiosResponse<UsersQueryResponse>) => d.data),
// });
  return axios
    .get(`${API_URL}/account-list?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

// const getUsers = (query: any) => {
//   return getServer(`/account-list?${query}`,{})
// }

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${API_URL}/get-account-by-id?id=${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<any | undefined> => {
  return axios
    .post(`${API_URL}/register`, {...user,host})
    // .then((response: AxiosResponse<Response<User>>) => response.data)
    // .then((response: Response<User>) => response.data)
    .then((response: Response<any>) => response)
}

const updateUser = (user: User): Promise<any | undefined> => {
  return axios
    .post(`${API_URL}/account-edit`, {...user,host})
    // .then((response: AxiosResponse<Response<User>>) => response.data)
    // .then((response: Response<User>) => response.data)
    .then((response: Response<any>) => response)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${API_URL}/account-delete?id=${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${API_URL}/account-delete?id=${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser}
