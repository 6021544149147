import { getServer, postServer, deleteServer } from 'src/service/http'

// カレンダー情報更新
export function getUpdate(param: object) {
    return postServer(`/calendar-info-update`,  { ...param })
}

export function getAlUpdate(param: object) {
    return postServer(`/calendar-data-update`,  { ...param })
}
export function getcalendarInfo(param: object) {
    return getServer(`/calendar-info-get`,  { ...param })
}
export function getdataLoad(param: object) {
    return getServer(`/calendar-data-load`,  { ...param })
}