import { getServer, postServer, deleteServer } from 'src/service/http'

// 商品カテゴリ --------------------------------------------------

//  すべての分類の取得
export function productCategoryListAllGet(param: object) {
    return getServer(`/product-category-listAll-get`, { ...param })
}

// 商品カテゴリ一覧取得
export function productCategoryListGet(param: object) {
    return getServer('/product-category-list-get', { ...param, });
}

// 商品カテゴリ登録
export function productCategoryAdd(param: object, headers: object = {}) {
    return postServer('/product-category-add', { ...param }, { ...headers });
}

// 商品カテゴリ情報取得
export function productCategoryInfoGet(param: object) {
    return postServer('/product-category-info-get', { ...param });
}

// product-category-update
export function productCategoryUpdate(param: object, headers: object = {}) {
    return postServer('/product-category-update', { ...param }, { ...headers });
}

// 商品カテゴリ削除
export function productCategoryDelete(param: object) {
    return deleteServer('/product-category-delete', { ...param });
}