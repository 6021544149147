import {useEffect, useState} from 'react'
import {KTCard} from '@/_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {Pagination} from 'src/app/components/pagination'
import {Loading} from '@/app/components/loading'
import {CustomFieldActionsCell} from './tabel/endPointActionsCell'
import { useLayout } from '@/_metronic/layout/core'
import {
  productOptionItemAdd,
  productOptionItemUpdate,
  productOptionItemGet,
  productOptionItemDelete,
  productOptionItemCopy,
  productOptionItemListGet,
} from 'src/app/modules/api'
import { useAuth } from 'src/app/modules/auth'

export default function ProductOption() {
  const {auth} = useAuth()
  let headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${auth.token}`
  }
  const navigate = useNavigate()
  const {setContentLoading} = useLayout()
  const [datas, setDatas] = useState<any>([]) // 数据列表
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    pageNum:"1",
    pageSize: 10,
    total: 0,
  }) // 分页参数

  useEffect(() => {
    initData()
  }, [])
  // 获取列表数据
  const initData = () => {
    getData(queryParams.page, queryParams.pageSize)
  }
  // 获取列表数据
  const getData = async (pageNum: any, pageSize: any) => {
    queryParams.pageSize=pageSize
    queryParams.pageNum=pageNum
    // queryParams.page=pageNum
    setQueryParams({...queryParams, pageNum:pageNum})
    setQueryParams({...queryParams, pageSize:pageSize})

    setContentLoading(true)
    try {
      const res: any = await productOptionItemListGet({ ...queryParams }, headers)
      console.log('res', res)
      setQueryParams({...queryParams, total: res.total})
      setDatas(res.rows)
      setContentLoading(false)
    } catch (error) {
      setContentLoading(false)
      console.error('error', error)
    }
  }

  // 跳转编辑页
  const goDetail = async (item: any) => {
    navigate(`/product/option/${item.id}`, {
      state: item.pk,
    })
  }

  return (
    <div style={{position: 'relative'}}>
        <div className='d-flex justify-content-end mb-6'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => navigate('/product/option/id')}
          >
            商品オプション項目の追加
          </button>
        </div>
      <KTCard resetSidePaddings={true}>
        <div className='table-responsive '>
          <table
            className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer '
            style={{minWidth: 1000}}
          >
            <thead className='table-light'>
              <tr className='text-start fs-6 text-uppercase gs-0'>
                <th id='id'>商品オプションID</th>
                <th id='name'>商品オプション項目名</th>
                <th id='status'>状態</th>
                <th id='type'>フォームタイプ</th>
                {/* <th id='useStatus'>利用状況</th> */}
                <th>操作</th>
              </tr>
            </thead>
            <tbody className=''>
              {datas.length > 0 ? (
                datas.map((item: any, index: number) => {
                  return (
                    <tr key={item.pk}>
                      <td>{item.id}</td>
                      <td>
                        <a
                          onClick={(event) => {
                            event.preventDefault()
                            goDetail(item)
                          }
                          }
                          href="#"
                        >
                          {item.name}
                        </a>
                      </td>
                      <td>{item.status === 'enabled' ? '有効' : '無効'}</td>
                      <td>
                        {item.type === 'select' && 'プルダウン'}
                        {item.type === 'checkbox' && 'チェックボックス'}
                        {item.type === 'radio' && 'ラジオボタン'}
                        {item.type === 'text' && 'テキストフィールド'}
                      </td>
                      {/* <td>{item.decstiprion}</td> */}
                      <td style={{minWidth: 90}}>
                        <CustomFieldActionsCell
                          getAllList={initData}
                          item={item}
                          nowPage={queryParams.page}
                        ></CustomFieldActionsCell>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={17}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {queryParams.total != 0 && (
            <Pagination
              currentPage={getData}
              page={queryParams.page}
              pageSize={queryParams.pageSize}
              total={queryParams.total}
            />
          )}
        </div>
      </KTCard>
    </div>
  )
}
