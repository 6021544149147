import { Fragment, useEffect, useState } from 'react'
import { KTCard, KTSVG } from '@/_metronic/helpers'
import { TableLoading } from './tabel/tableLoading'
import { useNavigate, Link } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'

const class1 = 'page-item previous'
const class2 = 'page-item previous active'
const class3 = 'page-item previous disabled'

const options = [
  { id: '0', name: '編集する' },
  { id: '1', name: '削除' },
]

function CsvOrder() {
  const navigate = useNavigate()
  const [arr, setArr] = useState<any>([
    {
      id: 'A00011',
      isChange: '鶏とり商店',
      status: '2023/10/31 23:30',
      controls: '25000円',
      getBtn: () => <a href="#" style={{ width: 122 }} className="btn btn-outline btn-outline-warning">+未確定</a>,
    },
    {
      id: 'A00012',
      isChange: '肉にく本舗',
      status: '2023/10/10 11:10:15',
      controls: '25000円',
      getBtn: () => <a href="#" style={{ width: 122 }} className="btn btn-outline btn-outline-success">✓発送済み</a>,
    },
    {
      id: 'A00013',
      isChange: '牛うし屋',
      status: '2023/10/10 12:00:55',
      controls: '25000円',
      getBtn: () => <a href="#" style={{ width: 122 }} className="btn btn-outline btn-outline-danger">✓未修正</a>,
    },
    {
      id: 'A00014',
      isChange: '鶏とり商店',
      status: '2023/10/10 12:00:55',
      controls: '25000円',
      getBtn: () => <a href="#" style={{ width: 122 }} className="btn btn-outline btn-outline-warning">✓未修正</a>,
    }
  ]) //总数据
  const [data, setData] = useState<any>([]) // 数据列表
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
  const [selectedList, setSelectList] = useState<any>([]) // 选中的项
  const [nowPage, setNowPage] = useState<any>(1) // 当前页
  const [pageSize, setPageSize] = useState<any>(10) // 每页多少条
  let [page, setPage] = useState<any>([]) // 页码列表

  //处理页码
  const changePage = (sumPage: any, size?: number | string) => {
    page = []
    let num = Math.ceil(sumPage / Number(size ? size : pageSize))
    for (let i = 1; i < num + 1; i++) {
      page.push(i)
    }
    let newPage = JSON.parse(JSON.stringify([...page]))
    setPage(newPage)
  }
  //获取当前页码的列表
  const getPageList = async (page: any) => {
    setIsLoading(true)
    let newArr = arr.slice((page - 1) * pageSize, page * pageSize)
    setData(newArr)
    setNowPage(page)
    setIsLoading(false)
  }
  // 改变当前显示数量
  const changePageSize = (size: string | number) => {
    setPageSize(size)
    changePage(arr.length, size)
    let newArr = arr.slice((nowPage - 1) * Number(size), nowPage * Number(size))
    setData(newArr)
  }
  // 改变选中列表
  const changeSelectList = (obj: any) => {
    if (obj.value) {
      if (obj.id === 'all') {
        let arr: any = []
        data.forEach((item: any) => {
          arr.push(item.id)
        })
        setSelectList(arr)
      } else {
        selectedList.push(obj.id)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    } else {
      if (obj.id === 'all') {
        setSelectList([])
      } else {
        let idx = selectedList.findIndex((item: any) => {
          return item === obj.id
        })
        selectedList.splice(idx, 1)
        let newSelectedList = JSON.parse(JSON.stringify(selectedList))
        setSelectList(newSelectedList)
      }
    }
  }

  // 获取列表数据
  const getAllList = () => {
    changePage(arr.length)
    let newArr = arr.slice((nowPage - 1) * pageSize, nowPage * pageSize)
    setData(newArr)
  }

  useEffect(() => {
    getAllList()
  }, [])

  return (
    <div style={{ position: 'relative' }}>
      <KTCard resetSidePaddings={true}>
        <div
          className='card-header border-0'
          style={{ display: 'flex', padding: '20px', paddingBottom: 0 }}
        >
          <div className='card-title fs-3 fw-bold'>目方更新一覧</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className='d-flex align-items-center position-relative my-1'>
              <input
                type='text'
                data-kt-user-table-filter='search'
                style={{ marginRight: 10 }}
                className='form-control w-250px ps-14'
                placeholder='検索'
              />
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
            </div>

          </div>
        </div>

        <div style={{ marginLeft: 20, marginTop: 10 }}>
          処理中＋目方更新対象を表示しています。
        </div>

        <div className='table-responsive ' style={{ margin: '0px 30px 50px 30px ' }}>
          <table
            className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer'
          >
            <thead>
              <tr className='text-start fs-6 text-uppercase gs-0'>
                <th>
                  <div className='form-check form-check-sm form-check-custom me-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      data-kt-check-target='#kt_table_users .form-check-input'
                      checked={selectedList.length === data.length && selectedList.length !== 0}
                      onChange={(e) => {
                        let obj = {
                          id: 'all',
                          value: e.target.checked,
                        }
                        changeSelectList(obj)
                      }}
                    />
                  </div>
                </th>
                <th id="name">加盟店名</th>
                <th id='isChange'>受注日</th>
                <th id='controls'>注文金額</th>
                <th id='status'>ステータス</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item: any, index: number) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check-target='#kt_table_users .form-check-input'
                            checked={selectedList.includes(item.id)}
                            onChange={(e) => {
                              let obj = {
                                id: item.id,
                                value: e.target.checked,
                              }
                              changeSelectList(obj)
                            }}
                          />
                        </div>
                      </td>
                      <td>
                      {' '}
                          <a
                            onClick={() => {
                              navigate(`/order/weight/${item.id}`)
                            }}
                            style={{
                              textDecorationLine: 'underline',
                              color: '#009ef7',
                              cursor: 'pointer',
                            }}
                          >
                            {item.isChange}
                          </a>{' '}
                        {/* <Link to='order/weight' className='fw-bold text-hover-primary'>{item.isChange}</Link> */}
                        </td>
                      <td>{item.status}</td>
                      <td>{item.controls}</td>
                      <td>
                        {item.getBtn()}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={17}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <select
            className='form-select '
            data-control='select2'
            style={{ width: 80, position: 'absolute', left: 20, bottom: 10 }}
            value={pageSize}
            onChange={(e) => {
              changePageSize(e.target.value)
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>

          {/* page */}
          <ul className='pagination' style={{ position: 'absolute', right: 20, bottom: 10 }}>
            <li className={nowPage === page[0] ? class3 : class1}>
              <a
                href='#'
                className='page-link'
                onClick={(e) => {
                  getPageList(nowPage - 1)
                  e.preventDefault()
                }}
              >
                <i className='previous'></i>
              </a>
            </li>
            {page.map((item: any, index: number) => {
              return (
                <li className={nowPage === item ? class2 : class1} key={index}>
                  <a
                    href='#'
                    className='page-link'
                    onClick={(e) => {
                      getPageList(item)
                      e.preventDefault()
                    }}
                  >
                    {item}
                  </a>
                </li>
              )
            })}
            <li className={nowPage === page[page.length - 1] ? class3 : class1}>
              <a
                href='#'
                className='page-link'
                onClick={(e) => {
                  getPageList(nowPage + 1)
                  e.preventDefault()
                }}
              >
                <i className='next'></i>
              </a>
            </li>
          </ul>
          {isLoading && <TableLoading></TableLoading>}
        </div>
      </KTCard>
    </div>
  )
}

export default CsvOrder
