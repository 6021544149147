import { getServer, postServer, deleteServer } from 'src/service/http'

// 商品オプション ---------------------------------------------------------------

// 商品オプション項目情報登録
export function productOptionItemAdd(param: object, headers: object = {}) {
    return postServer(`/product-option-item-add`, { ...param }, { ...headers })
}

// 商品オプション項目情報更新
export function productOptionItemUpdate(param: object, headers: object = {}) {
    return postServer(`/product-option-item-update`, { ...param }, { ...headers })
}

// 商品オプション項目情報取得
export function productOptionItemGet(param: object) {
    return getServer(`/product-option-item-get`, { ...param })
}

// 商品オプション項目情報削除
export function productOptionItemDelete(param: object) {
    return deleteServer(`/product-option-item-delete`, { ...param })
}


// 商品オプション項目情報複製
export function productOptionItemCopy(param: object, headers: object = {}) {
    return postServer(`/product-option-item-copy`, { ...param }, { ...headers })
}

// 商品オプション項目一覧取得
export function productOptionItemListGet(param: object, headers: object = {}) {
    return getServer(`/product-option-item-list-get`, { ...param }, { ...headers })
}