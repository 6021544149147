import {useState, useEffect, SetStateAction, ChangeEvent} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useParams, useNavigate, useLocation, useSearchParams} from 'react-router-dom'
import {CKEditor} from '@ckeditor/ckeditor5-react'
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {getCommonDetails} from 'src/app/modules/api'
import {
  productCustomItemAdd,
  productCustomItemCopy,
  productCustomItemUpdate,
} from 'src/app/modules/api'
import Item from 'antd/es/list/Item'
import {useAuth} from 'src/app/modules/auth'
import {useLayout, useAlert} from 'src/_metronic/layout/core'

const darkMarketSettingOptions: darkMarketSettingOptionsType[] = [
  {id: 'enabled', label: '有効'},
  {id: 'disabled', label: '無効'},
]
const targetSetUseOptions: targetSetUseOptions[] = [
  {id: 'product', label: '基本項目'},
  {id: 'variation', label: 'バリエーション項目'},
]

const validationSchema = Yup.object().shape({
  id: Yup.string()
    .required('商品カスタム項目IDは必須です。')
    .matches(/^[0-9]+$/, '半角数字のみで入力してください。'),
  name: Yup.string()
    .required('商品カスタム項目名は必須です。')
    .min(1, '最小8文字')
    .max(255, '最大16文字'),
  status: Yup.string().required('状態は必須です。'),
  type: Yup.string().required('設定項目は必須です。'),
})

export default function ProductCustomFieldDetail() {
  const navigate = useNavigate()
  const {id} = useParams()
  const {setContentLoading} = useLayout()
  // const {setMessage} = useAlert()
  const currentLocation = useLocation()
  const [getSearchArr] = useSearchParams()
  const searchValue = getSearchArr.getAll('copy')[0]
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [flg, setflg] = useState<boolean>(true)
  const [formData, setFormData] = useState<any>({
    id: '',
    name: '',
    status: 'enabled',
    type: 'product',
    description: '',
  })
  const {setMessage} = useAlert()
  const {auth} = useAuth()
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.token}`,
  }

  const updateData = (fieldsToUpdate: Partial<any>) => {
    const updatedData = {...formik.values, ...fieldsToUpdate}
    formik.setValues(updatedData)
  }

  useEffect(() => {
    initData()
  }, [])

  const initData = async () => {
    setContentLoading(true)
    if (currentLocation.search != '') {
      setflg(false)
    }
    if (id == undefined && searchValue == undefined) {
      setflg(false)
      setContentLoading(false)
    }
    if (id || searchValue) {
      // setIsLoading(true)
      let res: any = await getCommonDetails({
        pkVal: currentLocation.state,
      })
      // setIsLoading(false)
      formik.setValues({
        pkVal: res.pk,
        id: res.id,
        name: res.name,
        status: res.status,
        type: res.type,
        description: res.description,
      })
    }
  }

  const formik: any = useFormik<any>({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('onSubmit', JSON.stringify(values))
      setSubmitting(true)
      setContentLoading(true)
      if (id && searchValue == undefined) {
        productCustomItemUpdate(values, {headers})
          .then((res: any) => {
            setContentLoading(false)
            setMessage({type: 'success', message: res.message})
            // navigate('/product/customcell')
          })
          .catch((err: any) => {
            setContentLoading(false)
          })
      } else if (id && searchValue) {
        productCustomItemCopy(values, {headers})
          .then((res: any) => {
            setContentLoading(false)
            setMessage({type: 'success', message: res.message})
            // navigate('/product/customcell')
          })
          .catch((err: any) => {
            setContentLoading(false)
          })
      } else {
        productCustomItemAdd(values, {headers})
          .then((res: any) => {
            setContentLoading(false)
            setMessage({type: 'success', message: res.message})
            // navigate('/product/customcell')
          })
          .catch((err: any) => {
            setContentLoading(false)
          })
      }
    },
  })

  useEffect(() => {
    if (id && formik.values.id != id) {
      setflg(false)
      setContentLoading(false)
    }
  }, [formik.values])

  return (
    <div className='container-fluid'>
      <form className='my-3' onSubmit={formik.handleSubmit} noValidate>
        <div className='card mb-5'>
          <div className='card-body'>
            {/* 商品カスタム項目ID */}
            <div className='row mb-6'>
              <div className='col-lg-3 col-form-label'>
                <label className='col-form-label required'>商品カスタム項目ID</label>
              </div>
              <div className='col-lg-9 align-self-center'>
                <input
                  className='form-control'
                  type='text'
                  disabled={flg ? true : false}
                  // style={{background: '#e9ecef'}}
                  {...formik.getFieldProps('id')}
                />
                {formik.touched.id && formik.errors.id && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.id}</div>
                  </div>
                )}
              </div>
            </div>

            {/* 商品カスタム項目名 */}
            <div className='row mb-6'>
              {/*  style={{textAlign: 'right'}} */}
              <div className='col-lg-3 col-form-label'>
                <label className='col-form-label required'>商品カスタム項目名</label>
              </div>
              <div className='col-lg-9 align-self-center'>
                <input
                  className='form-control'
                  type='text'
                  // disabled={dataInfo.status == 'disabled' ? true : false}
                  disabled={flg ? true : false}
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>
            </div>

            {/* 状態 */}
            <div className='row mb-2'>
              <div className='col-lg-3 col-form-label'>
                <label className='col-form-label'>状態</label>
              </div>
              <div className='col-lg-9 align-self-center'>
                {darkMarketSettingOptions.map((item, index) => {
                  return (
                    <div className='form-check form-check-inline pe-3' key={index}>
                      <input
                        className='form-check-input'
                        type='radio'
                        value={item.id}
                        // disabled={dataInfo.status == 'disabled' ? true : false}
                        disabled={flg ? true : false}
                        checked={formik.values.status === item.id}
                        onChange={() => {
                          formik.values.status = item.id
                          updateData(formik.values)
                        }}
                      />
                      <label className='form-check-label'>{item.label}</label>
                    </div>
                  )
                })}
              </div>
            </div>

            {/* 設定項目 */}
            <div className='row mb-2'>
              <div className='col-lg-3 col-form-label'>
                <label className='col-form-label'>設定項目</label>
              </div>
              <div className='col-lg-9 align-self-center'>
                {targetSetUseOptions.map((item, index) => {
                  return (
                    <div className='form-check form-check-inline pe-3' key={index}>
                      <input
                        className='form-check-input'
                        type='radio'
                        value={item.id}
                        // disabled={dataInfo.status == 'disabled' ? true : false}
                        disabled={flg ? true : false}
                        checked={formik.values.type === item.id}
                        onChange={() => {
                          formik.values.type = item.id
                          updateData(formik.values)
                        }}
                      />
                      <label className='form-check-label'>{item.label}</label>
                    </div>
                  )
                })}
              </div>
            </div>

            {/* </form> */}
          </div>
        </div>

        {/* 按键组 */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <button
            className='btn btn-primary'
            type='button'
            style={{marginRight: '10px'}}
            onClick={() => navigate(-1)}
          >
            戻る
          </button>
          <button
            type='submit'
            // disabled={dataInfo.status == 'disabled' ? true : false}
            disabled={formik.isSubmitting || !formik.isValid}
            className='btn btn-primary'
          >
            {flg ? '更新' : '登録'}
          </button>
        </div>
      </form>
    </div>
  )
}
