/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect, useRef} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../auth'
import VerificationModal from './VerificationModal'
import {ResetPassword} from './ResetPassword'
import {Spin} from 'antd'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required('メールまたはユーザー名は必須です')
    .matches(/^[0-9A-Za-z.@\-\+]{2,50}$/, 'ユーザー 名のフォーマットが正しくありません'),
  password: Yup.string().min(8, '最小8文字').max(16, '最大16文字').required('パスワードは必須です'),
})

const initialValues = {
  username: '',
  password: '',
}

var timer: any
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [modalVisit, setmodalVisit] = useState('none')
  const [inputValue, inputChange] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
  const [telLoading, setTelLoading] = useState(false)
  const {auth, completeNewPassword} = useAuth()
  const [num, setNumber] = useState()
  const [second, setSecond] = useState(60) //倒计时秒数
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [cognitoUser, setCognitoUser] = useState('')
  const [isChangePassword, setIsChangePassword] = useState(false)

  useEffect(() => {
    clearInterval(timer)
    return () => clearInterval(timer)
  }, [])

  // 监听秒数的变动
  useEffect(() => {
    if (second > 0 && second < 60) {
    } else {
      // 定时器超过时间后，可以重新发送验证码
      clearInterval(timer)
      timer = null

      // 可点击
      setBtnDisabled(false)
      setSecond(60)
    }
  }, [second])

  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (
      values: {username: string; password: string},
      {setStatus, setSubmitting}: any
    ) => {
      setLoading(true)
      try {
        const result: any = await auth.signIn(values.username, values.password)
        console.log('result', result)

        if (result.success) {
          console.log('auth ok')
        } else {
          console.log(result)
          if (result.code === 'UserNotConfirmedException') {
            setShowModal(true)
          } else if (result.code === 'NEW_PASSWORD_REQUIRED') {
            setCognitoUser(result.user)
            setIsChangePassword(true)
            // navigate('/auth/reset-password', {
            //   state: {
            //     user: result.user,
            //     message: '仮パスワードが使用されています。新しいパスワードを設定してください。',
            //   },
            // })
          } else {
            // alert(result.message);
            throw new Error(result.message)
          }
        }

        setLoading(false)
      } catch (error) {
        const typedError = error as Error
        console.error(typedError)
        setStatus(typedError.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      {isChangePassword && <ResetPassword user={cognitoUser} handleClose={setIsChangePassword} />}
      {!isChangePassword && (
        <>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px'>
              <div className='d-flex flex-stack py-2 text-center'>
                <div className='w-100 separator separator-content my-6'>
                  <span className='fw-bold fs-4' style={{width: '8em'}}>
                    ログイン
                  </span>
                </div>
              </div>
              <div className='pb-0'>
                {formik.status && (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                )}

                <div className='fv-row mb-3'>
                  <label className='mb-1'>メールアドレス</label>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('username')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.username && formik.errors.username},
                      {
                        'is-valid': formik.touched.username && !formik.errors.username,
                      }
                    )}
                    type='text'
                    name='username'
                    autoComplete='off'
                  />
                  {formik.touched.username && formik.errors.username && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.username}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row mb-3'>
                  <label className='mb-1'>パスワード</label>
                  <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                  <div />
                  <Link to='/auth/forgot-password' className=''>
                    パスワードをお忘れですか？
                  </Link>
                </div>

                <div className='d-flex flex-stack mb-4'>
                  <div className='col-12 col-lg-6 offset-lg-3'>
                    <Spin spinning={loading}>
                      <button
                        id='kt_sign_in_submit'
                        className='btn btn-primary w-100 me-2 flex-shrink-0'
                        // onClick={() => loginWithRedirect()}
                        // onClick={() => signUp()}
                      >
                        <span className='indicator-label'>ログイン</span>
                      </button>
                    </Spin>
                  </div>
                </div>

                {/* <div className="row">
              <div className="col-12 text-center">
                <Link
                  to='/auth/RegisterMember'
                  className='fs-6'
                  data-kt-translate='sign-in-head-link'
                >
                  会員登録がお済みでない方はこちら
                </Link>
              </div>
            </div> */}
              </div>
            </div>
          </form>
          <VerificationModal showModal={showModal} setShowModal={setShowModal} />
        </>
      )}
    </>
  )
}
