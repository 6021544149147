import {useEffect, useState} from 'react'
import {KTCard} from 'src/_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import { Pagination } from 'src/app/components/pagination'
import { Loading } from 'src/app/components/loading'
import {CustomFieldActionsCell} from './tabel/endPointActionsCell'
import { productCustomItemListGet } from 'src/app/modules/api'
import { useLayout } from 'src/_metronic/layout/core'
import { useAuth } from 'src/app/modules/auth'

export default function ProductCustomField() {
  const {auth} = useAuth()
  const navigate = useNavigate()
  const {setContentLoading} = useLayout()
  const [datas, setDatas] = useState<any>([]) // 数据列表
  const [isLoading, setIsLoading] = useState<boolean>(false) // 加载状态
  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    pageSize: 10,
    total: 0
  }) // 分页参数

  useEffect(() => {
    initData()
  }, [])

  // 获取列表数据
  const initData = () => {
    getData(queryParams.page, queryParams.pageSize)
  }

  // 获取列表数据
  const getData = async (pageNum: any, pageSize: any) => {
    // console.log('pageNum', pageNum, pageSize)

    let headers = {
      'content-type': 'application/json',
      Authorization: `Bearer ${auth.token}`
    }

    setContentLoading(true)
    try {
      const res: any = await productCustomItemListGet({ pageNum, pageSize }, headers)
      console.log('res', res)
      setQueryParams({ ...queryParams, total: res.total })
      setDatas(res.rows);
      setContentLoading(false)
    } catch (error) {
      setContentLoading(false)
      console.error("error", error);
    }
  }

  // 跳转编辑页
  const goDetail = async (item: any) => {
    navigate(`/product/customcell/${item.id}`, { state: item.pk })
  }

  return (
    <div style={{position: 'relative'}}>
        <div className='d-flex justify-content-end mb-6'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() =>
              navigate('/product/customcell/id')
            }
          >
            商品カスタム項目の追加
          </button>
        </div>
      <KTCard resetSidePaddings={true}>
        <div className='table-responsive '>
          <table
            className='table align-middle table-row-bordered fs-6 gy-4 dataTable no-footer '
            style={{minWidth: 1000}}
          >
            <thead className='table-light'>
              <tr className='text-start fs-6 text-uppercase gs-0'>
                <th id='id'>
                  商品カスタム項目ID
                </th>
                <th id='name'>
                  商品カスタム項目名
                </th>
                <th id='status'>
                  状態
                </th>
                <th id='basicVariation'>
                  基本/バリエーション
                </th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody className=''>
              {datas.length > 0 ? (
                datas.map((item: any, index: number) => {
                  return (
                    <tr key={item.pk}>
                      <td>{item.id}</td>
                      <td>
                        <a onClick={(event)=> {event.preventDefault(); goDetail(item)}} href="#">
                          {item.name}
                        </a>
                      </td>
                      <td>{item.status === 'enabled' ? '有効' : '無効'}</td>
                      <td>{item.type === 'product' ? '基本' : 'バリエーション'}</td>
                      <td style={{minWidth: 90}}>
                        <CustomFieldActionsCell
                          getAllList={initData} 
                          item={item}
                        ></CustomFieldActionsCell>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={17}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {isLoading ? 'データの取得中' : '該当するレコードがありません。'}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {queryParams.total != 0 &&
            <Pagination currentPage={getData} page={queryParams.page} pageSize={queryParams.pageSize} total={queryParams.total} />
          }
        </div>
      </KTCard>
    </div>
  )
}
