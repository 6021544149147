import React from "react";
import { useEffect, useState } from 'react'
import { useLocation, useParams, useSearchParams, useNavigate } from 'react-router-dom'
import { shippingDataDetailGet, shippingUpDateGet } from 'src/app/modules/api';
import Table from 'react-bootstrap/Table';
import { showSuccessMessage } from 'src/app/components/sweetAlert'
import { productGroupGet, } from 'src/app/modules/api'//配送方式api
import { useAuth } from "src/app/modules/auth"
export default function LogisticsDetail() {
    const Navigate = useNavigate()
    const [data, setData] = useState<any>([]) // 数据列表
    const [information, setInformation] = useState<any>([]) // 商品信息
    const [deliveryGroupOptions, setDeliveryGroupOptions] = useState<any>([])//配送方式
    const {auth} = useAuth();
    let headers = {
      "Content-Type":"application/json",
      Authorization:`Bearer ${auth.token}`
    }

    // 定义可以修改的字段
    const [state, setState] = useState<any>({
        status: "",
        prefecture: "",//用户名
        city: "",
        zip_code: "",
        street_address: "",
        building_name: "",
        store_name: "",
        phone_number: "",
        email_address: "",
        temperature_range: "",
        tracking_genre: "",
        tracking_id: "",
        shipment_date: "",
        desired_delivery_date: "",
        desired_delivery_time: "",
        notes: "",
        delivery_method: "",//配送方法
        pk: ""
    })
    // pk
    const [pk, setPk] = useState<any>('')
    // const {id} = useParams()
    const currentLocation = useLocation()
    console.log(currentLocation, "参数id")
    // 获取发货详情
    const getAllDetail = (page: any, pageSize: any, obj?: any) => {
        console.log(obj)
        // let pkVal = '4gosl451c0snn4jjldn2780vtr#ShippingDetails#01dd3f31-a6d1-4379-b63f-d30abcbe096b'
        let pkVal = currentLocation.state.pk
        shippingDataDetailGet(pkVal).then((res: any) => {
            console.log(res, "详情接口返回的数据")
            setData(res)
            setInformation(res.itemsList)//商品信息
            setPk(res.pk)
            setState(
                {
                    status: res.status,
                    prefecture: res.prefecture,
                    city: res.city,
                    delivery_method: res.delivery_method,//配送方法
                    zip_code: res.zip_code,
                    street_address: res.street_address,
                    building_name: res.building_name,
                    store_name: res.store_name,
                    name: res.name,
                    phone_number: res.phone_number,
                    email_address: res.email_address,
                    temperature_range: res.temperature_range,
                    tracking_genre: res.tracking_genre,
                    tracking_id: res.tracking_id,
                    shipment_date: res.shipment_date,
                    desired_delivery_date: res.desired_delivery_date,
                    desired_delivery_time: res.desired_delivery_time,
                    notes: res.notes,
                    pk: res.pk,
                });
        })
    }
    // 调用配送方式
    const getOptions = async () => {
        let res = await productGroupGet({},headers)
        console.log(res, "配送方式");

        setDeliveryGroupOptions(res)
    }

    const handleClick = () => {
        alert('準備中です');
    };

    useEffect(() => {
        getAllDetail("1", "0")
        getOptions()//调用配送方式
    }, [])




    //更新按钮事件
    const getUpList = async () => {
        console.log(state, "需要更新参数");

        let res: any = await shippingUpDateGet(state, {headers})
        console.log(res, "更新返回的数据");
        showSuccessMessage(res.message)
        getAllDetail("1", "0")
    }
    const initialValues: any = { establishment_date: "" }
    return (
        <>
            <div className='card mb-6 shadow-sm'>
                <div className='d-flex align-items-center card-header'>
                    <span className='card-title fw-bold m-0'>出荷情報</span>
                    {/* <div className='card-toolbar flex-row-fluid justify-content-end'>
                        <a className='btn btn-primary'>編集</a>
                    </div> */}
                </div>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col'>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>出荷管理番号</label>
                                </div>
                                <div className='col align-self-center'>
                                    {data.display_id}
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>関連注文ID</label>
                                </div>
                                <div className='col align-self-center'>
                                    <a
                                        onClick={() => {
                                            Navigate(`/order/${data.display_id}`, {
                                                state: {
                                                    gamenFlg: '2',
                                                    orderManagementId: currentLocation.state.order_no,
                                                    pkVal: data.order_id,
                                                    id: data.id,
                                                    buyer_id: data.buyer_id,
                                                },
                                            })
                                        }}
                                        style={{
                                            textDecorationLine: 'underline',
                                            color: '#009ef7',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {data.display_id2}
                                    </a>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>ステータス</label>
                                </div>
                                <div className='col align-self-center'>
                                    <select
                                        className='form-select w-md-125px'
                                        value={state.status}
                                        onChange={(e) => {
                                            console.log(e.target.value, "ステータス改变事件");
                                            setState({ ...state, status: e.target.value });
                                        }}
                                    >
                                        <option value='awaiting'>出荷待ち</option>
                                        <option value='shipped'>出荷済み</option>
                                        <option value='cancelled'>キャンセル</option>
                                    </select>
                                </div>
                            </div>
                            {/* <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>出荷管理番号</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        value={data.id}
                                    />
                                </div>
                            </div> */}
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>郵便番号</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, zip_code: e.target.value });
                                        }}
                                        value={state.zip_code}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>都道府県</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, prefecture: e.target.value });
                                        }}
                                        value={state.prefecture}

                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>市区町村</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, city: e.target.value });
                                        }}
                                        value={state.city}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>丁目番地</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, street_address: e.target.value });
                                        }}
                                        value={state.street_address}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>建物名</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, building_name: e.target.value });
                                        }}
                                        value={state.building_name}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>屋号／店名</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, store_name: e.target.value });
                                        }}
                                        value={state.store_name}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>氏名</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, name: e.target.value });
                                        }}
                                        value={state.name}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>電話番号</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, phone_number: e.target.value });
                                        }}
                                        value={state.phone_number}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>メールアドレス</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='email'
                                        onChange={(e) => {
                                            setState({ ...state, email_address: e.target.value });
                                        }}
                                        value={state.email_address}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>温度帯</label>
                                </div>
                                <div className='col align-self-center'>
                                    <select
                                        className='form-select w-md-125px'
                                        value={state.temperature_range}
                                        onChange={(e) => {
                                            setState({ ...state, temperature_range: e.target.value });
                                        }}
                                    >
                                        <option value='1'>通常</option>
                                        <option value='2'>冷蔵</option>
                                        <option value='3'>冷凍</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>配送方法</label>
                                </div>
                                <div className='col align-self-center'>
                                    <select
                                        className='form-select w-md-125px'
                                        style={{ minWidth: 160 }}
                                        value={state.delivery_method}
                                        onChange={(e) => {
                                            setState({ ...state, delivery_method: e.target.value });
                                        }}
                                    >
                                        <option value="">選択してください</option>
                                        {
                                            deliveryGroupOptions.length !== 0 && deliveryGroupOptions.map((item: any, index: number) => {
                                                return (
                                                    <option value={item.id} key={item.pk}>{item.delivery_master_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>送り状種別</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, tracking_genre: e.target.value });
                                        }}
                                        value={state.tracking_genre}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>送り状番号</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, tracking_id: e.target.value });
                                        }}
                                        value={state.tracking_id}
                                    />
                                </div>
                            </div>
                            {/* <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>発送日</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, shipment_date: e.target.value });
                                        }}
                                        value={state.shipment_date}
                                    />
                                </div>
                            </div> */}
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>発送日</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, shipment_date: e.target.value });
                                        }}
                                        value={state.shipment_date}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>配送希望日</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, desired_delivery_date: e.target.value });
                                        }}
                                        value={state.desired_delivery_date}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>配送希望時間</label>
                                </div>
                                <div className='col align-self-center'>
                                    <input
                                        className='form-control'
                                        type='text'
                                        onChange={(e) => {
                                            setState({ ...state, desired_delivery_time: e.target.value });
                                        }}
                                        value={state.desired_delivery_time}
                                    />
                                </div>
                            </div>
                            <div className='row mb-6'>
                                <div className='col-3'>
                                    <label className='col-form-label'>社内メモ</label>
                                </div>
                                <div className='col align-self-center'>
                                    <textarea className='form-control'
                                        onChange={(e) => {
                                            setState({ ...state, notes: e.target.value });
                                        }}
                                        value={state.notes}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center card-footer justify-content-end gap-5'>
                    <a className='btn btn-light'>キャンセル</a>
                    <a className='btn btn-primary' onClick={() => { getUpList() }}>更新</a>
                </div>
            </div>


            {/*商品情报*/}
            <div className='card mb-6 shadow-sm'>
                <div className='d-flex align-items-center card-header'>
                    <span className='card-title fw-bold m-0'>商品情報</span>
                    <div className='card-toolbar flex-row-fluid justify-content-end'>
                        <a className='btn btn-light' onClick={handleClick}>ピッキング指示書をダウンロード</a>
                    </div>
                </div>
                <div className='card-body'>
                    <Table bordered >
                        <thead>
                            <tr>
                                <th>商品名</th>
                                <th>SKU</th>
                                <th>数量</th>
                                <th>単位</th>
                            </tr>
                        </thead>
                        <tbody>
                            {information?.length > 0 ? (
                                information?.map((item: any, index: number) => {
                                    return (
                                        <tr key={item.pk}>
                                            <td style={{ maxWidth: 160 }}>{item.product_name || ''}</td>
                                            <td>{item.sku || ''}</td>
                                            <td>{item.quantity || ''}</td>
                                            <td>{item.unit || ''}</td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>

        </>
    )
}
